import { ITHACA_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { LockedFeatures } from '@dtx-company/inter-app/src/redux/slices/lockedFeaturesSlice'
import { flowFetch } from '../../../code/src/utils/flowFetch'

const LOCKED_FEATURES_URL = `${ITHACA_ENDPOINT}/v2/locked-features`
export interface LockedFeaturesResponse {
  message: string
  lockedFeatures: LockedFeatures
}

export async function fetchLockedFeatures(): Promise<LockedFeatures | undefined> {
  const res = await flowFetch<LockedFeaturesResponse>(
    'GET',
    LOCKED_FEATURES_URL,
    {
      team: 'PLG'
    },
    {}
  )
  const lockedFeatures = res?.data.lockedFeatures

  return lockedFeatures
}
