import { ChoiceType, FieldType } from '@dtx-company/connect-codegen/src/gen/forms/v1/field_pb'
import { FormConfig } from '../api/redux/utils'
import { IconComponent } from '@dtx-company/design-system/src/types/util'
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import PollIcon from '@mui/icons-material/Poll'
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined'
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined'
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined'
import TitleIcon from '@mui/icons-material/Title'
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined'
import camelCase from 'lodash/camelCase'

export type DataRow = {
  [key: string]: any
}
export interface DataSet {
  page: number
  totalPages: number
  totalCount: number
  rows?: DataRow[]
  isLoading: boolean
  hasError: boolean
}

export const FIELD_TITLE_WIDTH = 290

interface Choice {
  value: string
}

interface ChoiceFieldProperties {
  choices: Choice[]
  choiceType: ChoiceType
  allowOther: boolean
}
export interface AddressProperties {
  address1: string
  address2?: string
  city: string
  zone: string
  postalCode: string
  country: string
}

export interface TextFieldProperties {
  paragraph: boolean
}

export interface ImageBlockProperties {
  imageUrl: string
}
export interface LegalFieldProperties {
  termsLink?: string
  disclaimer?: string
}

interface FieldProperties {
  choice?: ChoiceFieldProperties
  address?: AddressProperties
  textField?: TextFieldProperties
  image?: ImageBlockProperties
  legal?: LegalFieldProperties
}

export interface Field {
  id: string
  name: string
  title: string
  description: string
  active: boolean
  fieldType: FieldType
  required: boolean
  properties: FieldProperties
  autoGeneratedKey: string
}

export enum TextSubType {
  SHORT_TEXT,
  LONG_TEXT
}

export type FieldDescription = {
  title: string
  type: FieldType
  choiceType?: ChoiceType
  name: string
  textSubType?: TextSubType
  description: string
  icon: IconComponent
}

export const fieldTypes: FieldDescription[] = [
  {
    title: 'Name',
    type: FieldType.NAME,
    description: '',
    name: '',
    icon: AccountBoxOutlinedIcon
  },
  {
    title: 'Email',
    type: FieldType.EMAIL,
    description: '',
    name: '',
    icon: EmailOutlinedIcon
  },
  {
    title: 'Phone',
    type: FieldType.PHONE,
    description: '',
    name: '',
    icon: LocalPhoneOutlinedIcon
  },
  {
    title: 'Short text',
    type: FieldType.TEXT,
    textSubType: TextSubType.SHORT_TEXT,
    description: '',
    name: '',
    icon: TextFieldsOutlinedIcon
  },
  {
    title: 'Long text',
    type: FieldType.TEXT,
    textSubType: TextSubType.LONG_TEXT,
    description: '',
    name: '',
    icon: WysiwygOutlinedIcon
  },
  {
    title: 'Address',
    type: FieldType.ADDRESS,
    description: '',
    name: '',
    icon: HomeOutlinedIcon
  },
  {
    title: 'Single choice',
    type: FieldType.CHOICE,
    choiceType: ChoiceType.RADIO,
    description: '',
    name: '',
    icon: RadioButtonCheckedOutlinedIcon
  },
  {
    title: 'Multiple choice',
    type: FieldType.CHOICE,
    choiceType: ChoiceType.CHECKBOX,
    description: '',
    name: '',
    icon: CheckBoxOutlinedIcon
  },
  {
    title: 'Dropdown Field',
    type: FieldType.CHOICE,
    choiceType: ChoiceType.DROPDOWN,
    description: '',
    name: '',
    icon: ArrowDropDownCircleOutlinedIcon
  },
  {
    title: 'Date picker',
    type: FieldType.DATE,
    description: '',
    name: '',
    icon: CalendarMonthOutlinedIcon
  },
  {
    title: 'Heading',
    type: FieldType.BLOCK_TEXT,
    description: '',
    name: '',
    icon: TitleIcon
  },
  {
    title: 'Image',
    type: FieldType.BLOCK_IMAGE,
    description: '',
    name: '',
    icon: ImageOutlinedIcon
  },
  {
    title: 'Terms & Conditions',
    type: FieldType.LEGAL,
    description: '',
    name: '',
    icon: PrivacyTipOutlinedIcon
  },
  {
    title: 'Live Poll',
    type: FieldType.POLL,
    choiceType: ChoiceType.RADIO,
    description: '',
    name: '',
    icon: PollIcon
  }
]

export const fieldTypesLabelMappings = new Map(fieldTypes.map(obj => [obj.type, obj.title]))

type FieldMappings = {
  fields: Field[]
  fieldsOrder: number[]
}

export type Form = {
  id: string
  active: boolean
  title?: string
  description?: string
  successAction?: Record<string, any>
  createdAt?: string
  updatedAt?: string
} & FieldMappings

export const getFieldKey = (fieldType: FieldType): string => {
  const nameIndex = Math.floor(Math.random() * 1000)
  const name = camelCase(FieldType[fieldType])
  return `${name}-${nameIndex}`
}

export const getDefaultField = (field: FieldDescription): Field => {
  const f: Field = {
    id: '',
    name: field.name,
    active: true,
    required: false,
    fieldType: field.type,
    autoGeneratedKey: getFieldKey(field.type),
    title: field.type == FieldType.BLOCK_TEXT ? '' : field.title,
    description: field.description,
    properties: {}
  }

  switch (field.type) {
    case FieldType.CHOICE:
      f.title = 'Type a Question'
      f.properties = {
        choice: {
          choiceType: field.choiceType ?? ChoiceType.CHECKBOX,
          allowOther: true,
          choices: [{ value: 'Option 1' }, { value: 'Option 2' }, { value: 'Option 3' }]
        }
      }
      return f
    case FieldType.POLL:
      f.title = 'Type a Poll Prompt'
      f.properties = {
        choice: {
          choiceType: ChoiceType.RADIO,
          allowOther: true,
          choices: [{ value: 'Option 1' }, { value: 'Option 2' }, { value: 'Option 3' }]
        }
      }
      return f
    case FieldType.ADDRESS:
      f.properties = {
        address: {
          address1: 'Street 1',
          address2: 'Street 2',
          city: 'City',
          zone: 'State',
          postalCode: 'Postal Code',
          country: 'Country'
        }
      }
      return f
    case FieldType.BLOCK_IMAGE:
      f.title = ''
      return f
    case FieldType.TEXT:
      f.title = 'Type a Question'
      f.properties = { textField: { paragraph: field.textSubType == TextSubType.LONG_TEXT } }
      return f
    default:
      return f
  }
}

export type FieldProps = {
  fieldId: number
  isEditable: boolean
}

export enum TextboxStyleValues {
  STANDARD = 'standard',
  FILLED = 'filled',
  OUTLINED = 'outlined'
}

export enum ButtonStyleValues {
  CONTAINED = 'contained',
  OUTLINED = 'outlined'
}

export type PageTheme = {
  palette: {
    primary?: { main: string }
    secondary?: { main: string }
    background?: {
      default: string
    }
  }
  typography: {
    fontFamily: string
  }
  components: {
    // Name of the component
    MuiButton: {
      defaultProps: {
        variant: string
      }
    }
    MuiTextField: {
      defaultProps: {
        variant: string
      }
    }
  }
}

export interface Page {
  id: string
  name?: string
  slugName?: string
  domain?: string
  disableBranding?: boolean
  tags?: string[]
  theme: PageTheme
  config: FormConfig
  createdAt?: string
  updatedAt?: string
  pageComponents?: Record<string, any>[]
  formComponentId?: string //to keep track of the form component id in the layout
  formContentId?: string //to keep track of the form content id in the layout
  pageBackgroundId?: string //to keep track of the background component id in the layout
  formBackgroundColor?: string //keep track of form background color
}
