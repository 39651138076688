import { SignUpLocation } from '../auth'
import { fronteggObservers } from './observer'

export function notifyObserversOfLogin(accessToken: string): void {
  fronteggObservers.forEach(observer => observer.onLogin?.(accessToken))
}

export function notifyObserversOfSignup(
  accessToken: string,
  domain?: string,
  signUpLocation?: SignUpLocation
): void {
  fronteggObservers.forEach(observer => observer.onSignup?.(accessToken, domain, signUpLocation))
}

export function notifyObserversOfLogout(): void {
  fronteggObservers.forEach(observer => observer.onLogout?.())
}

export function notifyObserversOfRefresh(accessToken: string): void {
  fronteggObservers.forEach(observer => observer.onRefresh?.(accessToken))
}

export function notifyObserversOfImpersonation(impersonatedAccessToken: string): void {
  fronteggObservers.forEach(observer => observer.onImpersonation?.(impersonatedAccessToken))
}
