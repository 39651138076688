import { LoggerErrorBoundaryProps } from './LoggerErrorBoundaryProps'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { logger } from '../..'

export const LoggerErrorBoundary = ({
  children,
  options,
  ...rest
}: LoggerErrorBoundaryProps): JSX.Element => {
  return (
    <ReactErrorBoundary
      {...rest}
      onError={(error, info, ...args) => {
        if (rest.onError) rest.onError(error, info, ...args)
        logger.error({
          ...error,
          stack: error.stack,
          componentStack: info.componentStack,
          ...options
        })
      }}
    >
      {children}
    </ReactErrorBoundary>
  )
}
