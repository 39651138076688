// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file pages/v2/page.proto (package pages.v2, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { FieldMask, Message, proto3, Struct, Timestamp } from '@bufbuild/protobuf'
import { Creator } from '../../common/v1/types_pb'
import { Component } from './component_pb'
import { PaginationArguments, PaginationPageInfo } from '../../common/v1/pagination_pb'

/**
 * @generated from message pages.v2.Template
 */
export class Template extends Message<Template> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string page_id = 2;
   */
  pageId = ''

  /**
   * @generated from field: string org_id = 3;
   */
  orgId = ''

  /**
   * @generated from field: string workspace_id = 4;
   */
  workspaceId = ''

  /**
   * @generated from field: string state = 5;
   */
  state = ''

  /**
   * @generated from field: common.v1.Creator creator = 6;
   */
  creator?: Creator

  /**
   * @generated from field: string name = 7;
   */
  name = ''

  /**
   * @generated from field: optional string description = 8;
   */
  description?: string

  /**
   * @generated from field: string folder_path = 9;
   */
  folderPath = ''

  /**
   * @generated from field: repeated string tags = 10;
   */
  tags: string[] = []

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 11;
   */
  createdAt?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 12;
   */
  updatedAt?: Timestamp

  constructor(data?: PartialMessage<Template>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.Template'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'workspace_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'state', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'creator', kind: 'message', T: Creator },
    { no: 7, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 8, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: 'folder_path', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 10, name: 'tags', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 12, name: 'updated_at', kind: 'message', T: Timestamp }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Template {
    return new Template().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Template {
    return new Template().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Template {
    return new Template().fromJsonString(jsonString, options)
  }

  static equals(
    a: Template | PlainMessage<Template> | undefined,
    b: Template | PlainMessage<Template> | undefined
  ): boolean {
    return proto3.util.equals(Template, a, b)
  }
}

/**
 * @generated from message pages.v2.Page
 */
export class Page extends Message<Page> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string org_id = 3;
   */
  orgId = ''

  /**
   * @generated from field: string workspace_id = 4;
   */
  workspaceId = ''

  /**
   * @generated from field: optional string template_id = 2;
   */
  templateId?: string

  /**
   * @generated from field: optional string brand_kit_id = 5;
   */
  brandKitId?: string

  /**
   * @generated from field: string state = 6;
   */
  state = ''

  /**
   * @generated from field: common.v1.Creator creator = 7;
   */
  creator?: Creator

  /**
   * @generated from field: string name = 8;
   */
  name = ''

  /**
   * @generated from field: string folder_path = 9;
   */
  folderPath = ''

  /**
   * @generated from field: repeated string tags = 10;
   */
  tags: string[] = []

  /**
   * @generated from field: google.protobuf.Struct theme = 11;
   */
  theme?: Struct

  /**
   * @generated from field: google.protobuf.Struct config = 12;
   */
  config?: Struct

  /**
   * @generated from field: repeated pages.v2.Component components = 13;
   */
  components: Component[] = []

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 14;
   */
  createdAt?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 15;
   */
  updatedAt?: Timestamp

  constructor(data?: PartialMessage<Page>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.Page'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'workspace_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'template_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: 'brand_kit_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: 'state', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'creator', kind: 'message', T: Creator },
    { no: 8, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 9, name: 'folder_path', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 10, name: 'tags', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: 'theme', kind: 'message', T: Struct },
    { no: 12, name: 'config', kind: 'message', T: Struct },
    { no: 13, name: 'components', kind: 'message', T: Component, repeated: true },
    { no: 14, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 15, name: 'updated_at', kind: 'message', T: Timestamp }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Page {
    return new Page().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Page {
    return new Page().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Page {
    return new Page().fromJsonString(jsonString, options)
  }

  static equals(
    a: Page | PlainMessage<Page> | undefined,
    b: Page | PlainMessage<Page> | undefined
  ): boolean {
    return proto3.util.equals(Page, a, b)
  }
}

/**
 * @generated from message pages.v2.CreateTemplateRequest
 */
export class CreateTemplateRequest extends Message<CreateTemplateRequest> {
  /**
   * @generated from field: string page_id = 1;
   */
  pageId = ''

  /**
   * @generated from field: string org_id = 2;
   */
  orgId = ''

  /**
   * @generated from field: string workspace_id = 3;
   */
  workspaceId = ''

  /**
   * @generated from field: string name = 4;
   */
  name = ''

  /**
   * @generated from field: optional string description = 5;
   */
  description?: string

  /**
   * @generated from field: string folder_path = 6;
   */
  folderPath = ''

  /**
   * @generated from field: repeated string tags = 7;
   */
  tags: string[] = []

  constructor(data?: PartialMessage<CreateTemplateRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.CreateTemplateRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'workspace_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: 'folder_path', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'tags', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateTemplateRequest {
    return new CreateTemplateRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTemplateRequest {
    return new CreateTemplateRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateTemplateRequest {
    return new CreateTemplateRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateTemplateRequest | PlainMessage<CreateTemplateRequest> | undefined,
    b: CreateTemplateRequest | PlainMessage<CreateTemplateRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateTemplateRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.CreateTemplateResponse
 */
export class CreateTemplateResponse extends Message<CreateTemplateResponse> {
  /**
   * @generated from field: pages.v2.Template template = 1;
   */
  template?: Template

  constructor(data?: PartialMessage<CreateTemplateResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.CreateTemplateResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'template', kind: 'message', T: Template }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateTemplateResponse {
    return new CreateTemplateResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateTemplateResponse {
    return new CreateTemplateResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateTemplateResponse {
    return new CreateTemplateResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateTemplateResponse | PlainMessage<CreateTemplateResponse> | undefined,
    b: CreateTemplateResponse | PlainMessage<CreateTemplateResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateTemplateResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.ListTemplatesRequest
 */
export class ListTemplatesRequest extends Message<ListTemplatesRequest> {
  /**
   * @generated from field: string org_id = 1;
   */
  orgId = ''

  /**
   * @generated from field: string workspace_id = 2;
   */
  workspaceId = ''

  /**
   * @generated from field: string folder_id = 3;
   */
  folderId = ''

  /**
   * @generated from field: string creator_id = 5;
   */
  creatorId = ''

  /**
   * @generated from field: string name_prefix = 6;
   */
  namePrefix = ''

  /**
   * @generated from field: repeated string tags = 7;
   */
  tags: string[] = []

  /**
   * @generated from field: string visibility = 8;
   */
  visibility = ''

  /**
   * @generated from field: common.v1.PaginationArguments pagination = 4;
   */
  pagination?: PaginationArguments

  constructor(data?: PartialMessage<ListTemplatesRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.ListTemplatesRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'workspace_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'folder_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'creator_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'name_prefix', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'tags', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: 'visibility', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'pagination', kind: 'message', T: PaginationArguments }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListTemplatesRequest {
    return new ListTemplatesRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTemplatesRequest {
    return new ListTemplatesRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListTemplatesRequest {
    return new ListTemplatesRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListTemplatesRequest | PlainMessage<ListTemplatesRequest> | undefined,
    b: ListTemplatesRequest | PlainMessage<ListTemplatesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListTemplatesRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.ListTemplatesResponse
 */
export class ListTemplatesResponse extends Message<ListTemplatesResponse> {
  /**
   * @generated from field: repeated pages.v2.Template templates = 1;
   */
  templates: Template[] = []

  /**
   * @generated from field: common.v1.PaginationPageInfo page_info = 2;
   */
  pageInfo?: PaginationPageInfo

  constructor(data?: PartialMessage<ListTemplatesResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.ListTemplatesResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'templates', kind: 'message', T: Template, repeated: true },
    { no: 2, name: 'page_info', kind: 'message', T: PaginationPageInfo }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListTemplatesResponse {
    return new ListTemplatesResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListTemplatesResponse {
    return new ListTemplatesResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListTemplatesResponse {
    return new ListTemplatesResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListTemplatesResponse | PlainMessage<ListTemplatesResponse> | undefined,
    b: ListTemplatesResponse | PlainMessage<ListTemplatesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListTemplatesResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.GetTemplateRequest
 */
export class GetTemplateRequest extends Message<GetTemplateRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<GetTemplateRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.GetTemplateRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTemplateRequest {
    return new GetTemplateRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTemplateRequest {
    return new GetTemplateRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetTemplateRequest {
    return new GetTemplateRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetTemplateRequest | PlainMessage<GetTemplateRequest> | undefined,
    b: GetTemplateRequest | PlainMessage<GetTemplateRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetTemplateRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.GetTemplateResponse
 */
export class GetTemplateResponse extends Message<GetTemplateResponse> {
  /**
   * @generated from field: pages.v2.Template template = 1;
   */
  template?: Template

  constructor(data?: PartialMessage<GetTemplateResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.GetTemplateResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'template', kind: 'message', T: Template }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTemplateResponse {
    return new GetTemplateResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTemplateResponse {
    return new GetTemplateResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetTemplateResponse {
    return new GetTemplateResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetTemplateResponse | PlainMessage<GetTemplateResponse> | undefined,
    b: GetTemplateResponse | PlainMessage<GetTemplateResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetTemplateResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.UpdateTemplateRequest
 */
export class UpdateTemplateRequest extends Message<UpdateTemplateRequest> {
  /**
   * @generated from field: pages.v2.Template template = 1;
   */
  template?: Template

  /**
   * @generated from field: google.protobuf.FieldMask mask = 2;
   */
  mask?: FieldMask

  constructor(data?: PartialMessage<UpdateTemplateRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.UpdateTemplateRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'template', kind: 'message', T: Template },
    { no: 2, name: 'mask', kind: 'message', T: FieldMask }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateTemplateRequest {
    return new UpdateTemplateRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTemplateRequest {
    return new UpdateTemplateRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateTemplateRequest {
    return new UpdateTemplateRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateTemplateRequest | PlainMessage<UpdateTemplateRequest> | undefined,
    b: UpdateTemplateRequest | PlainMessage<UpdateTemplateRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateTemplateRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.UpdateTemplateResponse
 */
export class UpdateTemplateResponse extends Message<UpdateTemplateResponse> {
  /**
   * @generated from field: pages.v2.Template template = 1;
   */
  template?: Template

  constructor(data?: PartialMessage<UpdateTemplateResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.UpdateTemplateResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'template', kind: 'message', T: Template }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateTemplateResponse {
    return new UpdateTemplateResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateTemplateResponse {
    return new UpdateTemplateResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateTemplateResponse {
    return new UpdateTemplateResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateTemplateResponse | PlainMessage<UpdateTemplateResponse> | undefined,
    b: UpdateTemplateResponse | PlainMessage<UpdateTemplateResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateTemplateResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.DeleteTemplateRequest
 */
export class DeleteTemplateRequest extends Message<DeleteTemplateRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<DeleteTemplateRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.DeleteTemplateRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteTemplateRequest {
    return new DeleteTemplateRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteTemplateRequest {
    return new DeleteTemplateRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteTemplateRequest {
    return new DeleteTemplateRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteTemplateRequest | PlainMessage<DeleteTemplateRequest> | undefined,
    b: DeleteTemplateRequest | PlainMessage<DeleteTemplateRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteTemplateRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.DeleteTemplateResponse
 */
export class DeleteTemplateResponse extends Message<DeleteTemplateResponse> {
  constructor(data?: PartialMessage<DeleteTemplateResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.DeleteTemplateResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteTemplateResponse {
    return new DeleteTemplateResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteTemplateResponse {
    return new DeleteTemplateResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteTemplateResponse {
    return new DeleteTemplateResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteTemplateResponse | PlainMessage<DeleteTemplateResponse> | undefined,
    b: DeleteTemplateResponse | PlainMessage<DeleteTemplateResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteTemplateResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.CreatePageRequest
 */
export class CreatePageRequest extends Message<CreatePageRequest> {
  /**
   * @generated from field: optional string brand_kit_id = 1;
   */
  brandKitId?: string

  /**
   * @generated from field: string org_id = 2;
   */
  orgId = ''

  /**
   * @generated from field: string workspace_id = 3;
   */
  workspaceId = ''

  /**
   * @generated from field: string name = 4;
   */
  name = ''

  /**
   * @generated from field: string folder_path = 5;
   */
  folderPath = ''

  /**
   * @generated from field: repeated string tags = 6;
   */
  tags: string[] = []

  /**
   * @generated from field: google.protobuf.Struct theme = 7;
   */
  theme?: Struct

  /**
   * @generated from field: repeated pages.v2.Component components = 8;
   */
  components: Component[] = []

  /**
   * @generated from field: google.protobuf.Struct config = 9;
   */
  config?: Struct

  constructor(data?: PartialMessage<CreatePageRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.CreatePageRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'brand_kit_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'workspace_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'folder_path', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'tags', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: 'theme', kind: 'message', T: Struct },
    { no: 8, name: 'components', kind: 'message', T: Component, repeated: true },
    { no: 9, name: 'config', kind: 'message', T: Struct }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePageRequest {
    return new CreatePageRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePageRequest {
    return new CreatePageRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePageRequest {
    return new CreatePageRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreatePageRequest | PlainMessage<CreatePageRequest> | undefined,
    b: CreatePageRequest | PlainMessage<CreatePageRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreatePageRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.CreatePageResponse
 */
export class CreatePageResponse extends Message<CreatePageResponse> {
  /**
   * @generated from field: pages.v2.Page page = 1;
   */
  page?: Page

  constructor(data?: PartialMessage<CreatePageResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.CreatePageResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'page', kind: 'message', T: Page }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePageResponse {
    return new CreatePageResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePageResponse {
    return new CreatePageResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreatePageResponse {
    return new CreatePageResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreatePageResponse | PlainMessage<CreatePageResponse> | undefined,
    b: CreatePageResponse | PlainMessage<CreatePageResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreatePageResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.CreatePageFromTemplateRequest
 */
export class CreatePageFromTemplateRequest extends Message<CreatePageFromTemplateRequest> {
  /**
   * @generated from field: string template_id = 1;
   */
  templateId = ''

  /**
   * @generated from field: optional string brand_kit_id = 2;
   */
  brandKitId?: string

  /**
   * @generated from field: string org_id = 3;
   */
  orgId = ''

  /**
   * @generated from field: string workspace_id = 4;
   */
  workspaceId = ''

  /**
   * @generated from field: string name = 5;
   */
  name = ''

  /**
   * @generated from field: string folder_path = 6;
   */
  folderPath = ''

  /**
   * @generated from field: repeated string tags = 7;
   */
  tags: string[] = []

  /**
   * @generated from field: google.protobuf.Struct config = 8;
   */
  config?: Struct

  constructor(data?: PartialMessage<CreatePageFromTemplateRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.CreatePageFromTemplateRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'template_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'brand_kit_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'workspace_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'folder_path', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'tags', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: 'config', kind: 'message', T: Struct }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreatePageFromTemplateRequest {
    return new CreatePageFromTemplateRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreatePageFromTemplateRequest {
    return new CreatePageFromTemplateRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreatePageFromTemplateRequest {
    return new CreatePageFromTemplateRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreatePageFromTemplateRequest | PlainMessage<CreatePageFromTemplateRequest> | undefined,
    b: CreatePageFromTemplateRequest | PlainMessage<CreatePageFromTemplateRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreatePageFromTemplateRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.CreatePageFromTemplateResponse
 */
export class CreatePageFromTemplateResponse extends Message<CreatePageFromTemplateResponse> {
  /**
   * @generated from field: pages.v2.Page page = 1;
   */
  page?: Page

  constructor(data?: PartialMessage<CreatePageFromTemplateResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.CreatePageFromTemplateResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'page', kind: 'message', T: Page }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreatePageFromTemplateResponse {
    return new CreatePageFromTemplateResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreatePageFromTemplateResponse {
    return new CreatePageFromTemplateResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreatePageFromTemplateResponse {
    return new CreatePageFromTemplateResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreatePageFromTemplateResponse | PlainMessage<CreatePageFromTemplateResponse> | undefined,
    b: CreatePageFromTemplateResponse | PlainMessage<CreatePageFromTemplateResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreatePageFromTemplateResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.ListPagesRequest
 */
export class ListPagesRequest extends Message<ListPagesRequest> {
  /**
   * @generated from field: string org_id = 1;
   */
  orgId = ''

  /**
   * @generated from field: string workspace_id = 2;
   */
  workspaceId = ''

  /**
   * @generated from field: string folder_id = 3;
   */
  folderId = ''

  /**
   * @generated from field: string creator_id = 5;
   */
  creatorId = ''

  /**
   * @generated from field: string name_prefix = 6;
   */
  namePrefix = ''

  /**
   * @generated from field: repeated string tags = 7;
   */
  tags: string[] = []

  /**
   * @generated from field: common.v1.PaginationArguments pagination = 4;
   */
  pagination?: PaginationArguments

  constructor(data?: PartialMessage<ListPagesRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.ListPagesRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'workspace_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'folder_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'creator_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'name_prefix', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'tags', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: 'pagination', kind: 'message', T: PaginationArguments }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPagesRequest {
    return new ListPagesRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPagesRequest {
    return new ListPagesRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPagesRequest {
    return new ListPagesRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListPagesRequest | PlainMessage<ListPagesRequest> | undefined,
    b: ListPagesRequest | PlainMessage<ListPagesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListPagesRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.ListPagesResponse
 */
export class ListPagesResponse extends Message<ListPagesResponse> {
  /**
   * @generated from field: repeated pages.v2.Page pages = 1;
   */
  pages: Page[] = []

  /**
   * @generated from field: common.v1.PaginationPageInfo page_info = 2;
   */
  pageInfo?: PaginationPageInfo

  constructor(data?: PartialMessage<ListPagesResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.ListPagesResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pages', kind: 'message', T: Page, repeated: true },
    { no: 2, name: 'page_info', kind: 'message', T: PaginationPageInfo }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPagesResponse {
    return new ListPagesResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPagesResponse {
    return new ListPagesResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPagesResponse {
    return new ListPagesResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListPagesResponse | PlainMessage<ListPagesResponse> | undefined,
    b: ListPagesResponse | PlainMessage<ListPagesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListPagesResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.GetPageRequest
 */
export class GetPageRequest extends Message<GetPageRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<GetPageRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.GetPageRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPageRequest {
    return new GetPageRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPageRequest {
    return new GetPageRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPageRequest {
    return new GetPageRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetPageRequest | PlainMessage<GetPageRequest> | undefined,
    b: GetPageRequest | PlainMessage<GetPageRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetPageRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.GetPageResponse
 */
export class GetPageResponse extends Message<GetPageResponse> {
  /**
   * @generated from field: pages.v2.Page page = 1;
   */
  page?: Page

  constructor(data?: PartialMessage<GetPageResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.GetPageResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'page', kind: 'message', T: Page }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPageResponse {
    return new GetPageResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPageResponse {
    return new GetPageResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPageResponse {
    return new GetPageResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetPageResponse | PlainMessage<GetPageResponse> | undefined,
    b: GetPageResponse | PlainMessage<GetPageResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetPageResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.UpdatePageRequest
 */
export class UpdatePageRequest extends Message<UpdatePageRequest> {
  /**
   * @generated from field: pages.v2.Page page = 1;
   */
  page?: Page

  /**
   * @generated from field: google.protobuf.FieldMask mask = 2;
   */
  mask?: FieldMask

  constructor(data?: PartialMessage<UpdatePageRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.UpdatePageRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'page', kind: 'message', T: Page },
    { no: 2, name: 'mask', kind: 'message', T: FieldMask }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePageRequest {
    return new UpdatePageRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePageRequest {
    return new UpdatePageRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePageRequest {
    return new UpdatePageRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdatePageRequest | PlainMessage<UpdatePageRequest> | undefined,
    b: UpdatePageRequest | PlainMessage<UpdatePageRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdatePageRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.UpdatePageResponse
 */
export class UpdatePageResponse extends Message<UpdatePageResponse> {
  /**
   * @generated from field: pages.v2.Page page = 1;
   */
  page?: Page

  constructor(data?: PartialMessage<UpdatePageResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.UpdatePageResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'page', kind: 'message', T: Page }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePageResponse {
    return new UpdatePageResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePageResponse {
    return new UpdatePageResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdatePageResponse {
    return new UpdatePageResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdatePageResponse | PlainMessage<UpdatePageResponse> | undefined,
    b: UpdatePageResponse | PlainMessage<UpdatePageResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdatePageResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.DeletePageRequest
 */
export class DeletePageRequest extends Message<DeletePageRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<DeletePageRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.DeletePageRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePageRequest {
    return new DeletePageRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePageRequest {
    return new DeletePageRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletePageRequest {
    return new DeletePageRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeletePageRequest | PlainMessage<DeletePageRequest> | undefined,
    b: DeletePageRequest | PlainMessage<DeletePageRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeletePageRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.DeletePageResponse
 */
export class DeletePageResponse extends Message<DeletePageResponse> {
  constructor(data?: PartialMessage<DeletePageResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.DeletePageResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePageResponse {
    return new DeletePageResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePageResponse {
    return new DeletePageResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeletePageResponse {
    return new DeletePageResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeletePageResponse | PlainMessage<DeletePageResponse> | undefined,
    b: DeletePageResponse | PlainMessage<DeletePageResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeletePageResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.LoadPageRequest
 */
export class LoadPageRequest extends Message<LoadPageRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<LoadPageRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.LoadPageRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoadPageRequest {
    return new LoadPageRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoadPageRequest {
    return new LoadPageRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoadPageRequest {
    return new LoadPageRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: LoadPageRequest | PlainMessage<LoadPageRequest> | undefined,
    b: LoadPageRequest | PlainMessage<LoadPageRequest> | undefined
  ): boolean {
    return proto3.util.equals(LoadPageRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.LoadPageResponse
 */
export class LoadPageResponse extends Message<LoadPageResponse> {
  /**
   * @generated from field: pages.v2.Page page = 1;
   */
  page?: Page

  /**
   * @generated from field: google.protobuf.Struct data = 2;
   */
  data?: Struct

  constructor(data?: PartialMessage<LoadPageResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.LoadPageResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'page', kind: 'message', T: Page },
    { no: 2, name: 'data', kind: 'message', T: Struct }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoadPageResponse {
    return new LoadPageResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoadPageResponse {
    return new LoadPageResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoadPageResponse {
    return new LoadPageResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: LoadPageResponse | PlainMessage<LoadPageResponse> | undefined,
    b: LoadPageResponse | PlainMessage<LoadPageResponse> | undefined
  ): boolean {
    return proto3.util.equals(LoadPageResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.SavePageRequest
 */
export class SavePageRequest extends Message<SavePageRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: google.protobuf.Struct config = 2;
   */
  config?: Struct

  /**
   * @generated from field: google.protobuf.Struct data = 3;
   */
  data?: Struct

  constructor(data?: PartialMessage<SavePageRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.SavePageRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'config', kind: 'message', T: Struct },
    { no: 3, name: 'data', kind: 'message', T: Struct }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SavePageRequest {
    return new SavePageRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SavePageRequest {
    return new SavePageRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SavePageRequest {
    return new SavePageRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: SavePageRequest | PlainMessage<SavePageRequest> | undefined,
    b: SavePageRequest | PlainMessage<SavePageRequest> | undefined
  ): boolean {
    return proto3.util.equals(SavePageRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.SavePageResponse
 */
export class SavePageResponse extends Message<SavePageResponse> {
  /**
   * @generated from field: pages.v2.Page page = 1;
   */
  page?: Page

  /**
   * @generated from field: google.protobuf.Struct data = 2;
   */
  data?: Struct

  constructor(data?: PartialMessage<SavePageResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.SavePageResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'page', kind: 'message', T: Page },
    { no: 2, name: 'data', kind: 'message', T: Struct }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SavePageResponse {
    return new SavePageResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SavePageResponse {
    return new SavePageResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SavePageResponse {
    return new SavePageResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: SavePageResponse | PlainMessage<SavePageResponse> | undefined,
    b: SavePageResponse | PlainMessage<SavePageResponse> | undefined
  ): boolean {
    return proto3.util.equals(SavePageResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.PostPageRequest
 */
export class PostPageRequest extends Message<PostPageRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: google.protobuf.Struct data = 2;
   */
  data?: Struct

  constructor(data?: PartialMessage<PostPageRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.PostPageRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'data', kind: 'message', T: Struct }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostPageRequest {
    return new PostPageRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostPageRequest {
    return new PostPageRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostPageRequest {
    return new PostPageRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: PostPageRequest | PlainMessage<PostPageRequest> | undefined,
    b: PostPageRequest | PlainMessage<PostPageRequest> | undefined
  ): boolean {
    return proto3.util.equals(PostPageRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.PostPageResponse
 */
export class PostPageResponse extends Message<PostPageResponse> {
  /**
   * @generated from field: pages.v2.Page page = 1;
   */
  page?: Page

  /**
   * @generated from field: google.protobuf.Struct data = 2;
   */
  data?: Struct

  constructor(data?: PartialMessage<PostPageResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.PostPageResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'page', kind: 'message', T: Page },
    { no: 2, name: 'data', kind: 'message', T: Struct }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostPageResponse {
    return new PostPageResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostPageResponse {
    return new PostPageResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostPageResponse {
    return new PostPageResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: PostPageResponse | PlainMessage<PostPageResponse> | undefined,
    b: PostPageResponse | PlainMessage<PostPageResponse> | undefined
  ): boolean {
    return proto3.util.equals(PostPageResponse, a, b)
  }
}
