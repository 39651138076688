import { IS_CI } from '@dtx-company/true-common/src/constants/env'
import { IncomingMessage, ServerResponse } from 'http'
import { PageProvidersProps } from '../components/PageProviders'
import { ParsedUrlQuery } from 'querystring'
import { getInitialSessionAndOptimizelyValues } from './getInitialSessionAndOptimizelyValues'
import { serialize } from 'cookie'
import { writeLinkHeader } from './writeLinkHeader'

export async function getPageProvidersProps(
  req: IncomingMessage | undefined,
  query: ParsedUrlQuery | undefined
): Promise<PageProvidersProps> {
  return getInitialSessionAndOptimizelyValues(req, query)
}

export function writePageResponseHeaders(
  req: IncomingMessage | undefined,
  res: ServerResponse | undefined
): void {
  if (req && res) {
    writeLinkHeader(req, res)
  }
}

export function setLastPlatformUsedCookie(
  req: IncomingMessage | undefined,
  res: ServerResponse | undefined
): void {
  const secure = !(IS_CI || process.env.APP_ENV === 'development')
  const domain = secure ? 'flowcode.com' : 'localhost'

  try {
    const cookie = serialize('fc_last_platform_used', 'f1', {
      domain,
      httpOnly: false,
      path: '/',
      secure,
      sameSite: 'lax',
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) // 1 year
    })
    res?.setHeader('Set-Cookie', cookie)
  } catch (err) {
    return
  }
}
