import { Link, LinkInfoFragment } from '@dtx-company/flow-codegen/src/page/generated.types'
import {
  bgImageUrl,
  profileImageUrl,
  removeImageUrl,
  thumbnailImageUrl,
  uploadMediaUrl,
  uploadPdfUrl
} from '@dtx-company/true-common/src/constants/page'
import { dataURItoBlob, isFileString, isObjectURL } from '@dtx-company/true-common/src/utils/blob'
import fetch from 'isomorphic-unfetch'

export async function getIPAddress(): Promise<string> {
  const response = await fetch('https://api.ipify.org?format=json')
  const data = await response.json()
  return data?.ip
}

export async function uploadAvatar(
  encodedImage: string,
  pageId: string,
  token: string | undefined
): Promise<void> {
  const formData = new FormData()
  if (isObjectURL(encodedImage)) {
    const imageBlob = await fetch(encodedImage).then(r => r.blob())
    formData.append('image', imageBlob)
  } else {
    formData.append('image', dataURItoBlob(encodedImage))
  }
  formData.append('pageId', pageId)
  const res = await fetch(profileImageUrl, {
    method: 'POST',
    body: formData,
    headers: {
      authorization: `Bearer ${token}`
    }
  })
  if (res.status === 413) {
    throw Error('File is too big')
  }
}
export async function uploadLinkThumbnail(
  linkId: string,
  encodedImage: string,
  token: string | undefined,
  isStorageUrl = false
): Promise<void> {
  const formData = new FormData()
  if (isObjectURL(encodedImage) || isStorageUrl) {
    const imageBlob = await fetch(encodedImage).then(r => r.blob())
    formData.append('image', imageBlob)
  } else {
    formData.append('image', dataURItoBlob(encodedImage))
  }
  formData.append('id', linkId)
  const res = await fetch(thumbnailImageUrl, {
    method: 'POST',
    body: formData,
    headers: {
      authorization: `Bearer ${token}`
    }
  })
  if (res.status === 413) {
    throw Error('File is too big')
  }
}

export async function uploadLinkThumbnails(
  links: Link[],
  token: string | undefined
): Promise<void> {
  await Promise.all(
    links.map(
      async ({ id, thumbNailImgUrl }) =>
        thumbNailImgUrl && (await uploadLinkThumbnail(id, thumbNailImgUrl, token))
    )
  )
}

export async function uploadChildlinkThumbnails(
  token: string | undefined,
  link?: LinkInfoFragment
): Promise<void> {
  await Promise.all(
    (link?.childLinks ?? []).map(async ({ id, provider }) => {
      const thumbNailImgUrl = link?.actionData[provider]?.thumbNailImgUrl
      if (thumbNailImgUrl && isFileString(thumbNailImgUrl)) {
        await uploadLinkThumbnail(id, thumbNailImgUrl, token)
      }
    })
  )
}

export async function deleteChildlinkThumbnails(
  token: string | undefined,
  linkIds: string[]
): Promise<void> {
  await Promise.all(
    linkIds.map(async id => {
      return deleteLinkThumbnail(id, token, true)
    })
  )
}

export async function deleteImage(
  id: string,
  type: string,
  token: string | undefined,
  childLink = false
): Promise<boolean> {
  const res = await fetch(
    removeImageUrl +
      '?' +
      new URLSearchParams({
        type,
        id,
        ...(childLink &&
          type === 'link' && {
            child: 'true'
          })
      }),
    {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  )
  if (res.status === 200) {
    return true
  }
  return false
}

export async function deleteAvatar(pageId: string, token: string | undefined): Promise<boolean> {
  return await deleteImage(pageId, 'avatar', token)
}
export async function deleteLinkThumbnail(
  id: string,
  token: string | undefined,
  childLink = false
): Promise<boolean> {
  return await deleteImage(id, 'link', token, childLink)
}

export async function uploadPdf(
  linkId: string,
  file: string,
  token: string | undefined
): Promise<{ link?: string; error?: string }> {
  const formData = new FormData()
  formData.append('id', linkId)
  if (isObjectURL(file)) {
    try {
      const pdfBlob = await fetch(file).then(r => r.blob())
      formData.append('pdf', pdfBlob)
    } catch {
      throw Error('File does not exist')
    }
  } else {
    formData.append('pdf', dataURItoBlob(file))
  }
  const res = await fetch(uploadPdfUrl, {
    method: 'POST',
    body: formData,
    headers: {
      authorization: `Bearer ${token}`
    }
  })

  const data = await res.json()
  if (res.status === 413) {
    throw Error('File is too big')
  }

  return data
}

export async function uploadMediaFile(
  linkId: string,
  file: string,
  token: string | undefined
): Promise<{ link?: string; error?: string }> {
  const formData = new FormData()
  formData.append('id', linkId)
  if (isObjectURL(file)) {
    const fileBlob = await fetch(file).then(r => r.blob())
    formData.append('media', fileBlob)
  } else {
    formData.append('media', dataURItoBlob(file))
  }
  const res = await fetch(uploadMediaUrl, {
    method: 'POST',
    body: formData,
    headers: {
      authorization: `Bearer ${token}`
    }
  })

  const data = await res.json()
  if (res.status === 413) {
    throw Error('File is too big')
  }
  return data
}

export async function blobFromUrl(blob: string, contentType: string): Promise<Blob | null> {
  const res = await fetch(blob)
  return new Blob([await res.blob()], { type: contentType })
}

export async function uploadBackgroundImage(
  pageId: string,
  encodedImage: string,
  token: string | undefined
): Promise<{ imageUrl: string }> {
  const formData = new FormData()
  if (isObjectURL(encodedImage)) {
    const imageBlob = await fetch(encodedImage).then(r => r.blob())
    formData.append('image', imageBlob)
  } else {
    formData.append('image', dataURItoBlob(encodedImage))
  }
  formData.append('pageId', pageId)
  const res = await fetch(bgImageUrl, {
    method: 'POST',
    body: formData,
    headers: {
      authorization: `Bearer ${token}`
    }
  })
  if (res.status === 413) {
    throw Error('File is too big')
  }
  return res.json()
}

export async function deleteBackgroundImage(
  pageId: string,
  token: string | undefined
): Promise<boolean> {
  return await deleteImage(pageId, 'background', token)
}
