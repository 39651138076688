import { APP_BASE_URL, WWW_BASE_URL } from '../utils/urls'

export const FLOWCODE_ROOT = APP_BASE_URL
export const FLOWCODE_HOST = FLOWCODE_ROOT.replace('https://', '')
export const FLOWCODE_SUBDOMAIN = FLOWCODE_HOST.split('.')[0]

export const WWW_ROOT = WWW_BASE_URL
export const WWW_HOST = WWW_BASE_URL.replace('https://', '')

export const CDN_ROOT = 'https://cdn.flowcode.com'
export const CDN_ASSET_ROOT = `${CDN_ROOT}/prodassets/`
