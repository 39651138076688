import { CodeConfigurationField } from '@dtx-company/inter-app/src/types/flowcode'
import { InterpreterFrom } from 'xstate'
import { LocgContext } from './locgMachine.types'
import { PartialDeep } from 'type-fest'
import { createLocgMachine } from './locgMachine'
import { deepmerge } from '@mui/utils'
import { useInterpret } from '@xstate/react'
import useEnhancedEffect from '@mui/utils/useEnhancedEffect'
import useTranslation from 'next-translate/useTranslation'

export const useSetupLocgService = ({
  isMobile: locgDesignFirst
}: {
  isMobile?: boolean
}): InterpreterFrom<ReturnType<typeof createLocgMachine>> => {
  const { lang } = useTranslation()

  const locgMachine = createLocgMachine({
    initialState: locgDesignFirst ? 'codeDesign' : 'scanDestination'
  })

  const locgMachineInitialContext: PartialDeep<LocgContext> = {
    designFirst: locgDesignFirst,
    ...(locgDesignFirst && {
      values: {
        [CodeConfigurationField.FRAME]: true,
        [CodeConfigurationField.TOP_RIM_TEXT]: 'SCAN HERE',
        [CodeConfigurationField.BOTTOM_RIM_TEXT]: 'SCAN HERE'
      }
    })
  }

  const locgService = useInterpret(
    locgMachine.withContext(deepmerge(locgMachine.context, locgMachineInitialContext)),
    {
      devTools: process.env.APP_ENV === 'development'
    }
  )

  useEnhancedEffect(() => {
    // Pass the locale to the state machines to make it available in the context
    // so next-translate/getT can be used outside of React components
    locgService.send({ type: 'SET_LOCALE', locale: lang })
  }, [lang])

  return locgService
}
