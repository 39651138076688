import { APP_ENV, IS_CYPRESS, IS_REVIEW_APP, PR_NUMBER } from '../../constants/env'
import { getFlowcodeHost } from './host'

export function getWWWBaseUrl(): string {
  if (process.env.IS_SMOKETEST === 'true') {
    return `https://smoketest.flowcode.com`
  }

  if (IS_REVIEW_APP) {
    //running in a review app
    return `https://flow-app-${PR_NUMBER}.reviewapps.flowcode.com`
  }

  if (APP_ENV === 'development' || IS_CYPRESS) {
    return 'http://localhost:3000'
  }

  return `https://${getFlowcodeHost()}`
}
