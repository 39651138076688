// Maintaining existing behavior, script was added before the rule
/* eslint-disable @next/next/no-sync-scripts */
import Head from 'next/head'

type OptimizelyWebScriptProps = {
  // key value for _document to reorder to bottom of head tag
  key: 'OptimizelyWebScript'
}

// props.key should be enforced as it is used to reorder script at render time
export const OptimizelyWebScript = (_props: OptimizelyWebScriptProps): JSX.Element => {
  return (
    <Head>
      {/* `holdEvents` causes optimizely to buffer events until
            `sendEvents` is pushed, which will occur after GDPR opt-in */}
      {/* OptimizelyWebScript must be rendered after OptimizelyWebId */}
      <script
        id="optimizely-web-config"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
               if (window.optimizelyEndUserID === undefined) {
                  console.warn("optimizelyEndUserID is not set");
               }
               window["optimizely"] = window["optimizely"] || [];
               window["optimizely"].push({type: "holdEvents"});
            `
        }}
      />
      {/* blocking script will likely introduce latency on the web experiment,
            optimizely snippet is non-trivial in size */}
      <script id="optimizely-web-script" src="https://cdn.optimizely.com/js/20907390072.js" />
    </Head>
  )
}
