import { refreshFronteggTokens } from '@dtx-company/ithaca-sdk/src'
import { useCallback } from 'react'
import { useHandleFronteggAccessToken } from './useListenForAuthChanges'

export function useRefreshJWT(args = { updateAppStateSynchronously: true }): () => Promise<void> {
  const handleFronteggAccessToken = useHandleFronteggAccessToken()
  const { updateAppStateSynchronously } = args
  return useCallback(async () => {
    /* 
      If updateAppStateSynchronously = true, then we dispatch the new JWT to the redux store synchronously inside of
      handleFronteggAccessToken, before returning from the callback. 
      
      If updateAppStateSynchronously = false, then we send the JWT via an observer event to the redux store,
      which may or may not update the store before the callback returns
    */
    const shouldNotify = !updateAppStateSynchronously
    const { accessToken } = await refreshFronteggTokens(shouldNotify)
    if (accessToken && updateAppStateSynchronously) {
      await handleFronteggAccessToken(accessToken)
    }
  }, [updateAppStateSynchronously, handleFronteggAccessToken])
}
