import { useEffect, useRef } from 'react'

/**
 * Executes the given callback once, and only once the conditional value
 * is true.
 * If the conditional value is not given, it will execute once immediately.
 *
 * This is helpful for things like analytics events that track when a user
 * visits a particular page or opens a particular modal to attach the event
 * to the act of it opening rather than to every place it can be opened from.
 *
 * @example
 * useOnce(() => {
 *   fireHeapEvent(...)
 * })
 *
 * @example
 * useOnce(() => {
 *   if (!foo || !bar) return false
 *   fireHeapEvent(..., { foo, bar })
 * })
 *
 * @example
 * useOnce(() => {
 *   if (!foo || !bar) return false
 *   const defaultValue = getDefaultValue({ foo, bar })
 *   setValue(defaultValue)
 * })
 */
export function useOnce(callback: () => false | void): void {
  // BUGFIX: this used to use useState() but after 14mos of working fine noticed
  // a case with PlanPurchaseForm where it would execute twice every time
  // (firing userStartedProConversion twice) so switching to useRef to be
  // absolutely sure it won't execute twice
  const hasFiredRef = useRef(false)
  useEffect(() => {
    if (hasFiredRef.current) return
    const executed = callback()
    if (executed !== false) {
      hasFiredRef.current = true
    }
  }, [callback])
}
