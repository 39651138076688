import { useCookieState } from '../../redux/selectors/useCookieState'
import { useEffect, useState } from 'react'
import { useHeapSessionId } from '@dtx-company/true-common/src/utils/cookies/cookies.hooks'

// GDPR: Unblock Optimizely-Web events once the heapSessionId
// and the browserSessionIdLoaded are true
// This is paired with an initial state in a head script
// window.optimizely?.push({
//    type: 'holdEvents'
// })

export const useOptimizelyWebEvents = (): void => {
  const heapSessionId = useHeapSessionId()
  const { browserSessionIdLoaded } = useCookieState()
  const [buffering, setBuffing] = useState(true)

  useEffect(() => {
    if (buffering && heapSessionId && browserSessionIdLoaded) {
      setBuffing(false)

      window.optimizely?.push({
        type: 'user',
        attributes: {
          heapSessionId: heapSessionId
        }
      })

      window.optimizely?.push({
        type: 'sendEvents'
      })
    }
  }, [browserSessionIdLoaded, buffering, heapSessionId])
}
