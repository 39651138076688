import { ConfirmationAction } from './utils'
import { Field, Form, Page } from '../../types/form.types'
import { FieldType } from '@dtx-company/connect-codegen/src/gen/forms/v1/field_pb'
import { isValidUrl } from '@dtx-company/inter-app/src/utils/isValidUrl/isValidUrl'

export interface ValidationError {
  message: string
}

export const ERROR_MSG_MISSING_VALUE = 'Missing field value'
export const ERROR_MSG_INVALID_VALUE = 'Invalid field value'
export const ERROR_MSG_UNIQUE_NAME = 'Field name is not unique'

export enum ValidatorType {
  SETTINGS_CONFIRMATION,
  THEME_BACKGROUND_IMAGE
}

export interface Validator {
  path: string
  description: string
  required: boolean
  validatorType: ValidatorType
}

export const SUCCESS_ACTION_FIELD_PATH = 'form.successAction'
export const THEME_BACKGROUND_IMAGE_PATH = 'page.config.background.imageSrc'

export const settingsValidatorTypes: Record<string, Validator> = {
  [SUCCESS_ACTION_FIELD_PATH]: {
    path: SUCCESS_ACTION_FIELD_PATH,
    description: 'Settings for success action',
    required: false,
    validatorType: ValidatorType.SETTINGS_CONFIRMATION
  },
  [THEME_BACKGROUND_IMAGE_PATH]: {
    path: THEME_BACKGROUND_IMAGE_PATH,
    description: 'Theme background',
    required: false,
    validatorType: ValidatorType.THEME_BACKGROUND_IMAGE
  }
}

const REDIRECT_PATH = `${ConfirmationAction.REDIRECT}.url`
const VIEW_RESULTS_PATH = `${ConfirmationAction.VIEW_LIVE_RESULTS}.fieldNames`
const THANK_YOU_PATH = `${ConfirmationAction.THANK_YOU}.title`

export const globalValidators: Map<ValidatorType, (values: any[]) => boolean> = new Map([
  [
    ValidatorType.SETTINGS_CONFIRMATION,
    (values: any[]): boolean => {
      const actionValue = values[0]
      const getValue = valueGetter(actionValue)
      if (actionValue?.[ConfirmationAction.REDIRECT]) {
        const value = getValue(REDIRECT_PATH)
        return value ? isValidUrl(value) : false
      } else if (actionValue?.[ConfirmationAction.VIEW_LIVE_RESULTS]) {
        const value = getValue(VIEW_RESULTS_PATH)
        return value && value.length > 0
      } else if (actionValue?.[ConfirmationAction.THANK_YOU]) {
        const value = getValue(THANK_YOU_PATH)
        return value && value.length > 0
      }
      return true
    }
  ],
  [
    ValidatorType.THEME_BACKGROUND_IMAGE,
    (values: any[]): boolean => {
      const actionValue = values[0]
      return actionValue ? isValidUrl(actionValue) : true
    }
  ]
])

export const fieldValidators: Map<FieldType, (values: any[]) => boolean> = new Map([
  [
    FieldType.BLOCK_IMAGE,
    (values: any[]): boolean => {
      const field = values[0] as Field
      const link = field.properties?.image?.imageUrl
      return link ? isValidUrl(link) : false
    }
  ],
  [
    FieldType.LEGAL,
    (values: any[]): boolean => {
      const field = values[0] as Field
      const link = field.properties?.legal?.termsLink
      return link ? isValidUrl(link) : false
    }
  ]
])

export const valueGetter = (data: Record<string, any>): ((path: string) => any) => {
  return (pathString: string): any => {
    const path = pathString.split('.')
    let obj = { ...data }
    for (let i = 0; i < path.length; i++) {
      obj = obj?.[path[i]]
    }
    return obj
  }
}

export const validateSettings = (data: Record<string, any>): Record<string, ValidationError> => {
  const getValue = valueGetter(data)
  const hasValue = (value: any): boolean => Boolean(value)
  const validationErrors: Record<string, ValidationError> = {}
  Object.keys(settingsValidatorTypes).forEach(key => {
    const validator = settingsValidatorTypes[key]
    const value = getValue(key)
    if (validator.required && !hasValue(value)) {
      validationErrors[key] = { message: ERROR_MSG_MISSING_VALUE }
    }
    const valueValidator = globalValidators.get(validator.validatorType)
    if (valueValidator) {
      const valid = valueValidator?.(Array.isArray(value) ? value : [value])
      if (!valid) {
        validationErrors[key] = { message: ERROR_MSG_INVALID_VALUE }
      }
    }
  })
  return validationErrors
}

export const isFieldValid = (field: Field): boolean => {
  const validator = fieldValidators.get(field.fieldType)
  if (validator) {
    return validator([field])
  }
  return true
}

export const isNameValid = (field: number, form: Form): boolean => {
  const fieldName = form.fields[field].name
  const hasSameName = form.fields.find((f, id) => id != field && f.name == fieldName)
  return fieldName.trim().length > 0 && !hasSameName
}

export const validateFields = (form: Form): Record<string, ValidationError> => {
  const validationErrors: Record<string, ValidationError> = {}
  form.fieldsOrder.forEach(f => {
    const field = form.fields[f]
    const hasValidName = isNameValid(f, form)
    const isValid = hasValidName && isFieldValid(field)
    if (!isValid) {
      validationErrors[f] = { message: ERROR_MSG_INVALID_VALUE }
    }
  })
  return validationErrors
}

export const validateAll = ({
  form,
  page
}: {
  form: Form
  page: Page
}): Record<string, ValidationError> => {
  const fieldErrors = validateFields(form)
  const settingsErrors = validateSettings({ form, page })
  return { ...fieldErrors, ...settingsErrors }
}
