import { LandingPageDestination } from '@dtx-company/inter-app/src/types/flowcode'
import { RegionCode } from '@dtx-company/inter-app/src/constants/flagsByRegion'
import { getAsYouType } from 'awesome-phonenumber'

const protocol = 'https://'
const protocolParts = protocol.split('')

export const potentialPrefixes = protocolParts.reduce<string[]>((acc, _part, index) => {
  return [...acc, protocol.slice(0, index + 1)]
}, [])

const normalizeUrl = (trimmedValue: string): string => {
  if (potentialPrefixes.some(prefix => trimmedValue.startsWith(prefix))) {
    return trimmedValue
  }
  return `${protocol}${trimmedValue}`
}

/** Add the '@' symbol to scan destinations that need it like Instagram and Youtube */
const normalizeHandleValue = (value: string): string => {
  if (potentialPrefixes.some(prefix => value.startsWith(prefix))) {
    return value
  }
  if (value[0] !== '@') {
    return `@${value}`
  }
  return value
}

export const normalizeDestination = ({
  scanDestinationType,
  value,
  regionCode
}: {
  scanDestinationType: LandingPageDestination
  value: string
  regionCode?: RegionCode
}): string => {
  if (!value) return value
  const trimmedValue = value.trim()
  if (trimmedValue.length === 0) return trimmedValue

  switch (scanDestinationType) {
    case LandingPageDestination.INSTAGRAM:
    case LandingPageDestination.YOUTUBE:
      return normalizeHandleValue(trimmedValue)
    case LandingPageDestination.URL:
    case LandingPageDestination.WEBSITE:
      return normalizeUrl(trimmedValue)
    case LandingPageDestination.EMAIL:
      return trimmedValue.toLocaleLowerCase()
    case LandingPageDestination.SMS: {
      const ayt = getAsYouType(regionCode || 'US')
      ayt.reset(trimmedValue)
      return ayt.getPhoneNumber().number?.national ?? ayt.number()
    }
    default:
      return trimmedValue
  }
}
