import { Components, Theme } from '@mui/material'

/**
 * MUI Theme overrides for Flowcode's [Switch](./Switch.tsx) component.
 */
export const switchComponentOverrides: Components<Theme> = {
  MuiSwitch: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        height: theme.spacing(10),
        width: theme.spacing(15),
        padding: theme.spacing(1),
        '&.Mui-checked': {
          svg: { opacity: 1 }
        },
        '&.Mui-disabled': {
          svg: { opacity: 0.38 }
        },
        ...(ownerState.readOnly && {
          '& .MuiSwitch-track': {
            backgroundColor: theme.palette.action.disabled,
            border: 'none',
            opacity: 1
          }
        })
      }),
      sizeSmall: ({ theme }) => ({
        height: theme.spacing(7.5),
        width: theme.spacing(12),
        padding: theme.spacing(0.75),
        '& .MuiSwitch-switchBase': {
          padding: theme.spacing(1.75),
          '&.MuiSwitch-switchBase.Mui-checked': {
            padding: theme.spacing(1.0495)
          }
        },
        '& .MuiSwitch-track': {
          height: theme.spacing(6),
          width: theme.spacing(10)
        },
        '& .MuiSvgIcon-root.circleIcon': {
          fontSize: theme.spacing(4)
        }
      }),
      switchBase: ({ theme, ownerState }) => ({
        padding: theme.spacing(2.5),
        '&:hover': {
          backgroundColor: ownerState.readOnly ? 'unset' : theme.palette.shades.primary.hover
        },
        '&:focus, &:active': {
          backgroundColor: ownerState.readOnly ? 'unset' : theme.palette.shades.primary.focus
        },
        '&.Mui-checked': {
          padding: theme.spacing(1.398625)
        },
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: theme.palette.action.disabled,
          border: 'none',
          opacity: 1
        },
        '& .MuiSvgIcon-root.svgIcon-checked': {
          height: theme.spacing(7.20275), // 28.811px
          width: theme.spacing(7.20275),
          '&.MuiSvgIcon-fontSizeSmall': {
            height: theme.spacing(5.402375), // 21.6095px
            width: theme.spacing(5.402375)
          }
        },
        '&.Mui-disabled .MuiSvgIcon-root': {
          color: theme.palette.onSurface.disabled,
          '&.svgIcon-checked ': {
            color: theme.palette.surface['0']
          }
        },
        ...(ownerState.readOnly && {
          cursor: 'not-allowed'
        })
      }),

      track: ({ theme }) => ({
        height: theme.spacing(8),
        width: theme.spacing(14),
        boxSizing: 'border-box',
        borderRadius: theme.spacing(25),
        backgroundColor: theme.palette.onSurface.inverse,
        border: `2px solid ${theme.palette.primary.main}`,
        opacity: 1
      })
    }
  }
}
