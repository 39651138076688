import { ParsedUrlQueryInput } from 'querystring'
import { getUtmParams } from '../utils/url'
import { useCanWriteToBrowserStorage } from './useCanWriteToBrowserStorage'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

export function usePersistUtmParams(): void {
  const router = useRouter()
  const [utmParams, setUtmParams] = useState<ParsedUrlQueryInput>({})

  useEffect(() => {
    const params = getUtmParams(router.query)
    if (Object.keys(params).length > 0) {
      setUtmParams(params)
    }
  }, [router.query])

  const canPersist = useCanWriteToBrowserStorage()

  useEffect(() => {
    if (canPersist) {
      sessionStorage.setItem('utmParams', JSON.stringify(utmParams))
      //storage events are only fired for other tabs. to get a storage event in this tab, we need to manually fire it.
      //event is fired so that useUtmParamsFromSessionStart knows to read fresh utm values from sessionStorage
      global.window.dispatchEvent(new Event('storage'))
    }
  }, [canPersist, utmParams])
}
