import { User as IthacaUser } from '@dtx-company/ithaca-sdk/src'

export type User = IthacaUser | null
export enum UserRoles {
  NO_ACCOUNT = 0,
  FREE = 1,
  UNLIMITED = 2,
  FLOWCODE_100 = 3, // Deprecated. All Users with this role are treated as Pro users.
  PRO = 4,
  FREE_UNLIMITED = 10
}

export enum UserRBAC {
  ENTERPRISE = 'enterprise',
  PRO_FLEX = 'pro_flex'
}
