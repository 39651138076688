import { Cookie, CookieClassification, CookieMap } from '../../types/cookies'

export const FUNCTIONAL_COOKIE_PATTERN = /C0002/gi

export const HEAP_COOKIE = '_hp2_id.' + process.env.HEAP_ID

/**
 * Only pull cookie names from process.env if they differ per environment
 * Flexibility to define env specific cookies by decoupling name from value
 
 * @todo - Nuke: _vote cookie and push through redux
 * @todo - Nuke: FLOWCODE_PRO_OFFER and the entire component using it
 * @todo - Nuke: analytics waitlist 
 * @todo - Maybe Nuke: Pro Waitlist
 */
export const AllCookies: CookieMap = {
  [Cookie.ADMIN_TOKEN]: {
    name: Cookie.ADMIN_TOKEN,
    type: CookieClassification.strictlyNecessary,
    defaultValue: ''
  },
  [Cookie.ANONYMOUS_SESSION_ID]: {
    name: Cookie.ANONYMOUS_SESSION_ID,
    type: CookieClassification.strictlyNecessary,
    defaultValue: ''
  },
  [Cookie.BROWSER_SESSION_ID]: {
    name: 'BROWSER_SESSION_ID',
    type: CookieClassification.functional,
    defaultValue: ''
  },
  [Cookie.FLOWCODE_PRO_OFFER]: {
    name: 'FLOWCODE_PRO_OFFER',
    type: CookieClassification.functional,
    defaultValue: ''
  },
  [Cookie.LOGGED_IN]: {
    name: Cookie.LOGGED_IN,
    type: CookieClassification.strictlyNecessary,
    defaultValue: 'false'
  },
  [Cookie.OPT_OUT]: {
    name: 'OPT_OUT',
    type: CookieClassification.strictlyNecessary,
    defaultValue: 'false'
  },
  [Cookie.REDIRECT_SERVICE_COOKIE]: {
    name: 'rdservice',
    type: CookieClassification.functional,
    defaultValue: ''
  },
  [Cookie.analytics_waitlist]: {
    name: 'analytics_waitlist',
    type: CookieClassification.functional,
    defaultValue: ''
  },
  [Cookie.pro_waitlist]: {
    name: 'pro_waitlist',
    type: CookieClassification.functional,
    defaultValue: ''
  },
  [Cookie.vote_]: {
    name: 'vote_',
    type: CookieClassification.strictlyNecessary,
    defaultValue: ''
  },
  [Cookie.isBannerVisibleCookie]: {
    name: 'isBannerVisibleCookie',
    type: CookieClassification.functional,
    defaultValue: '0'
  },
  [Cookie.token]: {
    name: 'token',
    type: CookieClassification.strictlyNecessary,
    defaultValue: ''
  },
  [Cookie.FLOW_LEADS_COOKIE]: {
    name: 'token',
    type: CookieClassification.strictlyNecessary,
    defaultValue: ''
  },
  [Cookie.TV_TOOL_COOKIE]: {
    name: 'token',
    type: CookieClassification.strictlyNecessary,
    defaultValue: ''
  },
  [Cookie.NBA_ID_SESSION]: {
    name: Cookie.NBA_ID_SESSION,
    type: CookieClassification.strictlyNecessary,
    defaultValue: ''
  },
  [Cookie.COUNTRY_CODE]: {
    name: Cookie.COUNTRY_CODE,
    type: CookieClassification.strictlyNecessary,
    defaultValue: ''
  },
  [Cookie.DISMISSED_DATA_VIA_EXPLORE_API_SECTION]: {
    name: Cookie.DISMISSED_DATA_VIA_EXPLORE_API_SECTION,
    type: CookieClassification.strictlyNecessary,
    defaultValue: ''
  },
  [Cookie.USER_HAS_GENERATED_ANALYTICS_INSIGHTS]: {
    name: Cookie.USER_HAS_GENERATED_ANALYTICS_INSIGHTS,
    type: CookieClassification.strictlyNecessary,
    defaultValue: ''
  }
}
export const AllCookiesValues = Object.values(AllCookies)
