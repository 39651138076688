import { GetUserInfoResponseType } from '@app/code/src/types/user.types'
import { USER_INFO_QUERY } from '@app/code/src/gql/queries/user'
import { gqlFetcher } from '@dtx-company/inter-app/src/services/gqlFetcher'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import useSWR, { SWRResponse } from 'swr'
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation'

/**
 * Fetch the user info automatically component mount as opossed to {@link useUserInfoMutation}
 */
export const useUserInfo = (): SWRResponse<GetUserInfoResponseType, Error> => {
  const { token } = useAuthState()
  const data = useSWR(
    () => (token ? [USER_INFO_QUERY, token] : null),
    ([query, token]) => gqlFetcher<GetUserInfoResponseType>(query, undefined, undefined, token),
    { revalidateOnFocus: true, revalidateIfStale: true, revalidateOnMount: true }
  )
  return data
}

/**
 * Use this when needing to fetch the user info manually like when the user clicks a button
 * as oposed to on component mount done by {@link useUserInfo}
 *
 * @see https://swr.vercel.app/docs/mutation#useswrmutation
 */
export const useUserInfoMutation = (): SWRMutationResponse<GetUserInfoResponseType, Error> => {
  const { token } = useAuthState()
  return useSWRMutation(
    () => (token ? [USER_INFO_QUERY, token] : null),
    ([query, token]) => gqlFetcher<GetUserInfoResponseType>(query, undefined, undefined, token)
  )
}
