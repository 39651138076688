import Head from 'next/head'

export interface OptimizelyWebIdProps {
  sessionId: string
}

export const OptimizelyWebId = (props: OptimizelyWebIdProps): JSX.Element => {
  return (
    <Head>
      {/* The id's of the scripts below are a contract with the CDN web-worker */}
      {/* window.optimizelyEndUserID is updated on the CDN web-worker
          the value should be set to match BROWSER_SESSION_ID */}
      <script
        id="optimizely-web-id"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
             window.optimizelyEndUserID = "${props.sessionId}";
          `
        }}
      />
    </Head>
  )
}
