// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file pages/v2/component.proto (package pages.v2, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { Message, proto3, Struct, Timestamp } from '@bufbuild/protobuf'

/**
 * @generated from message pages.v2.ComponentDef
 */
export class ComponentDef extends Message<ComponentDef> {
  /**
   * @generated from field: string name = 1;
   */
  name = ''

  /**
   * @generated from field: string version = 2;
   */
  version = ''

  /**
   * @generated from field: string description = 3;
   */
  description = ''

  /**
   * @generated from field: string type_uri = 4;
   */
  typeUri = ''

  /**
   * @generated from field: string component_type = 5;
   */
  componentType = ''

  /**
   * @generated from field: string editor_component_type = 6;
   */
  editorComponentType = ''

  /**
   * @generated from field: string remote_url = 7;
   */
  remoteUrl = ''

  /**
   * @generated from field: string internal_remote_url = 14;
   */
  internalRemoteUrl = ''

  /**
   * @generated from field: string thumbnail_image = 8;
   */
  thumbnailImage = ''

  /**
   * @generated from field: bool is_container = 9;
   */
  isContainer = false

  /**
   * @generated from field: google.protobuf.Struct schema = 10;
   */
  schema?: Struct

  /**
   * @generated from field: google.protobuf.Struct default_sytyle = 11;
   */
  defaultSytyle?: Struct

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 12;
   */
  createdAt?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 13;
   */
  updatedAt?: Timestamp

  constructor(data?: PartialMessage<ComponentDef>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.ComponentDef'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'version', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'type_uri', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'component_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'editor_component_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'remote_url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 14, name: 'internal_remote_url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 8, name: 'thumbnail_image', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 9, name: 'is_container', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: 'schema', kind: 'message', T: Struct },
    { no: 11, name: 'default_sytyle', kind: 'message', T: Struct },
    { no: 12, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 13, name: 'updated_at', kind: 'message', T: Timestamp }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComponentDef {
    return new ComponentDef().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComponentDef {
    return new ComponentDef().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComponentDef {
    return new ComponentDef().fromJsonString(jsonString, options)
  }

  static equals(
    a: ComponentDef | PlainMessage<ComponentDef> | undefined,
    b: ComponentDef | PlainMessage<ComponentDef> | undefined
  ): boolean {
    return proto3.util.equals(ComponentDef, a, b)
  }
}

/**
 * @generated from message pages.v2.Component
 */
export class Component extends Message<Component> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string type_uri = 2;
   */
  typeUri = ''

  /**
   * @generated from field: google.protobuf.Struct style = 3;
   */
  style?: Struct

  /**
   * @generated from field: repeated pages.v2.Component children = 4;
   */
  children: Component[] = []

  constructor(data?: PartialMessage<Component>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.Component'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'type_uri', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'style', kind: 'message', T: Struct },
    { no: 4, name: 'children', kind: 'message', T: Component, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Component {
    return new Component().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Component {
    return new Component().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Component {
    return new Component().fromJsonString(jsonString, options)
  }

  static equals(
    a: Component | PlainMessage<Component> | undefined,
    b: Component | PlainMessage<Component> | undefined
  ): boolean {
    return proto3.util.equals(Component, a, b)
  }
}

/**
 * @generated from message pages.v2.GetComponentsRequest
 */
export class GetComponentsRequest extends Message<GetComponentsRequest> {
  /**
   * @generated from field: repeated string type_uris = 1;
   */
  typeUris: string[] = []

  constructor(data?: PartialMessage<GetComponentsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.GetComponentsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'type_uris', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentsRequest {
    return new GetComponentsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentsRequest {
    return new GetComponentsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetComponentsRequest {
    return new GetComponentsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetComponentsRequest | PlainMessage<GetComponentsRequest> | undefined,
    b: GetComponentsRequest | PlainMessage<GetComponentsRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetComponentsRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.GetComponentsResponse
 */
export class GetComponentsResponse extends Message<GetComponentsResponse> {
  /**
   * @generated from field: repeated pages.v2.ComponentDef definitions = 1;
   */
  definitions: ComponentDef[] = []

  constructor(data?: PartialMessage<GetComponentsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.GetComponentsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'definitions', kind: 'message', T: ComponentDef, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetComponentsResponse {
    return new GetComponentsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentsResponse {
    return new GetComponentsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetComponentsResponse {
    return new GetComponentsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetComponentsResponse | PlainMessage<GetComponentsResponse> | undefined,
    b: GetComponentsResponse | PlainMessage<GetComponentsResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetComponentsResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.GetComponentRequest
 */
export class GetComponentRequest extends Message<GetComponentRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = ''

  /**
   * @generated from field: string version = 2;
   */
  version = ''

  constructor(data?: PartialMessage<GetComponentRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.GetComponentRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'version', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentRequest {
    return new GetComponentRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentRequest {
    return new GetComponentRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetComponentRequest {
    return new GetComponentRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetComponentRequest | PlainMessage<GetComponentRequest> | undefined,
    b: GetComponentRequest | PlainMessage<GetComponentRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetComponentRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.GetComponentResponse
 */
export class GetComponentResponse extends Message<GetComponentResponse> {
  /**
   * @generated from field: pages.v2.ComponentDef definition = 1;
   */
  definition?: ComponentDef

  constructor(data?: PartialMessage<GetComponentResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.GetComponentResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'definition', kind: 'message', T: ComponentDef }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComponentResponse {
    return new GetComponentResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComponentResponse {
    return new GetComponentResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetComponentResponse {
    return new GetComponentResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetComponentResponse | PlainMessage<GetComponentResponse> | undefined,
    b: GetComponentResponse | PlainMessage<GetComponentResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetComponentResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.RegisterComponentRequest
 */
export class RegisterComponentRequest extends Message<RegisterComponentRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = ''

  /**
   * @generated from field: string version = 2;
   */
  version = ''

  /**
   * @generated from field: string description = 3;
   */
  description = ''

  /**
   * @generated from field: string component_type = 4;
   */
  componentType = ''

  /**
   * @generated from field: string editor_component_type = 5;
   */
  editorComponentType = ''

  /**
   * @generated from field: string remote_url = 6;
   */
  remoteUrl = ''

  /**
   * @generated from field: string internal_remote_url = 11;
   */
  internalRemoteUrl = ''

  /**
   * @generated from field: string thumbnail_image = 7;
   */
  thumbnailImage = ''

  /**
   * @generated from field: bool is_container = 8;
   */
  isContainer = false

  /**
   * @generated from field: google.protobuf.Struct schema = 9;
   */
  schema?: Struct

  /**
   * @generated from field: google.protobuf.Struct default_style = 10;
   */
  defaultStyle?: Struct

  constructor(data?: PartialMessage<RegisterComponentRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.RegisterComponentRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'version', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'component_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'editor_component_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'remote_url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 11, name: 'internal_remote_url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'thumbnail_image', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 8, name: 'is_container', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: 'schema', kind: 'message', T: Struct },
    { no: 10, name: 'default_style', kind: 'message', T: Struct }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RegisterComponentRequest {
    return new RegisterComponentRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RegisterComponentRequest {
    return new RegisterComponentRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RegisterComponentRequest {
    return new RegisterComponentRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: RegisterComponentRequest | PlainMessage<RegisterComponentRequest> | undefined,
    b: RegisterComponentRequest | PlainMessage<RegisterComponentRequest> | undefined
  ): boolean {
    return proto3.util.equals(RegisterComponentRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.RegisterComponentResponse
 */
export class RegisterComponentResponse extends Message<RegisterComponentResponse> {
  /**
   * @generated from field: pages.v2.ComponentDef definition = 1;
   */
  definition?: ComponentDef

  constructor(data?: PartialMessage<RegisterComponentResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.RegisterComponentResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'definition', kind: 'message', T: ComponentDef }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RegisterComponentResponse {
    return new RegisterComponentResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RegisterComponentResponse {
    return new RegisterComponentResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RegisterComponentResponse {
    return new RegisterComponentResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: RegisterComponentResponse | PlainMessage<RegisterComponentResponse> | undefined,
    b: RegisterComponentResponse | PlainMessage<RegisterComponentResponse> | undefined
  ): boolean {
    return proto3.util.equals(RegisterComponentResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.CreateComponentRequest
 */
export class CreateComponentRequest extends Message<CreateComponentRequest> {
  /**
   * @generated from field: string type_uri = 1;
   */
  typeUri = ''

  /**
   * @generated from field: google.protobuf.Struct config = 2;
   */
  config?: Struct

  constructor(data?: PartialMessage<CreateComponentRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.CreateComponentRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'type_uri', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'config', kind: 'message', T: Struct }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateComponentRequest {
    return new CreateComponentRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateComponentRequest {
    return new CreateComponentRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateComponentRequest {
    return new CreateComponentRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateComponentRequest | PlainMessage<CreateComponentRequest> | undefined,
    b: CreateComponentRequest | PlainMessage<CreateComponentRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateComponentRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.CreateComponentResponse
 */
export class CreateComponentResponse extends Message<CreateComponentResponse> {
  /**
   * @generated from field: google.protobuf.Struct data = 1;
   */
  data?: Struct

  constructor(data?: PartialMessage<CreateComponentResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.CreateComponentResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'message', T: Struct }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateComponentResponse {
    return new CreateComponentResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateComponentResponse {
    return new CreateComponentResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateComponentResponse {
    return new CreateComponentResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateComponentResponse | PlainMessage<CreateComponentResponse> | undefined,
    b: CreateComponentResponse | PlainMessage<CreateComponentResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateComponentResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.UpdateComponentRequest
 */
export class UpdateComponentRequest extends Message<UpdateComponentRequest> {
  /**
   * @generated from field: string type_uri = 1;
   */
  typeUri = ''

  /**
   * @generated from field: google.protobuf.Struct config = 2;
   */
  config?: Struct

  /**
   * @generated from field: google.protobuf.Struct new_config = 3;
   */
  newConfig?: Struct

  constructor(data?: PartialMessage<UpdateComponentRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.UpdateComponentRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'type_uri', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'config', kind: 'message', T: Struct },
    { no: 3, name: 'new_config', kind: 'message', T: Struct }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateComponentRequest {
    return new UpdateComponentRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateComponentRequest {
    return new UpdateComponentRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateComponentRequest {
    return new UpdateComponentRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateComponentRequest | PlainMessage<UpdateComponentRequest> | undefined,
    b: UpdateComponentRequest | PlainMessage<UpdateComponentRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateComponentRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.UpdateComponentResponse
 */
export class UpdateComponentResponse extends Message<UpdateComponentResponse> {
  /**
   * @generated from field: google.protobuf.Struct data = 1;
   */
  data?: Struct

  constructor(data?: PartialMessage<UpdateComponentResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.UpdateComponentResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'message', T: Struct }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateComponentResponse {
    return new UpdateComponentResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateComponentResponse {
    return new UpdateComponentResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateComponentResponse {
    return new UpdateComponentResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateComponentResponse | PlainMessage<UpdateComponentResponse> | undefined,
    b: UpdateComponentResponse | PlainMessage<UpdateComponentResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateComponentResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.GetDataRequest
 */
export class GetDataRequest extends Message<GetDataRequest> {
  /**
   * @generated from field: string type_uri = 1;
   */
  typeUri = ''

  /**
   * @generated from field: google.protobuf.Struct config = 2;
   */
  config?: Struct

  constructor(data?: PartialMessage<GetDataRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.GetDataRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'type_uri', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'config', kind: 'message', T: Struct }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDataRequest {
    return new GetDataRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDataRequest {
    return new GetDataRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDataRequest {
    return new GetDataRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetDataRequest | PlainMessage<GetDataRequest> | undefined,
    b: GetDataRequest | PlainMessage<GetDataRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetDataRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.GetDataResponse
 */
export class GetDataResponse extends Message<GetDataResponse> {
  /**
   * @generated from field: google.protobuf.Struct data = 1;
   */
  data?: Struct

  constructor(data?: PartialMessage<GetDataResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.GetDataResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'message', T: Struct }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDataResponse {
    return new GetDataResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDataResponse {
    return new GetDataResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDataResponse {
    return new GetDataResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetDataResponse | PlainMessage<GetDataResponse> | undefined,
    b: GetDataResponse | PlainMessage<GetDataResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetDataResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.SaveDataRequest
 */
export class SaveDataRequest extends Message<SaveDataRequest> {
  /**
   * @generated from field: string type_uri = 1;
   */
  typeUri = ''

  /**
   * @generated from field: google.protobuf.Struct config = 2;
   */
  config?: Struct

  /**
   * @generated from field: google.protobuf.Struct data = 3;
   */
  data?: Struct

  /**
   * @generated from field: bool validate_only = 4;
   */
  validateOnly = false

  constructor(data?: PartialMessage<SaveDataRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.SaveDataRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'type_uri', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'config', kind: 'message', T: Struct },
    { no: 3, name: 'data', kind: 'message', T: Struct },
    { no: 4, name: 'validate_only', kind: 'scalar', T: 8 /* ScalarType.BOOL */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveDataRequest {
    return new SaveDataRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveDataRequest {
    return new SaveDataRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveDataRequest {
    return new SaveDataRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: SaveDataRequest | PlainMessage<SaveDataRequest> | undefined,
    b: SaveDataRequest | PlainMessage<SaveDataRequest> | undefined
  ): boolean {
    return proto3.util.equals(SaveDataRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.SaveDataResponse
 */
export class SaveDataResponse extends Message<SaveDataResponse> {
  /**
   * @generated from field: google.protobuf.Struct data = 1;
   */
  data?: Struct

  constructor(data?: PartialMessage<SaveDataResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.SaveDataResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'message', T: Struct }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveDataResponse {
    return new SaveDataResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveDataResponse {
    return new SaveDataResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveDataResponse {
    return new SaveDataResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: SaveDataResponse | PlainMessage<SaveDataResponse> | undefined,
    b: SaveDataResponse | PlainMessage<SaveDataResponse> | undefined
  ): boolean {
    return proto3.util.equals(SaveDataResponse, a, b)
  }
}

/**
 * @generated from message pages.v2.PostDataRequest
 */
export class PostDataRequest extends Message<PostDataRequest> {
  /**
   * @generated from field: string type_uri = 1;
   */
  typeUri = ''

  /**
   * @generated from field: google.protobuf.Struct config = 2;
   */
  config?: Struct

  /**
   * @generated from field: google.protobuf.Struct data = 3;
   */
  data?: Struct

  /**
   * @generated from field: bool validate_only = 4;
   */
  validateOnly = false

  constructor(data?: PartialMessage<PostDataRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.PostDataRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'type_uri', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'config', kind: 'message', T: Struct },
    { no: 3, name: 'data', kind: 'message', T: Struct },
    { no: 4, name: 'validate_only', kind: 'scalar', T: 8 /* ScalarType.BOOL */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostDataRequest {
    return new PostDataRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostDataRequest {
    return new PostDataRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostDataRequest {
    return new PostDataRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: PostDataRequest | PlainMessage<PostDataRequest> | undefined,
    b: PostDataRequest | PlainMessage<PostDataRequest> | undefined
  ): boolean {
    return proto3.util.equals(PostDataRequest, a, b)
  }
}

/**
 * @generated from message pages.v2.PostDataResponse
 */
export class PostDataResponse extends Message<PostDataResponse> {
  /**
   * @generated from field: google.protobuf.Struct data = 1;
   */
  data?: Struct

  constructor(data?: PartialMessage<PostDataResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v2.PostDataResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'message', T: Struct }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostDataResponse {
    return new PostDataResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostDataResponse {
    return new PostDataResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostDataResponse {
    return new PostDataResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: PostDataResponse | PlainMessage<PostDataResponse> | undefined,
    b: PostDataResponse | PlainMessage<PostDataResponse> | undefined
  ): boolean {
    return proto3.util.equals(PostDataResponse, a, b)
  }
}
