import { Components, Theme } from '@mui/material'

/**
 * Because we're adding multiple `@font-face` declarations, we must use string CSS template syntax
 * for adding style overrides so that the previously defined `@font-face` declarations won't be replaced.
 *
 * @see https://mui.com/material-ui/customization/typography/#self-hosted-fonts
 */
export const cssBaselineOverrides: Components<Theme> = {
  MuiCssBaseline: {
    styleOverrides: `
      @media screen and not:(prefers-reduced-motion: reduce) {
        html {
          scroll-behavior: smooth;
        }
      }

      @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        * {
          animation-duration: 0.001ms !important;
          animation-iteration-count: 1 !important;
          transition-duration: 0.001ms !important;
        }
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      @font-face {
        font-family: 'RF Dewi Extended';
        src: url('https://cdn.flowcode.com/cdn/RFDewiExtended-Semibold.woff2') format('woff2');
        font-style: normal;
        font-weight: 600;
        font-display: swap;
      }
      @font-face {
        font-family: 'Centra No2';
        src: url('/fonts/CentraNo2/Centra-No2-Extrabold-Italic.woff2');
        font-style: normal;
        font-weight: 400;
        font-display: block;
      }
    `
  }
}
