export const enum PlanType {
  FREE = 'FREE',
  PRO = 'PRO',
  PRO_PLUS_ORG = 'PRO+ORG',
  PRO_FLEX = 'PRO_FLEX',
  PILOT = 'PILOT',
  ENTERPRISE = 'ENTERPRISE'
}

export const enum PlanDisplayNames {
  BASIC = 'Basic',
  PRO = 'Pro',
  PRO_PLUS_ORG = 'Pro Plus',
  PRO_FLEX = 'Growth',
  PILOT = 'Trial',
  ENTERPRISE = 'Enterprise'
}
