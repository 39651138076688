import { IthacaFilter } from '@dtx-company/flow-codegen/src/page/generated.types'
import { useAuthState } from '../../../hooks/useAuthState'
import { usePageCountQuery } from '../flowpageApiSlice/pageQueries'
import { usePageSelector } from '../../../hooks/usePageSelector'

/*  Fetches page count based on active ithaca filter from the UI
    If you want a separate filter, such as IthacaFilter.All, pass it as a prop
    note: check useFlowpagePermissionsEnabled() to see if a user has sharing permissions
*/
export function usePageCount(ithacaFilter?: IthacaFilter): number {
  const { jwt, isAuthenticated, isAuthChecked } = useAuthState()
  const pageManagementFilter = useGetIthacaFilter()

  const { data } = usePageCountQuery(
    {
      ithacaFilter: ithacaFilter ?? pageManagementFilter
    },
    {
      skip: !isAuthChecked || !isAuthenticated || !jwt?.token
    }
  )
  return data?.me.pageConnection.totalCount ?? 0
}

export function usePageCountWithLoading(ithacaFilter?: IthacaFilter): {
  totalCount: number
  isLoading: boolean
  refetch: () => void
} {
  const { jwt, isAuthenticated, isAuthChecked } = useAuthState()
  const pageManagementFilter = useGetIthacaFilter()

  const { data, isLoading, refetch } = usePageCountQuery(
    {
      ithacaFilter: ithacaFilter ?? pageManagementFilter
    },
    {
      skip: !isAuthChecked || !isAuthenticated || !jwt?.token
    }
  )
  return { totalCount: data?.me.pageConnection.totalCount ?? 0, isLoading, refetch }
}

export const useGetIthacaFilter = (): IthacaFilter => {
  return usePageSelector(state => state.pageManagementReducer.ithacaFilter)
}
