import * as media from './media-queries'
import * as padding from './padding'
import { theme as sharedTheme } from '@dtx-company/shared-components/src/theme'
import typography from './typography'

const theme = {
  ...sharedTheme,
  colors: { white: '#FFF', ...sharedTheme.colors },
  zIndex: {
    ...sharedTheme.zIndex
  },
  ...padding,
  ...media,
  ...typography
}

export * from './media-queries'
export * from './typography'
export * from './padding'

export default theme
