import { AnyAction, CombinedState, Reducer, combineReducers } from '@reduxjs/toolkit'
import analyticsDrawersReducer, { AnalyticsDrawersSliceState } from '../slices/analyticsDrawers'
import assetFilterReducer, { AssetFilterValuesState } from '../slices/assetFilters'
import dashboardReducer, { DashboardState } from '../slices/dashboard'
import dateFilterReducer, { DateFilterSliceState } from '../slices/dateFilters'

export const analyticsCombinedReducers: Reducer<
  CombinedState<{
    dashboardReducer: DashboardState
    assetFilterReducer: AssetFilterValuesState
    dateFilterReducer: DateFilterSliceState
    analyticsDrawersReducer: AnalyticsDrawersSliceState
  }>,
  AnyAction
> = combineReducers({
  dashboardReducer,
  assetFilterReducer,
  dateFilterReducer,
  analyticsDrawersReducer
})

export const rootAnalyticsReducer: Reducer = (state, action) => {
  return analyticsCombinedReducers(state, action)
}
