import { AUTO_DESIGN_MAX_COLORS, DEFAULT_AUTO_DESIGN_COLOR } from '../../constants/autoDesign'
import { FcGeneratorOptions } from '@dtx-company/flowcode-generator-browser/src'
import { FlowcodeDownloadOptionMimeType } from '../../types/flowcode'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface CombinedFCOptionBatchType {
  codeOptions: string | null
  codeBatchId: string | null
}
export interface HomePageState {
  shouldCreateTransparentCode: boolean
  fileTypeToDownload: FlowcodeDownloadOptionMimeType
  autoDesignColorsChosen: string[]
  centerImageFile: {
    name: string
    height: number
    width: number
    objectUrl: string
  }
  autoDesignedCodeOptions: FcGeneratorOptions | null
  showProUpsellExperiment: boolean
  downloadCodeData: CombinedFCOptionBatchType
}

const initialState: HomePageState = {
  shouldCreateTransparentCode: false,
  fileTypeToDownload: FlowcodeDownloadOptionMimeType.PNG,
  autoDesignColorsChosen: ['#000000'],
  centerImageFile: {
    name: '',
    height: 0,
    width: 0,
    objectUrl: ''
  },
  autoDesignedCodeOptions: null,
  showProUpsellExperiment: false,
  downloadCodeData: {
    codeOptions: null,
    codeBatchId: null
  }
}

const homePageSlice = createSlice({
  name: 'homePage',
  initialState,
  reducers: {
    chooseAutoDesignColor: (state, action: PayloadAction<string>) => {
      const colorToAdd = action.payload
      const maxColorsChosen = state.autoDesignColorsChosen.length === AUTO_DESIGN_MAX_COLORS
      const colorAlreadyChosen = state.autoDesignColorsChosen.includes(colorToAdd)
      if (maxColorsChosen || colorAlreadyChosen) return
      state.autoDesignColorsChosen = [...state.autoDesignColorsChosen, colorToAdd]
    },
    removeAutoDesignColor: (state, action: PayloadAction<string>) => {
      const colorToRemove = action.payload
      state.autoDesignColorsChosen = state.autoDesignColorsChosen.filter(
        color => color !== colorToRemove
      )
    },
    updateCenterImage: (
      state,
      action: PayloadAction<{ name: string; height: number; width: number; objectUrl: string }>
    ) => {
      state.centerImageFile = action.payload
    },
    clearCenterImage: state => {
      state.centerImageFile = {
        name: '',
        height: 0,
        width: 0,
        objectUrl: ''
      }
    },
    updateAutoDesignedCodeOptions: (state, action: PayloadAction<FcGeneratorOptions | null>) => {
      state.autoDesignedCodeOptions = action.payload
    },
    updateCenterImageInCodePreview: (
      state,
      action: PayloadAction<{ height: number; width: number; objectUrl: string }>
    ) => {
      if (state.autoDesignedCodeOptions) {
        state.autoDesignedCodeOptions.logoWidth = action.payload.width
        state.autoDesignedCodeOptions.logoHeight = action.payload.height
        state.autoDesignedCodeOptions.logoImageUrl = action.payload.objectUrl
        state.autoDesignedCodeOptions.logoFillSvgColor = DEFAULT_AUTO_DESIGN_COLOR
      }
    },
    updateDownloadCodeData: (state, action: PayloadAction<CombinedFCOptionBatchType>) => {
      state.downloadCodeData.codeOptions = action.payload.codeOptions
      state.downloadCodeData.codeBatchId = action.payload.codeBatchId
    },
    setShowProUpsellExperiment: (state, action: PayloadAction<boolean>) => {
      state.showProUpsellExperiment = action.payload
    },
    updateFileTypeToDownload: (state, action: PayloadAction<FlowcodeDownloadOptionMimeType>) => {
      state.fileTypeToDownload = action.payload
    }
  }
})

export const {
  chooseAutoDesignColor,
  removeAutoDesignColor,
  updateCenterImage,
  clearCenterImage,
  updateAutoDesignedCodeOptions,
  updateCenterImageInCodePreview,
  setShowProUpsellExperiment,
  updateDownloadCodeData,
  updateFileTypeToDownload
} = homePageSlice.actions

export default homePageSlice.reducer
