export enum ScanToOwnGenericPages {
  LANDING = 'LANDING',
  NICKNAME = 'NICKNAME',
  SCAN_DESTINATIONS_MENU = 'SCAN_DESTINATIONS_MENU',
  SCAN_DESTINATION_EMAIL = 'SCAN_DESTINATION_EMAIL',
  SCAN_DESTINATION_FILE = 'SCAN_DESTINATION_FILE',
  SCAN_DESTINATION_GOOGLE_DOC = 'SCAN_DESTINATION_GOOGLE_DOC',
  SCAN_DESTINATION_PHONE = 'SCAN_DESTINATION_PHONE',
  SCAN_DESTINATION_SOCIAL_MEDIA = 'SCAN_DESTINATION_SOCIAL_MEDIA',
  SCAN_DESTINATION_INSTAGRAM = 'SCAN_DESTINATION_INSTAGRAM',
  SCAN_DESTINATION_LINKEDIN = 'SCAN_DESTINATION_LINKEDIN',
  SCAN_DESTINATION_FACEBOOK = 'SCAN_DESTINATION_FACEBOOK',
  SCAN_DESTINATION_SNAPCHAT = 'SCAN_DESTINATION_SNAPCHAT',
  SCAN_DESTINATION_TIKTOK = 'SCAN_DESTINATION_TIKTOK',
  SCAN_DESTINATION_TWITTER = 'SCAN_DESTINATION_TWITTER',
  SCAN_DESTINATION_SPOTIFY = 'SCAN_DESTINATION_SPOTIFY',
  SCAN_DESTINATION_SMS = 'SCAN_DESTINATION_SMS',
  SCAN_DESTINATION_WEBSITE = 'SCAN_DESTINATION_WEBSITE',
  SCAN_DESTINATION_YOUTUBE = 'SCAN_DESTINATION_YOUTUBE',
  SCAN_DESTINATION_VENMO = 'SCAN_DESTINATION_VENMO',
  SCAN_DESTINATION_CONTACT = 'SCAN_DESTINATION_CONTACT',
  LINK_EXISTING_PAGE = 'LINK_EXISTING_PAGE',
  NO_EXISTING_PAGE = 'NO_EXISTING_PAGE',
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  COLLECT_CODE = 'COLLECT_CODE',
  RENAME_SLUG = 'RENAME_SLUG',
  OFFBOARDING_VIDEO = 'OFFBOARDING_VIDEO',
  CODE_COLLECTED = 'CODE_COLLECTED'
}
