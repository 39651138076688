import { Components, Theme } from '@mui/material'

export const buttonVariants = ['contained', 'outlined', 'text'] as const
export const buttonColors = [
  'primary',
  'secondary',
  'tertiary',
  'error',
  'upgrade',
  'inherit'
] as const

/**
 * MUI Theme overrides for Flowcode's [Button](./Button.tsx) component.
 */
export const buttonComponentOverrides: Components<Theme> = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true
    }
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: 'contained',
      color: 'primary'
    },
    styleOverrides: {
      root: {
        textTransform: 'none'
      },

      sizeSmall: ({ theme }) => ({
        padding: theme.spacing(1.75, 3),
        fontSize: '0.875rem',
        lineHeight: 18 / 14,
        fontWeight: 600,
        letterSpacing: '0.025rem'
      }),
      sizeMedium: ({ theme }) => ({
        padding: theme.spacing(2.5, 3.5),
        fontSize: '0.875rem',
        lineHeight: 18 / 14,
        fontWeight: 600,
        letterSpacing: '0.025rem'
      }),
      sizeLarge: ({ theme }) => ({
        padding: theme.spacing(3, 4),
        fontSize: '1rem',
        lineHeight: 1.5,
        fontWeight: 600,
        letterSpacing: '0.025rem'
      }),

      iconSizeSmall: {
        '& > *:nth-of-type(1)': {
          fontSize: '0.9375rem'
        }
      },
      iconSizeMedium: {
        '& > *:nth-of-type(1)': {
          fontSize: '1.041875rem'
        }
      },
      iconSizeLarge: {
        '& > *:nth-of-type(1)': {
          fontSize: '1.25rem'
        }
      },

      // Overrides for buttons where `variant="contained"`
      containedPrimary: ({ theme }) => ({
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.onSurface.inverse,
        '&:hover': {
          background: theme.palette.shades.primary.filledHover
        },
        '&:focus, &:active, &.Mui-focusVisible': {
          background: theme.palette.shades.primary.filledFocus
        },
        '&:disabled, &.Mui-disabled': {
          backgroundColor: theme.palette.action.disabled,
          color: theme.palette.onSurface.disabled
        }
      }),
      containedSecondary: ({ theme }) => ({
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
          background: theme.palette.shades.secondary.filledHover
        },
        '&:focus, &:active, &.Mui-focusVisible': {
          background: theme.palette.shades.secondary.filledFocus
        },
        '&:disabled, &.Mui-disabled': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.onSurface.primary,
          opacity: 0.38
        }
      }),
      containedTertiary: ({ theme }) => ({
        backgroundColor: theme.palette.tertiary.main,
        '&:hover': {
          background: theme.palette.shades.tertiary.filledHover
        },
        '&:focus, &:active, &.Mui-focusVisible': {
          background: theme.palette.shades.tertiary.filledFocus
        },
        '&:disabled, &.Mui-disabled': {
          backgroundColor: theme.palette.tertiary.main,
          color: theme.palette.onSurface.inverse,
          opacity: 0.38
        }
      }),
      containedError: ({ theme }) => ({
        backgroundColor: theme.palette.error.main,
        '&:hover': {
          background: theme.palette.shades.error.filledHover
        },
        '&:focus, &:active, &.Mui-focusVisible': {
          background: theme.palette.shades.error.filledFocus
        },
        '&:disabled, &.Mui-disabled': {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.onSurface.inverse,
          opacity: 0.38
        }
      }),
      containedUpgrade: ({ theme }) => ({
        backgroundColor: theme.palette.utility.upgrade,
        color: theme.palette.getContrastText(theme.palette.utility.upgrade),
        '&:hover': {
          background: theme.palette.shades.upgrade.hover
        },
        '&:focus, &:active, &.Mui-focusVisible': {
          background: theme.palette.shades.upgrade.filledFocus
        },
        '&:disabled, &.Mui-disabled': {
          backgroundColor: theme.palette.utility.upgrade,
          color: theme.palette.getContrastText(theme.palette.utility.upgrade),
          opacity: 0.38
        }
      }),

      // Overrides for buttons where `variant="outlined"`
      outlined: ({ theme }) => ({
        background: theme.palette.surface['0'],
        border: 1,
        borderStyle: 'solid'
      }),
      outlinedPrimary: ({ theme }) => ({
        borderColor: theme.palette.onSurface.primary,
        color: theme.palette.onSurface.primary,
        '&:hover': {
          backgroundColor: theme.palette.shades.primary.hover,
          borderColor: theme.palette.onSurface.primary
        },
        '&:focus, &:active, &.Mui-focusVisible': {
          backgroundColor: theme.palette.shades.primary.focus,
          borderColor: theme.palette.onSurface.primary
        },
        '&:disabled, &.Mui-disabled': {
          borderColor: theme.palette.border.disabled,
          color: theme.palette.onSurface.disabled
        }
      }),
      outlinedSecondary: ({ theme }) => ({
        borderColor: theme.palette.border.outline,
        color: theme.palette.onSurface.primary,
        '&:hover': {
          backgroundColor: theme.palette.shades.primary.hover,
          borderColor: theme.palette.border.outline
        },
        '&:focus, &:active, &.Mui-focusVisible': {
          backgroundColor: theme.palette.shades.primary.focus,
          borderColor: theme.palette.border.outline
        },
        '&:disabled, &.Mui-disabled': {
          borderColor: theme.palette.border.disabled,
          color: theme.palette.onSurface.disabled
        }
      }),
      outlinedTertiary: ({ theme }) => ({
        borderColor: theme.palette.tertiary.main,
        color: theme.palette.tertiary.main,
        '&:hover': {
          backgroundColor: theme.palette.shades.tertiary.hover,
          borderColor: theme.palette.tertiary
        },
        '&:focus, &:active, &.Mui-focusVisible': {
          backgroundColor: theme.palette.shades.tertiary.focus,
          borderColor: theme.palette.tertiary.main
        },
        '&:disabled, &.Mui-disabled': {
          borderColor: theme.palette.tertiary.main,
          color: theme.palette.tertiary.main,
          opacity: 0.38
        }
      }),
      outlinedError: ({ theme }) => ({
        '&:hover': {
          backgroundColor: theme.palette.shades.error.hover
        },
        '&:focus, &:active, &.Mui-focusVisible': {
          backgroundColor: theme.palette.shades.error.focus
        },
        '&:disabled, &.Mui-disabled': {
          borderColor: theme.palette.error.main,
          color: theme.palette.error.main,
          opacity: 0.38
        },
        focusVisible: {
          backgroundColor: theme.palette.shades.error.focus
        }
      }),

      // Reduce padding when `variant="outlined"` to account for border width
      // so that the button height and width is consistent with other variants
      outlinedSizeSmall: ({ theme }) => ({
        padding: `${theme.spacing(1.75)} calc(${theme.spacing(3)} - 1px)`
      }),
      outlinedSizeMedium: ({ theme }) => ({
        padding: `${theme.spacing(2.25)} calc(${theme.spacing(3.5)} - 1px)`
      }),
      outlinedSizeLarge: ({ theme }) => ({
        padding: `${theme.spacing(3)} calc(${theme.spacing(4)} - 1px)`
      }),

      // Overrides for buttons where `variant="text"`
      textPrimary: ({ theme }) => ({
        color: theme.palette.onSurface.primary,
        '&:hover': {
          backgroundColor: theme.palette.shades.primary.hover
        },
        '&:focus, &:active, &.Mui-focusVisible': {
          backgroundColor: theme.palette.shades.primary.focus
        },
        '&:disabled, &.Mui-disabled': {
          color: theme.palette.onSurface.disabled
        }
      }),
      textSecondary: ({ theme }) => ({
        color: theme.palette.onSurface.primary,
        '&:hover': {
          backgroundColor: theme.palette.shades.primary.hover
        },
        '&:focus, &:active, &.Mui-focusVisible': {
          backgroundColor: theme.palette.shades.primary.focus
        },
        '&:disabled, &.Mui-disabled': {
          color: theme.palette.onSurface.disabled
        }
      }),
      textTertiary: ({ theme }) => ({
        color: theme.palette.tertiary.main,
        '&:hover': {
          backgroundColor: theme.palette.shades.tertiary.hover
        },
        '&:focus, &:active, &.Mui-focusVisible': {
          backgroundColor: theme.palette.shades.tertiary.focus
        },
        '&:disabled, &.Mui-disabled': {
          color: theme.palette.tertiary.main,
          opacity: 0.38
        }
      }),
      textError: ({ theme }) => ({
        '&:hover': {
          backgroundColor: theme.palette.shades.error.hover
        },
        '&:focus, &:active, &.Mui-focusVisible': {
          backgroundColor: theme.palette.shades.error.focus
        },
        '&:disabled, &.Mui-disabled': {
          color: theme.palette.error.main,
          opacity: 0.38
        }
      })
    }
  }
}
