import { FronteggAccessToken } from './types'
import { IthacaJwt, fetchClaimsFromIthaca } from '../auth'
import { decodeFronteggAccessToken } from './decodeFronteggAccessToken'
import { logger } from '@dtx-company/logger'
import { refreshFronteggTokens } from './refreshFronteggTokens'
import { setTimeoutAsync } from '@dtx-company/true-common/src/utils/async-timers'

const retryLimit = 5
const sleepInterval = 100

const refreshJwtFromDFAT = async (): Promise<FronteggAccessToken | undefined> => {
  try {
    const { accessToken } = await refreshFronteggTokens(false)

    if (accessToken) {
      const decodedAccessToken = decodeFronteggAccessToken(accessToken)

      if (decodedAccessToken?.customClaims) {
        return decodedAccessToken
      }
    } else {
      throw new Error('customClaims retry - refreshJwtFromDFAT accessToken returned nullish')
    }
  } catch (e) {
    logger.logError(e, { technicalArea: 'authentication' })
  }
}

const retryBuildJwtFromDFAT = async (): Promise<FronteggAccessToken | undefined> => {
  let attemptCount = 0
  let res

  let shouldRetry = true

  while (shouldRetry) {
    attemptCount++
    await setTimeoutAsync(sleepInterval)
    res = await refreshJwtFromDFAT()

    if (res || attemptCount >= retryLimit) {
      shouldRetry = false
    }
  }

  if (!shouldRetry) {
    return res
  }
}

export async function buildJwtFromFronteggAccessToken(
  fronteggAccessToken: string
): Promise<IthacaJwt | undefined> {
  try {
    let finalDFAT

    const firstDFAT = decodeFronteggAccessToken(fronteggAccessToken)

    if (firstDFAT?.customClaims) {
      finalDFAT = firstDFAT
    } else {
      const retryResult = await retryBuildJwtFromDFAT()

      if (retryResult) {
        finalDFAT = retryResult
      } else {
        throw new Error(
          `customClaims missing even after retry | attempts: ${retryLimit} | timeout interval (ms): ${sleepInterval}`
        )
      }
    }

    if (finalDFAT && finalDFAT?.customClaims?.ithacaId) {
      const claims = await fetchClaimsFromIthaca(finalDFAT?.customClaims?.ithacaId, true, logger)
      if (claims && claims.ithacaId) {
        return {
          ...claims,
          token: fronteggAccessToken
        }
      }
    }
  } catch (e) {
    logger.logError(e, { technicalArea: 'authentication' })
  }
}
