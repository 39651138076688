import {
  FlowFeatureCache,
  FlowFeatureConsole,
  FlowFeatureContext,
  FlowFeatureContextProps
} from './FlowFeatureContext'
import { ReactNode, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { getFlowFeatureCacheFromRouter } from './getFlowFeatureCacheFromRouter'
import { parseFlowFeatureValue } from './parseFlowFeatureValue'
import { useFlowConsole } from '../../../hooks/useFlowConsole'
import { useFlowRouter } from '../../useFlowRouter'
import { useIsMounted } from '@dtx-company/true-common/src/hooks/useIsMounted'
import { useLocalStorageState } from '@dtx-company/true-common/src/hooks/useLocalStorageState'
import { useOnce } from '@dtx-company/true-common/src/hooks/useOnce'
import { useOptimizelyClient } from '../../useOptimizelyClient'
import { useSessionStorageState } from '@dtx-company/true-common/src/hooks/useSessionStorageState'

const emptyCache = {} as FlowFeatureCache

export interface FlowFeatureProviderProps {
  children?: ReactNode
}

export function FlowFeatureProvider(props: FlowFeatureProviderProps): JSX.Element {
  const [localCache, setLocalCache] = useLocalStorageState('flowFeatureCache', emptyCache)
  const [sessionCache, setSessionCache] = useSessionStorageState('flowFeatureCache', emptyCache)
  const [queryCache, setQueryCache] = useState(emptyCache)
  const [liveCache, setLiveCache] = useState(emptyCache)

  const optimizelyClient = useOptimizelyClient()

  //flag to determine when optimizely.setUser has been called - check this when rendering based on AUDIENCE
  const attributesReady = Boolean(optimizelyClient?.user?.attributes?.createdAt)

  const flowRouter = useFlowRouter()
  const isMounted = useIsMounted()
  const isFlowpage = flowRouter.pathname === Routes.FLOWPAGE

  const context = useMemo<FlowFeatureContextProps>(() => {
    const add: FlowFeatureConsole['add'] = (key, value, persist) => {
      const feature = parseFlowFeatureValue(value)
      const _add: SetStateAction<FlowFeatureCache> = cache => ({ ...cache, [key]: feature })
      persist ? setLocalCache(_add) : setSessionCache(_add)
    }
    const remove: FlowFeatureConsole['remove'] = (key: string): void => {
      const _remove: SetStateAction<FlowFeatureCache> = cache => {
        delete cache[key]
        return cache
      }
      setLocalCache(_remove)
      setSessionCache(_remove)
    }
    const clear: FlowFeatureConsole['clear'] = (): void => {
      const _clear: SetStateAction<FlowFeatureCache> = () => {
        return {}
      }
      setLocalCache(_clear)
      setSessionCache(_clear)
    }
    const getCache = (): FlowFeatureCache | void => {
      if (!optimizelyClient || isFlowpage) return
      const decisionMap = optimizelyClient.decideAll()
      const allFeatures = Object.keys(decisionMap).reduce<
        Record<string, [enabled: boolean, settings: Record<string, unknown>]>
      >((acc, key) => {
        const decision = decisionMap[key]
        acc[key] = [decision.enabled, decision.variables]
        return acc
      }, {})

      const context = contextRef.current
      const cache = {
        ...allFeatures,
        ...context.localCache,
        ...context.sessionCache,
        ...context.queryCache
      }
      return cache as FlowFeatureCache
    }
    const list: FlowFeatureConsole['list'] = (): void => {
      const cache = getCache()
      if (!cache) return
      const keys = Object.keys(cache).sort()
      if (keys.length > 0) {
        const queryKeys = Object.keys(context.queryCache)
        const sessionKeys = Object.keys(context.sessionCache)
        const localKeys = Object.keys(context.localCache)
        const liveKeys = Object.keys(context.liveCache)
        const lines = keys.map(key => {
          const feature = cache[key]
          let whichCache = ''
          if (queryKeys.includes(key)) {
            whichCache = 'query'
          } else if (sessionKeys.includes(key)) {
            whichCache = 'session'
          } else if (localKeys.includes(key)) {
            whichCache = 'local'
          } else if (liveKeys.includes(key)) {
            whichCache = 'live'
          } else {
            whichCache = 'none'
          }
          const [enabled, settings] = feature
          const settingLines = Object.keys(settings).map(settingKey => {
            return `     - ${settingKey}: ${settings[settingKey]}`
          })
          const headerText = `${enabled ? 'ON ' : 'OFF'}  ${key} (${whichCache})`
          if (settingLines.length > 0) {
            return `${headerText}\n${settingLines.join('\n')}`
          }
          return headerText
        })
        console.info(`Feature Flags\n${lines.join('\n')}`)
      } else {
        console.info(`Feature Flags\nNone.`)
      }
    }
    const featureConsole: FlowFeatureConsole = {
      add(key, value, persist) {
        remove(key)
        add(key, value, persist)
        list()
      },
      remove(key) {
        remove(key)
        list()
      },
      clear(): void {
        clear()
        list()
      },
      getCache,
      list(): void {
        list()
      }
    }
    return {
      featureConsole,
      localCache,
      sessionCache,
      queryCache,
      liveCache,
      attributesReady,
      addToLiveCache(key, value) {
        if (JSON.stringify(liveCache[key]) !== JSON.stringify(value)) {
          setLiveCache(cache => ({ ...cache, [key]: value }))
        }
      }
    }
  }, [
    localCache,
    sessionCache,
    queryCache,
    liveCache,
    attributesReady,
    setLocalCache,
    setSessionCache,
    optimizelyClient,
    isFlowpage
  ])

  const contextRef = useRef(context)
  contextRef.current = context

  // Store query params
  // Only execute once and persist until app is reloaded
  useEffect(() => {
    if (!isMounted()) return
    if (queryCache !== emptyCache) return
    const cache = getFlowFeatureCacheFromRouter(flowRouter)
    setQueryCache(cache)
  }, [isMounted, queryCache, flowRouter])

  // Add console featureConsole to manage local and session cache
  useFlowConsole(flow => {
    flow.feature = context.featureConsole
  })

  // Do an initial print if there are any cached values
  useOnce(() => {
    if (!isMounted()) return false
    if (queryCache === emptyCache) return false
    const context = contextRef.current
    const cache = { ...context.localCache, ...context.sessionCache, ...context.queryCache }
    if (Object.keys(cache).length > 0) {
      window?.flow?.feature?.list()
    }
  })

  return <FlowFeatureContext.Provider value={context}>{props.children}</FlowFeatureContext.Provider>
}
