import {
  CodeConfigurationField,
  LandingPageDestination
} from '@dtx-company/inter-app/src/types/flowcode'
import {
  LocgContext,
  ScrollTargetEvent,
  ValidationErrorData
} from '@app/code/src/machines/locg/locgMachine.types'
import { MAX_FILE_SIZE } from '../../../components/LoggedOutCodeGeneratorV2/GeneratorSections/ScanDestination/FileField/FileField'
import { MESSAGE_MAX_LENGTH } from '@app/code/src/components/LoggedOutCodeGeneratorV2/GeneratorSections/ScanDestination/constants'
import { isEmail } from '@dtx-company/flowcode-utils/src/validate'
import { isValidUrl } from '@dtx-company/inter-app/src/utils/isValidUrl/isValidUrl'
import { logger } from '@dtx-company/logger'
import { parsePhoneNumber } from 'awesome-phonenumber'
import getT from 'next-translate/getT'

export const validateScanDestination = async (
  context: LocgContext,
  event: ScrollTargetEvent
): Promise<Omit<ScrollTargetEvent, 'type'> | ValidationErrorData> => {
  const scanDestination = context.values.scanDestination

  return new Promise((resolve, reject) => {
    const errors: ValidationErrorData = {
      [CodeConfigurationField.SCAN_DESTINATION]: undefined,
      [CodeConfigurationField.BODY]: undefined,
      [CodeConfigurationField.SUBJECT]: undefined,
      [CodeConfigurationField.FILE]: undefined
    }

    const validationFn = async (): Promise<void> => {
      const t = await getT(context.locale, 'logged-out-generator')

      switch (context.values.scanDestinationType) {
        case LandingPageDestination.URL:
          if (!scanDestination) {
            errors[CodeConfigurationField.SCAN_DESTINATION] = t(
              'LoggedOutGenerator.ScanDestination.options.url.subcomponents.UrlInput.errors.required'
            )
          } else {
            if (!isValidUrl(scanDestination)) {
              errors[CodeConfigurationField.SCAN_DESTINATION] = t(
                'LoggedOutGenerator.ScanDestination.options.url.subcomponents.UrlInput.errors.invalidUrl'
              )
            }
          }
          break

        case LandingPageDestination.EMAIL:
          if (!scanDestination) {
            errors[CodeConfigurationField.SCAN_DESTINATION] = t(
              'LoggedOutGenerator.ScanDestination.options.email.subcomponents.EmailInput.errors.required'
            )
          } else {
            if (
              !isEmail({
                email: scanDestination,
                subject: context.values.subject,
                body: context.values.body
              })
            ) {
              errors[CodeConfigurationField.SCAN_DESTINATION] = t(
                'LoggedOutGenerator.ScanDestination.options.email.subcomponents.EmailInput.errors.invalid'
              )
            }

            if (context.values.subject && context.values.subject.length > MESSAGE_MAX_LENGTH) {
              errors[CodeConfigurationField.SUBJECT] = t(
                'LoggedOutGenerator.ScanDestination.options.email.subcomponents.SubjectInput.errors.too-long',
                {
                  characterCount: context.values.subject.length,
                  characterLimit: MESSAGE_MAX_LENGTH
                }
              )
            }
            if (context.values.body && context.values.body.length > MESSAGE_MAX_LENGTH) {
              errors[CodeConfigurationField.BODY] = t(
                'LoggedOutGenerator.ScanDestination.options.email.subcomponents.MessageInput.errors.too-long',
                { characterCount: context.values.body.length, characterLimit: MESSAGE_MAX_LENGTH }
              )
            }
          }
          break

        case LandingPageDestination.SMS:
          {
            if (!scanDestination) {
              errors[CodeConfigurationField.SCAN_DESTINATION] = t(
                'LoggedOutGenerator.ScanDestination.options.sms.subcomponents.PhoneNumberInput.errors.required'
              )
            } else {
              const parsedPhoneNumber = parsePhoneNumber(scanDestination, {
                regionCode: context.values.regionCode
              })

              if (parsedPhoneNumber.possibility === 'too-long') {
                errors[CodeConfigurationField.SCAN_DESTINATION] = t(
                  'LoggedOutGenerator.ScanDestination.options.sms.subcomponents.PhoneNumberInput.errors.too-long'
                )
              } else if (parsedPhoneNumber.possibility === 'too-short') {
                errors[CodeConfigurationField.SCAN_DESTINATION] = t(
                  'LoggedOutGenerator.ScanDestination.options.sms.subcomponents.PhoneNumberInput.errors.too-short'
                )
              } else if (parsedPhoneNumber.valid && parsedPhoneNumber.possible) {
                errors[CodeConfigurationField.SCAN_DESTINATION] = undefined
              } else {
                errors[CodeConfigurationField.SCAN_DESTINATION] = t(
                  'LoggedOutGenerator.ScanDestination.options.sms.subcomponents.PhoneNumberInput.errors.invalid'
                )
              }

              if (context.values.body && context.values.body.length > MESSAGE_MAX_LENGTH) {
                errors[CodeConfigurationField.BODY] = t(
                  'LoggedOutGenerator.ScanDestination.options.sms.subcomponents.MessageInput.errors.too-long',
                  { characterCount: context.values.body.length, characterLimit: MESSAGE_MAX_LENGTH }
                )
              }
            }
          }
          break

        // File validation is already handled in the component via dropzone, so this may be redundant
        case LandingPageDestination.FILE:
          {
            const file = context.values.file
            if (!file) {
              errors[CodeConfigurationField.FILE] = t('LoggedOutGenerator.files.errors.required')
            } else if (file.size > MAX_FILE_SIZE) {
              errors[CodeConfigurationField.FILE] = t(
                'LoggedOutGenerator.files.errors.file-too-large'
              )
            } else if (
              !['image/', 'application/pdf', 'audio/', 'video/'].some(type =>
                file.type.startsWith(type)
              )
            ) {
              errors[CodeConfigurationField.FILE] = t(
                'LoggedOutGenerator.files.errors.file-invalid-type'
              )
            }
          }
          break

        case LandingPageDestination.INSTAGRAM:
          if (!scanDestination) {
            errors[CodeConfigurationField.SCAN_DESTINATION] = t(
              'LoggedOutGenerator.ScanDestination.options.instagram.subcomponents.InstagramInput.errors.required'
            )
          } else {
            if (
              !isValidUrl(scanDestination, { host_whitelist: ['www.instagram.com'] }) &&
              !scanDestination.startsWith('@')
            ) {
              errors[CodeConfigurationField.SCAN_DESTINATION] = t(
                'LoggedOutGenerator.ScanDestination.options.instagram.subcomponents.InstagramInput.errors.invalid'
              )
            }
          }
          break

        case LandingPageDestination.FACEBOOK:
          {
            if (!scanDestination) {
              errors[CodeConfigurationField.SCAN_DESTINATION] = t(
                'LoggedOutGenerator.ScanDestination.options.facebook.subcomponents.FacebookInput.errors.required'
              )
            } else {
              // We want to extract the handle in case the user enters a full or partial URL,
              // e.g. 'facebook.com/GetFlowcode'
              const value = scanDestination.includes('facebook.com/')
                ? scanDestination.split('.com/')[1]
                : undefined
              if (value) {
                const resp = await fetch(`/api/social-validation/facebook?handle=${value}`, {
                  method: 'POST'
                })
                try {
                  const { error: socialValidationError } = await resp.json()
                  if (!resp.ok || socialValidationError) {
                    errors[CodeConfigurationField.SCAN_DESTINATION] = t(
                      'LoggedOutGenerator.ScanDestination.options.facebook.subcomponents.FacebookInput.errors.invalid'
                    )
                  }
                } catch (err) {
                  logger.logError(err, { team: 'templates' })
                }
              } else {
                errors[CodeConfigurationField.SCAN_DESTINATION] = t(
                  'LoggedOutGenerator.ScanDestination.options.facebook.subcomponents.FacebookInput.errors.required'
                )
              }
            }
          }
          break

        case LandingPageDestination.YOUTUBE:
          {
            if (!scanDestination) {
              errors[CodeConfigurationField.SCAN_DESTINATION] = t(
                'LoggedOutGenerator.ScanDestination.options.youtube.subcomponents.YouTubeInput.errors.required'
              )
            } else {
              const encodedScanDestintion = encodeURIComponent(scanDestination)
              const resp = await fetch(
                `/api/social-validation/youtube?handle=${encodedScanDestintion}`,
                {
                  method: 'POST'
                }
              )
              try {
                const { error: socialValidationError } = await resp.json()
                if (!resp.ok || socialValidationError) {
                  errors[CodeConfigurationField.SCAN_DESTINATION] = t(
                    `LoggedOutGenerator.ScanDestination.options.youtube.subcomponents.YouTubeInput.errors.invalid`
                  )
                }
              } catch (err) {
                logger.logError(err, { team: 'templates' })
              }
            }
          }
          break

        case LandingPageDestination.GOOGLE_DOC:
          if (!scanDestination) {
            errors[CodeConfigurationField.SCAN_DESTINATION] = t(
              'LoggedOutGenerator.ScanDestination.options.googleDocs.subcomponents.GoogleDocInput.errors.required'
            )
          } else if (!isValidUrl(scanDestination, { host_whitelist: ['docs.google.com'] })) {
            errors[CodeConfigurationField.SCAN_DESTINATION] = t(
              'LoggedOutGenerator.ScanDestination.options.googleDocs.subcomponents.GoogleDocInput.errors.invalid'
            )
          }
      }
    }

    validationFn().then(() => {
      if (Object.values(errors).some(error => error !== undefined)) {
        reject(errors)
      } else {
        resolve({ target: event.target })
      }
    })
  })
}
