// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file common/v1/types.proto (package common.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { Message, proto3 } from '@bufbuild/protobuf'

/**
 * @generated from message common.v1.Creator
 */
export class Creator extends Message<Creator> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = ''

  /**
   * @generated from field: string org_id = 2;
   */
  orgId = ''

  /**
   * @generated from field: string timezone = 3;
   */
  timezone = ''

  /**
   * @generated from field: string email = 4;
   */
  email = ''

  constructor(data?: PartialMessage<Creator>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'common.v1.Creator'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'user_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'timezone', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'email', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Creator {
    return new Creator().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Creator {
    return new Creator().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Creator {
    return new Creator().fromJsonString(jsonString, options)
  }

  static equals(
    a: Creator | PlainMessage<Creator> | undefined,
    b: Creator | PlainMessage<Creator> | undefined
  ): boolean {
    return proto3.util.equals(Creator, a, b)
  }
}
