import { FLOWPAGE_API_ENDPOINT } from '../constants/endpoints'

export const getFlowpageRootUrl = (): string => {
  switch (process.env.APP_ENV) {
    case 'development':
    case 'staging':
      return `https://stg.flow.page`
    case 'preprod':
      return `https://preprod.flow.page`
    case 'production':
    default:
      return `https://flow.page`
  }
}

export const DEFAULT_COLOR = 'greyLight'
export const DEFAULT_LINK_TITLE_FONT_SIZE = '10.5pt' // Our default fontSize for link's text is 14px = 10.5pt
export const defaultUserThemeInput = {
  primaryColor: DEFAULT_COLOR,
  style: 'color',
  profileImageMaskType: 'circle',
  id: 'default-theme',
  linkStyle: 'default',
  linkShape: 'default',
  fontFamily: 'Inter',
  fontColor: null,
  titleColor: null,
  borderColor: null,
  shadowColor: 'rgb(0 0 0 / 35%)',
  linkColor: null,
  textSize: null,
  templateConfig: undefined
}

export const defaultUserTheme = {
  ...defaultUserThemeInput,
  backgroundImgUrl: null,
  __typename: 'Theme' as ThemeTypeName
}

type ThemeTypeName = 'Theme'
export const DEFAULT_PAGE_ID = 'default-page'
type PageTypeName = 'Page'
export const defaultPageAttributes = {
  id: DEFAULT_PAGE_ID,
  displayName: '',
  email: '',
  shortUrl: '',
  profileImage: '',
  caption: '',
  active: true,
  slugName: '',
  share: true,
  isIndexed: false,
  verified: false,
  links: [],
  pageType: undefined,
  theme: defaultUserTheme,
  createdAt: '',
  __typename: 'Page' as PageTypeName
}

export enum FontFamilies {
  'Inter' = 'Inter',
  'Merriweather' = 'Merriweather',
  'IBM Plex Mono' = 'IBM Plex Mono',
  'Oswald' = 'Oswald',
  'Nunito' = 'Nunito',
  'Montserrat' = 'Montserrat',
  'Playfair Display' = 'Playfair Display',
  'EB Garamond' = 'EB Garamond',
  'Dancing Script' = 'Dancing Script',
  'Amatic SC' = 'Amatic SC'
}

export type FontObject = {
  href?: string
  fallbacks: string[]
  iconSrc: string
  free?: boolean
}

export const FONT_STYLESHEETS: { [key in FontFamilies]: FontObject } = {
  Inter: {
    fallbacks: ['sans-serif'],
    iconSrc: '/icons/fonts/Inter.png',
    free: true
  },
  Merriweather: {
    href: 'https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap',
    fallbacks: ['serif'],
    iconSrc: '/icons/fonts/Merriweather.png',
    free: true
  },
  'IBM Plex Mono': {
    href: 'https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap',
    fallbacks: ['monospace'],
    iconSrc: '/icons/fonts/IBM-Plex-Mono.png',
    free: true
  },
  Oswald: {
    href: 'https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap',
    fallbacks: ['sans-serif'],
    iconSrc: '/icons/fonts/Oswald.png'
  },
  Nunito: {
    href: 'https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap',
    fallbacks: ['sans-serif'],
    iconSrc: '/icons/fonts/Nunito.png'
  },
  Montserrat: {
    href: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap',
    fallbacks: ['sans-serif'],
    iconSrc: '/icons/fonts/Montserrat.png'
  },
  'Playfair Display': {
    href: 'https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap',
    fallbacks: ['serif'],
    iconSrc: '/icons/fonts/Playfair-Display.png'
  },
  'EB Garamond': {
    href: 'https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;700&display=swap',
    fallbacks: ['serif'],
    iconSrc: '/icons/fonts/EB-Garamond.png'
  },
  'Dancing Script': {
    href: 'https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap',
    fallbacks: ['cursive'],
    iconSrc: '/icons/fonts/Dancing-Script.png'
  },
  'Amatic SC': {
    href: 'https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap',
    fallbacks: ['cursive'],
    iconSrc: '/icons/fonts/Amatic-SC.png'
  }
}

export const AllFontFamilyOptionNames: FontFamilies[] = Object.keys(
  FONT_STYLESHEETS
) as FontFamilies[]

export const flowpageRoot = getFlowpageRootUrl().replace('https://', '') + '/'
export const shortenedFlowpageRoot = flowpageRoot
export const flowpageUrlRoot = `${process.env.PROTOCOL}://${flowpageRoot}`
export const profileImageUrl = `${FLOWPAGE_API_ENDPOINT}/profileImage`

export type FlowCodeImageTypes = 'svg' | 'jpg' | 'png' | 'pdf' | 'eps' | 'tiff'
export const thumbnailImageUrl = `${FLOWPAGE_API_ENDPOINT}/thumbnailImage`
export const removeImageUrl = `${FLOWPAGE_API_ENDPOINT}/image`
export const uploadPdfUrl = `${FLOWPAGE_API_ENDPOINT}/pdf`
export const uploadMediaUrl = `${FLOWPAGE_API_ENDPOINT}/media`
export const bgImageUrl = `${FLOWPAGE_API_ENDPOINT}/bgImage`
export const checkEUUrl = `${FLOWPAGE_API_ENDPOINT}/isEU`
