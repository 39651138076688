import { ITHACA_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { IthacaJwt, OldIthacaUserData } from './types'
import { JwtPayload } from 'jwt-decode'
import Cookies from 'universal-cookie'
import type { Logger } from '@dtx-company/logger'

const JWT_LOCAL_STORAGE_NAME = 'ithacaToken'

export type OldIthacaJwtData = OldIthacaUserData & JwtPayload
export let refreshToken: string | undefined

// promise pool for /impersonation-token endpoint
const cookies = new Cookies()

let ithacaInitialized = false

export function getIthacaInitialized(): boolean {
  return ithacaInitialized
}

export function ithacaInit(config: {
  sso?: { jwt?: IthacaJwt }
  onIthacaInitialized?: () => void
}): void {
  ithacaInitialized = true
  config.onIthacaInitialized?.()
}

//keep once frontegg is rolled out, important to clear legacy cookies + local storage
export function clearCache(): void {
  refreshToken = undefined
  cookies.remove('ADMIN_TOKEN')
  cookies.remove('token')
  cookies.remove('x-fc-token')
  if (typeof window === 'object') {
    window?.localStorage?.removeItem(JWT_LOCAL_STORAGE_NAME)
  }
}

export async function fetchClaimsFromIthaca(
  ithacaId: string | undefined,
  invalidateCache?: boolean,
  logger?: Logger
): Promise<OldIthacaJwtData | undefined> {
  const param = `?ithacaId=${ithacaId}${invalidateCache ? '&invalid=true' : undefined}`
  const claimsEndpoint = `${ITHACA_ENDPOINT}/v2/token-claims${param}`

  try {
    const res = await fetch(claimsEndpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (res.ok) {
      const json = await res.json()
      const claims: OldIthacaJwtData = json.claims

      if (!claims) {
        throw new Error(
          'Failed to build JWT from Frontegg Access Token - no user data returned from Ithaca'
        )
      } else if (!claims.ithacaId) {
        throw new Error(
          'Failed to build JWT from Frontegg Access Token - no Ithaca ID returned from Ithaca'
        )
      }

      return claims
    } else {
      await logger?.logFetchError(res, { technicalArea: 'authentication' })
    }
  } catch (e) {
    logger?.logError(e, { technicalArea: 'authentication' })
    return undefined
  }
}
