import { ithacaInit } from '@dtx-company/ithaca-sdk/src'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

export function useIthacaInitialization(): void {
  const dispatch = useDispatch()
  useEffect(() => {
    ithacaInit({
      sso: { jwt: undefined }
    })
  }, [dispatch])
}
