import { Theme, useMediaQuery } from '@mui/material'

export interface UseScreenSizeBreakpointsState {
  isLargeDesktop: boolean
  isDesktop: boolean
  isTabletOrDesktop: boolean
  isMobileOrTablet: boolean
  isTablet: boolean
  isMobile: boolean
  isXSMobile: boolean
}

export const useScreenSizeBreakpoints = (): UseScreenSizeBreakpointsState => {
  const isLargeDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'))
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const isTabletOrDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  const isMobileOrTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'lg'))
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const isXSMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return {
    isLargeDesktop,
    isDesktop,
    isTabletOrDesktop,
    isMobileOrTablet,
    isTablet,
    isMobile,
    isXSMobile
  }
}
