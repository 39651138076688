// @generated by protoc-gen-connect-es v0.8.6 with parameter "target=ts,import_extension="
// @generated from file forms/v1/forms.proto (package forms.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  ArchiveFormRequest,
  ArchiveFormResponse,
  CreateFormRequest,
  CreateFormResponse,
  GetFormRequest,
  GetFormResponse,
  ListFormsRequest,
  ListFormsResponse,
  RenderFormRequest,
  RenderFormResponse,
  UpdateFormRequest,
  UpdateFormResponse
} from './forms_pb'
import { MethodKind } from '@bufbuild/protobuf'
import {
  BatchDeleteSubmissionRequest,
  BatchDeleteSubmissionResponse,
  BatchGetSubmissionSummariesRequest,
  BatchGetSubmissionSummariesResponse,
  DeleteSubmissionRequest,
  DeleteSubmissionResponse,
  DownloadSubmissionsCSVRequest,
  GetLiveResultsRequest,
  GetLiveResultsResponse,
  ListSubmissionsRequest,
  ListSubmissionsResponse,
  SubmissionsSummaryRequest,
  SubmissionsSummaryResponse,
  SubmitFormRequest,
  SubmitFormResponse
} from './form_submission_pb'
import { HttpBody } from '../../google/api/httpbody_pb'

/**
 * @generated from service forms.v1.FormsService
 */
export const FormsService = {
  typeName: 'forms.v1.FormsService',
  methods: {
    /**
     * @generated from rpc forms.v1.FormsService.CreateForm
     */
    createForm: {
      name: 'CreateForm',
      I: CreateFormRequest,
      O: CreateFormResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc forms.v1.FormsService.GetForm
     */
    getForm: {
      name: 'GetForm',
      I: GetFormRequest,
      O: GetFormResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc forms.v1.FormsService.RenderForm
     */
    renderForm: {
      name: 'RenderForm',
      I: RenderFormRequest,
      O: RenderFormResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc forms.v1.FormsService.UpdateForm
     */
    updateForm: {
      name: 'UpdateForm',
      I: UpdateFormRequest,
      O: UpdateFormResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc forms.v1.FormsService.ArchiveForm
     */
    archiveForm: {
      name: 'ArchiveForm',
      I: ArchiveFormRequest,
      O: ArchiveFormResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc forms.v1.FormsService.ListForms
     */
    listForms: {
      name: 'ListForms',
      I: ListFormsRequest,
      O: ListFormsResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc forms.v1.FormsService.SubmitForm
     */
    submitForm: {
      name: 'SubmitForm',
      I: SubmitFormRequest,
      O: SubmitFormResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc forms.v1.FormsService.ListSubmissions
     */
    listSubmissions: {
      name: 'ListSubmissions',
      I: ListSubmissionsRequest,
      O: ListSubmissionsResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc forms.v1.FormsService.DeleteSubmission
     */
    deleteSubmission: {
      name: 'DeleteSubmission',
      I: DeleteSubmissionRequest,
      O: DeleteSubmissionResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc forms.v1.FormsService.BatchDeleteSubmission
     */
    batchDeleteSubmission: {
      name: 'BatchDeleteSubmission',
      I: BatchDeleteSubmissionRequest,
      O: BatchDeleteSubmissionResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc forms.v1.FormsService.SubmissionsSummary
     */
    submissionsSummary: {
      name: 'SubmissionsSummary',
      I: SubmissionsSummaryRequest,
      O: SubmissionsSummaryResponse,
      kind: MethodKind.Unary
    },
    /**
     * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME
     *
     * @generated from rpc forms.v1.FormsService.DownloadSubmissionsCSV
     */
    downloadSubmissionsCSV: {
      name: 'DownloadSubmissionsCSV',
      I: DownloadSubmissionsCSVRequest,
      O: HttpBody,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc forms.v1.FormsService.GetLiveResults
     */
    getLiveResults: {
      name: 'GetLiveResults',
      I: GetLiveResultsRequest,
      O: GetLiveResultsResponse,
      kind: MethodKind.ServerStreaming
    },
    /**
     * @generated from rpc forms.v1.FormsService.BatchGetSubmissionSummaries
     */
    batchGetSubmissionSummaries: {
      name: 'BatchGetSubmissionSummaries',
      I: BatchGetSubmissionSummariesRequest,
      O: BatchGetSubmissionSummariesResponse,
      kind: MethodKind.Unary
    }
  }
} as const
