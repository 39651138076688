import { BillingTabDialogContent } from '../../constants/billingTab'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@dtx-company/inter-app/src/redux/types'
import { useSelector } from 'react-redux'

export interface BillingTabDialogState {
  billingTabDialogContent?: BillingTabDialogContent
}

const initialState: BillingTabDialogState = {}

const billingTabDialogSlice = createSlice({
  name: 'billingTabDialog',
  initialState,
  reducers: {
    setBillingTabDialogContent: (
      state,
      action: PayloadAction<BillingTabDialogContent | undefined>
    ) => {
      state.billingTabDialogContent = action.payload
    }
  }
})

export const { setBillingTabDialogContent } = billingTabDialogSlice.actions
export default billingTabDialogSlice.reducer

export const useBillingTabDialog = (): BillingTabDialogState =>
  useSelector((state: RootState) => state.revenueReducer.billingTabDialog)
