import { OneTrustListener, useOneTrustConsentListener } from './useOneTrustConsentListener'
import { useDispatch } from 'react-redux'
import { useFireOneTrustConsentEvent } from './useFireOneTrustConsentEvent'
import { useMemo } from 'react'

/**
 * Listen to OneTrust OnConsentChanged function and remove cookies based on changed preferences
 */
export const useEnableCookies = (): void => {
  const dispatch = useDispatch()
  const listener = useMemo(() => OneTrustListener(dispatch), [dispatch])
  useOneTrustConsentListener(listener)
  useFireOneTrustConsentEvent()
}
