import { SVGProps, memo } from 'react'

function UploadSvg({
  fill = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...rest}>
      <g clipPath="url(#prefix__clip0)">
        <path
          fill={color || fill}
          fillRule="evenodd"
          d="M9 14.75a.75.75 0 001.5 0V2.56l4.72 4.72a.749.749 0 101.06-1.06l-6-6a.749.749 0 00-1.06 0l-6 6a.749.749 0 101.06 1.06L9 2.56v12.19zm-7.5 1.5a.75.75 0 00-1.5 0v2.5c0 .414.336.75.75.75h18a.75.75 0 00.75-.75v-2.5a.75.75 0 00-1.5 0V18H1.5v-1.75z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h19.5v19.5H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Upload = memo(UploadSvg)
