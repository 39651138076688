import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type EditorModes = 'design' | 'edit' | 'move' | 'pristine' | ''

export enum Menu {
  Main,
  BaseBackgroundColor,
  Move,
  Theme,
  Backdrop,
  LinkStyle,
  LinkShape,
  Fonts
}

// For state that effects the editor display but not the page itself
// tldr: other pageEditor slice already has lots of shit in it
export interface EditorUIState {
  userHasOpenedSidebar: boolean
  sidebarOpen: boolean
  menuShown: Menu
  previewMode: boolean
}

const initialEditorUIState: EditorUIState = {
  userHasOpenedSidebar: false,
  sidebarOpen: false,
  previewMode: false,
  menuShown: Menu.Main
}

const editorUISlice = createSlice({
  name: 'editorUI',
  initialState: initialEditorUIState,
  reducers: {
    setSidebarOpen(state, action: PayloadAction<boolean>) {
      state.sidebarOpen = action.payload
      state.userHasOpenedSidebar = true
    },
    setSidebarMenuShown(state, action: PayloadAction<Menu>) {
      state.menuShown = action.payload
    },

    setPreviewMode(state, action: PayloadAction<boolean>) {
      state.previewMode = action.payload
    }
  }
})

export const { setSidebarOpen, setSidebarMenuShown, setPreviewMode } = editorUISlice.actions

export default editorUISlice.reducer
