// @generated by protoc-gen-connect-es v0.8.6 with parameter "target=ts,import_extension="
// @generated from file plans/v1/plans.proto (package plans.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  CreateConfigRequest,
  CreateConfigResponse,
  CreateFeatureOverrideRequest,
  CreateFeatureOverrideResponse,
  CreateFeatureRequest,
  CreateFeatureResponse,
  CreateOrgLinkRequest,
  CreateOrgLinkResponse,
  CreatePlanRequest,
  CreatePlanResponse,
  CreateProfileOverrideRequest,
  CreateProfileOverrideResponse,
  CreateProfileRequest,
  CreateProfileResponse,
  DeleteConfigRequest,
  DeleteConfigResponse,
  DeleteFeatureOverrideRequest,
  DeleteFeatureOverrideResponse,
  DeleteFeatureRequest,
  DeleteFeatureResponse,
  DeletePlanRequest,
  DeletePlanResponse,
  DeleteProfileOverrideRequest,
  DeleteProfileOverrideResponse,
  DeleteProfileRequest,
  DeleteProfileResponse,
  GetConfigRequest,
  GetConfigResponse,
  GetFeatureRequest,
  GetFeatureResponse,
  GetOrgLinkRequest,
  GetOrgLinkResponse,
  GetPlanRequest,
  GetPlanResponse,
  GetProfileRequest,
  GetProfileResponse,
  GetUsageRequest,
  GetUsageResponse,
  ListConfigsRequest,
  ListConfigsResponse,
  ListFeatureOverridesRequest,
  ListFeatureOverridesResponse,
  ListFeaturesRequest,
  ListFeaturesResponse,
  ListOrgProfileRequest,
  ListOrgProfileResponse,
  ListPlansRequest,
  ListPlansResponse,
  ListProfileConfigsRequest,
  ListProfileConfigsResponse,
  ListProfileOverridesRequest,
  ListProfileOverridesResponse,
  ListProfilesRequest,
  ListProfilesResponse,
  UpdateConfigRequest,
  UpdateConfigResponse,
  UpdateProfileConfigRequest,
  UpdateProfileConfigResponse,
  UpdateProfileOverrideRequest,
  UpdateProfileOverrideResponse
} from './plans_pb'
import { MethodKind } from '@bufbuild/protobuf'

/**
 * @generated from service plans.v1.PlansService
 */
export const PlansService = {
  typeName: 'plans.v1.PlansService',
  methods: {
    /**
     * @generated from rpc plans.v1.PlansService.CreateOrgLink
     */
    createOrgLink: {
      name: 'CreateOrgLink',
      I: CreateOrgLinkRequest,
      O: CreateOrgLinkResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.GetOrgLink
     */
    getOrgLink: {
      name: 'GetOrgLink',
      I: GetOrgLinkRequest,
      O: GetOrgLinkResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.GetPlan
     */
    getPlan: {
      name: 'GetPlan',
      I: GetPlanRequest,
      O: GetPlanResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.ListPlans
     */
    listPlans: {
      name: 'ListPlans',
      I: ListPlansRequest,
      O: ListPlansResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.CreatePlan
     */
    createPlan: {
      name: 'CreatePlan',
      I: CreatePlanRequest,
      O: CreatePlanResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.DeletePlan
     */
    deletePlan: {
      name: 'DeletePlan',
      I: DeletePlanRequest,
      O: DeletePlanResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.GetFeature
     */
    getFeature: {
      name: 'GetFeature',
      I: GetFeatureRequest,
      O: GetFeatureResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.ListFeatures
     */
    listFeatures: {
      name: 'ListFeatures',
      I: ListFeaturesRequest,
      O: ListFeaturesResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.CreateFeature
     */
    createFeature: {
      name: 'CreateFeature',
      I: CreateFeatureRequest,
      O: CreateFeatureResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.DeleteFeature
     */
    deleteFeature: {
      name: 'DeleteFeature',
      I: DeleteFeatureRequest,
      O: DeleteFeatureResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.GetConfig
     */
    getConfig: {
      name: 'GetConfig',
      I: GetConfigRequest,
      O: GetConfigResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.UpdateConfig
     */
    updateConfig: {
      name: 'UpdateConfig',
      I: UpdateConfigRequest,
      O: UpdateConfigResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.ListConfigs
     */
    listConfigs: {
      name: 'ListConfigs',
      I: ListConfigsRequest,
      O: ListConfigsResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.CreateConfig
     */
    createConfig: {
      name: 'CreateConfig',
      I: CreateConfigRequest,
      O: CreateConfigResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.DeleteConfig
     */
    deleteConfig: {
      name: 'DeleteConfig',
      I: DeleteConfigRequest,
      O: DeleteConfigResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.GetProfile
     */
    getProfile: {
      name: 'GetProfile',
      I: GetProfileRequest,
      O: GetProfileResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.ListProfiles
     */
    listProfiles: {
      name: 'ListProfiles',
      I: ListProfilesRequest,
      O: ListProfilesResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.CreateProfile
     */
    createProfile: {
      name: 'CreateProfile',
      I: CreateProfileRequest,
      O: CreateProfileResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.DeleteProfile
     */
    deleteProfile: {
      name: 'DeleteProfile',
      I: DeleteProfileRequest,
      O: DeleteProfileResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.UpdateProfileConfig
     */
    updateProfileConfig: {
      name: 'UpdateProfileConfig',
      I: UpdateProfileConfigRequest,
      O: UpdateProfileConfigResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.ListProfileConfigs
     */
    listProfileConfigs: {
      name: 'ListProfileConfigs',
      I: ListProfileConfigsRequest,
      O: ListProfileConfigsResponse,
      kind: MethodKind.Unary
    },
    /**
     * ProfileOverridees:
     * ListProfileOverrides
     *
     * @generated from rpc plans.v1.PlansService.ListProfileOverrides
     */
    listProfileOverrides: {
      name: 'ListProfileOverrides',
      I: ListProfileOverridesRequest,
      O: ListProfileOverridesResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.DeleteProfileOverride
     */
    deleteProfileOverride: {
      name: 'DeleteProfileOverride',
      I: DeleteProfileOverrideRequest,
      O: DeleteProfileOverrideResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.UpdateProfileOverride
     */
    updateProfileOverride: {
      name: 'UpdateProfileOverride',
      I: UpdateProfileOverrideRequest,
      O: UpdateProfileOverrideResponse,
      kind: MethodKind.Unary
    },
    /**
     * FeatureOverride
     *
     * @generated from rpc plans.v1.PlansService.ListFeatureOverrides
     */
    listFeatureOverrides: {
      name: 'ListFeatureOverrides',
      I: ListFeatureOverridesRequest,
      O: ListFeatureOverridesResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.CreateFeatureOverride
     */
    createFeatureOverride: {
      name: 'CreateFeatureOverride',
      I: CreateFeatureOverrideRequest,
      O: CreateFeatureOverrideResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.DeleteFeatureOverride
     */
    deleteFeatureOverride: {
      name: 'DeleteFeatureOverride',
      I: DeleteFeatureOverrideRequest,
      O: DeleteFeatureOverrideResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.ListOrgProfile
     */
    listOrgProfile: {
      name: 'ListOrgProfile',
      I: ListOrgProfileRequest,
      O: ListOrgProfileResponse,
      kind: MethodKind.Unary
    },
    /**
     * FeatureOverride
     *
     * @generated from rpc plans.v1.PlansService.CreateProfileOverride
     */
    createProfileOverride: {
      name: 'CreateProfileOverride',
      I: CreateProfileOverrideRequest,
      O: CreateProfileOverrideResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc plans.v1.PlansService.GetUsage
     */
    getUsage: {
      name: 'GetUsage',
      I: GetUsageRequest,
      O: GetUsageResponse,
      kind: MethodKind.Unary
    }
  }
} as const
