import { getFlowpageHost } from '@dtx-company/true-common/src/utils/urls/host'
import fetch from 'cross-fetch'
import type { AgentFunction } from './ssr-agent'

export const fetchWithAgent: typeof fetch = async (url, requestInit = {}) => {
  let agent: AgentFunction | undefined

  if (typeof window === 'undefined') {
    // import ssr-agent for Server-side, avoiding 25k of polyfill on the client
    agent = await import('./ssr-agent').then(module => module.agent)
  }

  let finalUrl = url
  if (!url.toString().startsWith('http') && typeof window !== 'undefined') {
    finalUrl = `${process.env.PROTOCOL}://${getFlowpageHost(true)}${url}`
  }

  const includeCredentials = !finalUrl.toString().startsWith('https://flowcode-ui.cdn.prismic.io')

  return fetch(finalUrl, {
    // node-fetch on the server-side implements 'agent' property
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    agent,
    ...requestInit,
    ...(includeCredentials ? { credentials: 'include' } : {})
  })
}
