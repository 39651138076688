import { IncomingMessage, ServerResponse } from 'http'
import { buildLinkHeader } from './buildLinkHeader'

export const writeHTMLLinkHeader = (
  statusCode: number,
  req: IncomingMessage,
  res: ServerResponse
): void => {
  const url = req.url
  const html = !!res.getHeader('content-type')?.toString()?.startsWith('text/html')
  if (url && statusCode === 200 && html) {
    const [pathname] = url.split('?')
    const linkHeader = buildLinkHeader(pathname)
    if (linkHeader) {
      res.setHeader('Link', linkHeader)
    }
  }
}
//
