import { SVGProps, memo } from 'react'

function MailSvg({ fill = 'currentcolor', color, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 20 16" {...rest}>
      <path
        fill={color || fill}
        fillRule="evenodd"
        d="M2 1.5h16a.5.5 0 01.5.5v12a.5.5 0 01-.5.5H2a.5.5 0 01-.5-.5V2a.5.5 0 01.5-.5zM0 2a2 2 0 012-2h16a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2zm4.416 1.376a.75.75 0 10-.832 1.248l6 4a.75.75 0 00.832 0l6-4a.75.75 0 10-.832-1.248L10 7.099 4.416 3.376z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export const Mail = memo(MailSvg)

function Mail2Svg({ fill = 'currentcolor', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={17}
      height={14}
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.357.833H1.643C.957.833.5 1.307.5 2.018v10.43c0 .711.457 1.185 1.143 1.185h13.714c.686 0 1.143-.474 1.143-1.185V2.018c0-.71-.457-1.185-1.143-1.185zM14.33 3.915L8.843 7.707a.706.706 0 01-.686 0L2.671 3.915c-.228-.237-.342-.593-.114-.83.229-.237.572-.355.8-.118L8.5 6.522l5.143-3.555c.228-.237.571-.119.8.118.228.237.114.711-.114.83z"
        fill={fill || '#111'}
      />
    </svg>
  )
}

export const Mail2 = memo(Mail2Svg)
