import { FC } from 'react'

export const SnowplowLoadTest: FC = () => {
  return process.env.APP_ENV !== 'production' ? (
    <script
      type="text/javascript"
      crossOrigin="anonymous"
      dangerouslySetInnerHTML={{
        __html: `
            !function(win,doc){win.fcPixel||((pixel=win.fcPixel=function(e,params){pixel.queue.push([e,params]),pixel.processQueue&&pixel.processQueue()}).queue=[],pixel.version="1.0",pixel.app_id="stg-sb-test",(win=doc.createElement("script")).async=!0,win.src="https://cdn.flowcode.com/pixel/generic/fc_pixel_stg_testing.min.js",(doc=doc.getElementsByTagName("script")[0]).parentNode.insertBefore(win,doc))}(window,document);
            `
      }}
    ></script>
  ) : (
    <></>
  )
}
