import { AddSnackPayload, NotificationsStateType, Snack } from './notificationsSlice.types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: NotificationsStateType = {
  snacks: [],
  isOpen: false,
  currentSnack: undefined
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },

    addSnack: (state, action: PayloadAction<AddSnackPayload>) => {
      const snack = { ...action.payload, key: new Date().getTime() }
      state.snacks = [...state.snacks, snack]
    },

    setSnacks: (state, action: PayloadAction<Snack[]>) => {
      state.snacks = action.payload
    },

    setCurrentSnack: (state, action: PayloadAction<Snack | undefined>) => {
      state.currentSnack = action.payload
    }
  }
})

export const { setOpen, addSnack, setCurrentSnack, setSnacks } = notificationsSlice.actions

export default notificationsSlice.reducer
