import { DestinationInputType } from './types'
import { FlowcodeColorOptionType } from '@app/code/src/types/flowcode.types'
import { theme } from '@dtx-company/shared-components/src'

export const CASHAPP_DESTINATION: DestinationInputType = {
  displayText: 'Cashapp',
  stateKey: 'cashapp',
  type: 'text',
  icon: '/icons/cashapp-filled-black.svg',
  placeholder: '$username'
}

export const FILE_DESTINATION: DestinationInputType = {
  displayText: 'File',
  stateKey: 'file',
  type: 'file',
  icon: '/images/landing-page-destinations/file.svg',
  placeholder: 'my_file.pdf (20mb limit)',
  acceptFiles: 'application/pdf,video/mp4,audio/*' // May need to add additional MIME types
}

export const EMAIL_DESTINATION: DestinationInputType = {
  displayText: 'Email',
  stateKey: 'email',
  type: 'email',
  icon: '/icons/email-filled-black.svg',
  placeholder: 'example@gmail.com'
}

export const INSTAGRAM_DESTINATION: DestinationInputType = {
  displayText: 'Instagram',
  stateKey: 'instagram',
  type: 'text',
  icon: '/icons/instagram-outline.svg',
  placeholder: '@handle'
}

export const LINKEDIN_DESTINATION: DestinationInputType = {
  displayText: 'LinkedIn',
  stateKey: 'linkedin',
  type: 'text',
  icon: '/icons/linkedin-filled-black.svg',
  placeholder: 'linkedin.com/in/username'
}

export const PHONE_DESTINATION: DestinationInputType = {
  displayText: 'Call',
  stateKey: 'call',
  type: 'text',
  icon: '/icons/phone-filled.svg',
  placeholder: '555-555-5555'
}

export const SMS_DESTINATION: DestinationInputType = {
  displayText: 'SMS',
  stateKey: 'sms',
  type: 'text',
  icon: '/icons/sms-round-filled.svg',
  placeholder: '555-555-5555'
}

export const SNAPCHAT_DESTINATION: DestinationInputType = {
  displayText: 'Snapchat',
  stateKey: 'snapchat',
  type: 'text',
  icon: '/icons/snapchat-filled-black.svg',
  placeholder: '@username'
}

export const SPOTIFY_DESTINATION: DestinationInputType = {
  displayText: 'Spotify',
  stateKey: 'spotify',
  type: 'text',
  icon: '/icons/spotify-filled-black.svg',
  placeholder: 'https://open.spotify.com/track/'
} as DestinationInputType

export const TIKTOK_DESTINATION: DestinationInputType = {
  displayText: 'Tiktok',
  stateKey: 'tiktok',
  type: 'text',
  icon: '/icons/tiktok-filled-black.svg',
  placeholder: '@username'
}

export const TWITTER_DESTINATION: DestinationInputType = {
  displayText: 'Twitter',
  stateKey: 'twitter',
  type: 'text',
  icon: '/icons/social-media/twitter.svg',
  placeholder: '@username'
} as DestinationInputType

export const VENMO_DESTINATION: DestinationInputType = {
  displayText: 'Venmo',
  stateKey: 'venmo',
  type: 'text',
  icon: '/icons/venmo-filled-black.svg',
  placeholder: '@username'
}

export const WEBSITE_DESTINATION: DestinationInputType = {
  displayText: 'Website',
  stateKey: 'website',
  type: 'text',
  icon: '/images/landing-page-destinations/website.svg',
  placeholder: 'website.com'
}

export const YOUTUBE_DESTINATION: DestinationInputType = {
  displayText: 'YouTube',
  stateKey: 'youtube',
  type: 'text',
  icon: '/icons/youtube-filled.svg',
  placeholder: 'youtube.com'
}

const { builder } = theme.colors

export const BLACK_COLOR: FlowcodeColorOptionType = {
  label: 'Black',
  color: builder.black,
  id: 'black',
  defaultOnly: false,
  index: 0
}

export const BROWN_COLOR: FlowcodeColorOptionType = {
  label: 'Brown',
  color: builder.brownLight,
  id: 'brown',
  defaultOnly: false,
  index: 0
}

export const BROWN_DARK_COLOR: FlowcodeColorOptionType = {
  label: 'Dark Brown',
  color: builder.brownDark,
  id: 'dark_brown',
  defaultOnly: false,
  index: 0
}

export const GREY_COLOR: FlowcodeColorOptionType = {
  label: 'Grey',
  color: builder.greyLight,
  id: 'grey',
  defaultOnly: false,
  index: 1
}

export const GREY_DARK_COLOR: FlowcodeColorOptionType = {
  label: 'Dark Grey',
  color: builder.greyDark,
  id: 'dark_grey',
  defaultOnly: false,
  index: 2
}

export const RED_COLOR: FlowcodeColorOptionType = {
  label: 'Red',
  color: builder.redLight,
  darkColor: builder.redDark,
  id: 'red',
  defaultOnly: true,
  index: 3
}

export const RED_DARK_COLOR: FlowcodeColorOptionType = {
  label: 'Dark Red',
  color: builder.redDark,
  id: 'dark_red',
  defaultOnly: true,
  index: 3
}

export const MAGENTA_COLOR: FlowcodeColorOptionType = {
  label: 'Magenta',
  color: builder.magentaLight,
  id: 'magenta',
  defaultOnly: true,
  index: 3
}

export const MAGENTA_DARK_COLOR: FlowcodeColorOptionType = {
  label: 'Dark Magenta',
  color: builder.magentaDark,
  id: 'dark_magenta',
  defaultOnly: true,
  index: 3
}

export const PUMPKIN_COLOR: FlowcodeColorOptionType = {
  label: 'Pumpkin',
  color: builder.pumpkinLight,
  darkColor: builder.pumpkinDark,
  id: 'pumpkin',
  defaultOnly: true,
  index: 30
}
export const PUMPKIN_DARK_COLOR: FlowcodeColorOptionType = {
  label: 'Dark Pumpkin',
  color: builder.pumpkinDark,
  id: 'dark_pumpkin',
  defaultOnly: true,
  index: 30
}
export const JADE_COLOR: FlowcodeColorOptionType = {
  label: 'Jade',
  color: builder.jadeLight,
  darkColor: builder.jadeDark,
  id: 'jade',
  defaultOnly: true,
  index: 20
}

export const JADE_DARK_COLOR: FlowcodeColorOptionType = {
  label: 'Dark Jade',
  color: builder.jadeDark,
  id: 'dark_jade',
  defaultOnly: true,
  index: 20
}
export const TURQUOISE_COLOR: FlowcodeColorOptionType = {
  label: 'Turquoise',
  color: builder.turquoiseLight,
  darkColor: builder.turquoiseDark,
  id: 'turquoise',
  defaultOnly: true,
  index: 19
}

export const TURQUOISE_DARK_COLOR: FlowcodeColorOptionType = {
  label: 'Dark Turquoise',
  color: builder.turquoiseDark,
  id: 'dark_turquoise',
  defaultOnly: true,
  index: 19
}
export const SKY_COLOR: FlowcodeColorOptionType = {
  label: 'Sky',
  color: builder.skyLight,
  darkColor: builder.skyDark,
  id: 'sky',
  defaultOnly: true,
  index: 17
}
export const BLUE_COLOR: FlowcodeColorOptionType = {
  label: 'Blue',
  color: builder.blueLight,
  darkColor: builder.blueDark,
  id: 'blue',
  defaultOnly: true,
  index: 16
}
export const BLUE_DARK_COLOR: FlowcodeColorOptionType = {
  label: 'Dark Blue',
  color: builder.blueDark,
  id: 'dark_blue',
  defaultOnly: true,
  index: 16
}
export const PURPLE_COLOR: FlowcodeColorOptionType = {
  label: 'Purple',
  color: builder.purpleLight,
  darkColor: builder.purpleDark,
  id: 'purple',
  defaultOnly: true,
  index: 12
}
export const PURPLE_DARK_COLOR: FlowcodeColorOptionType = {
  label: 'Dark Purple',
  color: builder.purpleDark,
  id: 'dark_purple',
  defaultOnly: true,
  index: 12
}
export const FUSCHIA_COLOR: FlowcodeColorOptionType = {
  label: 'Fuschia',
  color: builder.fuchsiaLight,
  darkColor: builder.fuchsiaDark,
  id: 'fuschia',
  defaultOnly: true,
  index: 8
}
export const PINK_COLOR: FlowcodeColorOptionType = {
  label: 'Pink',
  color: builder.pinkLight,
  darkColor: builder.pinkDark,
  id: 'pink',
  defaultOnly: true,
  index: 9
}

export const PINK_DARK_COLOR: FlowcodeColorOptionType = {
  label: 'Dark Pink',
  color: builder.pinkDark,
  id: 'dark_pink',
  defaultOnly: true,
  index: 9
}

export const NAVY_COLOR: FlowcodeColorOptionType = {
  label: 'Navy',
  color: builder.navyLight,
  id: 'navy',
  defaultOnly: true,
  index: 9
}

export const NAVY_DARK_COLOR: FlowcodeColorOptionType = {
  label: 'Dark Navy',
  color: builder.navyDark,
  id: 'dark_navy',
  defaultOnly: true,
  index: 9
}

export const YELLOW_COLOR: FlowcodeColorOptionType = {
  label: 'Yellow',
  color: builder.yellowLight,
  darkColor: builder.yellowLight,
  id: 'yellow',
  defaultOnly: true,
  index: 9
}
export const LIME_COLOR: FlowcodeColorOptionType = {
  label: 'Lime',
  color: builder.lemonLight,
  darkColor: builder.lemonLight,
  id: 'lime',
  defaultOnly: true,
  index: 10
}
