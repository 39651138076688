import { AUTH_CACHE_ID_QUERY_PARAM } from './constants'
import {
  AuthCache,
  CachedValue,
  CachedValueType,
  ClearCachedValue,
  GetCachedValue,
  ParseCachedValue,
  SetCachedValue
} from './types'
import { AuthCacheContext } from './context'
import { CreateCodeArguments } from '@app/code/src/components/Homepage/CreateFlowcodeForm/CreateFlowcodeFormDesktop/CreateFlowcodeFormDesktop.hooks'
import { Router, useRouter } from 'next/router'
import { castToString, deleteFromCache, getFromCache, setInCache } from './utils'
import { useCallback, useContext, useMemo, useRef } from 'react'
import { useOAuthState } from '@dtx-company/true-common/src/authentication/OAuth/hooks'
import { v4 } from 'uuid'

function useNewAuthCacheId(): string {
  return useRef(v4()).current
}

function useExistingAuthCacheId(): string | undefined {
  const { query } = useRouter()
  return useOAuthState()?.authCacheId ?? (query[AUTH_CACHE_ID_QUERY_PARAM] as string)
}

function useAuthCacheId(): string {
  /*
   We either get an existing cache ID available in the 
   current context as a result of an OAuth (+ soon SAML) redirect,
   or we create a new auth ID
  */

  const existingAuthCacheId = useExistingAuthCacheId()
  const newAuthCacheId = useNewAuthCacheId()

  if (existingAuthCacheId) {
    return existingAuthCacheId
  }

  return newAuthCacheId
}

function useCachedValue<T>(
  cacheValueType: CachedValueType,
  authCacheId: string,
  parseCachedValue?: ParseCachedValue<T>
): CachedValue<T> {
  const key = `${cacheValueType}_${authCacheId}`

  const get = useCallback<GetCachedValue<T>>(
    async () => getFromCache(key, parseCachedValue),
    [key, parseCachedValue]
  )
  const set = useCallback<SetCachedValue<T>>(
    async value => setInCache(key, castToString<T>(value)),
    [key]
  )
  const clear = useCallback<ClearCachedValue>(async () => deleteFromCache(key), [key])

  return useMemo(() => ({ get, set, clear }), [get, set, clear])
}

function useCachedLocgFormValues(authCacheId: string): CachedValue<CreateCodeArguments> {
  return useCachedValue<CreateCodeArguments>('locg_form', authCacheId)
}

function useCachedQueryParams(authCacheId: string): CachedValue<Router['query']> {
  const parseCachedValue = useCallback((q: Router['query']) => {
    if (q.redirectTo) {
      q.redirectTo = decodeURIComponent(q.redirectTo as string)
    }
    return q
  }, [])

  return useCachedValue<Router['query']>('query_params', authCacheId, parseCachedValue)
}

export function useNewAuthCache(): AuthCache {
  const id = useAuthCacheId()

  const locgFormValues = useCachedLocgFormValues(id)
  const queryParams = useCachedQueryParams(id)

  return useMemo(() => ({ id, locgFormValues, queryParams }), [id, locgFormValues, queryParams])
}

export function useAuthCache(): AuthCache | null {
  return useContext(AuthCacheContext)
}
