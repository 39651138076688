import { Components, Theme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

/**
 * MUI Theme overrides for Flowcode's [Chip](./Chip.tsx) component.
 */
export const chipComponentOverrides: Components<Theme> = {
  MuiChip: {
    defaultProps: {
      deleteIcon: <CloseIcon />,
      size: 'small',
      variant: 'filled'
    },

    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        transition: 'unset',
        borderRadius: theme.spacing(25),
        maxWidth: theme.spacing(50),

        '&.Mui-selected': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.onSurface.inverse,
          border: 0,
          '&:hover': {
            background: theme.palette.shades.primary.filledHover
          },
          '&:focus, &:active, &:hover:active': {
            boxShadow: 'none',
            background: theme.palette.shades.primary.filledFocus,
            color: 'white'
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.surface.disabled,
            color: theme.palette.onSurface.disabled
          }
        },

        '&.MuiChip-endIcon': {
          '.MuiChip-deleteIcon': {
            cursor: 'unset',
            padding: 0,
            '&:hover': {
              color: 'inherit'
            },

            ...(ownerState.size === 'small' && {
              height: theme.spacing(4.5),
              width: theme.spacing(4.5),
              marginLeft: theme.spacing(0.5),
              marginRight: theme.spacing(2)
            }),
            ...(ownerState.size === 'medium' && {
              height: theme.spacing(5),
              width: theme.spacing(5),
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(3)
            }),
            ...(ownerState.size === 'large' && {
              height: theme.spacing(6),
              width: theme.spacing(6),
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(4)
            })
          }
        }
      }),

      outlined: ({ theme }) => ({
        borderColor: theme.palette.border.outline,
        color: theme.palette.onSurface.primary,
        '&:hover': {
          background: theme.palette.shades.primary.hover,
          borderColor: theme.palette.onSurface.primary
        },
        '&:focus': {
          background: theme.palette.shades.primary.focus,
          borderColor: theme.palette.onSurface.primary
        }
      }),

      filled: ({ theme }) => ({
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.onSurface.primary,
        '&:hover': {
          background: theme.palette.shades.secondary.filledHover
        },
        '&:focus': {
          background: theme.palette.shades.secondary.filledFocus
        }
      }),

      clickable: ({ ownerState, theme }) => ({
        transition: 'unset',
        ...(ownerState.variant === 'outlined' && {
          '&:hover': {
            background: theme.palette.shades.primary.hover,
            borderColor: theme.palette.onSurface.primary
          },
          '&:focus, &:active, &:hover:active, &:focus:hover': {
            boxShadow: 'none',
            background: theme.palette.shades.primary.focus,
            color: theme.palette.onSurface.primary
          }
        }),

        ...(ownerState.variant === 'filled' && {
          '&:hover': {
            background: theme.palette.shades.secondary.filledHover
          },
          '&:focus, &:active, &:hover:active, &:focus:hover': {
            boxShadow: 'none',
            background: theme.palette.shades.secondary.filledFocus,
            '&.Mui-selected': {
              background: theme.palette.shades.primary.filledFocus
            }
          },
          '&.Mui-disabled': {
            background: theme.palette.surface.disabled,
            color: theme.palette.onSurface.disabled
          }
        })
      }),

      sizeSmall: ({ theme }) => ({
        height: theme.spacing(8)
      }),
      sizeMedium: ({ theme }) => ({
        height: theme.spacing(10)
      }),
      sizeLarge: ({ theme }) => ({
        height: theme.spacing(12)
      }),

      labelSmall: ({ ownerState, theme }) => ({
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
        letterSpacing: '0.00625rem',
        fontWeight: 500,
        paddingLeft: ownerState.icon || ownerState.avatar ? theme.spacing(1) : theme.spacing(3),
        paddingRight: ownerState.onDelete ? theme.spacing(1) : theme.spacing(3)
      }),
      labelMedium: ({ ownerState, theme }) => ({
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        letterSpacing: '0.00625rem',
        fontWeight: 500,
        paddingLeft: ownerState.icon || ownerState.avatar ? theme.spacing(1) : theme.spacing(4),
        paddingRight: ownerState.onDelete ? theme.spacing(1) : theme.spacing(4)
      }),
      labelLarge: ({ ownerState, theme }) => ({
        fontSize: '1rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.00625rem',
        fontWeight: 500,
        paddingLeft: ownerState.icon || ownerState.avatar ? theme.spacing(1) : theme.spacing(5),
        paddingRight: ownerState.onDelete ? theme.spacing(1) : theme.spacing(5)
      }),

      icon: {
        color: 'inherit'
      },
      iconSmall: ({ theme }) => ({
        height: theme.spacing(4.5),
        width: theme.spacing(4.5),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(0.5)
      }),
      iconMedium: ({ theme }) => ({
        height: theme.spacing(5),
        width: theme.spacing(5),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(1)
      }),
      iconLarge: ({ theme }) => ({
        height: theme.spacing(6),
        width: theme.spacing(6),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(1)
      }),

      avatarSmall: ({ theme }) => ({
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(0.5)
      }),
      avatarMedium: ({ theme }) => ({
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(1)
      }),
      avatarLarge: ({ theme }) => ({
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(1)
      }),

      deleteIcon: ({ theme }) => ({
        color: 'inherit',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        boxSizing: 'content-box'
      })
    }
  }
}
