import { AutoDesignColorOptions, AutoInputOptionsProps } from '../types/autoDesign'

export const AUTO_DESIGN_FETCH_COUNT = 6
export const HOMEPAGE_AUTO_DESIGN_FETCH_COUNT = 3
export const DEFAULT_AUTO_DESIGN_COLOR = '#000000'
export const COLOR_HEX_LENGTH = 7
export const AUTO_DESIGN_MAX_COLORS = 3
export const AUTO_DESIGN_MIN_COLORS = 1
export const autoDesignOptionsInitialData: AutoInputOptionsProps = {
  logoImageUrl: '',
  logoWidth: 50,
  logoHeight: 50,
  logoFillSvgColor: DEFAULT_AUTO_DESIGN_COLOR,
  logoFillSvgSwitch: false,
  colors: {
    [AutoDesignColorOptions.PRIMARY_COLOR]: DEFAULT_AUTO_DESIGN_COLOR,
    [AutoDesignColorOptions.SECOND_COLOR]: '',
    [AutoDesignColorOptions.THIRD_COLOR]: ''
  },
  ignoreColorPriority: true,
  fetchCodes: true
}

export const autoDesignsDataColor = [
  {
    value: AutoDesignColorOptions.PRIMARY_COLOR,
    label: 'Primary Color'
  },
  {
    value: AutoDesignColorOptions.SECOND_COLOR,
    label: 'Second Color'
  },
  {
    value: AutoDesignColorOptions.THIRD_COLOR,
    label: 'Third Color'
  }
]
