import {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  PositionProps,
  SpaceProps
} from 'styled-system'
import { theme } from '../theme'

interface ButtonStateProps {
  '&:hover'?: ColorProps & BorderProps
  '&.disabled'?: ColorProps & BorderProps
  '&:hover:enabled'?: ColorProps & BorderProps
}

interface ButtonSizeProps {
  '&.withImg'?: LayoutProps &
    PositionProps & { div?: LayoutProps & PositionProps & SpaceProps & FlexboxProps }
}

export type ButtonColorOptions =
  | 'primary'
  | 'primaryBlack'
  | 'primaryOnDark'
  | 'secondary'
  | 'secondaryBlack'
  | 'secondaryOnDark'
  | 'tertiary'
  | 'tertiaryBlack'
  | 'tertiaryOnDark'
  | 'invisible'
  | 'pageNav'
export type ButtonSizeOptions =
  | 'small'
  | 'medium'
  | 'large'
  | 'mobileFullWidth'
  | 'mobileHalf'
  | 'invisible'

export interface ButtonColorVariantProps {
  primary: ColorProps & BorderProps & ButtonStateProps
  primaryBlack: ColorProps & BorderProps & ButtonStateProps
  primaryOnDark: ColorProps & BorderProps & ButtonStateProps
  secondary: ColorProps & BorderProps & ButtonStateProps
  secondaryBlack: ColorProps & BorderProps & ButtonStateProps
  secondaryOnDark: ColorProps & BorderProps & ButtonStateProps
  tertiary: ColorProps & BorderProps & ButtonStateProps
  tertiaryBlack: ColorProps & BorderProps & ButtonStateProps
  tertiaryOnDark: ColorProps & BorderProps & ButtonStateProps
  invisible: ColorProps & BorderProps & ButtonStateProps
  pageNav: ColorProps & BorderProps & ButtonStateProps
}

export const BUTTON_COLOR_VARIANTS: ButtonColorVariantProps = {
  primary: {
    color: 'primary.white',
    backgroundColor: 'primary.flowBlue',
    borderColor: 'primary.flowBlue',
    '&:hover': {
      backgroundColor: 'masterWeb.blue2',
      borderColor: 'masterWeb.blue2'
    },
    '&.disabled': {
      color: 'secondary.disabled',
      backgroundColor: 'secondary.border',
      borderColor: 'secondary.border'
    }
  },
  primaryBlack: {
    color: 'primary.white',
    backgroundColor: 'primary.black',
    borderColor: 'primary.black',
    '&.disabled': {
      color: 'primary.grey',
      backgroundColor: 'secondary.border',
      borderColor: 'secondary.border'
    }
  },
  primaryOnDark: {
    color: 'primary.black',
    backgroundColor: 'primary.white',
    borderColor: 'primary.white',
    '&.disabled': {
      color: 'primary.grey',
      backgroundColor: 'primary.black',
      borderColor: 'primary.black'
    }
  },
  secondary: {
    backgroundColor: 'primary.white',
    color: 'primary.flowBlue',
    borderColor: 'primary.flowBlue',
    '&:hover:enabled': {
      opacity: '60%'
    },
    '&.disabled': {
      color: 'secondary.disabled',
      backgroundColor: 'secondary.border',
      borderColor: 'secondary.disabled'
    }
  },
  secondaryBlack: {
    color: 'primary.black',
    backgroundColor: 'primary.white',
    borderColor: 'primary.black',
    '&.disabled': {
      color: 'primary.grey',
      backgroundColor: 'secondary.border',
      borderColor: 'secondary.border'
    }
  },
  secondaryOnDark: {
    color: 'primary.flowBlue',
    backgroundColor: 'primary.white',
    borderColor: 'primary.white'
  },
  tertiary: {
    color: 'primary.flowBlue',
    backgroundColor: 'unset',
    border: 'none',
    '&:hover': {
      color: 'masterWeb.blue2'
    },
    '&.disabled': {
      color: 'secondary.disabled'
    }
  },
  tertiaryBlack: {
    color: 'primary.black',
    backgroundColor: 'primary.white',
    borderColor: 'secondary.border'
  },
  tertiaryOnDark: {
    color: 'primary.white',
    backgroundColor: 'primary.black',
    borderColor: 'primary.white'
  },
  invisible: {
    color: 'inherit',
    backgroundColor: 'transparent',
    borderColor: 'transparent'
  },
  pageNav: {
    color: theme.colors.primary.flowBlue,
    backgroundColor: theme.colors.secondary.backgroundDark,
    borderColor: theme.colors.secondary.border,
    '&:hover': {
      color: 'masterWeb.blue2'
    },
    '&.disabled': {
      color: 'secondary.placeholder',
      backgroundColor: 'secondary.border',
      borderColor: 'secondary.border'
    }
  }
}

export interface ButtonSizeVariantProps {
  small: LayoutProps & ButtonSizeProps
  medium: LayoutProps & ButtonSizeProps
  large: LayoutProps & ButtonSizeProps
  mobileFullWidth: LayoutProps & ButtonSizeProps
  mobileHalf: LayoutProps & ButtonSizeProps
  invisible: LayoutProps & ButtonSizeProps
}

const flexBase = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

export const BUTTON_SIZE_VARIANTS: ButtonSizeVariantProps = {
  small: {
    width: '59px',
    height: '21px',
    '&.withImg': {
      width: '79px',
      div: {
        ...flexBase,
        maxHeight: '10px',
        maxWidth: '10px'
      }
    }
  },
  medium: {
    width: '89px',
    height: '35px',
    '&.withImg': {
      width: '109px',
      div: {
        ...flexBase,
        maxHeight: '10px',
        maxWidth: '10px'
      }
    }
  },
  large: {
    width: '109px',
    maxWidth: '135px',
    height: '41px',
    '&.withImg': {
      width: '109px',
      div: {
        ...flexBase,
        maxHeight: '16px',
        maxWidth: '16px'
      }
    }
  },
  mobileFullWidth: {
    width: '100%',
    maxWidth: '90vw',
    height: '48px',
    '&.withImg': {
      position: 'relative',
      div: {
        ...flexBase,
        maxWidth: '44px',
        maxHeight: '44px'
      }
    }
  },
  mobileHalf: {
    width: '50%',
    maxWidth: '45vw',
    height: '48px',
    '&.withImg': {
      position: 'relative',
      div: {
        ...flexBase,
        position: 'absolute',
        left: '8px',
        top: 'calc(50% - 22px)',
        maxWidth: '44px',
        maxHeight: '44px'
      }
    }
  },
  invisible: {}
}

export const ANIMATED_BUTTON_SIZE_VARIANTS: ButtonSizeVariantProps = {
  small: {
    width: '79px',
    height: '21px',
    '&.withImg': {
      div: {
        ...flexBase,
        maxHeight: '10px',
        maxWidth: '10px'
      }
    }
  },
  medium: {
    width: '109px',
    height: '35px',
    '&.withImg': {
      div: {
        ...flexBase,
        maxHeight: '10px',
        maxWidth: '10px'
      }
    }
  },
  large: {
    width: '109px',
    maxWidth: '135px',
    height: '41px',
    '&.withImg': {
      div: {
        ...flexBase,
        maxHeight: '16px',
        maxWidth: '16px'
      }
    }
  },
  mobileFullWidth: {
    width: '100%',
    maxWidth: '90vw',
    height: '48px',
    '&.withImg': {
      position: 'relative',
      div: {
        ...flexBase,
        position: 'absolute',
        left: '8px',
        top: 'calc(50% - 22px)',
        maxWidth: '44px',
        maxHeight: '44px'
      }
    }
  },
  mobileHalf: {
    width: '50%',
    maxWidth: '45vw',
    height: '48px',
    '&.withImg': {
      position: 'relative',
      div: {
        ...flexBase,
        position: 'absolute',
        left: '8px',
        top: 'calc(50% - 22px)',
        maxWidth: '44px',
        maxHeight: '44px'
      }
    }
  },
  invisible: {}
}
