import { TempAssetEvent } from '../locgMachine.types'
import { createMachine } from 'xstate'
import { deleteTempAsset } from '../services/deleteTempAsset'
import { uploadTempAsset } from '../services/uploadTempAsset'

export const tempAssetMachine = createMachine(
  {
    tsTypes: {} as import('./tempAssetMachine.typegen').Typegen0,
    schema: {
      context: {} as Record<never, never>,
      events: {} as TempAssetEvent
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
    context: {},
    id: 'TempAssetMachine',
    initial: 'idle',
    states: {
      idle: {
        on: {
          UPLOAD_TEMP_ASSET: {
            target: 'uploading'
          },
          DELETE_TEMP_ASSET: {
            target: 'deleting'
          }
        }
      },
      uploading: {
        invoke: {
          id: 'TempAssetUploader',
          src: 'TempAssetUploader',
          onDone: 'idle',
          onError: {
            target: 'idle',
            actions: () => console.error('error uploading logo')
          }
        }
      },
      deleting: {
        invoke: {
          id: 'TempAssetDeleter',
          src: 'TempAssetDeleter',
          onDone: 'idle',
          onError: {
            target: 'idle',
            actions: () => console.error('error deleting logo')
          }
        }
      }
    }
  },
  {
    actions: {},
    services: {
      TempAssetUploader: uploadTempAsset,
      TempAssetDeleter: deleteTempAsset
    },
    guards: {}
  }
)
