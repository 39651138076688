import { buildManifest } from './buildManifest'
import { reactLoadableManifest } from './reactLoadableManifest'
import memoize from 'lodash/memoize'
import unique from 'lodash/uniq'

const generateBuildLinkHeader = (pathname: string): string | undefined => {
  const pages = buildManifest()
  const reactLoadable = reactLoadableManifest()
  const appAssets = pages['/_app'] ?? []
  const pageAssets = pages[pathname] ?? []

  if (!pageAssets?.length) {
    return undefined
  }

  return unique(appAssets.concat(pageAssets))
    .filter(resource => resource.endsWith('.js') || resource.endsWith('.css'))
    .filter(resource => !reactLoadable.includes(resource))
    .map(resource => {
      const as = resource.endsWith('.js') ? 'script' : 'style'
      return `</_next/${resource}>; rel=preload; as=${as}`
    })
    .join(', ')
}

export const buildLinkHeader = memoize(generateBuildLinkHeader)
