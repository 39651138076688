export const MAX_EMAIL_CHARS = 64
export const SIGN_IN_ERROR_MESSAGE =
  'Your email or password is wrong, please check that you typed in both correctly.'
export const SIGN_UP_ERROR_MESSAGE = 'Something went wrong during sign up. Please try again.'
export const USER_ALREADY_EXIST_ERROR_MESSAGE = 'User already exists'
export const LOCAL_STORAGE_REDIRECT_PATH_KEY = 'LOCAL_STORAGE_REDIRECT_PATH_KEY'
export const LOCAL_STORAGE_ASSET_CREATE_CODE = 'LOCAL_STORAGE_ASSET_CREATE_CODE'
export const LOCAL_STORAGE_ASSET_CREATE_PAGE = 'LOCAL_STORAGE_ASSET_CREATE_PAGE'

export const CONVERSIONS_SSO_REDIRECT_COOKIE = 'fc_return_to'

export const NEW_SIGN_UP_ERROR_MESSAGE =
  'The email provided is already associated with an account. If you previously signed up via Google, please sign in via Google.'
