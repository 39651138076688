import { IthacaFilter } from '@dtx-company/flow-codegen/src/page/generated.types'
import {
  PagesFilter,
  PagesFilterActionType,
  PagesFilterDirectionActionType,
  PagesFilterValueActionType,
  SelectedFlowpageViewType
} from '../../types/pageManagement'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SelectedFlowpageView } from '../../types/flowpage'
import { SortDirections } from '@dtx-company/true-common/src/types/generic'

export interface PageManagementState {
  selectedFlowpageView: SelectedFlowpageView
  pagesFilter: {
    name: PagesFilter
    direction: SortDirections
    contains: string
  }
  displayBrowseTemplatesTooltip: boolean
  ithacaFilter: IthacaFilter
}

const initialState: PageManagementState = {
  selectedFlowpageView: SelectedFlowpageView.LIST,
  pagesFilter: {
    name: PagesFilter.NAME,
    direction: SortDirections.ASC,
    contains: ''
  },
  displayBrowseTemplatesTooltip: false,
  ithacaFilter: IthacaFilter.Owned
}

const pageManagement = createSlice({
  name: 'pageManagement',
  initialState,
  reducers: {
    setPagesFilter: (state, action: PagesFilterActionType) => {
      state.pagesFilter.name = action.payload || PagesFilter.NAME
    },
    setPagesFilterDirection: (state, action: PagesFilterDirectionActionType) => {
      state.pagesFilter.direction = action.payload ?? SortDirections.ASC
    },
    setPagesFilterValue: (state, action: PagesFilterValueActionType) => {
      state.pagesFilter.contains = action.payload ?? ''
    },
    setSelectedFlowpageView: (state, action: SelectedFlowpageViewType) => {
      state.selectedFlowpageView = action.payload
    },
    setDisplayBrowseTemplateTooltip: (state, action: PayloadAction<boolean>) => {
      state.displayBrowseTemplatesTooltip = action.payload
    },
    setIthacaFilter: (state, action: PayloadAction<IthacaFilter>) => {
      state.ithacaFilter = action.payload
    }
  }
})

export const {
  setPagesFilter,
  setSelectedFlowpageView,
  setPagesFilterDirection,
  setPagesFilterValue,
  setDisplayBrowseTemplateTooltip,
  setIthacaFilter
} = pageManagement.actions

export default pageManagement.reducer
