import { SVGProps, memo } from 'react'

function PDFSvg({ fill = 'currentcolor', color, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 20 25" {...rest}>
      <path
        fill={color || fill}
        fillRule="evenodd"
        d="M18.5 23a.5.5 0 01-.5.5H2a.5.5 0 01-.5-.5V2a.5.5 0 01.5-.5h8.5a.5.5 0 01.5.5v4.5A2.5 2.5 0 0013.5 9H18a.5.5 0 01.5.5V23zM20 9.5V23a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2.004 2.004 0 012-2h10.245a2 2 0 011.475.649l5.754 6.278c.338.368.526.85.526 1.351V9.5zm-7.553-7.957c.063.027.12.068.167.12L17.965 7.5H13.5a1 1 0 01-1-1V2c0-.157-.018-.31-.053-.457z"
        clipRule="evenodd"
      />
      <path
        fill={color || fill}
        d="M15.717 15.458c-.292-.467-.992-.642-2.159-.642-.408 0-.816 0-1.225.059-.291-.175-.583-.35-.816-.525-.584-.467-1.109-1.342-1.517-2.392 0 0 0-.058.058-.117.292-1.225.584-2.741 0-3.383a.748.748 0 00-.583-.233h-.233c-.35 0-.642.35-.759.7-.35 1.225-.116 1.925.234 3.033-.234.875-.525 1.808-1.05 2.8-.292.642-.642 1.225-.875 1.692a8.15 8.15 0 00-.817.466c-1.108.7-1.692 1.517-1.75 1.984-.058.175-.058.35 0 .466v.059l.467.291c.116.059.291.117.408.117.758 0 1.633-.875 2.8-2.917.058 0 .117-.058.175-.058.992-.292 2.158-.525 3.792-.7.933.467 2.1.7 2.8.7.408 0 .7-.117.875-.292.175-.175.233-.408.291-.641 0-.175 0-.35-.116-.467zM4.983 19.19c.059-.35.467-.991 1.167-1.575.117-.058.233-.175.467-.291-.7 1.108-1.225 1.691-1.634 1.866zM9.3 8.866c.058-.058.058-.116.117-.116l.116.058c.175.233.175.525.059 1.05v.175c-.059.233-.059.467-.175.758-.234-.875-.234-1.516-.117-1.925zm-1.167 7.35l-.116.059c.058-.292.291-.642.466-.992.409-.817.759-1.575.934-2.275.408.875.875 1.517 1.458 2.042.117.116.233.233.408.291-.816.117-1.925.409-3.15.875zm7.059-.058h-.234c-.408 0-1.108-.175-1.808-.467.058-.116.175-.116.233-.116 1.342 0 1.692.233 1.809.35.058.058.058.058.058.116 0 .059 0 .059-.058.117z"
      />
    </svg>
  )
}

export const PDF = memo(PDFSvg)
