export enum ServerSideErrorType {
  // error 5**
  ServerSideInternalError = 'server_internal_error',
  // error 4**
  ServerSideValidationError = 'server_validation_error',
  // server side error not 5** and not 4** https://developer.mozilla.org/en-US/docs/Web/HTTP/Status#client_error_responses
  ServerSideOtherError = 'server_other_error'
}

export enum ClientSideErrorType {
  FetchError = 'fetch_error',
  // frontend validation errors: forms, inputs, actions. So we know where validation is affecting user interaction
  ClientSideValidationError = 'client_side_validation_error',
  // JS or some other not error
  ClientSideError = 'client_side_error',
  // Unknown Error. Try to not use it
  DefaultError = 'default_error'
}

const between = (x: number, min: number, max: number): boolean => {
  return x >= min && x <= max
}
export const getServerSideErrorType = (status: number): ServerSideErrorType => {
  if (between(status, 400, 499)) return ServerSideErrorType.ServerSideValidationError
  else if (between(status, 500, 599)) return ServerSideErrorType.ServerSideInternalError
  else return ServerSideErrorType.ServerSideOtherError
}
