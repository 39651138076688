import { AnyAction, CombinedState, Reducer, combineReducers } from '@reduxjs/toolkit'
import formReducer, { FormState } from './formSlice'

export const formsCombinedReducers: Reducer<
  CombinedState<{
    formReducer: FormState
  }>,
  AnyAction
> = combineReducers({
  formReducer: formReducer
})

export const rootFormsReducer: Reducer = (state, action) => {
  return formsCombinedReducers(state, action)
}

export type RootFormsState = ReturnType<typeof formsCombinedReducers>
