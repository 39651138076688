import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { PDFActionData } from '../../types/flowpage'

export function validatePDFActionData(
  aData: Record<string, unknown> | null | undefined,
  expectedField: string
): aData is PDFActionData {
  return Boolean(aData && expectedField in aData)
}

export function getValidatedPDFActionData(
  aData: Record<string, unknown> | null | undefined
): PDFActionData {
  if (validatePDFActionData(aData, 'link')) {
    return aData
  }
  throw Error(`Malformed action data, field link doesn't exist on ${JSON.stringify(aData)}`)
}

export function filterDeprecatedBioLinks(
  links?: (LinkType | null)[] | null
): (LinkType | null)[] | undefined {
  const newLinks = links?.filter((link: LinkType | null) => !link?.actionData?.fromDeprecatedBio)
  return newLinks && newLinks?.length > 0 ? newLinks : undefined
}
