import fs from 'fs'

let _buildManifest: Record<string, string[] | undefined>
export const buildManifest = (): Record<string, string[] | undefined> => {
  if (_buildManifest === undefined) {
    try {
      const fileContents = fs.readFileSync(`${process.cwd()}/.next/build-manifest.json`, {
        encoding: 'utf8'
      })
      _buildManifest = JSON.parse(fileContents)['pages']
    } catch (e) {
      console.warn(`${__filename} could not load build-manifest.json`)
      _buildManifest = {}
    }
  }
  return _buildManifest
}
