import { AnyAction, CombinedState, Reducer, combineReducers } from '@reduxjs/toolkit'
import { CodeManagementStateType } from '../../types/flowcode'
import { CombinedGeneratorStateType } from '@dtx-company/app/shared/code/src/types/combinedGenerator.types'
import { FlowpageGeneratorPreviewStateType } from '@dtx-company/app/shared/code/src/types/flowpageGeneratorPreview.types'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import { ScanToOwnGenericSliceState } from '../../types/ScanToOwnGeneric'
import codeDetailsPageReducer, {
  CodeDetailsPageState
} from '@dtx-company/inter-app/src/redux/slices/codeDetailsPage'
import codeManagementReducer from '../../redux/slices/code-management-slice'
import combinedGeneratorReducer from '@dtx-company/app/shared/code/src/redux/slices/combinedGeneratorSlice'
import flowpageGeneratorPreviewReducer from '@dtx-company/app/shared/code/src/redux/slices/flowpageGeneratorPreviewSlice'
import homePageReducer, {
  HomePageState
} from '@dtx-company/inter-app/src/redux/slices/homePageSlice'
import scanDestinationReducer, { ScanDestinationState } from '../slices/scanDestinationSlice'
import scanToOwnReducer from '@dtx-company/inter-app/src/redux/slices/ScanToOwnGenericSlice'
import settingsReducer, {
  SettingsStateType
} from '@dtx-company/inter-app/src/redux/slices/settings-slice'
import unifiedAssetManagementReducer, {
  UnifiedAssetManagementState
} from '../slices/unifiedAssetManagement/unifiedAssetManagementSlice'

export const codeCombinedReducer: Reducer<
  CombinedState<{
    codeManagement: CodeManagementStateType & PersistPartial
    codeDetailsPage: CodeDetailsPageState
    combinedGenerator: CombinedGeneratorStateType
    flowpageGeneratorPreview: FlowpageGeneratorPreviewStateType
    homePage: HomePageState
    settings: SettingsStateType
    scanToOwn: ScanToOwnGenericSliceState
    scanDestination: ScanDestinationState
    unifiedAssetManagement: UnifiedAssetManagementState
  }>,
  AnyAction
> = combineReducers({
  codeManagement: codeManagementReducer,
  codeDetailsPage: codeDetailsPageReducer,
  combinedGenerator: combinedGeneratorReducer,
  flowpageGeneratorPreview: flowpageGeneratorPreviewReducer,
  homePage: homePageReducer,
  settings: settingsReducer,
  scanToOwn: scanToOwnReducer,
  scanDestination: scanDestinationReducer,
  unifiedAssetManagement: unifiedAssetManagementReducer
})
