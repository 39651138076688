import { Domains } from '@dtx-company/true-common/src/types/domains'
import { HubspotEvent } from '@dtx-company/true-common/src/types/hubspot'
import { IS_PRODUCTION } from '@dtx-company/true-common/src/constants/env'
import { SignUpLocation } from '@dtx-company/ithaca-sdk/src'
import {
  fireBingEvent,
  fireGtmEvent,
  fireHeapEvent,
  fireHubspotEvent,
  fireOptimizelyEvent
} from '../helpers'
import eventNames from '../constants/account'

const e = {
  /*
    DF - this is currently firing for both flowcode + flowpage users. Potentially rename to userCreatedFlocode account and only fire for flowcode users
   */
  userCreatedAccount(
    email: string,
    product: Nullable<Domains>,
    signUpLocation?: SignUpLocation
  ): void {
    const { userCreatedAccount } = eventNames
    fireGtmEvent(userCreatedAccount, { enhanced_conversion_data: { email } })
    fireHeapEvent(userCreatedAccount, { email, signUpLocation })
    fireOptimizelyEvent(userCreatedAccount)
    if (product === Domains.FLOWCODE) {
      fireHubspotEvent(HubspotEvent.FlowcodeAccountCreation)
    }
  },
  userCreatedFlowpageAccount(email: string): void {
    const { userCreatedFlowpageAccount } = eventNames
    fireGtmEvent(userCreatedFlowpageAccount)
    fireHeapEvent(userCreatedFlowpageAccount)
    fireBingEvent('Account Created', 'Flowpage')
    fireHubspotEvent(HubspotEvent.FlowpageAccountCreation)
    if (IS_PRODUCTION) {
      import('../helpers/hubspot')
        .then(mod => mod.submitHubspotForm)
        .then(submitHubspotForm =>
          submitHubspotForm({
            id: 'flowpage',
            fields: {
              email
            }
          })
        )
        .catch(console.error)
    }
  },
  userOptedOutOfMarketingEmails(): void {
    const { userOptedOutOfMarketingEmails } = eventNames
    fireHeapEvent(userOptedOutOfMarketingEmails)
  },
  Revenue_AccountCommunication_Toggled_AccountEmailsPreference(toggle: boolean): void {
    const { Revenue_AccountCommunication_Toggled_AccountEmailsPreference } = eventNames
    fireHeapEvent(Revenue_AccountCommunication_Toggled_AccountEmailsPreference, {
      toggle
    })
  },
  Revenue_AccountCommunication_Clicked_SaveEmailPreferences(): void {
    const { Revenue_AccountCommunication_Clicked_SaveEmailPreferences } = eventNames
    fireHeapEvent(Revenue_AccountCommunication_Clicked_SaveEmailPreferences)
  },
  Account_UpdatePassword_Focused_PasswordInput(passwordType: string): void {
    const { Account_UpdatePassword_Focused_PasswordInput } = eventNames
    fireHeapEvent(Account_UpdatePassword_Focused_PasswordInput, { password_type: passwordType })
  },
  Account_UpdatePassword_Toggled_EyeIcon(passwordType: string, showPassword: boolean): void {
    const { Account_UpdatePassword_Toggled_EyeIcon } = eventNames
    fireHeapEvent(Account_UpdatePassword_Toggled_EyeIcon, {
      password_type: passwordType,
      show_password: showPassword
    })
  },
  Account_UpdatePassword_Successfully_Updated_Password(): void {
    const { Account_UpdatePassword_Successfully_Updated_Password } = eventNames
    fireHeapEvent(Account_UpdatePassword_Successfully_Updated_Password)
  }
}

export default e
