type PaddingType = {
  section: {
    sm: string
    md: string
    lg: string
  }
}
export const padding: PaddingType = {
  section: {
    sm: '45px',
    md: '100px',
    lg: '200px 145px'
  }
}
