import { PaletteOptions } from '@mui/material/styles'

export const darkPalette: PaletteOptions = {
  mode: 'dark',
  primary: {
    light: '#F6F3Ef',
    main: '#EBE7EO',
    dark: '#D3CEC5',
    contrastText: '#343332'
  },
  secondary: {
    light: 'rgba(226, 205, 182, 0.16)',
    main: 'rgba(226, 205, 182, 0.32)',
    dark: 'rgba(226, 205, 182, 0.44)',
    contrastText: 'rgba(0,0,0,0.87)'
  },
  tertiary: {
    light: '#A7E7BD',
    main: '#7BDA9D',
    dark: '#5FBE83',
    contrastText: '#FFFFFF'
  },
  error: {
    light: '#FFDAD6',
    main: '#FFB4AB',
    dark: '#FF897D',
    contrastText: 'rgba(0,0,0,0.87)'
  },
  info: {
    light: '#D8E2FF',
    main: '#B5C4FF',
    dark: '#90A7FD',
    contrastText: 'rgba(0,0,0,0.87)'
  },
  success: {
    light: '#A7E7BD',
    main: '#7BDA9D',
    dark: '#5FBE83',
    contrastText: 'rgba(0,0,0,0.87)'
  },
  warning: {
    light: '#FFF1CC',
    main: '#FFE088',
    dark: '#FCCE78',
    contrastText: '#FFFFFF'
  },
  text: {
    primary: '#EBE7E0',
    secondary: '#B7B4AE',
    disabled: 'rgba(235, 231, 224, 0.38)'
  },
  divider: 'rgba(226, 205, 182, 0.24)',
  background: {
    paper: 'rgba(226, 205, 182, 0.05)',
    default: 'rgba(226, 205, 182, 0.24)'
  },
  action: {
    active: 'rgba(255, 255, 255, 0.54)',
    hover: 'rgba(255, 255, 255, 0.08)',
    selected: 'rgba(255, 255, 255, 0.12)',
    disabled: 'rgba(235, 231, 224, 0.12)',
    disabledBackground: 'rgba(235, 231, 224, 0.38)',
    focus: 'rgba(255, 255, 255, 0.16)',
    tooltip: 'rgba(31, 31, 31, 0.9)'
  },
  shades: {
    primary: {
      hover: 'rgba(226, 205, 182, 0.16)',
      focus: 'rgba(226, 205, 182, 0.32)',
      selected: 'rgba(226, 205, 182, 0.24)',
      filledHover: 'linear-gradient(0deg, rgba(36, 36, 36, 0.08), rgba(36, 36, 36, 0.08)), #EBE7E0',
      filledFocus: 'linear-gradient(0deg, rgba(36, 36, 36, 0.24), rgba(36, 36, 36, 0.24)), #EBE7E0'
    },
    secondary: {
      filledHover:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), rgba(226, 205, 182, 0.32)',
      filledFocus:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), rgba(226, 205, 182, 0.32)'
    },
    tertiary: {
      hover: 'rgba(123, 218, 157, 0.16)',
      focus: 'rgba(123, 218, 157, 0.32)',
      filledHover: 'linear-gradient(0deg, rgba(36, 36, 36, 0.08), rgba(36, 36, 36, 0.08)), #7BDA9D',
      filledFocus: 'linear-gradient(0deg, rgba(36, 36, 36, 0.24), rgba(36, 36, 36, 0.24)), #7BDA9D',
      filledDisabled:
        'linear-gradient(0deg, rgba(35, 135, 83, 0.38), rgba(35, 135, 83, 0.38)), #FFFFFF'
    },
    error: {
      hover: 'rgba(255, 180, 171, 0.18)',
      focus: 'rgba(255, 180, 171, 0.32)',
      filledHover: 'linear-gradient(0deg, rgba(36, 36, 36, 0.08), rgba(36, 36, 36, 0.08)), #FFB4AB',
      filledFocus: 'linear-gradient(0deg, rgba(36, 36, 36, 0.24), rgba(36, 36, 36, 0.24)), #FFB4AB'
    }
  }
}
