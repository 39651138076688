import { jam } from '@jam.dev/sdk'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useBillingPlanType } from '@app/common/src/hooks/useBillingPlanType/useBillingPlanType'
import { useEffect } from 'react'

export function useJamMetadata(): null {
  const { jwt } = useAuthState()

  const billingPlanType = useBillingPlanType()

  useEffect(() => {
    if (!jwt || !billingPlanType) return
    const { ithacaId: userId, email, org } = jwt

    jam.metadata(() => {
      return {
        userId,
        email,
        billingPlanType,
        orgName: org?.name,
        orgId: org?.orgId,
        orgRole: org?.userOrgRole
      }
    })
  }, [billingPlanType, jwt])

  return null
}
