import { FC } from 'react'
import { FlowSession } from '@dtx-company/inter-app/src/types/FlowSession'
import { useAnonymousCookie } from '../hooks/useAnonymousCookie'
import { useBrowserSessionCookie } from '../hooks/useBrowserSessionCookie'
import { useClearDeprecatedAuthState } from '@dtx-company/inter-app/src/hooks/useClearDeprecatedAuthState'
import { useCookieObserver } from '@dtx-company/true-common/src/utils/cookies/cookies.hooks'
import { useEnableCookies } from '../../../shared/common/src/hooks/useEnableCookies'
import { useFetchGeolocation } from '../../../shared/common/src/hooks/useFetchGeolocation'
import { useGetLockedFeatures } from '../../../shared/common/src/hooks/useGetLockedFeatures/useGetLockedFeatures'
import { useHeapAttributes } from '../hooks/useHeapAttributes'
import { useHeapTrackerEventProperties } from '../hooks/useHeapTrackerEventProperties'
import { useInitializeAuthServices } from '../../../components/_app/hooks/auth/useInitializeAuthServices'
import { useJamMetadata } from './useJamMetadata'
import { usePersistUtmParams } from '../../../shared/common/src/hooks/usePersistUtmParams'
import { useRDSCookieSync } from '../../../shared/common/src/hooks/useRDSCookieSync'
import { useSubdomainRedirect } from '../hooks/useSubdomainRedirect'

// Contains hooks that need to be called within _app, and within the Redux Provider
export const HooksRequiringProviders: FC<{ session?: FlowSession | undefined }> = ({
  session
}): JSX.Element => {
  useCookieObserver()
  useEnableCookies()
  useRDSCookieSync()
  useAnonymousCookie()
  useBrowserSessionCookie(session?.sessionId ?? '')
  useFetchGeolocation(session?.location?.continent ?? '', session?.location?.country ?? '')
  usePersistUtmParams()
  useHeapAttributes()
  useHeapTrackerEventProperties()
  useClearDeprecatedAuthState()
  useInitializeAuthServices()
  useSubdomainRedirect()
  useJamMetadata()
  useGetLockedFeatures()

  return <></>
}
//
