import * as yup from 'yup'

export const venmoSchema = yup.object().shape({
  recipients: yup
    .string()
    .test('invalid recipients', '${path} was invalid', val => val && /^[A-Za-z0-9_-]+$/.test(val)),
  amount: yup.number().min(0).notRequired(),
  note: yup.string().notRequired()
})

export const emailSchema = yup.object().shape({
  email: yup.string().email().required(),
  subject: yup.string().notRequired(),
  body: yup.string().notRequired()
})

export const smsSchema = yup.object().shape({
  phoneNumber: yup.string().required(),
  message: yup.string().notRequired()
})

export const phoneSchema = yup.object().shape({
  phoneNumber: yup.string().required()
})

export const wifiSchema = yup.object().shape({
  ssid: yup.string().required(),
  authenticationType: yup
    .string()
    .notRequired()
    .matches(/(WEP|WPA|WPA2-EAP|nopass)/),
  password: yup.mixed().when('authenticationType', {
    is: val => !!val && val !== 'nopass',
    then: yup.string().required(),
    otherwise: yup.string().notRequired()
  }),
  hidden: yup.string().notRequired().matches(/true/),
  eapMethod: yup.string().notRequired(),
  anonIdentity: yup.string().notRequired(),
  identity: yup.string().notRequired(),
  phaseTwoMethod: yup.string().notRequired()
})

export const cashAppSchema = yup.object().shape({
  username: yup.string().required(),
  amount: yup.number().min(0).notRequired()
})
