import { AnyAction, CombinedState, Reducer, combineReducers } from '@reduxjs/toolkit'
import { LinkEditorSliceType } from '@dtx-company/inter-app/src/redux/slices/linkEditor/linkEditorSlice.types'
import { PageEditorState } from '../slices/pageEditor/pageEditor.types'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import { SocialFilesState } from '../../types/flowpage'
import consumerBannerReducer, {
  FlowtagBannerStateType
} from '@dtx-company/inter-app/src/redux/slices/FlowtagBannerSlice'
import createPageModalReducer, {
  CreatePageModalState
} from '@dtx-company/inter-app/src/redux/slices/createPageModalSlice'
import createPageReducer, { CreatePageState } from '../slices/createPageSlice'
import drawerReducer, { DrawerState } from '../slices/drawer'
import editorUIReducer, { EditorUIState } from '@dtx-company/inter-app/src/redux/slices/editorUI'
import flowpageTemplatesReducer, {
  FlowpageTemplateState
} from '../slices/flowpageTemplates/flowpageTemplatesSlice'
import fpFunnelReducer, {
  FPFunnelStateType
} from '@dtx-company/inter-app/src/redux/slices/flowpageFunnelSlice'
import genericReducer, {
  PageGenericState
} from '@dtx-company/inter-app/src/redux/slices/PageGenericSlice'
import linkEditReducer from '@dtx-company/inter-app/src/redux/slices/linkEditor/linkEditorSlice'
import modalReducer, { ModalState } from '../slices/modal'
import pageEditorReducer from '../slices/pageEditor/pageEditorSlice'
import pageManagementReducer, { PageManagementState } from '../slices/pageManagementSlice'
import socialFilesReducer from '@dtx-company/inter-app/src/redux/slices/OnboardingSocialsSlice'

export const pageCombinedReducers: Reducer<
  CombinedState<{
    pageEditorReducer: PageEditorState & PersistPartial
    modalReducer: ModalState
    drawerReducer: DrawerState
    fpFunnelReducer: FPFunnelStateType
    editorUIReducer: EditorUIState
    pageManagementReducer: PageManagementState
    genericReducer: PageGenericState
    linkEditReducer: LinkEditorSliceType
    consumerBannerReducer: FlowtagBannerStateType & PersistPartial
    socialFilesReducer: SocialFilesState
    flowpageTemplatesReducer: FlowpageTemplateState
    createPageModalReducer: CreatePageModalState
    createPage: CreatePageState
  }>,
  AnyAction
> = combineReducers({
  pageEditorReducer,
  modalReducer,
  drawerReducer,
  fpFunnelReducer,
  editorUIReducer,
  pageManagementReducer,
  genericReducer,
  linkEditReducer,
  consumerBannerReducer,
  socialFilesReducer,
  flowpageTemplatesReducer,
  createPageModalReducer,
  createPage: createPageReducer
})

export const rootPageReducer: Reducer = (state, action) => {
  return pageCombinedReducers(state, action)
}
