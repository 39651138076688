import {
  AnalyticsTotalType,
  AssetLabelType
} from '@dtx-company/flow-codegen/src/code/generated.types'
import { FcGeneratorOptions } from '@dtx-company/flowcode-generator-browser/src'
import { PayloadAction } from '@reduxjs/toolkit'

export interface FlowcodeTemplateType {
  options?: FcGeneratorOptions
  configuration: string
  id?: string
  name?: string
  assetFile?: {
    assetName: string
    url: string
  }
  isCustomizable: boolean
  isInvertible?: boolean
}

export interface GetFlowcodeTemplatesResponseType {
  flowcodeTemplates: FlowcodeTemplateType[]
}

export enum SocialMediaDestinations {
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  TIKTOK = 'TIKTOK',
  SNAPCHAT = 'SNAPCHAT',
  TWITTER = 'TWITTER',
  SPOTIFY = 'SPOTIFY'
}

export interface LandingPageDestinationType {
  id: LandingPageDestination
  label: string
  placeholder?: string
  inputLabel?: string
  alfredIcon?: boolean
  // Has prefix if its a social link and no placeholder
  prefix?: string
  src: string
  alt?: string
  srcSet?: string | undefined
  objectFit?: React.CSSProperties['objectFit']
}

export enum FileDestinations {
  FILE = 'FILE',
  IMAGE = 'IMAGE',
  PDF = 'PDF'
}

export type NodeType<T> = {
  node: T
}
export type FlowcodeNodeType = NodeType<FlowcodeType>

export interface FlowcodeType {
  id: string
  nickname?: string
  creationSource?: string
  type?: string
  activeLink: {
    id: string
    redirectValue: string
    shorturl: string
    redirectType: string
  }
  domain: string
  assetFileName?: string
  lastModified: string
  timeStamp: string
  directory?: {
    name: string
    id: string
  }
  analytics: {
    total?: AnalyticsTotalType
    lastScanDate?: string
  }
  studioConfig?: {
    id: string
    configuration: string | null
    isCustomizable: boolean
    source: string
    name: string
  }
  entityPermissions?: EntityPermissionsType[]
  batchOwner: {
    isOwner: boolean
    user?: {
      ithacaId: string
      fullName: string
    }
  }
  assetLabels?: AssetLabelType[]
  batchRules?: {
    id: string
    interstitial: string
  }
}

export interface FlowcodeDerivedValues {
  flowcodeId: string
  nickname?: string
  flowcodeOptions: FcGeneratorOptions
  isStaticCode: boolean
  flowcodeImageUrl: string | undefined
  studioConfigSource?: StudioConfigSourceType
  redirectType: LandingPageDestination
  displayRedirectValue?: string
  timeStamp: string | undefined
  shortUrl: string | undefined
  directoryName: string | undefined
}

export enum StudioConfigSourceType {
  STUDIO = 'STUDIO',
  DIY = 'DIY',
  AUTODESIGN = 'AUTODESIGN'
}

export enum CreateFlowcodeBulkModalState {
  IS_VALIDATING = 'isValidating',
  ERROR = 'error',
  IS_CREATED = 'isCreated',
  IS_CREATING = 'isCreating'
}

export enum DownloadFolderZipResponseModalState {
  NEW_GENERATION = 'newGeneration',
  IN_PROGRESS_GENERATION = 'inProgressGeneration',
  DOWNLOADING_ZIP = 'downloadingZip'
}

export enum ManagementFilter {
  NAME = 'nickname',
  TIMESTAMP = 'time_stamp',
  SCANCOUNT = 'scans',
  SCANTIME = 'scan_time',
  ACTIVITY = 'activity',
  ACTIVITYTODAY = 'activity_today',
  PERMISSIONS = 'permissions',
  LAST_MODIFIED = 'last_modified'
}

export enum FilterDirection {
  ASC = '',
  DSC = '-'
}

export interface FlowcodesFilterType {
  name: ManagementFilter
  direction: FilterDirection
  contains: string
}

export interface FlowcodesSortByType {
  name: ManagementFilter
  direction: FilterDirection
}

export interface EventType {
  name: string
  startTime: string
  url: string
  location: string
  endTime: string
}

export interface LandingPageFormValuesType {
  link: string
  subtypeId?: string
  sms: {
    message: string
    phoneNumber: string
  }
  countryCode?: string
  phone: string
  email: {
    email: string
    subject: string
    body: string
  }
  venmo: {
    amount: number | undefined
    recipients: string
    note: string
  }
  file: File | null
  event: EventType
  wifi: Partial<{
    ssid: string
    authenticationType: string
    password: string
    hidden: string
    eapMethod: string
    anonIdentity: string
    identity: string
    phaseTwoMethod: string
  }>
  vcard: {
    id?: string
    first_name?: string
    last_name?: string
    company?: string
    title?: string
    email?: string
    phone?: string
    phone_country_code?: string
    work_phone?: string
    work_phone_country_code?: string
    website?: string
    street?: string
    street2?: string
    city?: string
    state?: string
    zipcode?: string
    country?: string
  }
}

export enum DrawerTypes {
  COLOR,
  PATTERN,
  THEME,
  LOGO,
  AUTODESIGNS,
  SAVEDDESIGNS,
  PREMADE,
  SCRATCH
}

export enum LandingPageDestination {
  URL = 'URL',
  VENMO = 'VENMO',
  WEBSITE = 'WEBSITE',
  SMS = 'SMS',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  EVENT = 'EVENT',
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  TIKTOK = 'TIKTOK',
  SNAPCHAT = 'SNAPCHAT',
  TWITTER = 'TWITTER',
  SPOTIFY = 'SPOTIFY',
  YOUTUBE = 'YOUTUBE',
  FILE = 'FILE',
  WIFI = 'WIFI',
  SCAN_TO_OWN = 'SCAN_TO_OWN',
  GOOGLE_DOC = 'GOOGLE_DOC',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  IMAGE = 'IMAGE',
  PDF = 'PDF',
  FLOWPAGE = 'FLOWPAGE',
  VCARD = 'VCARD'
}

export enum PermissionsTypes {
  OWNER = 'Owner',
  CREATOR = 'Creator',
  EDITOR = 'Editor',
  VIEWER = 'Viewer'
}

export interface EntityPermissionsType {
  entityId: string
  entityName: string
  entityType: number
  permissionName: string
}

export enum FlowcodeFolderLevel {
  PARENT,
  CHILD
}

export interface FlowcodeFolderType {
  node: {
    id: string
    name: string
    level?: FlowcodeFolderLevel
    timeStamp: string
    batchCount: number
    descendantCount: number
    analytics: {
      total: {
        scans: number
      }
      lastScanDate?: string
    }
    user: {
      fullName: string
      ithacaId: string
    }
    entityPermissions?: EntityPermissionsType[]
    isOwner: boolean
    assetLabels?: AssetLabelType[]
  }
}

type SortDirections = 'asc' | 'dsc'

export enum SelectedFlowcodeView {
  GRID,
  LIST
}

export interface ImageType {
  source: string
  alt?: string
}

export type BulkActionKey = 'codes' | `folder:${number}`

export interface CodeManagementStateType {
  transferFlowcodeModal: {
    isOpen: boolean
    batchId: string
  }
  emailFlowcodeModal: {
    batchId: string
    isOpen: boolean
  }
  downloadFlowcodeModal: {
    isOpen: boolean
    flowcode: FlowcodeType | undefined
    batchId: string
    nickname: string
  }
  permissionsModal: {
    isOpen: boolean
    batchId: string
    directoryId: string
    isBatch: boolean
    isPage: boolean
    pageId: string
    pageTemplateId: string
    isPageTemplate: boolean
  }
  displayMobileActionsModal: boolean
  displayCreateFolderModal: boolean
  selectedFlowcodeView: SelectedFlowcodeView
  highlightFilter: {
    name: ManagementFilter
    direction: FilterDirection
  }
  flowcodesFilter: FlowcodesFilterType
  deleteFlowcodeModal: {
    id: string
    isOpen: boolean
    isBulkActions: boolean
  }
  removeFlowcodeModal: {
    id: string
    isOpen: boolean
    isFolder: boolean
    entityId: string | null
  }
  createFlowcodeBulkModal: {
    isOpen: boolean
    processState: CreateFlowcodeBulkModalState | null
    folderName: string
    flowcodeCount: number
    errorMessages: string[]
    badResponse: boolean
    waitedForCodes: boolean
  }
  hasDownloadJustOccurred: boolean // used to show the downloading tooltip
  isDownloadInProgress: boolean // used to show the loading spinner
  openDrawerId?: string
  hasFolderLinksDownloadJustOccurred: boolean // used to show the download tooltip for csv links
  isFolderLinksDownloadInProgress: boolean // used to show download spinner for csv links
  downloadFolderZipResponseModal: {
    isOpen: boolean
    processState: DownloadFolderZipResponseModalState | null
    folderName: string
  }
  bulkActions: {
    isShiftKeyPressed: boolean
    previouslySelectedId: string
    selectAllButtonChecked: boolean
    batchIds: Record<BulkActionKey, string[]>
    folderIds: Record<BulkActionKey, string[]>
  }
  confirmMoveFlowcodeModal: {
    isOpen: boolean
    batchId: string
    folderId: string
    folderName?: string
  }
  isPerformingBulkActionsOperation: boolean
  // api data
  flowcodes: FlowcodeNodeType[]
  folders: FlowcodeFolderType[]
}
export interface PageInfoType {
  startCursor: string
  endCursor: string
  hasNextPage: boolean
}

export interface FetchFlowcodesQueryResponseType {
  myFlowcodes: {
    allBatches: { edges: FlowcodeNodeType[]; pageInfo: PageInfoType; totalCount: number }
    highlighted: {
      edges: FlowcodeNodeType[]
    }
  }
}

export interface GetSortDirectionsType {
  initialSortDirection: SortDirections
  controlledSortDirection: SortDirections
}

export interface GetOrderByType {
  flowcodesOrderBy: string
  foldersOrderBy: string
  highlightedOrderBy: string
}

export type SetFlowcodeFilterActionType = PayloadAction<{
  name?: ManagementFilter
  direction?: SortDirections | ''
  contains?: string
}>

export type GetFlowcodesSuccessActionType = PayloadAction<FetchFlowcodesQueryResponseType>

export type ToggleFlowcodeModalActionType = PayloadAction<{
  batchId?: string
  isBulkActions?: boolean
  originalFolderId?: string
  nickname?: string
}>

export type CreateFlowcodeBulkModalActionType = PayloadAction<{
  processState?: CreateFlowcodeBulkModalState | null
  folderName?: string
  flowcodeCount?: number
  errorMessages?: string[]
  badResponse?: boolean
  waitedForCodes?: boolean
}>

export type DownloadFolderZipResponseModalActionType = PayloadAction<{
  processState?: DownloadFolderZipResponseModalState | null
  folderName?: string
}>

export type PermissionsModalActionType = PayloadAction<{
  batchId?: string
  directoryId?: string
  isBatch?: boolean
  isPage?: boolean
  pageId?: string
  pageTemplateId?: string
  isPageTemplate?: boolean
}>

export interface FetchFoldersVariablesType {
  first?: number
  before?: string
  after?: string
  contains?: string
  orderBy?: string
  parentDirectoryId?: string
}

export interface FetchFlowcodesVariablesType {
  orderHighlightedBy?: string
  after?: string
  contains?: string
  orderBy?: string
  directoryId?: string
  withStudioConfig?: boolean
  parentDirectoryId?: string
}

export enum FlowcodeDownloadOptionMimeType {
  JPG = 'jpg',
  PNG = 'png',
  SVG = 'svg',
  PDF = 'pdf',
  CMYK = 'pdf',
  EPS = 'eps'
}
export interface DownloadModalFileType {
  value: FlowcodeDownloadOptionMimeType
  label: string
}

export interface FlowcodeColorOptionType {
  label: string
  color: string
  darkColor?: string
  id: string
  defaultOnly: boolean
  index: number
}

export enum CodeConfigurationField {
  SCAN_DESTINATION_TYPE = 'scanDestinationType',
  SCAN_DESTINATION = 'scanDestination',
  COLOR = 'color',
  PATTERN = 'pattern',
  LOGO = 'logo',
  SUBJECT = 'subject',
  BODY = 'body',
  FILE = 'file',
  REGION_CODE = 'regionCode',
  TOP_RIM_TEXT = 'topRimText',
  BOTTOM_RIM_TEXT = 'bottomRimText',
  SHAPE = 'shape',
  FRAME = 'frame',
  AUTOGENERATED_OPTIONS = 'autogeneratedOptions'
}
