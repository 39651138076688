import { IthacaJwt } from '@dtx-company/ithaca-sdk/src'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface UserState {
  isAuthChecked: boolean
  currentUserData: IthacaJwt | null
  impersonatedUserData: IthacaJwt | null
  /**
   * Set to true when the user is new (created via sign-up) and account was made within 5 minutes.
   */
  isNewUser: boolean
  isFronteggUser: boolean
}

export const initialUserState: UserState = {
  isAuthChecked: false,
  currentUserData: null,
  impersonatedUserData: null,
  isNewUser: false,
  isFronteggUser: false
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setAuthChecked: state => {
      state.isAuthChecked = true
    },
    setCurrentUserData: (
      state,
      action: PayloadAction<{ data: IthacaJwt; isNewUser?: boolean; isFronteggUser?: boolean }>
    ) => {
      state.isAuthChecked = true
      state.currentUserData = action.payload.data
      state.isNewUser = action.payload.isNewUser ?? false
      state.isFronteggUser = action.payload.isFronteggUser ?? false
    },
    /**
     * Clears a user's data when they log out of the app
     */
    clearUserData: state => {
      state.isAuthChecked = true
      state.currentUserData = initialUserState.currentUserData
      state.impersonatedUserData = initialUserState.impersonatedUserData
      state.isNewUser = initialUserState.isNewUser
      state.isFronteggUser = initialUserState.isFronteggUser
    },
    setImpersonatedUserData: (state, action: PayloadAction<{ data: IthacaJwt }>) => {
      state.isAuthChecked = true
      state.impersonatedUserData = action.payload.data
    },
    clearImpersonatedUserData: state => {
      state.impersonatedUserData = null
    }
  }
})

export const {
  setAuthChecked,
  setCurrentUserData,
  setImpersonatedUserData,
  clearImpersonatedUserData,
  clearUserData
} = userSlice.actions

export default userSlice.reducer
