import { createHash } from 'crypto'

export function sha1Hash(message: string): string {
  const hashBuffer = createHash('sha1').update(message, 'utf8').digest()
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
}

type Conversion = {
  orderId: string
  customerId: string
  customerEmail: string
  currencyCode: string
  orderPromoCode: string
  orderDiscount: number
  items: { category: string; quantity: number; sku: string; subTotal: number }[]
}

export function fireImpactTrackConversion(data: Conversion): void {
  const { customerEmail, ...rest } = data
  window?.ire?.(
    'trackConversion',
    41109,
    { ...rest, customerEmail: sha1Hash(customerEmail) },
    {
      verifySiteDefinitionMatch: true
    }
  )
}
