import { GET_CURRENT_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { logger } from '@dtx-company/logger'

export async function getCurrentImpersonationAccessTokenCookie(): Promise<string | null> {
  try {
    const res = await fetch(GET_CURRENT_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (!res.ok) {
      await logger.logFetchError(res, { technicalArea: 'authentication' })
      return null
    }
    const { impersonationAccessToken } = (await res.json()) as {
      impersonationAccessToken: string | null
    }
    return impersonationAccessToken
  } catch (e: unknown) {
    logger.logError(e, { technicalArea: 'authentication' })
    return null
  }
}
