import { FLOWCODE_API_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '../../../redux/slices/flowpageApiSlice/utils'

const flowcodeApiUrl = FLOWCODE_API_ENDPOINT

export const flowcodeApi = createApi({
  reducerPath: 'flowcodeApi',
  baseQuery: graphqlRequestBaseQuery({
    options: { url: flowcodeApiUrl + '/graphql' }
  }),
  tagTypes: [
    'ShoppingCart',
    'flowcodeCount',
    'EstimatedDeliveryDate',
    'OtherPopularItems',
    'assignedFlowcodeTemplates',
    'userSavedFlowcodeTemplates',
    'flowcodeTemplatesCount'
  ],
  endpoints: () => ({})
})
