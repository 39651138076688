import { ApprovedValuesTextVariants, ComponentDefinedTextVariants } from '../types'

export const APPROVED_VALUES_TEXT_VARIANTS: ApprovedValuesTextVariants = {
  'display/xLarge': {
    element: 'h1',
    fontSize: '42px',
    fontWeight: 600,
    lineHeight: '51px',
    color: 'primary.black'
  },
  'display/large': {
    element: 'h2',
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '44px',
    color: 'primary.black'
  },
  'display/medium': {
    element: 'h3',
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '34px',
    color: 'primary.black'
  },
  'display/small': {
    element: 'h4',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '29px',
    color: 'primary.black'
  },
  'body/large': {
    element: 'p',
    fontSize: '20px',
    fontWeight: 'normal',
    lineHeight: '24px',
    color: 'primary.black'
  },
  'body/medium': {
    element: 'p',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '19px',
    color: 'primary.black'
  },
  'body/small': {
    element: 'p',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '17px',
    color: 'primary.black'
  },
  'general/captionAndLabel': {
    element: 'p',
    fontSize: '12px',
    fontWeight: 400,
    color: 'primary.black'
  }
}

export const COMPONENT_DEFINED_TEXT_VARIANTS: ComponentDefinedTextVariants = {
  'accordion/content': APPROVED_VALUES_TEXT_VARIANTS['body/small'],
  'accordion/secondary': APPROVED_VALUES_TEXT_VARIANTS['body/medium'],
  'accordion/title': APPROVED_VALUES_TEXT_VARIANTS['body/medium'],
  'banner/desktop': {
    ...APPROVED_VALUES_TEXT_VARIANTS['body/small'],
    fontWeight: 700
  },
  'banner/mobile': {
    ...APPROVED_VALUES_TEXT_VARIANTS['general/captionAndLabel'],
    fontWeight: 700
  },
  'button/pageNav': {
    ...APPROVED_VALUES_TEXT_VARIANTS['body/small'],
    fontWeight: 700
  },
  'button/input': {
    ...APPROVED_VALUES_TEXT_VARIANTS['body/small'],
    fontWeight: 700
  },
  'button/general': {
    ...APPROVED_VALUES_TEXT_VARIANTS['body/medium'],
    fontWeight: 700
  },
  'button/link': {
    ...APPROVED_VALUES_TEXT_VARIANTS['body/small'],
    textDecoration: 'underline'
  },
  'chart/label': APPROVED_VALUES_TEXT_VARIANTS['body/small'],
  'chart/message': APPROVED_VALUES_TEXT_VARIANTS['body/medium'],
  'chart/title': APPROVED_VALUES_TEXT_VARIANTS['body/medium'],
  'checkbox/regular': APPROVED_VALUES_TEXT_VARIANTS['body/medium'],
  'checkbox/small': APPROVED_VALUES_TEXT_VARIANTS['general/captionAndLabel'],
  'header/page': APPROVED_VALUES_TEXT_VARIANTS['body/large'],
  'header/section': APPROVED_VALUES_TEXT_VARIANTS['body/small'],
  'header/subheader': {
    ...APPROVED_VALUES_TEXT_VARIANTS['body/medium'],
    fontWeight: 600
  },
  'input/field': APPROVED_VALUES_TEXT_VARIANTS['body/medium'],
  'input/helper': APPROVED_VALUES_TEXT_VARIANTS['general/captionAndLabel'],
  'input/label': APPROVED_VALUES_TEXT_VARIANTS['general/captionAndLabel'],
  'navigation/header': {
    ...APPROVED_VALUES_TEXT_VARIANTS['body/small'],
    fontWeight: 600
  },
  'navigation/footer': APPROVED_VALUES_TEXT_VARIANTS['body/small'],
  'navigation/trademark': APPROVED_VALUES_TEXT_VARIANTS['general/captionAndLabel'],
  'navigation/sidebar': APPROVED_VALUES_TEXT_VARIANTS['body/small'],
  'tab/active': {
    ...APPROVED_VALUES_TEXT_VARIANTS['body/small'],
    fontWeight: 700
  },
  'tab/inactive': APPROVED_VALUES_TEXT_VARIANTS['body/small'],
  'pill/active': {
    ...APPROVED_VALUES_TEXT_VARIANTS['body/medium'],
    fontWeight: 700
  },
  'pill/inactive': APPROVED_VALUES_TEXT_VARIANTS['body/medium'],
  'radio/regular': APPROVED_VALUES_TEXT_VARIANTS['body/medium'],
  'table/entry': APPROVED_VALUES_TEXT_VARIANTS['body/small'],
  'table/label': APPROVED_VALUES_TEXT_VARIANTS['general/captionAndLabel'],
  'tag/regular': APPROVED_VALUES_TEXT_VARIANTS['general/captionAndLabel'],
  'tag/bold': {
    ...APPROVED_VALUES_TEXT_VARIANTS['general/captionAndLabel'],
    fontWeight: 700
  },
  'toast/content': APPROVED_VALUES_TEXT_VARIANTS['body/medium'],
  'toggle/input': APPROVED_VALUES_TEXT_VARIANTS['body/small'],
  'toggle/label': APPROVED_VALUES_TEXT_VARIANTS['general/captionAndLabel'],
  'toggle/pageNav': {
    ...APPROVED_VALUES_TEXT_VARIANTS['body/small'],
    fontWeight: 700
  }
}

export const TEXT_VARIANTS = {
  ...APPROVED_VALUES_TEXT_VARIANTS,
  ...COMPONENT_DEFINED_TEXT_VARIANTS
}
