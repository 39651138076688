import { EnhancedStore, Reducer, StoreEnhancer, configureStore } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import { createInjectorsEnhancer } from 'redux-injectors'
import { createReducer } from '../reducers/root'
import { flowcodeApi } from '../slices/flowcodeApiSlice/init'
import { flowpageApi } from '../slices/flowpageApiSlice/empty'
import { objectMapDeep } from '@dtx-company/true-common/src/utils/objects'
import createSagaMiddleware from 'redux-saga'
import mapValues from 'lodash/mapValues'

const removeDataStrings = (obj: Record<string, unknown>): Record<string, unknown> =>
  objectMapDeep(obj, o =>
    mapValues(o, v =>
      typeof v === 'string' && v.startsWith('data:') ? v.split(',')[0] + ',<BYTES>' : v
    )
  )

export type CreateReduxStoreOptions = {
  preloadedState?: Record<string, unknown>
  additionalReducers?: Record<string, Reducer>
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function* sagaThatDoesNothing(): Generator<void> {}

export const createReduxStore = (): EnhancedStore => {
  const middleware = createSagaMiddleware()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - added because the StoreEnhancer types from redux-injectors and @reduxjs/toolkit are incompatible
  const enhancer: StoreEnhancer = createInjectorsEnhancer({
    createReducer: injectedReducers =>
      createReducer({
        ...injectedReducers
      }),
    // runSaga is required in this function, so we add this middleware that does nothing
    runSaga: () => middleware.run(() => sagaThatDoesNothing())
  })
  const enhancers: StoreEnhancer[] = [enhancer]

  return configureStore({
    // Hacky types are fine, only affects dev tools
    // Their types are broken :()
    devTools: {
      trace: true,
      actionSanitizer: <T>(action: T): T =>
        removeDataStrings(action as Record<string, unknown>) as unknown as T,
      stateSanitizer: <T>(s: T): T =>
        removeDataStrings(s as Record<string, unknown>) as unknown as T
    },
    reducer: createReducer(),
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          warnAfter: 50
        }
      })
        .concat(flowpageApi.middleware)
        .concat(flowcodeApi.middleware)
    },
    enhancers
  })
}

export const store = createReduxStore()
