import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface AssetFilterValue {
  name?: string
  id: string
  type: AssetFilterType
  imgUrl?: string
  isRoot?: boolean
  children?: AssetFilterValue[]
}

export type AssetFilterType = 'code' | 'folder' | 'tag' | 'page' | 'team'

export type AssetFiltersRecord = Record<AssetFilterValue['id'], AssetFilterValue>

export interface AssetFilterValuesState {
  activeFilters: AssetFiltersRecord
  showAllAssets: boolean
}

const initialState: AssetFilterValuesState = {
  activeFilters: {},
  showAllAssets: true
}

const assetFiltersSlice = createSlice({
  name: 'assetFilters',
  initialState: initialState,
  reducers: {
    addFilter(state, action: PayloadAction<AssetFilterValue>) {
      state.activeFilters = { ...state.activeFilters, [action.payload.id]: action.payload }
    },
    removeFilter(state, action: PayloadAction<AssetFilterValue>) {
      const newFilters = { ...state.activeFilters }
      delete newFilters[action.payload.id]
      state.activeFilters = newFilters
    },
    setFilters(state, action: PayloadAction<AssetFilterValue[]>) {
      const result = {} as AssetFiltersRecord

      action.payload.map(item => (result[item.id] = item))

      state.activeFilters = result
    },
    clearFilters(state) {
      state.activeFilters = {}
    },
    setShowAllAssets(state, action: PayloadAction<boolean>) {
      state.showAllAssets = action.payload
    }
  }
})

export const { addFilter, removeFilter, setFilters, clearFilters, setShowAllAssets } =
  assetFiltersSlice.actions
export default assetFiltersSlice.reducer
