import { ClientSideErrorType, FlowError } from './types'
import { LoggerOptions } from './types/LoggerOptions'
import { LoggerPayload } from './types/LoggerPayload'
import { buildOptionsForFetchError } from './buildOptionsForFetchError'
import { sendLog } from './utils/sendLog'

export { LoggerErrorBoundary } from './components/LoggerErrorBoundary/LoggerErrorBoundary'
export { handleDatadogLogger } from './utils/handleDatadogLogger'
export * from './types'

export const logger = {
  /**
   * Log an info message
   */
  info(payload: LoggerPayload): void {
    sendLog('info', payload)
  },
  /**
   * Log a warning message
   */
  warn(payload: LoggerPayload): void {
    sendLog('warn', payload)
  },
  /**
   * Log an error message
   *
   * Do not do `logger.error({ ...error, team: '...' })` - typescript will say it's fine
   * but js won't actually copy the properties, use `logError` instead
   * @see logger.logError
   *
   * @example
   * logger.error({
   *   name: 'Some Name',
   *   message: 'Some message that gives more details',
   *   team: 'foo'
   * })
   */
  error(payload: LoggerPayload): void {
    sendLog('error', payload)
  },
  /**
   * Logs an error caught in a `catch`, for synchronous, async, and promise code.
   *
   * @example
   * try {
   *   doSomething()
   * } catch(error) {
   *   logger.logError(error, { team: '...'})
   * }
   *
   * @example
   * somePromise().then(...).catch((reason) => {
   *   logger.logError(reason, { team: '...'})
   * })
   */
  logError(error: unknown, options: LoggerOptions): void {
    if (error instanceof FlowError) {
      sendLog('error', {
        name: error.name,
        message: error.message,
        stack: error.stack,
        type: error.type,
        code: error.code,
        componentStack: 'componentStack' in error ? String(error.componentStack) : undefined,
        ...options
      })
    } else if (error instanceof Error) {
      sendLog('error', {
        name: error.name,
        message: error.message,
        stack: error.stack,
        type: ClientSideErrorType.ClientSideError,
        componentStack: 'componentStack' in error ? String(error.componentStack) : undefined,
        ...options
      })
    } else if (typeof error === 'string') {
      sendLog('error', {
        name: 'error',
        type: ClientSideErrorType.ClientSideError,
        message: error,
        ...options
      })
    } else {
      sendLog('error', {
        name: 'Unknown Error',
        message: String(error),
        type: ClientSideErrorType.DefaultError,
        ...options
      })
    }
  },
  async logFetchError(res: Response, options: LoggerOptions): Promise<void> {
    const fetchErrorOptions = await buildOptionsForFetchError(res)
    if (res.status >= 400) {
      logger.logError(new Error(`Fetch error: ${res.status} - ${res.statusText}`), {
        ...options,
        type: ClientSideErrorType.FetchError,
        res: fetchErrorOptions
      })
    }
  }
}

export type Logger = typeof logger
