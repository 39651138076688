import { FC } from 'react'
import {
  Facebook,
  FacebookMinimal,
  GoogleDrive,
  Mail,
  Message,
  PDF,
  Phone2,
  Photo,
  Snapchat,
  SocialMedia,
  Upload,
  Venmo2,
  Web,
  Wifi,
  YouTube2
} from '@dtx-company/shared-components/src'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { LandingPageDestination } from '@dtx-company/inter-app/src/types/flowcode'
import Image from 'next/image'
import dynamic from 'next/dynamic'

const InstagramMinimal = dynamic<Record<string, unknown>>(() =>
  import('@dtx-company/shared-components/src/foundation/Icons/ThirdParty/Instagram').then(
    mod => mod.InstagramMinimal
  )
)
const Instagram = dynamic<Record<string, unknown>>(() =>
  import('@dtx-company/shared-components/src/foundation/Icons/ThirdParty/Instagram').then(
    mod => mod.Instagram
  )
)
const TikTok = dynamic<Record<string, unknown>>(() =>
  import('@dtx-company/shared-components/src/foundation/Icons/ThirdParty/TikTok').then(
    mod => mod.TikTok
  )
)
const TikTokMinimal = dynamic<Record<string, unknown>>(() =>
  import('@dtx-company/shared-components/src/foundation/Icons/ThirdParty/TikTok').then(
    mod => mod.TikTokMinimal
  )
)

const SnapchatMinimal = dynamic<Record<string, unknown>>(() =>
  import('@dtx-company/shared-components/src/foundation/Icons/ThirdParty/Snapchat').then(
    mod => mod.SnapchatMinimal
  )
)

export const SocialIcon: FC<{
  src: string
  width?: number
  height?: number
  id?: string
  color?: string
}> = props => (
  <Image
    src={props.src}
    width={props.width || 24}
    height={props.height || 24}
    data-testid={`${props.id || 'icon'}-icon`}
    alt={props.id || 'icon'}
    color={props.color}
  />
)

export interface SocialMediaIconProps {
  width?: number | string
  height?: number | string
  id?: string
  color?: string
}

export const TWITTER_LABEL = 'X (Twitter)'
export const TwitterSvg: FC<SocialMediaIconProps> = props => (
  <SocialIcon
    src={'/icons/social-media/twitter.svg'}
    {...props}
    width={typeof props.width === 'string' ? Number(props.width) : props.width}
    height={typeof props.height === 'string' ? Number(props.height) : props.height}
  />
)

const LinkedInMinimal = dynamic<Record<string, unknown>>(() =>
  import('@dtx-company/shared-components/src/foundation/Icons/ThirdParty/LinkedIn').then(
    mod => mod.LinkedInMinimal
  )
)

const Spotify = dynamic<Record<string, unknown>>(() =>
  import('@dtx-company/shared-components/src/foundation/Icons/ThirdParty/Spotify').then(
    mod => mod.Spotify
  )
)

interface DestinationIconProps {
  destination: LandingPageDestination
  fontSize: number
  isMinimalIcon?: boolean
  color?: string
}

export const DestinationIcon = ({
  destination,
  fontSize,
  isMinimalIcon,
  color
}: DestinationIconProps): JSX.Element => {
  switch (destination) {
    case LandingPageDestination.FACEBOOK:
      if (isMinimalIcon) {
        return (
          <FacebookMinimal data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
        )
      } else {
        return <Facebook data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
      }
    case LandingPageDestination.INSTAGRAM:
      if (isMinimalIcon) {
        return (
          <InstagramMinimal data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
        )
      } else {
        return <Instagram data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
      }
    case LandingPageDestination.EMAIL:
      return <Mail data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
    case LandingPageDestination.SMS:
      return <Message data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
    case LandingPageDestination.FILE:
      return <Upload data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
    case LandingPageDestination.TIKTOK:
      return isMinimalIcon ? (
        <TikTokMinimal data-testid={`${destination}-icon`} fontSize={fontSize} fill={color} />
      ) : (
        <TikTok data-testid={`${destination}-icon`} fontSize={fontSize} fill={color} />
      )
    case LandingPageDestination.SPOTIFY:
      return <Spotify data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
    case LandingPageDestination.SNAPCHAT:
      return isMinimalIcon ? (
        <SnapchatMinimal data-testid={`${destination}-icon`} fontSize={fontSize} fill={color} />
      ) : (
        <Snapchat data-testid={`${destination}-icon`} fontSize={fontSize} fill={color} />
      )
    case LandingPageDestination.TWITTER:
      return <TwitterSvg id={destination} height={fontSize} color={color} />
    case LandingPageDestination.PDF:
      return <PDF data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
    case LandingPageDestination.SOCIAL_MEDIA:
      return <SocialMedia data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
    case LandingPageDestination.GOOGLE_DOC:
      return <GoogleDrive data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
    case LandingPageDestination.IMAGE:
      return <Photo data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
    case LandingPageDestination.YOUTUBE:
      return <YouTube2 data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
    case LandingPageDestination.PHONE:
      return <Phone2 data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
    case LandingPageDestination.WIFI:
      return <Wifi data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
    case LandingPageDestination.LINKEDIN:
      return isMinimalIcon ? (
        <LinkedInMinimal data-testid={`${destination}-icon`} fontSize={fontSize} fill={color} />
      ) : (
        <Icon
          src="/images/landing-page-destinations/linkedin.svg"
          alt="LinkedIn"
          width="20px"
          height="20px"
          data-testid={`${destination}-icon`}
        />
      )
    case LandingPageDestination.VENMO:
      return <Venmo2 data-testid={`${destination}-icon`} fontSize={fontSize} color={color} />
    default:
      return <Web fontSize={fontSize} data-testid="web-icon" color={color} />
  }
}
