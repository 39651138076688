import { GlobalStyles } from '@mui/material'

const styles = `
  .pac-item {
    padding-left: 1rem !important;
    height: 3rem !important;
    line-height: 3rem !important;
    font-size: 15px !important;
    cursor: pointer !important;
  }
  .pac-item-query {
    font-size: 15px !important;
  }
  .pac-icon {
    display: none !important;
  }
  .pac-container {
    z-index: 9999999;
  }
`

/**
 * Defines the global styles for the Google Autocomplete widget used by address field
 * in the Flowpage contact collection form.
 *
 * https://developers.google.com/maps/documentation/javascript/place-autocomplete#style_autocomplete
 */
export function GooglePlaceAutocompleteGlobalStyles(): JSX.Element {
  return <GlobalStyles styles={styles} />
}
