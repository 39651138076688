// @generated by protoc-gen-connect-es v0.8.6 with parameter "target=ts,import_extension="
// @generated from file staticassets/v1/static_assets.proto (package staticassets.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetUploadUrlRequest, GetUploadUrlResponse } from './static_assets_pb'
import { MethodKind } from '@bufbuild/protobuf'

/**
 * @generated from service staticassets.v1.StaticAssetsService
 */
export const StaticAssetsService = {
  typeName: 'staticassets.v1.StaticAssetsService',
  methods: {
    /**
     * @generated from rpc staticassets.v1.StaticAssetsService.GetUploadUrl
     */
    getUploadUrl: {
      name: 'GetUploadUrl',
      I: GetUploadUrlRequest,
      O: GetUploadUrlResponse,
      kind: MethodKind.Unary
    }
  }
} as const
