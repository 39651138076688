export enum DrawerAction {
  UserOpenedDrawer = 'userOpenedDrawer',
  UserClosedDrawer = 'userClosedDrawer'
}

/**
 * Used to specify keys to forward to heap during scroll tracking that are independent of the page's route. ( since that value can change )
 */
export enum HeapPageKey {
  LandingPage = 'Landing Page',
  Dashboard = 'Dashboard'
}
