import { buildJwtFromFronteggAccessToken, refreshFronteggTokens } from '@dtx-company/ithaca-sdk/src'
import { setCurrentUserData } from '../../redux/slices/user-slice'
import { useCallback } from 'react'
import { useCurrentUserJwt, useIsFronteggUser } from '../../redux/selectors/currentUserSelectors'
import { useDispatch } from 'react-redux'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import useSWR from 'swr'

function tokenWillExpireSoon(token: string): boolean {
  const jwt: JwtPayload | undefined = jwtDecode(token)
  const expiration = jwt?.exp ?? 0
  const now = Math.floor(Date.now() / 1000) // get current Unix timestamp
  const thirtyMinutes = 1800 // 30 minutes in seconds
  const timeUntilExpiration = expiration - now
  return timeUntilExpiration <= thirtyMinutes
}

type RefreshToken = () => Promise<void>

function useRefreshToken(): RefreshToken {
  const dispatch = useDispatch()

  return useCallback<RefreshToken>(async () => {
    const { accessToken } = await refreshFronteggTokens()

    if (accessToken) {
      const jwt = await buildJwtFromFronteggAccessToken(accessToken)
      if (jwt) {
        dispatch(setCurrentUserData({ data: jwt, isFronteggUser: true }))
      }
    }
  }, [dispatch])
}

type RefreshTokenIfExpiringSoon = (
  token: string | undefined,
  isFronteggUser: boolean
) => Promise<void>

export function useRefreshTokenIfExpiringSoon(): RefreshTokenIfExpiringSoon {
  const refreshToken = useRefreshToken()
  return useCallback<RefreshTokenIfExpiringSoon>(
    async (token, isFronteggUser) => {
      if (token && isFronteggUser && tokenWillExpireSoon(token)) {
        await refreshToken()
      }
    },
    [refreshToken]
  )
}

export function useRefreshTokenBeforeExpiration(): void {
  const isFronteggUser = useIsFronteggUser()
  const currentUserJwt = useCurrentUserJwt()
  const token = currentUserJwt?.token
  const refreshTokenIfExpired = useRefreshTokenIfExpiringSoon()

  const every15Minutes = 15 * 60 * 1000

  useSWR([token, isFronteggUser], refreshTokenIfExpired, {
    refreshInterval: every15Minutes,
    refreshWhenHidden: true
    /* 
      Every 15 min, check if the token will expire in the next 30 min.
      
      "refreshWhileHidden: true" ensures that if the token expires while the window/tab is hidden, the token will be refreshed in the background.
      
      By default, SWR will also check for token expiry when:
        - the window/tab is refocused
        - the browser regains an internet connection after losing it
    */
  })
}
