import { SVGProps, memo } from 'react'

function FacebookSvg(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 10 20" {...props}>
      <g clipPath="url(#prefix__prefix__clip_fb)">
        <path
          fill="#3B5998"
          d="M6.205 20V9.999h2.761l.366-3.447H6.205l.005-1.725c0-.899.085-1.38 1.377-1.38h1.725V0h-2.76C3.234 0 2.066 1.672 2.066 4.484v2.069H0v3.446h2.067V20h4.138z"
        />
      </g>
      <defs>
        <clipPath id="prefix__prefix__clip_fb">
          <path fill="#fff" d="M0 0h9.332v20H0V0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Facebook = memo(FacebookSvg)

function FacebookMinimalSvg({
  stroke = 'currentcolor',
  color = '#323232',
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...rest}>
      <g fill="none">
        <path stroke={color || stroke} strokeWidth="1.5" d="M9.684,11.314h4.631"></path>
        <path
          stroke={color || stroke}
          strokeWidth={1.5}
          d="M14.316,7.455h-0.81c-1.257,0 -2.277,1.02 -2.277,2.277v0.81v6.003"
        ></path>
        <path
          stroke={color || stroke}
          strokeWidth={1.5}
          d="M19.0711,4.92893c3.90524,3.90524 3.90524,10.2369 0,14.1421c-3.90524,3.90524 -10.2369,3.90524 -14.1421,0c-3.90524,-3.90524 -3.90524,-10.2369 -1.77636e-15,-14.1421c3.90524,-3.90524 10.2369,-3.90524 14.1421,-1.77636e-15"
        ></path>
      </g>
    </svg>
  )
}

export const FacebookMinimal = memo(FacebookMinimalSvg)

function FacebookCircleSvg(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 33 33" fill="none" {...props}>
      <circle cx={16.5} cy={16.5} r={16.5} fill="#fff" />
      <path
        d="M17.14.012A17.254 17.254 0 0016.5 0C7.387 0 0 7.432 0 16.6c0 7.993 5.613 14.664 13.09 16.243.265.057.533.107.804.15l.04.006v-11.29c0-.301 0-.301-.299-.301H9.953c-.22 0-.222-.003-.222-.226v-4.315c0-.236 0-.238.242-.238H13.7c.225 0 .234-.006.234-.234 0-1.236-.007-2.47.003-3.705.007-1 .185-1.97.595-2.888.683-1.526 1.845-2.503 3.432-2.979.97-.291 1.966-.346 2.966-.27.899.067 1.795.176 2.693.262.15.015.203.076.202.227-.005 1.22 0 2.441-.003 3.662 0 .194-.01.204-.203.204-.669 0-1.338-.007-2.006.005-.583.01-1.14.135-1.625.483-.536.385-.79.937-.89 1.57a3.384 3.384 0 00-.035.517c-.004.966 0 1.933 0 2.898 0 .246 0 .246.25.246h4.066c.247 0 .248 0 .211.237l-.66 4.332c-.03.202-.038.208-.247.208h-3.38c-.24 0-.242 0-.242.24V33l.032-.005c.4-.063.796-.141 1.187-.234a16.501 16.501 0 007.244-3.81 16.56 16.56 0 005.223-9.436l.015-.08c.156-.918.236-1.847.239-2.777v-.06C33 7.648 25.955.35 17.14.012z"
        fill="#1877F2"
      />
    </svg>
  )
}

export const FacebookCircle = memo(FacebookCircleSvg)

function FacebookCircleFilledSvg({
  fill = '#fff',
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#prefix__facebookCircleFilledSvg)">
        <path
          d="M8.31.006A8.363 8.363 0 008 0C3.582 0 0 3.603 0 8.048c0 3.876 2.721 7.11 6.346 7.876.129.027.26.052.39.072l.02.003v-5.473c0-.146 0-.146-.145-.146H4.826c-.107 0-.108-.002-.108-.11V8.178c0-.115 0-.116.118-.116h1.806c.11 0 .113-.003.113-.113 0-.599-.003-1.198.002-1.797.003-.484.09-.955.288-1.4.332-.74.895-1.213 1.664-1.444.47-.141.954-.167 1.438-.131.436.033.87.086 1.306.127.073.007.098.037.098.11-.002.592 0 1.184-.001 1.776 0 .094-.005.098-.099.099-.324 0-.649-.004-.973.002-.282.005-.552.066-.787.235-.26.186-.383.454-.432.76a1.637 1.637 0 00-.017.251c-.002.469 0 .937 0 1.405 0 .12 0 .12.121.12h1.972c.12 0 .12 0 .102.115l-.32 2.1c-.015.098-.018.1-.12.1H9.358c-.115 0-.117 0-.117.117V16l.016-.002a8 8 0 004.088-1.96 8.069 8.069 0 002.539-4.615c.076-.445.115-.895.116-1.347v-.028C16 3.708 12.584.169 8.31.006z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="prefix__facebookCircleFilledSvg">
          <path fill={fill} d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const FacebookCircleFilled = memo(FacebookCircleFilledSvg)
