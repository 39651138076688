import { FlowFeatureCache } from './FlowFeatureContext'
import { FlowRouter } from '../../../hooks/useFlowRouter'
import { parseFlowFeatureValue } from './parseFlowFeatureValue'

// Matches `feature[${key}]`
const reFlagKey = /^feature\[([^\]]+)\]$/

export function getFlowFeatureCacheFromRouter(flowRouter: FlowRouter): FlowFeatureCache {
  const cache: FlowFeatureCache = {}
  Object.keys(flowRouter.query).forEach(param => {
    const matches = reFlagKey.exec(param)
    if (matches && matches[1]) {
      const key = matches[1]
      const value = flowRouter.getString(param)
      try {
        const data = parseFlowFeatureValue(value)
        cache[key] = data
      } catch (error) {
        // This is dev functionality so can simply log to console
        console.warn(`An error occured trying to parse ?${param}: ${error.message}`)
      }
    }
  })
  return cache
}
