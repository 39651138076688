// This component uses the console to render instead of the DOM
/* eslint-disable no-console */
import { Heap } from '@dtx-company/true-common/src/utils/heap'
import { useEffect, useState } from 'react'
import { useIsFlowpage } from '../../../hooks/useIsFlowpage/useIsFlowpage'
import { useRouter } from 'next/router'

type TrackingStatus = 'not_loaded' | 'stubbed' | 'loaded'

/**
 * Note: This function should only be used locally for determining whether or
 * not to send tracking events. Usage of this function outside to determine
 * rendering logic could lead to rehydration errors.
 */
export function getIsBrowser(): boolean {
  // eslint-disable-next-line @dtx-company/flowcode-rules/no-conditional-render-with-window
  return typeof window !== 'undefined'
}

export function getTrackingStatus(): TrackingStatus {
  if (!getIsBrowser()) return 'not_loaded'
  if (window.heap?.loaded) return 'loaded'
  if (window.heap) return 'stubbed'
  return 'not_loaded'
}

export function wrapTrack(track: Heap['track']): Heap['track'] {
  return (event, properties) => {
    const formattedProperties: Record<string, string | number> = {}
    if (properties) {
      Object.keys(properties).forEach(key => {
        const value = properties[key]
        // Heap ignores any other types so ignore them here too
        if (!['string', 'number', 'boolean'].includes(typeof value)) return
        // Heap lets you do numerical ops on numbers so it has some concept of those,
        // but anything else is treated as a string, including booleans
        formattedProperties[key] = typeof value === 'number' ? value : '' + value
      })
    }
    const keys = Object.keys(formattedProperties)
    const longestKey = Math.max(...keys.map(key => key.length))
    console.group(event)
    keys.forEach(key => {
      const value = formattedProperties[key]
      console.log(`${key.padEnd(longestKey)} `, value)
    })
    console.groupEnd()
    return track(event, properties)
  }
}

export function useTrackingLogEnabled(): boolean {
  const isBrowser = getIsBrowser()
  const router = useRouter()
  const [trackingLogEnabled, setTrackingLogEnabled] = useState(false)
  useEffect(() => {
    if (!isBrowser) return
    const fromStorage = window.localStorage.getItem('trackingLog')
    const fromQuery = router.query.trackingLog
    if (fromQuery === 'on' && fromStorage !== 'on') {
      window.localStorage.setItem('trackingLog', 'on')
    } else if (fromQuery === 'off' && fromStorage !== null) {
      window.localStorage.removeItem('trackingLog')
    }
    setTrackingLogEnabled(window.localStorage.getItem('trackingLog') === 'on')
  }, [isBrowser, router.query.trackingLog])
  return trackingLogEnabled
}

function useTrackingStatus(): TrackingStatus {
  const [status, setStatus] = useState(() => getTrackingStatus())
  useEffect(() => {
    let animationFrameId = 0
    function check(): void {
      const newStatus = getTrackingStatus()
      setStatus(newStatus)
      if (newStatus === 'loaded') {
        return
      }
      animationFrameId = window.requestAnimationFrame(check)
    }
    animationFrameId = window.requestAnimationFrame(check)
    return () => {
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [])
  return status
}

/**
 * Uses the console to render heap events as they happen.
 *
 * Can be enabled or disabled via `?trackingLog=on|off`.
 * The setting persists in `localStorage` until changed.
 */
export function TrackingLog(): null {
  const isEnabled = useTrackingLogEnabled()
  const status = useTrackingStatus()
  const isFlowpage = useIsFlowpage()

  // Hook stubs and hook real methods once loaded, if enabled
  useEffect(() => {
    const heap = window.heap
    if (!heap) return
    if (!isEnabled) return
    if (isFlowpage) return
    if (status === 'not_loaded') return

    const track = heap.track
    heap.track = wrapTrack(track)

    return () => {
      heap.track = track
    }
  }, [isEnabled, isFlowpage, status])

  return null
}
