import { FcArea, FcPoint, FcPolygon } from './fc_common.types'

export function getDistance(x1: number, y1: number, x2: number, y2: number): number {
  return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2))
}

export function getIntersection(
  x: number,
  y: number,
  w: number,
  h: number,
  cx: number,
  cy: number,
  r: number
): number[] {
  const v2x = x - cx
  const v2y = y - cy
  const b = (w * v2x + h * v2y) * -2
  const c = 2 * (w * w + h * h)
  const d = Math.sqrt(b * b - 2 * c * (v2x * v2x + v2y * v2y - r * r))
  if (isNaN(d)) {
    return []
  }
  const u1 = (b - d) / c
  const u2 = (b + d) / c
  const ret: number[] = []
  if (u1 <= 1 && u1 >= 0) {
    const point = new FcPoint(x + w * u1, y + h * u1)
    const deg = Math.atan2(point.y - cy, point.x - cx)
    ret.push(deg)
  }
  if (u2 <= 1 && u2 >= 0) {
    const point = new FcPoint(x + w * u2, y + h * u2)
    const deg = Math.atan2(point.y - cy, point.x - cx)
    ret.push(deg)
  }
  return ret
}

export function rotatePoint(
  point: FcPoint,
  origin: FcPoint,
  angle: number,
  scale: number
): FcPoint {
  const s = Math.sin(angle)
  const c = Math.cos(angle)
  point.x -= origin.x
  point.y -= origin.y
  const xnew = point.x * c - point.y * s
  const ynew = point.x * s + point.y * c
  point.x = (xnew + origin.x) / scale
  point.y = (ynew + origin.y) / scale
  return point
}

export function getRotatedQuad(
  box: FcArea,
  origin: FcPoint,
  angle: number,
  scale: number
): FcPoint[] {
  const p1 = rotatePoint(new FcPoint(box.x, box.y), origin, angle, scale)
  const p2 = rotatePoint(new FcPoint(box.x, box.y + box.height), origin, angle, scale)
  const p3 = rotatePoint(new FcPoint(box.x + box.width, box.y + box.height), origin, angle, scale)
  const p4 = rotatePoint(new FcPoint(box.x + box.width, box.y), origin, angle, scale)
  return [p1, p2, p3, p4]
}

export function doPolygonsIntersect(poly1: FcPolygon, poly2: FcPolygon): boolean {
  const a = poly1.points
  const b = poly2.points
  const polygons = [a, b]
  let minA = 0,
    maxA = 0,
    minB = 0,
    maxB = 0
  let minASet = false,
    maxASet = false,
    minBSet = false,
    maxBSet = false

  for (let i = 0; i < polygons.length; i++) {
    const polygon = polygons[i]

    for (let i1 = 0; i1 < polygon.length; i1++) {
      const i2 = (i1 + 1) % polygon.length
      const p1 = polygon[i1]
      const p2 = polygon[i2]
      const normal = { x: p2.y - p1.y, y: p1.x - p2.x }

      minASet = false
      maxASet = false
      for (let j = 0; j < a.length; j++) {
        const projected = normal.x * a[j].x + normal.y * a[j].y
        if (!minASet || projected < minA) {
          minA = projected
          minASet = true
        }
        if (!maxASet || projected > maxA) {
          maxA = projected
          maxASet = true
        }
      }

      minBSet = false
      maxBSet = false
      for (let j = 0; j < b.length; j++) {
        const projected = normal.x * b[j].x + normal.y * b[j].y
        if (!minBSet || projected < minB) {
          minB = projected
          minBSet = true
        }
        if (!maxBSet || projected > maxB) {
          maxB = projected
          maxBSet = true
        }
      }
      if (maxA < minB || maxB < minA) {
        return false
      }
    }
  }
  return true
}
