import { Cookie, UseCookiesType } from '../../types/cookies'
import { getCookie } from './cookies.utils'
import { getHeap } from '../heap'
import { v4 as uuidv4 } from 'uuid'

export const sendRDSCookieToHeap = (cookie: string): void => {
  const heap = getHeap()
  heap?.addUserProperties({ dtxcookie: cookie })
}

export const createRedirectServiceCookie = (now: number, uuid: string): string => {
  return `${uuid}-SSE:${now}`
}

/**
 * Get or create RDS cookie and the drop cookie
 */
export async function syncRedirectServiceCookie({
  setCookie
}: {
  setCookie: UseCookiesType['setCookie']
}): Promise<void> {
  try {
    let redirectServiceCookie = getCookie(Cookie.REDIRECT_SERVICE_COOKIE)

    if (redirectServiceCookie) {
      sendRDSCookieToHeap(redirectServiceCookie)
      return
    }

    const now = Date.now()
    const dateInSeconds = Math.floor(now / 1000)
    const uuid = uuidv4()
    redirectServiceCookie = createRedirectServiceCookie(dateInSeconds, uuid)

    const dateNow = new Date(now)
    const tenYearsFromNow = new Date(dateNow.setFullYear(dateNow.getFullYear() + 10))
    const secure = process.env.NODE_ENV !== 'development'

    setCookie(Cookie.REDIRECT_SERVICE_COOKIE, redirectServiceCookie, {
      expires: tenYearsFromNow,
      path: '/',
      secure,
      ...(secure && {
        sameSite: 'none'
      }),
      // prevent cookie from being url encoded to match redirect service
      encode: s => s
    })
  } catch (e) {
    console.error('error setting rds cookie', e)
  }
}
