import { Button } from '../Button'
import { FC } from 'react'
import { IconBase } from './IconBase'
import { IconBaseProps, IconProps } from '../../../types'
import { getTrueImgSrc } from '../../../utils/getTrueImgSrc'

export const IconButton: FC<IconProps & IconBaseProps> = ({
  type = 'submit',
  onClick,
  src,
  alt,
  ...rest
}) => {
  const trueSrc = getTrueImgSrc(src)

  return (
    <Button
      onClick={onClick}
      colorVariant="invisible"
      sizeVariant="invisible"
      padding={rest.additionalStyle?.padding || '0'}
      border="none"
      type={type}
    >
      <IconBase src={trueSrc} alt={alt || trueSrc} {...rest} />
    </Button>
  )
}
