import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'

export interface DrawerContentMeta {
  [key: string]: string | Record<string, any>
}

export interface LinkImageEditModalStateType {
  isOpen: boolean
  initialAspectRatio?: number
  sourceImageUrl: string
  resultImageUrl: string
  /** Stores the first initial image url that was received by ImageEdit */
  originalImageUrl: string
  /** when default cropping is desired (see ImageEdit's `performAutomaticDefaultCrop` prop)
  it stores the image url of the automatically cropped image */
  defaultCroppedImageUrl: string
  maskType: string
  hideMask: boolean
}

export interface LinkEditorSliceType {
  drawerContentState: string | null
  drawerContentMeta: DrawerContentMeta | null | undefined
  previewLink: LinkType | null
  drawerOpen: boolean
  isNewPreviewLink: boolean
  linkImageEditModalState: LinkImageEditModalStateType
}

export const initialLinkEditorState: LinkEditorSliceType = {
  drawerContentState: null,
  drawerOpen: false,
  drawerContentMeta: null,
  previewLink: null,
  isNewPreviewLink: true,
  linkImageEditModalState: {
    isOpen: false,
    initialAspectRatio: 1,
    sourceImageUrl: '',
    resultImageUrl: '',
    maskType: 'square',
    originalImageUrl: '',
    defaultCroppedImageUrl: '',
    hideMask: false
  }
}
