import { AllCookies, AllCookiesValues } from './cookies.constants'
import { Cookie, CookieClassification, CookieType } from '../../types/cookies'
import { IS_CI, NGROK_DOMAIN, PR_NUMBER, USE_NGROK } from '../../constants/env'
import Cookies from 'universal-cookie'
export { syncRedirectServiceCookie } from './syncRedirectServiceCookie'

const cookies = new Cookies()

export function getSameSitePolicy(): 'none' | 'lax' {
  const isDevelopment = process.env.APP_ENV === 'development'
  const isCI = Boolean(process.env.CI)

  if (isCI || isDevelopment) {
    return 'lax'
  }

  return 'none'
}

export function shouldUseSecureCookie(): boolean {
  return getSameSitePolicy() === 'none'
}

/**
 * Fetch cookie by name
 */
export function getCookie(name: Cookie): string | undefined {
  return cookies.get(AllCookies[name]?.name) ?? undefined
}

/**
 * Delete cookie by name
 * @param name - cookie enum
 */
export const deleteCookie = async (name: Cookie): Promise<void> => {
  cookies.remove(name)
}

export const getNonEssentialCookies = (): [string, CookieType][] =>
  Object.entries(AllCookies).filter(value => {
    return value[1].type !== CookieClassification.strictlyNecessary
  })

/**
 * Delete all cookies except for the ones classified as strictly necessary
 */
export const deleteAllCookies = async (): Promise<void> => {
  getNonEssentialCookies().map(value => {
    deleteCookie(value[0] as Cookie)
  })
}

/**
 * - Delete cookie by classification
 * - Handle user cookie preference toggles
 */
export const deleteCookiesByClassification = async (
  classification: CookieClassification
): Promise<void> => {
  const filteredCookies = AllCookiesValues.filter(value => {
    return value.type === classification
  })
  filteredCookies.forEach(value => {
    cookies.remove(value.name)
  })
}

export const setLoggedInCookie = (isUserCurrentlyLoggedIn = false): void => {
  const doesLoggedInCookieExist = Boolean(getCookie(Cookie.LOGGED_IN))
  const areCookiesCorrect = doesLoggedInCookieExist === isUserCurrentlyLoggedIn

  if (areCookiesCorrect) return

  const dateNow = new Date()
  const oneYearFromNow = new Date(dateNow.setFullYear(dateNow.getFullYear() + 1))

  const cookieDomain = getCookieDomain()

  if (isUserCurrentlyLoggedIn) {
    cookies.set(Cookie.LOGGED_IN, isUserCurrentlyLoggedIn, {
      path: '/',
      expires: oneYearFromNow,
      domain: cookieDomain
    })
    return
  }
  cookies.remove(Cookie.LOGGED_IN, {
    path: '/',
    domain: cookieDomain
  })
}

export function getCookieDomain(): string {
  if (PR_NUMBER) {
    //running in a review app
    return '.reviewapps.flowcode.com'
  }

  if (USE_NGROK) {
    return `${NGROK_DOMAIN}`
  }

  if (IS_CI || process.env.APP_ENV === 'development') {
    return 'localhost'
  }

  if (process.env.APP_ENV === 'staging') {
    return '.stg.flowcode.com'
  }

  if (process.env.APP_ENV === 'preprod') {
    return '.preprod.flowcode.com'
  }

  return '.flowcode.com'
}
