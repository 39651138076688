// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file forms/v1/field.proto (package forms.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { Message, proto3, Struct } from '@bufbuild/protobuf'

/**
 * @generated from enum forms.v1.FieldType
 */
export enum FieldType {
  /**
   * @generated from enum value: FIELD_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: FIELD_TYPE_TEXT = 1;
   */
  TEXT = 1,

  /**
   * @generated from enum value: FIELD_TYPE_ADDRESS = 2;
   */
  ADDRESS = 2,

  /**
   * @generated from enum value: FIELD_TYPE_CHOICE = 3;
   */
  CHOICE = 3,

  /**
   * @generated from enum value: FIELD_TYPE_EMAIL = 4;
   */
  EMAIL = 4,

  /**
   * @generated from enum value: FIELD_TYPE_PHONE = 5;
   */
  PHONE = 5,

  /**
   * @generated from enum value: FIELD_TYPE_LEGAL = 6;
   */
  LEGAL = 6,

  /**
   * @generated from enum value: FIELD_TYPE_DATE = 7;
   */
  DATE = 7,

  /**
   * @generated from enum value: FIELD_TYPE_BLOCK_TEXT = 8;
   */
  BLOCK_TEXT = 8,

  /**
   * @generated from enum value: FIELD_TYPE_NAME = 9;
   */
  NAME = 9,

  /**
   * @generated from enum value: FIELD_TYPE_BLOCK_IMAGE = 10;
   */
  BLOCK_IMAGE = 10,

  /**
   * @generated from enum value: FIELD_TYPE_POLL = 11;
   */
  POLL = 11,

  /**
   * @generated from enum value: FIELD_TYPE_FILE_UPLOAD = 12;
   */
  FILE_UPLOAD = 12
}
// Retrieve enum metadata with: proto3.getEnumType(FieldType)
proto3.util.setEnumType(FieldType, 'forms.v1.FieldType', [
  { no: 0, name: 'FIELD_TYPE_UNSPECIFIED' },
  { no: 1, name: 'FIELD_TYPE_TEXT' },
  { no: 2, name: 'FIELD_TYPE_ADDRESS' },
  { no: 3, name: 'FIELD_TYPE_CHOICE' },
  { no: 4, name: 'FIELD_TYPE_EMAIL' },
  { no: 5, name: 'FIELD_TYPE_PHONE' },
  { no: 6, name: 'FIELD_TYPE_LEGAL' },
  { no: 7, name: 'FIELD_TYPE_DATE' },
  { no: 8, name: 'FIELD_TYPE_BLOCK_TEXT' },
  { no: 9, name: 'FIELD_TYPE_NAME' },
  { no: 10, name: 'FIELD_TYPE_BLOCK_IMAGE' },
  { no: 11, name: 'FIELD_TYPE_POLL' },
  { no: 12, name: 'FIELD_TYPE_FILE_UPLOAD' }
])

/**
 * @generated from enum forms.v1.ChoiceType
 */
export enum ChoiceType {
  /**
   * @generated from enum value: CHOICE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CHOICE_TYPE_RADIO = 1;
   */
  RADIO = 1,

  /**
   * @generated from enum value: CHOICE_TYPE_DROPDOWN = 2;
   */
  DROPDOWN = 2,

  /**
   * @generated from enum value: CHOICE_TYPE_CHECKBOX = 3;
   */
  CHECKBOX = 3
}
// Retrieve enum metadata with: proto3.getEnumType(ChoiceType)
proto3.util.setEnumType(ChoiceType, 'forms.v1.ChoiceType', [
  { no: 0, name: 'CHOICE_TYPE_UNSPECIFIED' },
  { no: 1, name: 'CHOICE_TYPE_RADIO' },
  { no: 2, name: 'CHOICE_TYPE_DROPDOWN' },
  { no: 3, name: 'CHOICE_TYPE_CHECKBOX' }
])

/**
 * @generated from message forms.v1.Choice
 */
export class Choice extends Message<Choice> {
  /**
   * @generated from field: string value = 1;
   */
  value = ''

  constructor(data?: PartialMessage<Choice>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.Choice'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'value', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Choice {
    return new Choice().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Choice {
    return new Choice().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Choice {
    return new Choice().fromJsonString(jsonString, options)
  }

  static equals(
    a: Choice | PlainMessage<Choice> | undefined,
    b: Choice | PlainMessage<Choice> | undefined
  ): boolean {
    return proto3.util.equals(Choice, a, b)
  }
}

/**
 * @generated from message forms.v1.ChoiceFieldProperties
 */
export class ChoiceFieldProperties extends Message<ChoiceFieldProperties> {
  /**
   * @generated from field: repeated forms.v1.Choice choices = 1;
   */
  choices: Choice[] = []

  /**
   * @generated from field: forms.v1.ChoiceType choice_type = 2;
   */
  choiceType = ChoiceType.UNSPECIFIED

  /**
   * @generated from field: bool allow_other = 3;
   */
  allowOther = false

  constructor(data?: PartialMessage<ChoiceFieldProperties>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.ChoiceFieldProperties'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'choices', kind: 'message', T: Choice, repeated: true },
    { no: 2, name: 'choice_type', kind: 'enum', T: proto3.getEnumType(ChoiceType) },
    { no: 3, name: 'allow_other', kind: 'scalar', T: 8 /* ScalarType.BOOL */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ChoiceFieldProperties {
    return new ChoiceFieldProperties().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChoiceFieldProperties {
    return new ChoiceFieldProperties().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ChoiceFieldProperties {
    return new ChoiceFieldProperties().fromJsonString(jsonString, options)
  }

  static equals(
    a: ChoiceFieldProperties | PlainMessage<ChoiceFieldProperties> | undefined,
    b: ChoiceFieldProperties | PlainMessage<ChoiceFieldProperties> | undefined
  ): boolean {
    return proto3.util.equals(ChoiceFieldProperties, a, b)
  }
}

/**
 * @generated from message forms.v1.TextFieldProperties
 */
export class TextFieldProperties extends Message<TextFieldProperties> {
  /**
   * @generated from field: bool paragraph = 1;
   */
  paragraph = false

  constructor(data?: PartialMessage<TextFieldProperties>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.TextFieldProperties'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'paragraph', kind: 'scalar', T: 8 /* ScalarType.BOOL */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextFieldProperties {
    return new TextFieldProperties().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextFieldProperties {
    return new TextFieldProperties().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TextFieldProperties {
    return new TextFieldProperties().fromJsonString(jsonString, options)
  }

  static equals(
    a: TextFieldProperties | PlainMessage<TextFieldProperties> | undefined,
    b: TextFieldProperties | PlainMessage<TextFieldProperties> | undefined
  ): boolean {
    return proto3.util.equals(TextFieldProperties, a, b)
  }
}

/**
 * @generated from message forms.v1.ImageBlockProperties
 */
export class ImageBlockProperties extends Message<ImageBlockProperties> {
  /**
   * @generated from field: string image_url = 1;
   */
  imageUrl = ''

  constructor(data?: PartialMessage<ImageBlockProperties>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.ImageBlockProperties'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'image_url', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImageBlockProperties {
    return new ImageBlockProperties().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImageBlockProperties {
    return new ImageBlockProperties().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ImageBlockProperties {
    return new ImageBlockProperties().fromJsonString(jsonString, options)
  }

  static equals(
    a: ImageBlockProperties | PlainMessage<ImageBlockProperties> | undefined,
    b: ImageBlockProperties | PlainMessage<ImageBlockProperties> | undefined
  ): boolean {
    return proto3.util.equals(ImageBlockProperties, a, b)
  }
}

/**
 * @generated from message forms.v1.LegalFieldProperties
 */
export class LegalFieldProperties extends Message<LegalFieldProperties> {
  /**
   * @generated from field: string terms_link = 1;
   */
  termsLink = ''

  /**
   * @generated from field: string disclaimer = 2;
   */
  disclaimer = ''

  constructor(data?: PartialMessage<LegalFieldProperties>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.LegalFieldProperties'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'terms_link', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'disclaimer', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LegalFieldProperties {
    return new LegalFieldProperties().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LegalFieldProperties {
    return new LegalFieldProperties().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): LegalFieldProperties {
    return new LegalFieldProperties().fromJsonString(jsonString, options)
  }

  static equals(
    a: LegalFieldProperties | PlainMessage<LegalFieldProperties> | undefined,
    b: LegalFieldProperties | PlainMessage<LegalFieldProperties> | undefined
  ): boolean {
    return proto3.util.equals(LegalFieldProperties, a, b)
  }
}

/**
 * @generated from message forms.v1.FieldProperties
 */
export class FieldProperties extends Message<FieldProperties> {
  /**
   * @generated from oneof forms.v1.FieldProperties.properties
   */
  properties:
    | {
        /**
         * @generated from field: forms.v1.ChoiceFieldProperties choice = 1;
         */
        value: ChoiceFieldProperties
        case: 'choice'
      }
    | {
        /**
         * @generated from field: forms.v1.TextFieldProperties text_field = 2;
         */
        value: TextFieldProperties
        case: 'textField'
      }
    | {
        /**
         * @generated from field: forms.v1.ImageBlockProperties image = 3;
         */
        value: ImageBlockProperties
        case: 'image'
      }
    | {
        /**
         * @generated from field: forms.v1.LegalFieldProperties legal = 4;
         */
        value: LegalFieldProperties
        case: 'legal'
      }
    | { case: undefined; value?: undefined } = { case: undefined }

  constructor(data?: PartialMessage<FieldProperties>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.FieldProperties'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'choice', kind: 'message', T: ChoiceFieldProperties, oneof: 'properties' },
    { no: 2, name: 'text_field', kind: 'message', T: TextFieldProperties, oneof: 'properties' },
    { no: 3, name: 'image', kind: 'message', T: ImageBlockProperties, oneof: 'properties' },
    { no: 4, name: 'legal', kind: 'message', T: LegalFieldProperties, oneof: 'properties' }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FieldProperties {
    return new FieldProperties().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FieldProperties {
    return new FieldProperties().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FieldProperties {
    return new FieldProperties().fromJsonString(jsonString, options)
  }

  static equals(
    a: FieldProperties | PlainMessage<FieldProperties> | undefined,
    b: FieldProperties | PlainMessage<FieldProperties> | undefined
  ): boolean {
    return proto3.util.equals(FieldProperties, a, b)
  }
}

/**
 * @generated from message forms.v1.Field
 */
export class Field extends Message<Field> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string name = 2;
   */
  name = ''

  /**
   * @generated from field: string title = 3;
   */
  title = ''

  /**
   * @generated from field: string description = 4;
   */
  description = ''

  /**
   * @generated from field: bool active = 5;
   */
  active = false

  /**
   * @generated from field: forms.v1.FieldType field_type = 6;
   */
  fieldType = FieldType.UNSPECIFIED

  /**
   * @generated from field: bool required = 7;
   */
  required = false

  /**
   * @generated from field: forms.v1.FieldProperties properties = 8;
   */
  properties?: FieldProperties

  /**
   * whether the field is editable in the editor
   *
   * @generated from field: bool immutable = 9;
   */
  immutable = false

  /**
   * whether the field is fillable on a landing page
   *
   * @generated from field: bool static = 10;
   */
  static = false

  /**
   * @generated from field: google.protobuf.Struct default_value = 11;
   */
  defaultValue?: Struct

  constructor(data?: PartialMessage<Field>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.Field'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: 'field_type', kind: 'enum', T: proto3.getEnumType(FieldType) },
    { no: 7, name: 'required', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: 'properties', kind: 'message', T: FieldProperties },
    { no: 9, name: 'immutable', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: 'static', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: 'default_value', kind: 'message', T: Struct }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Field {
    return new Field().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Field {
    return new Field().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Field {
    return new Field().fromJsonString(jsonString, options)
  }

  static equals(
    a: Field | PlainMessage<Field> | undefined,
    b: Field | PlainMessage<Field> | undefined
  ): boolean {
    return proto3.util.equals(Field, a, b)
  }
}
