import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { BoxProps } from '@dtx-company/shared-components/src'
import { ErrorPage } from '../ErrorPage'
import { FC } from 'react'

export const PageErrorFallback: FC<BoxProps> = props => (
  <Box height="100%" {...props}>
    <ErrorPage />
  </Box>
)

export const FullPageErrorFallback: FC = () => <PageErrorFallback height="100vh" />
