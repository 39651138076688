import { Box, theme } from '@dtx-company/design-system/src'
import {
  COLOR_INPUT_WIDTH_DESKTOP,
  COLOR_INPUT_WIDTH_DESKTOP_SMALL_VARIANT,
  COLOR_INPUT_WIDTH_MOBILE
} from './ColorSelector.constants'
import { ChangeEventHandler, FC } from 'react'
import { CodeConfigurationField } from '@dtx-company/inter-app/src/types/flowcode'
import { FlowcodeColorOptionType } from '@app/common/src/types/createFlowcode.types'
import { useGlobalServices } from '@app/common/src/hooks/useGlobalServices'
import { useSelector } from '@xstate/react'
import { visuallyHidden } from '@mui/utils'

interface ColorInputProps {
  color: FlowcodeColorOptionType
  selected: boolean
  handleChange: ChangeEventHandler<HTMLInputElement>
  isSmallVariant?: boolean
}

export const ColorInput: FC<ColorInputProps> = props => {
  const { locgService } = useGlobalServices()
  const isColorSelectionSectionActive = useSelector(locgService, state =>
    state.matches('codeDesign.editing.color')
  )

  const boxShadowColor = props.selected ? theme.palette.tertiary.dark : theme.palette.text.secondary

  return (
    <Box
      component="label"
      data-heap-id="LoggedOutGenerator_ColorInput"
      data-color={props.color.id}
      sx={{
        cursor: 'pointer',
        position: 'relative',
        borderRadius: '50%',
        height: {
          xs: COLOR_INPUT_WIDTH_MOBILE,
          md: props.isSmallVariant
            ? COLOR_INPUT_WIDTH_DESKTOP_SMALL_VARIANT
            : COLOR_INPUT_WIDTH_DESKTOP
        },
        width: {
          xs: COLOR_INPUT_WIDTH_MOBILE,
          md: props.isSmallVariant
            ? COLOR_INPUT_WIDTH_DESKTOP_SMALL_VARIANT
            : COLOR_INPUT_WIDTH_DESKTOP
        },
        input: {
          appearance: 'none',
          background: props.color.color,
          borderRadius: '50%',
          position: 'absolute',
          inset: 0,
          margin: 0,
          width: '100%',
          height: '100%'
        }
      }}
    >
      <Box component={'span'} sx={visuallyHidden}>
        {props.color.label}
      </Box>
      <Box
        sx={theme => ({
          position: 'absolute',
          inset: 0,
          borderRadius: '50%',
          ':after': {
            transition: 'box-shadow 0.2s ease-in-out',
            position: 'absolute',
            content: "''",
            inset: 0,
            borderRadius: '50%',
            ...(props.selected && {
              boxShadow: `0 0 0 3px ${boxShadowColor},
              inset 0 0 0 2px ${boxShadowColor}`
            })
          },
          ...(!props.selected && {
            ':hover:after': {
              boxShadow: `0 0 0 1px ${boxShadowColor},
              inset 0 0 0 1px ${boxShadowColor},
              inset 0 0 0 3px ${theme.palette.surface[0]}`
            }
          })
        })}
      >
        <input
          autoFocus={isColorSelectionSectionActive && props.selected}
          type="radio"
          name={CodeConfigurationField.COLOR}
          value={props.color.id}
          onChange={props.handleChange}
        />
      </Box>
    </Box>
  )
}
