import { LinkInput } from '@dtx-company/flow-codegen/src/page/generated.types'
import { LinkType, PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { PageEditorState } from './pageEditor.types'
import { addMailchimp, deleteMailchimp, editMailchimp, hasMailchimp } from './mailchimpEditing'
import {
  deleteAvatar,
  deleteBackgroundImage,
  deleteLinkThumbnail,
  uploadAvatar,
  uploadBackgroundImage,
  uploadLinkThumbnail,
  uploadMediaFile,
  uploadPdf
} from '../../../utils/rest'
import { getValidatedPDFActionData } from '../../../utils/flowpage'
import { isFileString } from '@dtx-company/true-common/src/utils/blob'
import { unwrapOrThrow } from '@dtx-company/true-common/src/utils/objects'

export const getCurrentPageWithState = (state: PageEditorState): PageType | null => state.tempPage

/**
 * Removes id from LinkInput before creating page since BE ignores this value
 */
export const removeIdFromLink = (linkInput: LinkInput): LinkInput => {
  const { id: _id, ...link } = linkInput
  return link
}

export const getDefaultPageId = (pages: PageEditorState['pages']): string | null => {
  if (pages) {
    const pagesList = Object.values(unwrapOrThrow(pages))
    return pagesList.length > 0 ? Object.values(unwrapOrThrow(pages))[0].id : null
  }
  return null
}

export const serializeLink = ({
  __typename,
  thumbNailImgUrl: _,
  actionData: a,
  position: _position,
  order,
  ...rest
}: LinkType): LinkInput => ({
  ...rest,
  actionData:
    // Files should be uploaded in another step
    'link' in a && isFileString(a.link) ? { ...a, link: '' } : a,
  order: order ?? -1
})

const PDF_COLLECTION_TYPE = 'pdf'
export const CONTACT_COLLECTION_FORM_TYPE = 'contactCollection'

export const syncFiles = async (
  page: PageType,
  isCreating = false,
  token: string | undefined
): Promise<void> => {
  if (page.profileImage && isFileString(page.profileImage)) {
    await uploadAvatar(page.profileImage, page.id, token)
  } else if (!page.profileImage && !isCreating) {
    await deleteAvatar(page.id, token)
  }
  if (page.theme?.backgroundImgUrl && isFileString(page.theme?.backgroundImgUrl)) {
    await uploadBackgroundImage(page.id, page.theme?.backgroundImgUrl, token)
  } else if (page.theme && page.theme.backgroundImgUrl === undefined && !isCreating) {
    await deleteBackgroundImage(page.id, token)
  }
  if (page.links) {
    await Promise.all(
      page.links.map(async link => {
        const l = unwrapOrThrow(link)
        if (l.thumbNailImgUrl && isFileString(l.thumbNailImgUrl)) {
          await uploadLinkThumbnail(l.id, l.thumbNailImgUrl, token)
        } else if (l.thumbNailImgUrl === undefined && !isCreating) {
          await deleteLinkThumbnail(l.id, token)
        }
        if (l.type === PDF_COLLECTION_TYPE) {
          const url = getValidatedPDFActionData(l.actionData).link
          if (isFileString(url)) {
            await uploadMediaFile(l.id, url, token)
          }
        }
        if (l.type === CONTACT_COLLECTION_FORM_TYPE) {
          const url = l.actionData.link
          if (isFileString(url)) {
            await uploadPdf(l.id, url, token)
          }
        }
      })
    )
  }
}

export async function syncMailchimpForPage(page: PageType): Promise<void> {
  if (page.links) {
    await Promise.all(
      page.links.map(async link => {
        const l = unwrapOrThrow(link)
        if (l.type === CONTACT_COLLECTION_FORM_TYPE) {
          const mcKey = await hasMailchimp(page.id ?? '')
          await syncMailchimp(
            mcKey,
            (l.actionData.mailChimp?.url ?? '').length > 0,
            l.actionData.mailChimp,
            page.id
          )
        }
      })
    )
  }
}

export async function syncMailchimp(
  mailchimpApiKey: string | boolean,
  isMailchimpHandlerWidget: boolean,
  mailchimpAd: { enabled: boolean; url?: string },
  pageId: string
): Promise<void> {
  if (!!mailchimpApiKey && typeof mailchimpApiKey === 'string') {
    //mailchimp is already set up

    if (isMailchimpHandlerWidget) {
      //this is the widget where mailchimp was setup, we can proceed

      if (mailchimpAd?.enabled) {
        if (mailchimpAd?.url !== mailchimpApiKey) {
          //edit mailchimp with new api key
          await editMailchimp(mailchimpAd?.url ?? '', pageId ?? '')
        }
      } else {
        //delete mailchimp
        await deleteMailchimp(pageId ?? '')
      }
    }
  } else {
    //mailchimp is not setup

    if (mailchimpAd?.enabled && (mailchimpAd?.url ?? '').length > 0) {
      //add mailchimp
      await addMailchimp(mailchimpAd?.url ?? '', pageId ?? '')
    }
  }
}
