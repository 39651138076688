import { DialogTransitionContext } from './DialogTransitionContext'
import { DialogTransitionItem } from './DialogTransitionItem'
import { ReactNode, useEffect, useRef } from 'react'

/**
 * Provides state and methods for dialogs to know about each other and manage
 * keeping only the top-most one visible at any time.
 *
 * Add this to _app to have all DS Dialog's in your application benefit from the
 * improved transitions.
 *
 * @see Dialog
 * @see DialogTransition
 */
export function DialogTransitionProvider({ children }: { children: ReactNode }): JSX.Element {
  // Use a ref to avoid unnecessary re-renders and so the transition
  // can immediately inspect changes (useState wouldn't reflect changes
  // until next render).
  const elementStackRef = useRef<Array<DialogTransitionItem>>([])
  const elementStack = elementStackRef.current

  // It's possible for a component to just be unmounted without the dialog closing.
  // This function will find any stray dialogs that are no longer in the DOM
  // and remove them from its list.
  useEffect(() => {
    const observer = new MutationObserver(mutationRecords => {
      const elementStack = elementStackRef.current
      mutationRecords.forEach(record => {
        record.removedNodes.forEach(removedNode => {
          for (let index = elementStack.length - 1; index >= 0; index--) {
            const element = elementStack[index].element
            if (removedNode === element || removedNode.contains(element)) {
              elementStack.splice(index, 1)
            }
          }
        })
      })
    })
    // This relies on Dialogs being mounted as direct children of document.body, ignoring
    // further descendents in the subtree.
    observer.observe(document.body, { childList: true })
    return () => {
      observer.disconnect()
    }
  }, [])

  const removeById = (id: string): void => {
    const existingItem = elementStack.find(item => item.id === id)
    if (existingItem) {
      elementStack.splice(elementStack.indexOf(existingItem), 1)
    }
  }

  const add = (item: DialogTransitionItem): void => {
    removeById(item.id)
    elementStack.push(item)
  }

  const at = (offset: number): DialogTransitionItem | undefined => {
    if (offset < 0) {
      return elementStack[elementStack.length + offset]
    }
    return elementStack[offset]
  }

  return (
    <DialogTransitionContext.Provider
      value={{
        add,
        removeById,
        at
      }}
    >
      {children}
    </DialogTransitionContext.Provider>
  )
}
