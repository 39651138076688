import { NextRouter, useRouter } from 'next/router'
import { useMemo } from 'react'

function getSingle(router: NextRouter, key: string): string | undefined {
  const value = router.query[key]
  return Array.isArray(value) ? value[value.length - 1] : value
}

function getMultiple(router: NextRouter, key: string): string[] | undefined {
  const value = router.query[key]
  if (value === undefined) return undefined
  return Array.isArray(value) ? value : [value]
}

export interface FlowRouter extends NextRouter {
  /**
   * Get a query param key as a string.
   * If the key exists multiple times, the last one will be used.
   */
  getString(key: string): string | undefined

  /**
   * Get a query param key as a boolean.
   * If the key exists multiple times, the last one will be used.
   */
  getBoolean(key: string): boolean | undefined
  /**
   * Get a query param key as an array of strings.
   * If the key only exists once, it'll still be put into an array.
   */
  getStrings(key: string): string[] | undefined
  /**
   * Get a query param key as an array of strings, split any values by
   * a delimiter (comma by default).
   * If the key only exists once, it'll still be put into an array.
   *
   * @example
   * // ?foo=cat,dog&foo=chicken
   * // => ['cat','dog','chicken']
   */
  getDelimitedStrings(key: string, options?: { delimiter: string }): string[] | undefined
  /**
   * An object with all query params cast as strings.
   *
   * @example
   * // ?foo=bar&baz=qux
   * // => { foo: 'bar', baz: 'qux' }
   */
  stringQuery: Record<string, string>
}

function wrapRouter(router: NextRouter): FlowRouter {
  const stringQuery = Object.keys(router.query).reduce<Record<string, string>>((acc, key) => {
    const value = getSingle(router, key)
    return value ? { ...acc, [key]: value } : acc
  }, {})

  return {
    ...router,
    getString(key) {
      return getSingle(router, key)
    },
    getStrings(key) {
      return getMultiple(router, key)
    },
    getDelimitedStrings(key, options) {
      const { delimiter = ',' } = options || {}
      const values = getMultiple(router, key)
      if (!values) return undefined
      return values.reduce<string[]>((acc, value) => {
        return acc.concat(...value.split(delimiter))
      }, [])
    },
    getBoolean(key) {
      const value = getSingle(router, key)
      return value ? value.toLowerCase() === 'true' || value === '1' || value === 'on' : undefined
    },
    stringQuery
  }
}

/**
 * Wraps useRouter from nextjs with extra functionality, such as query param parsing.
 *
 * @see useRouter
 *
 * @example
 * const flowRouter = useFlowRouter()
 * flowRouter.getString('foo') || 'default'
 */
export function useFlowRouter(): FlowRouter {
  const router = useRouter()
  const flowRouter = useMemo(() => wrapRouter(router), [router])
  return flowRouter
}
