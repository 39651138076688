import fs from 'fs'

type ManifestItem = {
  id: number
  files: string[]
}

export type ReactLoadableManifest = Record<string, ManifestItem>

let _reactLoadableManifest: string[] | undefined
export const reactLoadableManifest = (): string[] => {
  if (_reactLoadableManifest === undefined) {
    try {
      const fileContents = fs.readFileSync(`${process.cwd()}/.next/react-loadable-manifest.json`, {
        encoding: 'utf8'
      })
      const manifest: ReactLoadableManifest = JSON.parse(fileContents)
      _reactLoadableManifest = Object.values(manifest).flatMap(entry => {
        return entry.files
      })
    } catch (e) {
      console.warn(`${__filename} could not load react-loadable-manifest.json`)
      _reactLoadableManifest = []
    }
  }
  return _reactLoadableManifest
}
