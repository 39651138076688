import { PageEditorState } from './pageEditor.types'

export const initialEditorState: PageEditorState = {
  tempPage: null,
  pageId: null,
  templateId: null,
  pages: undefined,
  clientHasMaillchimpKey: '',
  autosaveIndicatorState: {
    isSuccess: true,
    isLoading: false,
    isError: false,
    error: undefined
  }
}

/**
 * flowpageAPI mutation endpoint names that trigger loading, success and error
 * states for the autosave indicator
 */
export const mutationEndpointNamesThatTriggerAutosaveIndicator = new Set([
  'createLink',
  'editLink',
  'deleteLink',
  'reorderLinks',
  'createOrEditLinkTheme',
  'editPageInfo',
  'renameSlug',
  'editTheme'
])
