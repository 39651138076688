import { Heap, getHeap } from '@dtx-company/true-common/src/utils/heap'
import { useEffect, useState } from 'react'

/**
 * Provides the heap object in a way that's safe for other hooks to use.
 */
export function useHeap(): Heap | undefined {
  const [heap, setHeap] = useState<Heap>()
  useEffect(() => {
    let id = 0
    function checkHeap(): void {
      const myHeap = getHeap()
      if (myHeap && myHeap !== heap) {
        setHeap(myHeap)
      }
      // Heap is initially created as an array, with methods that push commands
      // onto itself so that once the object is instantiated it can replay the commands
      // in sequence.
      // We need to updating it until the object version is loaded or we'll have an
      // old reference to the array version trying to `push` onto the object version
      if (!myHeap || !myHeap.loaded) {
        id = window.requestAnimationFrame(checkHeap)
      }
    }
    checkHeap()
    return () => {
      window.cancelAnimationFrame(id)
    }
  }, [heap])
  return heap
}
