import { FC, ReactNode } from 'react'
import { default as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip'
import { Shadows } from '@mui/material/styles'
import { Stack } from '../Stack/Stack'
import { Typography } from '../Typography/Typography'

export type TooltipProps = Pick<
  MuiTooltipProps,
  | 'children'
  | 'arrow'
  | 'describeChild'
  | 'disableFocusListener'
  | 'disableHoverListener'
  | 'disableInteractive'
  | 'disableTouchListener'
  | 'enterDelay'
  | 'enterNextDelay'
  | 'enterTouchDelay'
  | 'followCursor'
  | 'id'
  | 'leaveDelay'
  | 'leaveTouchDelay'
  | 'onClose'
  | 'onOpen'
  | 'open'
  | 'placement'
  | 'title'
>

/**
 * Tooltips display informative text when users hover over, focus on, or tap an element.
 * Documentation: [https://mui.com/material-ui/react-tooltip]
 */

export const Tooltip: FC<TooltipProps> = (props: TooltipProps) => (
  <MuiTooltip
    data-testid="tooltip"
    {...props}
    componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: 'action.tooltip',
          fontSize: '1rem',
          lineHeight: theme => theme.spacing(6),
          boxShadow: theme => (theme.shadows as Shadows)[2]
        }
      },
      arrow: {
        sx: {
          color: 'action.tooltip'
        }
      }
    }}
    enterTouchDelay={0}
  />
)

export const TooltipContent: FC<{ title?: string; content?: ReactNode | string }> = ({
  title,
  content
}) => (
  <Stack
    gap={2}
    sx={{
      '& *': {
        cursor: 'default'
      },
      '& a': {
        cursor: 'pointer',
        textDecoration: 'underline',
        display: 'inline'
      }
    }}
  >
    <Typography variant={'labelLarge'}>{title}</Typography>
    <Typography variant={'labelMedium'}>{content}</Typography>
  </Stack>
)
