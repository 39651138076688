import { Config, system } from 'styled-system'

const config: Config = {
  transition: true,
  transitionDelay: {
    property: 'transitionDelay'
  },
  transitionDuration: {
    property: 'transitionDuration'
  },
  transitionProperty: {
    property: 'transitionProperty'
  },
  transitionTimingFunction: {
    property: 'transitionTimingFunction'
  }
}

export interface TransitionProps {
  transition?: React.CSSProperties['transition']
  transitionDelay?: React.CSSProperties['transitionDelay']
  transitionDuration?: React.CSSProperties['transitionDuration']
  transitionProperty?: React.CSSProperties['transitionProperty']
  transitionTimingFunction?: React.CSSProperties['transitionTimingFunction']
}

export const transition = system(config)
