import { PlansService } from '@dtx-company/connect-codegen/src/gen/plans/v1/plans_connect'
import { PromiseClient, createPromiseClient } from '@bufbuild/connect'
import { createConnectTransport } from '@bufbuild/connect-web'

export const getPlansServiceBaseUrl = (): string => {
  switch (process.env.APP_ENV) {
    case 'development':
      if (process.env.APP_NAME === 'internal-admin') {
        return `http://localhost:3003/internal-admin/api/proxy/atlas-dev-only`
      } else {
        return `http://localhost:3000/api/proxy/atlas-dev-only`
      }
    case 'staging':
      return `https://gateway.stg.flowcode.com/atlas`
    case 'preprod':
      return `https://gateway.preprod.flowcode.com/atlas`
    case 'production':
    default:
      return `https://gateway.flowcode.com/atlas`
  }
}

const plansClient: PromiseClient<typeof PlansService> = createPromiseClient(
  PlansService,
  createConnectTransport({
    baseUrl: getPlansServiceBaseUrl(),
    credentials: 'include'
  })
)

export default plansClient
