import { Components, Theme } from '@mui/material'
import { buttonClasses } from '@mui/material/Button'
import { paperClasses } from '@mui/material/Paper'
import { snackbarContentClasses } from '@mui/material/SnackbarContent'

/**
 * MUI Theme overrides for Flowcode's [Snackbar](./Snackbar.tsx) component.
 */
export const snackbarComponentOverrides: Components<Theme> = {
  MuiSnackbar: {
    styleOverrides: {
      root: ({ theme }) => ({
        [`&& .${paperClasses.root}.${snackbarContentClasses.root}`]: {
          minWidth: 'unset',

          // Set background color for 'default' type
          ['&[role="status"]']: {
            backgroundColor: theme.palette.surface.main,

            // Set action button text color for 'default' type
            [`.${buttonClasses.textInherit}`]: {
              color: theme.palette.onSurface.inverseTertiary
            }
          },

          // Set background and text color for 'error' type
          ['&[role="alert"]']: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText
          }
        }
      })
    }
  }
}
