import {
  BLACK_COLOR,
  BLUE_COLOR,
  BLUE_DARK_COLOR,
  BROWN_COLOR,
  BROWN_DARK_COLOR,
  GREY_COLOR,
  GREY_DARK_COLOR,
  JADE_COLOR,
  JADE_DARK_COLOR,
  MAGENTA_COLOR,
  MAGENTA_DARK_COLOR,
  NAVY_COLOR,
  NAVY_DARK_COLOR,
  PINK_COLOR,
  PINK_DARK_COLOR,
  PUMPKIN_COLOR,
  PUMPKIN_DARK_COLOR,
  PURPLE_COLOR,
  PURPLE_DARK_COLOR,
  RED_COLOR,
  RED_DARK_COLOR,
  TURQUOISE_COLOR,
  TURQUOISE_DARK_COLOR
} from '../../CombinedGenerator/components/Inputs/constants'
import { FlowcodeColorOptionType } from '@app/common/src/types/createFlowcode.types'

export const LOGGED_OUT_GENERATOR_COLORS: FlowcodeColorOptionType[] = [
  BLACK_COLOR,
  GREY_DARK_COLOR,
  GREY_COLOR,
  BROWN_DARK_COLOR,
  BROWN_COLOR,
  PUMPKIN_DARK_COLOR,
  PUMPKIN_COLOR,
  RED_DARK_COLOR,
  RED_COLOR,
  MAGENTA_DARK_COLOR,
  MAGENTA_COLOR,
  PINK_DARK_COLOR,
  PINK_COLOR,
  PURPLE_DARK_COLOR,
  PURPLE_COLOR,
  NAVY_DARK_COLOR,
  NAVY_COLOR,
  BLUE_DARK_COLOR,
  BLUE_COLOR,
  TURQUOISE_DARK_COLOR,
  TURQUOISE_COLOR,
  JADE_DARK_COLOR,
  JADE_COLOR
]
