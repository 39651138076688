import * as editPageReducers from './editPageReducers'
import { AnyAction, createSlice } from '@reduxjs/toolkit'
import {
  initialEditorState,
  mutationEndpointNamesThatTriggerAutosaveIndicator
} from './pageEditor.constants'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const pageEditorSlice = createSlice({
  name: 'pageEditor',
  initialState: initialEditorState,
  reducers: editPageReducers,
  extraReducers: builder => {
    // Handle success, loading and error state Autosave indicator for flowpageApi mutations
    builder.addMatcher(
      (action: AnyAction) =>
        mutationEndpointNamesThatTriggerAutosaveIndicator.has(action.meta?.arg?.endpointName || ''),
      (state, action) => {
        if (action.type.endsWith('/fulfilled')) state.autosaveIndicatorState = { isSuccess: true }
        if (action.type.endsWith('/pending')) state.autosaveIndicatorState = { isLoading: true }
        if (action.type.endsWith('/rejected'))
          state.autosaveIndicatorState = { isError: true, error: action.payload }
      }
    )
  }
})

const pageReducer = persistReducer(
  {
    key: 'pageEditorReducer',
    version: 1,
    storage,
    whitelist: ['pageId']
  },
  pageEditorSlice.reducer
)

export const {
  addLink,
  editLink,
  deleteLink,
  orderLinks,
  editTheme,
  editUserInfo,
  toggleLinkActive,
  setPageId,
  editPageType,
  editProfileImage,
  initDemoPage,
  updateRenamePage,
  renameSlug,
  toggleShare,
  resetPageEditorSlice,
  initTempPage,
  setTemplateId,
  setClientHasMailchimpKey,
  toggleLoading
} = pageEditorSlice.actions

export default pageReducer
