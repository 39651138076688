import { FlowExperimentConsole } from '../useFlowExperiment/context/FlowExperimentProvider'
import { FlowFeatureConsole } from '../useFlowFeature/context/FlowFeatureContext'
import { useEffect, useRef } from 'react'
import { useIsMounted } from '@dtx-company/true-common/src/hooks/useIsMounted'

export interface FlowConsole {
  experiment?: FlowExperimentConsole
  feature?: FlowFeatureConsole
}

function getFlowConsole(): FlowConsole {
  window.flow = window.flow || {}
  return window.flow
}

/**
 * Provides the flowConsole namespace so that methods may be added to it.
 *
 * This namespace is intended be used to provide dx tools such as
 * flow.feature.add(...) to add feature overrides and in the future
 * may have a command to toggle visibility of a more robust DX UI.
 *
 * The namespace resides at `window.flow` in the dev console.
 */
export function useFlowConsole(callback: (flow: FlowConsole) => unknown): void {
  const isMounted = useIsMounted()
  const initialCallback = useRef(callback).current
  useEffect(() => {
    if (!isMounted()) return
    const flowConsole = getFlowConsole()
    initialCallback(flowConsole)
  }, [isMounted, initialCallback])
}
