import { SVGProps, memo } from 'react'

function SocialMediaSvg({
  stroke = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 26 27" {...rest}>
      <path
        stroke={color || stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4.125 17.25a3.125 3.125 0 10-.001-6.251 3.125 3.125 0 00.001 6.251zm17.5-10a3.125 3.125 0 10-.001-6.251 3.125 3.125 0 00.001 6.251zm0 18.75a3.125 3.125 0 10-.001-6.251A3.125 3.125 0 0021.625 26zM7.25 14.75l11.25 7.5M18.5 6L7.25 12.25"
      />
    </svg>
  )
}

export const SocialMedia = memo(SocialMediaSvg)

function SocialMedia2Svg({ fill, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={15}
      height={16}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.099 10.41l-.093.093-4.363-2.145c0-.187.093-.373.093-.56 0-.186 0-.373-.093-.56l4.363-2.144.093.093c.557.56 1.207.84 1.95.84.742 0 1.392-.28 1.949-.84.557-.56.835-1.213.835-1.959s-.278-1.399-.835-1.959c-1.021-1.025-2.785-1.025-3.899 0-.557.56-.835 1.213-.835 1.96 0 .186 0 .372.092.559L4.994 5.933 4.9 5.84C3.88 4.814 2.116 4.72 1 5.84.446 6.4.168 7.052.168 7.798c0 .747.278 1.4.835 1.959.557.56 1.207.84 1.95.84.742 0 1.392-.28 1.949-.84l.093-.093 4.362 2.145c0 .187-.092.373-.092.56 0 .746.278 1.399.835 1.958.557.56 1.207.84 1.95.84.742 0 1.392-.28 1.949-.84.557-.56.835-1.212.835-1.958 0-.747-.278-1.4-.835-1.96-1.114-1.025-2.878-1.025-3.899 0z"
        fill={fill || '#111'}
      />
    </svg>
  )
}

export const SocialMedia2 = memo(SocialMedia2Svg)

function SharePageAndroidSvg({ color = '#111111', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.33333 15.3332C7.17428 15.3332 8.66667 13.8408 8.66667 11.9998C8.66667 10.1589 7.17428 8.66649 5.33333 8.66649C3.49238 8.66649 2 10.1589 2 11.9998C2 13.8408 3.49238 15.3332 5.33333 15.3332Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6667 8.66648C20.5076 8.66648 22 7.1741 22 5.33315C22 3.4922 20.5076 1.99982 18.6667 1.99982C16.8257 1.99982 15.3333 3.4922 15.3333 5.33315C15.3333 7.1741 16.8257 8.66648 18.6667 8.66648Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6667 21.9998C20.5076 21.9998 22 20.5074 22 18.6665C22 16.8255 20.5076 15.3331 18.6667 15.3331C16.8257 15.3331 15.3333 16.8255 15.3333 18.6665C15.3333 20.5074 16.8257 21.9998 18.6667 21.9998Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3 10.5165L15.7 6.81648"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3 13.4832L15.7 17.1832"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SharePageAndroid = memo(SharePageAndroidSvg)

function SharePageiOSSvg({ color = '#111111', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.90909 8.36363H5.86363C4.73352 8.36363 3.81818 9.47314 3.81818 10.843V19.5207C3.81818 20.8905 4.73352 22 5.86363 22H18.1364C19.2665 22 20.1818 20.8905 20.1818 19.5207V10.843C20.1818 9.47314 19.2665 8.36363 18.1364 8.36363H16.0909"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 2V14.2222" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.59206 5.33333L12 2L15.4091 5.33333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SharePageiOS = memo(SharePageiOSSvg)
