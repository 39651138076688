import { clearCache } from '@dtx-company/ithaca-sdk/src'
import { clearSSORefreshTokenFromLocalStorage } from '@dtx-company/true-common/src/utils/localStorage/clearSSORefreshTokenFromLocalStorage'
import { useEffect } from 'react'

export function useClearDeprecatedAuthState(): void {
  useEffect(() => {
    //clear old cookies + previous SSO state values
    clearCache()
    clearSSORefreshTokenFromLocalStorage()
  }, [])
}
