import { Link, PageType } from '@dtx-company/flow-codegen/src/page/generated.types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PlaceholdersType } from '@dtx-company/inter-app/src/types/flowpage'
import { checkUserHasReachedMaxPages } from '@dtx-company/inter-app/src/redux/slices/utils/pageLimit'
import { placeholders } from '../../constants/flowpageLinkPlaceholders'

export interface FPFunnelStateType {
  slug: string | null
  batchId: string | null | undefined
  pageType?: PageType
  isExistingFlowpage: boolean //when a user chooses to change an existin page to a flowtag page
  userHasReachedMaxPages: boolean | undefined
  placeholders?: PlaceholdersType
}

const initialState: FPFunnelStateType = {
  slug: null,
  batchId: undefined,
  isExistingFlowpage: false,
  pageType: undefined,
  userHasReachedMaxPages: undefined,
  placeholders: placeholders
}

const fpFunneSlice = createSlice({
  name: 'fpFunnel',
  initialState,
  reducers: {
    setSlug: (state, action: PayloadAction<{ slug: string | null }>) => {
      state.slug = action.payload.slug
    },
    setBatchId: (state, action: PayloadAction<{ batchId: string | null }>) => {
      state.batchId = action.payload.batchId
    },
    setIsExistingFlowpage: (state, action: PayloadAction<{ isExistingFlowpage: boolean }>) => {
      state.isExistingFlowpage = action.payload.isExistingFlowpage
    },
    setPageType: (state, action: PayloadAction<{ pageType: PageType | undefined }>) => {
      state.pageType = action.payload.pageType
    },
    setPlaceholders: (state, action: PayloadAction<{ placeholders: PlaceholdersType }>) => {
      state.placeholders = action.payload.placeholders
    },

    editSocialPlaceholder: (state, action: PayloadAction<{ link?: Partial<Link> }>) => {
      state.placeholders = {
        ...state?.placeholders,
        socialLink: { ...state.placeholders?.socialLink, ...action.payload?.link }
      }
    },
    editDestinationPlaceholder: (state, action: PayloadAction<{ link?: Partial<Link> }>) => {
      state.placeholders = {
        ...state?.placeholders,
        destination: { ...state.placeholders?.destination, ...action.payload?.link }
      }
    },
    editContactPlaceholder: (state, action: PayloadAction<{ link?: Partial<Link> }>) => {
      state.placeholders = {
        ...state?.placeholders,
        contactInfo: { ...state.placeholders?.contactInfo, ...action.payload?.link }
      }
    },
    clearPlaceholders: (state, _action) => {
      state.placeholders = undefined
    },
    clearPlaceholderType: (state, action: PayloadAction<{ type: keyof PlaceholdersType }>) => {
      if (state.placeholders) {
        state.placeholders[action.payload.type] = undefined
      }
    },
    reorderPlaceholders: (state, action: PayloadAction<{ links: Partial<Link>[] }>) => {
      const oldLinks = state.placeholders
      if (oldLinks) {
        const links = action.payload.links
        const newLinksMap = links.reduce(
          (acc, link, idx) => ({ ...acc, ...(link?.id && { [link?.id]: idx }) }),
          {} as Record<string, number>
        )

        state.placeholders = {
          socialLink: oldLinks?.socialLink
            ? {
                ...oldLinks.socialLink,
                order: newLinksMap['placeholder-social-link']
              }
            : undefined,
          destination: oldLinks?.destination
            ? {
                ...oldLinks.destination,
                order: newLinksMap['placeholder-destination-link']
              }
            : undefined,
          contactInfo: oldLinks.contactInfo
            ? {
                ...oldLinks.contactInfo,
                order: newLinksMap['placeholder-contact-link']
              }
            : undefined
        }
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(checkUserHasReachedMaxPages.fulfilled, (state, action) => {
      state.userHasReachedMaxPages = action.payload.userHasReachedMaxPages
    })
    builder.addCase(checkUserHasReachedMaxPages.pending, (state, _action) => {
      state.userHasReachedMaxPages = undefined
    })
    builder.addCase(checkUserHasReachedMaxPages.rejected, (state, _action) => {
      state.userHasReachedMaxPages = false
    })
  }
})

export const {
  setSlug,
  setBatchId,
  setIsExistingFlowpage,
  setPageType,
  editDestinationPlaceholder,
  reorderPlaceholders,
  editSocialPlaceholder,
  editContactPlaceholder,
  clearPlaceholderType,
  setPlaceholders,
  clearPlaceholders
} = fpFunneSlice.actions

export default fpFunneSlice.reducer
