import { Box } from '../Box'
import { FC, RefObject, useEffect, useRef } from 'react'
import { ImageStyleProps } from '../../../types'
import { useVisibilityThreshold } from '../../../hooks/useVisibilityThreshold'
import LazyLoad, { forceVisible } from 'react-lazyload'
import styled from 'styled-components'

const ImageBase = styled.img<ImageStyleProps>`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: ${props => props.objectFit};
`

const LazyLoadStyleReset = styled.span`
  .lazyload-wrapper {
    display: inline;
  }
`

export type ImageComponentProps = ImageStyleProps & {
  ref?: RefObject<HTMLDivElement>
  lazyLoad?: boolean
}

export const Image: FC<ImageComponentProps> = ({
  src,
  alt,
  srcSet,
  lazyLoad,
  objectFit = 'contain',
  ...rest
}: ImageComponentProps): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { visible } = useVisibilityThreshold({ threshold: 0.1, ref })

  useEffect(() => {
    if (visible && lazyLoad) {
      forceVisible()
    }
  }, [visible, lazyLoad])

  return (
    <Box data-testid={alt || src} ref={ref} {...rest}>
      {lazyLoad ? (
        <LazyLoadStyleReset>
          <LazyLoad height="100%" offset={0} once={true}>
            <ImageBase src={src} srcSet={srcSet} alt={alt} objectFit={objectFit} />
          </LazyLoad>
        </LazyLoadStyleReset>
      ) : (
        <ImageBase src={src} srcSet={srcSet} alt={alt} objectFit={objectFit} />
      )}
    </Box>
  )
}
