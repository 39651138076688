import { AppOptimizelyProvider } from './AppOptimizelyProvider'
import { AtlasProvider } from '@dtx-company/inter-app/src/hoc/atlas-provider/provider'
import { FC, ReactNode } from 'react'
import { FlowSWRConfig } from '@dtx-company/inter-app/src/components/FlowSWRConfig'
import { HooksRequiringProviders } from './HooksRequiringProviders'
import { ImpactSync } from './Impact'
import { Intercom } from './Intercom'
import { PersistGate } from 'redux-persist/integration/react'
import { SessionAndOptimizelyValues } from '../types'
import { XStateProvider } from './XStateProvider'
import { persistor } from '@dtx-company/inter-app/src/redux/persistor'

export type PageProvidersProps = {
  swrFallback?: Record<string, unknown>
} & SessionAndOptimizelyValues

export const PageProviders: FC<PageProvidersProps & { children: ReactNode }> = ({
  children,
  swrFallback,
  session,
  optimizely,
  ...pageProps
}) => {
  return (
    <FlowSWRConfig fallback={swrFallback}>
      <AppOptimizelyProvider session={session} optimizely={optimizely}>
        <AtlasProvider>
          <HooksRequiringProviders session={session} />
          <XStateProvider
            isMobile={optimizely?.attributes?.deviceType !== 'desktop'}
            {...pageProps}
          >
            <PersistGate loading={children} persistor={persistor}>
              {children}
              <Intercom />
              <ImpactSync />
            </PersistGate>
          </XStateProvider>
        </AtlasProvider>
      </AppOptimizelyProvider>
    </FlowSWRConfig>
  )
}
