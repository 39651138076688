import { AssetType, DateRange } from '@dtx-company/connect-codegen/src/gen/asset/v1/asset_pb'
import { Order } from '@dtx-company/connect-codegen/src/gen/common/v1/pagination_pb'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TeamMemberResult } from '@dtx-company/inter-app/src/components/FlowTeams/components/OrganizationSearchField/OrganizationSearchField.types'

export interface UnifiedAssetManagementState {
  orderByDirection: Order
  orderByField: 'Name' | 'CreatedAt' | 'Description' | 'UpdatedAt'
  filters: {
    assetTypes: AssetType[]
    owners: TeamMemberResult[]
    tags: {
      id: string
      name: string
    }[]
    createdAtDateRange: Pick<DateRange, 'endDate' | 'startDate'> | undefined
    namePrefix: string
  }
  createInFolderPath: string | undefined
  shouldMutateFolders: boolean
  shouldMutateAssets: boolean
  shouldMutateCurrentFolder: boolean
  hasEnteredBulkActionsMode: boolean
  showScopedSearch: boolean
  emptyArchiveFlowOpen: boolean
}

const initialState: UnifiedAssetManagementState = {
  orderByDirection: Order.DESC,
  orderByField: 'CreatedAt',
  filters: {
    assetTypes: [AssetType.CODE, AssetType.PAGE],
    owners: [],
    tags: [],
    createdAtDateRange: undefined,
    namePrefix: ''
  },
  createInFolderPath: undefined,
  shouldMutateFolders: false,
  shouldMutateAssets: false,
  shouldMutateCurrentFolder: false,
  hasEnteredBulkActionsMode: false,
  showScopedSearch: false,
  emptyArchiveFlowOpen: false
}

const unifiedAssetManagement = createSlice({
  name: 'unifiedAssetManagement',
  initialState,
  reducers: {
    setCreateInFolderPath: (state, action: PayloadAction<string | undefined>) => {
      state.createInFolderPath = action.payload
    },
    setShouldMutateFolders: (state, action: PayloadAction<boolean>) => {
      state.shouldMutateFolders = action.payload
    },
    setShouldMutateAssets: (state, action: PayloadAction<boolean>) => {
      state.shouldMutateAssets = action.payload
    },
    setShouldMutateCurrentFolder: (state, action: PayloadAction<boolean>) => {
      state.shouldMutateCurrentFolder = action.payload
    },
    setHasEnteredBulkActionsMode: (state, action: PayloadAction<boolean>) => {
      state.hasEnteredBulkActionsMode = action.payload
    },
    setShowScopedSearch: (state, action: PayloadAction<boolean>) => {
      state.showScopedSearch = action.payload
    },
    setEmptyArchiveFlowOpen: (state, action: PayloadAction<boolean>) => {
      state.emptyArchiveFlowOpen = action.payload
    }
  }
})

export const {
  setCreateInFolderPath,
  setShouldMutateFolders,
  setShouldMutateAssets,
  setShouldMutateCurrentFolder,
  setHasEnteredBulkActionsMode,
  setShowScopedSearch,
  setEmptyArchiveFlowOpen
} = unifiedAssetManagement.actions

export default unifiedAssetManagement.reducer
