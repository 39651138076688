/**
 * Event ids for custom behavioral events in Hubspot.
 *
 * Must be created on their site first under Reports > Analytics Tools
 */
export enum HubspotEvent {
  ProAccountCreation = 'pe8765124_pro_account_signup',
  FlowpageAccountCreation = 'pe8765124_flowpage_account_signup',
  FlowcodeAccountCreation = 'pe8765124_flowcode_account_signup'
}
