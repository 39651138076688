// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file staticassets/v1/static_assets.proto (package staticassets.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { Message, proto3 } from '@bufbuild/protobuf'

/**
 * @generated from message staticassets.v1.GetUploadUrlRequest
 */
export class GetUploadUrlRequest extends Message<GetUploadUrlRequest> {
  constructor(data?: PartialMessage<GetUploadUrlRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'staticassets.v1.GetUploadUrlRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUploadUrlRequest {
    return new GetUploadUrlRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUploadUrlRequest {
    return new GetUploadUrlRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetUploadUrlRequest {
    return new GetUploadUrlRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetUploadUrlRequest | PlainMessage<GetUploadUrlRequest> | undefined,
    b: GetUploadUrlRequest | PlainMessage<GetUploadUrlRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetUploadUrlRequest, a, b)
  }
}

/**
 * @generated from message staticassets.v1.GetUploadUrlResponse
 */
export class GetUploadUrlResponse extends Message<GetUploadUrlResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = ''

  /**
   * @generated from field: string upload_url = 2;
   */
  uploadUrl = ''

  constructor(data?: PartialMessage<GetUploadUrlResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'staticassets.v1.GetUploadUrlResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'upload_url', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUploadUrlResponse {
    return new GetUploadUrlResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUploadUrlResponse {
    return new GetUploadUrlResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetUploadUrlResponse {
    return new GetUploadUrlResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetUploadUrlResponse | PlainMessage<GetUploadUrlResponse> | undefined,
    b: GetUploadUrlResponse | PlainMessage<GetUploadUrlResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetUploadUrlResponse, a, b)
  }
}
