import { useEffect, useState } from 'react'
import { useIsMounted } from '@dtx-company/true-common/src/hooks/useIsMounted'

export const useInitialWindowSize = (): {
  width: number | undefined
  height: number | undefined
} => {
  const [width, setWidth] = useState<number>()
  const [height, setHeight] = useState<number>()
  const isMounted = useIsMounted()

  useEffect(() => {
    if (isMounted() && window) {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
  }, [isMounted])

  useEffect(() => {
    const onWindowResize = (): void => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', onWindowResize)

    return () => window.removeEventListener('resize', onWindowResize)
  }, [])

  return {
    width,
    height
  }
}
