import { FlowExperimentCache } from './FlowExperimentContext'
import { FlowRouter } from '../../useFlowRouter'
import { parseFlowExperimentValue } from './parseFlowExperimentValue'

// Matches `experiment[${key}]`
const reFlagKey = /^experiment\[([^\]]+)\]$/

export function getFlowExperimentCacheFromRouter(flowRouter: FlowRouter): FlowExperimentCache {
  const cache: FlowExperimentCache = {}
  Object.keys(flowRouter.query).forEach(param => {
    const matches = reFlagKey.exec(param)
    if (matches && matches[1]) {
      const key = matches[1]
      const value = flowRouter.getString(param)
      try {
        const data = parseFlowExperimentValue(value)
        cache[key] = data
      } catch (error) {
        // This is dev functionality so can simply log to console
        console.warn(`An error occured trying to parse ?${param}: ${error.message}`)
      }
    }
  })
  return cache
}
