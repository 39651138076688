import { Components, Theme } from '@mui/material'

/**
 * MUI Theme overrides for Flowcode's [Dialog](./Dialog.tsx) component.
 */
export const dialogComponentOverrides: Components<Theme> = {
  MuiDialog: {
    styleOverrides: {
      root: ({ theme }) => ({}),
      paper: ({ theme }) => ({
        boxShadow: theme.shadows[3],
        '&.paperWithCloseIcon .MuiDialogTitle-root': {
          paddingTop: theme.spacing(4)
        }
      })
    }
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        overflowWrap: 'anywhere',
        paddingTop: theme.spacing(5)
      })
    }
  }
}
