import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type DefaultFieldType = {
  link?: string
  imageUrl?: string
  title?: string
}

export enum DrawerTypes {
  FLOWLYTICS_GETTING_STARTED = 'flowlytics-getting-started',
  FLOWTAG_FAQS = 'flowtag_faqs',
  FLOWCODE_FAQS = 'flowcode_faqs',
  TEMPLATES_FAQS = 'templates_faqs',
  PRINT_STORE_FAQS = 'print_store_faqs',
  FLOWPAGE_FAQS = 'flowpage_faqs'
}

export interface WidgetFormProps {
  widgetObj: any
  curr?: any
  defaultFields?: DefaultFieldType
}

export type DrawerType = { type: DrawerTypes; open: boolean; props?: DrawerProps }

export type DrawerProps = Record<string, (() => void) | string | boolean> | WidgetFormProps

export interface DrawerState {
  drawers: DrawerType[]
}

const initialDrawerState: DrawerState = {
  drawers: [
    { type: DrawerTypes.FLOWLYTICS_GETTING_STARTED, open: false },
    { type: DrawerTypes.FLOWTAG_FAQS, open: false },
    { type: DrawerTypes.FLOWCODE_FAQS, open: false },
    { type: DrawerTypes.TEMPLATES_FAQS, open: false },
    { type: DrawerTypes.PRINT_STORE_FAQS, open: false },
    { type: DrawerTypes.FLOWPAGE_FAQS, open: false }
  ]
}

const drawerSlice = createSlice({
  name: 'drawer',
  initialState: initialDrawerState,
  reducers: {
    setDrawerOpen(
      state,
      action: PayloadAction<{
        type: DrawerTypes
        props?: DrawerProps
      } | null>
    ) {
      const type = action?.payload?.type
      const props = action?.payload?.props
      state.drawers = state.drawers.map(drawer =>
        drawer.type === type ? { ...drawer, open: true, ...(props && { props }) } : drawer
      )
    },
    closeDrawers(state) {
      state.drawers.map(drawer => {
        return {
          ...drawer,
          open: false
        }
      })
    },
    setDrawerClose(state, action: PayloadAction<DrawerTypes | null>) {
      const type = action?.payload
      state.drawers = state.drawers.map(drawer =>
        drawer.type === type ? { type, open: false } : drawer
      )
    }
  }
})

export const { setDrawerClose, setDrawerOpen, closeDrawers } = drawerSlice.actions
export default drawerSlice.reducer
