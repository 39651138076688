import {
  ACCOUNT_INFO,
  GET_PAGE_DETAILS,
  GET_PAGE_TEMPLATE,
  HIGHLIGHTED_FLOWPAGES,
  PAGE_MANAGEMENT,
  TOTAL_PAGE_COUNT
} from '@dtx-company/flow-codegen/src/page/queries'
import {
  AccountInfoMeQuery,
  AccountInfoMeQueryVariables,
  GetPageDetailsQuery,
  GetPageDetailsQueryVariables,
  GetPageTemplateQuery,
  HighlightedFlowpagesQuery,
  HighlightedFlowpagesQueryVariables,
  PageCountQuery,
  PageCountQueryVariables,
  PageManagementMeQuery,
  PageManagementMeQueryVariables
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { flowpageApi } from './empty'

export const pageQueries = flowpageApi.injectEndpoints({
  endpoints: builder => ({
    /* Queries */
    pageDetail: builder.query<
      GetPageDetailsQuery,
      GetPageDetailsQueryVariables & { isPageTemplate?: boolean }
    >({
      query: ({ id, isPageTemplate }) => ({
        document: isPageTemplate ? GET_PAGE_TEMPLATE : GET_PAGE_DETAILS,
        variables: {
          id
        },
        authOnly: true
      }),
      transformResponse: (
        baseQueryData: GetPageDetailsQuery | GetPageTemplateQuery,
        _meta,
        { isPageTemplate }
      ): GetPageDetailsQuery => {
        if (!isPageTemplate) return baseQueryData as GetPageDetailsQuery
        return {
          __typename: 'Query',
          Page: (baseQueryData as GetPageTemplateQuery)?.template?.page
        }
      },
      providesTags: ['PageDetail']
    }),
    pageManagement: builder.query<PageManagementMeQuery, PageManagementMeQueryVariables>({
      query: ({ ithacaFilter, first, last, after, before, where, orderBy, orderDirection }) => ({
        document: PAGE_MANAGEMENT,
        authOnly: true,
        variables: {
          ithacaFilter,
          where,
          first,
          last,
          after,
          before,
          orderBy,
          orderDirection
        }
      }),
      providesTags: ['pageManagement']
    }),

    pageCount: builder.query<PageCountQuery, PageCountQueryVariables>({
      query: variables => ({
        document: TOTAL_PAGE_COUNT,
        authOnly: true,
        variables
      }),
      providesTags: ['pageCount']
    }),

    accountInfo: builder.query<AccountInfoMeQuery, AccountInfoMeQueryVariables>({
      query: () => ({
        document: ACCOUNT_INFO,
        authOnly: true
      }),
      providesTags: ['accountInfo']
    }),

    highlightedFlowpages: builder.query<
      HighlightedFlowpagesQuery,
      HighlightedFlowpagesQueryVariables
    >({
      query: ({ first }) => ({
        document: HIGHLIGHTED_FLOWPAGES,
        authOnly: true,
        variables: { first }
      }),
      providesTags: ['highlightedFlowpages']
    })
  }),

  overrideExisting: true
})

export const {
  usePageDetailQuery,
  useHighlightedFlowpagesQuery,
  usePageCountQuery,
  useLazyAccountInfoQuery,
  usePageManagementQuery,
  useLazyPageManagementQuery,
  useAccountInfoQuery
} = pageQueries
