import { createContext } from 'react'
import { parseFlowFeatureValue } from './parseFlowFeatureValue'
import { useFlowFeature } from '../useFlowFeature'

export type FlowFeatureCache = Record<string, ReturnType<typeof useFlowFeature>>

export interface FlowFeatureContextProps {
  featureConsole: FlowFeatureConsole | undefined
  localCache: FlowFeatureCache
  sessionCache: FlowFeatureCache
  queryCache: FlowFeatureCache
  liveCache: FlowFeatureCache
  addToLiveCache: (key: string, value: ReturnType<typeof useFlowFeature>) => void
  attributesReady: boolean
}

// TODO: have these default to undefined
// have `useFlowFeatureContext` throw new Error('useFlowFeatureContext must be used within FlowFeatureProvider')
// update all tests to ensure they have the Provider wrapping them
export const FlowFeatureContext = createContext<FlowFeatureContextProps>({
  featureConsole: undefined,
  localCache: {},
  sessionCache: {},
  queryCache: {},
  liveCache: {},
  // need to stub this til the above TODO is solved
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addToLiveCache(_key, _value) {},
  attributesReady: false
})

export interface FlowFeatureConsole {
  add(key: string, value: Parameters<typeof parseFlowFeatureValue>[0], persist?: boolean): void
  remove(key: string): void
  clear(): void
  getCache(): FlowFeatureCache | void
  list(): void
}
