import { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { ClientError, GraphQLClient } from 'graphql-request'
import { DocumentNode } from 'graphql'
import { GqlErrorObjectType } from '@app/code/src/types/error.types'
import { GraphqlRequestBaseQueryArgs } from './types'

type ReduxGraphqlRequestError = Pick<ClientError, 'name' | 'message' | 'stack'> & { code?: string }

export type GraphqlRequestBaseQuery = BaseQueryFn<
  { document: string | DocumentNode; variables?: any; authOnly?: boolean },
  unknown,
  ReduxGraphqlRequestError,
  Partial<Pick<ClientError, 'request' | 'response'>>
>

/**
 *
 * This function exists to modify the ClientError object to match the error structure
 * from the network response (specifically to include the code property)
 *
 */
function parseClientErrorToGqlError(error: ClientError): ReduxGraphqlRequestError {
  const { name, message, stack } = error
  const gqlError = 'response' in error ? (error as unknown as GqlErrorObjectType) : undefined
  const code = gqlError?.response?.errors?.[0]?.extensions?.code
  return { name, message, stack, code }
}

/** this is a modified version of graphqlRequestBaseQuery from @rtk-query/graphql-request-base-query to modify request headers for authentication */
export const graphqlRequestBaseQuery = ({
  options
}: GraphqlRequestBaseQueryArgs): GraphqlRequestBaseQuery => {
  const client = new GraphQLClient(options.url, { credentials: 'include' })

  return async ({ document, variables }) => {
    try {
      const data = await client.request(document, variables)
      return { data, meta: {} }
    } catch (error) {
      if (error instanceof ClientError) {
        const { name, message, stack, request, response } = error
        const gqlError = parseClientErrorToGqlError(error)
        console.error(`GQL error flowpage api: `, { name, message, stack, request })
        return { error: gqlError, meta: { request, response } }
      }
      throw error
    }
  }
}
