import { HEAP_APP_ID } from '../../../constants/third-party'
import { OPT_OUT } from '../../../constants/cookies'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

// DF - this is a slightly modified version of heap's snippet.
// MD - further modifying to resolve heap errors: https://app.shortcut.com/flowcode/story/68880/fix-heap-push-errors
// TODO: update to use `useIsFlowpage` - need to resolve how to ensure EU still works (can use a VPN to get into EU)
export const HeapScript = (): JSX.Element => {
  const optOut = Boolean(cookies.get(OPT_OUT))
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          if (!window.heap) {
            window.heap = window.heap || []
            window.heap.appid = ${HEAP_APP_ID}
            for (
              var n = function (e) {
                  return function () {
                    try {
                      var heap = window.heap
                      if (typeof heap.push === 'function') {
                        // This is the original stub code to create a command queue
                        // to execute heap fns once the real heap code is loaded
                        heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
                      } else {
                        // If this is hit, something is hanging onto a reference of the
                        // heap stub even after the real heap code has loaded so
                        // proxy the call over to the real code
                        heap[e].call(heap, arguments)
                      }
                    } catch (error) {
                      // An uncaught error here can crash the app.
                      // It is unclear why the code above fails to fix this,
                      // so here we just swallow the error.
                      console.error('Caught heap error: ' + error.message)
                    }
                  }
                },
                p = [
                  'addEventProperties',
                  'addUserProperties',
                  'clearEventProperties',
                  'identify',
                  'resetIdentity',
                  'removeEventProperty',
                  'setEventProperties',
                  'track',
                  'unsetEventProperty'
                ],
                o = 0;
              o < p.length;
              o++
            )
              heap[p[o]] = n(p[o])
          }
        `
        }}
      />
      {!optOut && (
        <script
          data-testid="heap-script"
          className="optanon-category-C0002"
          type="text/plain" // Uses type="text/plain" so that the script doesn't load until tracking is accepted for EU, and then OneTrust will handle updating to load the script
          async
          defer
          src={`https://cdn.heapanalytics.com/js/heap-${HEAP_APP_ID}.js`}
        />
      )}
    </>
  )
}
