import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import { useCurrentUserJwt } from '@dtx-company/inter-app/src/redux/selectors/currentUserSelectors'
import { useEffect } from 'react'

export function CaptureUserAuthenticated(): null {
  const jwt = useCurrentUserJwt()

  const isAuthenticated = !!jwt && jwt.token != ''

  useEffect(() => {
    if (isAuthenticated) {
      fireAnalyticsEvent('userAuthenticated')
    }
  }, [isAuthenticated])

  return null
}
