interface UserProperties {
  email?: string
  dtxcookie?: string
  role?: string | number
  anonymousSessionId?: string
  plan?: string
}

export interface Heap {
  track: (event: string, properties?: Record<string, unknown>) => void
  identify: (identity: string) => void
  resetIdentity: () => void
  addUserProperties: (properties: UserProperties) => void
  addEventProperties: (properties: Record<string, unknown>) => void
  removeEventProperty: (property: string) => void
  clearEventProperties: () => void
  loaded: boolean
  appid: string
  userId: string
  identity: string | null
  config: { [key: string]: string | boolean }
}

export function getHeap(): Heap | undefined {
  if (typeof window === 'undefined') {
    return undefined
  }
  return window.heap
}

/**
 * If importing into apps/* or packages/inter-app, use: `@dtx-company/inter-app/src/event-tracking/helpers/index.ts`
 *
 * If importing into packages/* (not inter-app), use: `@dtx-company/true-common/src/utils/heap`
 */

export const fireHeapEvent: Heap['track'] = (event, properties): void => {
  getHeap()?.track(event, properties)
}
