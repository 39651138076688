import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface CurrentSessionState {
  isEU: boolean | undefined
  isEULoaded: boolean
  isUS: boolean | undefined
  isUSLoaded: boolean
}

export const initialCurrentSessionState: CurrentSessionState = {
  isEU: undefined,
  isEULoaded: false,
  isUS: undefined,
  isUSLoaded: false
}

const currentSessionSlice = createSlice({
  name: 'currentSession',
  initialState: initialCurrentSessionState,
  reducers: {
    setIsEU: (state, action: PayloadAction<{ isEU: boolean }>) => {
      state.isEU = action.payload.isEU
      state.isEULoaded = true
    },
    setIsUS: (state, action: PayloadAction<{ isUS: boolean }>) => {
      state.isUS = action.payload.isUS
      state.isUSLoaded = true
    }
  }
})

export const { setIsEU, setIsUS } = currentSessionSlice.actions

export default currentSessionSlice.reducer
