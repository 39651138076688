import { doPolygonsIntersect, rotatePoint } from './fc_math'
import { fcColorDifferenceFromValues, fcValueToRgb } from './fc_svg.types'

const FcGetPixelThreshold = 2

export enum FcShape {
  CIRCLE = 1,
  SQUARE,
  DIAMOND,
  SHAGGY, // Not using in Position Elements
  LOGO, // This Shape requires a text element to be passed
  CUSTOM = 6,
  NONE = 7
}

export type FcColor = string
export type FcSize = number
export type FcPercentageSize = number
export type FcFontFace = string
export type FcTextNode = {
  text: string
  font?: string
}

export const FcColorBlack = '#000'
export const FcColorWhite = '#ffffff'
export const FcColorTransparent = '#ffffff00'

export interface FcRgb {
  r: number
  g: number
  b: number
}

export interface FcCmyk {
  c: number
  m: number
  y: number
  k: number
}

export interface FcColorTranslation {
  rgb: FcRgb
  cmyk: FcCmyk
}

export interface FcGridImageData {
  blend: boolean
  rawImage: FcArea
  blendMask?: FcReservedArea
  blendModuleSize?: number
  blendErase?: boolean
  blendModuleColorFillSvg?: boolean
  blendModuleImageUrl?: string | null
  hasBlendModuleImage?: boolean
  blendModuleColorFg?: FcColor
  blendModuleColorBg?: FcColor
  blendModuleShape?: FcShape
  blendModuleImageData?: string | null
  blendModuleTolerationFg?: number
  blendModuleTolerationBg?: number
  blendModulePixelsToCheck?: number
  rotate?: number
}

export enum FcColorType {
  STROKE = 0,
  FILL = 1,
  STOP = 2
}

export enum FcElement {
  UNSET = 0,
  DATA,
  TIMING,
  ALIGNMENT,
  POSITION,
  TYPEINFO,
  CONTAINER
}

//NOTE: Don't we only need Bottom Left, Top Left, Top Right in the context of eyes?
export enum FcPositionProperty {
  DEFAULT = 0,
  CENTER,
  TOP_RIGHT,
  TOP_LEFT,
  BOTTOM_RIGHT,
  BOTTOM_LEFT,
  TOP,
  BOTTOM,
  RIGHT,
  LEFT
}

export class FcPosition {
  property: FcPositionProperty
  offsetPercentage: number
  extraDegrees?: number

  constructor(property: FcPositionProperty, offsetPercentage: number, extraDegrees?: number) {
    this.property = property
    this.offsetPercentage = offsetPercentage
    this.extraDegrees = extraDegrees
  }
}

export const FcPositionDefault = new FcPosition(FcPositionProperty.DEFAULT, 0)

export class FcPolygon {
  points: FcPoint[]
  boundingBox?: FcArea

  constructor(points: FcPoint[]) {
    this.points = points
  }

  getBoundingBox(): FcArea {
    let minX = 10000
    let maxX = 0
    let minY = 10000
    let maxY = 0
    if (!this.boundingBox) {
      this.points.forEach((p: FcPoint) => {
        if (p.x < minX) {
          minX = p.x
        }
        if (p.x > maxX) {
          maxX = p.x
        }
        if (p.y < minY) {
          minY = p.y
        }
        if (p.y > maxY) {
          maxY = p.y
        }
      })
      this.boundingBox = new FcArea(minX, minY, maxX - minX, maxY - minY)
    }
    return this.boundingBox
  }

  inBox(x: number, y: number, x2: number, y2: number): boolean {
    if (!this.getBoundingBox().inBox(x, y, x2, y2)) {
      return false
    }
    return doPolygonsIntersect(
      this,
      new FcPolygon([
        new FcPoint(x, y),
        new FcPoint(x, y2),
        new FcPoint(x2, y2),
        new FcPoint(x2, y)
      ])
    )
  }
}

export class FcArea {
  x: number
  y: number
  x2: number
  y2: number
  width: number
  height: number
  pixelPadding?: number
  rotatedQuad?: FcPolygon
  pixelMap?: Map<string, number>
  rotateAngle?: number

  constructor(
    x: number,
    y: number,
    width: number,
    height: number,
    pixels?: number[][],
    pixelPadding?: number,
    rotateAngle?: number,
    rotatedQuad?: FcPolygon
  ) {
    this.rotatedQuad = rotatedQuad
    this.x = x
    this.y = y
    this.width = width
    this.height = height
    this.x2 = x + width
    this.y2 = y + height
    this.rotateAngle = rotateAngle
    if (pixels) {
      this.pixelMap = new Map<string, number>()
      const pixelsHeight = pixels.length
      const pixelsWidth = pixels[0].length
      if (pixelsWidth >= width) {
        for (let i = 0; i < pixelsHeight; i++) {
          const scaledHeight = Math.round((i * height) / pixelsHeight)
          for (let j = 0; j < pixelsWidth; j++) {
            const scaledWidth = Math.round((j * width) / pixelsWidth)
            if (pixels[i][j]) {
              this.setPixelValue(scaledWidth, scaledHeight, pixels[i][j])
            }
          }
        }
      } else {
        for (let i = 0; i < height; i++) {
          const scaledHeight = Math.round((i * pixelsHeight) / height)
          for (let j = 0; j < width; j++) {
            const scaledWidth = Math.round((j * pixelsWidth) / width)
            if (pixels[scaledHeight] && pixels[scaledHeight][scaledWidth] !== undefined) {
              this.setPixelValue(j, i, pixels[scaledHeight][scaledWidth])
            }
          }
        }
      }
      this.pixelPadding = Math.ceil(pixelPadding || 0)
    }
  }

  setPixelValue(x: number, y: number, value: number): void {
    x = this.x + x
    y = this.y + y
    if (this.rotatedQuad) {
      const point = rotatePoint(
        new FcPoint(x, y),
        new FcPoint(this.x + this.width / 2, this.y + this.height / 2),
        this.rotateAngle!,
        1
      )
      x = point.x
      y = point.y
    }
    const key = `${Math.floor(x)}-${Math.floor(y)}`
    this.pixelMap!.set(key, value)
  }

  getPixelValue(x: number, y: number): number | undefined {
    const key = `${x}-${y}`
    return this.pixelMap?.get(key)
  }

  inBox(x: number, y: number, x2: number, y2: number): boolean {
    if (this.rotatedQuad) {
      if (!this.rotatedQuad.inBox(x, y, x2, y2)) {
        return false
      }
    } else {
      if (x > this.x2 || x2 < this.x) {
        return false
      }
      if (y > this.y2 || y2 < this.y) {
        return false
      }
    }
    if (!this.pixelMap) {
      return true
    }

    x = Math.floor(x)
    x2 = Math.floor(x2)
    y = Math.floor(y)
    y2 = Math.floor(y2)

    if (this.pixelPadding) {
      x -= this.pixelPadding
      x2 += this.pixelPadding
      y -= this.pixelPadding
      y2 += this.pixelPadding
    }

    for (let yc = y; yc <= y2; yc++) {
      for (let xc = x; xc <= x2; xc++) {
        if (this.getPixelValue(xc, yc) === 1) {
          return true
        }
      }
    }
    return false
  }

  getPixel(x: number, y: number, x2: number, y2: number, w?: number): FcRgb | undefined {
    if (!this.pixelMap) {
      return undefined
    }
    if (this.rotatedQuad) {
      if (!this.rotatedQuad.inBox(x, y, x2, y2)) {
        return undefined
      }
    } else {
      if (x > this.x2 || x2 < this.x) {
        return undefined
      }
      if (y > this.y2 || y2 < this.y) {
        return undefined
      }
    }
    x = Math.floor(x)
    x2 = Math.floor(x2)
    y = Math.floor(y)
    y2 = Math.floor(y2)
    if (this.pixelPadding) {
      x -= this.pixelPadding
      x2 += this.pixelPadding
      y -= this.pixelPadding
      y2 += this.pixelPadding
    }

    const yAvg = Math.floor(y + (y2 - y) / 2)
    const xAvg = Math.floor(x + (x2 - x) / 2)

    if (!w) {
      w = 0
    }

    x = xAvg - w
    x2 = xAvg + w
    y = yAvg - w
    y2 = yAvg + w

    let pix: number | undefined = undefined
    for (let i = x; i <= x2; i++) {
      for (let j = y; j <= y2; j++) {
        const p = this.getPixelValue(i, j)
        if (p === undefined) {
          return undefined
        }
        if (pix === undefined) {
          pix = p
        } else if (p !== pix) {
          if (fcColorDifferenceFromValues(p, pix) > FcGetPixelThreshold) {
            return undefined
          }
        }
      }
    }
    if (pix === undefined) {
      return pix
    }

    return fcValueToRgb(pix)
  }
}

export class FcPoint {
  x: number
  y: number

  constructor(x: number, y: number) {
    this.x = x
    this.y = y
  }
}

export class FcPointInCircle {
  x: number
  y: number
  angle: number

  constructor(x: number, y: number, angle: number) {
    this.x = x
    this.y = y
    this.angle = angle
  }
}

export interface FcText {
  text: string
  font: FcFontFace
  fontSize: FcPercentageSize
  fontColor: FcColor
  position: FcPosition
  fontOutlineColor?: FcColor
  fontOutlineSize?: FcPercentageSize
  gradient?: FcColorGradient | null
  rotation?: number
  shape?: FcShape
}

export class FcText implements FcText {
  constructor(
    text: string,
    font: FcFontFace,
    fontSize: FcPercentageSize,
    fontColor: FcColor,
    position: FcPosition,
    fontOutlineColor?: FcColor,
    fontOutlineSize?: FcPercentageSize,
    gradient?: FcColorGradient | null,
    rotation?: number,
    shape?: FcShape
  ) {
    this.text = text
    this.font = font
    this.fontSize = fontSize
    this.fontColor = fontColor
    this.position = position
    this.fontOutlineColor = fontOutlineColor
    this.fontOutlineSize = fontOutlineSize
    this.gradient = gradient
    this.rotation = rotation
    this.shape = shape
  }
}

export enum FcErrorCorrectionLevel {
  L = 1,
  M = 0,
  Q = 3,
  H = 2
}

export class FcAngleRange {
  start: number
  end: number
  constructor(start: number, end: number) {
    this.start = start
    this.end = end
  }
}

export class FcReservedArea {
  boxes = new Array<FcArea>()
  polygons = new Array<FcPolygon>()
  minX = 10000
  maxX = 0
  minY = 10000
  maxY = 0
  angles = new Array<FcAngleRange>()

  addBoxArea(box: FcArea): void {
    this.boxes.push(box)
    this.checkBoxBound(box)
  }

  addBox(
    x: number,
    y: number,
    width: number,
    height: number,
    pixels?: number[][],
    pixelPadding?: number,
    rotate?: number
  ): void {
    const box = new FcArea(x, y, width, height, pixels, pixelPadding, rotate)
    this.addBoxArea(box)
  }

  checkBoxBound(box: FcArea): void {
    if (box.x < this.minX) {
      this.minX = box.x
    }
    if (box.y < this.minY) {
      this.minY = box.y
    }
    const x2 = box.x + box.width
    if (x2 > this.maxX) {
      this.maxX = x2
    }
    const y2 = box.y + box.height
    if (y2 > this.maxY) {
      this.maxY = y2
    }
  }

  addPolygon(p: FcPolygon): void {
    this.polygons.push(p)
    this.checkBoxBound(p.getBoundingBox())
  }

  addAngle(start: number, end: number): void {
    this.angles.push(new FcAngleRange(start, end))
  }

  resetMinMax(): void {
    this.minX = 10000
    this.maxX = 0
    this.minY = 10000
    this.maxY = 0
  }

  inBox(x: number, y: number, width: number, height: number): boolean {
    let len = this.boxes.length
    const x2 = x + width
    const y2 = y + height
    for (let i = 0; i < len; i++) {
      if (this.boxes[i].inBox(x, y, x2, y2)) {
        return true
      }
    }
    len = this.polygons.length
    for (let i = 0; i < len; i++) {
      if (this.polygons[i].inBox(x, y, x2, y2)) {
        return true
      }
    }
    return false
  }
}

export enum FcGradientType {
  LINEAR_RIGHT = 0,
  LINEAR_LEFT = 1,
  LINEAR_TOP = 2,
  LINEAR_BOTTOM = 3,
  RADIAL = 5,
  SHARED_WITH_DATA = 6
}

export enum FcColorGradientType {
  LINEAR_RIGHT = 0,
  LINEAR_LEFT = 1,
  LINEAR_TOP = 2,
  LINEAR_BOTTOM = 3,
  LINEAR_TOP_LEFT = 4,
  LINEAR_TOP_RIGHT = 5,
  LINEAR_BOTTOM_LEFT = 6,
  LINEAR_BOTTOM_RIGHT = 7,
  RADIAL = 8,
  SHARED_WITH_DATA = 9
}

export enum FcColorGradientSpread {
  NONE = 0,
  REFLECT = 1,
  REPEAT = 2,
  PAD = 3
}

export class FcGradient {
  type: FcGradientType
  startPercent: number
  endPercent: number
  numberSizes: number

  constructor(type: FcGradientType, startPercent: number, endPercent: number, numberSizes: number) {
    this.type = type
    this.startPercent = startPercent
    this.endPercent = endPercent
    this.numberSizes = numberSizes
  }
}

export class FcColorGradient {
  type: FcColorGradientType
  startColor: FcColor
  endColor: FcColor
  startPercent: number
  endPercent: number
  color2?: FcColor
  color3?: FcColor
  color2Percent?: number
  color3Percent?: number
  spread?: FcColorGradientSpread
  fx?: number
  fy?: number
  cx?: number
  cy?: number
  r?: number

  constructor(
    type: FcColorGradientType,
    startPercent: number,
    endPercent: number,
    startColor: string,
    endColor: string,
    color2?: FcColor,
    color2Percent?: number,
    color3?: FcColor,
    color3Percent?: number,
    spread?: FcColorGradientSpread,
    fx?: number,
    fy?: number,
    cx?: number,
    cy?: number,
    r?: number
  ) {
    this.type = type
    this.startPercent = startPercent
    this.endPercent = endPercent
    this.startColor = startColor
    this.endColor = endColor
    this.color2 = color2
    this.color3 = color3
    this.color2Percent = color2Percent
    this.color3Percent = color3Percent
    this.spread = spread
    this.fx = fx
    this.fy = fy
    this.cx = cx
    this.cy = cy
    this.r = r
  }
}

export interface FcCenterText {
  text: FcText[]
  paddingVertical: number
  paddingHorizontal: number
  heightOffset?: number
  blend?: boolean
  blendErase?: boolean
  blendModuleSize?: number
  blendModuleShape?: FcShape
  blendModuleColorFg?: FcColor
  blendModuleColorBg?: FcColor
}
