import { Cohort } from '@dtx-company/true-common/src/types/cohorts'
import { IthacaJwt } from '@dtx-company/ithaca-sdk/src'
import { RootState } from '../../redux/types'
import { cohorts } from '@dtx-company/true-common/src/constants/cohorts'

export * from './jwtHasOperation'

export function getCurrentJwtFromStore(storeState: RootState): IthacaJwt | null {
  return getFronteggJwtFromStore(storeState)
}

function getFronteggJwtFromStore(storeState: RootState): IthacaJwt | null {
  const currentUserJwt = storeState.appReducer.userReducer.currentUserData
  const impersonatedUserJwt = storeState.appReducer.userReducer.impersonatedUserData
  return impersonatedUserJwt ?? currentUserJwt
}

export function getCohort(jwt: IthacaJwt | null): Cohort | null {
  const cohortName = jwt?.cohorts?.[0] ?? ''
  const cohort = cohorts.find(c => c.name === cohortName) ?? null
  return cohort
}
