import { Dispatch } from '@reduxjs/toolkit'
import { IS_INTERNAL_ADMIN } from '@dtx-company/true-common/src/constants/env'
import {
  IthacaJwt,
  buildJwtFromFronteggAccessToken,
  getCurrentImpersonationAccessTokenCookie
} from '@dtx-company/ithaca-sdk/src'
import {
  clearImpersonatedUserData,
  setImpersonatedUserData
} from '@dtx-company/inter-app/src/redux/slices/user-slice'
import { useAuthState } from '../useAuthState'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import useSWR from 'swr'

export function useListenForImpersonationChanges(): void {
  const { isAuthChecked, isAuthenticated, isImpersonating, token } = useAuthState()
  const dispatch = useDispatch()
  const { reload } = useRouter()

  const [impersonatedSincePageLoad, setImpersonatedSincePageLoad] = useState(false)

  //track if we've impersonated since loading the app.
  useEffect(() => {
    setImpersonatedSincePageLoad(isImpersonating)
  }, [isImpersonating])

  /*
   Because useSWR fires when page visibility / focus is changed, this hook takes care of refreshing
   internal admin + app's impersonation state when a user re-focuses the page or opens the tab again.
  */
  useSWR(
    [
      isAuthChecked,
      isAuthenticated,
      dispatch,
      reload,
      token,
      isImpersonating,
      impersonatedSincePageLoad
    ],
    impersonationTokenFetcher
  )
}

type ImpersonationTokenFetcherArgs = [
  isAuthChecked: boolean,
  isAuthenticated: boolean,
  dispatch: Dispatch,
  reload: () => void,
  token: string | undefined,
  isImpersonating: boolean,
  impersonatedSincePageLoad: boolean
]

export async function impersonationTokenFetcher([
  isAuthChecked,
  isAuthenticated,
  dispatch,
  reload,
  token,
  isImpersonating,
  impersonatedSincePageLoad
]: ImpersonationTokenFetcherArgs): Promise<void> {
  if (isAuthChecked && isAuthenticated) {
    //check current value of impersonation cookie
    let impersonationJwt: undefined | IthacaJwt
    const impersonationAccessToken = await getCurrentImpersonationAccessTokenCookie()
    if (impersonationAccessToken === token || (!impersonationAccessToken && !isImpersonating)) {
      // FE state is up to date, no need to refresh
      return
    }

    if (impersonationAccessToken) {
      impersonationJwt = await buildJwtFromFronteggAccessToken(impersonationAccessToken)
    }

    //sync redux with current value of impersonation cookie
    if (impersonationJwt) {
      dispatch(setImpersonatedUserData({ data: impersonationJwt }))
    } else {
      dispatch(clearImpersonatedUserData())
      /*
        If we were previously impersonating, and impersonation was ended from another tab, we need to now refresh the app.
      */
      if (impersonatedSincePageLoad && !IS_INTERNAL_ADMIN) {
        reload()
      }
    }
  }
}
