// TODO: could probably give this a better name and structure, but simply moving it
// into the new package for now to pass type checks for DD_LOGS
export function handleDatadogLogger(userUID?: string): void {
  let uid = ''
  if (userUID) uid = userUID
  window.DD_LOGS &&
    window.DD_LOGS.onReady(() => {
      if (!window.DD_LOGS?.setGlobalContextProperty) {
        window.DD_LOGS?.logger.error('setGlobalContextProperty is undefined on window.DD_LOGS', {})
        return
      }
      window.DD_LOGS?.setGlobalContextProperty('uid', uid)
    })
}
