import { rootAnalyticsReducer as analyticsReducer } from './analytics'
import { codeCombinedReducer as codeReducer } from './code'
import { combineReducers } from '@reduxjs/toolkit'
import { flowcodeApi } from '../slices/flowcodeApiSlice/init'
import { flowpageApi } from '../slices/flowpageApiSlice/empty'
import { flowteamsCombinedReducer as flowteamsReducer } from './flowteams'
import { formsCombinedReducers as formsReducer } from '@dtx-company/app/shared/forms/src/api/redux/forms'
import { rootPageReducer as pageReducer } from './page'
import { revenueCombinedReducer as revenueReducer } from './revenue'
import authFormReducer from '../../redux/slices/auth-form'
import configReducer from '../slices/config-slice'
import cookieReducer from '../slices/cookie'
import currentSessionReducer from '../slices/current-session-slice'
import notificationsReducer from '../slices/notifications/notificationsSlice'
import optimizeTestReducer from '../slices/optimize-test-slice'
import userReducer from '../slices/user-slice'

export const appReducer = combineReducers({
  currentSessionReducer,
  cookieReducer,
  configReducer,
  optimizeTestReducer,
  userReducer,
  authFormReducer
})

// eslint-disable-next-line  @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export function createReducer(injectedReducers = {}) {
  return combineReducers({
    appReducer,
    pageReducer,
    codeReducer,
    analyticsReducer,
    flowteamsReducer,
    formsReducer,
    revenueReducer, // revenueIncreaser :3
    notificationsReducer,
    flowpageApi: flowpageApi.reducer,
    flowcodeApi: flowcodeApi.reducer,
    ...injectedReducers
  })
}
