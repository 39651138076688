import { FileNameActionType, SocialFilesState } from '@dtx-company/inter-app/src/types/flowpage'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export const initialState: SocialFilesState = {
  imageFileName: '',
  pdfFileName: ''
}

const onboardingSocials = createSlice({
  name: 'onboardingSocials',
  initialState,
  reducers: {
    setFileName: (state, action: PayloadAction<FileNameActionType>) => {
      switch (action.payload.type) {
        case 'image':
          state.imageFileName = action.payload.fileName
          break
        case 'pdf':
          state.pdfFileName = action.payload.fileName
          break
        default:
          state.imageFileName = action.payload.fileName
          state.pdfFileName = action.payload.fileName
      }
    }
  }
})

export const { setFileName } = onboardingSocials.actions

export default onboardingSocials.reducer
