import { MaterializedFeature } from '@dtx-company/connect-codegen/src/gen/plans/v1/plans_pb'
import { ReactNode, createContext } from 'react'

export interface AtlasProviderValue {
  usage: {
    [key: string]: MaterializedFeature
  }
}

export interface AtlasProviderProps {
  children: ReactNode
}

export const AtlasContext = createContext<AtlasProviderValue | undefined>(undefined)
