export function dataURItoBlob(dataURI: string): Blob {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)

  // create a view into the buffer
  const ia = new Uint8Array(ab)

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString })
  return blob
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const dataUrl = reader.result
      resolve(dataUrl as string)
    }
    reader.onerror = error => {
      reject(error)
    }
    reader.readAsDataURL(blob)
  })
}

export function isBase64File(s: string): boolean {
  return Boolean(s?.startsWith('data'))
}

export function isObjectURL(s: string): boolean {
  return Boolean(s?.startsWith('blob:'))
}

export function isFileString(s: string | undefined | null): boolean {
  if (!s) {
    return false
  }
  return isBase64File(s) || isObjectURL(s)
}

export function canvasToBlob(
  canvas: HTMLCanvasElement,
  mimeType?: string,
  qualityArgument?: number
): Promise<Blob> {
  return new Promise(resolve => {
    canvas.toBlob(
      blob => {
        blob && resolve(blob)
      },
      mimeType,
      qualityArgument
    )
  })
}
