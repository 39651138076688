export enum SortDirections {
  ASC = 'asc',
  DSC = 'dsc'
}
export enum PresetPages {
  Landing = 'LANDING',
  SignIn = 'SIGN_IN',
  SignUp = 'SIGN_UP',
  ActivatedSuccess = 'ACTIVATED_SUCCESS',
  ActivationError = 'ACTIVATION_ERROR'
}

export enum PresetFpPages {
  Landing = 'LANDING',
  SignIn = 'SIGN_IN',
  SignUp = 'SIGN_UP',
  PagePreview = 'PREVIEW',
  ActivatedSuccess = 'ACTIVATED_SUCCESS',
  ActivationError = 'ACTIVATION_ERROR'
}

export enum CollectPageTypes {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}
