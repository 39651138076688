import { AnyAction, CombinedState, Reducer, combineReducers } from '@reduxjs/toolkit'
import billingTabDialogReducer, {
  BillingTabDialogState
} from '@dtx-company/inter-app/src/redux/slices/billingTabDialogSlice'
import lockedFeatureModalReducer, {
  LockedFeatureModalState
} from '../slices/lockedFeatureModalSlice'
import lockedFeaturesReducer, { LockedFeaturesState } from '../slices/lockedFeaturesSlice'

export const revenueCombinedReducer: Reducer<
  CombinedState<{
    billingTabDialog: BillingTabDialogState
    lockedFeatures: LockedFeaturesState
    lockedFeatureModal: LockedFeatureModalState
  }>,
  AnyAction
> = combineReducers({
  billingTabDialog: billingTabDialogReducer,
  lockedFeatures: lockedFeaturesReducer,
  lockedFeatureModal: lockedFeatureModalReducer
})
