import { DeleteTempAssetEvent, EmptyContext } from '../locgMachine.types'
import { LOCG_TEMP_ASSETS_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { logger } from '@dtx-company/logger/src'

export const deleteTempAsset = async (
  _context: EmptyContext,
  { assetType, authCacheId }: DeleteTempAssetEvent
): Promise<false | undefined> => {
  try {
    const searchParams = new URLSearchParams({ assetType })

    const resp = await fetch(`${LOCG_TEMP_ASSETS_ENDPOINT}/${authCacheId}?${searchParams}`, {
      method: 'DELETE'
    })
    if (!resp.ok) {
      logger.logFetchError(resp, { team: 'templates', technicalArea: 'locg' })
      return false
    }
  } catch (err) {
    logger.logError(err, { team: 'templates', technicalArea: 'locg' })
    return false
  }
}
