import { FlowcodeColorOptionType } from '../../types/flowcode.types'
import {
  FlowpageGeneratorPreviewDestinationPayload,
  FlowpageGeneratorPreviewFilePayload,
  FlowpageGeneratorPreviewStateType
} from '../../types/flowpageGeneratorPreview.types'
import { MaskType, theme } from '@dtx-company/shared-components/src'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const { builder } = theme.colors

const flowpageGeneratorPreviewSlice = createSlice({
  name: 'flowpageGeneratorPreview',
  initialState: {
    colorOption: {
      color: builder.pumpkinLight,
      darkColor: builder.pumpkinDark,
      defaultOnly: true,
      id: 'pumpkin',
      index: 30,
      label: 'Pumpkin'
    },
    destinations: {
      call: '',
      email: '',
      website: '',
      instagram: '',
      sms: '',
      youtube: '',
      facebook: '',
      tiktok: '',
      snapchat: '',
      twitter: '',
      linkedin: '',
      spotify: '',
      venmo: '',
      cashapp: ''
    },
    files: {
      file: null
    },
    profileImage: '',
    title: '',
    slug: '',
    maskType: MaskType.SQUARE
  } as FlowpageGeneratorPreviewStateType,
  reducers: {
    setColor: (state, action: PayloadAction<FlowcodeColorOptionType>) => {
      state.colorOption = action.payload
    },
    setLink: (state, action: PayloadAction<FlowpageGeneratorPreviewDestinationPayload>) => {
      const { key, payload } = action.payload
      state.destinations[key] = payload
    },
    setFileLink: (state, action: PayloadAction<FlowpageGeneratorPreviewFilePayload>) => {
      const { key, payload } = action.payload
      state.files[key] = payload
    },
    setImage: (state, action: PayloadAction<string>) => {
      state.profileImage = action.payload
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload
    },
    setSlug: (state, action: PayloadAction<string>) => {
      state.slug = action.payload
    },
    setMaskType: (state, action: PayloadAction<MaskType>) => {
      state.maskType = action.payload
    }
  }
})

export const { setColor, setLink, setFileLink, setImage, setTitle, setSlug, setMaskType } =
  flowpageGeneratorPreviewSlice.actions

export default flowpageGeneratorPreviewSlice.reducer
