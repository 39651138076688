import { IncomingMessage, OutgoingHttpHeader, OutgoingHttpHeaders, ServerResponse } from 'http'
import { writeHTMLLinkHeader } from './writeHtmlLinkHeader'

type WriteParams1 = [
  statusCode: number,
  statusMessage?: string,
  headers?: OutgoingHttpHeaders | OutgoingHttpHeader[]
]
type WriteParams2 = [statusCode: number, headers?: OutgoingHttpHeaders | OutgoingHttpHeader[]]
type WriteParams = WriteParams1 | WriteParams2
type Params = Parameters<ServerResponse['writeHead']>

const writeHeader = (req: IncomingMessage | undefined, res: ServerResponse | undefined): void => {
  if (res && req) {
    const originalWriteHead = res.writeHead
    res.writeHead = (...args: WriteParams): ServerResponse => {
      const [statusCode] = args
      writeHTMLLinkHeader(statusCode, req, res)
      return originalWriteHead.apply(res, args as Params)
    }
  }
}

export async function writeLinkHeader(
  req: IncomingMessage | undefined,
  res: ServerResponse | undefined
): Promise<void> {
  writeHeader(req, res)
}
//
