import { LoggedOutGeneratorSection } from '@app/code/src/components/LoggedOutCodeGeneratorV2/LoggedOutCodeGenerator.types'
import { TRANSITION_DURATION } from '@app/code/src/components/LoggedOutCodeGeneratorV2/LoggedOutCodeGenerator.constants'
import { createMachine, sendParent } from 'xstate'

export const authFormMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QEECuAXAFgMQPYCcBbAOkgEt0yA7KAYgGUBVAIQFkBJAFQG0AGAXUSgADrlgUyuKkJAAPRAFoATAEYAnMQAsAdk1K1K3ZpVKArGoBsAGhABPRJt4ri2iwA5tbt495LXp0wBfQJs0LDwiYlhUACNCCkoaBkYAYRSAUXp6PkEkEFFxSikZeQRTJWIlAGYqtTVterc1TQtNKs0bewQq1UrfXlNa0zcTbV5tYNCMHAISaLiE6jpsZHYAGUYAJXScmQKJYrzS5RU3LSqxiwtDJU0DJTdOxGrnW4adbRU9JV4LKuCQiAqLgIHAZGEZkQ9mIDtIjooLmcal8-JYeoNHnZEF8qi4dM1TMYqsM7pMQBCIiRyIkoNDCpI4aBjjVeMRkXoGn8zFVMV12hVTNpPG5zG4foTNEFARTZlFYvF0DS6bCSooRi4qtdTK0mpKDLzEO1TMRxsLReLNJKyTLIuIoFRIOxGfkYUVnaV1GdeGpBk5Oapbgbum1iG5foZfNpBpr6gDAkA */
    tsTypes: {} as import('./authFormMachine.typegen').Typegen0,
    schema: {
      events: {} as {
        type: 'SUBMIT' | 'SUCCESS' | 'FAILURE' | 'BACK'
      }
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
    id: 'AuthForm',
    initial: 'editing',
    states: {
      editing: {
        entry: ['focusFirstInput'],
        on: {
          SUBMIT: {
            target: 'submitting',
            actions: ['onSubmit']
          },
          BACK: {
            actions: sendParent({ type: LoggedOutGeneratorSection.CodeDesign })
          }
        }
      },

      submitting: {
        on: {
          SUCCESS: {
            target: 'signedIn',
            actions: ['onSuccess']
          },
          FAILURE: {
            target: 'editing',
            actions: ['onFailure']
          }
        }
      },

      signedIn: {
        type: 'final'
      }
    }
  },

  {
    actions: {
      focusFirstInput: () => {
        setTimeout(() => {
          document.querySelector<HTMLInputElement>('input[name="email"]')?.focus()
        }, TRANSITION_DURATION)
      },
      // TODO: Implement these actions - CP
      onSubmit: () => {
        console.info('authFormMachine - onSubmit')
      },
      onSuccess: () => {
        console.info('authFormMachine - onSuccess')
      },
      onFailure: () => {
        console.info('authFormMachine - onFailure')
      }
    },
    services: {},
    guards: {},
    delays: {}
  }
)
