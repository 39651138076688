import { FlowError, ServerSideErrorProps } from '@dtx-company/logger'

export type FetchRequest = Omit<RequestInit, 'method'>
export type RequestMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export interface FlowResponse<T> {
  data: T
  status: number
  statusText: string
  redirected: boolean
  ok: boolean
  type: string
  url: string
}

interface FetchErrorProps extends ServerSideErrorProps {
  status: number
  statusText: string
  data: any
}

export class FetchSideError extends FlowError {
  status: number
  statusText: string
  data: any

  constructor(props: FetchErrorProps) {
    super(props)
    this.message = props.message
    this.name = props.name
    this.type = props.type
    this.status = props.status
    this.statusText = props.statusText
    this.data = props.data

    Object.setPrototypeOf(this, FetchSideError.prototype)
  }
}
