import { OAuthQueryParams, OAuthState } from './types'
import { Routes } from '../../constants/routes'
import { useMemo } from 'react'
import { useRouter } from 'next/router'

export function useOAuthQueryParams(): OAuthQueryParams {
  const { query, pathname } = useRouter()
  const isOnAuthRoute = pathname === Routes.FRONTEGG_SOCIAL_REDIRECT
  const code = isOnAuthRoute ? (query.code as string | undefined) : undefined
  const state = isOnAuthRoute ? query.state?.toString() : undefined
  return useMemo(() => ({ state, code }), [state, code])
}

export function useOAuthState(): OAuthState | undefined {
  const stateParam = useOAuthQueryParams().state
  return useMemo(() => {
    try {
      if (stateParam) {
        //Need to decode the state param because it is encoded in the url
        const decodedParam = decodeURIComponent(stateParam)
        return JSON.parse(decodedParam) as OAuthState
      }
    } catch (e) {
      return undefined
    }
    return undefined
  }, [stateParam])
}
