import {
  AssetPermission,
  AssetPermissionsInReduxStore
} from '@dtx-company/inter-app/src/components/FlowTeams/types/asset-permissions.types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type AssetPermissionsSlice = Record<string, AssetPermissionsInReduxStore>

const initialState: AssetPermissionsSlice = {}

export const assetPermissionsSlice = createSlice({
  name: 'assetPermissions',
  initialState,
  reducers: {
    initializeAssetPermissions: (
      state: AssetPermissionsSlice,
      action: PayloadAction<{ assetId: string; currentPermissions: AssetPermission[] }>
    ) => {
      const { assetId, currentPermissions } = action.payload
      state[assetId] = { currentPermissions, draftPermissions: currentPermissions }
    },
    deleteAssetPermissions: (state, action: PayloadAction<{ assetId: string }>) => {
      const { assetId } = action.payload
      if (!state[assetId]) return
      delete state[assetId]
    },
    resetDraftPermissions: (state, action: PayloadAction<{ assetId: string }>) => {
      const { assetId } = action.payload
      if (!state[assetId]) return
      state[assetId].draftPermissions = state[assetId].currentPermissions
    },
    addDraftPermissionToAsset: (
      state: AssetPermissionsSlice,
      action: PayloadAction<{ assetId: string; newDraftPermission: AssetPermission }>
    ) => {
      const { assetId, newDraftPermission } = action.payload
      if (!state[assetId]) return
      state[assetId].draftPermissions = [...state[assetId].draftPermissions, newDraftPermission]
    },
    removeDraftPermissionFromAsset: (
      state: AssetPermissionsSlice,
      action: PayloadAction<{ assetId: string; entityId: string }>
    ) => {
      const { assetId, entityId } = action.payload
      if (!state[assetId]) return
      state[assetId].draftPermissions = state[assetId].draftPermissions.filter(
        p => p.entityId !== entityId
      )
    },
    updateDraftPermissionOnAsset: (
      state: AssetPermissionsSlice,
      action: PayloadAction<{
        assetId: string
        entityId: string
        updatedPermission: AssetPermission
      }>
    ) => {
      const { assetId, entityId, updatedPermission } = action.payload
      if (!state[assetId]) return
      state[assetId].draftPermissions = state[assetId].draftPermissions.map(p =>
        p.entityId === entityId ? updatedPermission : p
      )
    },
    setCurrentPermissionsFromDraftPermissions: (
      state: AssetPermissionsSlice,
      action: PayloadAction<{ assetId: string }>
    ) => {
      const { assetId } = action.payload
      state[assetId].currentPermissions = state[assetId].draftPermissions
    }
  }
})

export const {
  initializeAssetPermissions,
  addDraftPermissionToAsset,
  resetDraftPermissions,
  removeDraftPermissionFromAsset,
  updateDraftPermissionOnAsset,
  setCurrentPermissionsFromDraftPermissions,
  deleteAssetPermissions
} = assetPermissionsSlice.actions

export default assetPermissionsSlice.reducer
