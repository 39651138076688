import { SVGProps, memo } from 'react'

function GoogleDriveSvg({
  fill = 'currentcolor',
  stroke = '#fff',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 27 22" {...rest}>
      <path
        fill={color || fill}
        stroke={stroke}
        strokeWidth={0.3}
        d="M8.44.548h0V.546L8.68.15h8.954l.238.396.001.002 8 13h0l.267.427-.24.453-3.998 6.998h0l-.239.424H4.65l-.238-.424h0l-4-6.998-.239-.453.266-.427h0l8-13zm2.497 1.302h-.269l.141.229 6.781 11 .044.071h6.01l-.141-.229-6.782-11-.044-.071h-5.74zm-1.653.978l-.128-.208-.128.207-6.844 11.094-.046.076.044.077 2.874 5.031.125.22.133-.215 6.939-11.281.048-.079-.048-.078-2.97-4.844zm4.156 6.75l-.128-.208-.128.207-2.06 3.344-.142.229h4.663l-.141-.229-2.064-3.344zm-3.41 5.272h-.084l-.044.071-3.094 5-.141.229h13.981l.043-.075 2.875-5 .13-.225H10.03z"
      />
    </svg>
  )
}

export const GoogleDrive = memo(GoogleDriveSvg)

function GoogleDrive2SVG(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__prefix__clip_google)" fill={props.fill || '#111'}>
        <path d="M2.033 13.704l.706 1.22c.145.25.354.458.605.604l2.52-4.36H.824c0 .29.076.574.22.825l.99 1.711zM8.823 6.034l-2.52-4.36c-.25.146-.458.354-.604.605l-4.655 8.063c-.144.25-.22.535-.22.824h5.04l2.96-5.132zM14.303 15.528c.251-.146.46-.354.605-.605l.293-.504 1.403-2.43c.143-.251.22-.535.22-.825h-5.04l1.071 2.107 1.448 2.257z" />
        <path d="M8.824 6.034l2.52-4.36a1.612 1.612 0 00-.825-.221H7.13a1.7 1.7 0 00-.825.22l2.52 4.361zM11.784 11.166h-5.92l-2.52 4.36c.25.147.535.223.824.222h9.31c.29-.002.574-.078.826-.22l-2.52-4.362z" />
        <path d="M14.276 6.313l-2.327-4.035a1.663 1.663 0 00-.605-.605l-2.52 4.36 2.96 5.132h5.03c0-.29-.075-.574-.22-.825l-2.318-4.027z" />
      </g>
      <defs>
        <clipPath id="prefix__prefix__clip_google">
          <path fill="#fff" transform="translate(.5 .5)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const GoogleDrive2 = memo(GoogleDrive2SVG)
