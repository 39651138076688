import {
  BulkActionKey,
  CodeManagementStateType,
  CreateFlowcodeBulkModalActionType,
  DownloadFolderZipResponseModalActionType,
  FilterDirection,
  FlowcodeFolderType,
  FlowcodeNodeType,
  ManagementFilter,
  PermissionsModalActionType,
  SelectedFlowcodeView,
  SetFlowcodeFilterActionType,
  ToggleFlowcodeModalActionType
} from '../../types/flowcode'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState: CodeManagementStateType = {
  transferFlowcodeModal: {
    isOpen: false,
    batchId: ''
  },
  emailFlowcodeModal: {
    batchId: '',
    isOpen: false
  },
  downloadFlowcodeModal: {
    isOpen: false,
    flowcode: undefined,
    batchId: '',
    nickname: ''
  },
  displayMobileActionsModal: false,
  displayCreateFolderModal: false,
  // DF - maybe transition to simple asset id and ssset tyep vs having a distinct id for each asset
  permissionsModal: {
    isOpen: false,
    batchId: '',
    directoryId: '',
    isBatch: true,
    isPage: false,
    pageId: '',
    pageTemplateId: '',
    isPageTemplate: false
  },
  selectedFlowcodeView: SelectedFlowcodeView.LIST,
  highlightFilter: {
    name: ManagementFilter.TIMESTAMP,
    direction: FilterDirection.DSC
  },
  flowcodesFilter: {
    name: ManagementFilter.NAME,
    direction: FilterDirection.ASC,
    contains: ''
  },
  deleteFlowcodeModal: {
    id: '',
    isOpen: false,
    isBulkActions: false
  },
  removeFlowcodeModal: {
    id: '',
    isOpen: false,
    isFolder: false,
    entityId: null
  },
  createFlowcodeBulkModal: {
    isOpen: false,
    processState: null,
    folderName: '',
    flowcodeCount: 0,
    errorMessages: [],
    badResponse: false,
    waitedForCodes: false
  },
  hasDownloadJustOccurred: false,
  isDownloadInProgress: false,
  openDrawerId: 'edit-code',
  hasFolderLinksDownloadJustOccurred: false,
  isFolderLinksDownloadInProgress: false,
  downloadFolderZipResponseModal: {
    isOpen: false,
    processState: null,
    folderName: ''
  },
  bulkActions: {
    previouslySelectedId: '',
    isShiftKeyPressed: false,
    selectAllButtonChecked: false,
    batchIds: {
      codes: []
    },
    folderIds: {
      codes: []
    }
  },
  confirmMoveFlowcodeModal: {
    isOpen: false,
    folderId: '',
    batchId: ''
  },
  isPerformingBulkActionsOperation: false,
  // api data
  flowcodes: [],
  folders: []
}

// slice reducers

const codeManagementSlice = createSlice({
  name: 'codeManagement',
  initialState,
  reducers: {
    toggleDeleteFlowcodeModal: (
      state,
      action: PayloadAction<{ id: string; isBulkActions?: boolean }>
    ) => {
      state.deleteFlowcodeModal.isOpen = !state.deleteFlowcodeModal.isOpen
      state.deleteFlowcodeModal.id = action.payload.id
      state.deleteFlowcodeModal.isBulkActions = action.payload.isBulkActions || false
    },
    toggleRemoveFlowcodeModal: (
      state,
      action: PayloadAction<{ id: string; isFolder?: boolean; entityId?: string }>
    ) => {
      state.removeFlowcodeModal.isOpen = !state.removeFlowcodeModal.isOpen
      state.removeFlowcodeModal.id = action.payload.id
      state.removeFlowcodeModal.isFolder = action.payload.isFolder || false
      state.removeFlowcodeModal.entityId = action.payload.entityId || null
    },
    toggleEmailFlowcodeModal: (state, action: PayloadAction<{ batchId: string }>) => {
      state.emailFlowcodeModal.isOpen = !state.emailFlowcodeModal.isOpen
      state.emailFlowcodeModal.batchId = action.payload.batchId
    },
    toggleDownloadFlowcodeModal: (state, action: ToggleFlowcodeModalActionType) => {
      state.downloadFlowcodeModal.isOpen = !state.downloadFlowcodeModal.isOpen
      state.downloadFlowcodeModal.batchId = action.payload.batchId || ''
      state.downloadFlowcodeModal.nickname = action.payload.nickname || ''
    },
    toggleTransferFlowcodeModal: (state, action: ToggleFlowcodeModalActionType) => {
      state.transferFlowcodeModal.batchId = action.payload.batchId || ''
      state.transferFlowcodeModal.isOpen = !state.transferFlowcodeModal.isOpen
    },
    toggleDisplayCreateFolderModal: state => {
      state.displayCreateFolderModal = !state.displayCreateFolderModal
    },
    toggleMobileActionsModal: state => {
      state.displayMobileActionsModal = !state.displayMobileActionsModal
    },
    togglePermissionsModal: (state, action: PermissionsModalActionType) => {
      state.permissionsModal.isOpen = !state.permissionsModal.isOpen
      state.permissionsModal.batchId = action.payload.batchId || ''
      state.permissionsModal.directoryId = action.payload.directoryId || ''
      state.permissionsModal.isBatch = !!action.payload.isBatch
      state.permissionsModal.isPage = !!action.payload.isPage
      state.permissionsModal.pageId = action.payload.pageId || ''
      state.permissionsModal.pageTemplateId = action.payload.pageTemplateId || ''
      state.permissionsModal.isPageTemplate = Boolean(action.payload.isPageTemplate)
    },
    setSelectedFlowcodeView: (state, action: PayloadAction<SelectedFlowcodeView>) => {
      state.selectedFlowcodeView = action.payload
    },
    setFlowcodesFilter: (state, action: SetFlowcodeFilterActionType) => {
      state.flowcodesFilter.name = action.payload.name || ManagementFilter.NAME
      state.flowcodesFilter.direction =
        action.payload.direction === 'asc' || !action.payload.direction
          ? FilterDirection.ASC
          : FilterDirection.DSC
      state.flowcodesFilter.contains = action.payload.contains || ''
    },
    setHighlightedFilter: (state, action: PayloadAction<{ name?: ManagementFilter }>) => {
      state.highlightFilter.name = action.payload.name || ManagementFilter.TIMESTAMP
    },
    setDownloadJustOccurred: state => {
      state.hasDownloadJustOccurred = true
    },
    resetDownloadJustOccurred: state => {
      state.hasDownloadJustOccurred = false
    },
    toggleIsDownloadInProgress: state => {
      state.isDownloadInProgress = !state.isDownloadInProgress
    },
    toggleCreateFlowcodeBulkModal: (state, action: PayloadAction<{ isOpen?: boolean }>) => {
      state.createFlowcodeBulkModal.isOpen = action.payload.isOpen || false
    },
    setCreateFlowcodeBulkModal: (state, action: CreateFlowcodeBulkModalActionType) => {
      state.createFlowcodeBulkModal.flowcodeCount = action.payload.flowcodeCount || 0
      state.createFlowcodeBulkModal.folderName = action.payload.folderName || ''
      state.createFlowcodeBulkModal.processState = action.payload.processState || null
      state.createFlowcodeBulkModal.errorMessages = action.payload.errorMessages || []
      state.createFlowcodeBulkModal.badResponse = action.payload.badResponse || false
      state.createFlowcodeBulkModal.waitedForCodes = action.payload.waitedForCodes || false
    },
    setOpenDrawerId: (state, action: PayloadAction<{ id?: string }>) => {
      state.openDrawerId = action.payload.id
    },
    setFolderLinksDownloadJustOccurred: state => {
      state.hasFolderLinksDownloadJustOccurred = true
    },
    resetFolderLinksDownloadJustOccurred: state => {
      state.hasFolderLinksDownloadJustOccurred = false
    },
    toggleFolderLinksIsDownloadInProgress: state => {
      state.isFolderLinksDownloadInProgress = !state.isFolderLinksDownloadInProgress
    },
    toggleDownloadFolderZipResponseModal: (state, action: PayloadAction<{ isOpen?: boolean }>) => {
      state.downloadFolderZipResponseModal.isOpen = action.payload.isOpen || false
    },
    setDownloadFolderZipResponseModal: (
      state,
      action: DownloadFolderZipResponseModalActionType
    ) => {
      state.downloadFolderZipResponseModal.processState = action.payload.processState || null
      state.downloadFolderZipResponseModal.folderName = action.payload.folderName || ''
    },
    setBulkActionsBatchIds: (
      state,
      action: PayloadAction<{ ids: string[]; key: BulkActionKey }>
    ) => {
      const key = action.payload.key
      state.bulkActions.batchIds[key] = action.payload.ids
    },
    setBulkActionsFolderIds: (
      state,
      action: PayloadAction<{ ids: string[]; key: BulkActionKey }>
    ) => {
      const key = action.payload.key
      state.bulkActions.folderIds[key] = action.payload.ids
    },
    resetBulkActionsIds: (state, action: PayloadAction<BulkActionKey>) => {
      const key = action.payload
      state.bulkActions.folderIds[key] = []
      state.bulkActions.batchIds[key] = []
      state.bulkActions.selectAllButtonChecked = false
    },
    toggleIsPerformingBulkActionsOperation: state => {
      state.isPerformingBulkActionsOperation = !state.isPerformingBulkActionsOperation
    },
    setIsShiftKeyPressed: (state, action: PayloadAction<boolean>) => {
      state.bulkActions.isShiftKeyPressed = action.payload
    },
    setPreviouslySelectedId: (state, action: PayloadAction<string>) => {
      state.bulkActions.previouslySelectedId = action.payload
    },
    setSelectAllButtonChecked: (state, action: PayloadAction<boolean>) => {
      state.bulkActions.selectAllButtonChecked = action.payload
    },
    setFlowcodes(state, action: PayloadAction<FlowcodeNodeType[] | undefined>) {
      state.flowcodes = action.payload ?? []
    },
    setFolders(state, action: PayloadAction<FlowcodeFolderType[] | undefined>) {
      state.folders = action.payload ?? []
    },
    toggleConfirmMoveFlowcodeModal: (
      state,
      action: PayloadAction<{ batchId?: string; folderId?: string; folderName?: string }>
    ) => {
      state.confirmMoveFlowcodeModal.isOpen = !state.confirmMoveFlowcodeModal.isOpen
      state.confirmMoveFlowcodeModal.batchId = action.payload.batchId ?? ''
      state.confirmMoveFlowcodeModal.folderId = action.payload.folderId ?? ''
      state.confirmMoveFlowcodeModal.folderName = action.payload.folderName
    }
  }
})

const codeManagementReducer = persistReducer(
  {
    key: 'codeManagementReducer',
    version: 1,
    storage,
    whitelist: ['selectedFlowcodeView']
  },
  codeManagementSlice.reducer
)

export const {
  setDownloadJustOccurred,
  setFlowcodesFilter,
  setHighlightedFilter,
  setSelectedFlowcodeView,
  setCreateFlowcodeBulkModal,
  toggleDeleteFlowcodeModal,
  toggleRemoveFlowcodeModal,
  toggleDisplayCreateFolderModal,
  toggleEmailFlowcodeModal,
  toggleDownloadFlowcodeModal,
  resetDownloadJustOccurred,
  toggleTransferFlowcodeModal,
  toggleMobileActionsModal,
  toggleCreateFlowcodeBulkModal,
  toggleIsDownloadInProgress,
  toggleConfirmMoveFlowcodeModal,
  setOpenDrawerId,
  setFolderLinksDownloadJustOccurred,
  resetFolderLinksDownloadJustOccurred,
  toggleFolderLinksIsDownloadInProgress,
  toggleDownloadFolderZipResponseModal,
  setDownloadFolderZipResponseModal,
  togglePermissionsModal,
  setBulkActionsBatchIds,
  setBulkActionsFolderIds,
  resetBulkActionsIds,
  toggleIsPerformingBulkActionsOperation,
  setIsShiftKeyPressed,
  setPreviouslySelectedId,
  setFlowcodes,
  setFolders,
  setSelectAllButtonChecked
} = codeManagementSlice.actions

export default codeManagementReducer
