import {
  LandingPageDestination,
  LandingPageFormValuesType
} from '@dtx-company/inter-app/src/types/flowcode'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface ScanDestinationState {
  destination?: LandingPageDestination
  values?: Partial<LandingPageFormValuesType>
}

const initialState: ScanDestinationState = {
  destination: undefined,
  values: undefined
}

const scanDestinationSlice = createSlice({
  name: 'scanDestination',
  initialState,
  reducers: {
    saveDestination: (state, action: PayloadAction<Partial<LandingPageFormValuesType>>) => {
      state.values = action.payload
    },
    saveDestinationType: (state, action: PayloadAction<LandingPageDestination>) => {
      state.destination = action.payload
    },
    clearDestination: state => {
      state.values = initialState.values
    },
    clearDestinationType: state => {
      state.destination = initialState.destination
    }
  }
})

export const { saveDestination, saveDestinationType, clearDestination, clearDestinationType } =
  scanDestinationSlice.actions

export default scanDestinationSlice.reducer
