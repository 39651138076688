import { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring'

export const getUtmParams = (params: ParsedUrlQuery): ParsedUrlQueryInput =>
  Object.entries(params).reduce((acc, [key, val]) => {
    if (key.startsWith('utm')) {
      return {
        ...acc,
        [key]: val
      }
    }
    return acc
  }, {})

export const extractDomain = (url: URL): string => {
  const hostname = url.hostname
  if (hostname === 'localhost') {
    return hostname
  }
  const segments = hostname.split('.')
  segments.splice(0, segments.length - 2)
  return segments.join('.')
}
