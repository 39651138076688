import {
  CookieClassification,
  UserCookiePreferences
} from '@dtx-company/true-common/src/types/cookies'
import { Dispatch } from '@reduxjs/toolkit'
import { deleteCookiesByClassification } from '@dtx-company/true-common/src/utils/cookies/cookies.utils'
import { setCookiePreferences } from '@dtx-company/inter-app/src/redux/slices/cookie'
import { useEffect } from 'react'

export const OneTrustListener = (dispatch: Dispatch) => (): void => {
  try {
    const optanonGroups = window.OptanonActiveGroups?.split(',').filter(x => !!x) ?? []
    if (optanonGroups && optanonGroups.length > 1) {
      const newCookiePref: UserCookiePreferences = {
        C0001: false,
        C0002: false,
        C0003: false,
        C0004: false,
        C0005: false
      }
      optanonGroups.forEach(group => {
        if (Object.keys(newCookiePref).includes(group)) {
          newCookiePref[group as keyof UserCookiePreferences] = true
        }
      })
      const deleteClassificationList = Object.values(CookieClassification).filter(
        value => !optanonGroups.includes(value)
      ) as CookieClassification[]
      deleteClassificationList.forEach(classification => {
        deleteCookiesByClassification(classification)
      })
      dispatch(setCookiePreferences(newCookiePref))
    }
  } catch (e) {
    console.error('Could not remove cookies')
  }
}

export const ONE_TRUST_CONSENT_EVENT_TYPE = 'consent.onetrust'

export const useOneTrustConsentListener = (listener: () => void): void => {
  useEffect(() => {
    window.addEventListener(ONE_TRUST_CONSENT_EVENT_TYPE, listener)
    return () => {
      window.removeEventListener(ONE_TRUST_CONSENT_EVENT_TYPE, listener)
    }
  }, [listener])
}
