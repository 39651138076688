import { BrandColors, BuilderColors } from '../types/mui'
import { PaletteOptions } from '@mui/material'
import { darkPalette } from './palettes/darkPalette'
import { lightPalette } from './palettes/lightPalette'

type ThemeColors = {
  builder: BuilderColors
  brand: BrandColors
  darkPalette: PaletteOptions
  lightPalette: PaletteOptions
}

//to add a new color, first add it to the BuilderColors type in types/mui.d.ts
export const colors: ThemeColors = {
  builder: {
    blue40: '#005BC1',
    blue80: '#ADC6FF',
    green40: '#006D3D',
    green80: '#7BDA9D',
    jade1: '#DCF9ED',
    magenta40: '#A03868',
    magenta70: '#EF8DB4',
    moon80: '#D3CEC5',
    moon90: '#EBE7E0',
    orange50: '#BF5A00',
    orange70: '#FF8D3F',
    primary00: '#1F1F1F',
    primary30: '#4E4D4B',
    primary95: '#F6F3EF',
    primary100: '#FFFFFF',
    purple40: '#7F4799',
    purple70: '#CD9FE1',
    red40: '#BA1A1A',
    red60: '#F7756A',
    space10: '#242424',
    space30: '#53514F',
    teal40: '#166A7B',
    teal80: '#82D7E8',
    yellow60: '#BF8600',
    yellow80: '#FFBA37',
    yoda50: '#678000',
    yoda80: '#AED50B'
  },
  brand: {
    ink: '#242424',
    graphite: '#53514F',
    parchment: '#D1C8BE',
    paper: '#EEEBE5',
    green: '#238753',
    lightGreen: '#7BDA9D',
    matcha: '#86A14C',
    yoda: '#AED50B',
    yellow: '#FACA2F',
    sand: '#FFE9B0',
    tan: '#D3AF7A',
    gold: '#D27B30',
    brown: '#A55B5B',
    red: '#9B001A',
    orange: '#FF523C',
    magenta: '#BD3F7B',
    lilac: '#D49ED6',
    grape: '#674075',
    navy: '#1B0F66',
    blue: '#5169B8',
    periwinkle: '#B1CAF6',
    turquoise: '#6CB8C6'
  },
  darkPalette: darkPalette,
  lightPalette: lightPalette
}
