// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file forms/v1/options.proto (package forms.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { Message, proto3, Timestamp } from '@bufbuild/protobuf'

/**
 * @generated from message forms.v1.PaginationOptions
 */
export class PaginationOptions extends Message<PaginationOptions> {
  /**
   * @generated from field: int32 page_size = 1;
   */
  pageSize = 0

  /**
   * @generated from field: optional string page_token = 2;
   */
  pageToken?: string

  /**
   * @generated from field: int32 skip = 3;
   */
  skip = 0

  constructor(data?: PartialMessage<PaginationOptions>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.PaginationOptions'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'page_size', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'page_token', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: 'skip', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaginationOptions {
    return new PaginationOptions().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaginationOptions {
    return new PaginationOptions().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaginationOptions {
    return new PaginationOptions().fromJsonString(jsonString, options)
  }

  static equals(
    a: PaginationOptions | PlainMessage<PaginationOptions> | undefined,
    b: PaginationOptions | PlainMessage<PaginationOptions> | undefined
  ): boolean {
    return proto3.util.equals(PaginationOptions, a, b)
  }
}

/**
 * @generated from message forms.v1.DateRange
 */
export class DateRange extends Message<DateRange> {
  /**
   * @generated from field: optional google.protobuf.Timestamp before = 1;
   */
  before?: Timestamp

  /**
   * @generated from field: optional google.protobuf.Timestamp after = 2;
   */
  after?: Timestamp

  constructor(data?: PartialMessage<DateRange>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.DateRange'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'before', kind: 'message', T: Timestamp, opt: true },
    { no: 2, name: 'after', kind: 'message', T: Timestamp, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DateRange {
    return new DateRange().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DateRange {
    return new DateRange().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DateRange {
    return new DateRange().fromJsonString(jsonString, options)
  }

  static equals(
    a: DateRange | PlainMessage<DateRange> | undefined,
    b: DateRange | PlainMessage<DateRange> | undefined
  ): boolean {
    return proto3.util.equals(DateRange, a, b)
  }
}
