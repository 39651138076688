import { FronteggAccessToken } from './types'
import { logger } from '@dtx-company/logger'
import decodeJwt from 'jwt-decode'

export function decodeFronteggAccessToken(
  encodedAccessToken: string
): FronteggAccessToken | undefined {
  let decodedJwt: FronteggAccessToken | undefined
  try {
    decodedJwt = decodeJwt<FronteggAccessToken>(encodedAccessToken)
    if (!decodedJwt) {
      throw new Error('Failed to decode Frontegg access token')
    }
    if (!decodedJwt.customClaims?.ithacaId) {
      throw new Error('No Ithaca ID present in custom claims on Frontegg access token')
    }
  } catch (e) {
    logger.logError(e, { technicalArea: 'authentication' })
  }
  return decodedJwt
}
