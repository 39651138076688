import { Cohort } from '@dtx-company/true-common/src/types/cohorts'
import { IthacaJwt, IthacaOrg } from '@dtx-company/ithaca-sdk/src'
import { IthacaOperationName } from '@dtx-company/true-common/src/types/operations'
import { OrgExtra, OrgRoleEnum, OrgRoleExtraEnum } from '@dtx-company/true-common/src/types/org'
import { UserRBAC } from '../../types/user'
import { decodeFronteggAccessToken } from '@dtx-company/ithaca-sdk/src/frontegg/decodeFronteggAccessToken'
import { getCohort, jwtHasOperation } from '../../utils/jwt'
import { useCallback, useMemo } from 'react'
import {
  useCurrentUserJwt,
  useIsAuthChecked,
  useIsImpersonating
} from '../../redux/selectors/currentUserSelectors'
import { useRefreshJWT } from '../../hooks/auth/useRefreshJWT'

type HasOrgExtra = (orgExtra: OrgExtra) => boolean
type HasUserOrgRoleExtra = (extra: OrgRoleExtraEnum) => boolean
type HasTeamOperation = (teamId: string, operationName: IthacaOperationName) => boolean

export interface AuthState {
  jwt: IthacaJwt | null
  token: IthacaJwt['token'] | undefined
  org: IthacaOrg | undefined
  isAuthenticated: boolean
  isAuthChecked: boolean
  isViewer: boolean
  isAdmin: boolean
  isSuperAdmin: boolean
  isFlowpageAdmin: boolean
  deprecated_isEnterpriseUser: boolean
  deprecated_isEnterpriseOrProFlexUser: boolean
  deprecated_isProFlexUser: boolean
  deprecated_isProAccount: boolean
  deprecated_isProPlusAccount: boolean
  deprecated_isFreeAccount: boolean
  codeLimit: number
  pageLimit: number
  hasOperation: (operationName: IthacaOperationName) => boolean
  hasOrgExtra: HasOrgExtra
  hasUserOrgRoleExtra: HasUserOrgRoleExtra
  hasTeamOperation: HasTeamOperation
  refreshJwt: () => Promise<void>
  isInFlowcodeOrg: boolean
  cohort: Cohort | null
  isImpersonating: boolean
  userIsInFlowcodeOrg: boolean
  fronteggId?: string
}

export function useAuthState(): AuthState {
  const jwt = useCurrentUserJwt()

  const isAuthChecked = useIsAuthChecked()

  const hasOperation = useCallback(
    (operationName: IthacaOperationName): boolean => {
      return jwtHasOperation({ jwt, operationName })
    },
    [jwt]
  )

  const deprecated_isEnterprise = !!(jwt?.org?.orgPlan.split(':')[1] === UserRBAC.ENTERPRISE)
  const deprecated_isProFlexUser = !!(jwt?.org?.orgPlan.split(':')[1] === UserRBAC.PRO_FLEX)
  const deprecated_isEnterpriseOrProFlexUser = deprecated_isEnterprise || deprecated_isProFlexUser

  const userIsInFlowcodeOrg = Boolean(jwt?.org?.name === 'Flowcode')

  const isFlowpageAdmin =
    userIsInFlowcodeOrg &&
    Boolean(jwt?.org?.userOrgRoleExtras?.includes(OrgRoleExtraEnum.FLOWPAGE_ADMIN))

  const isSuperAdmin =
    userIsInFlowcodeOrg &&
    Boolean(jwt?.org?.userOrgRoleExtras?.includes(OrgRoleExtraEnum.SUPER_ADMIN))

  const deprecated_isProPlusAccount =
    (jwt?.personalPlan?.includes('pro_plus') ?? false) && !deprecated_isEnterprise
  const deprecated_isProAccount =
    (jwt?.personalPlan?.includes('pro') ?? false) &&
    !deprecated_isProPlusAccount &&
    !deprecated_isEnterprise

  const refreshJwt = useRefreshJWT({ updateAppStateSynchronously: false })

  const hasOrgExtra = useCallback<HasOrgExtra>(
    extra => {
      return jwt?.org?.orgExtras?.includes(extra) ?? false
    },
    [jwt]
  )

  const hasUserOrgRoleExtra = useCallback<HasUserOrgRoleExtra>(
    extra => {
      return jwt?.org?.userOrgRoleExtras?.includes(extra) ?? false
    },
    [jwt]
  )

  const hasTeamOperation = useCallback<HasTeamOperation>(
    (teamId, operationToFind) => {
      const [team] = jwt?.org?.teams?.filter(team => team.teamId === teamId) ?? []
      if (!team) return false
      const teamOperations = (
        team?.permissions?.map(perm => perm.operations as IthacaOperationName[]) ?? []
      ).flat()
      const operationFound = teamOperations?.find(op => op === operationToFind)
      return Boolean(operationFound)
    },
    [jwt]
  )

  const cohort = getCohort(jwt)

  const isImpersonating = useIsImpersonating()

  const fronteggId = useMemo(() => {
    if (!jwt) return undefined
    return decodeFronteggAccessToken(jwt.token)?.sub
  }, [jwt])

  return {
    userIsInFlowcodeOrg,
    isImpersonating,
    cohort,
    refreshJwt,
    jwt, //TODO: audit this value for side effects when properties like token are stubbed
    token: jwt?.token, //TODO: audit this value for side effects when stubbed
    org: jwt?.org,
    isAuthenticated: !!jwt && jwt.token != '',
    isAuthChecked,
    isViewer: jwt?.org?.userOrgRole === OrgRoleEnum.VIEWER,
    isAdmin: !!(jwt?.org?.userOrgRole === OrgRoleEnum.ADMIN),
    isSuperAdmin,
    isFlowpageAdmin,
    deprecated_isEnterpriseUser: deprecated_isEnterprise,
    deprecated_isEnterpriseOrProFlexUser,
    deprecated_isProFlexUser: deprecated_isProFlexUser,
    deprecated_isProAccount: deprecated_isProAccount,
    deprecated_isProPlusAccount: deprecated_isProPlusAccount,
    deprecated_isFreeAccount:
      (jwt?.personalPlan?.includes('free') ?? false) && !deprecated_isEnterpriseOrProFlexUser,
    codeLimit: Number(jwt?.attributes?.['code_limit']) ?? 0,
    pageLimit: Number(jwt?.attributes?.['page_limit']) ?? 0,
    hasOperation,
    hasOrgExtra,
    hasUserOrgRoleExtra,
    hasTeamOperation,
    isInFlowcodeOrg: jwt?.org?.name === 'Flowcode',
    fronteggId
  }
}
