import { PlaceholdersType } from '../types/flowpage'
import { TWITTER_LABEL } from '@app/code/src/components/molecules/destination-icon'

export const placeholders: PlaceholdersType = {
  socialLink: {
    id: 'placeholder-social-link',
    actionData: {
      instagram: {
        title: `My Instagram`,
        description: '',
        order: 0,
        link: ''
      },
      tiktok: {
        title: `My TikTok`,
        description: '',
        order: 1,
        link: ''
      },
      snapchat: {
        title: `My Snapchat`,
        description: '',
        order: 2,
        link: ''
      },
      twitter: {
        title: `My ${TWITTER_LABEL}`,
        description: '',
        order: 3,
        link: ''
      },
      linkedin: {
        title: `My LinkedIn`,
        description: '',
        order: 4,
        link: ''
      },
      iconSize: 'small',
      iconStyle: 'circle'
    }
  },
  contactInfo: {
    id: 'placeholder-contact-link',
    actionData: {
      call: '',
      text: '',
      email: '',
      iconFill: 'fill',
      iconSize: '24px'
    },
    order: -1,
    displayType: 'widget'
  },
  destination: {
    id: 'placeholder-destination-link',
    title: 'My URL',
    actionData: {
      link: ''
    }
  }
}
