// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file forms/v1/form_submission.proto (package forms.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { Message, proto3, protoInt64, Timestamp, Value } from '@bufbuild/protobuf'
import { SuccessAction } from './success_screen_pb'
import { Field } from './field_pb'
import { DateRange, PaginationOptions } from './options_pb'

/**
 * @generated from message forms.v1.TextAnswer
 */
export class TextAnswer extends Message<TextAnswer> {
  /**
   * @generated from field: string value = 1;
   */
  value = ''

  constructor(data?: PartialMessage<TextAnswer>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.TextAnswer'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'value', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextAnswer {
    return new TextAnswer().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextAnswer {
    return new TextAnswer().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextAnswer {
    return new TextAnswer().fromJsonString(jsonString, options)
  }

  static equals(
    a: TextAnswer | PlainMessage<TextAnswer> | undefined,
    b: TextAnswer | PlainMessage<TextAnswer> | undefined
  ): boolean {
    return proto3.util.equals(TextAnswer, a, b)
  }
}

/**
 * @generated from message forms.v1.TextAnswers
 */
export class TextAnswers extends Message<TextAnswers> {
  /**
   * @generated from field: repeated forms.v1.TextAnswer values = 1;
   */
  values: TextAnswer[] = []

  constructor(data?: PartialMessage<TextAnswers>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.TextAnswers'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'values', kind: 'message', T: TextAnswer, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextAnswers {
    return new TextAnswers().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextAnswers {
    return new TextAnswers().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextAnswers {
    return new TextAnswers().fromJsonString(jsonString, options)
  }

  static equals(
    a: TextAnswers | PlainMessage<TextAnswers> | undefined,
    b: TextAnswers | PlainMessage<TextAnswers> | undefined
  ): boolean {
    return proto3.util.equals(TextAnswers, a, b)
  }
}

/**
 * @generated from message forms.v1.FileAnswer
 */
export class FileAnswer extends Message<FileAnswer> {
  /**
   * @generated from field: string url = 1;
   */
  url = ''

  /**
   * @generated from field: string file_name = 2;
   */
  fileName = ''

  /**
   * @generated from field: string mimetype = 3;
   */
  mimetype = ''

  constructor(data?: PartialMessage<FileAnswer>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.FileAnswer'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'file_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'mimetype', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileAnswer {
    return new FileAnswer().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileAnswer {
    return new FileAnswer().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileAnswer {
    return new FileAnswer().fromJsonString(jsonString, options)
  }

  static equals(
    a: FileAnswer | PlainMessage<FileAnswer> | undefined,
    b: FileAnswer | PlainMessage<FileAnswer> | undefined
  ): boolean {
    return proto3.util.equals(FileAnswer, a, b)
  }
}

/**
 * @generated from message forms.v1.FileAnswers
 */
export class FileAnswers extends Message<FileAnswers> {
  /**
   * @generated from field: repeated forms.v1.FileAnswer files = 1;
   */
  files: FileAnswer[] = []

  constructor(data?: PartialMessage<FileAnswers>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.FileAnswers'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'files', kind: 'message', T: FileAnswer, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileAnswers {
    return new FileAnswers().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileAnswers {
    return new FileAnswers().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileAnswers {
    return new FileAnswers().fromJsonString(jsonString, options)
  }

  static equals(
    a: FileAnswers | PlainMessage<FileAnswers> | undefined,
    b: FileAnswers | PlainMessage<FileAnswers> | undefined
  ): boolean {
    return proto3.util.equals(FileAnswers, a, b)
  }
}

/**
 * @generated from message forms.v1.AddressAnswer
 */
export class AddressAnswer extends Message<AddressAnswer> {
  /**
   * Street number and street name
   *
   * @generated from field: string address_1 = 1;
   */
  address1 = ''

  /**
   * Apartment #, suite, unit, building no, floor...
   *
   * @generated from field: optional string address_2 = 2;
   */
  address2?: string

  /**
   * City name
   *
   * @generated from field: string city = 3;
   */
  city = ''

  /**
   * Zone/region in country. A state in the USA, a province in Canada/Japan, etc..
   *
   * @generated from field: string zone = 4;
   */
  zone = ''

  /**
   * Localized postal code
   *
   * @generated from field: string postal_code = 5;
   */
  postalCode = ''

  /**
   * Two character Country code
   *
   * @generated from field: string country = 6;
   */
  country = ''

  constructor(data?: PartialMessage<AddressAnswer>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.AddressAnswer'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'address_1', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'address_2', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: 'city', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'zone', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'postal_code', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'country', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddressAnswer {
    return new AddressAnswer().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddressAnswer {
    return new AddressAnswer().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddressAnswer {
    return new AddressAnswer().fromJsonString(jsonString, options)
  }

  static equals(
    a: AddressAnswer | PlainMessage<AddressAnswer> | undefined,
    b: AddressAnswer | PlainMessage<AddressAnswer> | undefined
  ): boolean {
    return proto3.util.equals(AddressAnswer, a, b)
  }
}

/**
 * @generated from message forms.v1.Answer
 */
export class Answer extends Message<Answer> {
  /**
   * @generated from oneof forms.v1.Answer.answer
   */
  answer:
    | {
        /**
         * @generated from field: forms.v1.TextAnswers text_answer = 1;
         */
        value: TextAnswers
        case: 'textAnswer'
      }
    | {
        /**
         * @generated from field: forms.v1.AddressAnswer address_answer = 2;
         */
        value: AddressAnswer
        case: 'addressAnswer'
      }
    | {
        /**
         * @generated from field: forms.v1.FileAnswers file_answer = 3;
         */
        value: FileAnswers
        case: 'fileAnswer'
      }
    | { case: undefined; value?: undefined } = { case: undefined }

  constructor(data?: PartialMessage<Answer>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.Answer'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'text_answer', kind: 'message', T: TextAnswers, oneof: 'answer' },
    { no: 2, name: 'address_answer', kind: 'message', T: AddressAnswer, oneof: 'answer' },
    { no: 3, name: 'file_answer', kind: 'message', T: FileAnswers, oneof: 'answer' }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Answer {
    return new Answer().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Answer {
    return new Answer().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Answer {
    return new Answer().fromJsonString(jsonString, options)
  }

  static equals(
    a: Answer | PlainMessage<Answer> | undefined,
    b: Answer | PlainMessage<Answer> | undefined
  ): boolean {
    return proto3.util.equals(Answer, a, b)
  }
}

/**
 * @generated from message forms.v1.PostDataResponse
 */
export class PostDataResponse extends Message<PostDataResponse> {
  /**
   * @generated from field: string form_id = 3;
   */
  formId = ''

  /**
   * @generated from field: forms.v1.Submission submission = 1;
   */
  submission?: Submission

  /**
   * @generated from field: forms.v1.SuccessAction success_action = 2;
   */
  successAction?: SuccessAction

  constructor(data?: PartialMessage<PostDataResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.PostDataResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: 'form_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 1, name: 'submission', kind: 'message', T: Submission },
    { no: 2, name: 'success_action', kind: 'message', T: SuccessAction }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostDataResponse {
    return new PostDataResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostDataResponse {
    return new PostDataResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostDataResponse {
    return new PostDataResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: PostDataResponse | PlainMessage<PostDataResponse> | undefined,
    b: PostDataResponse | PlainMessage<PostDataResponse> | undefined
  ): boolean {
    return proto3.util.equals(PostDataResponse, a, b)
  }
}

/**
 * @generated from message forms.v1.Submission
 */
export class Submission extends Message<Submission> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: repeated forms.v1.FieldValue fields = 2;
   */
  fields: FieldValue[] = []

  constructor(data?: PartialMessage<Submission>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.Submission'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'fields', kind: 'message', T: FieldValue, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Submission {
    return new Submission().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Submission {
    return new Submission().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Submission {
    return new Submission().fromJsonString(jsonString, options)
  }

  static equals(
    a: Submission | PlainMessage<Submission> | undefined,
    b: Submission | PlainMessage<Submission> | undefined
  ): boolean {
    return proto3.util.equals(Submission, a, b)
  }
}

/**
 * @generated from message forms.v1.FieldValue
 */
export class FieldValue extends Message<FieldValue> {
  /**
   * @generated from field: forms.v1.Field field = 1;
   */
  field?: Field

  /**
   * @generated from field: google.protobuf.Value value = 2;
   */
  value?: Value

  constructor(data?: PartialMessage<FieldValue>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.FieldValue'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'field', kind: 'message', T: Field },
    { no: 2, name: 'value', kind: 'message', T: Value }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FieldValue {
    return new FieldValue().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FieldValue {
    return new FieldValue().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FieldValue {
    return new FieldValue().fromJsonString(jsonString, options)
  }

  static equals(
    a: FieldValue | PlainMessage<FieldValue> | undefined,
    b: FieldValue | PlainMessage<FieldValue> | undefined
  ): boolean {
    return proto3.util.equals(FieldValue, a, b)
  }
}

/**
 * Proto stored in the db
 *
 * @generated from message forms.v1.DBAnswers
 */
export class DBAnswers extends Message<DBAnswers> {
  /**
   * @generated from field: map<string, forms.v1.Answer> answers = 1;
   */
  answers: { [key: string]: Answer } = {}

  constructor(data?: PartialMessage<DBAnswers>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.DBAnswers'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'answers',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Answer }
    }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DBAnswers {
    return new DBAnswers().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DBAnswers {
    return new DBAnswers().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DBAnswers {
    return new DBAnswers().fromJsonString(jsonString, options)
  }

  static equals(
    a: DBAnswers | PlainMessage<DBAnswers> | undefined,
    b: DBAnswers | PlainMessage<DBAnswers> | undefined
  ): boolean {
    return proto3.util.equals(DBAnswers, a, b)
  }
}

/**
 * @generated from message forms.v1.FormSummary
 */
export class FormSummary extends Message<FormSummary> {
  /**
   * @generated from field: string form_id = 1;
   */
  formId = ''

  /**
   * @generated from field: string title = 2;
   */
  title = ''

  /**
   * @generated from field: int64 submission_count = 3;
   */
  submissionCount = protoInt64.zero

  constructor(data?: PartialMessage<FormSummary>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.FormSummary'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'form_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'submission_count', kind: 'scalar', T: 3 /* ScalarType.INT64 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FormSummary {
    return new FormSummary().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FormSummary {
    return new FormSummary().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FormSummary {
    return new FormSummary().fromJsonString(jsonString, options)
  }

  static equals(
    a: FormSummary | PlainMessage<FormSummary> | undefined,
    b: FormSummary | PlainMessage<FormSummary> | undefined
  ): boolean {
    return proto3.util.equals(FormSummary, a, b)
  }
}

/**
 * @generated from message forms.v1.SubmitFormRequest
 */
export class SubmitFormRequest extends Message<SubmitFormRequest> {
  /**
   * @generated from field: string form_id = 1;
   */
  formId = ''

  /**
   * @generated from field: map<string, forms.v1.Answer> answers = 2;
   */
  answers: { [key: string]: Answer } = {}

  constructor(data?: PartialMessage<SubmitFormRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.SubmitFormRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'form_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 2,
      name: 'answers',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Answer }
    }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitFormRequest {
    return new SubmitFormRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitFormRequest {
    return new SubmitFormRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitFormRequest {
    return new SubmitFormRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: SubmitFormRequest | PlainMessage<SubmitFormRequest> | undefined,
    b: SubmitFormRequest | PlainMessage<SubmitFormRequest> | undefined
  ): boolean {
    return proto3.util.equals(SubmitFormRequest, a, b)
  }
}

/**
 * @generated from message forms.v1.SubmitFormResponse
 */
export class SubmitFormResponse extends Message<SubmitFormResponse> {
  constructor(data?: PartialMessage<SubmitFormResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.SubmitFormResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitFormResponse {
    return new SubmitFormResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitFormResponse {
    return new SubmitFormResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SubmitFormResponse {
    return new SubmitFormResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: SubmitFormResponse | PlainMessage<SubmitFormResponse> | undefined,
    b: SubmitFormResponse | PlainMessage<SubmitFormResponse> | undefined
  ): boolean {
    return proto3.util.equals(SubmitFormResponse, a, b)
  }
}

/**
 * @generated from message forms.v1.SubmissionRow
 */
export class SubmissionRow extends Message<SubmissionRow> {
  /**
   * @generated from field: map<string, forms.v1.Answer> answers = 1;
   */
  answers: { [key: string]: Answer } = {}

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 2;
   */
  createdAt?: Timestamp

  /**
   * @generated from field: string id = 3;
   */
  id = ''

  constructor(data?: PartialMessage<SubmissionRow>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.SubmissionRow'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'answers',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Answer }
    },
    { no: 2, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 3, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmissionRow {
    return new SubmissionRow().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmissionRow {
    return new SubmissionRow().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmissionRow {
    return new SubmissionRow().fromJsonString(jsonString, options)
  }

  static equals(
    a: SubmissionRow | PlainMessage<SubmissionRow> | undefined,
    b: SubmissionRow | PlainMessage<SubmissionRow> | undefined
  ): boolean {
    return proto3.util.equals(SubmissionRow, a, b)
  }
}

/**
 * @generated from message forms.v1.ListSubmissionsRequest
 */
export class ListSubmissionsRequest extends Message<ListSubmissionsRequest> {
  /**
   * @generated from field: string form_id = 1;
   */
  formId = ''

  /**
   * @generated from field: optional string filter = 2;
   */
  filter?: string

  /**
   * @generated from field: optional forms.v1.PaginationOptions page_options = 3;
   */
  pageOptions?: PaginationOptions

  /**
   * @generated from field: optional forms.v1.DateRange date_range = 4;
   */
  dateRange?: DateRange

  constructor(data?: PartialMessage<ListSubmissionsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.ListSubmissionsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'form_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'filter', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: 'page_options', kind: 'message', T: PaginationOptions, opt: true },
    { no: 4, name: 'date_range', kind: 'message', T: DateRange, opt: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListSubmissionsRequest {
    return new ListSubmissionsRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListSubmissionsRequest {
    return new ListSubmissionsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListSubmissionsRequest {
    return new ListSubmissionsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListSubmissionsRequest | PlainMessage<ListSubmissionsRequest> | undefined,
    b: ListSubmissionsRequest | PlainMessage<ListSubmissionsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListSubmissionsRequest, a, b)
  }
}

/**
 * @generated from message forms.v1.ListSubmissionsResponse
 */
export class ListSubmissionsResponse extends Message<ListSubmissionsResponse> {
  /**
   * @generated from field: repeated forms.v1.SubmissionRow submissions = 1;
   */
  submissions: SubmissionRow[] = []

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = ''

  constructor(data?: PartialMessage<ListSubmissionsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.ListSubmissionsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'submissions', kind: 'message', T: SubmissionRow, repeated: true },
    { no: 2, name: 'next_page_token', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListSubmissionsResponse {
    return new ListSubmissionsResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListSubmissionsResponse {
    return new ListSubmissionsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListSubmissionsResponse {
    return new ListSubmissionsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListSubmissionsResponse | PlainMessage<ListSubmissionsResponse> | undefined,
    b: ListSubmissionsResponse | PlainMessage<ListSubmissionsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListSubmissionsResponse, a, b)
  }
}

/**
 * @generated from message forms.v1.DeleteSubmissionRequest
 */
export class DeleteSubmissionRequest extends Message<DeleteSubmissionRequest> {
  /**
   * @generated from field: string submission_id = 1;
   */
  submissionId = ''

  constructor(data?: PartialMessage<DeleteSubmissionRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.DeleteSubmissionRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'submission_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteSubmissionRequest {
    return new DeleteSubmissionRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteSubmissionRequest {
    return new DeleteSubmissionRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteSubmissionRequest {
    return new DeleteSubmissionRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteSubmissionRequest | PlainMessage<DeleteSubmissionRequest> | undefined,
    b: DeleteSubmissionRequest | PlainMessage<DeleteSubmissionRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteSubmissionRequest, a, b)
  }
}

/**
 * @generated from message forms.v1.DeleteSubmissionResponse
 */
export class DeleteSubmissionResponse extends Message<DeleteSubmissionResponse> {
  constructor(data?: PartialMessage<DeleteSubmissionResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.DeleteSubmissionResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteSubmissionResponse {
    return new DeleteSubmissionResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteSubmissionResponse {
    return new DeleteSubmissionResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteSubmissionResponse {
    return new DeleteSubmissionResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteSubmissionResponse | PlainMessage<DeleteSubmissionResponse> | undefined,
    b: DeleteSubmissionResponse | PlainMessage<DeleteSubmissionResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteSubmissionResponse, a, b)
  }
}

/**
 * @generated from message forms.v1.BatchDeleteSubmissionRequest
 */
export class BatchDeleteSubmissionRequest extends Message<BatchDeleteSubmissionRequest> {
  /**
   * @generated from field: repeated string submission_ids = 1;
   */
  submissionIds: string[] = []

  constructor(data?: PartialMessage<BatchDeleteSubmissionRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.BatchDeleteSubmissionRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'submission_ids', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): BatchDeleteSubmissionRequest {
    return new BatchDeleteSubmissionRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): BatchDeleteSubmissionRequest {
    return new BatchDeleteSubmissionRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): BatchDeleteSubmissionRequest {
    return new BatchDeleteSubmissionRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: BatchDeleteSubmissionRequest | PlainMessage<BatchDeleteSubmissionRequest> | undefined,
    b: BatchDeleteSubmissionRequest | PlainMessage<BatchDeleteSubmissionRequest> | undefined
  ): boolean {
    return proto3.util.equals(BatchDeleteSubmissionRequest, a, b)
  }
}

/**
 * @generated from message forms.v1.BatchDeleteSubmissionResponse
 */
export class BatchDeleteSubmissionResponse extends Message<BatchDeleteSubmissionResponse> {
  constructor(data?: PartialMessage<BatchDeleteSubmissionResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.BatchDeleteSubmissionResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): BatchDeleteSubmissionResponse {
    return new BatchDeleteSubmissionResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): BatchDeleteSubmissionResponse {
    return new BatchDeleteSubmissionResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): BatchDeleteSubmissionResponse {
    return new BatchDeleteSubmissionResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: BatchDeleteSubmissionResponse | PlainMessage<BatchDeleteSubmissionResponse> | undefined,
    b: BatchDeleteSubmissionResponse | PlainMessage<BatchDeleteSubmissionResponse> | undefined
  ): boolean {
    return proto3.util.equals(BatchDeleteSubmissionResponse, a, b)
  }
}

/**
 * @generated from message forms.v1.SubmissionsSummaryRequest
 */
export class SubmissionsSummaryRequest extends Message<SubmissionsSummaryRequest> {
  /**
   * @generated from field: string form_id = 1;
   */
  formId = ''

  /**
   * @generated from field: optional string filter = 2;
   */
  filter?: string

  /**
   * @generated from field: optional forms.v1.DateRange date_range = 3;
   */
  dateRange?: DateRange

  constructor(data?: PartialMessage<SubmissionsSummaryRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.SubmissionsSummaryRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'form_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'filter', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: 'date_range', kind: 'message', T: DateRange, opt: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SubmissionsSummaryRequest {
    return new SubmissionsSummaryRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SubmissionsSummaryRequest {
    return new SubmissionsSummaryRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SubmissionsSummaryRequest {
    return new SubmissionsSummaryRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: SubmissionsSummaryRequest | PlainMessage<SubmissionsSummaryRequest> | undefined,
    b: SubmissionsSummaryRequest | PlainMessage<SubmissionsSummaryRequest> | undefined
  ): boolean {
    return proto3.util.equals(SubmissionsSummaryRequest, a, b)
  }
}

/**
 * @generated from message forms.v1.SubmissionsSummaryResponse
 */
export class SubmissionsSummaryResponse extends Message<SubmissionsSummaryResponse> {
  /**
   * @generated from field: int64 total_count = 1;
   */
  totalCount = protoInt64.zero

  constructor(data?: PartialMessage<SubmissionsSummaryResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.SubmissionsSummaryResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'total_count', kind: 'scalar', T: 3 /* ScalarType.INT64 */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SubmissionsSummaryResponse {
    return new SubmissionsSummaryResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SubmissionsSummaryResponse {
    return new SubmissionsSummaryResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SubmissionsSummaryResponse {
    return new SubmissionsSummaryResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: SubmissionsSummaryResponse | PlainMessage<SubmissionsSummaryResponse> | undefined,
    b: SubmissionsSummaryResponse | PlainMessage<SubmissionsSummaryResponse> | undefined
  ): boolean {
    return proto3.util.equals(SubmissionsSummaryResponse, a, b)
  }
}

/**
 * @generated from message forms.v1.DownloadSubmissionsCSVRequest
 */
export class DownloadSubmissionsCSVRequest extends Message<DownloadSubmissionsCSVRequest> {
  /**
   * @generated from field: string form_id = 1;
   */
  formId = ''

  constructor(data?: PartialMessage<DownloadSubmissionsCSVRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.DownloadSubmissionsCSVRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'form_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DownloadSubmissionsCSVRequest {
    return new DownloadSubmissionsCSVRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DownloadSubmissionsCSVRequest {
    return new DownloadSubmissionsCSVRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DownloadSubmissionsCSVRequest {
    return new DownloadSubmissionsCSVRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DownloadSubmissionsCSVRequest | PlainMessage<DownloadSubmissionsCSVRequest> | undefined,
    b: DownloadSubmissionsCSVRequest | PlainMessage<DownloadSubmissionsCSVRequest> | undefined
  ): boolean {
    return proto3.util.equals(DownloadSubmissionsCSVRequest, a, b)
  }
}

/**
 * @generated from message forms.v1.GetLiveResultsRequest
 */
export class GetLiveResultsRequest extends Message<GetLiveResultsRequest> {
  /**
   * @generated from field: string form_id = 1;
   */
  formId = ''

  constructor(data?: PartialMessage<GetLiveResultsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.GetLiveResultsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'form_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetLiveResultsRequest {
    return new GetLiveResultsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLiveResultsRequest {
    return new GetLiveResultsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetLiveResultsRequest {
    return new GetLiveResultsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetLiveResultsRequest | PlainMessage<GetLiveResultsRequest> | undefined,
    b: GetLiveResultsRequest | PlainMessage<GetLiveResultsRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetLiveResultsRequest, a, b)
  }
}

/**
 * @generated from message forms.v1.Count
 */
export class Count extends Message<Count> {
  /**
   * @generated from field: map<string, string> field_counts = 1;
   */
  fieldCounts: { [key: string]: string } = {}

  constructor(data?: PartialMessage<Count>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.Count'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'field_counts',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'scalar', T: 9 /* ScalarType.STRING */ }
    }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Count {
    return new Count().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Count {
    return new Count().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Count {
    return new Count().fromJsonString(jsonString, options)
  }

  static equals(
    a: Count | PlainMessage<Count> | undefined,
    b: Count | PlainMessage<Count> | undefined
  ): boolean {
    return proto3.util.equals(Count, a, b)
  }
}

/**
 * @generated from message forms.v1.GetLiveResultsResponse
 */
export class GetLiveResultsResponse extends Message<GetLiveResultsResponse> {
  /**
   * @generated from field: map<string, forms.v1.Count> counts = 1;
   */
  counts: { [key: string]: Count } = {}

  constructor(data?: PartialMessage<GetLiveResultsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.GetLiveResultsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'counts',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Count }
    }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetLiveResultsResponse {
    return new GetLiveResultsResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetLiveResultsResponse {
    return new GetLiveResultsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetLiveResultsResponse {
    return new GetLiveResultsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetLiveResultsResponse | PlainMessage<GetLiveResultsResponse> | undefined,
    b: GetLiveResultsResponse | PlainMessage<GetLiveResultsResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetLiveResultsResponse, a, b)
  }
}

/**
 * @generated from message forms.v1.BatchGetSubmissionSummariesRequest
 */
export class BatchGetSubmissionSummariesRequest extends Message<BatchGetSubmissionSummariesRequest> {
  /**
   * @generated from field: repeated string form_ids = 1;
   */
  formIds: string[] = []

  /**
   * @generated from field: bool all_forms = 2;
   */
  allForms = false

  constructor(data?: PartialMessage<BatchGetSubmissionSummariesRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.BatchGetSubmissionSummariesRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'form_ids', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: 'all_forms', kind: 'scalar', T: 8 /* ScalarType.BOOL */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): BatchGetSubmissionSummariesRequest {
    return new BatchGetSubmissionSummariesRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): BatchGetSubmissionSummariesRequest {
    return new BatchGetSubmissionSummariesRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): BatchGetSubmissionSummariesRequest {
    return new BatchGetSubmissionSummariesRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a:
      | BatchGetSubmissionSummariesRequest
      | PlainMessage<BatchGetSubmissionSummariesRequest>
      | undefined,
    b:
      | BatchGetSubmissionSummariesRequest
      | PlainMessage<BatchGetSubmissionSummariesRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(BatchGetSubmissionSummariesRequest, a, b)
  }
}

/**
 * @generated from message forms.v1.BatchGetSubmissionSummariesResponse
 */
export class BatchGetSubmissionSummariesResponse extends Message<BatchGetSubmissionSummariesResponse> {
  /**
   * @generated from field: repeated forms.v1.FormSummary statistics = 1;
   */
  statistics: FormSummary[] = []

  constructor(data?: PartialMessage<BatchGetSubmissionSummariesResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.BatchGetSubmissionSummariesResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'statistics', kind: 'message', T: FormSummary, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): BatchGetSubmissionSummariesResponse {
    return new BatchGetSubmissionSummariesResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): BatchGetSubmissionSummariesResponse {
    return new BatchGetSubmissionSummariesResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): BatchGetSubmissionSummariesResponse {
    return new BatchGetSubmissionSummariesResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a:
      | BatchGetSubmissionSummariesResponse
      | PlainMessage<BatchGetSubmissionSummariesResponse>
      | undefined,
    b:
      | BatchGetSubmissionSummariesResponse
      | PlainMessage<BatchGetSubmissionSummariesResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(BatchGetSubmissionSummariesResponse, a, b)
  }
}
