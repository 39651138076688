import { gql } from 'graphql-request'

export const LinkInfoFragment = gql`
  fragment LinkInfo on Link {
    id
    active
    provider
    type
    title
    description
    thumbNailImgUrl
    order
    position
    embed
    displayType
    actionData
    templateConfig {
      order
      lockAll
      requireAll
      fields {
        name
        required
        locked
      }
    }
    childLinks {
      id
      active
      provider
      type
      title
      description
      order
      actionData
      parentLinkId
      thumbNailImgUrl
    }
    linkTheme {
      linkColor
      fontColor
      textSize
      borderColor
      shadowColor
      active
    }
  }
`

export const leanPageInfoFrag = gql`
  fragment LeanPageInfo on Page {
    id
    displayName
    email
    shortUrl
    profileImage
    caption
    active
    pageType
    slugName
    verified
    share
    isIndexed
    createdAt
    assetTags {
      id
      tagName
      pageId
    }
    permission {
      permissionName
      entityId
      entityName
    }
    links {
      type
    }
    owner {
      displayName
      email
      ithacaId
    }
    domain {
      domain
    }
  }
`

export const minimalPageInfoFrag = gql`
  fragment MinimalPageInfo on Page {
    id
    displayName
    profileImage
    caption
    slugName
  }
`

export const allPageInfoFrag = gql`
  fragment AllPageInfo on Page {
    id
    displayName
    email
    shortUrl
    profileImage
    caption
    baseTemplateId
    active
    pageType
    slugName
    domainId
    share
    isIndexed
    createdFromTemplate {
      pageId
    }
    verified
    domain {
      domainServiceSubdomainId
      domain
    }
    permission {
      permissionName
      entityId
      entityName
    }
    createdAt
    links {
      ...LinkInfo
    }
    assetTags {
      id
      tagName
      pageId
    }
    theme {
      id
      primaryColor
      style
      profileImageMaskType
      backgroundImgUrl
      linkStyle
      linkShape
      fontFamily
      linkColor
      fontColor
      borderColor
      shadowColor
      titleColor
      textSize
      templateConfig {
        order
        fields {
          name
          locked
          required
        }
        lockAll
        requireAll
      }
    }
    owner {
      displayName
      email
      ithacaId
    }
    slots {
      data
      id
      slotMeta {
        type
        id
      }
      templateConfig {
        order
        fields {
          name
          locked
          required
        }
        lockAll
        requireAll
      }
    }
    templateConfig {
      lockAll
      fields {
        name
        required
        locked
      }
      requireAll
      order
    }
  }
  ${LinkInfoFragment}
`

export const adminPageInfoFrag = gql`
  fragment AdminPageInfo on Page {
    ...AllPageInfo
    deletedAt
    modifiedAt
    deleteReason
  }
  ${allPageInfoFrag}
`
