import { FlowOptimizely } from '@dtx-company/inter-app/src/types/FlowOptimizely'
import { FlowSession } from '@dtx-company/inter-app/src/types/FlowSession'
import { OptimizelyProvider } from '@dtx-company/inter-app/src/components/OptimizelyProvider'
import { ReactNode } from 'react'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { useInitialAppValue } from '../hooks/useInitialAppValue'
import { useRouter } from 'next/router'

export function AppOptimizelyProvider(props: {
  children: ReactNode
  session: FlowSession | undefined
  optimizely: FlowOptimizely | undefined
}): JSX.Element {
  const router = useRouter()
  const isFlowpage = router.pathname === Routes.FLOWPAGE
  const value = useInitialAppValue(props.session, props.optimizely)
  return (
    <OptimizelyProvider
      sessionId={value.session?.sessionId ?? ''}
      datafile={value.optimizely?.datafile ?? ''}
      attributes={value.optimizely?.attributes}
      overrides={value.optimizely?.overrides}
      isFlowpage={isFlowpage}
    >
      {props.children}
    </OptimizelyProvider>
  )
}
//
