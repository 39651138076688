// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file plans/v1/plans.proto (package plans.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { Message, proto3, protoInt64, Timestamp } from '@bufbuild/protobuf'

/**
 * Plan represents the plan or tier ex: Free Pro Enterprise
 *
 * @generated from message plans.v1.Plan
 */
export class Plan extends Message<Plan> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string name = 2;
   */
  name = ''

  /**
   * @generated from field: string description = 3;
   */
  description = ''

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 5;
   */
  updatedAt?: Timestamp

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 6;
   */
  deletedAt?: Timestamp

  /**
   * @generated from field: repeated plans.v1.Profile profiles = 8;
   */
  profiles: Profile[] = []

  constructor(data?: PartialMessage<Plan>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.Plan'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 5, name: 'updated_at', kind: 'message', T: Timestamp },
    { no: 6, name: 'deleted_at', kind: 'message', T: Timestamp, opt: true },
    { no: 8, name: 'profiles', kind: 'message', T: Profile, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Plan {
    return new Plan().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Plan {
    return new Plan().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Plan {
    return new Plan().fromJsonString(jsonString, options)
  }

  static equals(
    a: Plan | PlainMessage<Plan> | undefined,
    b: Plan | PlainMessage<Plan> | undefined
  ): boolean {
    return proto3.util.equals(Plan, a, b)
  }
}

/**
 * GetPlanRequest is the request object to get a plan by id
 *
 * @generated from message plans.v1.GetPlanRequest
 */
export class GetPlanRequest extends Message<GetPlanRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<GetPlanRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.GetPlanRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPlanRequest {
    return new GetPlanRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPlanRequest {
    return new GetPlanRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPlanRequest {
    return new GetPlanRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetPlanRequest | PlainMessage<GetPlanRequest> | undefined,
    b: GetPlanRequest | PlainMessage<GetPlanRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetPlanRequest, a, b)
  }
}

/**
 * @generated from message plans.v1.GetPlanResponse
 */
export class GetPlanResponse extends Message<GetPlanResponse> {
  /**
   * @generated from field: plans.v1.Plan plan = 1;
   */
  plan?: Plan

  constructor(data?: PartialMessage<GetPlanResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.GetPlanResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'plan', kind: 'message', T: Plan }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPlanResponse {
    return new GetPlanResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPlanResponse {
    return new GetPlanResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPlanResponse {
    return new GetPlanResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetPlanResponse | PlainMessage<GetPlanResponse> | undefined,
    b: GetPlanResponse | PlainMessage<GetPlanResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetPlanResponse, a, b)
  }
}

/**
 * CreatePlanResponse is the object holding a plan that is to be created
 *
 * @generated from message plans.v1.CreatePlanRequest
 */
export class CreatePlanRequest extends Message<CreatePlanRequest> {
  /**
   * @generated from field: plans.v1.Plan plan = 1;
   */
  plan?: Plan

  constructor(data?: PartialMessage<CreatePlanRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.CreatePlanRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'plan', kind: 'message', T: Plan }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePlanRequest {
    return new CreatePlanRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePlanRequest {
    return new CreatePlanRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatePlanRequest {
    return new CreatePlanRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreatePlanRequest | PlainMessage<CreatePlanRequest> | undefined,
    b: CreatePlanRequest | PlainMessage<CreatePlanRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreatePlanRequest, a, b)
  }
}

/**
 * CreatePlanResponse is the response to the create plan request sending back the id of the created
 * plan
 *
 * @generated from message plans.v1.CreatePlanResponse
 */
export class CreatePlanResponse extends Message<CreatePlanResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<CreatePlanResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.CreatePlanResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatePlanResponse {
    return new CreatePlanResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatePlanResponse {
    return new CreatePlanResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreatePlanResponse {
    return new CreatePlanResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreatePlanResponse | PlainMessage<CreatePlanResponse> | undefined,
    b: CreatePlanResponse | PlainMessage<CreatePlanResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreatePlanResponse, a, b)
  }
}

/**
 * ListPlansRequest is an empty object, later we can fill it with filtering options
 *
 * @generated from message plans.v1.ListPlansRequest
 */
export class ListPlansRequest extends Message<ListPlansRequest> {
  constructor(data?: PartialMessage<ListPlansRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListPlansRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPlansRequest {
    return new ListPlansRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPlansRequest {
    return new ListPlansRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPlansRequest {
    return new ListPlansRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListPlansRequest | PlainMessage<ListPlansRequest> | undefined,
    b: ListPlansRequest | PlainMessage<ListPlansRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListPlansRequest, a, b)
  }
}

/**
 * ListPlansResponse returns an array of plans
 *
 * @generated from message plans.v1.ListPlansResponse
 */
export class ListPlansResponse extends Message<ListPlansResponse> {
  /**
   * @generated from field: repeated plans.v1.Plan items = 1;
   */
  items: Plan[] = []

  constructor(data?: PartialMessage<ListPlansResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListPlansResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'items', kind: 'message', T: Plan, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPlansResponse {
    return new ListPlansResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPlansResponse {
    return new ListPlansResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPlansResponse {
    return new ListPlansResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListPlansResponse | PlainMessage<ListPlansResponse> | undefined,
    b: ListPlansResponse | PlainMessage<ListPlansResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListPlansResponse, a, b)
  }
}

/**
 * ListPlanFeatureRequest request object to get features by plan
 *
 * @generated from message plans.v1.ListPlanFeaturesRequest
 */
export class ListPlanFeaturesRequest extends Message<ListPlanFeaturesRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<ListPlanFeaturesRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListPlanFeaturesRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListPlanFeaturesRequest {
    return new ListPlanFeaturesRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListPlanFeaturesRequest {
    return new ListPlanFeaturesRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListPlanFeaturesRequest {
    return new ListPlanFeaturesRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListPlanFeaturesRequest | PlainMessage<ListPlanFeaturesRequest> | undefined,
    b: ListPlanFeaturesRequest | PlainMessage<ListPlanFeaturesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListPlanFeaturesRequest, a, b)
  }
}

/**
 * ListPlanFeatureResponse returns all features associated with a plan
 *
 * @generated from message plans.v1.ListPlanFeaturesResponse
 */
export class ListPlanFeaturesResponse extends Message<ListPlanFeaturesResponse> {
  /**
   * @generated from field: repeated plans.v1.Feature features = 1;
   */
  features: Feature[] = []

  constructor(data?: PartialMessage<ListPlanFeaturesResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListPlanFeaturesResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'features', kind: 'message', T: Feature, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListPlanFeaturesResponse {
    return new ListPlanFeaturesResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListPlanFeaturesResponse {
    return new ListPlanFeaturesResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListPlanFeaturesResponse {
    return new ListPlanFeaturesResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListPlanFeaturesResponse | PlainMessage<ListPlanFeaturesResponse> | undefined,
    b: ListPlanFeaturesResponse | PlainMessage<ListPlanFeaturesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListPlanFeaturesResponse, a, b)
  }
}

/**
 * DeletePlanRequest holds an id which is then deleted from the database
 *
 * @generated from message plans.v1.DeletePlanRequest
 */
export class DeletePlanRequest extends Message<DeletePlanRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<DeletePlanRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.DeletePlanRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePlanRequest {
    return new DeletePlanRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePlanRequest {
    return new DeletePlanRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletePlanRequest {
    return new DeletePlanRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeletePlanRequest | PlainMessage<DeletePlanRequest> | undefined,
    b: DeletePlanRequest | PlainMessage<DeletePlanRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeletePlanRequest, a, b)
  }
}

/**
 * DeletePlanResponse <haven't figured out what to put here yet>
 *
 * @generated from message plans.v1.DeletePlanResponse
 */
export class DeletePlanResponse extends Message<DeletePlanResponse> {
  constructor(data?: PartialMessage<DeletePlanResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.DeletePlanResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePlanResponse {
    return new DeletePlanResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePlanResponse {
    return new DeletePlanResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeletePlanResponse {
    return new DeletePlanResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeletePlanResponse | PlainMessage<DeletePlanResponse> | undefined,
    b: DeletePlanResponse | PlainMessage<DeletePlanResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeletePlanResponse, a, b)
  }
}

/**
 * Feature represents a features ie codes, analytics, api, pages etc
 *
 * @generated from message plans.v1.Feature
 */
export class Feature extends Message<Feature> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string name = 2;
   */
  name = ''

  /**
   * @generated from field: string description = 3;
   */
  description = ''

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 5;
   */
  updatedAt?: Timestamp

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 6;
   */
  deletedAt?: Timestamp

  /**
   * @generated from field: repeated plans.v1.Config configs = 7;
   */
  configs: Config[] = []

  constructor(data?: PartialMessage<Feature>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.Feature'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 5, name: 'updated_at', kind: 'message', T: Timestamp },
    { no: 6, name: 'deleted_at', kind: 'message', T: Timestamp, opt: true },
    { no: 7, name: 'configs', kind: 'message', T: Config, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Feature {
    return new Feature().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Feature {
    return new Feature().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Feature {
    return new Feature().fromJsonString(jsonString, options)
  }

  static equals(
    a: Feature | PlainMessage<Feature> | undefined,
    b: Feature | PlainMessage<Feature> | undefined
  ): boolean {
    return proto3.util.equals(Feature, a, b)
  }
}

/**
 * GetFeatureRquest
 *
 * @generated from message plans.v1.GetFeatureRequest
 */
export class GetFeatureRequest extends Message<GetFeatureRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<GetFeatureRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.GetFeatureRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFeatureRequest {
    return new GetFeatureRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFeatureRequest {
    return new GetFeatureRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFeatureRequest {
    return new GetFeatureRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetFeatureRequest | PlainMessage<GetFeatureRequest> | undefined,
    b: GetFeatureRequest | PlainMessage<GetFeatureRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetFeatureRequest, a, b)
  }
}

/**
 * GetFeatureResponse
 *
 * @generated from message plans.v1.GetFeatureResponse
 */
export class GetFeatureResponse extends Message<GetFeatureResponse> {
  /**
   * @generated from field: plans.v1.Feature feature = 1;
   */
  feature?: Feature

  constructor(data?: PartialMessage<GetFeatureResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.GetFeatureResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'feature', kind: 'message', T: Feature }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFeatureResponse {
    return new GetFeatureResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFeatureResponse {
    return new GetFeatureResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetFeatureResponse {
    return new GetFeatureResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetFeatureResponse | PlainMessage<GetFeatureResponse> | undefined,
    b: GetFeatureResponse | PlainMessage<GetFeatureResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetFeatureResponse, a, b)
  }
}

/**
 * ListFeaturesRequest
 *
 * @generated from message plans.v1.ListFeaturesRequest
 */
export class ListFeaturesRequest extends Message<ListFeaturesRequest> {
  constructor(data?: PartialMessage<ListFeaturesRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListFeaturesRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFeaturesRequest {
    return new ListFeaturesRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFeaturesRequest {
    return new ListFeaturesRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListFeaturesRequest {
    return new ListFeaturesRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListFeaturesRequest | PlainMessage<ListFeaturesRequest> | undefined,
    b: ListFeaturesRequest | PlainMessage<ListFeaturesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListFeaturesRequest, a, b)
  }
}

/**
 * ListFeaturesResponse
 *
 * @generated from message plans.v1.ListFeaturesResponse
 */
export class ListFeaturesResponse extends Message<ListFeaturesResponse> {
  /**
   * @generated from field: repeated plans.v1.Feature items = 1;
   */
  items: Feature[] = []

  constructor(data?: PartialMessage<ListFeaturesResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListFeaturesResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'items', kind: 'message', T: Feature, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFeaturesResponse {
    return new ListFeaturesResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFeaturesResponse {
    return new ListFeaturesResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListFeaturesResponse {
    return new ListFeaturesResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListFeaturesResponse | PlainMessage<ListFeaturesResponse> | undefined,
    b: ListFeaturesResponse | PlainMessage<ListFeaturesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListFeaturesResponse, a, b)
  }
}

/**
 * DeleteFeaturesRequest
 *
 * @generated from message plans.v1.DeleteFeatureRequest
 */
export class DeleteFeatureRequest extends Message<DeleteFeatureRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<DeleteFeatureRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.DeleteFeatureRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteFeatureRequest {
    return new DeleteFeatureRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteFeatureRequest {
    return new DeleteFeatureRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteFeatureRequest {
    return new DeleteFeatureRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteFeatureRequest | PlainMessage<DeleteFeatureRequest> | undefined,
    b: DeleteFeatureRequest | PlainMessage<DeleteFeatureRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteFeatureRequest, a, b)
  }
}

/**
 * DeleteFeaturesResponse
 *
 * @generated from message plans.v1.DeleteFeatureResponse
 */
export class DeleteFeatureResponse extends Message<DeleteFeatureResponse> {
  constructor(data?: PartialMessage<DeleteFeatureResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.DeleteFeatureResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteFeatureResponse {
    return new DeleteFeatureResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteFeatureResponse {
    return new DeleteFeatureResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteFeatureResponse {
    return new DeleteFeatureResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteFeatureResponse | PlainMessage<DeleteFeatureResponse> | undefined,
    b: DeleteFeatureResponse | PlainMessage<DeleteFeatureResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteFeatureResponse, a, b)
  }
}

/**
 * @generated from message plans.v1.CreateFeatureResponse
 */
export class CreateFeatureResponse extends Message<CreateFeatureResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string name = 2;
   */
  name = ''

  constructor(data?: PartialMessage<CreateFeatureResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.CreateFeatureResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateFeatureResponse {
    return new CreateFeatureResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFeatureResponse {
    return new CreateFeatureResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateFeatureResponse {
    return new CreateFeatureResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateFeatureResponse | PlainMessage<CreateFeatureResponse> | undefined,
    b: CreateFeatureResponse | PlainMessage<CreateFeatureResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateFeatureResponse, a, b)
  }
}

/**
 * @generated from message plans.v1.CreateFeatureRequest
 */
export class CreateFeatureRequest extends Message<CreateFeatureRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string name = 2;
   */
  name = ''

  /**
   * @generated from field: string description = 3;
   */
  description = ''

  /**
   * @generated from field: repeated plans.v1.Config configs = 4;
   */
  configs: Config[] = []

  constructor(data?: PartialMessage<CreateFeatureRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.CreateFeatureRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'configs', kind: 'message', T: Config, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFeatureRequest {
    return new CreateFeatureRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFeatureRequest {
    return new CreateFeatureRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateFeatureRequest {
    return new CreateFeatureRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateFeatureRequest | PlainMessage<CreateFeatureRequest> | undefined,
    b: CreateFeatureRequest | PlainMessage<CreateFeatureRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateFeatureRequest, a, b)
  }
}

/**
 * Config
 *
 * @generated from message plans.v1.Config
 */
export class Config extends Message<Config> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string name = 2;
   */
  name = ''

  /**
   * @generated from field: string description = 3;
   */
  description = ''

  /**
   * @generated from field: string value_type = 4;
   */
  valueType = ''

  /**
   * @generated from field: string calculation_type = 5;
   */
  calculationType = ''

  /**
   * @generated from field: string feature_id = 6;
   */
  featureId = ''

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 7;
   */
  createdAt?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 8;
   */
  updatedAt?: Timestamp

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 9;
   */
  deletedAt?: Timestamp

  constructor(data?: PartialMessage<Config>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.Config'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'value_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'calculation_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'feature_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 8, name: 'updated_at', kind: 'message', T: Timestamp },
    { no: 9, name: 'deleted_at', kind: 'message', T: Timestamp, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Config {
    return new Config().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Config {
    return new Config().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Config {
    return new Config().fromJsonString(jsonString, options)
  }

  static equals(
    a: Config | PlainMessage<Config> | undefined,
    b: Config | PlainMessage<Config> | undefined
  ): boolean {
    return proto3.util.equals(Config, a, b)
  }
}

/**
 * GetConfigRequest
 *
 * @generated from message plans.v1.GetConfigRequest
 */
export class GetConfigRequest extends Message<GetConfigRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<GetConfigRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.GetConfigRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConfigRequest {
    return new GetConfigRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConfigRequest {
    return new GetConfigRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConfigRequest {
    return new GetConfigRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetConfigRequest | PlainMessage<GetConfigRequest> | undefined,
    b: GetConfigRequest | PlainMessage<GetConfigRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetConfigRequest, a, b)
  }
}

/**
 * GetConfigResponse
 *
 * @generated from message plans.v1.GetConfigResponse
 */
export class GetConfigResponse extends Message<GetConfigResponse> {
  /**
   * @generated from field: plans.v1.Config config = 1;
   */
  config?: Config

  constructor(data?: PartialMessage<GetConfigResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.GetConfigResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'config', kind: 'message', T: Config }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConfigResponse {
    return new GetConfigResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConfigResponse {
    return new GetConfigResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConfigResponse {
    return new GetConfigResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetConfigResponse | PlainMessage<GetConfigResponse> | undefined,
    b: GetConfigResponse | PlainMessage<GetConfigResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetConfigResponse, a, b)
  }
}

/**
 * CreateConfigRequest
 *
 * @generated from message plans.v1.CreateConfigRequest
 */
export class CreateConfigRequest extends Message<CreateConfigRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string name = 2;
   */
  name = ''

  /**
   * @generated from field: string value_type = 3;
   */
  valueType = ''

  /**
   * @generated from field: string calculation_type = 4;
   */
  calculationType = ''

  /**
   * @generated from field: string description = 5;
   */
  description = ''

  /**
   * @generated from field: string feature_id = 6;
   */
  featureId = ''

  constructor(data?: PartialMessage<CreateConfigRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.CreateConfigRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'value_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'calculation_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'feature_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateConfigRequest {
    return new CreateConfigRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateConfigRequest {
    return new CreateConfigRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateConfigRequest {
    return new CreateConfigRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateConfigRequest | PlainMessage<CreateConfigRequest> | undefined,
    b: CreateConfigRequest | PlainMessage<CreateConfigRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateConfigRequest, a, b)
  }
}

/**
 * CreateConfigResponse
 *
 * @generated from message plans.v1.CreateConfigResponse
 */
export class CreateConfigResponse extends Message<CreateConfigResponse> {
  /**
   * @generated from field: plans.v1.Config config = 1;
   */
  config?: Config

  constructor(data?: PartialMessage<CreateConfigResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.CreateConfigResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'config', kind: 'message', T: Config }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateConfigResponse {
    return new CreateConfigResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateConfigResponse {
    return new CreateConfigResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateConfigResponse {
    return new CreateConfigResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateConfigResponse | PlainMessage<CreateConfigResponse> | undefined,
    b: CreateConfigResponse | PlainMessage<CreateConfigResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateConfigResponse, a, b)
  }
}

/**
 * ListConfigsRequest
 *
 * @generated from message plans.v1.ListConfigsRequest
 */
export class ListConfigsRequest extends Message<ListConfigsRequest> {
  constructor(data?: PartialMessage<ListConfigsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListConfigsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListConfigsRequest {
    return new ListConfigsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListConfigsRequest {
    return new ListConfigsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListConfigsRequest {
    return new ListConfigsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListConfigsRequest | PlainMessage<ListConfigsRequest> | undefined,
    b: ListConfigsRequest | PlainMessage<ListConfigsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListConfigsRequest, a, b)
  }
}

/**
 * ListConfigsResponse
 *
 * @generated from message plans.v1.ListConfigsResponse
 */
export class ListConfigsResponse extends Message<ListConfigsResponse> {
  /**
   * @generated from field: repeated plans.v1.Config items = 1;
   */
  items: Config[] = []

  constructor(data?: PartialMessage<ListConfigsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListConfigsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'items', kind: 'message', T: Config, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListConfigsResponse {
    return new ListConfigsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListConfigsResponse {
    return new ListConfigsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListConfigsResponse {
    return new ListConfigsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListConfigsResponse | PlainMessage<ListConfigsResponse> | undefined,
    b: ListConfigsResponse | PlainMessage<ListConfigsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListConfigsResponse, a, b)
  }
}

/**
 * UpdateConfigRequest
 *
 * @generated from message plans.v1.UpdateConfigRequest
 */
export class UpdateConfigRequest extends Message<UpdateConfigRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string name = 2;
   */
  name = ''

  /**
   * @generated from field: optional string description = 3;
   */
  description?: string

  /**
   * @generated from field: optional string value_type = 4;
   */
  valueType?: string

  /**
   * @generated from field: optional string calculation_type = 5;
   */
  calculationType?: string

  /**
   * @generated from field: optional string feature_id = 6;
   */
  featureId?: string

  constructor(data?: PartialMessage<UpdateConfigRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.UpdateConfigRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: 'value_type', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: 'calculation_type', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: 'feature_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateConfigRequest {
    return new UpdateConfigRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateConfigRequest {
    return new UpdateConfigRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateConfigRequest {
    return new UpdateConfigRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateConfigRequest | PlainMessage<UpdateConfigRequest> | undefined,
    b: UpdateConfigRequest | PlainMessage<UpdateConfigRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateConfigRequest, a, b)
  }
}

/**
 * UpdateConfigResponse
 *
 * @generated from message plans.v1.UpdateConfigResponse
 */
export class UpdateConfigResponse extends Message<UpdateConfigResponse> {
  /**
   * @generated from field: plans.v1.Config config = 1;
   */
  config?: Config

  constructor(data?: PartialMessage<UpdateConfigResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.UpdateConfigResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'config', kind: 'message', T: Config }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateConfigResponse {
    return new UpdateConfigResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateConfigResponse {
    return new UpdateConfigResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateConfigResponse {
    return new UpdateConfigResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateConfigResponse | PlainMessage<UpdateConfigResponse> | undefined,
    b: UpdateConfigResponse | PlainMessage<UpdateConfigResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateConfigResponse, a, b)
  }
}

/**
 * DeleteConfigRequest
 *
 * @generated from message plans.v1.DeleteConfigRequest
 */
export class DeleteConfigRequest extends Message<DeleteConfigRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<DeleteConfigRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.DeleteConfigRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteConfigRequest {
    return new DeleteConfigRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteConfigRequest {
    return new DeleteConfigRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteConfigRequest {
    return new DeleteConfigRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteConfigRequest | PlainMessage<DeleteConfigRequest> | undefined,
    b: DeleteConfigRequest | PlainMessage<DeleteConfigRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteConfigRequest, a, b)
  }
}

/**
 * DeleteConfigResponse
 *
 * @generated from message plans.v1.DeleteConfigResponse
 */
export class DeleteConfigResponse extends Message<DeleteConfigResponse> {
  constructor(data?: PartialMessage<DeleteConfigResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.DeleteConfigResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteConfigResponse {
    return new DeleteConfigResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteConfigResponse {
    return new DeleteConfigResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteConfigResponse {
    return new DeleteConfigResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteConfigResponse | PlainMessage<DeleteConfigResponse> | undefined,
    b: DeleteConfigResponse | PlainMessage<DeleteConfigResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteConfigResponse, a, b)
  }
}

/**
 * Profile
 *
 * @generated from message plans.v1.Profile
 */
export class Profile extends Message<Profile> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string name = 2;
   */
  name = ''

  /**
   * @generated from field: string plan_id = 3;
   */
  planId = ''

  /**
   * @generated from field: bool default_profile = 4;
   */
  defaultProfile = false

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 7;
   */
  deletedAt?: Timestamp

  constructor(data?: PartialMessage<Profile>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.Profile'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'plan_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'default_profile', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 6, name: 'updated_at', kind: 'message', T: Timestamp },
    { no: 7, name: 'deleted_at', kind: 'message', T: Timestamp, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Profile {
    return new Profile().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Profile {
    return new Profile().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Profile {
    return new Profile().fromJsonString(jsonString, options)
  }

  static equals(
    a: Profile | PlainMessage<Profile> | undefined,
    b: Profile | PlainMessage<Profile> | undefined
  ): boolean {
    return proto3.util.equals(Profile, a, b)
  }
}

/**
 * GetProfileRequest
 *
 * @generated from message plans.v1.GetProfileRequest
 */
export class GetProfileRequest extends Message<GetProfileRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<GetProfileRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.GetProfileRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProfileRequest {
    return new GetProfileRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProfileRequest {
    return new GetProfileRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProfileRequest {
    return new GetProfileRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetProfileRequest | PlainMessage<GetProfileRequest> | undefined,
    b: GetProfileRequest | PlainMessage<GetProfileRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetProfileRequest, a, b)
  }
}

/**
 * GetProfileResponse
 *
 * @generated from message plans.v1.GetProfileResponse
 */
export class GetProfileResponse extends Message<GetProfileResponse> {
  /**
   * @generated from field: plans.v1.Profile profile = 1;
   */
  profile?: Profile

  constructor(data?: PartialMessage<GetProfileResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.GetProfileResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile', kind: 'message', T: Profile }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProfileResponse {
    return new GetProfileResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProfileResponse {
    return new GetProfileResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetProfileResponse {
    return new GetProfileResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetProfileResponse | PlainMessage<GetProfileResponse> | undefined,
    b: GetProfileResponse | PlainMessage<GetProfileResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetProfileResponse, a, b)
  }
}

/**
 * CreateProfileRequest
 *
 * @generated from message plans.v1.CreateProfileRequest
 */
export class CreateProfileRequest extends Message<CreateProfileRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string name = 2;
   */
  name = ''

  /**
   * @generated from field: string plan_id = 3;
   */
  planId = ''

  /**
   * @generated from field: bool default_profile = 4;
   */
  defaultProfile = false

  constructor(data?: PartialMessage<CreateProfileRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.CreateProfileRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'plan_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'default_profile', kind: 'scalar', T: 8 /* ScalarType.BOOL */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProfileRequest {
    return new CreateProfileRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProfileRequest {
    return new CreateProfileRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProfileRequest {
    return new CreateProfileRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateProfileRequest | PlainMessage<CreateProfileRequest> | undefined,
    b: CreateProfileRequest | PlainMessage<CreateProfileRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateProfileRequest, a, b)
  }
}

/**
 * CreateProfileResponse
 *
 * @generated from message plans.v1.CreateProfileResponse
 */
export class CreateProfileResponse extends Message<CreateProfileResponse> {
  /**
   * @generated from field: plans.v1.Profile profile = 1;
   */
  profile?: Profile

  constructor(data?: PartialMessage<CreateProfileResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.CreateProfileResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile', kind: 'message', T: Profile }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateProfileResponse {
    return new CreateProfileResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProfileResponse {
    return new CreateProfileResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProfileResponse {
    return new CreateProfileResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateProfileResponse | PlainMessage<CreateProfileResponse> | undefined,
    b: CreateProfileResponse | PlainMessage<CreateProfileResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateProfileResponse, a, b)
  }
}

/**
 * UpdateProfileRequest
 *
 * @generated from message plans.v1.UpdateProfileRequest
 */
export class UpdateProfileRequest extends Message<UpdateProfileRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string name = 2;
   */
  name = ''

  /**
   * @generated from field: string plan_id = 3;
   */
  planId = ''

  /**
   * @generated from field: bool default_profile = 4;
   */
  defaultProfile = false

  constructor(data?: PartialMessage<UpdateProfileRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.UpdateProfileRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'plan_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'default_profile', kind: 'scalar', T: 8 /* ScalarType.BOOL */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProfileRequest {
    return new UpdateProfileRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProfileRequest {
    return new UpdateProfileRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileRequest {
    return new UpdateProfileRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateProfileRequest | PlainMessage<UpdateProfileRequest> | undefined,
    b: UpdateProfileRequest | PlainMessage<UpdateProfileRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProfileRequest, a, b)
  }
}

/**
 * UpdateProfileResponse
 *
 * @generated from message plans.v1.UpdateProfileResponse
 */
export class UpdateProfileResponse extends Message<UpdateProfileResponse> {
  /**
   * @generated from field: plans.v1.Profile profile = 1;
   */
  profile?: Profile

  constructor(data?: PartialMessage<UpdateProfileResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.UpdateProfileResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile', kind: 'message', T: Profile }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProfileResponse {
    return new UpdateProfileResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProfileResponse {
    return new UpdateProfileResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileResponse {
    return new UpdateProfileResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateProfileResponse | PlainMessage<UpdateProfileResponse> | undefined,
    b: UpdateProfileResponse | PlainMessage<UpdateProfileResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProfileResponse, a, b)
  }
}

/**
 * ListProfilesRequest
 *
 * @generated from message plans.v1.ListProfilesRequest
 */
export class ListProfilesRequest extends Message<ListProfilesRequest> {
  constructor(data?: PartialMessage<ListProfilesRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListProfilesRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProfilesRequest {
    return new ListProfilesRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProfilesRequest {
    return new ListProfilesRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfilesRequest {
    return new ListProfilesRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListProfilesRequest | PlainMessage<ListProfilesRequest> | undefined,
    b: ListProfilesRequest | PlainMessage<ListProfilesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListProfilesRequest, a, b)
  }
}

/**
 * ListProfilesResponse
 *
 * @generated from message plans.v1.ListProfilesResponse
 */
export class ListProfilesResponse extends Message<ListProfilesResponse> {
  /**
   * @generated from field: repeated plans.v1.Profile items = 1;
   */
  items: Profile[] = []

  constructor(data?: PartialMessage<ListProfilesResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListProfilesResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'items', kind: 'message', T: Profile, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProfilesResponse {
    return new ListProfilesResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProfilesResponse {
    return new ListProfilesResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfilesResponse {
    return new ListProfilesResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListProfilesResponse | PlainMessage<ListProfilesResponse> | undefined,
    b: ListProfilesResponse | PlainMessage<ListProfilesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListProfilesResponse, a, b)
  }
}

/**
 * DeleteProfileRequest
 *
 * @generated from message plans.v1.DeleteProfileRequest
 */
export class DeleteProfileRequest extends Message<DeleteProfileRequest> {
  /**
   * @generated from field: string profile_id = 1;
   */
  profileId = ''

  /**
   * @generated from field: string plan_id = 2;
   */
  planId = ''

  constructor(data?: PartialMessage<DeleteProfileRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.DeleteProfileRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'plan_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteProfileRequest {
    return new DeleteProfileRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteProfileRequest {
    return new DeleteProfileRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteProfileRequest {
    return new DeleteProfileRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteProfileRequest | PlainMessage<DeleteProfileRequest> | undefined,
    b: DeleteProfileRequest | PlainMessage<DeleteProfileRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteProfileRequest, a, b)
  }
}

/**
 * DeleteProfileResponse
 *
 * @generated from message plans.v1.DeleteProfileResponse
 */
export class DeleteProfileResponse extends Message<DeleteProfileResponse> {
  constructor(data?: PartialMessage<DeleteProfileResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.DeleteProfileResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteProfileResponse {
    return new DeleteProfileResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteProfileResponse {
    return new DeleteProfileResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteProfileResponse {
    return new DeleteProfileResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteProfileResponse | PlainMessage<DeleteProfileResponse> | undefined,
    b: DeleteProfileResponse | PlainMessage<DeleteProfileResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteProfileResponse, a, b)
  }
}

/**
 * Config
 *
 * @generated from message plans.v1.ProfileConfig
 */
export class ProfileConfig extends Message<ProfileConfig> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string profile_id = 2;
   */
  profileId = ''

  /**
   * @generated from field: string config_id = 3;
   */
  configId = ''

  /**
   * @generated from field: string config_value = 4;
   */
  configValue = ''

  /**
   * @generated from field: string feature_id = 5;
   */
  featureId = ''

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 7;
   */
  updatedAt?: Timestamp

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 8;
   */
  deletedAt?: Timestamp

  constructor(data?: PartialMessage<ProfileConfig>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ProfileConfig'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'profile_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'config_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'config_value', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'feature_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 7, name: 'updated_at', kind: 'message', T: Timestamp },
    { no: 8, name: 'deleted_at', kind: 'message', T: Timestamp, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileConfig {
    return new ProfileConfig().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileConfig {
    return new ProfileConfig().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileConfig {
    return new ProfileConfig().fromJsonString(jsonString, options)
  }

  static equals(
    a: ProfileConfig | PlainMessage<ProfileConfig> | undefined,
    b: ProfileConfig | PlainMessage<ProfileConfig> | undefined
  ): boolean {
    return proto3.util.equals(ProfileConfig, a, b)
  }
}

/**
 * @generated from message plans.v1.UpdateProfileConfigRequest
 */
export class UpdateProfileConfigRequest extends Message<UpdateProfileConfigRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string profile_id = 2;
   */
  profileId = ''

  /**
   * @generated from field: string config_id = 3;
   */
  configId = ''

  /**
   * @generated from field: string config_value = 4;
   */
  configValue = ''

  /**
   * @generated from field: optional string feature_id = 5;
   */
  featureId?: string

  constructor(data?: PartialMessage<UpdateProfileConfigRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.UpdateProfileConfigRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'profile_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'config_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'config_value', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'feature_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProfileConfigRequest {
    return new UpdateProfileConfigRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileConfigRequest {
    return new UpdateProfileConfigRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileConfigRequest {
    return new UpdateProfileConfigRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateProfileConfigRequest | PlainMessage<UpdateProfileConfigRequest> | undefined,
    b: UpdateProfileConfigRequest | PlainMessage<UpdateProfileConfigRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProfileConfigRequest, a, b)
  }
}

/**
 * @generated from message plans.v1.UpdateProfileConfigResponse
 */
export class UpdateProfileConfigResponse extends Message<UpdateProfileConfigResponse> {
  /**
   * @generated from field: plans.v1.ProfileConfig profile_config = 1;
   */
  profileConfig?: ProfileConfig

  constructor(data?: PartialMessage<UpdateProfileConfigResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.UpdateProfileConfigResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_config', kind: 'message', T: ProfileConfig }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProfileConfigResponse {
    return new UpdateProfileConfigResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileConfigResponse {
    return new UpdateProfileConfigResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileConfigResponse {
    return new UpdateProfileConfigResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateProfileConfigResponse | PlainMessage<UpdateProfileConfigResponse> | undefined,
    b: UpdateProfileConfigResponse | PlainMessage<UpdateProfileConfigResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProfileConfigResponse, a, b)
  }
}

/**
 * ListProfileConfigsRequest
 *
 * @generated from message plans.v1.ListProfileConfigsRequest
 */
export class ListProfileConfigsRequest extends Message<ListProfileConfigsRequest> {
  /**
   * @generated from field: string plan_id = 1;
   */
  planId = ''

  /**
   * @generated from field: string profile_id = 2;
   */
  profileId = ''

  constructor(data?: PartialMessage<ListProfileConfigsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListProfileConfigsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'plan_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'profile_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListProfileConfigsRequest {
    return new ListProfileConfigsRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListProfileConfigsRequest {
    return new ListProfileConfigsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfileConfigsRequest {
    return new ListProfileConfigsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListProfileConfigsRequest | PlainMessage<ListProfileConfigsRequest> | undefined,
    b: ListProfileConfigsRequest | PlainMessage<ListProfileConfigsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListProfileConfigsRequest, a, b)
  }
}

/**
 * ListProfileConfigsResponse
 *
 * @generated from message plans.v1.ListProfileConfigsResponse
 */
export class ListProfileConfigsResponse extends Message<ListProfileConfigsResponse> {
  /**
   * @generated from field: repeated plans.v1.ProfileConfig items = 1;
   */
  items: ProfileConfig[] = []

  constructor(data?: PartialMessage<ListProfileConfigsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListProfileConfigsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'items', kind: 'message', T: ProfileConfig, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListProfileConfigsResponse {
    return new ListProfileConfigsResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListProfileConfigsResponse {
    return new ListProfileConfigsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfileConfigsResponse {
    return new ListProfileConfigsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListProfileConfigsResponse | PlainMessage<ListProfileConfigsResponse> | undefined,
    b: ListProfileConfigsResponse | PlainMessage<ListProfileConfigsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListProfileConfigsResponse, a, b)
  }
}

/**
 * ProfileOverride allows individual overrides of config values for a given profile on a per
 * org basis
 *
 * @generated from message plans.v1.ProfileOverride
 */
export class ProfileOverride extends Message<ProfileOverride> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string config_id = 2;
   */
  configId = ''

  /**
   * @generated from field: string org_id = 3;
   */
  orgId = ''

  /**
   * @generated from field: string config_value = 4;
   */
  configValue = ''

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 7;
   */
  deletedAt?: Timestamp

  constructor(data?: PartialMessage<ProfileOverride>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ProfileOverride'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'config_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'config_value', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 6, name: 'updated_at', kind: 'message', T: Timestamp },
    { no: 7, name: 'deleted_at', kind: 'message', T: Timestamp, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileOverride {
    return new ProfileOverride().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileOverride {
    return new ProfileOverride().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileOverride {
    return new ProfileOverride().fromJsonString(jsonString, options)
  }

  static equals(
    a: ProfileOverride | PlainMessage<ProfileOverride> | undefined,
    b: ProfileOverride | PlainMessage<ProfileOverride> | undefined
  ): boolean {
    return proto3.util.equals(ProfileOverride, a, b)
  }
}

/**
 * @generated from message plans.v1.CreateProfileOverrideRequest
 */
export class CreateProfileOverrideRequest extends Message<CreateProfileOverrideRequest> {
  /**
   * @generated from field: string config_id = 1;
   */
  configId = ''

  /**
   * @generated from field: string org_id = 2;
   */
  orgId = ''

  /**
   * @generated from field: string config_value = 3;
   */
  configValue = ''

  constructor(data?: PartialMessage<CreateProfileOverrideRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.CreateProfileOverrideRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'config_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'config_value', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateProfileOverrideRequest {
    return new CreateProfileOverrideRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateProfileOverrideRequest {
    return new CreateProfileOverrideRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProfileOverrideRequest {
    return new CreateProfileOverrideRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateProfileOverrideRequest | PlainMessage<CreateProfileOverrideRequest> | undefined,
    b: CreateProfileOverrideRequest | PlainMessage<CreateProfileOverrideRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateProfileOverrideRequest, a, b)
  }
}

/**
 * @generated from message plans.v1.CreateProfileOverrideResponse
 */
export class CreateProfileOverrideResponse extends Message<CreateProfileOverrideResponse> {
  /**
   * @generated from field: plans.v1.ProfileOverride profile_override = 1;
   */
  profileOverride?: ProfileOverride

  constructor(data?: PartialMessage<CreateProfileOverrideResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.CreateProfileOverrideResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_override', kind: 'message', T: ProfileOverride }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateProfileOverrideResponse {
    return new CreateProfileOverrideResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateProfileOverrideResponse {
    return new CreateProfileOverrideResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProfileOverrideResponse {
    return new CreateProfileOverrideResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateProfileOverrideResponse | PlainMessage<CreateProfileOverrideResponse> | undefined,
    b: CreateProfileOverrideResponse | PlainMessage<CreateProfileOverrideResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateProfileOverrideResponse, a, b)
  }
}

/**
 * @generated from message plans.v1.UpdateProfileOverrideRequest
 */
export class UpdateProfileOverrideRequest extends Message<UpdateProfileOverrideRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string org_id = 2;
   */
  orgId = ''

  /**
   * @generated from field: string config_value = 3;
   */
  configValue = ''

  constructor(data?: PartialMessage<UpdateProfileOverrideRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.UpdateProfileOverrideRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'config_value', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProfileOverrideRequest {
    return new UpdateProfileOverrideRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileOverrideRequest {
    return new UpdateProfileOverrideRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileOverrideRequest {
    return new UpdateProfileOverrideRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateProfileOverrideRequest | PlainMessage<UpdateProfileOverrideRequest> | undefined,
    b: UpdateProfileOverrideRequest | PlainMessage<UpdateProfileOverrideRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProfileOverrideRequest, a, b)
  }
}

/**
 * @generated from message plans.v1.UpdateProfileOverrideResponse
 */
export class UpdateProfileOverrideResponse extends Message<UpdateProfileOverrideResponse> {
  /**
   * @generated from field: plans.v1.ProfileOverride profile_override = 1;
   */
  profileOverride?: ProfileOverride

  constructor(data?: PartialMessage<UpdateProfileOverrideResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.UpdateProfileOverrideResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_override', kind: 'message', T: ProfileOverride }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProfileOverrideResponse {
    return new UpdateProfileOverrideResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileOverrideResponse {
    return new UpdateProfileOverrideResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileOverrideResponse {
    return new UpdateProfileOverrideResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateProfileOverrideResponse | PlainMessage<UpdateProfileOverrideResponse> | undefined,
    b: UpdateProfileOverrideResponse | PlainMessage<UpdateProfileOverrideResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProfileOverrideResponse, a, b)
  }
}

/**
 * DeleteProfileOverrideRequest
 *
 * @generated from message plans.v1.DeleteProfileOverrideRequest
 */
export class DeleteProfileOverrideRequest extends Message<DeleteProfileOverrideRequest> {
  /**
   * @generated from field: string profile_override_id = 1;
   */
  profileOverrideId = ''

  constructor(data?: PartialMessage<DeleteProfileOverrideRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.DeleteProfileOverrideRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_override_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteProfileOverrideRequest {
    return new DeleteProfileOverrideRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteProfileOverrideRequest {
    return new DeleteProfileOverrideRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteProfileOverrideRequest {
    return new DeleteProfileOverrideRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteProfileOverrideRequest | PlainMessage<DeleteProfileOverrideRequest> | undefined,
    b: DeleteProfileOverrideRequest | PlainMessage<DeleteProfileOverrideRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteProfileOverrideRequest, a, b)
  }
}

/**
 * DeleteProfileOverrideResponse
 *
 * @generated from message plans.v1.DeleteProfileOverrideResponse
 */
export class DeleteProfileOverrideResponse extends Message<DeleteProfileOverrideResponse> {
  constructor(data?: PartialMessage<DeleteProfileOverrideResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.DeleteProfileOverrideResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteProfileOverrideResponse {
    return new DeleteProfileOverrideResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteProfileOverrideResponse {
    return new DeleteProfileOverrideResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteProfileOverrideResponse {
    return new DeleteProfileOverrideResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteProfileOverrideResponse | PlainMessage<DeleteProfileOverrideResponse> | undefined,
    b: DeleteProfileOverrideResponse | PlainMessage<DeleteProfileOverrideResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteProfileOverrideResponse, a, b)
  }
}

/**
 * ListProfileOverridesRequest
 *
 * @generated from message plans.v1.ListProfileOverridesRequest
 */
export class ListProfileOverridesRequest extends Message<ListProfileOverridesRequest> {
  /**
   * @generated from field: string org_id = 1;
   */
  orgId = ''

  constructor(data?: PartialMessage<ListProfileOverridesRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListProfileOverridesRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListProfileOverridesRequest {
    return new ListProfileOverridesRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListProfileOverridesRequest {
    return new ListProfileOverridesRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfileOverridesRequest {
    return new ListProfileOverridesRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListProfileOverridesRequest | PlainMessage<ListProfileOverridesRequest> | undefined,
    b: ListProfileOverridesRequest | PlainMessage<ListProfileOverridesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListProfileOverridesRequest, a, b)
  }
}

/**
 * ListProfileOverridesResponse
 *
 * @generated from message plans.v1.ListProfileOverridesResponse
 */
export class ListProfileOverridesResponse extends Message<ListProfileOverridesResponse> {
  /**
   * @generated from field: repeated plans.v1.ProfileOverride items = 1;
   */
  items: ProfileOverride[] = []

  constructor(data?: PartialMessage<ListProfileOverridesResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListProfileOverridesResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'items', kind: 'message', T: ProfileOverride, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListProfileOverridesResponse {
    return new ListProfileOverridesResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListProfileOverridesResponse {
    return new ListProfileOverridesResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfileOverridesResponse {
    return new ListProfileOverridesResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListProfileOverridesResponse | PlainMessage<ListProfileOverridesResponse> | undefined,
    b: ListProfileOverridesResponse | PlainMessage<ListProfileOverridesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListProfileOverridesResponse, a, b)
  }
}

/**
 * FeatureOverride
 *
 * @generated from message plans.v1.FeatureOverride
 */
export class FeatureOverride extends Message<FeatureOverride> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string feature_id = 2;
   */
  featureId = ''

  /**
   * @generated from field: string org_id = 3;
   */
  orgId = ''

  /**
   * @generated from field: bool enabled = 4;
   */
  enabled = false

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 7;
   */
  deletedAt?: Timestamp

  constructor(data?: PartialMessage<FeatureOverride>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.FeatureOverride'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'feature_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'enabled', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 6, name: 'updated_at', kind: 'message', T: Timestamp },
    { no: 7, name: 'deleted_at', kind: 'message', T: Timestamp, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FeatureOverride {
    return new FeatureOverride().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FeatureOverride {
    return new FeatureOverride().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FeatureOverride {
    return new FeatureOverride().fromJsonString(jsonString, options)
  }

  static equals(
    a: FeatureOverride | PlainMessage<FeatureOverride> | undefined,
    b: FeatureOverride | PlainMessage<FeatureOverride> | undefined
  ): boolean {
    return proto3.util.equals(FeatureOverride, a, b)
  }
}

/**
 * ListFeatureOverridesRequest
 *
 * @generated from message plans.v1.ListFeatureOverridesRequest
 */
export class ListFeatureOverridesRequest extends Message<ListFeatureOverridesRequest> {
  /**
   * @generated from field: string org_id = 1;
   */
  orgId = ''

  constructor(data?: PartialMessage<ListFeatureOverridesRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListFeatureOverridesRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListFeatureOverridesRequest {
    return new ListFeatureOverridesRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListFeatureOverridesRequest {
    return new ListFeatureOverridesRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListFeatureOverridesRequest {
    return new ListFeatureOverridesRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListFeatureOverridesRequest | PlainMessage<ListFeatureOverridesRequest> | undefined,
    b: ListFeatureOverridesRequest | PlainMessage<ListFeatureOverridesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListFeatureOverridesRequest, a, b)
  }
}

/**
 * ListFeatureOverridesResponse
 *
 * @generated from message plans.v1.ListFeatureOverridesResponse
 */
export class ListFeatureOverridesResponse extends Message<ListFeatureOverridesResponse> {
  /**
   * @generated from field: repeated plans.v1.FeatureOverride items = 1;
   */
  items: FeatureOverride[] = []

  constructor(data?: PartialMessage<ListFeatureOverridesResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListFeatureOverridesResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'items', kind: 'message', T: FeatureOverride, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListFeatureOverridesResponse {
    return new ListFeatureOverridesResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListFeatureOverridesResponse {
    return new ListFeatureOverridesResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListFeatureOverridesResponse {
    return new ListFeatureOverridesResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListFeatureOverridesResponse | PlainMessage<ListFeatureOverridesResponse> | undefined,
    b: ListFeatureOverridesResponse | PlainMessage<ListFeatureOverridesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListFeatureOverridesResponse, a, b)
  }
}

/**
 * UpdateFeatureOverrideResponse
 *
 * @generated from message plans.v1.UpdateFeatureOverrideResponse
 */
export class UpdateFeatureOverrideResponse extends Message<UpdateFeatureOverrideResponse> {
  /**
   * @generated from field: plans.v1.FeatureOverride feature_override = 1;
   */
  featureOverride?: FeatureOverride

  constructor(data?: PartialMessage<UpdateFeatureOverrideResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.UpdateFeatureOverrideResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'feature_override', kind: 'message', T: FeatureOverride }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateFeatureOverrideResponse {
    return new UpdateFeatureOverrideResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateFeatureOverrideResponse {
    return new UpdateFeatureOverrideResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateFeatureOverrideResponse {
    return new UpdateFeatureOverrideResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateFeatureOverrideResponse | PlainMessage<UpdateFeatureOverrideResponse> | undefined,
    b: UpdateFeatureOverrideResponse | PlainMessage<UpdateFeatureOverrideResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateFeatureOverrideResponse, a, b)
  }
}

/**
 * DeleteFeatureOverrideRequest
 *
 * @generated from message plans.v1.DeleteFeatureOverrideRequest
 */
export class DeleteFeatureOverrideRequest extends Message<DeleteFeatureOverrideRequest> {
  /**
   * @generated from field: string org_id = 1;
   */
  orgId = ''

  /**
   * @generated from field: string override_id = 2;
   */
  overrideId = ''

  /**
   * @generated from field: string feature_id = 3;
   */
  featureId = ''

  constructor(data?: PartialMessage<DeleteFeatureOverrideRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.DeleteFeatureOverrideRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'override_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'feature_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteFeatureOverrideRequest {
    return new DeleteFeatureOverrideRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteFeatureOverrideRequest {
    return new DeleteFeatureOverrideRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteFeatureOverrideRequest {
    return new DeleteFeatureOverrideRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteFeatureOverrideRequest | PlainMessage<DeleteFeatureOverrideRequest> | undefined,
    b: DeleteFeatureOverrideRequest | PlainMessage<DeleteFeatureOverrideRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteFeatureOverrideRequest, a, b)
  }
}

/**
 * DeleteFeatureOverrideResponse
 *
 * @generated from message plans.v1.DeleteFeatureOverrideResponse
 */
export class DeleteFeatureOverrideResponse extends Message<DeleteFeatureOverrideResponse> {
  constructor(data?: PartialMessage<DeleteFeatureOverrideResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.DeleteFeatureOverrideResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteFeatureOverrideResponse {
    return new DeleteFeatureOverrideResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteFeatureOverrideResponse {
    return new DeleteFeatureOverrideResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteFeatureOverrideResponse {
    return new DeleteFeatureOverrideResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteFeatureOverrideResponse | PlainMessage<DeleteFeatureOverrideResponse> | undefined,
    b: DeleteFeatureOverrideResponse | PlainMessage<DeleteFeatureOverrideResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteFeatureOverrideResponse, a, b)
  }
}

/**
 * CreateFeatureOverrideRequest
 *
 * @generated from message plans.v1.CreateFeatureOverrideRequest
 */
export class CreateFeatureOverrideRequest extends Message<CreateFeatureOverrideRequest> {
  /**
   * @generated from field: string feature_id = 1;
   */
  featureId = ''

  /**
   * @generated from field: string org_id = 2;
   */
  orgId = ''

  /**
   * @generated from field: bool enabled = 3;
   */
  enabled = false

  constructor(data?: PartialMessage<CreateFeatureOverrideRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.CreateFeatureOverrideRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'feature_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'enabled', kind: 'scalar', T: 8 /* ScalarType.BOOL */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateFeatureOverrideRequest {
    return new CreateFeatureOverrideRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateFeatureOverrideRequest {
    return new CreateFeatureOverrideRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateFeatureOverrideRequest {
    return new CreateFeatureOverrideRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateFeatureOverrideRequest | PlainMessage<CreateFeatureOverrideRequest> | undefined,
    b: CreateFeatureOverrideRequest | PlainMessage<CreateFeatureOverrideRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateFeatureOverrideRequest, a, b)
  }
}

/**
 * CreateFeatureOverrideResponse
 *
 * @generated from message plans.v1.CreateFeatureOverrideResponse
 */
export class CreateFeatureOverrideResponse extends Message<CreateFeatureOverrideResponse> {
  /**
   * @generated from field: plans.v1.FeatureOverride feature_override = 1;
   */
  featureOverride?: FeatureOverride

  constructor(data?: PartialMessage<CreateFeatureOverrideResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.CreateFeatureOverrideResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'feature_override', kind: 'message', T: FeatureOverride }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateFeatureOverrideResponse {
    return new CreateFeatureOverrideResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateFeatureOverrideResponse {
    return new CreateFeatureOverrideResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateFeatureOverrideResponse {
    return new CreateFeatureOverrideResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateFeatureOverrideResponse | PlainMessage<CreateFeatureOverrideResponse> | undefined,
    b: CreateFeatureOverrideResponse | PlainMessage<CreateFeatureOverrideResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateFeatureOverrideResponse, a, b)
  }
}

/**
 * @generated from message plans.v1.Cachable
 */
export class Cachable extends Message<Cachable> {
  /**
   * @generated from field: string config_name = 1;
   */
  configName = ''

  /**
   * @generated from field: string calculation_type = 2;
   */
  calculationType = ''

  /**
   * @generated from field: string value = 3;
   */
  value = ''

  /**
   * @generated from field: int64 current_use = 4;
   */
  currentUse = protoInt64.zero

  constructor(data?: PartialMessage<Cachable>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.Cachable'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'config_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'calculation_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'value', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'current_use', kind: 'scalar', T: 3 /* ScalarType.INT64 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Cachable {
    return new Cachable().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Cachable {
    return new Cachable().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Cachable {
    return new Cachable().fromJsonString(jsonString, options)
  }

  static equals(
    a: Cachable | PlainMessage<Cachable> | undefined,
    b: Cachable | PlainMessage<Cachable> | undefined
  ): boolean {
    return proto3.util.equals(Cachable, a, b)
  }
}

/**
 * @generated from message plans.v1.ListOfCached
 */
export class ListOfCached extends Message<ListOfCached> {
  /**
   * @generated from field: repeated plans.v1.Cachable cached = 1;
   */
  cached: Cachable[] = []

  constructor(data?: PartialMessage<ListOfCached>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListOfCached'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'cached', kind: 'message', T: Cachable, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOfCached {
    return new ListOfCached().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOfCached {
    return new ListOfCached().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOfCached {
    return new ListOfCached().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListOfCached | PlainMessage<ListOfCached> | undefined,
    b: ListOfCached | PlainMessage<ListOfCached> | undefined
  ): boolean {
    return proto3.util.equals(ListOfCached, a, b)
  }
}

/**
 * @generated from message plans.v1.UsageResult
 */
export class UsageResult extends Message<UsageResult> {
  /**
   * @generated from field: map<string, plans.v1.ListOfCached> feature_results = 1;
   */
  featureResults: { [key: string]: ListOfCached } = {}

  constructor(data?: PartialMessage<UsageResult>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.UsageResult'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'feature_results',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: ListOfCached }
    }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsageResult {
    return new UsageResult().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsageResult {
    return new UsageResult().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsageResult {
    return new UsageResult().fromJsonString(jsonString, options)
  }

  static equals(
    a: UsageResult | PlainMessage<UsageResult> | undefined,
    b: UsageResult | PlainMessage<UsageResult> | undefined
  ): boolean {
    return proto3.util.equals(UsageResult, a, b)
  }
}

/**
 * @generated from message plans.v1.GetUsageRequest
 */
export class GetUsageRequest extends Message<GetUsageRequest> {
  /**
   * @generated from field: string org_id = 1;
   */
  orgId = ''

  /**
   * @generated from field: string plan_id = 2;
   */
  planId = ''

  constructor(data?: PartialMessage<GetUsageRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.GetUsageRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'plan_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUsageRequest {
    return new GetUsageRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUsageRequest {
    return new GetUsageRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUsageRequest {
    return new GetUsageRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetUsageRequest | PlainMessage<GetUsageRequest> | undefined,
    b: GetUsageRequest | PlainMessage<GetUsageRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetUsageRequest, a, b)
  }
}

/**
 * @generated from message plans.v1.GetUsageResponse
 */
export class GetUsageResponse extends Message<GetUsageResponse> {
  /**
   * @generated from field: map<string, plans.v1.ListOfCached> feature_usage = 1;
   */
  featureUsage: { [key: string]: ListOfCached } = {}

  constructor(data?: PartialMessage<GetUsageResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.GetUsageResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'feature_usage',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: ListOfCached }
    }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUsageResponse {
    return new GetUsageResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUsageResponse {
    return new GetUsageResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUsageResponse {
    return new GetUsageResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetUsageResponse | PlainMessage<GetUsageResponse> | undefined,
    b: GetUsageResponse | PlainMessage<GetUsageResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetUsageResponse, a, b)
  }
}

/**
 * @generated from message plans.v1.MaterializedConfig
 */
export class MaterializedConfig extends Message<MaterializedConfig> {
  /**
   * @generated from field: string config_id = 1;
   */
  configId = ''

  /**
   * @generated from field: string config_name = 2;
   */
  configName = ''

  /**
   * @generated from field: string profile_config_id = 3;
   */
  profileConfigId = ''

  /**
   * @generated from field: string config_value = 4;
   */
  configValue = ''

  /**
   * @generated from field: string calculation_type = 5;
   */
  calculationType = ''

  /**
   * @generated from field: string override_id = 6;
   */
  overrideId = ''

  constructor(data?: PartialMessage<MaterializedConfig>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.MaterializedConfig'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'config_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'config_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'profile_config_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'config_value', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'calculation_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'override_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MaterializedConfig {
    return new MaterializedConfig().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MaterializedConfig {
    return new MaterializedConfig().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): MaterializedConfig {
    return new MaterializedConfig().fromJsonString(jsonString, options)
  }

  static equals(
    a: MaterializedConfig | PlainMessage<MaterializedConfig> | undefined,
    b: MaterializedConfig | PlainMessage<MaterializedConfig> | undefined
  ): boolean {
    return proto3.util.equals(MaterializedConfig, a, b)
  }
}

/**
 * @generated from message plans.v1.MaterializedFeature
 */
export class MaterializedFeature extends Message<MaterializedFeature> {
  /**
   * @generated from field: string name = 1;
   */
  name = ''

  /**
   * @generated from field: string description = 2;
   */
  description = ''

  /**
   * @generated from field: string feature_id = 3;
   */
  featureId = ''

  /**
   * @generated from field: string feature_override_id = 4;
   */
  featureOverrideId = ''

  /**
   * @generated from field: bool enabled = 5;
   */
  enabled = false

  /**
   * @generated from field: repeated plans.v1.MaterializedConfig configs = 6;
   */
  configs: MaterializedConfig[] = []

  constructor(data?: PartialMessage<MaterializedFeature>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.MaterializedFeature'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'feature_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'feature_override_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'enabled', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: 'configs', kind: 'message', T: MaterializedConfig, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MaterializedFeature {
    return new MaterializedFeature().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MaterializedFeature {
    return new MaterializedFeature().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): MaterializedFeature {
    return new MaterializedFeature().fromJsonString(jsonString, options)
  }

  static equals(
    a: MaterializedFeature | PlainMessage<MaterializedFeature> | undefined,
    b: MaterializedFeature | PlainMessage<MaterializedFeature> | undefined
  ): boolean {
    return proto3.util.equals(MaterializedFeature, a, b)
  }
}

/**
 * @generated from message plans.v1.OrgProfile
 */
export class OrgProfile extends Message<OrgProfile> {
  /**
   * @generated from field: repeated plans.v1.MaterializedFeature features = 1;
   */
  features: MaterializedFeature[] = []

  constructor(data?: PartialMessage<OrgProfile>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.OrgProfile'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'features', kind: 'message', T: MaterializedFeature, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrgProfile {
    return new OrgProfile().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrgProfile {
    return new OrgProfile().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrgProfile {
    return new OrgProfile().fromJsonString(jsonString, options)
  }

  static equals(
    a: OrgProfile | PlainMessage<OrgProfile> | undefined,
    b: OrgProfile | PlainMessage<OrgProfile> | undefined
  ): boolean {
    return proto3.util.equals(OrgProfile, a, b)
  }
}

/**
 * @generated from message plans.v1.ListOrgProfileRequest
 */
export class ListOrgProfileRequest extends Message<ListOrgProfileRequest> {
  /**
   * @generated from field: string org_id = 1;
   */
  orgId = ''

  constructor(data?: PartialMessage<ListOrgProfileRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListOrgProfileRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListOrgProfileRequest {
    return new ListOrgProfileRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOrgProfileRequest {
    return new ListOrgProfileRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListOrgProfileRequest {
    return new ListOrgProfileRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListOrgProfileRequest | PlainMessage<ListOrgProfileRequest> | undefined,
    b: ListOrgProfileRequest | PlainMessage<ListOrgProfileRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListOrgProfileRequest, a, b)
  }
}

/**
 * @generated from message plans.v1.ListOrgProfileResponse
 */
export class ListOrgProfileResponse extends Message<ListOrgProfileResponse> {
  /**
   * @generated from field: repeated plans.v1.MaterializedFeature features = 1;
   */
  features: MaterializedFeature[] = []

  constructor(data?: PartialMessage<ListOrgProfileResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.ListOrgProfileResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'features', kind: 'message', T: MaterializedFeature, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListOrgProfileResponse {
    return new ListOrgProfileResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListOrgProfileResponse {
    return new ListOrgProfileResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListOrgProfileResponse {
    return new ListOrgProfileResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListOrgProfileResponse | PlainMessage<ListOrgProfileResponse> | undefined,
    b: ListOrgProfileResponse | PlainMessage<ListOrgProfileResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListOrgProfileResponse, a, b)
  }
}

/**
 * OrgProfileLink maps an organizations plan profile
 * org basis
 *
 * @generated from message plans.v1.OrgLink
 */
export class OrgLink extends Message<OrgLink> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string profile_id = 2;
   */
  profileId = ''

  /**
   * @generated from field: bool active = 3;
   */
  active = false

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 5;
   */
  updatedAt?: Timestamp

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 6;
   */
  deletedAt?: Timestamp

  constructor(data?: PartialMessage<OrgLink>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.OrgLink'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'profile_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 5, name: 'updated_at', kind: 'message', T: Timestamp },
    { no: 6, name: 'deleted_at', kind: 'message', T: Timestamp, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrgLink {
    return new OrgLink().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrgLink {
    return new OrgLink().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrgLink {
    return new OrgLink().fromJsonString(jsonString, options)
  }

  static equals(
    a: OrgLink | PlainMessage<OrgLink> | undefined,
    b: OrgLink | PlainMessage<OrgLink> | undefined
  ): boolean {
    return proto3.util.equals(OrgLink, a, b)
  }
}

/**
 * @generated from message plans.v1.CreateOrgLinkRequest
 */
export class CreateOrgLinkRequest extends Message<CreateOrgLinkRequest> {
  /**
   * @generated from field: string org_id = 1;
   */
  orgId = ''

  /**
   * @generated from field: string plan_name = 2;
   */
  planName = ''

  /**
   * @generated from field: bool active = 3;
   */
  active = false

  constructor(data?: PartialMessage<CreateOrgLinkRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.CreateOrgLinkRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'plan_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOrgLinkRequest {
    return new CreateOrgLinkRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrgLinkRequest {
    return new CreateOrgLinkRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateOrgLinkRequest {
    return new CreateOrgLinkRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateOrgLinkRequest | PlainMessage<CreateOrgLinkRequest> | undefined,
    b: CreateOrgLinkRequest | PlainMessage<CreateOrgLinkRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateOrgLinkRequest, a, b)
  }
}

/**
 * @generated from message plans.v1.CreateOrgLinkResponse
 */
export class CreateOrgLinkResponse extends Message<CreateOrgLinkResponse> {
  constructor(data?: PartialMessage<CreateOrgLinkResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.CreateOrgLinkResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateOrgLinkResponse {
    return new CreateOrgLinkResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOrgLinkResponse {
    return new CreateOrgLinkResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateOrgLinkResponse {
    return new CreateOrgLinkResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateOrgLinkResponse | PlainMessage<CreateOrgLinkResponse> | undefined,
    b: CreateOrgLinkResponse | PlainMessage<CreateOrgLinkResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateOrgLinkResponse, a, b)
  }
}

/**
 * @generated from message plans.v1.GetOrgLinkRequest
 */
export class GetOrgLinkRequest extends Message<GetOrgLinkRequest> {
  /**
   * @generated from field: string org_id = 1;
   */
  orgId = ''

  constructor(data?: PartialMessage<GetOrgLinkRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.GetOrgLinkRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrgLinkRequest {
    return new GetOrgLinkRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrgLinkRequest {
    return new GetOrgLinkRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrgLinkRequest {
    return new GetOrgLinkRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetOrgLinkRequest | PlainMessage<GetOrgLinkRequest> | undefined,
    b: GetOrgLinkRequest | PlainMessage<GetOrgLinkRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetOrgLinkRequest, a, b)
  }
}

/**
 * @generated from message plans.v1.GetOrgLinkResponse
 */
export class GetOrgLinkResponse extends Message<GetOrgLinkResponse> {
  /**
   * @generated from field: string org_id = 1;
   */
  orgId = ''

  /**
   * @generated from field: bool active = 2;
   */
  active = false

  /**
   * @generated from field: string profile_id = 3;
   */
  profileId = ''

  constructor(data?: PartialMessage<GetOrgLinkResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'plans.v1.GetOrgLinkResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: 'profile_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrgLinkResponse {
    return new GetOrgLinkResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrgLinkResponse {
    return new GetOrgLinkResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetOrgLinkResponse {
    return new GetOrgLinkResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetOrgLinkResponse | PlainMessage<GetOrgLinkResponse> | undefined,
    b: GetOrgLinkResponse | PlainMessage<GetOrgLinkResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetOrgLinkResponse, a, b)
  }
}
