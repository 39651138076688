export const scrollWithinContainer = ({
  containerId,
  elementId,
  offset = 0
}: {
  containerId: string
  elementId: string
  offset?: number
}): void => {
  const container = document.getElementById(containerId)
  const element = document.getElementById(elementId)

  if (container && element) {
    container.scrollTo({
      top: element.offsetTop + offset,
      behavior: 'smooth'
    })
  }
}
