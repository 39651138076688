import { Components, Theme } from '@mui/material'

/**
 * MUI Theme overrides for Flowcode's [FormControlLabel](./FormControlLabel.tsx) component.
 */
export const formControlLabelComponentOverrides: Components<Theme> = {
  MuiFormControlLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.MuiFormControlLabel-labelPlacementEnd .MuiSwitch-root': {
          marginRight: theme.spacing(2),
          '&.MuiSwitch-sizeSmall': {
            marginRight: theme.spacing(1)
          }
        }
      }),

      labelPlacementStart: ({ theme }) => ({
        '& .MuiSwitch-root': {
          marginLeft: theme.spacing(2),
          '&.MuiSwitch-sizeSmall': {
            marginLeft: theme.spacing(1)
          }
        }
      }),

      label: ({ theme, ownerState }) => ({
        ...(ownerState['aria-readonly'] && {
          cursor: 'not-allowed',
          color: theme.palette.onSurface.disabled
        })
      })
    }
  }
}
