import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface AnalyticsDrawersSliceState {
  isScheduledReportsDrawerOpen: boolean
}

const initialState: AnalyticsDrawersSliceState = {
  isScheduledReportsDrawerOpen: false
}

const analyticsDrawersSlice = createSlice({
  name: 'analyticsDrawers',
  initialState,
  reducers: {
    toggleScheduledReportsDrawer(state, action: PayloadAction<boolean>) {
      state.isScheduledReportsDrawerOpen = action.payload
    }
  }
})

export const { toggleScheduledReportsDrawer } = analyticsDrawersSlice.actions
export default analyticsDrawersSlice.reducer
