import { Domains } from '@dtx-company/true-common/src/types/domains'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface ConfigStateType {
  domain: Domains
  isLeftMenuOpen: boolean
  isHelpCtxMenuOpen: boolean
  isAccountCtxMenuOpen: boolean
  isCreateCtxMenuOpen: boolean
  isAssetManagementSheetOpen: boolean
  isMoreOptionsSheetOpen: boolean
  isAssetCreationSheetOpen: boolean
  isProfileMenuSheetOpen: boolean
  isDesktopSideDrawerOpen: boolean
  userHasToggledSideDrawer: boolean
  isUnifiedAssetManagementSheetOpen: boolean
  isAddCustomDomainFlowOpen: boolean
}

export enum MenuTarget {
  ACCOUNT,
  QUESTION_MARK
}

const initialState: ConfigStateType = {
  isLeftMenuOpen: false,
  isHelpCtxMenuOpen: false,
  isAccountCtxMenuOpen: false,
  isCreateCtxMenuOpen: false,
  domain: process.env.PRODUCT === Domains.FLOWPAGE ? Domains.FLOWPAGE : Domains.FLOWCODE,
  isAssetManagementSheetOpen: false,
  isMoreOptionsSheetOpen: false,
  isAssetCreationSheetOpen: false,
  isProfileMenuSheetOpen: false,
  isDesktopSideDrawerOpen: false,
  userHasToggledSideDrawer: false,
  isUnifiedAssetManagementSheetOpen: false,
  isAddCustomDomainFlowOpen: false
}

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setDomain: (state, action: PayloadAction<{ domain: Domains }>): void => {
      state.domain = action.payload.domain
    },
    setNavLeftMenu: (state, action: PayloadAction<{ open: boolean }>) => {
      state.isLeftMenuOpen = action.payload.open
    },
    setAccountCtxMenu: (state, action: PayloadAction<{ open: boolean }>) => {
      state.isAccountCtxMenuOpen = action.payload.open
    },
    setHelpCtxMenu: (state, action: PayloadAction<{ open: boolean }>) => {
      state.isHelpCtxMenuOpen = action.payload.open
    },
    setCreateCtxMenu: (state, action: PayloadAction<{ open: boolean }>) => {
      state.isCreateCtxMenuOpen = action.payload.open
    },
    setAssetManagementSheet: (state, action: PayloadAction<{ open: boolean }>) => {
      state.isAssetManagementSheetOpen = action.payload.open
    },
    setMoreOptionsSheet: (state, action: PayloadAction<{ open: boolean }>) => {
      state.isMoreOptionsSheetOpen = action.payload.open
    },
    setAssetCreationSheet: (state, action: PayloadAction<{ open: boolean }>) => {
      state.isAssetCreationSheetOpen = action.payload.open
    },
    setProfileMenuSheetOpen: (state, action: PayloadAction<{ open: boolean }>) => {
      state.isProfileMenuSheetOpen = action.payload.open
    },
    setDesktopSideDrawer: (state, action: PayloadAction<{ open: boolean }>) => {
      state.isDesktopSideDrawerOpen = action.payload.open
    },
    setUserHasToggledSideDrawer: (state, action: PayloadAction<{ hasToggled: boolean }>) => {
      state.userHasToggledSideDrawer = action.payload.hasToggled
    },
    setUnifiedAssetManagementSheetOpen: (state, action: PayloadAction<{ open: boolean }>) => {
      state.isUnifiedAssetManagementSheetOpen = action.payload.open
    },
    setIsAddCustomDomainFlowOpen: (state, action: PayloadAction<{ open: boolean }>) => {
      state.isAddCustomDomainFlowOpen = action.payload.open
    }
  }
})

export const {
  setDomain,
  setNavLeftMenu,
  setAccountCtxMenu,
  setCreateCtxMenu,
  setHelpCtxMenu,
  setAssetManagementSheet,
  setMoreOptionsSheet,
  setAssetCreationSheet,
  setProfileMenuSheetOpen,
  setDesktopSideDrawer,
  setUserHasToggledSideDrawer,
  setUnifiedAssetManagementSheetOpen,
  setIsAddCustomDomainFlowOpen
} = configSlice.actions

export default configSlice.reducer
