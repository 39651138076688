import { setHeapAttributes } from '@app/code/src/utils/heap'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCookies } from '@dtx-company/true-common/src/utils/cookies/cookies.hooks'
import { useEffect } from 'react'
import {
  useIsEU,
  useIsEULoaded
} from '@dtx-company/inter-app/src/redux/selectors/currentSessionSelectors'
import { useIsFlowpage } from '../../../hooks/useIsFlowpage/useIsFlowpage'

export const useHeapAttributes = (): void => {
  const isEU = useIsEU()
  const isEULoaded = useIsEULoaded()
  const { jwt, isAuthChecked } = useAuthState()
  const { optOut } = useCookies()
  const isFlowpage = useIsFlowpage()

  useEffect(() => {
    if (optOut || isFlowpage) return
    if (!isEULoaded || isEU) return
    if (!isAuthChecked) return

    setHeapAttributes({
      ithacaId: jwt?.ithacaId,
      email: jwt?.email,
      plan: jwt?.personalPlan
    })
  }, [optOut, isFlowpage, isEULoaded, isEU, jwt, isAuthChecked])
}
