import { Components, Theme } from '@mui/material'
import { TypographyVariants } from '@mui/material/styles'

export enum FlowFontFamily {
  INTER = `'Inter', sans-serif`,
  RF_DEWI_EXTENDED = `'RF Dewi Extended', sans-serif`
}

const displayXLarge: TypographyVariants['displayLarge'] = {
  fontFamily: FlowFontFamily.RF_DEWI_EXTENDED,
  fontSize: '3.5rem',
  fontWeight: 600,
  lineHeight: 1.14
}

const displayLarge: TypographyVariants['displayLarge'] = {
  fontFamily: FlowFontFamily.RF_DEWI_EXTENDED,
  fontSize: '2.75rem',
  fontWeight: 600,
  lineHeight: 1.14
}
const displayMedium: TypographyVariants['displayMedium'] = {
  fontFamily: FlowFontFamily.RF_DEWI_EXTENDED,
  fontSize: '2.25rem',
  fontWeight: 600,
  lineHeight: 1.27
}
const displaySmall: TypographyVariants['displaySmall'] = {
  fontFamily: FlowFontFamily.RF_DEWI_EXTENDED,
  fontSize: '2rem',
  fontWeight: 600,
  lineHeight: 1.222
}
const headlineXLarge: TypographyVariants['headlineXLarge'] = {
  fontFamily: FlowFontFamily.RF_DEWI_EXTENDED,
  fontSize: '2.5rem',
  fontWeight: 600,
  lineHeight: 1.25
}
const headlineLarge: TypographyVariants['headlineLarge'] = {
  fontFamily: FlowFontFamily.RF_DEWI_EXTENDED,
  fontSize: '2rem',
  fontWeight: 600,
  lineHeight: 1.25
}
const headlineMedium: TypographyVariants['headlineMedium'] = {
  fontFamily: FlowFontFamily.RF_DEWI_EXTENDED,
  fontSize: '1.75rem',
  fontWeight: 600,
  lineHeight: 1.29
}
const headlineSmall: TypographyVariants['headlineSmall'] = {
  fontFamily: FlowFontFamily.RF_DEWI_EXTENDED,
  fontSize: '1.5rem',
  fontWeight: 600,
  lineHeight: 1.33
}
const headlineXSmall: TypographyVariants['headlineXSmall'] = {
  fontFamily: FlowFontFamily.RF_DEWI_EXTENDED,
  fontSize: '1.25rem',
  fontWeight: 600,
  lineHeight: 1.4
}
const subtitleLarge: TypographyVariants['subtitleLarge'] = {
  fontFamily: FlowFontFamily.INTER,
  fontSize: '1.5rem',
  fontWeight: 500,
  lineHeight: 1.33
}
const subtitleMedium: TypographyVariants['subtitleMedium'] = {
  fontFamily: FlowFontFamily.INTER,
  fontSize: '1.25rem',
  fontWeight: 500,
  lineHeight: 1.4
}
const subtitleSmall: TypographyVariants['subtitleSmall'] = {
  fontFamily: FlowFontFamily.INTER,
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: 1.5,
  letterSpacing: '0.00625rem'
}
const bodyLarge: TypographyVariants['bodyLarge'] = {
  fontFamily: FlowFontFamily.INTER,
  fontSize: '1.25rem',
  fontWeight: 400,
  lineHeight: 1.4,
  letterSpacing: '0.15px'
}
const bodyMedium: TypographyVariants['bodyMedium'] = {
  fontFamily: FlowFontFamily.INTER,
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: 1.5,
  letterSpacing: '0.009375rem'
}
const bodySmall: TypographyVariants['bodySmall'] = {
  fontFamily: FlowFontFamily.INTER,
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: 1.43,
  letterSpacing: '0.00625rem'
}
const labelLarge: TypographyVariants['labelLarge'] = {
  fontFamily: FlowFontFamily.INTER,
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: 1.5,
  letterSpacing: '0.00625rem'
}
const labelMedium: TypographyVariants['labelMedium'] = {
  fontFamily: FlowFontFamily.INTER,
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: 1.43,
  letterSpacing: '0.00625rem'
}
const labelSmall: TypographyVariants['labelSmall'] = {
  fontFamily: FlowFontFamily.INTER,
  fontSize: '0.75rem',
  fontWeight: 500,
  lineHeight: 1.33,
  letterSpacing: '0.03125rem'
}
const captionMedium: TypographyVariants['captionMedium'] = {
  fontFamily: FlowFontFamily.INTER,
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: 1.333,
  letterSpacing: '0.03125rem'
}
const overlineSmall: TypographyVariants['overlineSmall'] = {
  fontFamily: FlowFontFamily.INTER,
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: 2.67,
  letterSpacing: '0.0625rem',
  textTransform: 'uppercase'
}
const overlineMedium: TypographyVariants['overlineMedium'] = {
  fontFamily: FlowFontFamily.INTER,
  fontSize: '0.875rem',
  fontWeight: 700,
  lineHeight: 1.43,
  letterSpacing: '0.0625rem',
  textTransform: 'uppercase'
}

export const variants = {
  displayXLarge,
  displayLarge,
  displayMedium,
  displaySmall,
  headlineXLarge,
  headlineLarge,
  headlineMedium,
  headlineSmall,
  headlineXSmall,
  subtitleLarge,
  subtitleMedium,
  subtitleSmall,
  bodyLarge,
  bodyMedium,
  bodySmall,
  labelLarge,
  labelMedium,
  labelSmall,
  captionMedium,
  overlineMedium,
  overlineSmall,

  // disable unused MUI built-in variants
  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  body2: undefined,
  overline: undefined,
  // set body1 to our bodyMedium, as MUI uses body1 to apply default styles
  body1: bodyMedium
}

export const typographyComponentOverrides: Components<Theme> = {
  MuiTypography: {
    defaultProps: {
      // Set the default variant to 'bodyMedium' rather than 'body1'
      variant: 'bodyMedium' as const,
      variantMapping: {
        displayXLarge: 'h2',
        displayLarge: 'h2',
        displayMedium: 'h2',
        displaySmall: 'h2',
        headlineLarge: 'h2',
        headlineMedium: 'h2',
        headlineSmall: 'h2',
        headlineXSmall: 'h2',
        subtitleLarge: 'h3',
        subtitleMedium: 'h3',
        subtitleSmall: 'h3',
        bodyLarge: 'p',
        bodyMedium: 'span',
        bodySmall: 'span',
        labelLarge: 'span',
        labelMedium: 'span',
        labelSmall: 'span',
        captionMedium: 'span',
        overlineSmall: 'span',
        overlineMedium: 'span'
      }
    }
  }
}
