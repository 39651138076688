import { INITIAL_SCAN_DESTINATION_FORM_STATE } from '../../constants/ScanToOwnGeneric'
import { LandingPageDestination } from '@dtx-company/inter-app/src/types/flowcode'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  ScanDestinationFormValuesType,
  ScanToOwnGenericSliceState
} from '../../types/ScanToOwnGeneric'

export const initialState: ScanToOwnGenericSliceState = {
  nickname: '',
  scanDestinationFormValues: {
    ...INITIAL_SCAN_DESTINATION_FORM_STATE
  },
  isFinalSTOfcPage: false
}

const ScanToOwnGenericSlice = createSlice({
  name: 'scanToOwnGenericOnboarding',
  initialState,
  reducers: {
    setNickname: (state, action: PayloadAction<{ nickname: string }>) => {
      state.nickname = action.payload.nickname
    },
    setScanDestination: (
      state,
      action: PayloadAction<{ destinationType: LandingPageDestination }>
    ) => {
      state.scanDestination = action.payload.destinationType
    },
    setScanDestinationFormValues: (
      state,
      action: PayloadAction<{ scanDestinationFormValues: ScanDestinationFormValuesType }>
    ) => {
      state.scanDestinationFormValues = {
        ...action.payload.scanDestinationFormValues
      }
    },
    setResetScanDesintationFormValues: state => {
      state.scanDestinationFormValues = {
        ...initialState.scanDestinationFormValues
      }
    },
    setIsFinalSTOfcPage: (state, action: PayloadAction<boolean>) => {
      state.isFinalSTOfcPage = action.payload
    }
  }
})

export const {
  setNickname,
  setScanDestination,
  setResetScanDesintationFormValues,
  setScanDestinationFormValues,
  setIsFinalSTOfcPage
} = ScanToOwnGenericSlice.actions

export default ScanToOwnGenericSlice.reducer
