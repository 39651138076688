import { EmptyContext, UploadTempAssetEvent } from '../locgMachine.types'
import { LOCG_TEMP_ASSETS_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { logger } from '@dtx-company/logger/src'
import { stripEmojis } from '@dtx-company/true-common/src/utils/strings/stripEmojis'

export const uploadTempAsset = async (
  _context: EmptyContext,
  { asset, assetType, authCacheId }: UploadTempAssetEvent
): Promise<false | undefined> => {
  try {
    const resp = await fetch(`${LOCG_TEMP_ASSETS_ENDPOINT}/${authCacheId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fileName: stripEmojis(asset.name),
        assetType
      })
    })
    if (!resp.ok) {
      logger.logFetchError(resp, { team: 'templates', technicalArea: 'locg' })
      return false
    }
    const { presignedURL }: { presignedURL: string } = await resp.json()
    const directUploadResp = await fetch(presignedURL, {
      method: 'PUT',
      body: asset
    })
    if (!directUploadResp.ok) {
      logger.logFetchError(directUploadResp, { team: 'templates', technicalArea: 'locg' })
    }
  } catch (err) {
    logger.logError(err, { team: 'templates', technicalArea: 'locg' })
    return false
  }
}
