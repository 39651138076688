import { useAuthState } from '../useAuthState'

/**
 * Billing admins are users that can buy and change plans for themselves
 * or their org.
 *
 * Logged out users are not billing admins, but they may become one once
 * they log into an account that is.
 *
 * For personal plans, there's only one user and they are the billing admin.
 * For org plans, one user will have the role but multiple may in the future
 *   except for enterprise where nobody gets the role and billing and subscription
 *   management is externally done.
 */
export function useIsBillingAdmin(): boolean | undefined {
  const authState = useAuthState()
  if (!authState.isAuthChecked) return undefined
  if (!authState.isAuthenticated) return false
  if (!authState.org) return true // personal plans
  return getIsBillingAdminFromExtras(authState.org.userOrgRoleExtras)
}

export const getIsBillingAdminFromExtras = (extras?: string[]): boolean =>
  extras?.includes('org_role_extra:billing_admin') || false
