import { SVGProps, memo } from 'react'

function WebSvg({
  stroke = 'currentcolor',
  fill = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 20 16" {...rest}>
      <rect
        width={18.5}
        height={14.5}
        x={0.75}
        y={0.75}
        stroke={color || stroke}
        strokeWidth={1.5}
        rx={1.25}
      />
      <rect width={2} height={2} x={3} y={3} fill={color || fill} rx={1} />
      <rect width={2} height={2} x={6} y={3} fill={color || fill} rx={1} />
      <rect width={2} height={2} x={9} y={3} fill={color || fill} rx={1} />
    </svg>
  )
}

export const Web = memo(WebSvg)
