import { createContext } from 'react'
import { useExperiment } from '@optimizely/react-sdk'

export type FlowExperimentCache = Record<string, ReturnType<typeof useExperiment>>

export interface FlowExperimentContextProps {
  localCache: FlowExperimentCache
  sessionCache: FlowExperimentCache
  queryCache: FlowExperimentCache
  liveCache: FlowExperimentCache
  addToLiveCache: (key: string, value: ReturnType<typeof useExperiment>) => void
}

// TODO: have these default to undefined
// have `useFlowExperimentContext` throw new Error('useFlowExperimentContext must be used within FlowExperimentProvider')
// update all tests to ensure they have the Provider wrapping them
export const FlowExperimentContext = createContext<FlowExperimentContextProps>({
  localCache: {},
  sessionCache: {},
  queryCache: {},
  liveCache: {},
  // need to stub this til the above TODO is solved
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addToLiveCache(_key, _value) {}
})
