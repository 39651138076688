import { Cookie } from '@dtx-company/true-common/src/utils/cookies'
import { FlowSession } from '../../types/FlowSession'
import { IncomingMessage } from 'http'
import { ParsedUrlQuery } from 'querystring'
import { createHash } from 'crypto'
import { parse as parseCookie } from 'cookie'
import { v4 } from 'uuid'
import LRUCache from 'lru-cache'

export const createSessionId = (req?: IncomingMessage): string => {
  // Support running outside the context of Cloudflare worker (development mode)
  // which normally will set this cookie
  let renderId = v4()
  const cookies = parseCookie(req?.headers.cookie ?? '')
  if (cookies[Cookie.BROWSER_SESSION_ID]) {
    renderId = cookies[Cookie.BROWSER_SESSION_ID]
  }
  return renderId
}

export const enum OriginRequestHeader {
  OPTIMIZELY_HASH = 'x-optimizely-datafile-hash',
  OPTIMIZELY_TARGETING = 'x-optimizely-targeting'
}

const OPTIMIZELY_CDN_FILE = `https://cdn.optimizely.com/datafiles/${process.env.OPTIMIZELY_SDK_KEY}.json`

const datafileCache = new LRUCache<string, Promise<string>>({
  max: 5
})
let previousCachedDatafile = '[]'

const hash = (message: string): string => {
  const hashBuffer = createHash('sha256').update(message, 'utf8').digest()
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
}

export const fetchOptimizelyDatafile = (originOptimizelyHash?: string): Promise<string> => {
  // Each CDN worker script could have different versions as files age out of
  // cache, if we have a cache hit, we don't have to fetch a new copy
  if (originOptimizelyHash) {
    const datafile = datafileCache.get(originOptimizelyHash)
    if (datafile) {
      return datafile
    }
  }

  // If we don't have a cache hit, we fetch the current file from the CDN, hoping
  // that it will be a match, we will always return this even if the hash doesn't match
  // This could yield a condition where we over-fetch while the Optimizely datafile
  // upstream caches enter a steady state
  const datafile = new Promise<string>(resolve => {
    fetch(OPTIMIZELY_CDN_FILE)
      .then(res => res.text())
      .then(text => {
        // set the actual hash value for future callers
        datafileCache.set(hash(text), Promise.resolve(text))
        previousCachedDatafile = text
        resolve(text)
      })
      .catch(e => {
        // when errors occur, older data probably better than no data
        console.error(
          `Could not load ${OPTIMIZELY_CDN_FILE}, returning previous results`,
          e.message
        )
        resolve(previousCachedDatafile)
      })
  })

  if (originOptimizelyHash) {
    datafileCache.set(originOptimizelyHash, datafile)
  }

  return datafile
}

export const getOptimizelyDatafileHash = (req?: IncomingMessage): string | undefined =>
  req?.headers[OriginRequestHeader.OPTIMIZELY_HASH]?.toString()

export const getExperimentOverrides = (query: ParsedUrlQuery): Record<string, string> => {
  const filteredQuery = Object.entries(query).reduce<Record<string, string>>(
    (acc, [key, value]) => {
      if (value && key.startsWith('opti-')) {
        const trimmedKey = key.replace('opti-', '')
        acc[trimmedKey] = value.toString()
      }
      return acc
    },
    {}
  )
  return filteredQuery
}

const Mobile =
  /(?:phone|windows\s+phone|ipod|blackberry|(?:android|bb\d+|meego|silk|googlebot) .+? mobile|palm|windows\s+ce|opera mini|avantgo|mobilesafari|docomo)/i
const Tablet = /(?:ipad|playbook|(?:android|bb\d+|meego|silk)(?! .+? mobile))/i

const getDeviceType = (userAgent: string): string => {
  if (Mobile.test(userAgent)) {
    return 'mobile'
  }

  if (Tablet.test(userAgent)) {
    return 'tablet'
  }

  return 'desktop'
}

export const getAttributes = (req?: IncomingMessage): Record<string, string> | undefined => {
  // attributes should be defined at the CDN
  let attributes: Record<string, string> | undefined
  try {
    const targetingAttributes = req?.headers[OriginRequestHeader.OPTIMIZELY_TARGETING]
    if (targetingAttributes) {
      attributes = JSON.parse(targetingAttributes.toString())
    } else {
      // fallback for local development
      const userAgent = req?.headers['user-agent'] ?? ''
      attributes = {
        country: 'US',
        deviceType: getDeviceType(userAgent.toString())
      }
    }
  } catch (e) {
    console.error(e)
  }
  return attributes
}

const isAsia = (isoCode: string): boolean =>
  /AF|AZ|BH|BD|AM|BT|IO|BN|MM|KH|LK|CN|TW|CX|CC|CY|GE|PS|HK|IN|ID|IR|IQ|IL|JP|KZ|JO|KP|KR|KW|KG|LA|LB|MO|MY|MV|MN|OM|NP|PK|PH|TL|QA|RU|SA|SG|VN|SY|TJ|TH|AE|TR|TM|UZ|YE|XE|XD|XS/.test(
    isoCode
  )
const isEurope = (isoCode: string): boolean =>
  /AL|AD|AZ|AT|AM|BE|BA|BG|BY|HR|CY|CZ|DK|EE|FO|FI|AX|FR|GE|DE|GI|GR|VA|HU|IS|IE|IT|KZ|LV|LI|LT|LU|MT|MC|MD|ME|NL|NO|PL|PT|RO|RU|SM|RS|SK|SI|ES|SJ|SE|CH|TR|UA|MK|GB|GG|JE|IM/.test(
    isoCode
  )
const isAntarctica = (isoCode: string): boolean => /AQ|BV|GS|TF|HM/.test(isoCode)
const isAfrica = (isoCode: string): boolean =>
  /DZ|AO|BW|BI|CM|CV|CF|TD|KM|YT|CG|CD|BJ|GQ|ET|ER|DJ|GA|GM|GH|GN|CI|KE|LS|LR|LY|MG|MW|ML|MR|MU|MA|MZ|NA|NE|NG|GW|RE|RW|SH|ST|SN|SC|SL|SO|ZA|ZW|SS|EH|SD|SZ|TG|TN|UG|EG|TZ|BF|ZM/.test(
    isoCode
  )
const isOceania = (isoCode: string): boolean =>
  /AS|AU|SB|CK|FJ|PF|KI|GU|NR|NC|VU|NZ|NU|NF|MP|UM|FM|MH|PW|PG|PN|TK|TO|TV|WF|WS|XX/.test(isoCode)
const isNorthAmerica = (isoCode: string): boolean =>
  /AG|BS|BB|BM|BZ|VG|CA|KY|CR|CU|DM|DO|SV|GL|GD|GP|GT|HT|HN|JM|MQ|MX|MS|AN|CW|AW|SX|BQ|NI|UM|PA|PR|BL|KN|AI|LC|MF|PM|VC|TT|TC|US|VI/.test(
    isoCode
  )
const isSouthAmerica = (isoCode: string): boolean =>
  /AR|BO|BR|CL|CO|EC|FK|GF|GY|PY|PE|SR|UY|VE/.test(isoCode)

const getContinent = (
  isoCode?: string
): 'AS' | 'EU' | 'AN' | 'AF' | 'OC' | 'NA' | 'SA' | undefined => {
  if (!isoCode) return undefined
  if (isNorthAmerica(isoCode)) return 'NA'
  if (isEurope(isoCode)) return 'EU'
  if (isSouthAmerica(isoCode)) return 'SA'
  if (isAsia(isoCode)) return 'AS'
  if (isAfrica(isoCode)) return 'AF'
  if (isOceania(isoCode)) return 'OC'
  if (isAntarctica(isoCode)) return 'AN'
  return undefined
}
// Return placeholder location for development purposes, production environments
// (not including review-apps) will modify this value to be the location
// of the requester, this is not an exhaustive implementation
export const getLocation = (req?: IncomingMessage): FlowSession['location'] => {
  // this header should be present on Review-apps
  const country = req?.headers['CF-IPCountry']?.toString()
  return {
    continent: getContinent(country),
    country
  }
}
