import {
  CheckBoxOutlineBlankSharp,
  CheckBoxSharp,
  IndeterminateCheckBoxSharp
} from '@mui/icons-material'
import { formHelperTextClasses } from '@mui/material/FormHelperText'
import { formLabelClasses } from '@mui/material/FormLabel'
import type { Components, Theme } from '@mui/material'

/**
 * MUI Theme overrides for Flowcode's [Checkbox](./Checkbox.tsx) component.
 */
export const checkboxComponentOverrides: Components<Theme> = {
  MuiCheckbox: {
    defaultProps: {
      icon: <CheckBoxOutlineBlankSharp />,
      indeterminateIcon: <IndeterminateCheckBoxSharp />,
      checkedIcon: <CheckBoxSharp />
    },

    styleOverrides: {
      root: ({ theme }) => ({
        '&:focus, &:active, &.Mui-focusVisible': {
          backgroundColor: theme.palette.shades.primary.focus
        },

        // Compensate for the padding added by the Checkbox component
        marginLeft: theme.spacing(-2.75),

        // Align the helper text with the label
        [`& ~ div > .${formHelperTextClasses.root}`]: {
          marginLeft: 0,
          marginRight: 0
        }
      }),

      // Ensure the label color matches the checkbox color
      colorPrimary: ({ theme }) => ({
        [`&& ~ div > .${formLabelClasses.root}`]: {
          color: theme.palette.text.primary
        }
      }),

      // Ensure the label color matches the checkbox color
      colorSecondary: ({ theme }) => ({
        [`&& ~ div > .${formLabelClasses.root}`]: {
          color: theme.palette.text.secondary
        }
      })
    }
  }
}
