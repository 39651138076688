const isLowerEnvironment = process.env.APP_ENV !== 'production'

export const NBA_WIDGET_URL = isLowerEnvironment
  ? 'https://cdn-qa.nba.com/manage/sites/101/uwmaster/UW100-2024-TEST-WIDGET-FOR-FLOWCODE/UW100-2024-TEST-WIDGET-FOR-FLOWCODE.js'
  : 'https://cdn.nba.com/manage/sites/101/uwmaster/UW37-2023-2023-IN-SEASON-TOURNAMENT-FLOWCODE/UW37-2023-2023-IN-SEASON-TOURNAMENT-FLOWCODE.js'

export const NBA_USER_CONSENT_URL = isLowerEnvironment
  ? 'https://cdn-dev.nba.com/user-consent/index.min.js'
  : 'https://cdn.nba.com/user-consent/index.min.js'

export const NBA_TAG_CONTAINER_URL = isLowerEnvironment
  ? 'https://assets.adobedtm.com/82bf3c13a525/b93b1a237384/launch-b2da07fe468d-development.min.js'
  : 'https://assets.adobedtm.com/82bf3c13a525/b93b1a237384/launch-c9d2ee1dbb19.min.js'
