import { IS_CI, IS_DEVELOPMENT, IS_STAGING } from '@dtx-company/true-common/src/constants/env'
import {
  SET_ACCESS_TOKEN_COOKIE_ENDPOINT,
  STG_SET_ACCESS_TOKEN_COOKIE_ENDPOINT
} from '@dtx-company/true-common/src/constants/endpoints'
import { logger } from '@dtx-company/logger'

export async function setAccessTokenCookie(accessToken: string): Promise<{ success: boolean }> {
  try {
    const res = await fetch(SET_ACCESS_TOKEN_COOKIE_ENDPOINT, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ accessToken })
    })
    if (!res.ok) {
      await logger.logFetchError(res, { technicalArea: 'authentication' })
    }

    /*
      Locally, we communicate with gateway.stg.flowcode.com services which cannot be proxied since they are gRPC. 
      To auth with these services, we set and clear cookies for staging in local development. 
      Eventually, we can remove this workaround when we can proxy all stg service calls with a local gateway.
    */
    if (IS_DEVELOPMENT || IS_CI) {
      await fetch(STG_SET_ACCESS_TOKEN_COOKIE_ENDPOINT, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ accessToken })
      })
    }

    if (IS_STAGING) {
      /* 
      - extra calls to set an access token cookie with local dev + review apps domains
      - required for flows like SSO where no redirect to localhost / review app URL is not possible
      - means that any prior local or review app login will be overwritten by staging login 
      */
      await fetch(SET_ACCESS_TOKEN_COOKIE_ENDPOINT, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ accessToken, domain: 'localhost' })
      })
      await fetch(SET_ACCESS_TOKEN_COOKIE_ENDPOINT, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ accessToken, domain: '.reviewapps.flowcode.com' })
      })
    }
  } catch (e) {
    logger.logError(e, { technicalArea: 'authentication' })
    return { success: false }
  }
  return { success: true }
}
