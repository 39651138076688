import { IS_DEBUG } from '@dtx-company/true-common/src/constants/env'
import {
  OptimizelyDecideOption,
  eventDispatcher as _eventDispatcher,
  createInstance,
  enums
} from '@optimizely/react-sdk'
import { useCookieState } from '../../redux/selectors/useCookieState'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useHeapSessionId } from '@dtx-company/true-common/src/utils/cookies/cookies.hooks'
import { useIsMounted } from '@dtx-company/true-common/src/hooks/useIsMounted'
import { useOptimizelyContext } from '../useOptimizelyContext'
import type { EventV1 as OptimizelyEvent } from '@optimizely/js-sdk-event-processor'
export type EventDispatcher = typeof _eventDispatcher
type EventDispatcherArgs = Parameters<typeof _eventDispatcher.dispatchEvent>
export type OptimizelyReactSDKClient = ReturnType<typeof createInstance> | undefined

export function createOptimizely(
  datafile: string,
  eventDispatcher: EventDispatcher,
  isMounted: boolean
): OptimizelyReactSDKClient {
  const defaultDecideOptions = [
    OptimizelyDecideOption.ENABLED_FLAGS_ONLY,
    OptimizelyDecideOption.IGNORE_USER_PROFILE_SERVICE
  ]
  if (isMounted) {
    defaultDecideOptions.push(OptimizelyDecideOption.DISABLE_DECISION_EVENT)
  }

  return createInstance({
    datafile,
    logLevel: IS_DEBUG ? enums.LOG_LEVEL.DEBUG : enums.LOG_LEVEL.ERROR,
    eventDispatcher,
    defaultDecideOptions,
    // https://docs.developers.optimizely.com/full-stack/docs/event-batching-javascript#basic-example
    // on pages where have more than 10 events (i.e. /free-qr-code-generator) subsequent events are not sent to the
    // Optimizely backend. Increasing the batch size to 100 to be safe this doesn't creep up again until the underlying issue is fixed
    eventBatchSize: 100,
    eventFlushInterval: 1000
  })
}

const addHeapSession = (
  args: EventDispatcherArgs,
  heapSessionId: string,
  heapSessionIdEntityId?: string
): EventDispatcherArgs => {
  if (heapSessionIdEntityId) {
    const [event, cb] = args
    const params = event.params
    params.visitors = params.visitors.map((visitor: OptimizelyEvent['visitors'][0]) => {
      return {
        ...visitor,
        attributes: visitor.attributes.concat({
          entity_id: heapSessionIdEntityId,
          key: 'heapSessionId',
          type: 'custom',
          value: heapSessionId
        })
      }
    })

    const updatedEvent = {
      ...event,
      params
    }

    return [updatedEvent, cb]
  }
  return args
}

// Buffer data until we have GDPR opt-in and Heap session id
export const useBufferedDispatcher = (datafile: string): EventDispatcher => {
  const heapSessionId = useHeapSessionId()
  const isMounted = useIsMounted()
  const { browserSessionIdLoaded } = useCookieState()
  const [pendingEvents, setPendingEvents] = useState<EventDispatcherArgs[]>([])
  const heapSessionIdEntityId = useMemo(() => {
    try {
      const { attributes }: { attributes: [{ id: string; key: string }] } = JSON.parse(datafile)

      const heapSessionIdAttr = attributes?.find(attr => attr.key === 'heapSessionId')
      if (!heapSessionIdAttr) {
        throw new Error('could not locate heapSessionId in datafile')
      }
      return heapSessionIdAttr.id
    } catch (e) {
      // ID value from `heapSessionId` https://app.optimizely.com/v2/projects/17283172040/audiences/attributes
      console.error(e)
    }
  }, [datafile])

  const eventDispatcher = useRef<EventDispatcher>({
    dispatchEvent: (): void => {
      //
    }
  })

  useEffect(() => {
    if (heapSessionId && browserSessionIdLoaded) {
      // dispatch buffered decision events
      if (pendingEvents.length) {
        pendingEvents.forEach(args => {
          const updatedArgs = addHeapSession(args, heapSessionId, heapSessionIdEntityId)
          _eventDispatcher.dispatchEvent(...updatedArgs)
        })
        setPendingEvents([])
      }

      eventDispatcher.current.dispatchEvent = (...args): void => {
        // modify event before forwarding
        const updatedArgs = addHeapSession(args, heapSessionId, heapSessionIdEntityId)
        _eventDispatcher.dispatchEvent(...updatedArgs)
      }
    } else {
      eventDispatcher.current.dispatchEvent = (...args): void => {
        if (isMounted()) {
          setPendingEvents(pendingEvents.concat([args]))
        }
      }
    }
  }, [pendingEvents, heapSessionId, browserSessionIdLoaded, heapSessionIdEntityId, isMounted])

  return eventDispatcher.current
}

export const useBufferedTracking = (optimizely?: OptimizelyReactSDKClient): void => {
  useEffect(() => {
    if (!optimizely) return
    // emit track event for buffered track events
    if (Array.isArray(window.optimizelyTrack)) {
      window.optimizelyTrack.forEach((args: OptimizelyTrackArgs) => {
        optimizely.track(...args)
      })
    }

    // remove buffering
    window.optimizelyTrack = {
      push: (args: OptimizelyTrackArgs) => {
        return optimizely.track(...args)
      }
    }
  }, [optimizely])
}

const ORG_PLAN_PREFIX = 'org_plan:'
const PERSONAL_PLAN_PREFIX = 'personal_plan:'

export function getFormattedPlanName(orgPlan: string, personalPlan: string): string | null {
  if (orgPlan && orgPlan.startsWith(ORG_PLAN_PREFIX)) {
    const plan = orgPlan.slice(ORG_PLAN_PREFIX.length)
    //needed to differentiate between old pro_plus (on personalPlan)
    if (plan === 'pro_plus') return 'pro_plus_org'
    return plan
  }
  if (personalPlan && personalPlan.startsWith(PERSONAL_PLAN_PREFIX)) {
    return personalPlan.slice(PERSONAL_PLAN_PREFIX.length)
  }
  return null
}

export const useOptimizelyClient = (): OptimizelyReactSDKClient => {
  const optimizelyContext = useOptimizelyContext()
  return optimizelyContext?.client
}
