import { AUTHENTICATION_V2_ENDPOINT } from '../constants/endpoints'

export enum OrgAccountType {
  ENTERPRISE = 'org_plan:enterprise',
  PRO_FLEX = 'org_plan:pro_flex',
  PRO_PLUS = 'org_plan:pro_plus',
  PILOT = 'org_plan:pilot'
}

export enum OrgRoleEnum {
  VIEWER = 'org_role:viewer',
  MEMBER = 'org_role:member',
  ADMIN = 'org_role:admin'
}

export enum OrgRoleExtraEnum {
  BILLING_ADMIN = 'org_role_extra:billing_admin',
  STUDIO_DESIGNER = 'org_role_extra:studio_designer',
  SUPER_ADMIN = 'org_role_extra:super_admin',
  FLOWPAGE_ADMIN = 'org_role_extra:flowpage_admin'
}

export enum OrgExtra {
  BLOCK_SMS = 'org_extra:block_sms',
  FLOWTEAMS = 'org_extra:flowteams'
}

export enum TeamRoleEnum {
  VIEWER = 'team_role:viewer',
  MEMBER = 'team_role:member',
  ADMIN = 'team_role:admin'
}

export interface MemberType {
  ithacaId: string
  firstName: string
  lastName: string
  email: string
  orgRole: OrgRoleEnum
  orgRoleExtras: string[]
  inviter?: {
    ithacaId: string
    firstName: string
    lastName: string
    email: string
  }
  teams?: { teamId: string; teamRole: TeamRoleEnum; name?: string }[]
  pending?: boolean
  color?: string
  id?: string
  createdAt?: string
  lastLogin?: string
}

export interface NewMemberType {
  firstName: string
  lastName: string
  email: string
  orgRole: OrgRoleEnum
  ithacaId?: string
}

export interface TeamType {
  id?: string
  teamId: string
  name: string
  memberCount: number
  color?: string
  parentTeamId?: string
  parentTeamName?: string
  members?: TeamMemberType[]
  subTeams?: TeamType[]
  directChildren?: TeamType[]
  directChildrenCount?: number
  profilePictureUrl?: string
}

export interface TeamMemberType {
  ithacaId: string
  firstName?: string
  lastName?: string
  email?: string
  teamRole?: TeamRoleEnum
  color?: string
}

export interface OrgExtrasType {
  orgExtra: string
  orgRoleExtras: string[]
}

export interface OrgNewTeamType {
  orgId: string
  teamName: string
  users: TeamMemberType[]
}

export interface UpdateMemberRoleType {
  orgId: string
  userIthacaId: string
  newRole: string
}

export interface UpdatePendingMemberRoleType {
  orgId: string
  invitationId: string
  email?: string
  firstName?: string
  lastName?: string
  orgRole?: OrgRoleEnum
}

export interface UpdateMemberExtraRolesType {
  orgId: string
  userIthacaId: string
  orgRoleExtras: string[]
}

export interface PatchTeamType {
  orgId: string
  teamId: string
  teamName?: string
  newUsers?: TeamMemberType[]
  changedUsers?: TeamMemberType[]
  deletedUsers?: string[]
}

export interface InviteMemberToOrgType {
  orgId: string
  firstName: string
  lastName: string
  teams?: {
    teamId: string
    teamRole: TeamRoleEnum
  }[]
  email: string
  orgRole?: OrgRoleEnum
  orgRoleExtras?: string[]
}

export interface InviteExistingMemberToOrgType {
  ithacaId: string
  orgId: string
  role: OrgRoleEnum
  extras?: string[]
}

export interface RetractInvitationBodyType {
  orgId: string
  invitationId: string
}

export const SOMETHING_WENT_WRONG_ERROR_MESSAGE = 'Something went wrong. Please try again.'
export const ORG_ENDPOINT_BASE_URL = AUTHENTICATION_V2_ENDPOINT

export enum FailedInvitationCode {
  ALREADY_IN_ANOTHER_ORG = 'already-in-another-org',
  INVITE_FOR_ANOTHER_USER = 'invite-for-another-user',
  EXISTING_INVITE = 'existing-invite',
  ALREADY_AT_SEAT_LIMIT = 'already-at-seat-limit',
  EXISTING_MEMBER = 'existing-member',
  OTHER = 'other'
}

export const FailedInvitationMessage = {
  [FailedInvitationCode.ALREADY_IN_ANOTHER_ORG]:
    'This email is already associated with a user that is part of, or has been invited to another organization.',
  [FailedInvitationCode.EXISTING_INVITE]:
    'This email is already associated with an invite that has already been made by another user.',
  [FailedInvitationCode.ALREADY_AT_SEAT_LIMIT]:
    'This organization has run out of seats. Please contact support@flowcode.com to inquire.',
  [FailedInvitationCode.EXISTING_MEMBER]:
    'This email is already associated with a user that is part of, or has been invited to another organization.',
  [FailedInvitationCode.INVITE_FOR_ANOTHER_USER]:
    'This invitation is intended for another user. Please check the email and try again.',
  [FailedInvitationCode.OTHER]: 'Other'
}

export enum TeamMemberRole {
  ADMIN = 'team_role:admin',
  MEMBER = 'team_role:member',
  VIEWER = 'team_role:viewer'
}

export interface Team {
  teamId: string
  name: string
  memberCount: number
  parentTeamId?: string
  parentTeamName?: string
  members?: TeamMember[]
  teamRole?: TeamRoleEnum
  directChildren?: Team[]
  directChildrenCount: number
  requesterRole?: TeamMemberRole
  profilePictureUrl?: string
}

export interface ParentTeam {
  id?: string
  name?: string
}

export interface TeamMember {
  id: string
  name?: string
  firstName?: string
  lastName?: string
  email?: string
  role: TeamMemberRole
  hasBeenInvited: boolean
  hasJoinedOrg: boolean
  profilePictureUrl?: string
}

export interface ApiCollaborator {
  id: string
  name?: string
  type: number
  email?: string
}
export interface InvitationStatus {
  email: string
  success: boolean
  failureMessage?: string
}

export interface FailedInvitation {
  email: string
  reason: FailedInvitationCode
}

export const TeamRoleEnumToTeamMemberRole = {
  [TeamRoleEnum.VIEWER]: TeamMemberRole.VIEWER,
  [TeamRoleEnum.MEMBER]: TeamMemberRole.MEMBER,
  [TeamRoleEnum.ADMIN]: TeamMemberRole.ADMIN
}
