import {
  FlowExperimentCache,
  FlowExperimentContext,
  FlowExperimentContextProps
} from './FlowExperimentContext'
import { ReactNode, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { getFlowExperimentCacheFromRouter } from './getFlowExperimentCacheFromRouter'
import { parseFlowExperimentValue } from './parseFlowExperimentValue'
import { useFlowConsole } from '../../../hooks/useFlowConsole'
import { useFlowRouter } from '../../../hooks/useFlowRouter'
import { useIsMounted } from '@dtx-company/true-common/src/hooks/useIsMounted'
import { useLocalStorageState } from '@dtx-company/true-common/src/hooks/useLocalStorageState'
import { useOnce } from '@dtx-company/true-common/src/hooks/useOnce'
import { useOptimizelyClient } from '../../useOptimizelyClient'
import { useSessionStorageState } from '@dtx-company/true-common/src/hooks/useSessionStorageState'

const emptyCache = {} as FlowExperimentCache

export interface FlowExperimentConsole {
  add(key: string, value: Parameters<typeof parseFlowExperimentValue>[0], persist?: boolean): void
  remove(key: string): void
  clear(): void
  list(): void
}

export interface FlowExperimentProviderProps {
  children?: ReactNode
}

export function FlowExperimentProvider(props: FlowExperimentProviderProps): JSX.Element {
  const [localCache, setLocalCache] = useLocalStorageState('flowExperimentCache', emptyCache)
  const [sessionCache, setSessionCache] = useSessionStorageState('flowExperimentCache', emptyCache)
  const [queryCache, setQueryCache] = useState(emptyCache)
  const [liveCache, setLiveCache] = useState(emptyCache)

  const optimizelyClient = useOptimizelyClient()
  const context = useMemo<FlowExperimentContextProps>(() => {
    return {
      localCache,
      sessionCache,
      queryCache,
      liveCache,
      addToLiveCache(key, value) {
        if (JSON.stringify(liveCache[key]) !== JSON.stringify(value)) {
          setLiveCache(cache => ({ ...cache, [key]: value }))
        }
      }
    }
  }, [localCache, sessionCache, queryCache, liveCache])

  const contextRef = useRef(context)
  contextRef.current = context

  const flowRouter = useFlowRouter()

  const isFlowpage = flowRouter.pathname === Routes.FLOWPAGE
  const isMounted = useIsMounted()

  // Store query params
  // Only execute once and persist until app is reloaded
  useEffect(() => {
    if (!isMounted()) return
    if (queryCache !== emptyCache) return
    const cache = getFlowExperimentCacheFromRouter(flowRouter)
    setQueryCache(cache)
  }, [isMounted, queryCache, flowRouter])

  // Add console methods to manage local and session cache
  useFlowConsole(flow => {
    const add: FlowExperimentConsole['add'] = (key, value, persist) => {
      const experiment = parseFlowExperimentValue(value)
      const _add: SetStateAction<FlowExperimentCache> = cache => ({ ...cache, [key]: experiment })
      persist ? setLocalCache(_add) : setSessionCache(_add)
    }
    const remove: FlowExperimentConsole['remove'] = (key: string): void => {
      const _remove: SetStateAction<FlowExperimentCache> = cache => {
        delete cache[key]
        return cache
      }
      setLocalCache(_remove)
      setSessionCache(_remove)
    }
    const clear: FlowExperimentConsole['clear'] = (): void => {
      const _clear: SetStateAction<FlowExperimentCache> = () => {
        return {}
      }
      setLocalCache(_clear)
      setSessionCache(_clear)
    }
    const list: FlowExperimentConsole['list'] = (): void => {
      if (!optimizelyClient || isFlowpage) return
      const allKeys = Object.keys(optimizelyClient.getOptimizelyConfig()?.experimentsMap || {})
      const allExperiments = allKeys.reduce<Record<string, [value: string | null]>>((acc, key) => {
        acc[key] = [optimizelyClient.activate(key)]
        return acc
      }, {})

      const context = contextRef.current
      const cache = {
        ...allExperiments,
        ...context.liveCache,
        ...context.localCache,
        ...context.sessionCache,
        ...context.queryCache
      }
      const keys = Object.keys(cache).sort()
      if (keys.length > 0) {
        const queryKeys = Object.keys(context.queryCache)
        const sessionKeys = Object.keys(context.sessionCache)
        const localKeys = Object.keys(context.localCache)
        const liveKeys = Object.keys(context.liveCache)
        const lines = keys.map(key => {
          const experiment = cache[key]
          let whichCache = ''
          if (queryKeys.includes(key)) {
            whichCache = 'query'
          } else if (sessionKeys.includes(key)) {
            whichCache = 'session'
          } else if (localKeys.includes(key)) {
            whichCache = 'local'
          } else if (liveKeys.includes(key)) {
            whichCache = 'live'
          } else {
            whichCache = 'none'
          }
          const [variation] = experiment
          return `(${whichCache}) ${key}: ${variation ?? 'NULL'}`
        })
        console.info(`Experiments\n${lines.join('\n')}`)
      } else {
        console.info(`Experiments\nNone.`)
      }
    }
    flow.experiment = {
      add(key, value, persist) {
        remove(key)
        add(key, value, persist)
        list()
      },
      remove(key) {
        remove(key)
        list()
      },
      clear(): void {
        clear()
        list()
      },
      list(): void {
        list()
      }
    }
  })

  // Do an initial print if there are any cached values
  useOnce(() => {
    if (!isMounted()) return false
    if (queryCache === emptyCache) return false
    const context = contextRef.current
    const cache = { ...context.localCache, ...context.sessionCache, ...context.queryCache }
    if (Object.keys(cache).length > 0) {
      window?.flow?.experiment?.list()
    }
  })

  return (
    <FlowExperimentContext.Provider value={context}>
      {props.children}
    </FlowExperimentContext.Provider>
  )
}
