import { FC } from 'react'
import { IconBase } from './IconBase'
import { IconBaseProps, IconProps } from '../../..'
import { IconButton } from './IconButton'
import { getTrueImgSrc } from '../../../utils/getTrueImgSrc'

export const Icon: FC<IconProps & IconBaseProps> = props => {
  const { button = false, src, alt, ...rest } = props
  const trueSrc = getTrueImgSrc(src)

  return button ? (
    <IconButton {...props} src={trueSrc} />
  ) : (
    <IconBase src={trueSrc} alt={alt} {...rest} />
  )
}
