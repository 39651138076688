import { ClientSideError, ClientSideErrorType, FlowError } from '../types'
import { LoggerLevel } from '../types/LoggerLevel'
import { LoggerPayload } from '../types/LoggerPayload'
import { logErrorToHeap } from './logErrorToHeap'

/**
 * Sends a log to datadog with more contextual metadata than a standard error,
 * such as the team that should look into the error.
 */
export function sendLog(level: LoggerLevel, payload: LoggerPayload): void {
  // Add a stack if none given, a stacktrace to here is better than nothing at all and
  // will at least include where the logging originates from
  if (!payload.stack) payload.stack = new Error().stack
  try {
    if (typeof window !== 'object') {
      // dynamically import so server modules do not get bundled
      import('./sendServerLog').then(mod => mod.sendServerLog(level, payload)).catch(console.error)
    } else {
      const { message, ...attributes } = payload
      if (level === 'error') {
        if (payload instanceof FlowError || !!payload.type) {
          logErrorToHeap(payload as any)
        } else {
          logErrorToHeap(
            new ClientSideError({
              name: payload.name,
              type: ClientSideErrorType.DefaultError,
              message: payload.message
            })
          )
        }
      }
      window.DD_LOGS &&
        window.DD_LOGS.onReady(() => {
          window.DD_LOGS?.logger.setHandler(['console', 'http'])
          if (level === 'error') {
            window.DD_LOGS?.logger.error(message, attributes)
          } else if (level === 'warn') {
            window.DD_LOGS?.logger.warn(message, attributes)
          } else {
            window.DD_LOGS?.logger.info(message, attributes)
          }
        })
    }
  } catch (e) {
    console.error('error forwarding logs', e.message)
  }
}
