import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface FlowtagBannerStateType {
  bannerEnabled: boolean
}

const initialState: FlowtagBannerStateType = {
  bannerEnabled: true
}

const flowtagBannerSlice = createSlice({
  name: 'flowtagBanner',
  initialState,
  reducers: {
    setBannerState: (state, action: PayloadAction<boolean>) => {
      state.bannerEnabled = action.payload
    }
  }
})

const consumerBannerReducer = persistReducer(
  {
    key: 'consumerBannerReducer',
    version: 1,
    storage,
    whitelist: ['bannerEnabled']
  },
  flowtagBannerSlice.reducer
)

export const { setBannerState } = flowtagBannerSlice.actions

export default consumerBannerReducer
