export const USER_INFO_QUERY = /* GraphQL */ `
  query getUserInfo {
    me {
      id
      flowcodeCount
      isFlowArmy
      canCreateStaticCode
    }
    subscriptionDetails {
      industryOptions
      companySizeOptions
    }
  }
`
