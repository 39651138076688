import { FC } from 'react'
import { SpacerProps } from './Spacer.types'
import { display, space, width } from 'styled-system'
import styled from 'styled-components'

/**
 * Provides spacing between elements
 */
export const Spacer = styled.div<SpacerProps>`
  ${space}
  ${width}
  ${display}
`

/**
 * Provides horizontal spacing between elements
 */
export const HorizontalSpacer: FC<SpacerProps> = props => <Spacer width="100%" {...props} />

/**
 * Provides vertical spacing between elements
 */
export const VerticalSpacer: FC<SpacerProps> = props => <Spacer {...props} />
