import { SessionAndOptimizelyValues } from '../types'
import { useState } from 'react'

// app re-renders with new initialProps on navigation
// we want to initial html page's session data to remain active
// throughout the session
export const useInitialAppValue = (
  session?: SessionAndOptimizelyValues['session'],
  optimizely?: SessionAndOptimizelyValues['optimizely']
): SessionAndOptimizelyValues => {
  return useState(() => {
    if (!session) console.error('session props not available')
    if (!optimizely) console.error('optimizely props not available')
    return {
      session,
      optimizely
    }
  })[0]
}
