import { PlanType } from '@dtx-company/true-common/src/types/planType'

// It'd be alot simpler to just planType.toString() but we wouldn't want changing
// the underlying string to break heap so casting them to discrete values
// Long-term could build more robust stuff around heap such that devs could pass
// string | number | PlanType values into heap properties and it'd cast them all
// consistently
export function getHeapTrackerPlanTypeString(planType: PlanType): string {
  switch (planType) {
    case PlanType.FREE:
      return 'FREE'
    case PlanType.PRO:
      return 'PRO'
    case PlanType.PRO_PLUS_ORG:
      return 'PRO_PLUS_ORG'
    case PlanType.PRO_FLEX:
      return 'PRO_FLEX'
    case PlanType.PILOT:
      return 'PILOT'
    case PlanType.ENTERPRISE:
      return 'ENTERPRISE'
  }
}
