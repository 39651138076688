import IntercomEventQueue from './IntercomEventQueue'

/**
 * Enqueues an event to be sent to Intercom.
 *
 * @param eventName - The name of the event to send to Intercom.
 * @param metadata - Optional metadata to send with the event.
 */
export const fireIntercomEvent = (eventName: string, metadata?: Record<string, unknown>): void => {
  IntercomEventQueue.enqueueEvent(eventName, metadata)
}
