import { Order_Direction } from '@dtx-company/flow-codegen/src/page/generated.types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export enum CreatePageSection {
  STARTER_TEMPLATES = 'starter-templates',
  MY_TEMPLATES = 'my-templates',
  SHARED_TEMPLATES = 'shared-templates',
  SEARCH_TEMPLATES = 'search-templates'
}

export interface CreatePageModalState {
  queryVariables: {
    contains: string
    order: {
      orderDirection: Order_Direction
      orderBy: 'name' | 'createdAt' | 'modifiedAt' | 'position'
    }
  }
  activeSection: CreatePageSection
}

const initialState: CreatePageModalState = {
  queryVariables: {
    order: {
      orderBy: 'name',
      orderDirection: Order_Direction.Asc
    },
    contains: ''
  },
  activeSection: CreatePageSection.STARTER_TEMPLATES
}

const createPageModal = createSlice({
  name: 'createPageModal',
  initialState,
  reducers: {
    setContains: (
      state,
      action: PayloadAction<CreatePageModalState['queryVariables']['contains']>
    ) => {
      state.queryVariables.contains = action.payload
    },
    setActiveSection: (state, action: PayloadAction<CreatePageModalState['activeSection']>) => {
      state.activeSection = action.payload
    },
    setOrderDirection: (
      state,
      action: PayloadAction<CreatePageModalState['queryVariables']['order']['orderDirection']>
    ) => {
      state.queryVariables.order.orderDirection = action.payload
    },
    setOrderBy: (
      state,
      action: PayloadAction<CreatePageModalState['queryVariables']['order']['orderBy']>
    ) => {
      state.queryVariables.order.orderBy = action.payload
    }
  }
})

export const { setContains, setActiveSection, setOrderBy, setOrderDirection } =
  createPageModal.actions

export default createPageModal.reducer
