export default {
  fontFamily: `'Inter', sans-serif`,
  fontSize: {
    label: '15px',
    labelSmall: '13px'
  },
  fontWeight: {
    thin: 300,
    semiThin: 400,
    regular: 500,
    semiBold: 600,
    bold: 700
  }
}
