import { useFlowExperiment } from '../useFlowExperiment'

export function parseFlowExperimentValue(
  value: string | null | undefined
): ReturnType<typeof useFlowExperiment> {
  if (!value) {
    return [null, true, false]
  }
  return [value, true, false]
}
