// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file asset/v1/asset.proto (package asset.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { Any, Message, proto3, protoInt64, Timestamp } from '@bufbuild/protobuf'
import { PaginationArguments, PaginationPageInfo } from '../../common/v1/pagination_pb'

/**
 * @generated from enum asset.v1.AssetState
 */
export enum AssetState {
  /**
   * @generated from enum value: ASSET_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ASSET_STATE_ACTIVE = 1;
   */
  ACTIVE = 1,

  /**
   * @generated from enum value: ASSET_STATE_DRAFT = 2;
   */
  DRAFT = 2,

  /**
   * @generated from enum value: ASSET_STATE_ARCHIVED = 3;
   */
  ARCHIVED = 3
}
// Retrieve enum metadata with: proto3.getEnumType(AssetState)
proto3.util.setEnumType(AssetState, 'asset.v1.AssetState', [
  { no: 0, name: 'ASSET_STATE_UNSPECIFIED' },
  { no: 1, name: 'ASSET_STATE_ACTIVE' },
  { no: 2, name: 'ASSET_STATE_DRAFT' },
  { no: 3, name: 'ASSET_STATE_ARCHIVED' }
])

/**
 * @generated from enum asset.v1.AssetType
 */
export enum AssetType {
  /**
   * @generated from enum value: ASSET_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ASSET_TYPE_CODE = 1;
   */
  CODE = 1,

  /**
   * @generated from enum value: ASSET_TYPE_PAGE = 2;
   */
  PAGE = 2,

  /**
   * @generated from enum value: ASSET_TYPE_PAGE_V2 = 3;
   */
  PAGE_V2 = 3,

  /**
   * @generated from enum value: ASSET_TYPE_PAGE_TEMPLATE_V2 = 4;
   */
  PAGE_TEMPLATE_V2 = 4,

  /**
   * @generated from enum value: ASSET_TYPE_BUNDLE = 5;
   */
  BUNDLE = 5,

  /**
   * @generated from enum value: ASSET_TYPE_SUITE = 6;
   */
  SUITE = 6,

  /**
   * @generated from enum value: ASSET_TYPE_BRAND_KIT = 7;
   */
  BRAND_KIT = 7,

  /**
   * @generated from enum value: ASSET_TYPE_STUDIO_CONFIG = 8;
   */
  STUDIO_CONFIG = 8,

  /**
   * @generated from enum value: ASSET_TYPE_CODE_V3 = 9;
   */
  CODE_V3 = 9,

  /**
   * @generated from enum value: ASSET_TYPE_BATCH = 10;
   */
  BATCH = 10,

  /**
   * @generated from enum value: ASSET_TYPE_BATCH_TEMPLATE = 11;
   */
  BATCH_TEMPLATE = 11
}
// Retrieve enum metadata with: proto3.getEnumType(AssetType)
proto3.util.setEnumType(AssetType, 'asset.v1.AssetType', [
  { no: 0, name: 'ASSET_TYPE_UNSPECIFIED' },
  { no: 1, name: 'ASSET_TYPE_CODE' },
  { no: 2, name: 'ASSET_TYPE_PAGE' },
  { no: 3, name: 'ASSET_TYPE_PAGE_V2' },
  { no: 4, name: 'ASSET_TYPE_PAGE_TEMPLATE_V2' },
  { no: 5, name: 'ASSET_TYPE_BUNDLE' },
  { no: 6, name: 'ASSET_TYPE_SUITE' },
  { no: 7, name: 'ASSET_TYPE_BRAND_KIT' },
  { no: 8, name: 'ASSET_TYPE_STUDIO_CONFIG' },
  { no: 9, name: 'ASSET_TYPE_CODE_V3' },
  { no: 10, name: 'ASSET_TYPE_BATCH' },
  { no: 11, name: 'ASSET_TYPE_BATCH_TEMPLATE' }
])

/**
 * @generated from enum asset.v1.CreationMethod
 */
export enum CreationMethod {
  /**
   * @generated from enum value: CREATION_METHOD_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CREATION_METHOD_PLATFORM = 1;
   */
  PLATFORM = 1,

  /**
   * @generated from enum value: CREATION_METHOD_API = 2;
   */
  API = 2
}
// Retrieve enum metadata with: proto3.getEnumType(CreationMethod)
proto3.util.setEnumType(CreationMethod, 'asset.v1.CreationMethod', [
  { no: 0, name: 'CREATION_METHOD_UNSPECIFIED' },
  { no: 1, name: 'CREATION_METHOD_PLATFORM' },
  { no: 2, name: 'CREATION_METHOD_API' }
])

/**
 * @generated from enum asset.v1.ArchiveReason
 */
export enum ArchiveReason {
  /**
   * @generated from enum value: ARCHIVE_REASON_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ARCHIVE_REASON_USER_ARCHIVAL = 1;
   */
  USER_ARCHIVAL = 1,

  /**
   * @generated from enum value: ARCHIVE_REASON_SUBSCRIPTION_CANCELLED = 2;
   */
  SUBSCRIPTION_CANCELLED = 2,

  /**
   * @generated from enum value: ARCHIVE_REASON_CONTENT_MODERATION = 3;
   */
  CONTENT_MODERATION = 3,

  /**
   * @generated from enum value: ARCHIVE_REASON_ML_MODERATION = 4;
   */
  ML_MODERATION = 4
}
// Retrieve enum metadata with: proto3.getEnumType(ArchiveReason)
proto3.util.setEnumType(ArchiveReason, 'asset.v1.ArchiveReason', [
  { no: 0, name: 'ARCHIVE_REASON_UNSPECIFIED' },
  { no: 1, name: 'ARCHIVE_REASON_USER_ARCHIVAL' },
  { no: 2, name: 'ARCHIVE_REASON_SUBSCRIPTION_CANCELLED' },
  { no: 3, name: 'ARCHIVE_REASON_CONTENT_MODERATION' },
  { no: 4, name: 'ARCHIVE_REASON_ML_MODERATION' }
])

/**
 * @generated from enum asset.v1.Visibility
 */
export enum Visibility {
  /**
   * @generated from enum value: VISIBILITY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: VISIBILITY_PRIVATE = 1;
   */
  PRIVATE = 1,

  /**
   * @generated from enum value: VISIBILITY_PUBLIC = 2;
   */
  PUBLIC = 2,

  /**
   * @generated from enum value: VISIBILITY_ORG = 3;
   */
  ORG = 3
}
// Retrieve enum metadata with: proto3.getEnumType(Visibility)
proto3.util.setEnumType(Visibility, 'asset.v1.Visibility', [
  { no: 0, name: 'VISIBILITY_UNSPECIFIED' },
  { no: 1, name: 'VISIBILITY_PRIVATE' },
  { no: 2, name: 'VISIBILITY_PUBLIC' },
  { no: 3, name: 'VISIBILITY_ORG' }
])

/**
 * @generated from enum asset.v1.Location
 */
export enum Location {
  /**
   * @generated from enum value: LOCATION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: LOCATION_ROOT = 1;
   */
  ROOT = 1,

  /**
   * @generated from enum value: LOCATION_FOLDER = 2;
   */
  FOLDER = 2
}
// Retrieve enum metadata with: proto3.getEnumType(Location)
proto3.util.setEnumType(Location, 'asset.v1.Location', [
  { no: 0, name: 'LOCATION_UNSPECIFIED' },
  { no: 1, name: 'LOCATION_ROOT' },
  { no: 2, name: 'LOCATION_FOLDER' }
])

/**
 * @generated from message asset.v1.Asset
 */
export class Asset extends Message<Asset> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string name = 14;
   */
  name = ''

  /**
   * @generated from field: string description = 15;
   */
  description = ''

  /**
   * @generated from field: string path = 2;
   */
  path = ''

  /**
   * @generated from field: string workspace_id = 3;
   */
  workspaceId = ''

  /**
   * @generated from field: string org_id = 4;
   */
  orgId = ''

  /**
   * @generated from field: string creator_id = 5;
   */
  creatorId = ''

  /**
   * @generated from field: string owner_id = 16;
   */
  ownerId = ''

  /**
   * @generated from field: asset.v1.AssetType asset_type = 6;
   */
  assetType = AssetType.UNSPECIFIED

  /**
   * @generated from field: asset.v1.AssetState state = 7;
   */
  state = AssetState.UNSPECIFIED

  /**
   * @generated from field: string version = 22;
   */
  version = ''

  /**
   * @generated from field: asset.v1.CreationMethod creation_method = 8;
   */
  creationMethod = CreationMethod.UNSPECIFIED

  /**
   * @generated from field: google.protobuf.Any data = 9;
   */
  data?: Any

  /**
   * @generated from field: repeated string tags = 11;
   */
  tags: string[] = []

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 12;
   */
  createdAt?: Timestamp

  /**
   * @generated from field: optional google.protobuf.Timestamp updated_at = 13;
   */
  updatedAt?: Timestamp

  /**
   * @generated from field: optional google.protobuf.Timestamp archived_at = 20;
   */
  archivedAt?: Timestamp

  /**
   * @generated from field: string opaque_id = 17;
   */
  opaqueId = ''

  /**
   * @generated from field: asset.v1.Visibility visibility = 18;
   */
  visibility = Visibility.UNSPECIFIED

  /**
   * @generated from field: asset.v1.ArchiveReason archive_reason = 19;
   */
  archiveReason = ArchiveReason.UNSPECIFIED

  /**
   * @generated from field: string category = 21;
   */
  category = ''

  constructor(data?: PartialMessage<Asset>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.Asset'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 14, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 15, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'path', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'workspace_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'creator_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 16, name: 'owner_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'asset_type', kind: 'enum', T: proto3.getEnumType(AssetType) },
    { no: 7, name: 'state', kind: 'enum', T: proto3.getEnumType(AssetState) },
    { no: 22, name: 'version', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 8, name: 'creation_method', kind: 'enum', T: proto3.getEnumType(CreationMethod) },
    { no: 9, name: 'data', kind: 'message', T: Any },
    { no: 11, name: 'tags', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 13, name: 'updated_at', kind: 'message', T: Timestamp, opt: true },
    { no: 20, name: 'archived_at', kind: 'message', T: Timestamp, opt: true },
    { no: 17, name: 'opaque_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 18, name: 'visibility', kind: 'enum', T: proto3.getEnumType(Visibility) },
    { no: 19, name: 'archive_reason', kind: 'enum', T: proto3.getEnumType(ArchiveReason) },
    { no: 21, name: 'category', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Asset {
    return new Asset().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Asset {
    return new Asset().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Asset {
    return new Asset().fromJsonString(jsonString, options)
  }

  static equals(
    a: Asset | PlainMessage<Asset> | undefined,
    b: Asset | PlainMessage<Asset> | undefined
  ): boolean {
    return proto3.util.equals(Asset, a, b)
  }
}

/**
 * @generated from message asset.v1.DateRange
 */
export class DateRange extends Message<DateRange> {
  /**
   * Optional timestamp in RFC3339 format
   *
   * @generated from field: string start_date = 1;
   */
  startDate = ''

  /**
   * optional timestamp in RFC3339 format
   *
   * @generated from field: string end_date = 2;
   */
  endDate = ''

  constructor(data?: PartialMessage<DateRange>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.DateRange'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'start_date', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'end_date', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DateRange {
    return new DateRange().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DateRange {
    return new DateRange().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DateRange {
    return new DateRange().fromJsonString(jsonString, options)
  }

  static equals(
    a: DateRange | PlainMessage<DateRange> | undefined,
    b: DateRange | PlainMessage<DateRange> | undefined
  ): boolean {
    return proto3.util.equals(DateRange, a, b)
  }
}

/**
 * If multiple filters are provided, they are combined together with the AND condition
 *
 * @generated from message asset.v1.AssetFilter
 */
export class AssetFilter extends Message<AssetFilter> {
  /**
   * returns assets only of the provides types
   *
   * @generated from field: repeated asset.v1.AssetType asset_types = 1;
   */
  assetTypes: AssetType[] = []

  /**
   * returns assets only in the provides states
   *
   * @generated from field: repeated asset.v1.AssetState states = 2;
   */
  states: AssetState[] = []

  /**
   * returns assets only in the provided creation method
   *
   * @generated from field: optional asset.v1.CreationMethod creation_method = 3;
   */
  creationMethod?: CreationMethod

  /**
   * returns only assets by the creator
   *
   * @generated from field: string creator_id = 4;
   */
  creatorId = ''

  /**
   * returns assets that match the supplied name prefix
   *
   * @generated from field: string name_prefix = 5;
   */
  namePrefix = ''

  /**
   * returns assets associated with any of the supplied tags
   *
   * @generated from field: repeated string tags = 6;
   */
  tags: string[] = []

  /**
   * CreatedAtDateRange scopes the response to assets created in the specified range. The filter is exclusive
   *
   * When start_date is empty the filtering will get everything created up to end_date
   * When end_date is empty the filtering will get everything created from the start_date up until now
   *
   * @generated from field: optional asset.v1.DateRange created_at_date_range = 7;
   */
  createdAtDateRange?: DateRange

  /**
   * returns only assets by the owner
   *
   * @generated from field: repeated string owner_ids = 8;
   */
  ownerIds: string[] = []

  /**
   * @generated from field: string opaque_id = 9;
   */
  opaqueId = ''

  /**
   * @generated from field: repeated asset.v1.Visibility visibilities = 11;
   */
  visibilities: Visibility[] = []

  /**
   * @generated from field: repeated string categories = 12;
   */
  categories: string[] = []

  /**
   * @generated from field: optional asset.v1.Location location = 13;
   */
  location?: Location

  constructor(data?: PartialMessage<AssetFilter>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.AssetFilter'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'asset_types', kind: 'enum', T: proto3.getEnumType(AssetType), repeated: true },
    { no: 2, name: 'states', kind: 'enum', T: proto3.getEnumType(AssetState), repeated: true },
    {
      no: 3,
      name: 'creation_method',
      kind: 'enum',
      T: proto3.getEnumType(CreationMethod),
      opt: true
    },
    { no: 4, name: 'creator_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'name_prefix', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'tags', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: 'created_at_date_range', kind: 'message', T: DateRange, opt: true },
    { no: 8, name: 'owner_ids', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: 'opaque_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 11,
      name: 'visibilities',
      kind: 'enum',
      T: proto3.getEnumType(Visibility),
      repeated: true
    },
    { no: 12, name: 'categories', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: 'location', kind: 'enum', T: proto3.getEnumType(Location), opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssetFilter {
    return new AssetFilter().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssetFilter {
    return new AssetFilter().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssetFilter {
    return new AssetFilter().fromJsonString(jsonString, options)
  }

  static equals(
    a: AssetFilter | PlainMessage<AssetFilter> | undefined,
    b: AssetFilter | PlainMessage<AssetFilter> | undefined
  ): boolean {
    return proto3.util.equals(AssetFilter, a, b)
  }
}

/**
 * @generated from message asset.v1.UpdateAssetRequest
 */
export class UpdateAssetRequest extends Message<UpdateAssetRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string version = 10;
   */
  version = ''

  /**
   * @generated from field: optional string path = 2 [deprecated = true];
   * @deprecated
   */
  path?: string

  /**
   * optional tags needed as we migrate tagging into the assets-service
   * updating an assets tag will hit the assets-service mutations after migrations
   *
   * @generated from field: repeated string tags = 3;
   */
  tags: string[] = []

  /**
   * optional data to store with this asset
   *
   * @generated from field: google.protobuf.Any data = 4;
   */
  data?: Any

  /**
   * Name is an optional display name associated to the asset.
   *
   * @generated from field: optional string name = 5;
   */
  name?: string

  /**
   * Description is an optional description associated to the asset.
   *
   * @generated from field: optional string description = 6;
   */
  description?: string

  /**
   * OwnerId is an optional field used to "transfer ownership" of an asset
   *
   * @generated from field: optional string owner_id = 7;
   */
  ownerId?: string

  /**
   * @generated from field: asset.v1.Visibility visibility = 8;
   */
  visibility = Visibility.UNSPECIFIED

  /**
   * @generated from field: optional string category = 9;
   */
  category?: string

  constructor(data?: PartialMessage<UpdateAssetRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.UpdateAssetRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 10, name: 'version', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'path', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: 'tags', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: 'data', kind: 'message', T: Any },
    { no: 5, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: 'owner_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: 'visibility', kind: 'enum', T: proto3.getEnumType(Visibility) },
    { no: 9, name: 'category', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAssetRequest {
    return new UpdateAssetRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAssetRequest {
    return new UpdateAssetRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateAssetRequest {
    return new UpdateAssetRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateAssetRequest | PlainMessage<UpdateAssetRequest> | undefined,
    b: UpdateAssetRequest | PlainMessage<UpdateAssetRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateAssetRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.UpdateAssetsRequest
 */
export class UpdateAssetsRequest extends Message<UpdateAssetsRequest> {
  /**
   * @generated from field: repeated asset.v1.UpdateAssetRequest assets = 1;
   */
  assets: UpdateAssetRequest[] = []

  constructor(data?: PartialMessage<UpdateAssetsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.UpdateAssetsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'assets', kind: 'message', T: UpdateAssetRequest, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAssetsRequest {
    return new UpdateAssetsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAssetsRequest {
    return new UpdateAssetsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateAssetsRequest {
    return new UpdateAssetsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateAssetsRequest | PlainMessage<UpdateAssetsRequest> | undefined,
    b: UpdateAssetsRequest | PlainMessage<UpdateAssetsRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateAssetsRequest, a, b)
  }
}

/**
 * ArchiveAssetRequest updates the state of the asset to archived
 *
 * @generated from message asset.v1.ArchiveAssetsRequest
 */
export class ArchiveAssetsRequest extends Message<ArchiveAssetsRequest> {
  /**
   * @generated from field: repeated string ids = 1;
   */
  ids: string[] = []

  /**
   * @generated from field: optional asset.v1.ArchiveReason archive_reason = 2;
   */
  archiveReason?: ArchiveReason

  constructor(data?: PartialMessage<ArchiveAssetsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.ArchiveAssetsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'ids', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: 'archive_reason', kind: 'enum', T: proto3.getEnumType(ArchiveReason), opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ArchiveAssetsRequest {
    return new ArchiveAssetsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArchiveAssetsRequest {
    return new ArchiveAssetsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ArchiveAssetsRequest {
    return new ArchiveAssetsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ArchiveAssetsRequest | PlainMessage<ArchiveAssetsRequest> | undefined,
    b: ArchiveAssetsRequest | PlainMessage<ArchiveAssetsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ArchiveAssetsRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.ArchiveAssetRequest
 */
export class ArchiveAssetRequest extends Message<ArchiveAssetRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: optional asset.v1.ArchiveReason archive_reason = 2;
   */
  archiveReason?: ArchiveReason

  constructor(data?: PartialMessage<ArchiveAssetRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.ArchiveAssetRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'archive_reason', kind: 'enum', T: proto3.getEnumType(ArchiveReason), opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ArchiveAssetRequest {
    return new ArchiveAssetRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArchiveAssetRequest {
    return new ArchiveAssetRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ArchiveAssetRequest {
    return new ArchiveAssetRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ArchiveAssetRequest | PlainMessage<ArchiveAssetRequest> | undefined,
    b: ArchiveAssetRequest | PlainMessage<ArchiveAssetRequest> | undefined
  ): boolean {
    return proto3.util.equals(ArchiveAssetRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.RestoreAssetRequest
 */
export class RestoreAssetRequest extends Message<RestoreAssetRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<RestoreAssetRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.RestoreAssetRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RestoreAssetRequest {
    return new RestoreAssetRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RestoreAssetRequest {
    return new RestoreAssetRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RestoreAssetRequest {
    return new RestoreAssetRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: RestoreAssetRequest | PlainMessage<RestoreAssetRequest> | undefined,
    b: RestoreAssetRequest | PlainMessage<RestoreAssetRequest> | undefined
  ): boolean {
    return proto3.util.equals(RestoreAssetRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.RestoreAssetsRequest
 */
export class RestoreAssetsRequest extends Message<RestoreAssetsRequest> {
  /**
   * @generated from field: repeated string ids = 1;
   */
  ids: string[] = []

  constructor(data?: PartialMessage<RestoreAssetsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.RestoreAssetsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'ids', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RestoreAssetsRequest {
    return new RestoreAssetsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RestoreAssetsRequest {
    return new RestoreAssetsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RestoreAssetsRequest {
    return new RestoreAssetsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: RestoreAssetsRequest | PlainMessage<RestoreAssetsRequest> | undefined,
    b: RestoreAssetsRequest | PlainMessage<RestoreAssetsRequest> | undefined
  ): boolean {
    return proto3.util.equals(RestoreAssetsRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.DeleteAssetRequest
 */
export class DeleteAssetRequest extends Message<DeleteAssetRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<DeleteAssetRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.DeleteAssetRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAssetRequest {
    return new DeleteAssetRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAssetRequest {
    return new DeleteAssetRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteAssetRequest {
    return new DeleteAssetRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteAssetRequest | PlainMessage<DeleteAssetRequest> | undefined,
    b: DeleteAssetRequest | PlainMessage<DeleteAssetRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteAssetRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.DeleteAssetsRequest
 */
export class DeleteAssetsRequest extends Message<DeleteAssetsRequest> {
  /**
   * @generated from field: repeated string ids = 1;
   */
  ids: string[] = []

  constructor(data?: PartialMessage<DeleteAssetsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.DeleteAssetsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'ids', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAssetsRequest {
    return new DeleteAssetsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAssetsRequest {
    return new DeleteAssetsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteAssetsRequest {
    return new DeleteAssetsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteAssetsRequest | PlainMessage<DeleteAssetsRequest> | undefined,
    b: DeleteAssetsRequest | PlainMessage<DeleteAssetsRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteAssetsRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.GetAssetRequest
 */
export class GetAssetRequest extends Message<GetAssetRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string version = 2;
   */
  version = ''

  constructor(data?: PartialMessage<GetAssetRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.GetAssetRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'version', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssetRequest {
    return new GetAssetRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssetRequest {
    return new GetAssetRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssetRequest {
    return new GetAssetRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetAssetRequest | PlainMessage<GetAssetRequest> | undefined,
    b: GetAssetRequest | PlainMessage<GetAssetRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetAssetRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.GetAssetsRequest
 */
export class GetAssetsRequest extends Message<GetAssetsRequest> {
  /**
   * @(metabase) Implicitly gets most recent version
   *
   * @generated from field: repeated string ids = 1;
   */
  ids: string[] = []

  /**
   * @generated from field: repeated asset.v1.GetAssetRequest requests = 2;
   */
  requests: GetAssetRequest[] = []

  constructor(data?: PartialMessage<GetAssetsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.GetAssetsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'ids', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: 'requests', kind: 'message', T: GetAssetRequest, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssetsRequest {
    return new GetAssetsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssetsRequest {
    return new GetAssetsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssetsRequest {
    return new GetAssetsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetAssetsRequest | PlainMessage<GetAssetsRequest> | undefined,
    b: GetAssetsRequest | PlainMessage<GetAssetsRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetAssetsRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.OrgAndWorkspace
 */
export class OrgAndWorkspace extends Message<OrgAndWorkspace> {
  /**
   * @generated from field: string org_id = 1;
   */
  orgId = ''

  /**
   * @generated from field: string workspace_id = 2;
   */
  workspaceId = ''

  constructor(data?: PartialMessage<OrgAndWorkspace>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.OrgAndWorkspace'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'workspace_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrgAndWorkspace {
    return new OrgAndWorkspace().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrgAndWorkspace {
    return new OrgAndWorkspace().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrgAndWorkspace {
    return new OrgAndWorkspace().fromJsonString(jsonString, options)
  }

  static equals(
    a: OrgAndWorkspace | PlainMessage<OrgAndWorkspace> | undefined,
    b: OrgAndWorkspace | PlainMessage<OrgAndWorkspace> | undefined
  ): boolean {
    return proto3.util.equals(OrgAndWorkspace, a, b)
  }
}

/**
 * @generated from message asset.v1.ListAssetsRequest
 */
export class ListAssetsRequest extends Message<ListAssetsRequest> {
  /**
   * @generated from oneof asset.v1.ListAssetsRequest.id
   */
  id:
    | {
        /**
         * @generated from field: string org_id = 1;
         */
        value: string
        case: 'orgId'
      }
    | {
        /**
         * @generated from field: string workspace_id = 2;
         */
        value: string
        case: 'workspaceId'
      }
    | {
        /**
         * @generated from field: string folder_id = 3;
         */
        value: string
        case: 'folderId'
      }
    | {
        /**
         * @generated from field: asset.v1.OrgAndWorkspace org_and_workspace = 6;
         */
        value: OrgAndWorkspace
        case: 'orgAndWorkspace'
      }
    | { case: undefined; value?: undefined } = { case: undefined }

  /**
   * @generated from field: asset.v1.AssetFilter filter = 4;
   */
  filter?: AssetFilter

  /**
   * @generated from field: optional common.v1.PaginationArguments paginate = 5;
   */
  paginate?: PaginationArguments

  constructor(data?: PartialMessage<ListAssetsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.ListAssetsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'id' },
    { no: 2, name: 'workspace_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'id' },
    { no: 3, name: 'folder_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'id' },
    { no: 6, name: 'org_and_workspace', kind: 'message', T: OrgAndWorkspace, oneof: 'id' },
    { no: 4, name: 'filter', kind: 'message', T: AssetFilter },
    { no: 5, name: 'paginate', kind: 'message', T: PaginationArguments, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssetsRequest {
    return new ListAssetsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssetsRequest {
    return new ListAssetsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAssetsRequest {
    return new ListAssetsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListAssetsRequest | PlainMessage<ListAssetsRequest> | undefined,
    b: ListAssetsRequest | PlainMessage<ListAssetsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListAssetsRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.CountAssetsRequest
 */
export class CountAssetsRequest extends Message<CountAssetsRequest> {
  /**
   * @generated from oneof asset.v1.CountAssetsRequest.id
   */
  id:
    | {
        /**
         * @generated from field: string org_id = 1;
         */
        value: string
        case: 'orgId'
      }
    | {
        /**
         * @generated from field: string workspace_id = 2;
         */
        value: string
        case: 'workspaceId'
      }
    | {
        /**
         * @generated from field: string folder_id = 3;
         */
        value: string
        case: 'folderId'
      }
    | { case: undefined; value?: undefined } = { case: undefined }

  /**
   * @generated from field: asset.v1.AssetFilter filter = 4;
   */
  filter?: AssetFilter

  constructor(data?: PartialMessage<CountAssetsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.CountAssetsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'id' },
    { no: 2, name: 'workspace_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'id' },
    { no: 3, name: 'folder_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'id' },
    { no: 4, name: 'filter', kind: 'message', T: AssetFilter }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CountAssetsRequest {
    return new CountAssetsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CountAssetsRequest {
    return new CountAssetsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CountAssetsRequest {
    return new CountAssetsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CountAssetsRequest | PlainMessage<CountAssetsRequest> | undefined,
    b: CountAssetsRequest | PlainMessage<CountAssetsRequest> | undefined
  ): boolean {
    return proto3.util.equals(CountAssetsRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.MoveAssetsRequest
 */
export class MoveAssetsRequest extends Message<MoveAssetsRequest> {
  /**
   * @generated from field: repeated string asset_ids = 1;
   */
  assetIds: string[] = []

  /**
   * the destination id to move all the asset_ids to
   *
   * @generated from oneof asset.v1.MoveAssetsRequest.id
   */
  id:
    | {
        /**
         * @generated from field: string org_id = 2;
         */
        value: string
        case: 'orgId'
      }
    | {
        /**
         * @generated from field: string workspace_id = 3;
         */
        value: string
        case: 'workspaceId'
      }
    | {
        /**
         * @generated from field: string folder_id = 4;
         */
        value: string
        case: 'folderId'
      }
    | { case: undefined; value?: undefined } = { case: undefined }

  constructor(data?: PartialMessage<MoveAssetsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.MoveAssetsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'asset_ids', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'id' },
    { no: 3, name: 'workspace_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'id' },
    { no: 4, name: 'folder_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'id' }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MoveAssetsRequest {
    return new MoveAssetsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MoveAssetsRequest {
    return new MoveAssetsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MoveAssetsRequest {
    return new MoveAssetsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: MoveAssetsRequest | PlainMessage<MoveAssetsRequest> | undefined,
    b: MoveAssetsRequest | PlainMessage<MoveAssetsRequest> | undefined
  ): boolean {
    return proto3.util.equals(MoveAssetsRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.EmptyArchiveRequest
 */
export class EmptyArchiveRequest extends Message<EmptyArchiveRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = ''

  constructor(data?: PartialMessage<EmptyArchiveRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.EmptyArchiveRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'user_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EmptyArchiveRequest {
    return new EmptyArchiveRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EmptyArchiveRequest {
    return new EmptyArchiveRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): EmptyArchiveRequest {
    return new EmptyArchiveRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: EmptyArchiveRequest | PlainMessage<EmptyArchiveRequest> | undefined,
    b: EmptyArchiveRequest | PlainMessage<EmptyArchiveRequest> | undefined
  ): boolean {
    return proto3.util.equals(EmptyArchiveRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.UpdateAssetResponse
 */
export class UpdateAssetResponse extends Message<UpdateAssetResponse> {
  /**
   * @generated from field: asset.v1.Asset asset = 1;
   */
  asset?: Asset

  constructor(data?: PartialMessage<UpdateAssetResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.UpdateAssetResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'asset', kind: 'message', T: Asset }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAssetResponse {
    return new UpdateAssetResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAssetResponse {
    return new UpdateAssetResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateAssetResponse {
    return new UpdateAssetResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateAssetResponse | PlainMessage<UpdateAssetResponse> | undefined,
    b: UpdateAssetResponse | PlainMessage<UpdateAssetResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateAssetResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.UpdateAssetsResponse
 */
export class UpdateAssetsResponse extends Message<UpdateAssetsResponse> {
  /**
   * @generated from field: repeated asset.v1.Asset assets = 1;
   */
  assets: Asset[] = []

  constructor(data?: PartialMessage<UpdateAssetsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.UpdateAssetsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'assets', kind: 'message', T: Asset, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAssetsResponse {
    return new UpdateAssetsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAssetsResponse {
    return new UpdateAssetsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateAssetsResponse {
    return new UpdateAssetsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateAssetsResponse | PlainMessage<UpdateAssetsResponse> | undefined,
    b: UpdateAssetsResponse | PlainMessage<UpdateAssetsResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateAssetsResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.ArchiveAssetsResponse
 */
export class ArchiveAssetsResponse extends Message<ArchiveAssetsResponse> {
  /**
   * @generated from field: repeated asset.v1.Asset assets = 1;
   */
  assets: Asset[] = []

  constructor(data?: PartialMessage<ArchiveAssetsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.ArchiveAssetsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'assets', kind: 'message', T: Asset, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ArchiveAssetsResponse {
    return new ArchiveAssetsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArchiveAssetsResponse {
    return new ArchiveAssetsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ArchiveAssetsResponse {
    return new ArchiveAssetsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ArchiveAssetsResponse | PlainMessage<ArchiveAssetsResponse> | undefined,
    b: ArchiveAssetsResponse | PlainMessage<ArchiveAssetsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ArchiveAssetsResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.ArchiveAssetResponse
 */
export class ArchiveAssetResponse extends Message<ArchiveAssetResponse> {
  /**
   * @generated from field: asset.v1.Asset asset = 1;
   */
  asset?: Asset

  constructor(data?: PartialMessage<ArchiveAssetResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.ArchiveAssetResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'asset', kind: 'message', T: Asset }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ArchiveAssetResponse {
    return new ArchiveAssetResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArchiveAssetResponse {
    return new ArchiveAssetResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ArchiveAssetResponse {
    return new ArchiveAssetResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ArchiveAssetResponse | PlainMessage<ArchiveAssetResponse> | undefined,
    b: ArchiveAssetResponse | PlainMessage<ArchiveAssetResponse> | undefined
  ): boolean {
    return proto3.util.equals(ArchiveAssetResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.RestoreAssetResponse
 */
export class RestoreAssetResponse extends Message<RestoreAssetResponse> {
  /**
   * @generated from field: asset.v1.Asset asset = 1;
   */
  asset?: Asset

  constructor(data?: PartialMessage<RestoreAssetResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.RestoreAssetResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'asset', kind: 'message', T: Asset }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RestoreAssetResponse {
    return new RestoreAssetResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RestoreAssetResponse {
    return new RestoreAssetResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RestoreAssetResponse {
    return new RestoreAssetResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: RestoreAssetResponse | PlainMessage<RestoreAssetResponse> | undefined,
    b: RestoreAssetResponse | PlainMessage<RestoreAssetResponse> | undefined
  ): boolean {
    return proto3.util.equals(RestoreAssetResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.RestoreAssetsResponse
 */
export class RestoreAssetsResponse extends Message<RestoreAssetsResponse> {
  /**
   * @generated from field: repeated asset.v1.Asset assets = 1;
   */
  assets: Asset[] = []

  constructor(data?: PartialMessage<RestoreAssetsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.RestoreAssetsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'assets', kind: 'message', T: Asset, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RestoreAssetsResponse {
    return new RestoreAssetsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RestoreAssetsResponse {
    return new RestoreAssetsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RestoreAssetsResponse {
    return new RestoreAssetsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: RestoreAssetsResponse | PlainMessage<RestoreAssetsResponse> | undefined,
    b: RestoreAssetsResponse | PlainMessage<RestoreAssetsResponse> | undefined
  ): boolean {
    return proto3.util.equals(RestoreAssetsResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.DeleteAssetResponse
 */
export class DeleteAssetResponse extends Message<DeleteAssetResponse> {
  constructor(data?: PartialMessage<DeleteAssetResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.DeleteAssetResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAssetResponse {
    return new DeleteAssetResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAssetResponse {
    return new DeleteAssetResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteAssetResponse {
    return new DeleteAssetResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteAssetResponse | PlainMessage<DeleteAssetResponse> | undefined,
    b: DeleteAssetResponse | PlainMessage<DeleteAssetResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteAssetResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.DeleteAssetsResponse
 */
export class DeleteAssetsResponse extends Message<DeleteAssetsResponse> {
  constructor(data?: PartialMessage<DeleteAssetsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.DeleteAssetsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAssetsResponse {
    return new DeleteAssetsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAssetsResponse {
    return new DeleteAssetsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteAssetsResponse {
    return new DeleteAssetsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: DeleteAssetsResponse | PlainMessage<DeleteAssetsResponse> | undefined,
    b: DeleteAssetsResponse | PlainMessage<DeleteAssetsResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteAssetsResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.GetAssetResponse
 */
export class GetAssetResponse extends Message<GetAssetResponse> {
  /**
   * @generated from field: asset.v1.Asset asset = 1;
   */
  asset?: Asset

  constructor(data?: PartialMessage<GetAssetResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.GetAssetResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'asset', kind: 'message', T: Asset }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssetResponse {
    return new GetAssetResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssetResponse {
    return new GetAssetResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssetResponse {
    return new GetAssetResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetAssetResponse | PlainMessage<GetAssetResponse> | undefined,
    b: GetAssetResponse | PlainMessage<GetAssetResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetAssetResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.GetAssetsResponse
 */
export class GetAssetsResponse extends Message<GetAssetsResponse> {
  /**
   * @generated from field: repeated asset.v1.Asset assets = 1;
   */
  assets: Asset[] = []

  constructor(data?: PartialMessage<GetAssetsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.GetAssetsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'assets', kind: 'message', T: Asset, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssetsResponse {
    return new GetAssetsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssetsResponse {
    return new GetAssetsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssetsResponse {
    return new GetAssetsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetAssetsResponse | PlainMessage<GetAssetsResponse> | undefined,
    b: GetAssetsResponse | PlainMessage<GetAssetsResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetAssetsResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.ListVersionsRequest
 */
export class ListVersionsRequest extends Message<ListVersionsRequest> {
  /**
   * @generated from field: string asset_id = 1;
   */
  assetId = ''

  /**
   * @generated from field: string org_id = 2;
   */
  orgId = ''

  /**
   * @generated from field: string workspace_id = 3;
   */
  workspaceId = ''

  /**
   * @generated from field: optional common.v1.PaginationArguments paginate = 4;
   */
  paginate?: PaginationArguments

  constructor(data?: PartialMessage<ListVersionsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.ListVersionsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'workspace_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'paginate', kind: 'message', T: PaginationArguments, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListVersionsRequest {
    return new ListVersionsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListVersionsRequest {
    return new ListVersionsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListVersionsRequest {
    return new ListVersionsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListVersionsRequest | PlainMessage<ListVersionsRequest> | undefined,
    b: ListVersionsRequest | PlainMessage<ListVersionsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListVersionsRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.ListVersionsResponse
 */
export class ListVersionsResponse extends Message<ListVersionsResponse> {
  /**
   * @generated from field: repeated asset.v1.Asset assets = 1;
   */
  assets: Asset[] = []

  /**
   * @generated from field: common.v1.PaginationPageInfo page_info = 2;
   */
  pageInfo?: PaginationPageInfo

  constructor(data?: PartialMessage<ListVersionsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.ListVersionsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'assets', kind: 'message', T: Asset, repeated: true },
    { no: 2, name: 'page_info', kind: 'message', T: PaginationPageInfo }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListVersionsResponse {
    return new ListVersionsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListVersionsResponse {
    return new ListVersionsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListVersionsResponse {
    return new ListVersionsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListVersionsResponse | PlainMessage<ListVersionsResponse> | undefined,
    b: ListVersionsResponse | PlainMessage<ListVersionsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListVersionsResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.CreateVersionRequest
 */
export class CreateVersionRequest extends Message<CreateVersionRequest> {
  /**
   * @generated from field: string asset_id = 1;
   */
  assetId = ''

  /**
   * @generated from field: string version = 2;
   */
  version = ''

  /**
   * if set, specifies version to clone into a new one, otherwise uses most recent version
   *
   * @generated from field: string base_version = 3;
   */
  baseVersion = ''

  constructor(data?: PartialMessage<CreateVersionRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.CreateVersionRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'version', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'base_version', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateVersionRequest {
    return new CreateVersionRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateVersionRequest {
    return new CreateVersionRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateVersionRequest {
    return new CreateVersionRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateVersionRequest | PlainMessage<CreateVersionRequest> | undefined,
    b: CreateVersionRequest | PlainMessage<CreateVersionRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateVersionRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.CreateVersionResponse
 */
export class CreateVersionResponse extends Message<CreateVersionResponse> {
  /**
   * @generated from field: asset.v1.Asset asset = 1;
   */
  asset?: Asset

  constructor(data?: PartialMessage<CreateVersionResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.CreateVersionResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'asset', kind: 'message', T: Asset }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateVersionResponse {
    return new CreateVersionResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateVersionResponse {
    return new CreateVersionResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateVersionResponse {
    return new CreateVersionResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateVersionResponse | PlainMessage<CreateVersionResponse> | undefined,
    b: CreateVersionResponse | PlainMessage<CreateVersionResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateVersionResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.PublishVersionRequest
 */
export class PublishVersionRequest extends Message<PublishVersionRequest> {
  /**
   * @generated from field: string asset_id = 1;
   */
  assetId = ''

  /**
   * @generated from field: string version = 2;
   */
  version = ''

  /**
   * @generated from field: asset.v1.Visibility visibility = 3;
   */
  visibility = Visibility.UNSPECIFIED

  constructor(data?: PartialMessage<PublishVersionRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.PublishVersionRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'version', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'visibility', kind: 'enum', T: proto3.getEnumType(Visibility) }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): PublishVersionRequest {
    return new PublishVersionRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PublishVersionRequest {
    return new PublishVersionRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): PublishVersionRequest {
    return new PublishVersionRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: PublishVersionRequest | PlainMessage<PublishVersionRequest> | undefined,
    b: PublishVersionRequest | PlainMessage<PublishVersionRequest> | undefined
  ): boolean {
    return proto3.util.equals(PublishVersionRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.PublishVersionResponse
 */
export class PublishVersionResponse extends Message<PublishVersionResponse> {
  /**
   * @generated from field: asset.v1.Asset asset = 1;
   */
  asset?: Asset

  constructor(data?: PartialMessage<PublishVersionResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.PublishVersionResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'asset', kind: 'message', T: Asset }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): PublishVersionResponse {
    return new PublishVersionResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): PublishVersionResponse {
    return new PublishVersionResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): PublishVersionResponse {
    return new PublishVersionResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: PublishVersionResponse | PlainMessage<PublishVersionResponse> | undefined,
    b: PublishVersionResponse | PlainMessage<PublishVersionResponse> | undefined
  ): boolean {
    return proto3.util.equals(PublishVersionResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.GetAssetCategoriesRequest
 */
export class GetAssetCategoriesRequest extends Message<GetAssetCategoriesRequest> {
  /**
   * @generated from field: string org_id = 1;
   */
  orgId = ''

  /**
   * @generated from field: string workspace_id = 2;
   */
  workspaceId = ''

  /**
   * @generated from field: asset.v1.AssetFilter filter = 3;
   */
  filter?: AssetFilter

  constructor(data?: PartialMessage<GetAssetCategoriesRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.GetAssetCategoriesRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'workspace_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'filter', kind: 'message', T: AssetFilter }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetAssetCategoriesRequest {
    return new GetAssetCategoriesRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetAssetCategoriesRequest {
    return new GetAssetCategoriesRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetAssetCategoriesRequest {
    return new GetAssetCategoriesRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetAssetCategoriesRequest | PlainMessage<GetAssetCategoriesRequest> | undefined,
    b: GetAssetCategoriesRequest | PlainMessage<GetAssetCategoriesRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetAssetCategoriesRequest, a, b)
  }
}

/**
 * @generated from message asset.v1.GetAssetCategoriesResponse
 */
export class GetAssetCategoriesResponse extends Message<GetAssetCategoriesResponse> {
  /**
   * @generated from field: repeated asset.v1.Category categories = 1;
   */
  categories: Category[] = []

  constructor(data?: PartialMessage<GetAssetCategoriesResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.GetAssetCategoriesResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'categories', kind: 'message', T: Category, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetAssetCategoriesResponse {
    return new GetAssetCategoriesResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetAssetCategoriesResponse {
    return new GetAssetCategoriesResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetAssetCategoriesResponse {
    return new GetAssetCategoriesResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetAssetCategoriesResponse | PlainMessage<GetAssetCategoriesResponse> | undefined,
    b: GetAssetCategoriesResponse | PlainMessage<GetAssetCategoriesResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetAssetCategoriesResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.Category
 */
export class Category extends Message<Category> {
  /**
   * @generated from field: string category = 1;
   */
  category = ''

  /**
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero

  constructor(data?: PartialMessage<Category>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.Category'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'category', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'count', kind: 'scalar', T: 3 /* ScalarType.INT64 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Category {
    return new Category().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Category {
    return new Category().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Category {
    return new Category().fromJsonString(jsonString, options)
  }

  static equals(
    a: Category | PlainMessage<Category> | undefined,
    b: Category | PlainMessage<Category> | undefined
  ): boolean {
    return proto3.util.equals(Category, a, b)
  }
}

/**
 * @generated from message asset.v1.ListAssetsResponse
 */
export class ListAssetsResponse extends Message<ListAssetsResponse> {
  /**
   * @generated from field: repeated asset.v1.Asset assets = 1;
   */
  assets: Asset[] = []

  /**
   * @generated from field: common.v1.PaginationPageInfo page_info = 2;
   */
  pageInfo?: PaginationPageInfo

  constructor(data?: PartialMessage<ListAssetsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.ListAssetsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'assets', kind: 'message', T: Asset, repeated: true },
    { no: 2, name: 'page_info', kind: 'message', T: PaginationPageInfo }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssetsResponse {
    return new ListAssetsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssetsResponse {
    return new ListAssetsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListAssetsResponse {
    return new ListAssetsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListAssetsResponse | PlainMessage<ListAssetsResponse> | undefined,
    b: ListAssetsResponse | PlainMessage<ListAssetsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListAssetsResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.CountAssetsResponse
 */
export class CountAssetsResponse extends Message<CountAssetsResponse> {
  /**
   * @generated from field: int32 num_assets = 1;
   */
  numAssets = 0

  constructor(data?: PartialMessage<CountAssetsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.CountAssetsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'num_assets', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CountAssetsResponse {
    return new CountAssetsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CountAssetsResponse {
    return new CountAssetsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CountAssetsResponse {
    return new CountAssetsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CountAssetsResponse | PlainMessage<CountAssetsResponse> | undefined,
    b: CountAssetsResponse | PlainMessage<CountAssetsResponse> | undefined
  ): boolean {
    return proto3.util.equals(CountAssetsResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.MoveAssetsResponse
 */
export class MoveAssetsResponse extends Message<MoveAssetsResponse> {
  /**
   * @generated from field: repeated asset.v1.Asset assets = 1;
   */
  assets: Asset[] = []

  constructor(data?: PartialMessage<MoveAssetsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.MoveAssetsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'assets', kind: 'message', T: Asset, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MoveAssetsResponse {
    return new MoveAssetsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MoveAssetsResponse {
    return new MoveAssetsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): MoveAssetsResponse {
    return new MoveAssetsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: MoveAssetsResponse | PlainMessage<MoveAssetsResponse> | undefined,
    b: MoveAssetsResponse | PlainMessage<MoveAssetsResponse> | undefined
  ): boolean {
    return proto3.util.equals(MoveAssetsResponse, a, b)
  }
}

/**
 * @generated from message asset.v1.EmptyArchiveResponse
 */
export class EmptyArchiveResponse extends Message<EmptyArchiveResponse> {
  constructor(data?: PartialMessage<EmptyArchiveResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'asset.v1.EmptyArchiveResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EmptyArchiveResponse {
    return new EmptyArchiveResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EmptyArchiveResponse {
    return new EmptyArchiveResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): EmptyArchiveResponse {
    return new EmptyArchiveResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: EmptyArchiveResponse | PlainMessage<EmptyArchiveResponse> | undefined,
    b: EmptyArchiveResponse | PlainMessage<EmptyArchiveResponse> | undefined
  ): boolean {
    return proto3.util.equals(EmptyArchiveResponse, a, b)
  }
}
