import { RootState } from '@dtx-company/inter-app/src/redux/types'
import { fetchLockedFeatures } from '../../utils/fetchLockedFeatures'
import { setLockedFeatures } from '@dtx-company/inter-app/src/redux/slices/lockedFeaturesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

export const useGetLockedFeatures = (): void => {
  const dispatch = useDispatch()
  const lockedFeatures = useSelector(
    (state: RootState) => state.revenueReducer.lockedFeatures.values
  )

  useEffect(() => {
    const fetchLockedFeaturesData = async (): Promise<void> => {
      if (Object.entries(lockedFeatures).length === 0) {
        const lockedFeaturesData = await fetchLockedFeatures()
        if (lockedFeaturesData) {
          dispatch(setLockedFeatures(lockedFeaturesData))
        }
      }
    }
    fetchLockedFeaturesData()
  }, [dispatch, lockedFeatures])
}
