import { Components, Theme } from '@mui/material'

export const backdropComponentOverrides: Components<Theme> = {
  MuiBackdrop: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.utility.scrim
      }),
      invisible: { background: 'transparent' }
    }
  }
}
