import {
  CombinedGeneratorDestinationPayload,
  CombinedGeneratorFilePayload,
  CombinedGeneratorStateType
} from '../../types/combinedGenerator.types'
import { FlowcodeColorOptionType } from '../../types/flowcode.types'
import { FlowcodeTemplateType } from '@dtx-company/inter-app/src/types/flowcode'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { theme } from '@dtx-company/shared-components/src'

const { builder } = theme.colors
const combinedGeneratorSlice = createSlice({
  name: 'combinedGenerator',
  initialState: {
    colorOption: {
      color: builder.pumpkinLight,
      darkColor: builder.pumpkinDark,
      defaultOnly: true,
      id: 'pumpkin',
      index: 30,
      label: 'Pumpkin'
    },
    destinations: {
      call: '',
      email: '',
      website: '',
      instagram: '',
      sms: '',
      youtube: '',
      facebook: ''
    },
    files: {
      file: null,
      image: null,
      video: null
    },
    image: '',
    pattern: {
      configuration: '',
      isCustomizable: false
    },
    title: ''
  } as CombinedGeneratorStateType,
  reducers: {
    setColor: (state, action: PayloadAction<FlowcodeColorOptionType>) => {
      state.colorOption = action.payload
    },
    setDestination: (state, action: PayloadAction<CombinedGeneratorDestinationPayload>) => {
      const { key, payload } = action.payload
      state.destinations[key] = payload
    },
    setFileDestination: (state, action: PayloadAction<CombinedGeneratorFilePayload>) => {
      const { key, payload } = action.payload
      state.files[key] = payload
    },
    setImage: (state, action: PayloadAction<string>) => {
      state.image = action.payload
    },
    setPattern: (state, action: PayloadAction<FlowcodeTemplateType>) => {
      state.pattern = action.payload
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload
    }
  }
})

export const { setColor, setDestination, setFileDestination, setImage, setPattern, setTitle } =
  combinedGeneratorSlice.actions

export default combinedGeneratorSlice.reducer
