import { Cookie } from '@dtx-company/true-common/src/types/cookies'
import {
  getCookie,
  getCookieDomain
} from '@dtx-company/true-common/src/utils/cookies/cookies.utils'
import { setAnonIdLoaded } from '@dtx-company/inter-app/src/redux/slices/cookie'
import { useCookies } from '@dtx-company/true-common/src/utils/cookies/cookies.hooks'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { v4 } from 'uuid'

/**
 * Strictly necessary cookie used to track flowcodes created by anonymous users. This should not be forwarded to third party services.
 */
export const useAnonymousCookie = (): void => {
  const { optOut, setCookie } = useCookies()
  const dispatch = useDispatch()

  // TODO: make sure errors are caught - DF
  useEffect(() => {
    const setAnonCookie = async (): Promise<void> => {
      if (optOut) {
        console.warn(`${Cookie.ANONYMOUS_SESSION_ID} was not set since user has opted out.`)
        return
      }

      let anonymousSessionId = getCookie(Cookie.ANONYMOUS_SESSION_ID)
      if (!anonymousSessionId) {
        anonymousSessionId = v4()
        setCookie(Cookie.ANONYMOUS_SESSION_ID, anonymousSessionId, {
          path: '/',
          domain: getCookieDomain()
        })
      }
      dispatch(setAnonIdLoaded(true))
    }
    setAnonCookie()
  }, [optOut, setCookie, dispatch])
}
