import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import type { RootPageState } from '../../redux/types'

export const usePageSelector = <T>(pageSelector: (pageState: RootPageState) => T): T => {
  return useSelector(
    (state: { pageReducer: RootPageState }) => pageSelector(state?.pageReducer || state),
    isEqual
  )
}
