import { PaletteOptions } from '@mui/material/styles'

// colors  https://www.figma.com/design/vpryyNV4qJxuwrB4MfAbye/Homepage-%2F-Contacts-Sales---Versions?node-id=1922-13878&t=nOwd8e01oJgBmgLt-0
// new color theme is not 100% implemented yet, only tertiary was updated to update Marketing navigation, the rest is a cope of light palette
const filledHoverShade = 'rgba(255, 255, 255, 0.16)'
const filledFocusShade = 'rgba(255, 255, 255, 0.24)'
const hexToRGB = (hex: string): number[] => {
  const hexString = hex.replace('#', '')
  const r = parseInt(hexString.substring(0, 2), 16)
  const g = parseInt(hexString.substring(2, 4), 16)
  const b = parseInt(hexString.substring(4, 6), 16)
  return [r, g, b]
}

const withAlphaRGB = (rgb: number[], alpha: number): string => `rgba(${rgb.join(', ')}, ${alpha})`
const baseRGBTheme2 = {
  primary: [36, 36, 36],
  secondary: [126, 104, 78],
  tertiary: [79, 69, 193],
  error: [186, 26, 26],
  shades: {
    primary: [126, 104, 78],
    primaryFilled: [226, 205, 182],
    tertiary: [0, 109, 61]
  }
}
const baseFlow2Colors = {
  primary: {
    light: '#41403F',
    main: '#242424',
    dark: '#000000',
    contrastText: '#FFFFFF'
  },
  secondary: {
    light: withAlphaRGB(baseRGBTheme2.secondary, 0.08),
    main: withAlphaRGB(baseRGBTheme2.secondary, 0.16),
    dark: withAlphaRGB(baseRGBTheme2.secondary, 0.32),
    contrastText: '#242424'
  },
  tertiary: {
    light: '#6960CA',
    main: '#4F45C1',
    dark: '#4F45C1',
    contrastText: '#FFFFFF'
  },
  error: {
    light: '#DE3730',
    main: '#BA1A1A',
    dark: '#93000A',
    contrastText: '#FFFFFF'
  },
  info: {
    light: '#5B73C4',
    main: '#415AA9',
    dark: '#274190',
    contrastText: '#FFFFFF'
  },
  warning: {
    light: '#F19547',
    main: '#D27B30',
    dark: '#B26317',
    contrastText: '#242424'
  },
  upgrade: {
    main: '#F8BB68',
    light: 'rgb(249, 200, 134)',
    dark: 'rgb(173, 130, 72)',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  success: {
    light: '#43A26A',
    main: '#238753',
    dark: '#006D3D',
    contrastText: '#FFFFFF'
  }
}

export const flow2Palette: PaletteOptions = {
  mode: 'light',
  ...baseFlow2Colors,
  text: {
    primary: '#242424',
    secondary: '#696763',
    disabled: withAlphaRGB(baseRGBTheme2.primary, 0.38)
  },
  divider: withAlphaRGB(baseRGBTheme2.secondary, 0.24),
  background: {
    paper: '#FFFFFF',
    default: '#FFFFFF'
  },
  action: {
    active: withAlphaRGB(baseRGBTheme2.primary, 0.54),
    hover: withAlphaRGB(baseRGBTheme2.primary, 0.08),
    selected: withAlphaRGB(baseRGBTheme2.primary, 0.12),
    disabled: withAlphaRGB(baseRGBTheme2.primary, 0.12),
    disabledBackground: withAlphaRGB(baseRGBTheme2.primary, 0.38),
    focus: withAlphaRGB(baseRGBTheme2.primary, 0.16),
    tooltip: 'rgba(31, 31, 31, 0.9)'
  },
  shades: {
    primary: {
      hover: withAlphaRGB(baseRGBTheme2.secondary, 0.08),
      focus: withAlphaRGB(baseRGBTheme2.secondary, 0.16),
      selected: withAlphaRGB(baseRGBTheme2.secondary, 0.16),
      active: withAlphaRGB(baseRGBTheme2.secondary, 0.32),
      filledHover: `linear-gradient(0deg, ${withAlphaRGB(
        baseRGBTheme2.shades.primaryFilled,
        0.16
      )}, ${withAlphaRGB(baseRGBTheme2.shades.primaryFilled, 0.16)}), ${withAlphaRGB(
        baseRGBTheme2.primary,
        1
      )}`,
      filledFocus: `linear-gradient(0deg, ${withAlphaRGB(
        baseRGBTheme2.shades.primaryFilled,
        0.24
      )}, ${withAlphaRGB(baseRGBTheme2.shades.primaryFilled, 0.24)}), ${withAlphaRGB(
        baseRGBTheme2.primary,
        1
      )}`
    },
    secondary: {
      filledHover: `linear-gradient(0deg, ${withAlphaRGB(
        baseRGBTheme2.primary,
        0.08
      )}, ${withAlphaRGB(baseRGBTheme2.primary, 0.08)}), ${withAlphaRGB(
        baseRGBTheme2.secondary,
        0.16
      )}`,
      filledFocus: `linear-gradient(0deg, ${withAlphaRGB(
        baseRGBTheme2.primary,
        0.16
      )}, ${withAlphaRGB(baseRGBTheme2.primary, 0.16)}), ${withAlphaRGB(
        baseRGBTheme2.secondary,
        0.16
      )}`
    },
    tertiary: {
      hover: withAlphaRGB(baseRGBTheme2.shades.tertiary, 0.08),
      focus: withAlphaRGB(baseRGBTheme2.shades.tertiary, 0.16),
      filledHover: `linear-gradient(0deg, ${filledHoverShade}, ${filledHoverShade}), ${withAlphaRGB(
        baseRGBTheme2.tertiary,
        1
      )}`,
      filledFocus: `linear-gradient(0deg, ${filledFocusShade}, ${filledFocusShade}), ${withAlphaRGB(
        baseRGBTheme2.tertiary,
        1
      )}`,
      filledDisabled: `linear-gradient(0deg, ${withAlphaRGB(
        baseRGBTheme2.tertiary,
        0.38
      )}, ${withAlphaRGB(baseRGBTheme2.tertiary, 0.38)}), #FFFFFF`
    },
    error: {
      hover: withAlphaRGB(baseRGBTheme2.error, 0.08),
      focus: withAlphaRGB(baseRGBTheme2.error, 0.16),
      filledHover: `linear-gradient(0deg, ${filledHoverShade}, ${filledHoverShade}), ${withAlphaRGB(
        baseRGBTheme2.error,
        1
      )}`,
      filledFocus: `linear-gradient(0deg, ${filledFocusShade}, ${filledFocusShade}), ${withAlphaRGB(
        baseRGBTheme2.error,
        1
      )}`
    },
    upgrade: {
      hover: baseFlow2Colors.warning.main,
      filledFocus: `linear-gradient(0deg, ${filledFocusShade}, ${filledFocusShade}), ${withAlphaRGB(
        hexToRGB(baseFlow2Colors.upgrade.main),
        1
      )}`
    }
  },
  surface: {
    0: '#FFFFFF',
    1: '#F9F7F6',
    2: '#F5F3F1',
    3: '#F2EEEC',
    4: '#ECEAE6',
    5: '#E9E5E1',
    inverse: '#343332',
    disabled: withAlphaRGB(baseRGBTheme2.primary, 0.12),
    tintLight: '#7E684E',
    tintDark: '#E2CDB6'
  },
  onSurface: {
    primary: '#242424',
    secondary: '#696763',
    disabled: withAlphaRGB(baseRGBTheme2.primary, 0.38),
    inverse: '#FFFFFF',
    inverseSecondary: '#C5C3BE',
    inverseTertiary: '#7BDA9D'
  },
  border: {
    outline: '#B7B4AE',
    divider: withAlphaRGB(baseRGBTheme2.secondary, 0.24),
    disabled: withAlphaRGB(baseRGBTheme2.primary, 0.12)
  },
  utility: {
    scrim: withAlphaRGB(baseRGBTheme2.primary, 0.32),
    tooltip: withAlphaRGB(baseRGBTheme2.primary, 0.9),
    upgrade: baseFlow2Colors.upgrade.main
  },
  container: {
    error: '#FFDAD6',
    warning: '#FFF1CC',
    info: '#D8E2FF',
    success: '#CEF3D8'
  }
}
