import { CssBaseline } from '../CssBaseline/CssBaseline'
import { FC, PropsWithChildren } from 'react'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import { theme } from '../../theme'

export const ThemeProvider: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
)
