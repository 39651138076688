import { Components, Theme } from '@mui/material'

/**
 * MUI Theme overrides for Flowcode's [Tab](./Tab.tsx) component.
 */
export const tabComponentOverrides: Components<Theme> = {
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: 'none',
        '&:has(button.MuiTab-root.Mui-selected.Mui-disabled) .MuiTabs-indicator': {
          backgroundColor: theme.palette.surface.disabled
        }
      })
    }
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: '16px 24px',
        '&:hover': {
          backgroundColor: theme.palette.shades.primary.hover
        },
        '&:focus': {
          backgroundColor: theme.palette.shades.primary.focus,
          color: 'primary'
        }
      })
    }
  }
}
