import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface LockedFeatures {
  [key: string]: string
}

export interface LockedFeaturesState {
  values: LockedFeatures
}

const initialState: LockedFeaturesState = {
  values: {}
}

const lockedFeaturesSlice = createSlice({
  name: 'lockedFeatures',
  initialState,
  reducers: {
    setLockedFeatures: (state, action: PayloadAction<LockedFeatures>) => {
      state.values = action.payload
    },
    clearLockedFeatures: state => {
      state.values = initialState.values
    }
  }
})

export const { setLockedFeatures, clearLockedFeatures } = lockedFeaturesSlice.actions

export default lockedFeaturesSlice.reducer
