// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file forms/v1/success_screen.proto (package forms.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { Message, proto3 } from '@bufbuild/protobuf'

/**
 * @generated from message forms.v1.SuccessAction
 */
export class SuccessAction extends Message<SuccessAction> {
  /**
   * @generated from oneof forms.v1.SuccessAction.action
   */
  action:
    | {
        /**
         * @generated from field: forms.v1.UrlRedirect redirect = 1;
         */
        value: UrlRedirect
        case: 'redirect'
      }
    | {
        /**
         * @generated from field: forms.v1.SuccessScreen success_screen = 2;
         */
        value: SuccessScreen
        case: 'successScreen'
      }
    | {
        /**
         * @generated from field: forms.v1.ForwardUrl forward = 3;
         */
        value: ForwardUrl
        case: 'forward'
      }
    | {
        /**
         * @generated from field: forms.v1.ViewLiveResults view_live_result = 4;
         */
        value: ViewLiveResults
        case: 'viewLiveResult'
      }
    | { case: undefined; value?: undefined } = { case: undefined }

  constructor(data?: PartialMessage<SuccessAction>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.SuccessAction'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'redirect', kind: 'message', T: UrlRedirect, oneof: 'action' },
    { no: 2, name: 'success_screen', kind: 'message', T: SuccessScreen, oneof: 'action' },
    { no: 3, name: 'forward', kind: 'message', T: ForwardUrl, oneof: 'action' },
    { no: 4, name: 'view_live_result', kind: 'message', T: ViewLiveResults, oneof: 'action' }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuccessAction {
    return new SuccessAction().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuccessAction {
    return new SuccessAction().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuccessAction {
    return new SuccessAction().fromJsonString(jsonString, options)
  }

  static equals(
    a: SuccessAction | PlainMessage<SuccessAction> | undefined,
    b: SuccessAction | PlainMessage<SuccessAction> | undefined
  ): boolean {
    return proto3.util.equals(SuccessAction, a, b)
  }
}

/**
 * @generated from message forms.v1.UrlRedirect
 */
export class UrlRedirect extends Message<UrlRedirect> {
  /**
   * @generated from field: string url = 1;
   */
  url = ''

  constructor(data?: PartialMessage<UrlRedirect>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.UrlRedirect'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'url', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UrlRedirect {
    return new UrlRedirect().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UrlRedirect {
    return new UrlRedirect().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UrlRedirect {
    return new UrlRedirect().fromJsonString(jsonString, options)
  }

  static equals(
    a: UrlRedirect | PlainMessage<UrlRedirect> | undefined,
    b: UrlRedirect | PlainMessage<UrlRedirect> | undefined
  ): boolean {
    return proto3.util.equals(UrlRedirect, a, b)
  }
}

/**
 * Bundler url to forward submission data
 *
 * @generated from message forms.v1.ForwardUrl
 */
export class ForwardUrl extends Message<ForwardUrl> {
  /**
   * @generated from field: string url = 1;
   */
  url = ''

  constructor(data?: PartialMessage<ForwardUrl>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.ForwardUrl'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'url', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ForwardUrl {
    return new ForwardUrl().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ForwardUrl {
    return new ForwardUrl().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ForwardUrl {
    return new ForwardUrl().fromJsonString(jsonString, options)
  }

  static equals(
    a: ForwardUrl | PlainMessage<ForwardUrl> | undefined,
    b: ForwardUrl | PlainMessage<ForwardUrl> | undefined
  ): boolean {
    return proto3.util.equals(ForwardUrl, a, b)
  }
}

/**
 * @generated from message forms.v1.SuccessScreen
 */
export class SuccessScreen extends Message<SuccessScreen> {
  /**
   * @generated from field: string title = 1;
   */
  title = ''

  /**
   * @generated from field: string description = 2;
   */
  description = ''

  constructor(data?: PartialMessage<SuccessScreen>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.SuccessScreen'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuccessScreen {
    return new SuccessScreen().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuccessScreen {
    return new SuccessScreen().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuccessScreen {
    return new SuccessScreen().fromJsonString(jsonString, options)
  }

  static equals(
    a: SuccessScreen | PlainMessage<SuccessScreen> | undefined,
    b: SuccessScreen | PlainMessage<SuccessScreen> | undefined
  ): boolean {
    return proto3.util.equals(SuccessScreen, a, b)
  }
}

/**
 * @generated from message forms.v1.ViewLiveResults
 */
export class ViewLiveResults extends Message<ViewLiveResults> {
  /**
   * @generated from field: repeated string field_names = 1;
   */
  fieldNames: string[] = []

  constructor(data?: PartialMessage<ViewLiveResults>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.ViewLiveResults'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'field_names', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ViewLiveResults {
    return new ViewLiveResults().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ViewLiveResults {
    return new ViewLiveResults().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ViewLiveResults {
    return new ViewLiveResults().fromJsonString(jsonString, options)
  }

  static equals(
    a: ViewLiveResults | PlainMessage<ViewLiveResults> | undefined,
    b: ViewLiveResults | PlainMessage<ViewLiveResults> | undefined
  ): boolean {
    return proto3.util.equals(ViewLiveResults, a, b)
  }
}
