import { LandingPageDestination } from '@dtx-company/inter-app/src/types/flowcode'
import { ScanDestinationFormValuesType } from '../types/ScanToOwnGeneric'
import { ScanToOwnGenericPages } from '@dtx-company/true-common/src/types/scanToOwn'

export const STO_SOCIAL_MEDIA_PAGES: Partial<
  Record<LandingPageDestination, ScanToOwnGenericPages>
> = {
  [LandingPageDestination.LINKEDIN]: ScanToOwnGenericPages.SCAN_DESTINATION_LINKEDIN,
  [LandingPageDestination.INSTAGRAM]: ScanToOwnGenericPages.SCAN_DESTINATION_INSTAGRAM,
  [LandingPageDestination.FACEBOOK]: ScanToOwnGenericPages.SCAN_DESTINATION_FACEBOOK,
  [LandingPageDestination.TIKTOK]: ScanToOwnGenericPages.SCAN_DESTINATION_TIKTOK,
  [LandingPageDestination.SNAPCHAT]: ScanToOwnGenericPages.SCAN_DESTINATION_SNAPCHAT,
  [LandingPageDestination.TWITTER]: ScanToOwnGenericPages.SCAN_DESTINATION_TWITTER
}

export const INITIAL_SCAN_DESTINATION_FORM_STATE: ScanDestinationFormValuesType = {
  link: '',
  sms: {
    message: '',
    phoneNumber: ''
  },
  phone: '',
  email: {
    email: '',
    subject: '',
    body: ''
  },
  venmo: {
    amount: undefined,
    recipients: '',
    note: ''
  },
  file: {
    name: '',
    localFileUrl: ''
  },
  subtypeId: '',
  contact: {
    collect: [],
    pitch: ''
  },
  vcard: {
    id: undefined,
    first_name: undefined,
    last_name: undefined,
    company: undefined,
    title: undefined,
    email: undefined,
    phone: undefined,
    phone_country_code: undefined,
    work_phone: undefined,
    work_phone_country_code: undefined,
    website: undefined,
    street: undefined,
    street2: undefined,
    city: undefined,
    state: undefined,
    zipcode: undefined,
    country: undefined
  }
}
