import { Components, Theme } from '@mui/material'
import { filledInputClasses } from '@mui/material/FilledInput'
import { formControlClasses } from '@mui/material/FormControl'
import { formLabelClasses } from '@mui/material/FormLabel'
import { inputLabelClasses } from '@mui/material/InputLabel'
import { textFieldClasses } from '@mui/material/TextField'

export const autocompleteComponentOverrides: Components<Theme> = {
  MuiAutocomplete: {
    styleOverrides: {
      root: ({ theme }) => ({
        [`&& .${formControlClasses.root}.${textFieldClasses.root}`]: {
          [`.${formLabelClasses.root}.${inputLabelClasses.root}.${inputLabelClasses.shrink}`]: {
            transform: `translate(${theme.spacing(3)}, ${theme.spacing(1)}) scale(0.75)`
          }
        },
        [`&& .${filledInputClasses.root}.${filledInputClasses.hiddenLabel}`]: {
          paddingBottom: 0,
          paddingTop: 0
        },
        [`&& .${filledInputClasses.root}:focus-within.:not(${filledInputClasses.hiddenLabel})`]: {
          paddingTop: theme.spacing(4.5),
          paddingLeft: theme.spacing(1.75)
        },

        [`&& .${filledInputClasses.root}::after`]: {
          borderBottom: 'none'
        }
      }),
      input: ({ theme }) => ({
        [`&.${filledInputClasses.input}.${filledInputClasses.inputHiddenLabel}`]: {
          paddingTop: theme.spacing(2.875),
          paddingBottom: theme.spacing(2.875),
          [`&.${filledInputClasses.inputSizeSmall}`]: {
            paddingTop: theme.spacing(1.875),
            paddingBottom: theme.spacing(1.875)
          }
        }
      }),
      inputRoot: {
        '&&:focus-within': {
          paddingLeft: 0
        }
      }
    }
  }
}
