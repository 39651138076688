import { AnyAction, CombinedState, Reducer, combineReducers } from '@reduxjs/toolkit'
import assetPermissionsReducer, {
  AssetPermissionsSlice
} from '@dtx-company/inter-app/src/redux/slices/flowteams/assetPermissionsSlice'
import autoshareAssetsReducer, {
  AutoshareAssetsSlice
} from '../slices/flowteams/autoshareAssetsSlice'
import modalMembersTableReducer, {
  ModalMembersTableSliceState
} from '@dtx-company/inter-app/src/redux/slices/flowteams/modalMembersTableSlice'

export const flowteamsCombinedReducer: Reducer<
  CombinedState<{
    modalMembersTable: ModalMembersTableSliceState
    assetPermissions: AssetPermissionsSlice
    autoshareAssets: AutoshareAssetsSlice
  }>,
  AnyAction
> = combineReducers({
  modalMembersTable: modalMembersTableReducer,
  assetPermissions: assetPermissionsReducer,
  autoshareAssets: autoshareAssetsReducer
})
