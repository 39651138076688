import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit'
import { PageCountQuery } from '@dtx-company/flow-codegen/src/page/generated.types'
import { RootState } from '../../types'
import { TOTAL_PAGE_COUNT } from '@dtx-company/flow-codegen/src/page/queries'
import { pageGqlFetcher } from '../../../services/gqlFetcher'

export const checkUserHasReachedMaxPages = createAsyncThunk<
  { userHasReachedMaxPages: boolean },
  undefined,
  { dispatch: Dispatch; state: RootState }
>('fpFunnel/checkUserHasReachedMaxPages', async (_, { getState }) => {
  const fronteggJwt = getState().appReducer.userReducer.currentUserData
  const jwt = fronteggJwt
  const { me } = await pageGqlFetcher<PageCountQuery>(TOTAL_PAGE_COUNT)
  const pageLimit = Number(jwt?.attributes?.['page_limit'])
  const accountPages = me.pageConnection.totalCount

  if (accountPages && pageLimit > 0 ? Object.keys(accountPages).length >= pageLimit : false) {
    return { userHasReachedMaxPages: true }
  }
  return { userHasReachedMaxPages: false }
})
