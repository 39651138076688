import { Cohort } from '../types/cohorts'

//original values
export const cohort0: Cohort = {
  name: 'cohort0',
  free: {
    codeLimit: 10,
    pageLimit: 3
  },
  pro: {
    codeLimit: Infinity,
    pageLimit: 10
  },
  proPlus: {
    codeLimit: Infinity,
    pageLimit: Infinity
  },
  proFlex: {
    codeLimit: Infinity,
    pageLimit: Infinity
  },
  pilot: {
    codeLimit: Infinity,
    pageLimit: 2
  },
  enterprise: {
    codeLimit: Infinity,
    pageLimit: Infinity
  }
}

//only flowcode limits updated
export const cohortA: Cohort = {
  name: 'cohort:A',
  free: {
    codeLimit: 5,
    pageLimit: cohort0.free.pageLimit
  },
  pro: {
    codeLimit: 100,
    pageLimit: cohort0.pro.pageLimit
  },
  proPlus: {
    codeLimit: Infinity,
    pageLimit: cohort0.proPlus.pageLimit
  },
  proFlex: {
    codeLimit: Infinity,
    pageLimit: cohort0.proPlus.pageLimit
  },
  pilot: {
    codeLimit: Infinity,
    pageLimit: 2
  },
  enterprise: {
    codeLimit: Infinity,
    pageLimit: cohort0.enterprise.pageLimit
  }
}

//only flowpage limits updated
export const cohortB: Cohort = {
  name: 'cohort:B',
  free: {
    codeLimit: cohort0.free.codeLimit,
    pageLimit: 2
  },
  pro: {
    codeLimit: cohort0.pro.codeLimit,
    pageLimit: 5
  },
  proPlus: {
    codeLimit: cohort0.proPlus.codeLimit,
    pageLimit: Infinity
  },
  proFlex: {
    codeLimit: cohort0.proPlus.codeLimit,
    pageLimit: Infinity
  },
  pilot: {
    codeLimit: Infinity,
    pageLimit: 2
  },
  enterprise: {
    codeLimit: cohort0.enterprise.codeLimit,
    pageLimit: Infinity
  }
}

//flowcode and flowpage limits updated
export const cohortC: Cohort = {
  name: 'cohort:C',
  free: {
    codeLimit: 5,
    pageLimit: 2
  },
  pro: {
    codeLimit: 100,
    pageLimit: 5
  },
  proPlus: {
    codeLimit: Infinity,
    pageLimit: Infinity
  },
  proFlex: {
    codeLimit: Infinity,
    pageLimit: Infinity
  },
  pilot: {
    codeLimit: Infinity,
    pageLimit: 2
  },
  enterprise: {
    codeLimit: Infinity,
    pageLimit: Infinity
  }
}

//control group, no changes from original values
export const cohortD: Cohort = {
  name: 'cohort:D',
  free: cohort0.free,
  pro: cohort0.pro,
  proPlus: cohort0.proPlus,
  proFlex: cohort0.proPlus,
  pilot: cohort0.pilot,
  enterprise: cohort0.enterprise
}

export const cohortE: Cohort = {
  name: 'cohort:E',
  free: {
    codeLimit: 3,
    pageLimit: 2
  },
  pro: cohortC.pro,
  proPlus: cohortC.proPlus,
  proFlex: cohortC.proPlus,
  pilot: cohortC.pilot,
  enterprise: cohortC.enterprise
}

export const cohortF: Cohort = {
  name: 'cohort:F',
  free: cohortE.free,
  pro: cohortE.pro,
  proPlus: {
    ...cohortE.proPlus,
    codeLimit: 1000
  },
  proFlex: cohortE.proFlex,
  pilot: cohortE.pilot,
  enterprise: cohortE.enterprise
}

export const cohortG: Cohort = {
  name: 'cohort:G',
  free: {
    codeLimit: 2,
    pageLimit: 1
  },
  pro: cohortE.pro,
  proPlus: cohortE.proPlus,
  proFlex: cohortE.proFlex,
  pilot: cohortE.pilot,
  enterprise: cohortE.enterprise
}

export const cohortH: Cohort = {
  name: 'cohort:H',
  free: {
    codeLimit: 3,
    pageLimit: 2
  },
  pro: {
    codeLimit: 100,
    pageLimit: 5
  },
  proPlus: {
    codeLimit: 1000,
    pageLimit: 50
  },
  proFlex: {
    codeLimit: Infinity,
    pageLimit: Infinity
  },
  pilot: {
    codeLimit: Infinity,
    pageLimit: 100
  },
  enterprise: {
    codeLimit: Infinity,
    pageLimit: Infinity
  }
}

// Testing more restrictive limits for free
export const cohortI: Cohort = {
  name: 'cohort:I',
  free: {
    codeLimit: 1,
    pageLimit: 1
  },
  pro: {
    codeLimit: 100,
    pageLimit: 5
  },
  proPlus: {
    codeLimit: 1000,
    pageLimit: 50
  },
  proFlex: {
    codeLimit: Infinity,
    pageLimit: Infinity
  },
  pilot: {
    codeLimit: Infinity,
    pageLimit: 2
  },
  enterprise: {
    codeLimit: Infinity,
    pageLimit: Infinity
  }
}

// releasing growth plan, normalizing limits for codes and pages
export const cohortJ: Cohort = {
  name: 'cohort:J',
  free: {
    codeLimit: 2,
    pageLimit: 2
  },
  pro: {
    codeLimit: 10,
    pageLimit: 10
  },
  proPlus: {
    codeLimit: 50,
    pageLimit: 50
  },
  proFlex: {
    codeLimit: 500,
    pageLimit: 150
  },
  pilot: {
    codeLimit: Infinity,
    pageLimit: 2
  },
  enterprise: {
    codeLimit: Infinity,
    pageLimit: Infinity
  }
}

export const cohorts: Cohort[] = [
  cohortA,
  cohortB,
  cohortC,
  cohortD,
  cohortE,
  cohortF,
  cohortG,
  cohortH,
  cohortI,
  cohortJ
]

// There are a few different places getting the cohort a few different ways
// with different conditions so I don't want to risk breaking anything by
// making one of them the source of truth so I'm adding this instead
export const DEFAULT_COHORT = cohortJ
