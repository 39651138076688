import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type DefaultFieldType = {
  link?: string
  imageUrl?: string
  title?: string
}

export enum EntityTypes {
  USER = 0,
  TEAM = 1,
  ORG = 2,
  PLAN = 3
}

export enum ModalTypes {
  WELCOME_TO_PRO_AMEX = 'welcome-to-pro-amex',
  REFERRAL = 'referral',
  SIGNUP = 'signup',
  FLOWCODE = 'flowcode',
  LOCATION = 'loaction',
  COLLECTION = 'collection',
  CONTACT = 'contact',
  ADD_LINK = 'addLink',
  PAGE_SETTINGS = 'pageSettings',
  ANALYTICS = 'analytics',
  CHART = 'chart',
  LINK_FORM = 'linkForm', // both add and edit link
  SHARE_OPTIONS = 'shareOptions',
  STUDIO_ASSETS_UPLOAD = 'studioAssetsUpload',
  PERMISSIONS_FORM = 'permissionsForm',
  DESTINATION_RULE = 'destinationRule',
  RULE_SUMMARY = 'ruleSummary',
  SAVE_CODE_TEMPLATE = 'saveCodeTemplate',
  DUPLICATE_CODE = 'duplicateCode',
  DELETE_CODE = 'deleteCode',
  CODE_PERMISSIONS = 'codePermissions',
  CODE_RENAME = 'codeRename',
  CODE_MOVE = 'codeMove',
  TRANSFER_CODE = 'transferCode',
  EMAIL_CODE = 'emailCode',
  SMS_CODE = 'smsCode',
  DOWNLOAD_CODE = 'downloadCode',
  PRINT_STORE_CHECKOUT = 'printStoreCheckout',
  STUDIO_AUTO_DESIGNS = 'studioAutoDesigns',
  STUDIO_LIBRARY_MODAL = 'studioAssignCode',
  STUDIO_COMPONENTS_MODAL = 'studioComponentsModal',
  STUDIO_SWITCH_COLOR_SPACE = 'studioSwitchColorspace',
  SELECT_FLOWCODE_MODAL = 'codeSelectionModal',
  EMAIL_PLACEMENT_TEMPLATE_MODAL = 'emailPlacementTemplateModal',
  DOWNLOAD_PLACEMENT_TEMPLATE_MODAL = 'downloadPlacementTemplateModal',
  PRO_UPSELL_HOMEPAGE_MODAL = 'proUpsellHomepageModal',
  CLEAR_TEMPLATE = 'clearTemplate',
  CONFIRM_SUBDOMAIN = 'confirmSubdomain',
  REPORTS_MODAL = 'reportsModal',
  CONFIRM_CHANGE_SUBDOMAIN = 'confirmChangeSubdomain',
  MULTIPLE_PAGE_GATE = 'multiplePageGate',
  MANAGEMENT_SORT_MODAL = 'mangaementSortModal',
  MOBILE_FLOWPAGE_PREVIEW = 'mobileFlowpagePreview',
  DELETE_FOLDER_MODAL = 'deleteFolderModal',
  MOVE_ASSET_MODAL = 'moveAssetModal',
  EMAIL_CONFIRMATION_MODAL = 'emailCollectionModal',
  SHOPPING_CART_ITEM_PREVIEW_MODAL = 'shoppingCartItemPreviewModal',
  SHOPPING_CART_CHECKOUT_ERROR_MODAL = 'shoppingCartCheckoutErrorModal',
  EXPLORE_ALL_LINKS_MODAL = 'exploreAllLinksModal',
  PRODUCT_PAGE_CODE_USED_MODAL = 'productPageCodeUsedModal',
  CANCELLATION_MODAL = 'cancellationModal',
  ORG_CANCELLATION_MODAL = 'orgCancellationModal',
  SHOPPING_CART_RECENTLY_ADDED_ITEM_MODAL = 'shoppingCartRecentlyAddedItemModal',
  CREATE_FLOWCODE_TEMPLATE_MODAL = 'createFlowcodeTemplateModal',
  CREATE_FLOWPAGE_TEMPLATE_MODAL = 'createFlowpageTemplateModal',
  CREATE_FLOWPAGE_TEMPLATE_MODAL_V2 = 'createFlowpageTemplateModal_v2',
  ASSIGN_FLOWPAGE_TEMPLATE_MODAL = 'assignFlowpageTemplateModal',
  DELETE_FLOWPAGE_TEMPLATE_MODAL = 'deleteFlowpageTemplateModal',
  EDIT_FLOWPAGE_TEMPLATE_MODAL = 'editFlowpageTemplateModal',
  SHARE_FLOWPAGE_TEMPLATE_MODAL = 'shareFlowpageTemplateModal',
  CLAIM_SLUG_MODAL = 'claimSlugModal',
  PREVIEW_FLOWPAGE_TEMPLATE_MODAL = 'previewFlowpageTemplateModal',
  SEE_ALL_PRODUCTS_MODAL = 'seeAllProductsModal',
  DELETE_ALL_TEMPLATE_FLOWPAGES_MODAL = 'deleteAllTemplateFlowpagesModal',
  CREATE_PAGE_MODAL = 'createPageModal',
  RESET_CUSTOMIZATION_OPTIONS = 'reset_customization_options',
  RESTORE_PLAN_MODAL = 'restorePlanModal',
  UPGRADE_SUCCESS_MODAL = 'upgradeSuccessModal',
  TEMPLATE_ACTIONS_MODAL = 'templateActionsModal',
  TEMPLATES_SIGN_UP_MODAL = 'templatesSignUpModal',
  TEMPLATES_DOWNLOAD_MODAL = 'templatesDownloadModal',
  PROFILE_PICTURE = 'profilePicture',
  CONFIRM_MOVE_FLOWCODE = 'confirmMoveFlowcode',
  TRANSFER_FOLDER = 'transferFolder',
  INVITE_MEMBER_MODAL = 'inviteMemberModal',
  DESKTOP_ASSET_CREATION_MODAL = 'desktopAssetCreationModal',
  DESKTOP_PROFILE_MENU_MODAL = 'desktopProfileMenuModal',
  DESKTOP_SHOPPING_CART_EMPTY_STATE_MODAL = 'desktopShoppingCartEmptyStateModal',
  CREATE_TEAM_MODAL = 'createTeamModal',
  MOBILE_CODE_DETAILS_ACTIONS_DRAWER = 'mobileCodeDetailsActionsDrawer',
  ANALYTICS_ACTION_BAR_MENU = 'analyticsActionBarMenu',
  UNSAVED_CHANGES = 'unsavedChanges',
  MOBILE_GLOBAL_SEARCH = 'mobileGlobalSearch',
  DELETE_PAGE_TEMPLATE = 'deletePageTemplate',
  RENAME_PAGE_TEMPLATE = 'renamePageTemplate',
  SEARCH_FLOWPAGE_TEMPLATES = 'searchFlowpageTemplates',
  VIDEO_DENIAL_SHEET_MOBILE = 'videoDenialSheetMobile',
  NAV_PLANS_UPGRADE_DIALOG = 'navPlansUpgradeDialog',
  UNIFIED_ASSET_CREATION_DIALOG = 'unifiedAssetCreationDialog',
  UNIFIED_ASSET_ARCHIVE_DIALOG = 'unifiedAssetArchiveDialog',
  UNIFIED_ASSET_ARCHIVE_RESTORE_DIALOG = 'unifiedAssetArchiveRestoreDialog',
  UNIFIED_ASSET_ARCHIVE_PERM_DELETE_DIALOG = 'unifiedAssetArchivePermDeleteDialog',
  TRANSFER_FLOWPAGE_MODAL = 'transferFlowpageModal',
  PIXEL_WIZARD_MODAL = 'pixelWizardModal',
  UNIFIED_TEMPLATES_LIMIT_UPGRADE_MODAL = 'unifiedTemplatesLimitUpgradeModal',
  UNIFIED_ASSET_FOLDER_MORE_OPTIONS = 'unifiedAssetFolderMoreOptions',
  TRANSFER_UNIFIED_ASSET_MODAL = 'transferUnifiedAssetModal',
  MOVE_UNIFIED_ASSET_MODAL = 'moveUnifiedAssetModal',
  SHARE_UNIFIED_ASSET_MODAL = 'shareUnifiedAssetModal',
  UNIFIED_ASSET_BULK_ACTION_PARTIAL_EXECUTION_MODAL = 'unifiedAssetBulkActionPartialExecutionModal',
  ARCHIVE_UNIFIED_ASSET_RESTORE_MODAL = 'archiveUnifiedAssetRestoreModal',
  TRIAL_INTRO_OVERLAY = 'trialIntroOverlay',
  DATA_CAP = 'dataCap',
  ENABLE_AUTOSHARE_TEAM_MODAL = 'enableAutoShareTeamModal',
  DISABLE_AUTOSHARE_TEAM_MODAL = 'disableAutoShareTeamModal',
  MULTI_DOWNLOAD_UAM_MODAL = 'multiDownloadUAMModal',
  ASSET_MGMT_LOADING_TIMEOUT_SNACKBAR = 'assetMgmtLoadingTimeoutSnackbar',
  ASSET_MGMT_LOADING_TIMEOUT_MODAL = 'assetMgmtLoadingTimeoutModal'
}
export interface WidgetFormProps {
  widgetObj: any
  curr?: any
  defaultFields?: DefaultFieldType
}

export type ModalType<ModalPropsType = ModalProps> = {
  type: ModalTypes
  open: boolean
  props?: ModalPropsType
}

export type ModalProps =
  | Record<string, (() => void) | string | boolean | EntityTypes>
  | WidgetFormProps

export interface ModalState {
  modals: ModalType[]
}

const initialModalState: ModalState = {
  modals: [
    { type: ModalTypes.WELCOME_TO_PRO_AMEX, open: false },
    { type: ModalTypes.SAVE_CODE_TEMPLATE, open: false },
    { type: ModalTypes.DUPLICATE_CODE, open: false },
    { type: ModalTypes.DELETE_CODE, open: false },
    { type: ModalTypes.TRANSFER_CODE, open: false },
    { type: ModalTypes.CODE_PERMISSIONS, open: false },
    { type: ModalTypes.CODE_RENAME, open: false },
    { type: ModalTypes.CODE_MOVE, open: false },
    { type: ModalTypes.EMAIL_CODE, open: false },
    { type: ModalTypes.SMS_CODE, open: false },
    { type: ModalTypes.DOWNLOAD_CODE, open: false },
    { type: ModalTypes.ADD_LINK, open: false },
    { type: ModalTypes.COLLECTION, open: false },
    { type: ModalTypes.CONTACT, open: false },
    { type: ModalTypes.FLOWCODE, open: false },
    { type: ModalTypes.LINK_FORM, open: false },
    { type: ModalTypes.REFERRAL, open: false },
    { type: ModalTypes.SHARE_OPTIONS, open: false },
    { type: ModalTypes.ANALYTICS, open: false },
    { type: ModalTypes.CHART, open: false },
    { type: ModalTypes.STUDIO_ASSETS_UPLOAD, open: false },
    { type: ModalTypes.PERMISSIONS_FORM, open: false },
    { type: ModalTypes.DESTINATION_RULE, open: false },
    { type: ModalTypes.RULE_SUMMARY, open: false },
    { type: ModalTypes.PRINT_STORE_CHECKOUT, open: false },
    { type: ModalTypes.LOCATION, open: false },
    { type: ModalTypes.STUDIO_AUTO_DESIGNS, open: false },
    { type: ModalTypes.STUDIO_LIBRARY_MODAL, open: false },
    { type: ModalTypes.SELECT_FLOWCODE_MODAL, open: false },
    { type: ModalTypes.EMAIL_PLACEMENT_TEMPLATE_MODAL, open: false },
    { type: ModalTypes.DOWNLOAD_PLACEMENT_TEMPLATE_MODAL, open: false },
    { type: ModalTypes.PRO_UPSELL_HOMEPAGE_MODAL, open: false },
    { type: ModalTypes.CLEAR_TEMPLATE, open: false },
    { type: ModalTypes.PAGE_SETTINGS, open: false },
    { type: ModalTypes.STUDIO_COMPONENTS_MODAL, open: false },
    { type: ModalTypes.CONFIRM_SUBDOMAIN, open: false },
    { type: ModalTypes.CONFIRM_CHANGE_SUBDOMAIN, open: false },
    { type: ModalTypes.MULTIPLE_PAGE_GATE, open: false },
    { type: ModalTypes.STUDIO_SWITCH_COLOR_SPACE, open: false },
    { type: ModalTypes.MANAGEMENT_SORT_MODAL, open: false },
    { type: ModalTypes.MOBILE_FLOWPAGE_PREVIEW, open: false },
    { type: ModalTypes.DELETE_FOLDER_MODAL, open: false },
    { type: ModalTypes.MOVE_ASSET_MODAL, open: false },
    { type: ModalTypes.EMAIL_CONFIRMATION_MODAL, open: false },
    { type: ModalTypes.SHOPPING_CART_ITEM_PREVIEW_MODAL, open: false },
    { type: ModalTypes.SHOPPING_CART_CHECKOUT_ERROR_MODAL, open: false },
    { type: ModalTypes.EXPLORE_ALL_LINKS_MODAL, open: false },
    { type: ModalTypes.PRODUCT_PAGE_CODE_USED_MODAL, open: false },
    { type: ModalTypes.CANCELLATION_MODAL, open: false },
    { type: ModalTypes.ORG_CANCELLATION_MODAL, open: false },
    { type: ModalTypes.SHOPPING_CART_RECENTLY_ADDED_ITEM_MODAL, open: false },
    { type: ModalTypes.CREATE_FLOWCODE_TEMPLATE_MODAL, open: false },
    { type: ModalTypes.CREATE_FLOWPAGE_TEMPLATE_MODAL, open: false },
    { type: ModalTypes.CREATE_FLOWPAGE_TEMPLATE_MODAL_V2, open: false },
    { type: ModalTypes.ASSIGN_FLOWPAGE_TEMPLATE_MODAL, open: false },
    { type: ModalTypes.DELETE_FLOWPAGE_TEMPLATE_MODAL, open: false },
    { type: ModalTypes.EDIT_FLOWPAGE_TEMPLATE_MODAL, open: false },
    { type: ModalTypes.SHARE_FLOWPAGE_TEMPLATE_MODAL, open: false },
    {
      type: ModalTypes.CLAIM_SLUG_MODAL,
      open: false
    },
    {
      type: ModalTypes.PREVIEW_FLOWPAGE_TEMPLATE_MODAL,
      open: false
    },
    { type: ModalTypes.SEE_ALL_PRODUCTS_MODAL, open: false },
    {
      type: ModalTypes.CREATE_PAGE_MODAL,
      open: false
    },
    { type: ModalTypes.DELETE_ALL_TEMPLATE_FLOWPAGES_MODAL, open: false },
    { type: ModalTypes.RESET_CUSTOMIZATION_OPTIONS, open: false },
    { type: ModalTypes.RESTORE_PLAN_MODAL, open: false },
    { type: ModalTypes.UPGRADE_SUCCESS_MODAL, open: false },
    { type: ModalTypes.TEMPLATE_ACTIONS_MODAL, open: false },
    { type: ModalTypes.TEMPLATES_SIGN_UP_MODAL, open: false },
    { type: ModalTypes.TEMPLATES_DOWNLOAD_MODAL, open: false },
    { type: ModalTypes.PROFILE_PICTURE, open: false },
    { type: ModalTypes.TRANSFER_FOLDER, open: false },
    { type: ModalTypes.INVITE_MEMBER_MODAL, open: false },
    { type: ModalTypes.DESKTOP_ASSET_CREATION_MODAL, open: false },
    { type: ModalTypes.DESKTOP_PROFILE_MENU_MODAL, open: false },
    { type: ModalTypes.DESKTOP_SHOPPING_CART_EMPTY_STATE_MODAL, open: false },
    { type: ModalTypes.CREATE_TEAM_MODAL, open: false },
    { type: ModalTypes.MOBILE_CODE_DETAILS_ACTIONS_DRAWER, open: false },
    { type: ModalTypes.ANALYTICS_ACTION_BAR_MENU, open: false },
    { type: ModalTypes.UNSAVED_CHANGES, open: false },
    { type: ModalTypes.MOBILE_GLOBAL_SEARCH, open: false },
    { type: ModalTypes.DELETE_PAGE_TEMPLATE, open: false },
    { type: ModalTypes.RENAME_PAGE_TEMPLATE, open: false },
    { type: ModalTypes.VIDEO_DENIAL_SHEET_MOBILE, open: false },
    { type: ModalTypes.NAV_PLANS_UPGRADE_DIALOG, open: false },
    { type: ModalTypes.SEARCH_FLOWPAGE_TEMPLATES, open: false },
    { type: ModalTypes.UNIFIED_ASSET_CREATION_DIALOG, open: false },
    { type: ModalTypes.UNIFIED_ASSET_ARCHIVE_DIALOG, open: false },
    { type: ModalTypes.UNIFIED_ASSET_ARCHIVE_RESTORE_DIALOG, open: false },
    { type: ModalTypes.UNIFIED_ASSET_ARCHIVE_PERM_DELETE_DIALOG, open: false },
    { type: ModalTypes.TRANSFER_FLOWPAGE_MODAL, open: false },
    { type: ModalTypes.PIXEL_WIZARD_MODAL, open: false },
    { type: ModalTypes.UNIFIED_TEMPLATES_LIMIT_UPGRADE_MODAL, open: false },
    { type: ModalTypes.TRANSFER_UNIFIED_ASSET_MODAL, open: false },
    { type: ModalTypes.UNIFIED_ASSET_FOLDER_MORE_OPTIONS, open: false },
    { type: ModalTypes.SHARE_UNIFIED_ASSET_MODAL, open: false },
    { type: ModalTypes.MOVE_UNIFIED_ASSET_MODAL, open: false },
    { type: ModalTypes.UNIFIED_ASSET_BULK_ACTION_PARTIAL_EXECUTION_MODAL, open: false },
    { type: ModalTypes.ARCHIVE_UNIFIED_ASSET_RESTORE_MODAL, open: false },
    { type: ModalTypes.TRIAL_INTRO_OVERLAY, open: false },
    { type: ModalTypes.DATA_CAP, open: false },
    { type: ModalTypes.ENABLE_AUTOSHARE_TEAM_MODAL, open: false },
    { type: ModalTypes.DISABLE_AUTOSHARE_TEAM_MODAL, open: false },
    { type: ModalTypes.MULTI_DOWNLOAD_UAM_MODAL, open: false },
    {
      type: ModalTypes.ASSET_MGMT_LOADING_TIMEOUT_MODAL,
      open: false
    },
    { type: ModalTypes.ASSET_MGMT_LOADING_TIMEOUT_SNACKBAR, open: false }
  ]
}

const modalSlice = createSlice({
  name: 'modal',
  initialState: initialModalState,
  reducers: {
    setModalOpen(
      state,
      action: PayloadAction<{
        type: ModalTypes
        props?: ModalProps
      } | null>
    ) {
      const type = action?.payload?.type
      const props = action?.payload?.props

      state.modals = state.modals.map(modal =>
        modal.type === type ? { ...modal, open: true, ...(props && { props }) } : modal
      )
    },
    closeModals(state) {
      state.modals.map(modal => {
        return {
          ...modal,
          open: false
        }
      })
    },
    setModalClose(state, action: PayloadAction<ModalTypes | null>) {
      const type = action?.payload
      state.modals = state.modals.map(modal =>
        modal.type === type ? { type, open: false } : modal
      )
    }
  }
})

export const { setModalClose, setModalOpen, closeModals } = modalSlice.actions
export default modalSlice.reducer
