import { APP_BASE_URL } from '.'
import { APP_ENV, IS_CYPRESS } from '../../constants/env'

export function getInternalAdminBaseUrl(): string {
  if (APP_ENV === 'development' || IS_CYPRESS) {
    return 'http://localhost:3003/internal-admin'
  }

  return `${APP_BASE_URL}/internal-admin`
}
