import { APP_ENV, IS_CYPRESS, IS_REVIEW_APP, NGROK_DOMAIN, USE_NGROK } from '../../constants/env'

export function getAppBaseUrl(): string {
  if (process.env.IS_SMOKETEST === 'true') {
    return `https://smoketest.flowcode.com`
  }

  if (IS_REVIEW_APP) {
    //running in a review app
    return `https://flow-app-${process.env.PR_NUMBER}.reviewapps.flowcode.com`
  }

  if (USE_NGROK) {
    return `https://${NGROK_DOMAIN}`
  }

  if (APP_ENV === 'development' || IS_CYPRESS) {
    return 'http://localhost:3000'
  }

  switch (APP_ENV) {
    case 'staging':
      return `https://app.stg.flowcode.com`
    case 'preprod':
      return `https://app.preprod.flowcode.com`
    case 'production':
    default:
      return `https://app.flowcode.com`
  }
}
