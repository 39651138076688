import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useIsFlowpage } from '../../../hooks/useIsFlowpage/useIsFlowpage'

import { sha1Hash } from '@dtx-company/inter-app/src/event-tracking/helpers/impact'
import { useEffect } from 'react'
/**
 * Impact Universal Tracking Tag script.
 * This should be placed in the document head.
 *
 */
export const ImpactUttHeadScript = (): JSX.Element => {
  return (
    <script
      id="impact-js"
      type="text/javascript"
      async
      dangerouslySetInnerHTML={{
        __html: `
          (function(a,b,c,d,e,f,g){
            e['ire_o']=c;
            e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};
            f=d.createElement(b);
            g=d.getElementsByTagName(b)[0];
            f.async=1;
            f.src=a;
            g.parentNode.insertBefore(f,g);
          })('https://utt.impactcdn.com/A4848491-e412-49c8-9183-ddc13fb858d11.js','script','ire',document,window);
        `
      }}
    />
  )
}

export const ImpactSync = (): null => {
  const isFlowpage = useIsFlowpage()
  const { isAuthChecked, jwt } = useAuthState()
  useEffect(() => {
    if (isFlowpage) return
    if (!isAuthChecked) return

    if (window?.ire) {
      const email = jwt?.email ? sha1Hash(jwt.email) : ''
      window.ire('identify', {
        customerId: jwt?.ithacaId ?? '',
        customerEmail: email
      })
    }
  }, [isFlowpage, isAuthChecked, jwt])

  return null
}
