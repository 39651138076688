import { OPT_OUT } from '../../../constants/cookies'
import { trackHubSpotPage } from '@dtx-company/inter-app/src/event-tracking/helpers'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const handleRouteChange = (path: string): void => {
  trackHubSpotPage(path)
}

export const useTrackRouteChange = (): void => {
  const router = useRouter()
  const optOut = cookies.get(OPT_OUT)
  useEffect(() => {
    if (!optOut) {
      router.events.on('routeChangeComplete', handleRouteChange)
    }

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router, optOut])
}
