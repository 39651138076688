import { Box, Typography } from '@dtx-company/design-system/src'
import { FC, PropsWithChildren } from 'react'

interface GeneratorSectionFieldsetProps {
  heading: string
  description: string
  isSmallVariant?: boolean
}

export const GeneratorSectionFieldset: FC<PropsWithChildren<GeneratorSectionFieldsetProps>> = ({
  heading,
  description,
  isSmallVariant,
  children
}) => {
  return (
    <Box
      component="fieldset"
      sx={{
        border: 'none',
        m: 0,
        p: 0,
        legend: {
          p: 0
        },
        minWidth: 0
      }}
    >
      {isSmallVariant ? (
        <Typography component="legend" variant="bodySmall">
          {heading}
        </Typography>
      ) : (
        <>
          <Typography
            component="legend"
            variant={{
              xs: 'labelLarge',
              md: 'headlineXSmall'
            }}
          >
            {heading}
          </Typography>
          <Box
            sx={{
              mt: 0.5
            }}
          >
            <Typography
              component="p"
              variant={{
                xs: 'caption',
                md: 'labelMedium'
              }}
              color="text.secondary"
            >
              {description}
            </Typography>
          </Box>
        </>
      )}

      {children}
    </Box>
  )
}
