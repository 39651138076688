import { LoggerOptions } from './types/LoggerOptions'

export async function buildOptionsForFetchError(res: Response): Promise<Partial<LoggerOptions>> {
  //res body stream cannot be read multilpe times, so we read res properties from a cloned copy
  const clonedRes = res.clone()

  let body = undefined

  try {
    const contentType = clonedRes.headers.get('Content-Type')
    if (contentType && contentType.includes('application/json')) {
      body = await clonedRes.json()
    } else {
      body = await clonedRes.text()
    }
  } catch (e) {
    console.error(e)
  }

  return {
    status: clonedRes.status,
    statusText: clonedRes.statusText,
    body,
    url: clonedRes.url
  }
}
