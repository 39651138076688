import { Box, Stack } from '@dtx-company/design-system/src'
import {
  COLOR_INPUT_WIDTH_DESKTOP,
  COLOR_INPUT_WIDTH_DESKTOP_SMALL_VARIANT,
  COLOR_INPUT_WIDTH_MOBILE
} from './ColorSelector.constants'
import { CodeConfigurationField } from '@dtx-company/inter-app/src/types/flowcode'
import { ColorInput } from './ColorInput'
import { FC } from 'react'
import { GeneratorSectionFieldset } from '../../subcomponents/GeneratorSectionFieldSet/GeneratorSectionFieldSet'
import { LOGGED_OUT_GENERATOR_COLORS } from '../../../../Homepage/SimplifiedDesktop/constants'
import { selectColor } from '@app/code/src/machines/locg/locgSelectors'
import { useGlobalServices } from '@app/common/src/hooks/useGlobalServices'
import { useSelector } from '@xstate/react'
import useTranslation from 'next-translate/useTranslation'

export const ColorSelector: FC<{ isSmallVariant?: boolean }> = ({ isSmallVariant }) => {
  const { locgService } = useGlobalServices()
  const selectedColor = useSelector(locgService, selectColor)
  const { t } = useTranslation('logged-out-generator')

  return (
    <GeneratorSectionFieldset
      heading={
        isSmallVariant
          ? t('LoggedOutGenerator.ColorSelector.smallVariantHeading')
          : t('LoggedOutGenerator.ColorSelector.heading')
      }
      description={t('LoggedOutGenerator.ColorSelector.description')}
      isSmallVariant={isSmallVariant}
    >
      <Stack
        component="ul"
        sx={theme => ({
          listStyleType: 'none',
          padding: 0,
          mt: isSmallVariant ? 2 : 4,
          mb: 0,
          display: 'grid',
          gridTemplateColumns: {
            xs: `repeat(auto-fill, minmax(${COLOR_INPUT_WIDTH_MOBILE}, 1fr))`,
            md: `repeat(auto-fill, minmax(${
              isSmallVariant ? COLOR_INPUT_WIDTH_DESKTOP_SMALL_VARIANT : COLOR_INPUT_WIDTH_DESKTOP
            }, 1fr))`
          },
          gap: theme.spacing(isSmallVariant ? 2 : 4)
        })}
      >
        {LOGGED_OUT_GENERATOR_COLORS.map(color => {
          return (
            <Box
              component="li"
              key={color.id}
              sx={{
                display: 'inline-flex'
              }}
            >
              <ColorInput
                color={color}
                selected={selectedColor?.id === color.id}
                handleChange={() =>
                  locgService.send({
                    type: 'CHANGE',
                    key: CodeConfigurationField.COLOR,
                    value: color
                  })
                }
                isSmallVariant={isSmallVariant}
              />
            </Box>
          )
        })}
      </Stack>
    </GeneratorSectionFieldset>
  )
}
