import { AllCookies, HEAP_COOKIE } from './cookies.constants'
import { Cookie, UseCookiesType } from '../../types/cookies'
import { deleteAllCookies, shouldUseSecureCookie } from './cookies.utils'
import { getCookieDomain } from '../../utils/cookies/cookies.utils'
import { useCallback, useEffect, useState } from 'react'
import Cookies, { CookieSetOptions } from 'universal-cookie'
import isEqual from 'lodash/isEqual'

const cookies = new Cookies()

/**
 * - Wraps optOut logic
 * - Returns all functions needed to interact w/ cookies
 * - Handles EU case
 */
export const useCookies = (): UseCookiesType => {
  const optOut = cookies.get(Cookie.OPT_OUT)

  /**
   * Set cookie by name
   * @param name {Cookie} -
   * @param value {string?} - Optional value or use default
   */
  const setCookie = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (name: Cookie, value: any, options?: CookieSetOptions): void => {
      if (optOut) return
      const c = AllCookies[name]
      cookies.set(c.name, value ?? c.defaultValue, { ...options })
      return
    },
    [optOut]
  )

  /**
   * Remove all cookies and persist OPT_OUT
   */
  const handleUserOptOut = useCallback(async (): Promise<void> => {
    deleteAllCookies()
    setCookie(Cookie.OPT_OUT, 'true', {
      expires: new Date('December 17, 3000 03:24:00'),
      path: '/',
      sameSite: 'none',
      domain: getCookieDomain(),
      secure: shouldUseSecureCookie()
    })
    return
  }, [setCookie])

  return {
    optOut: Boolean(optOut),
    setCookie,
    handleUserOptOut
  }
}

const CookieChangedEvent = 'flowcode:cookie-changed'
export const useCookieListener = (
  cookieName: string,
  listener: (cookieValue: { sessionId: string }) => void
): void => {
  useEffect(() => {
    let previousValue = cookies.get(cookieName)
    const observer: EventListener = () => {
      const newValue = cookies.get(cookieName)
      if (isEqual(previousValue, newValue) === false) {
        listener(newValue)
      }
      previousValue = newValue
    }
    window.addEventListener(CookieChangedEvent, observer)
    return () => {
      window.removeEventListener(CookieChangedEvent, observer)
    }
  }, [cookieName, listener])
}

export const useCookieObserver = (): void => {
  useEffect(() => {
    let lastCookie = document.cookie
    // rename document.cookie to document._cookie, and redefine document.cookie
    const nativeCookie = '_cookie'
    const cookieDescriptor = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie')
    if (cookieDescriptor) {
      Object.defineProperty(Document.prototype, nativeCookie, cookieDescriptor)
      Object.defineProperty(Document.prototype, 'cookie', {
        enumerable: true,
        configurable: true,
        get() {
          return this[nativeCookie]
        },
        set(value) {
          this[nativeCookie] = value
          // check cookie change
          const cookie = this[nativeCookie]
          if (cookie !== lastCookie) {
            window.dispatchEvent(new Event(CookieChangedEvent))
            lastCookie = cookie
          }
        }
      })
    }

    return () => {
      if (cookieDescriptor) {
        Object.defineProperty(Document.prototype, 'cookie', cookieDescriptor)
      }
    }
  }, [])
}

export const useHeapSessionId = (): string | undefined => {
  const [heapSessionId, setHeapSessionId] = useState<string | undefined>(() => {
    if (typeof window !== 'undefined') {
      const tempCookies = new Cookies()
      const heapCookie = tempCookies.get(HEAP_COOKIE)
      return heapCookie?.sessionId
    }
    return undefined
  })

  const listener = useCallback((cookieValue: { sessionId: string }) => {
    if (cookieValue) {
      setHeapSessionId(cookieValue.sessionId)
    }
  }, [])

  useCookieListener(HEAP_COOKIE, listener)
  return heapSessionId
}
