import { GetAutoDesignsPartialsResponseType } from '@dtx-company/true-common/src/types/autoDesign'

interface FlowcodeColorOptionType {
  label: string
  color: string
  darkColor?: string
  id: string
  defaultOnly: boolean
}

export enum AutoDesignColorOptions {
  PRIMARY_COLOR = '$primaryColor',
  SECOND_COLOR = '$secondColor',
  THIRD_COLOR = '$thirdColor'
}

export type AutoDesignColorProps = Record<AutoDesignColorOptions, string>

export interface AutoInputOptionsProps {
  logoImageUrl: string
  logoWidth: number
  logoHeight: number
  logoFillSvgColor: string
  logoFillSvgSwitch: boolean
  colors: Record<AutoDesignColorOptions, string>
  ignoreColorPriority: boolean
  fetchCodes: boolean
}

export interface HandleAutoDesignToolsInputProps {
  logoImageUrl?: string
  logoWidth?: number
  logoHeight?: number
  logoFillSvgColor?: string
  logoFillSvgSwitch?: boolean
  colors?: Record<AutoDesignColorOptions, string>
  ignoreColorPriority?: boolean
  fetchCodes?: boolean
}

export interface AutoDesignToolsProps {
  autoInputData: AutoInputOptionsProps
  isLoading: boolean
  setAutoInputData: React.Dispatch<React.SetStateAction<AutoInputOptionsProps>>
}

export interface AutoDesignLogoToolsProps {
  handleAutoInput: (updatedValues: HandleAutoDesignToolsInputProps) => void
}

export interface AutoDesignColorToolsProps {
  autoInputData: AutoInputOptionsProps
  handleAutoInput: (updatedValues: HandleAutoDesignToolsInputProps) => void
  colors?: FlowcodeColorOptionType[]
}

export interface GetAutoDesignsPartialsProps {
  logoImageUrl: string
  replaceValues: string
  autoDesignFetchCount: number
  ignoreColorPriority: boolean
  logoWidth: number
  logoHeight: number
  logoFillSvgColor: string
}

export interface AutoDesignHeapEventProps {
  configuration: string
  unselectedCodes: GetAutoDesignsPartialsResponseType[]
  centerImageUrl: string
}
