import { SVGProps, memo } from 'react'

function WifiSvg({
  stroke = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 28 20" {...rest}>
      <path
        stroke={color || stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.398 10.058A13.716 13.716 0 0114.048 7c3.161 0 6.222 1.082 8.651 3.058M1 5.798C4.59 2.706 9.213 1 14 1c4.787 0 9.41 1.706 13 4.798M9.737 14.33a7.497 7.497 0 014.27-1.33 7.5 7.5 0 014.27 1.33M14 19h.015"
      />
    </svg>
  )
}

export const Wifi = memo(WifiSvg)

function Wifi2Svg({ stroke = 'currentcolor', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g
        clipPath="url(#prefix__clip0)"
        stroke={stroke || '#111'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M3.648 8.438a7.589 7.589 0 014.88-1.78c1.783 0 3.51.63 4.88 1.78M1.167 5.959A11.04 11.04 0 018.5 3.167c2.7 0 5.307.992 7.333 2.792M6.095 10.925a4.136 4.136 0 014.817 0M8.5 13.643h.008" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(.5 .5)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Wifi2 = memo(Wifi2Svg)
