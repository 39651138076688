import {
  DateFormat,
  Intervals,
  LastMonth,
  Today
} from '@dtx-company/inter-app/src/constants/dateFilter'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { format } from 'date-fns'

export interface DateFilterSliceState {
  interval: Intervals
  startDate: string
  endDate: string
}

const initialState: DateFilterSliceState = {
  interval: Intervals.DAILY,
  startDate: format(LastMonth, DateFormat.API),
  endDate: format(Today, DateFormat.API)
}

const dateFiltersSlice = createSlice({
  name: 'dateFilters',
  initialState,
  reducers: {
    setDates(state, action: PayloadAction<DateFilterSliceState>) {
      state.endDate = action.payload.endDate
      state.startDate = action.payload.startDate
      state.interval = action.payload.interval
    },
    resetDateFilters(state) {
      state.endDate = initialState.endDate
      state.startDate = initialState.startDate
      state.interval = initialState.interval
    }
  }
})

export const { setDates, resetDateFilters } = dateFiltersSlice.actions
export default dateFiltersSlice.reducer
