import { SVGProps, memo } from 'react'

function PhoneSvg({
  stroke = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 21 21" {...rest}>
      <path
        stroke={color || stroke}
        d="M17.2 13.137h0l-1.675-1.117h0a1.494 1.494 0 00-2.004.325h0l-1.033 1.329-.201-.11a9.149 9.149 0 01-.982-.599c-.482-.34-1.116-.861-2.011-1.758-.896-.897-1.418-1.531-1.759-2.013a9.112 9.112 0 01-.602-.987c-.035-.065-.07-.13-.107-.195l1.328-1.033h0c.617-.48.758-1.354.326-2.004h0L7.397 3.35h0l-1.29-1.936s0 0 0 0A1.495 1.495 0 004.096.963h0l-1.486.891a2.568 2.568 0 00-1.164 1.508h0l-.002.007c-.5 1.827-.417 5.367 4.951 10.735 5.369 5.368 8.908 5.452 10.735 4.95h0l.007-.001a2.568 2.568 0 001.508-1.164l.001-.003.89-1.483h0a1.494 1.494 0 00-.451-2.01s0 0 0 0L17.2 13.138z"
      />
    </svg>
  )
}

export const Phone = memo(PhoneSvg)

function Phone2Svg({
  stroke = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...rest}>
      <path
        stroke={color || stroke}
        strokeWidth={1.5}
        d="M13.922 17.1l.364-.656-.246-.135a10.71 10.71 0 01-1.169-.712c-.575-.406-1.326-1.024-2.384-2.083-1.058-1.06-1.677-1.812-2.083-2.386-.332-.47-.526-.825-.717-1.176l-.13-.237a.119.119 0 01.031-.152h0l.003-.002 1.568-1.22h0c.67-.522.825-1.472.354-2.18h0l-1.274-1.91h0L6.721 1.973s0 0 0 0a1.624 1.624 0 00-2.185-.492h0l-1.75 1.05a2.896 2.896 0 00-1.31 1.696h0l-.001.007c-.573 2.09-.504 6.206 5.79 12.5 6.295 6.295 10.412 6.364 12.5 5.791h0l.008-.002a2.888 2.888 0 001.696-1.31l.002-.002 1.048-1.746h0a1.624 1.624 0 00-.492-2.186s0 0 0 0l-2.217-1.478h0l-1.971-1.314h0a1.624 1.624 0 00-2.18.354h0l-1.22 1.568h0l-.002.003a.119.119 0 01-.152.031l-.363.656zm0 0a.87.87 0 001.109-.23m-1.11.23c-.075-.043-.151-.084-.23-.127-.785-.427-1.773-.965-3.735-2.93-1.967-1.97-2.504-2.955-2.932-3.74l-.123-.225a.869.869 0 01.23-1.109l1.567-1.22a.872.872 0 00.19-1.172l-1.273-1.91-1.518-2.278a.875.875 0 00-1.177-.265L3.17 3.175c-.473.279-.821.73-.972 1.258l12.833 12.436m0 0l1.22-1.567-1.22 1.567z"
      />
    </svg>
  )
}

export const Phone2 = memo(Phone2Svg)

function Phone3Svg({ fill, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.636 11.668l-1.453-1.008c-.558-.336-1.229-.224-1.675.224l-.894 1.12h-.112l-.223.448.223-.448c-.112 0-.112-.112-.223-.112-.224-.112-.559-.336-.894-.56a15.815 15.815 0 01-1.787-1.569 15.837 15.837 0 01-1.565-1.792c-.223-.336-.446-.56-.558-.896 0-.112-.112-.112-.112-.224v-.112l1.117-.897c.447-.336.559-1.12.224-1.68L5.81 2.706l-1.117-1.68C4.358.464 3.576.352 3.017.688l-1.34.784A3.5 3.5 0 00.67 2.706C.224 4.274.336 7.299 5.028 12.004s7.708 4.705 9.273 4.37c.558-.113 1.005-.449 1.228-1.01l.782-1.343c.336-.56.224-1.233-.335-1.68l-1.34-.673z"
        fill={fill || '#111'}
      />
    </svg>
  )
}

export const Phone3 = memo(Phone3Svg)
