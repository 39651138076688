import { Link } from '@dtx-company/flow-codegen/src/page/generated.types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ScanDestinationFormValuesType } from '../../types/ScanToOwnGeneric'
import { ScanDestinationMenuItemsType } from '../../types/ScanDestinationMenu'

export type PageGenericDestinationType = Omit<ScanDestinationMenuItemsType, 'icon'>

export interface PageLinksType {
  link: Link
  destinationId: string
  scanDestinationFormValue: ScanDestinationFormValuesType
}

export interface PageGenericState {
  nickname: string
  scanDestinations: PageGenericDestinationType[]
  links: PageLinksType[]
  reverseLoadScanDestinationForms: boolean
  isFinalSTOfpPage: boolean
}

export const initialState: PageGenericState = {
  nickname: '',
  scanDestinations: [],
  links: [],
  reverseLoadScanDestinationForms: false,
  isFinalSTOfpPage: false
}

const PageGeneric = createSlice({
  name: 'GenericFpOnboarding',
  initialState,
  reducers: {
    setFpScanDestinations: (
      state,
      action: PayloadAction<{ destination: PageGenericDestinationType; isActive: boolean }>
    ) => {
      const payload = action.payload.destination
      const isActive = action.payload.isActive
      if (isActive) {
        const filteredDestinations = state.scanDestinations.filter(
          destination => destination.id !== payload.id
        )
        const filteredLinks = state.links.filter(link => link.destinationId !== payload.id)
        state.scanDestinations = [...filteredDestinations]
        state.links = [...filteredLinks]
      } else {
        state.scanDestinations.push(action.payload.destination)
      }
    },
    setInitialFpScanDestinations: (
      state,
      action: PayloadAction<{ destinations: PageGenericDestinationType[] }>
    ) => {
      const payload = action.payload.destinations
      state.scanDestinations = state.scanDestinations.concat(payload)
    },
    setPageLink: (state, action: PayloadAction<{ fields: PageLinksType }>) => {
      const fields = action.payload.fields
      const linkIndex = state.links?.findIndex(link => link?.destinationId === fields.destinationId)
      if (linkIndex !== undefined && linkIndex > -1) {
        const newLinks = state.links.map(link => {
          return link?.destinationId === fields.destinationId ? fields : link
        })
        state.links = [...newLinks]
      } else {
        state.links = [...state.links, fields]
      }
    },
    setScanDestinationOrder: (state, action: PayloadAction<{ reverse: boolean }>) => {
      state.reverseLoadScanDestinationForms = action.payload.reverse
    },
    setIsFinalSTOfpPage: (state, action: PayloadAction<boolean>) => {
      state.isFinalSTOfpPage = action.payload
    }
  }
})

export const {
  setFpScanDestinations,
  setInitialFpScanDestinations,
  setPageLink,
  setScanDestinationOrder,
  setIsFinalSTOfpPage
} = PageGeneric.actions

export default PageGeneric.reducer
