import {
  ConfirmationAction,
  FormConfig,
  PageFieldName,
  defaultBackgroundColor,
  defaultComponentsConfig,
  defaultFormConfig,
  defaultPaletteConfig,
  defaultPrimaryColor,
  defaultSecondaryColor,
  defaultSuccessAction,
  defaultTextStyleConfig,
  defaultTheme,
  normalizeUrl
} from './utils'
import {
  ERROR_MSG_INVALID_VALUE,
  ERROR_MSG_UNIQUE_NAME,
  ValidationError,
  Validator,
  globalValidators,
  isFieldValid,
  isNameValid,
  settingsValidatorTypes,
  valueGetter
} from './validation'
import { Field, Form, Page, PageTheme } from '../../types/form.types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SuccessAction } from '@dtx-company/connect-codegen/src/gen/forms/v1/success_screen_pb'

export interface FormState {
  page: Page
  // FORM properties
  form: Form
  // submissions and editing properties
  isEditMode: boolean
  validationErrors: Record<string, ValidationError>
  validators: Record<string, Validator>
}

const initialState: FormState = {
  page: {
    id: '',
    name: undefined,
    slugName: undefined,
    domain: undefined,
    disableBranding: false,
    tags: [],
    config: defaultFormConfig,
    theme: defaultTheme,
    pageComponents: [],
    formComponentId: PageFieldName.FORM,
    formContentId: PageFieldName.CONTENT,
    formBackgroundColor: defaultBackgroundColor
  },
  form: {
    id: '',
    active: true,
    fields: [],
    fieldsOrder: [],
    successAction: {}
  },
  // Editing properties
  isEditMode: true,
  validators: {},
  validationErrors: {}
}

type FieldOrder = {
  sourceIndex: number
  destinationIndex: number
  itemId: number
}

type FieldUpdate = {
  index: number
  field: Field
}

type FieldOption = {
  index: number
  optionIndex: number
  option: string
}

type FieldOptions = {
  index: number
  options: string[]
}

type FieldOptional = {
  field: number
  optional: boolean
}

type BackgroundOption = {
  imageSrc: string
  backgroundColor: string
  darken: boolean
}

type FieldName = {
  field: number
  name: string
}

type SuccessOption = {
  title?: string
  description?: string
}

const insertFormField = (state: FormState, field: Field, index: number): void => {
  const newFieldId = state.form.fields.length
  state.form.fields.push(field)
  const fieldsOrder = [...state.form.fieldsOrder]
  fieldsOrder.splice(index, 0, newFieldId)
  state.form.fieldsOrder = fieldsOrder
}

const formSlice = createSlice({
  name: 'form',
  initialState: initialState,
  reducers: {
    resetPage(state, _action: PayloadAction<void>) {
      state.page = { ...initialState.page }
    },
    setPageComponents(state, action: PayloadAction<Record<string, any>[]>) {
      state.page.pageComponents = action.payload
    },
    setEditMode(state, action: PayloadAction<boolean>) {
      state.isEditMode = action.payload
    },
    setFormActive(state, action: PayloadAction<boolean>) {
      state.form.active = action.payload
    },
    setForm(state, action: PayloadAction<Form>) {
      state.form = action.payload
    },
    resetForm(state, _action: PayloadAction<void>) {
      state.form = { ...initialState.form }
      state.page.config.formTitle = { text: '' }
      state.page.config.formDescription = { text: '' }
      state.form.fields = []
      state.form.fieldsOrder = []
    },
    setFormId(state, action: PayloadAction<string>) {
      state.form.id = action.payload
    },
    setPage(state, action: PayloadAction<Page>) {
      state.page = action.payload
    },
    setFormComponentId(state, action: PayloadAction<string>) {
      state.page.formComponentId = action.payload
    },
    setFormContentId(state, action: PayloadAction<string>) {
      state.page.formContentId = action.payload
    },
    setPageFormConfig(state, action: PayloadAction<FormConfig>) {
      state.page.config = action.payload
      //In case default created page does not have title and description set to empty
      state.page.config.formTitle = { text: action.payload.formTitle?.text ?? '' }
      state.page.config.formDescription = { text: action.payload.formDescription?.text ?? '' }
    },
    setPageName(state, action: PayloadAction<string>) {
      state.page.name = action.payload
    },
    setDomain(state, action: PayloadAction<string>) {
      state.page.domain = action.payload
    },
    setSlugName(state, action: PayloadAction<string>) {
      state.page.slugName = action.payload
    },
    setDisableBranding(state, action: PayloadAction<boolean>) {
      state.page.disableBranding = action.payload
    },
    setTags(state, action: PayloadAction<string[]>) {
      state.page.tags = [...new Set(action.payload)]
    },
    setTheme(state, action: PayloadAction<PageTheme>) {
      state.page.theme = action.payload
      if (!state.page.theme.palette) {
        state.page.theme.palette = defaultPaletteConfig
      }
      if (!state.page.theme.components) {
        state.page.theme.components = defaultComponentsConfig
      }
      if (!state.page.theme.typography) {
        state.page.theme.typography = defaultTextStyleConfig
      }
    },
    setTitle(state, action: PayloadAction<string>) {
      state.page.config.formTitle = { text: action.payload }
    },
    setDescription(state, action: PayloadAction<string>) {
      state.page.config.formDescription = { text: action.payload }
    },
    setBackground(state, action: PayloadAction<BackgroundOption>) {
      state.page.config.background = action.payload
    },
    setPrimaryColor(state, action: PayloadAction<string>) {
      const primaryColor = action.payload ? action.payload : defaultPrimaryColor
      if (state.page.theme.palette.primary) {
        state.page.theme.palette.primary.main = primaryColor
      } else {
        state.page.theme.palette.primary = { main: primaryColor }
      }
    },
    setSecondaryColor(state, action: PayloadAction<string>) {
      const secondaryColor = action.payload ? action.payload : defaultSecondaryColor
      if (state.page.theme.palette.secondary) {
        state.page.theme.palette.secondary.main = secondaryColor
      } else {
        state.page.theme.palette.secondary = { main: secondaryColor }
      }
    },
    setPageFont(state, action: PayloadAction<string>) {
      if (state.page?.theme?.typography) {
        state.page.theme.typography.fontFamily = action.payload
      } else {
        state.page.theme.typography = { fontFamily: action.payload }
      }
    },
    setPageTextStyle(state, action: PayloadAction<string>) {
      if (state.page?.theme?.components?.MuiTextField?.defaultProps) {
        state.page.theme.components.MuiTextField.defaultProps.variant = action.payload
      } else {
        state.page.theme.components.MuiTextField = { defaultProps: { variant: action.payload } }
      }
    },
    setPageButtonStyle(state, action: PayloadAction<string>) {
      if (state.page?.theme?.components?.MuiButton?.defaultProps) {
        state.page.theme.components.MuiButton.defaultProps.variant = action.payload
      } else {
        state.page.theme.components.MuiButton = { defaultProps: { variant: action.payload } }
      }
    },
    insertField(state, action: PayloadAction<{ field: Field; index: number | undefined }>) {
      const fieldIndex = action.payload.index
      insertFormField(
        state,
        action.payload.field,
        fieldIndex == undefined ? state.form.fields.length : fieldIndex
      )
    },
    setFieldName(state, action: PayloadAction<{ name: string; index: number }>) {
      const fieldId = action.payload.index
      if (state.form.fields[fieldId]) {
        state.form.fields[fieldId].name = action.payload.name
      }
    },
    removeField(state, action: PayloadAction<number>) {
      //filter, re-order
      state.form.fields = state.form.fieldsOrder
        .filter(index => index != action.payload)
        .map(index => state.form.fields[index])
      state.form.fieldsOrder = [...state.form.fields.keys()]
      state.validationErrors = {}
    },
    duplicateField(state, action: PayloadAction<FieldName>) {
      const fieldId = action.payload.field
      const curField = state.form.fields[fieldId]
      const field = {
        ...curField,
        name: action.payload.name,
        id: ''
      }
      const index = state.form.fieldsOrder.findIndex(f => f == fieldId)
      insertFormField(state, field, index)
    },
    setIsFieldOptional(state, action: PayloadAction<FieldOptional>) {
      state.form.fields[action.payload.field].required = action.payload.optional
    },
    updateField(state, action: PayloadAction<FieldUpdate>) {
      state.form.fields[action.payload.index] = action.payload.field
    },
    addNewFieldOption(state, action: PayloadAction<FieldOption>) {
      const field = state.form.fields[action.payload.index]
      field.properties.choice?.choices.push({
        value: `Option ${field.properties.choice?.choices.length + 1}`
      })
      state.form.fields[action.payload.index] = field
    },
    removeFieldOption(state, action: PayloadAction<FieldOption>) {
      state.form.fields[action.payload.index].properties.choice?.choices.splice(
        action.payload.optionIndex,
        1
      )
    },
    updateFieldOption(state, action: PayloadAction<FieldOption>) {
      const field = state.form.fields[action.payload.index]
      const f = { ...field }
      if (f && f.properties.choice) {
        f.properties.choice.choices[action.payload.optionIndex] = {
          value: action.payload.option
        }
        state.form.fields[action.payload.index] = f
      }
    },
    setFieldOptions(state, action: PayloadAction<FieldOptions>) {
      const field = state.form.fields[action.payload.index]
      if (field && field.properties.choice) {
        field.properties.choice.choices = action.payload.options.map(option => {
          return { value: option }
        })
        state.form.fields[action.payload.index] = field
      }
    },
    reorderFields(state, action: PayloadAction<FieldOrder>) {
      const fieldsOrder = Array.from(state.form.fieldsOrder)
      fieldsOrder.splice(action.payload.sourceIndex, 1)
      fieldsOrder.splice(action.payload.destinationIndex, 0, action.payload.itemId)
      state.form.fieldsOrder = fieldsOrder
    },
    setSuccessActionThankYou(state, action: PayloadAction<SuccessOption>) {
      if (!state.form.successAction?.[ConfirmationAction.THANK_YOU]) {
        state.form.successAction = SuccessAction.fromJson(defaultSuccessAction).toJson() as Record<
          string,
          any
        >
      }
      if (action.payload.title != undefined) {
        state.form.successAction[ConfirmationAction.THANK_YOU].title = action.payload.title
      }
      if (action.payload.description != undefined) {
        state.form.successAction[ConfirmationAction.THANK_YOU].description =
          action.payload.description
      }
    },
    setSuccessActionRedirect(state, action: PayloadAction<string>) {
      state.form.successAction = SuccessAction.fromJson({
        [ConfirmationAction.REDIRECT]: {
          url: normalizeUrl(action.payload)
        }
      }).toJson() as Record<string, any>
    },
    setSuccessActionViewLiveResults(state, action: PayloadAction<string[]>) {
      state.form.successAction = SuccessAction.fromJson({
        [ConfirmationAction.VIEW_LIVE_RESULTS]: {
          fieldNames: action.payload
        }
      }).toJson() as Record<string, any>
      const actionHasFieldNames =
        (SuccessAction.fromJson(state.form.successAction) as Record<string, any>)?.[
          ConfirmationAction.VIEW_LIVE_RESULTS
        ]?.fieldNames?.length > 0
    },
    addFieldNameSuccessActionViewLiveResults(state, action: PayloadAction<string>) {
      const currentFormValues: string[] =
        state.form.successAction?.[ConfirmationAction.VIEW_LIVE_RESULTS]?.['fieldNames'] ?? []
      state.form.successAction = SuccessAction.fromJson({
        [ConfirmationAction.VIEW_LIVE_RESULTS]: {
          fieldNames: [...currentFormValues, action.payload]
        }
      }).toJson() as Record<string, any>
    },
    removeFieldNameSuccessActionViewLiveResults(state, action: PayloadAction<string>) {
      const currentFormValues: string[] =
        state.form.successAction?.[ConfirmationAction.VIEW_LIVE_RESULTS]?.['fieldNames'] ?? []
      state.form.successAction = SuccessAction.fromJson({
        [ConfirmationAction.VIEW_LIVE_RESULTS]: {
          fieldNames: currentFormValues.filter((val, i) => val !== action.payload)
        }
      }).toJson() as Record<string, any>
      const actionHasFieldNames =
        (SuccessAction.fromJson(state.form.successAction) as Record<string, any>)?.[
          ConfirmationAction.VIEW_LIVE_RESULTS
        ]?.fieldNames?.length > 0
      if (!actionHasFieldNames) {
        // if it removes all of the field names from it, want to reset the SA to thank you
        state.form.successAction = SuccessAction.fromJson(defaultSuccessAction).toJson() as Record<
          string,
          any
        >
      }
    },
    setValidationErrors(state, action: PayloadAction<Record<string, ValidationError>>) {
      state.validationErrors = action.payload
    },
    validateField(state, action: PayloadAction<number>) {
      const field = state.form.fields[action.payload]
      let isValidName = true
      let errorMessage = ERROR_MSG_INVALID_VALUE
      if (!isNameValid(action.payload, state.form)) {
        isValidName = false
        errorMessage = ERROR_MSG_UNIQUE_NAME
      }
      const isValid = isValidName && isFieldValid(field)
      if (!isValid) {
        state.validationErrors[action.payload] = { message: errorMessage }
      } else {
        delete state.validationErrors[action.payload]
      }
    },
    validateSetting(state, action: PayloadAction<string>) {
      const path = action.payload
      const validator = settingsValidatorTypes[path]
      if (validator) {
        const value = valueGetter({ ...state })(path)
        const valueValidator = globalValidators.get(validator.validatorType)
        const valid = valueValidator?.(Array.isArray(value) ? value : [value])
        if (!valid) {
          state.validationErrors[path] = { message: ERROR_MSG_INVALID_VALUE }
        } else {
          delete state.validationErrors[path]
        }
      }
    }
  }
})

export const {
  resetPage,
  setPageComponents,
  setEditMode,
  setForm,
  resetForm,
  setFormActive,
  setFormId,
  setIsFieldOptional,
  removeField,
  duplicateField,
  insertField,
  reorderFields,
  updateField,
  setFieldName,
  setBackground,
  setPrimaryColor,
  setSecondaryColor,
  setPage,
  setFormComponentId,
  setFormContentId,
  setPageFormConfig,
  setPageName,
  setDomain,
  setSlugName,
  setPageFont,
  setPageTextStyle,
  setPageButtonStyle,
  setTitle,
  setDescription,
  setDisableBranding,
  setTags,
  setTheme,
  addNewFieldOption,
  removeFieldOption,
  updateFieldOption,
  setFieldOptions,
  validateField,
  validateSetting,
  setValidationErrors,
  setSuccessActionThankYou,
  setSuccessActionRedirect,
  setSuccessActionViewLiveResults,
  addFieldNameSuccessActionViewLiveResults,
  removeFieldNameSuccessActionViewLiveResults
} = formSlice.actions
export default formSlice.reducer
