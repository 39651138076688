import { Components, Theme } from '@mui/material'
import { inputAdornmentClasses } from '@mui/material/InputAdornment'

export const selectComponentOverrides: Components<Theme> = {
  MuiSelect: {
    styleOverrides: {
      select: ({ theme }) => ({
        '&:focus': {
          backgroundColor: 'transparent'
        },

        textAlign: 'left',

        // Add space between error icon and select dropdown icon
        [`~ .${inputAdornmentClasses.positionEnd}`]: {
          marginRight: theme.spacing(5)
        }
      }),

      icon: {
        color: 'inherit'
      }
    }
  }
}
