import { OptimizelyReactSDKClient } from '../useOptimizelyClient'
import { createContext } from 'react'

export interface OptimizelyContextProps {
  client: OptimizelyReactSDKClient
  sessionId: string
  datafile: string
  overrides?: Record<string, string>
  attributes?: Record<string, string>
}

export const OptimizelyContext = createContext<OptimizelyContextProps | undefined>(undefined)
