import { IconBaseProps } from '../../../types'
import { Image } from '../Image'
import { layout, space } from 'styled-system'
import styled from 'styled-components'

export const IconBase = styled(Image)<IconBaseProps>`
  display: inline-block;
  ${layout}
  ${space}
`
