import * as React from 'react'
import { FlowExperimentProvider } from '../../hooks/useFlowExperiment/context/FlowExperimentProvider'
import { FlowFeatureContext } from '../../hooks/useFlowFeature/context/FlowFeatureContext'
import { FlowFeatureProvider } from '../../hooks/useFlowFeature/context/FlowFeatureProvider'
import { OptimizelyContext } from '../../hooks/useOptimizelyContext/context'
import { OptimizelyWebId } from '../OptimizelyWebId'
import { OptimizelyWebScript } from '../OptimizelyWebScript'
import { ReactNode, useMemo } from 'react'
import { OptimizelyProvider as ReactOptimizelyProvider } from '@optimizely/react-sdk'
import { checkIfUserJustSignedUp } from '../../hooks/auth/useListenForAuthChanges'
import {
  createOptimizely,
  getFormattedPlanName,
  useBufferedDispatcher,
  useBufferedTracking,
  useOptimizelyClient
} from '../../hooks/useOptimizelyClient'
import { fireAnalyticsEvent } from '../../event-tracking/helpers/fireAnalyticsEvent'
import { useCurrentUserJwt } from '../../redux/selectors/currentUserSelectors'
import { useIsMounted } from '@dtx-company/true-common/src/hooks/useIsMounted'
import { useOptimizelyContext } from '../../hooks/useOptimizelyContext'
import { useOptimizelyWebEvents } from '../../hooks/useOptimizelyWebEvents'

export interface OptimizelyProviderProps {
  children: React.ReactNode
  sessionId: string
  datafile: string
  overrides?: Record<string, string>
  attributes?: Record<string, string>
  isFlowpage?: boolean
}

const InnerOptimizelyProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const isMounted = useIsMounted()
  const context = useOptimizelyContext()
  const optimizely = useOptimizelyClient()
  useOptimizelyWebEvents()
  if (!optimizely || !context) return <>{children}</>
  return (
    <ReactOptimizelyProvider
      key={'test-heap-id'}
      isServerSide={!isMounted()}
      optimizely={optimizely}
    >
      {process.env.NODE_ENV === 'test' && (
        // need a way to test if the ReactOptimizelyProvider renders or not but spying
        // on it causes this error to throw:
        // Error: Uncaught [TypeError: Cannot set properties of undefined (setting 'props')]
        // and nothing else visibily renders, so adding something to check on
        <div data-testid="react-optimizely-provider"></div>
      )}
      <OptimizelyWebId sessionId={context.sessionId} />
      <OptimizelyWebScript key="OptimizelyWebScript" />
      <FlowFeatureProvider>
        <FlowExperimentProvider>
          <SetOptimizelyAttributes>{children}</SetOptimizelyAttributes>
        </FlowExperimentProvider>
      </FlowFeatureProvider>
    </ReactOptimizelyProvider>
  )
}

function SetOptimizelyAttributes({ children }: { children: ReactNode }): JSX.Element {
  const optimizely = useOptimizelyClient()
  const jwt = useCurrentUserJwt()
  const { featureConsole } = React.useContext(FlowFeatureContext)
  const loggedForIthacaIdRef = React.useRef<string>()
  const organization = jwt?.org?.name?.toLowerCase()
  const ithacaId = jwt?.ithacaId
  const createdAt = jwt?.createdAt
  const email = jwt?.email
  const plan = getFormattedPlanName(jwt?.org?.orgPlan ?? '', jwt?.personalPlan ?? '')
  const orgId = jwt?.org?.orgId
  const justSignedUp = jwt && checkIfUserJustSignedUp(jwt)

  React.useEffect(() => {
    if (!optimizely) return

    if (!ithacaId) {
      return
    }
    optimizely.setUser({
      ...optimizely.user,
      attributes: {
        ...optimizely.user.attributes,
        // NOTE: passing undefined to optimizely triggers an error that creates
        // an infinite rerender loop - use null for clearing attributes
        email: email || null,
        account_type: plan || null,
        organization: organization || null,
        ithacaId: ithacaId || null,
        createdAt: +new Date(createdAt || 0),
        orgId: orgId || null
      }
    })
    if (featureConsole && loggedForIthacaIdRef.current !== ithacaId && justSignedUp) {
      loggedForIthacaIdRef.current = ithacaId
      if (ithacaId) {
        const featureFlags = Object.fromEntries(
          Object.entries(featureConsole.getCache() || {}).map(([key, value]) => [key, value[0]])
        )
        fireAnalyticsEvent('userAuthenticated_optimizelyFeatureFlags', {
          ...featureFlags
        })
      }
    }
  }, [
    optimizely,
    featureConsole,
    email,
    plan,
    organization,
    ithacaId,
    createdAt,
    orgId,
    justSignedUp
  ])

  return <>{children}</>
}

export function OptimizelyProvider(props: OptimizelyProviderProps): JSX.Element {
  const { children, isFlowpage } = props
  const { sessionId, datafile, overrides, attributes } = props
  const eventDispatcher = useBufferedDispatcher(datafile)
  const isMounted = useIsMounted()()
  // Memoize so the context only changes when its values do to avoid issues like
  // optimizely resetting things back to their defaults when using autoUpdate.
  // (I don't think the context ever actually does change - just the user does)
  const context = useMemo(() => {
    const client = createOptimizely(datafile, eventDispatcher, isMounted)
    client?.setUser({ id: sessionId, attributes }) // FYI these attributes are from the user agent request header for example
    Object.entries(overrides || {}).forEach(([key, value]) => {
      client?.setForcedVariation(key, sessionId, value)
    })
    return { sessionId, datafile, overrides, attributes, client }
  }, [datafile, eventDispatcher, isMounted, sessionId, attributes, overrides])

  useBufferedTracking(context.client)

  return isFlowpage ? (
    <>{children}</>
  ) : (
    <OptimizelyContext.Provider value={context}>
      <InnerOptimizelyProvider>{children}</InnerOptimizelyProvider>
    </OptimizelyContext.Provider>
  )
}
