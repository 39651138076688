import { SVGProps, memo } from 'react'

function MessageSvg({
  fill = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 18" fill="none" {...rest}>
      <mask id="prefix__message" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 0a5 5 0 00-5 5v6a5 5 0 005 5h9.4l5.6 2V5a5 5 0 00-5-5H5z"
        />
      </mask>
      <path
        d="M14.4 16l.505-1.413-.245-.087h-.26V16zm5.6 2l-.505 1.413 2.005.715V18H20zM1.5 5A3.5 3.5 0 015 1.5v-3A6.5 6.5 0 00-1.5 5h3zm0 6V5h-3v6h3zM5 14.5A3.5 3.5 0 011.5 11h-3A6.5 6.5 0 005 17.5v-3zm9.4 0H5v3h9.4v-3zm-.505 2.913l5.6 2 1.01-2.826-5.6-2-1.01 2.826zM21.5 18v-7h-3v7h3zm0-7v-.5h-3v.5h3zm-3-6v5.5h3V5h-3zM15 1.5A3.5 3.5 0 0118.5 5h3A6.5 6.5 0 0015-1.5v3zm-10 0h10v-3H5v3z"
        fill={color || fill}
        mask="url(#prefix__message)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 7.75a1 1 0 11-2 0 1 1 0 012 0zm3.571 0a1 1 0 11-2 0 1 1 0 012 0zm2.572 1a1 1 0 100-2 1 1 0 000 2z"
        fill={color || fill}
      />
    </svg>
  )
}

export const Message = memo(MessageSvg)

function Message2Svg({ fill = 'currentcolor', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={17}
      height={15}
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.929.167H5.07C2.557.167.5 2.327.5 4.967v3.36c0 2.64 2.057 4.8 4.571 4.8H12.614l.229.12 3.657 1.32v-9.6c0-2.64-2.057-4.8-4.571-4.8zM5.3 7.367c-.457 0-.914-.48-.914-.96s.457-.96.914-.96.914.48.914.96c0 .6-.343.96-.914.96zm3.314 0c-.457 0-.914-.48-.914-.96s.457-.96.914-.96.915.48.915.96c0 .6-.458.96-.915.96zm3.2 0c-.457 0-.914-.48-.914-.96s.457-.96.914-.96.915.48.915.96c0 .6-.343.96-.915.96z"
        fill={fill || '#111'}
      />
    </svg>
  )
}

export const Message2 = memo(Message2Svg)
