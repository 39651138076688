import { IthacaJwt } from '@dtx-company/ithaca-sdk/src'
import { MaterializedFeature } from '@dtx-company/connect-codegen/src/gen/plans/v1/plans_pb'
import { logger } from '@dtx-company/logger'
import plansClient from '../../connect-client/plansClient'

export async function initializeAtlasUsage(
  jwt: IthacaJwt
): Promise<{ [key: string]: MaterializedFeature } | void> {
  try {
    if (!jwt.org?.orgId) return
    const usage = await plansClient.listOrgProfile({
      orgId: jwt.org?.orgId
    })

    return usage.features.reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {})
  } catch (e) {
    logger.logError(e, { tag: 'atlas', team: 'platform-products' })
  }
}
