import { FC, ReactNode } from 'react'
import { Provider } from 'react-redux'
import { useInitializeReduxStore } from '../hooks/useInitializeReduxStore'

interface Props {
  children: ReactNode
}

export const ReduxProvider: FC<Props> = ({ children }) => {
  const [store] = useInitializeReduxStore()

  return <Provider store={store}>{children}</Provider>
}
//
