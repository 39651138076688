import { RootState } from '../types'
import { useSelector } from 'react-redux'

export function useIsEU(): boolean | undefined {
  return useSelector((state: RootState) => state.appReducer.currentSessionReducer.isEU)
}

export function useIsEULoaded(): boolean {
  return useSelector((state: RootState) => state.appReducer.currentSessionReducer.isEULoaded)
}

export function useIsUS(): boolean | undefined {
  return useSelector((state: RootState) => state.appReducer.currentSessionReducer.isUS)
}

export function useIsUSLoaded(): boolean {
  return useSelector((state: RootState) => state.appReducer.currentSessionReducer.isUSLoaded)
}
