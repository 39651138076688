export default {
  userCreatedAccount: 'userCreatedAccount',
  userCreatedFlowpageAccount: 'userCreatedFlowpageAccount',
  userOptedOutOfMarketingEmails: 'userOptedOutOfMarketingEmails',
  Revenue_AccountCommunication_Toggled_AccountEmailsPreference:
    'Revenue_AccountCommunication_Toggled_AccountEmailsPreference',
  Revenue_AccountCommunication_Clicked_SaveEmailPreferences:
    'Revenue_AccountCommunication_Clicked_SaveEmailPreferences',
  Account_UpdatePassword_Focused_PasswordInput: 'Account_UpdatePassword_Focused_PasswordInput',
  Account_UpdatePassword_Toggled_EyeIcon: 'Account_UpdatePassword_Toggled_EyeIcon',
  Account_UpdatePassword_Successfully_Updated_Password:
    'Account_UpdatePassword_Successfully_Updated_Password'
}
