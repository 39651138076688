/*
 * Constants pertaining to our help docs / site and generally how users can reach out for help.
 */
export const HELP_URL = 'https://intercom.help/flowcode/en/'
export const HELP_AGE_GATE_URL =
  'https://intercom.help/flowcode/en/articles/6927446-how-to-add-an-age-gate-to-your-flowpage'
export const HELP_ANALYTICS_DASHBOARD_URL =
  'https://intercom.help/flowcode/en/articles/6927481-navigating-your-analytics-dashboard'
export const HELP_ANALYTICS_URL =
  'https://intercom.help/flowcode/en/articles/7061584-flowcode-analytics'
export const HELP_BEST_PRACTICES_FLOWCODE_IMPLEMENTATION_URL =
  'https://intercom.help/flowcode/en/articles/7061600-best-practices-general-flowcode-implementation'
export const HELP_BEST_PRACTICES_TIPS_TRICKS_URL =
  'https://intercom.help/flowcode/en/articles/6927494-best-practices-tips-tricks-and-need-to-knows'
export const HELP_BULK_FLOWCODES_URL =
  'https://intercom.help/flowcode/en/articles/6927438-how-to-create-flowcodes-in-bulk-step-by-step-guide'
export const HELP_CNAME_RECORD_URL =
  'https://intercom.help/flowcode/en/articles/6927426-how-do-i-create-a-cname-record-in-dns-with-my-hosting-provider'
export const HELP_CREATE_FLOWPAGE_URL =
  'https://intercom.help/flowcode/en/articles/7061616-creating-a-flowpage'
export const HELP_CUSTOMIZE_FLOWCODE_DESIGN_URL =
  'https://intercom.help/flowcode/en/articles/6927429-how-to-customize-your-flowcode-s-design'
export const HELP_ENTERPRISE_URL =
  'https://intercom.help/flowcode/en/articles/7061692-table-of-contents'
export const HELP_FACEBOOK_PIXEL_URL =
  'https://intercom.help/flowcode/en/articles/6927447-how-to-use-the-facebook-pixel-widget'
export const HELP_FLOWPAGE_URL =
  'https://intercom.help/flowcode/en/articles/6927443-how-to-set-up-your-flowpage'
export const HELP_MARKETING_MATERIALS_URL =
  'https://intercom.help/flowcode/en/articles/6927495-creating-marketing-materials-with-a-flowcode-step-by-step-guide'
export const HELP_SCAN_DESTINATION_URL =
  'https://intercom.help/flowcode/en/articles/6927428-how-do-i-update-where-my-flowcode-scans-to'
export const HELP_SHARE_FLOWPAGE_URL =
  'https://intercom.help/flowcode/en/articles/6927412-where-should-i-share-my-flowpage'
export const HELP_SMART_RULES_URL =
  'https://intercom.help/flowcode/en/articles/6927435-what-are-smart-rules'
export const HELP_TEAMS_URL = 'https://intercom.help/flowcode/en/articles/7061649-teams-faq'
export const HELP_WHITE_LABEL_URL =
  'https://intercom.help/flowcode/en/articles/6927425-white-label-overview-faq'
export const WHITE_LABEL_OVERVIEW_FAQS_URL =
  'https://help.flowcode.com/en/collections/3928895-domains'
