import {
  CheckCircle as CheckCircleIcon,
  Info as InfoIcon,
  Report as ReportIcon,
  Warning as WarningIcon
} from '@mui/icons-material'
import { Components, Theme } from '@mui/material'
import { alertClasses } from '@mui/material/Alert'
import { alertTitleClasses } from '@mui/material/AlertTitle'
import { buttonClasses } from '@mui/material/Button'
import { svgIconClasses } from '@mui/material/SvgIcon'

/**
 * MUI Theme overrides for Flowcode's [Alert](./Alert.tsx) component.
 */
export const alertComponentOverrides: Components<Theme> = {
  MuiAlert: {
    defaultProps: {
      iconAlignment: 'center',
      iconMapping: {
        success: <CheckCircleIcon />,
        warning: <WarningIcon />,
        error: <ReportIcon />,
        info: <InfoIcon />
      }
    },

    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        color: theme.palette.text.primary,
        borderLeftWidth: 4,
        borderLeftStyle: 'solid',
        padding: theme.spacing(2, 5),

        [`.${alertClasses.icon}`]: {
          ...(ownerState.iconAlignment === 'center' && { alignItems: 'center' }),
          // Always align the icon to the top on screens `sm` and lower
          [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start'
          },
          marginRight: theme.spacing(4)
        },

        // Standard variant customizations
        [`&&.${alertClasses.standard}`]: {
          [`.${alertClasses.icon}`]: {
            color: theme.palette.text.primary
          }
        },
        [`&&.${alertClasses.standardSuccess}`]: {
          borderLeftColor: theme.palette.success.main,
          backgroundColor: theme.palette.container.success
        },
        [`&&.${alertClasses.standardWarning}`]: {
          borderLeftColor: theme.palette.warning.main,
          backgroundColor: theme.palette.container.warning
        },
        [`&&.${alertClasses.standardError}`]: {
          borderLeftColor: theme.palette.error.main,
          backgroundColor: theme.palette.container.error
        },
        [`&&.${alertClasses.standardInfo}`]: {
          borderLeftColor: theme.palette.info.main,
          backgroundColor: theme.palette.container.info
        },

        // Outlined variant customizations
        [`&&.${alertClasses.outlined}`]: {
          borderColor: theme.palette.border.outline
        },
        [`&&.${alertClasses.outlinedSuccess}`]: {
          borderLeftColor: theme.palette.success.main
        },
        [`&&.${alertClasses.outlinedWarning}`]: {
          borderLeftColor: theme.palette.warning.main
        },
        [`&&.${alertClasses.outlinedError}`]: {
          borderLeftColor: theme.palette.error.main
        },
        [`&&.${alertClasses.outlinedInfo}`]: {
          borderLeftColor: theme.palette.info.main
        },

        // Customizations for screens `sm` and lower
        [theme.breakpoints.down('sm')]: {
          // Move the action button below the title and description
          flexWrap: 'wrap',

          [`.${alertTitleClasses.root}`]: {
            ...theme.typography.labelMedium,
            marginBottom: theme.spacing(1)
          },
          [`.${alertClasses.message}`]: {
            ...theme.typography.captionMedium,
            ...(ownerState.action && { paddingBottom: 0 })
          },
          [`.${alertClasses.action}`]: {
            // If no action is supplied, but there is a close icon, keep the close icon aligned to the right
            ...(ownerState.action && {
              width: '100%',
              // Nudge the button text a little bit to the right so it's aligned with the text above it
              [`.${buttonClasses.root}`]: { paddingLeft: theme.spacing(4) }
            }),
            [`.${buttonClasses.text}`]: theme.typography.labelMedium
          }
        },

        // Customizations for screens `md` and higher
        [theme.breakpoints.up('md')]: {
          [`.${alertTitleClasses.root}`]: theme.typography.subtitleSmall,
          [`.${alertClasses.message}`]: theme.typography.bodySmall
        },

        [`.${alertClasses.action}`]: {
          alignItems: 'center',
          paddingTop: 0,

          [`.${svgIconClasses.root}`]: {
            height: theme.spacing(6),
            width: theme.spacing(6)
          }
        }
      })
    }
  }
}
