import { FC } from 'react'
import { HeapScript } from '../../../components/head/scripts/heap'
import { ImpactUttHeadScript } from './Impact'
import {
  NBA_TAG_CONTAINER_URL,
  NBA_USER_CONSENT_URL,
  NBA_WIDGET_URL
} from '@app/page/src/components/widgets/__NBAIdGate/NBAIdWidget.constants'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { SnowplowLoadTest } from '../../SnowplowTest'

export const AppHeadContents: FC<{
  pathname: string
  page?: { links: { type: string; actionData?: { link?: string } }[] }
}> = ({ pathname, page }) => {
  const isFlowpage = pathname === Routes.FLOWPAGE
  const nbaIdWidgetGate = page?.links?.find(link => link.type === 'nbaIdGate')

  return (
    <>
      {isFlowpage ? (
        <>
          {/* Add checks for additional 3rd party gate widget scripts here */}
          {nbaIdWidgetGate && (
            <>
              {/* eslint-disable-next-line @next/next/no-sync-scripts */}
              <script // NBA "User Consent" Script
                src={NBA_USER_CONSENT_URL}
              />
              <script src={NBA_TAG_CONTAINER_URL} async />
              <script // NBA ID Widget
                data-testid="nba-id-widget"
                async
                src={nbaIdWidgetGate?.actionData?.link || NBA_WIDGET_URL} // If Old NBA ID Widget Pages exist, but don't have their own link, default to old source
              />
            </>
          )}
        </>
      ) : (
        <>
          <SnowplowLoadTest />
          <HeapScript />
          <ImpactUttHeadScript />
        </>
      )}
    </>
  )
}
