import { ErasedData } from './fc_grid.types'
import { FcColor, FcColorType, FcRgb, FcSize } from './fc_common.types'
const colorArgs = ['stroke', 'fill', 'stop-color']
const colorOpacArgs = ['stroke-opacity', 'fill-opacity', 'stop-opacity']

export interface ColorAttribs {
  color: FcColor
  cmyk?: string
  opacity?: string
  opacityHex?: string
}

export function fcBuildSvgColor(
  color: FcColor | undefined,
  colorType: FcColorType,
  useCmyk: boolean
): string {
  if (!color) {
    return ''
  }
  const attribs = fcBuildSvgColorAttribs(useCmyk, color)
  const kw = colorArgs[colorType]
  const cmyk = attribs.cmyk ? ` device-cmyk(${attribs.cmyk})` : ''
  if (attribs.opacity) {
    const opacKw = colorOpacArgs[colorType]
    return `${kw}="${attribs.color}${cmyk}" ${opacKw}="${attribs.opacity}"`
  }
  return `${kw}="${attribs.color}${cmyk}"`
}

export function fcBuildSvgColorForImageFill(color: FcColor, useCmyk: boolean): string {
  const attribs = fcBuildSvgColorAttribs(useCmyk, color)
  return fcBuildColorStringFromAttribs(attribs)
}

export function fcBuildColorStringFromAttribs(attribs: ColorAttribs): string {
  const cmyk = attribs.cmyk ? ` device-cmyk(${attribs.cmyk})` : ''
  const opacityHex = attribs.opacityHex || ''
  return `${attribs.color}${opacityHex}${cmyk}`
}

function getCMKYPercentage(val: string): string {
  return (parseInt(val.trim()) / 100).toString()
}

export function fcGetColorLabel(color: string): string {
  const colorParts = color.split(':')
  if (colorParts.length > 1) {
    color = colorParts[0]
  }
  return color.replace(/[#()]/g, '')
}

function luminance(color: FcRgb): number {
  const a = [color.r, color.g, color.b].map(function (v) {
    v /= 255
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  })
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

export function fcColorDifferenceFromValues(color1: number, color2: number): number {
  return fcColorDifference(fcValueToRgb(color1), fcValueToRgb(color2))
}

export function fcColorDifference(color1: FcRgb, color2: FcRgb): number {
  const lum1 = luminance(color1)
  const lum2 = luminance(color2)
  if (lum1 === lum2) {
    return 0
  }
  const brightest = Math.max(lum1, lum2)
  const darkest = Math.min(lum1, lum2)
  const diff = (brightest + 0.05) / (darkest + 0.05)
  return diff
}

export function fcValueToRgb(color: number): FcRgb {
  return { r: (color >> 16) & 0xff, g: (color >> 8) & 0xff, b: color & 0xff }
}

export function fcSvgColorToNumber(color?: FcColor): FcRgb {
  if (!color) {
    return { r: 0, g: 0, b: 0 }
  }
  if (color.startsWith('#')) {
    color = color.substr(1)
  }
  if (color.length == 3) {
    return {
      r: parseInt(color[0] + color[0], 16),
      g: parseInt(color[1] + color[1], 16),
      b: parseInt(color[2] + color[2], 16)
    }
  }

  if (color.length != 6 && color.length != 8) {
    throw new Error('Invalid color length')
  }
  return {
    r: parseInt(color.substr(0, 2), 16),
    g: parseInt(color.substr(2, 2), 16),
    b: parseInt(color.substr(4, 2), 16)
  }
}

export function fcBuildSvgColorAttribs(useCmyk: boolean, color?: FcColor): ColorAttribs {
  if (!color) {
    return { color: '' }
  }

  let cmyk: string | undefined = undefined
  const colorParts = color.split(':')
  if (colorParts.length > 1) {
    color = colorParts[0]
    if (useCmyk) {
      const cmykStr = colorParts[1]
      const cmykParts = cmykStr.substring(1, cmykStr.length - 1).split(' ')
      if (cmykParts.length != 4) {
        throw new Error(`cmyk string ${cmykStr} is invalid`)
      }
      const cmykC = getCMKYPercentage(cmykParts[0])
      const cmykM = getCMKYPercentage(cmykParts[1])
      const cmykY = getCMKYPercentage(cmykParts[2])
      const cmykK = getCMKYPercentage(cmykParts[3])
      cmyk = `${cmykC} ${cmykM} ${cmykY} ${cmykK}`
    }
  }
  if (color.startsWith('#')) {
    if (color.length === 4 || color.length === 6) {
      const r = color[1]
      const g = color[2]
      const b = color[3]
      let alpha = ''
      if (color.length === 6) {
        alpha = color.substring(4)
      }
      color = `#${r}${r}${g}${g}${b}${b}${alpha}`
    }
    if (color.length === 9) {
      const opacityHex = color.substr(7, 2)
      const opacity = Math.round((parseInt(opacityHex, 16) / 255) * 100) / 100
      if (isNaN(opacity)) {
        throw new Error('invalid color ' + color)
      }
      color = color.substr(0, 7)
      return { color, opacity: opacity.toString(), opacityHex: opacityHex, cmyk }
    }
  }
  return { color, cmyk }
}

export interface SvgFields {
  erasedData?: ErasedData
  width: FcSize
  height: FcSize
  x: number
  y: number
  viewBox?: string
  dom?: string
  overlay?: string
  child?: boolean
  hasClip?: boolean
  useCmyk?: boolean
}

export class FcSvg implements SvgFields {
  erasedData?: ErasedData
  width: FcSize
  height: FcSize
  x: number
  y: number
  viewBox = ''
  dom = ''
  overlay = ''
  child = false
  hasClip = false
  useCmyk = false
  centerTextClipped = false
  centerTextWidthMax = 0
  logoNewWidth = 0
  logoNewHeight = 0

  constructor(
    x: number,
    y: number,
    width: FcSize,
    height: FcSize,
    useCmyk?: boolean,
    backgroundColor?: string,
    backgroundColorPadding?: number
  ) {
    this.x = x
    this.y = y
    this.width = width
    this.height = height
    this.useCmyk = useCmyk || false
    if (backgroundColor != undefined) {
      if (!backgroundColorPadding) {
        this.addToDom(
          `<rect width="100%" height="100%" ${fcBuildSvgColor(
            backgroundColor,
            FcColorType.FILL,
            this.useCmyk
          )} shape-rendering="crispEdges"/>`
        )
      } else {
        this.addToDom(
          `<rect x="${backgroundColorPadding}" y="${backgroundColorPadding}" width="${
            width - backgroundColorPadding * 2
          } " height="${height - backgroundColorPadding * 2}" ${fcBuildSvgColor(
            backgroundColor,
            FcColorType.FILL,
            this.useCmyk
          )} shape-rendering="crispEdges"/>`
        )
      }
    }
  }

  static fromJson(svg: SvgFields): FcSvg {
    // placeholder implementation of a copy constructor
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    svg.__proto__ = FcSvg.prototype
    return svg as FcSvg
  }

  static toJson(svg: FcSvg): string {
    return JSON.stringify(svg)
  }

  getCenterTextClipped(): boolean {
    return this.centerTextClipped
  }

  getUseCmyk(): boolean {
    return this.useCmyk
  }

  getPercentDataErased(): number {
    return this.erasedData ? this.erasedData.percentErased : 0
  }

  getErasedData(): ErasedData | undefined {
    return this.erasedData
  }

  addToDom(str: string): void {
    this.dom += str
  }

  addOverlay(str: string): void {
    this.overlay += str
  }

  getParent(noXmlns?: boolean): string {
    let viewBox = ''
    if (this.viewBox) {
      viewBox = ` viewBox="${this.viewBox}"`
    }
    let xmlns = ''
    if (!noXmlns) {
      xmlns =
        'xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" '
    }
    return `<svg ${xmlns}x="${this.x}" y="${this.y}" width="${this.width}" height="${this.height}"${viewBox}>`
  }

  setClip(clipPath: string): void {
    this.dom = `<g clip-path="url(#${clipPath}clipPath)">` + this.dom
    this.hasClip = true
  }

  endClip(): void {
    if (this.hasClip) {
      this.addToDom('</g>')
    }
  }

  getOverlay(): string {
    return this.overlay
  }

  setOverlay(): void {
    if (this.overlay !== '') {
      this.addToDom(this.overlay)
      this.overlay = ''
    }
  }

  setViewBox(viewBox: string): void {
    this.viewBox = viewBox
  }

  getDom(noXmlns?: boolean): string {
    let dom = ''
    if (!this.child) {
      dom += this.getParent(noXmlns)
    } else {
      dom += `<svg x="${this.x}" y="${this.y}" width="${this.width}" height="${this.height}">`
    }
    dom += this.dom
    dom += `</svg>`
    return dom
  }

  addSvg(svg: FcSvg): void {
    this.addToDom(svg.getDom(true))
    svg.child = true
  }
}
