import { ANONYMOUS_SESSION_ID } from '@dtx-company/true-common/src/constants/sessionId'
import {
  FLOWCODE_API_ENDPOINT,
  FLOWPAGE_API_ENDPOINT
} from '@dtx-company/true-common/src/constants/endpoints'
import { GraphQLClient } from 'graphql-request'
import { Variables } from 'graphql-request/dist/types'
import { fetchWithAgent } from './fetch-agent'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const flowpageApiUrl = FLOWPAGE_API_ENDPOINT

export const gqlFetcher = async <T, V = Variables>(
  query: string,
  variables?: V,
  flowpage = false,
  token: string | undefined = '',
  tags?: Record<string, unknown>
): Promise<T> => {
  const uri = flowpage ? flowpageApiUrl : FLOWCODE_API_ENDPOINT
  const anonymousSessionId = cookies.get(ANONYMOUS_SESSION_ID)

  const client = createGraphQLClient({
    url: `${uri}/graphql`,
    anonymousSessionId,
    token,
    tags
  })
  return client.request(query, variables)
}

export const pageGqlFetcher = async <T, V = Variables>(
  query: string,
  variables?: V,
  token = '',
  tags?: Record<string, unknown>
): Promise<T> => gqlFetcher(query, variables, true, token, tags)

export const printStoreGqlFetcher = async <T, V = Variables>({
  query,
  token,
  variables
}: {
  query: string
  variables?: V
  token?: string
}): Promise<T> => {
  const url = `${FLOWCODE_API_ENDPOINT}/design-templates/v1/graphql`
  const anonymousSessionId = cookies.get(ANONYMOUS_SESSION_ID)

  const client = createGraphQLClient({
    url,
    anonymousSessionId,
    token
  })
  return client.request(query, variables)
}

function createGraphQLClient({
  url,
  token,
  anonymousSessionId,
  tags
}: {
  url: string
  token?: string
  anonymousSessionId?: string
  tags?: Record<string, unknown>
}): GraphQLClient {
  return new GraphQLClient(url, {
    credentials: 'include',
    headers: {
      ...(!!token && { authorization: `Bearer ${token}` }),
      ...(!!anonymousSessionId && { AnonymousSessionId: anonymousSessionId }),
      ...(tags && { TRACER: Buffer.from(JSON.stringify(tags), 'utf8').toString('base64') })
    },
    fetch: fetchWithAgent
  })
}
