import { Components, Theme } from '@mui/material'

/**
 * MUI Theme overrides for Flowcode's [Snackbar](./Snackbar.tsx) component.
 */
export const dividerComponentOverrides: Components<Theme> = {
  MuiDivider: {
    styleOverrides: {
      wrapper: ({ theme }) => ({
        paddingLeft: `calc(${theme.spacing(2)} * 1.2)`,
        paddingRight: `calc(${theme.spacing(2)} * 1.2)`
      }),

      wrapperVertical: ({ theme }) => ({
        paddingTop: `calc(${theme.spacing(2)} * 1.2)`,
        paddingBottom: `calc(${theme.spacing(2)} * 1.2)`
      })
    }
  }
}
