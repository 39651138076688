import { FRONTEGG_AUTH_HEADER, FRONTEGG_REFRESH_ENDPOINT } from './constants'
import { FronteggAuthResponse } from './types'
import { logger } from '@dtx-company/logger'
import { notifyObserversOfRefresh } from './notify'
import { setAccessTokenCookie } from './setAccessTokenCookie'

export async function refreshFronteggTokens(
  shouldNotify = true
): Promise<{ accessToken: string | null }> {
  try {
    //refresh token is sent as a cookie
    const refreshRes = await fetch(FRONTEGG_REFRESH_ENDPOINT, {
      method: 'POST',
      credentials: 'include',
      headers: FRONTEGG_AUTH_HEADER
    })
    if (!refreshRes.ok) {
      await logger.logFetchError(refreshRes, {
        technicalArea: 'authentication',
        url: 'refresh-frontegg-tokens',
        status: refreshRes.status
      })

      // if the refresh token update fails, try to refresh it one more time. The fail might be related to a
      // raise condition between subscription service and frontegg update
      await fetch(FRONTEGG_REFRESH_ENDPOINT, {
        method: 'POST',
        credentials: 'include',
        headers: FRONTEGG_AUTH_HEADER
      })
    }
    if (refreshRes?.ok && refreshRes.headers.get('content-type')?.includes('application/json')) {
      const { accessToken } = (await refreshRes.json()) as FronteggAuthResponse
      const { success } = await setAccessTokenCookie(accessToken)
      if (!success) {
        return { accessToken: null }
      }
      if (shouldNotify) {
        //shouldNotify is set to false when we want to refresh a token without initializing user state in redux
        notifyObserversOfRefresh(accessToken)
      }
      return { accessToken }
    }
  } catch (e: unknown) {
    logger.logError(e, { technicalArea: 'authentication' })
  }
  return { accessToken: null }
}
