import { PaletteOptions } from '@mui/material/styles'

const filledHoverShade = 'rgba(255, 255, 255, 0.16)'
const filledFocusShade = 'rgba(255, 255, 255, 0.24)'

export const baseRGB = {
  primary: [36, 36, 36],
  secondary: [126, 104, 78],
  tertiary: [35, 135, 83],
  error: [186, 26, 26],
  shades: {
    primary: [126, 104, 78],
    primaryFilled: [226, 205, 182],
    tertiary: [0, 109, 61]
  }
}

const hexToRGBValues = (hex: string): number[] => {
  const hexString = hex.replace('#', '')
  const r = parseInt(hexString.substring(0, 2), 16)
  const g = parseInt(hexString.substring(2, 4), 16)
  const b = parseInt(hexString.substring(4, 6), 16)
  return [r, g, b]
}

export const withAlpha = (rgb: number[], alpha: number): string =>
  `rgba(${rgb.join(', ')}, ${alpha})`

const baseColors = {
  primary: {
    light: '#41403F',
    main: '#242424',
    dark: '#000000',
    contrastText: '#FFFFFF'
  },
  secondary: {
    light: withAlpha(baseRGB.secondary, 0.08),
    main: withAlpha(baseRGB.secondary, 0.16),
    dark: withAlpha(baseRGB.secondary, 0.32),
    contrastText: '#242424'
  },
  tertiary: {
    light: '#43A26A',
    main: '#238753',
    dark: '#006D3D',
    contrastText: '#FFFFFF'
  },
  error: {
    light: '#DE3730',
    main: '#BA1A1A',
    dark: '#93000A',
    contrastText: '#FFFFFF'
  },
  info: {
    light: '#5B73C4',
    main: '#415AA9',
    dark: '#274190',
    contrastText: '#FFFFFF'
  },
  warning: {
    light: '#F19547',
    main: '#D27B30',
    dark: '#B26317',
    contrastText: '#242424'
  },
  upgrade: {
    main: '#F8BB68',
    light: 'rgb(249, 200, 134)',
    dark: 'rgb(173, 130, 72)',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  success: {
    light: '#43A26A',
    main: '#238753',
    dark: '#006D3D',
    contrastText: '#FFFFFF'
  }
}

export const lightPalette: PaletteOptions = {
  mode: 'light',
  ...baseColors,
  text: {
    primary: '#242424',
    secondary: '#696763',
    disabled: withAlpha(baseRGB.primary, 0.38)
  },
  divider: withAlpha(baseRGB.secondary, 0.24),
  background: {
    paper: '#FFFFFF',
    default: '#FFFFFF'
  },
  action: {
    active: withAlpha(baseRGB.primary, 0.54),
    hover: withAlpha(baseRGB.primary, 0.08),
    selected: withAlpha(baseRGB.primary, 0.12),
    disabled: withAlpha(baseRGB.primary, 0.12),
    disabledBackground: withAlpha(baseRGB.primary, 0.38),
    focus: withAlpha(baseRGB.primary, 0.16),
    tooltip: 'rgba(31, 31, 31, 0.9)'
  },
  shades: {
    primary: {
      hover: withAlpha(baseRGB.secondary, 0.08),
      focus: withAlpha(baseRGB.secondary, 0.16),
      selected: withAlpha(baseRGB.secondary, 0.16),
      active: withAlpha(baseRGB.secondary, 0.32),
      filledHover: `linear-gradient(0deg, ${withAlpha(
        baseRGB.shades.primaryFilled,
        0.16
      )}, ${withAlpha(baseRGB.shades.primaryFilled, 0.16)}), ${withAlpha(baseRGB.primary, 1)}`,
      filledFocus: `linear-gradient(0deg, ${withAlpha(
        baseRGB.shades.primaryFilled,
        0.24
      )}, ${withAlpha(baseRGB.shades.primaryFilled, 0.24)}), ${withAlpha(baseRGB.primary, 1)}`
    },
    secondary: {
      filledHover: `linear-gradient(0deg, ${withAlpha(baseRGB.primary, 0.08)}, ${withAlpha(
        baseRGB.primary,
        0.08
      )}), ${withAlpha(baseRGB.secondary, 0.16)}`,
      filledFocus: `linear-gradient(0deg, ${withAlpha(baseRGB.primary, 0.16)}, ${withAlpha(
        baseRGB.primary,
        0.16
      )}), ${withAlpha(baseRGB.secondary, 0.16)}`
    },
    tertiary: {
      hover: withAlpha(baseRGB.shades.tertiary, 0.08),
      focus: withAlpha(baseRGB.shades.tertiary, 0.16),
      filledHover: `linear-gradient(0deg, ${filledHoverShade}, ${filledHoverShade}), ${withAlpha(
        baseRGB.tertiary,
        1
      )}`,
      filledFocus: `linear-gradient(0deg, ${filledFocusShade}, ${filledFocusShade}), ${withAlpha(
        baseRGB.tertiary,
        1
      )}`,
      filledDisabled: `linear-gradient(0deg, ${withAlpha(baseRGB.tertiary, 0.38)}, ${withAlpha(
        baseRGB.tertiary,
        0.38
      )}), #FFFFFF`
    },
    error: {
      hover: withAlpha(baseRGB.error, 0.08),
      focus: withAlpha(baseRGB.error, 0.16),
      filledHover: `linear-gradient(0deg, ${filledHoverShade}, ${filledHoverShade}), ${withAlpha(
        baseRGB.error,
        1
      )}`,
      filledFocus: `linear-gradient(0deg, ${filledFocusShade}, ${filledFocusShade}), ${withAlpha(
        baseRGB.error,
        1
      )}`
    },
    upgrade: {
      hover: baseColors.warning.main,
      filledFocus: `linear-gradient(0deg, ${filledFocusShade}, ${filledFocusShade}), ${withAlpha(
        hexToRGBValues(baseColors.upgrade.main),
        1
      )}`
    }
  },
  surface: {
    0: '#FFFFFF',
    1: '#F9F7F6',
    2: '#F5F3F1',
    3: '#F2EEEC',
    4: '#ECEAE6',
    5: '#E9E5E1',
    inverse: '#343332',
    disabled: withAlpha(baseRGB.primary, 0.12),
    tintLight: '#7E684E',
    tintDark: '#E2CDB6'
  },
  onSurface: {
    primary: '#242424',
    secondary: '#696763',
    disabled: withAlpha(baseRGB.primary, 0.38),
    inverse: '#FFFFFF',
    inverseSecondary: '#C5C3BE',
    inverseTertiary: '#7BDA9D'
  },
  border: {
    outline: '#B7B4AE',
    divider: withAlpha(baseRGB.secondary, 0.24),
    disabled: withAlpha(baseRGB.primary, 0.12)
  },
  utility: {
    scrim: withAlpha(baseRGB.primary, 0.32),
    tooltip: withAlpha(baseRGB.primary, 0.9),
    upgrade: baseColors.upgrade.main
  },
  container: {
    error: '#FFDAD6',
    warning: '#FFF1CC',
    info: '#D8E2FF',
    success: '#CEF3D8'
  }
}
