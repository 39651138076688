import { AtlasContext, AtlasProviderProps } from './context'
import { FC } from 'react'
import { initializeAtlasUsage } from '@dtx-company/inter-app/src/utils/atlas/initAtlasUsage'
import { useAtlasFeatureFlag } from '../../hooks/atlas/useAtlasFeatureFlag'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import useSWR from 'swr'

export const AtlasProvider: FC<AtlasProviderProps> = ({ children }): JSX.Element => {
  const { jwt } = useAuthState()
  const atlasEnabled = useAtlasFeatureFlag()

  const { data } = useSWR(
    () => (atlasEnabled && jwt ? ['atlas_provider', jwt] : undefined),
    async ([_key, jwt]) => {
      const atlasData = await initializeAtlasUsage(jwt)
      return atlasData
    },
    {
      revalidateOnFocus: false
    }
  )

  const usage = data || {}

  return (
    <AtlasContext.Provider value={{ usage }}>
      <>{children}</>
    </AtlasContext.Provider>
  )
}
