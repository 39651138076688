import { Dispatch, SetStateAction } from 'react'
import _useSessionStorageState from 'use-session-storage-state'

export type UseSessionStorageStateOptions<T> = {
  key: string
  defaultValue?: T
}

export type UseSessionStorageStateReturn<T> = [T, Dispatch<SetStateAction<T>>]

/**
 * Syncs stored values into `sessionStorage` (if available).
 *
 * If multiple places use this hook with the same key, setting the value in one
 * place will updated it in all places.
 *
 * Session storage lives for the life of the current tab.
 *
 * If sessionStorage is blocked, out of memory, or otherwise fails then
 * this will fall back to local memory.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
 */
export function useSessionStorageState<T>(
  options: UseSessionStorageStateOptions<T>
): UseSessionStorageStateReturn<T>
export function useSessionStorageState<T>(
  key: string,
  defaultValue?: T
): UseSessionStorageStateReturn<T>
export function useSessionStorageState<T>(
  keyOrOptions: string | UseSessionStorageStateOptions<T>,
  defaultValueOrNothing?: T
): UseSessionStorageStateReturn<T> {
  const isOptions = typeof keyOrOptions === 'object'

  const [value, setValue] = _useSessionStorageState<T>(
    isOptions ? keyOrOptions.key : keyOrOptions,
    {
      defaultValue: isOptions ? keyOrOptions.defaultValue : defaultValueOrNothing,
      // NOTE: the underlying lib supports syncing, but per the docs it shouldn't work
      // and testing doesn't seem to work - maybe some browsers allow it - but disabling
      // it for our abstraction for consistency and clarity
      storageSync: false
    }
  )

  return [value, setValue]
}
