import { ReactNode } from 'react'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { IntercomProvider as _IntercomProvider } from 'react-use-intercom'
import { useFlowRouter } from '@dtx-company/inter-app/src/hooks/useFlowRouter'

interface IntercomProviderProps {
  children: ReactNode
}

export function IntercomProvider(props: IntercomProviderProps): JSX.Element {
  const router = useFlowRouter()
  const isFlowpage = router.pathname === Routes.FLOWPAGE
  const isAuthPages = router.pathname === Routes.SIGN_IN || router.pathname === Routes.SIGN_UP

  return (
    <_IntercomProvider
      appId={process.env.INTERCOM_APP_ID}
      shouldInitialize={!isFlowpage && !isAuthPages}
      {...props}
    />
  )
}
//
