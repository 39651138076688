/**
 * Get the URL for a service based on the environment. This follows the same
 * pattern across all services. Defaults to production if no environment is
 * specified.
 *
 * @param serviceName Name of the service
 * @returns
 */
const getDashedGenericServiceUrl = (serviceName: string): string => {
  switch (process.env.APP_ENV) {
    case 'development':
    case 'staging':
      return `https://stg-${serviceName}.flowcode.com`
    case 'preprod':
      return `https://preprod-${serviceName}.flowcode.com`
    case 'production':
    default:
      return `https://${serviceName}.flowcode.com`
  }
}

export const getApiRootUrl = (): string => getDashedGenericServiceUrl('pageapi')

export const getFormsApiRootUrl = (): string => getDashedGenericServiceUrl('forms')

export const getFlowcodeGeneratorRootUrl = (): string => getDashedGenericServiceUrl('generator')

export const getCaptureServerRootUrl = (): string => getDashedGenericServiceUrl('capture')

export const getNotificationsRootUrl = (): string => getDashedGenericServiceUrl('notifications')

export const getLeatherwoodRootUrl = (): string => getDashedGenericServiceUrl('leatherwood')

export const getSubscriptionServiceRootUrl = (): string =>
  getDashedGenericServiceUrl('subscription')

export const getIthacaRootUrl = (): string => getDashedGenericServiceUrl('ithaca')

export const getVcardApiRootUrl = (): string => getDashedGenericServiceUrl('vcard')

export const getBrandsApiRootUrl = (): string => getDashedGenericServiceUrl('brands')

export const getAnalyticsCollectorRootUrl = (): string =>
  getDashedGenericServiceUrl('analytics-collector')

export const getDottedGenericServiceUrl = (serviceName: string): string => {
  switch (process.env.APP_ENV) {
    case 'development':
    case 'staging':
      return `https://${serviceName}.stg.flowcode.com`
    case 'preprod':
      return `https://${serviceName}.preprod.flowcode.com`
    case 'production':
    default:
      return `https://${serviceName}.flowcode.com`
  }
}

export const getRegistryRootUrl = (): string => getDottedGenericServiceUrl('registry')

export const getPagesV2RootUrl = (): string => getDottedGenericServiceUrl('pages')

export const getStaticAssetsRootUrl = (): string => getDottedGenericServiceUrl('static-assets')

export const getCommerceWorkerUrl = (): string => {
  switch (process.env.APP_ENV) {
    case 'development':
    case 'staging':
      return 'https://commerce-worker-staging.dtx.workers.dev'
    case 'preprod':
      return 'https://commerce-worker-preprod.dtx.workers.dev'
    case 'production':
    default:
      return 'https://commerce-worker.dtx.workers.dev'
  }
}

export const getAutoBuilderRootUrl = (): string => {
  switch (process.env.APP_ENV) {
    case 'development':
    case 'staging':
    case 'preprod':
      return 'https://app.stg.flowcode.com/v1/ml-config-generator'
    case 'production':
    default:
      return 'https://app.flowcode.com/v1/ml-config-generator'
  }
}
