import { BoxComponentProps, BoxProps } from '../../../types/atoms/box.types'
import { ForwardRefExoticComponent, forwardRef } from 'react'
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  system,
  typography
} from 'styled-system'
import { transition } from '../../../configs'
import styled from 'styled-components'

const filter = system({
  filter: {
    property: 'filter'
  }
})
const visibility = system({
  visibility: {
    property: 'visibility'
  }
})

const BoxBase = styled.div<BoxProps>`
  display: flex;
  box-sizing: border-box;
  cursor: ${(props: BoxProps) => (props.cursor ? props.cursor : 'inherit')};
  ${(props: BoxProps) =>
    (props.rotation || props.rotation === 0) &&
    `transform: rotate(${props.rotation}deg); transition: all 0.2s linear; display: inline-block;`}
  ${(props: BoxProps) =>
    props.collapsible &&
    `display: block;
     overflow-y: hidden;
     transition: all 0.2s ease-in-out;
     height: 100%;
     position: relative;
     max-height: ${props.collapsible.open ? `${props.collapsible.maxHeight}px` : '0px'};
  `}
  ${space}
  ${layout}
  ${flexbox}
  ${position}
  ${color}
  ${shadow}
  ${border}
  ${background}
  ${transition}
  ${grid}
  ${filter}
  ${visibility}
  ${typography}
`
const Box: ForwardRefExoticComponent<BoxComponentProps> = forwardRef<HTMLDivElement, BoxProps>(
  (props: BoxProps, ref) => {
    const { children, ...rest } = props
    return (
      <BoxBase ref={ref} {...rest}>
        {children}
      </BoxBase>
    )
  }
)

Box.displayName = 'Box'

const CenterBox: ForwardRefExoticComponent<BoxComponentProps> = forwardRef<
  HTMLDivElement,
  BoxProps
>((props: BoxProps, ref) => {
  const { children, ...rest } = props
  return (
    <BoxBase ref={ref} {...rest} alignItems="center" justifyContent="center">
      {children}
    </BoxBase>
  )
})

CenterBox.displayName = 'CenterBox'

export { Box, CenterBox }
