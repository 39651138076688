// @generated by protoc-gen-connect-es v0.8.6 with parameter "target=ts,import_extension="
// @generated from file pages/v2/page.proto (package pages.v2, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  CreatePageFromTemplateRequest,
  CreatePageFromTemplateResponse,
  CreatePageRequest,
  CreatePageResponse,
  CreateTemplateRequest,
  CreateTemplateResponse,
  DeletePageRequest,
  DeletePageResponse,
  DeleteTemplateRequest,
  DeleteTemplateResponse,
  GetPageRequest,
  GetPageResponse,
  GetTemplateRequest,
  GetTemplateResponse,
  ListPagesRequest,
  ListPagesResponse,
  ListTemplatesRequest,
  ListTemplatesResponse,
  LoadPageRequest,
  LoadPageResponse,
  PostPageRequest,
  PostPageResponse,
  SavePageRequest,
  SavePageResponse,
  UpdatePageRequest,
  UpdatePageResponse,
  UpdateTemplateRequest,
  UpdateTemplateResponse
} from './page_pb'
import { MethodKind } from '@bufbuild/protobuf'

/**
 * @generated from service pages.v2.PageService
 */
export const PageService = {
  typeName: 'pages.v2.PageService',
  methods: {
    /**
     * @generated from rpc pages.v2.PageService.CreateTemplate
     */
    createTemplate: {
      name: 'CreateTemplate',
      I: CreateTemplateRequest,
      O: CreateTemplateResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc pages.v2.PageService.ListTemplates
     */
    listTemplates: {
      name: 'ListTemplates',
      I: ListTemplatesRequest,
      O: ListTemplatesResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc pages.v2.PageService.GetTemplate
     */
    getTemplate: {
      name: 'GetTemplate',
      I: GetTemplateRequest,
      O: GetTemplateResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc pages.v2.PageService.UpdateTemplate
     */
    updateTemplate: {
      name: 'UpdateTemplate',
      I: UpdateTemplateRequest,
      O: UpdateTemplateResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc pages.v2.PageService.DeleteTemplate
     */
    deleteTemplate: {
      name: 'DeleteTemplate',
      I: DeleteTemplateRequest,
      O: DeleteTemplateResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc pages.v2.PageService.CreatePageFromTemplate
     */
    createPageFromTemplate: {
      name: 'CreatePageFromTemplate',
      I: CreatePageFromTemplateRequest,
      O: CreatePageFromTemplateResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc pages.v2.PageService.CreatePage
     */
    createPage: {
      name: 'CreatePage',
      I: CreatePageRequest,
      O: CreatePageResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc pages.v2.PageService.ListPages
     */
    listPages: {
      name: 'ListPages',
      I: ListPagesRequest,
      O: ListPagesResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc pages.v2.PageService.GetPage
     */
    getPage: {
      name: 'GetPage',
      I: GetPageRequest,
      O: GetPageResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc pages.v2.PageService.UpdatePage
     */
    updatePage: {
      name: 'UpdatePage',
      I: UpdatePageRequest,
      O: UpdatePageResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc pages.v2.PageService.DeletePage
     */
    deletePage: {
      name: 'DeletePage',
      I: DeletePageRequest,
      O: DeletePageResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc pages.v2.PageService.LoadPage
     */
    loadPage: {
      name: 'LoadPage',
      I: LoadPageRequest,
      O: LoadPageResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc pages.v2.PageService.SavePage
     */
    savePage: {
      name: 'SavePage',
      I: SavePageRequest,
      O: SavePageResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc pages.v2.PageService.PostPage
     */
    postPage: {
      name: 'PostPage',
      I: PostPageRequest,
      O: PostPageResponse,
      kind: MethodKind.Unary
    }
  }
} as const
