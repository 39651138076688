import { FC, ReactNode, createContext, useState } from 'react'

export type LockedFeatureModalContextType = {
  onClose?: (reason?: 'success' | 'dismiss') => void
  setOnClose: (onClose: LockedFeatureModalContextType['onClose']) => void
}

export const LockedFeatureModalContext = createContext<LockedFeatureModalContextType | undefined>(
  undefined
)

export const LockedFeatureModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [onClose, setFunctionOnClose] = useState<LockedFeatureModalContextType['onClose']>()
  const setOnClose = (fun: LockedFeatureModalContextType['onClose']): void => {
    // this allows to avoid function call on lock modal opening
    setFunctionOnClose(() => fun)
  }

  return (
    <LockedFeatureModalContext.Provider value={{ onClose, setOnClose }}>
      {children}
    </LockedFeatureModalContext.Provider>
  )
}
