import {
  CodeConfigurationField,
  FlowcodeColorOptionType,
  LandingPageDestination
} from '@dtx-company/inter-app/src/types/flowcode'
import { FcGeneratorOptions } from '@dtx-company/flowcode-generator-types/src'
import { FcShape } from '@dtx-company/flowcode-generator-types/src/fc_common.types'
import { LocgScanDestinationType, LocgValues } from './locgMachine.types'
import { LoggedOutGeneratorSection } from '../../components/LoggedOutCodeGeneratorV2/LoggedOutCodeGenerator.types'
import { RegionCode } from '@dtx-company/inter-app/src/constants/flagsByRegion'
import { StateFrom } from 'xstate'
import { createLocgMachine } from './locgMachine'
import { getCountryCodeForRegionCode } from 'awesome-phonenumber'
import { normalizeDestination } from '@dtx-company/inter-app/src/utils/links/normalizeDestination'

type LocgMachineState = StateFrom<ReturnType<typeof createLocgMachine>>

export const selectScanDestinationType = (state: LocgMachineState): LocgScanDestinationType => {
  return state.context.values[CodeConfigurationField.SCAN_DESTINATION_TYPE]
}

export const selectRegionCode = (state: LocgMachineState): RegionCode | undefined => {
  return state.context.values[CodeConfigurationField.REGION_CODE]
}

export const selectCountryCode = (state: LocgMachineState): number | undefined => {
  const regionCode = selectRegionCode(state)
  return regionCode ? getCountryCodeForRegionCode(regionCode) : undefined
}

export const selectScanDestination = (state: LocgMachineState): string => {
  return state.context.values[CodeConfigurationField.SCAN_DESTINATION]
}

export const selectScanDestinationPresent = (state: LocgMachineState): boolean => {
  return !!(
    state.context.values[CodeConfigurationField.SCAN_DESTINATION] ||
    state.context.values[CodeConfigurationField.FILE]
  )
}

export const selectNormalizedScanDestination = (state: LocgMachineState): string => {
  const scanDestinationType = selectScanDestinationType(state)
  const scanDestination = selectScanDestination(state)
  const regionCode = selectRegionCode(state)

  const normalizedDestination = normalizeDestination({
    scanDestinationType,
    value: scanDestination,
    regionCode
  })

  return normalizedDestination
}

export const selectDisplayScanDestination = (state: LocgMachineState): string => {
  const scanDestinationType = selectScanDestinationType(state)
  const normalizedDestination = selectNormalizedScanDestination(state)
  const file = selectFile(state)

  switch (scanDestinationType) {
    case LandingPageDestination.SMS:
      return `+${selectCountryCode(state)} ${normalizedDestination}`
    case LandingPageDestination.FILE:
      return file?.name ?? ''
    default:
      return normalizedDestination
  }
}

// TODO: proper validation implementation - CP
export const selectScanDestinationIsValid = (state: LocgMachineState): boolean => {
  return state.context.values.scanDestination.length > 0 || !!state.context.values.file
}

export const selectBody = (state: LocgMachineState): string | undefined => {
  return state.context.values[CodeConfigurationField.BODY]
}

export const selectSubject = (state: LocgMachineState): string | undefined => {
  return state.context.values[CodeConfigurationField.SUBJECT]
}

export const selectFile = (state: LocgMachineState): File | undefined => {
  return state.context.values[CodeConfigurationField.FILE]
}

export const selectAutogeneratedOptions = (
  state: LocgMachineState
): FcGeneratorOptions | undefined => {
  return state.context.values[CodeConfigurationField.AUTOGENERATED_OPTIONS] as
    | FcGeneratorOptions
    | undefined
}

export const selectColor = (state: LocgMachineState): FlowcodeColorOptionType => {
  return state.context.values[CodeConfigurationField.COLOR]
}

export const selectTopRimText = (state: LocgMachineState): string => {
  return state.context.values[CodeConfigurationField.TOP_RIM_TEXT] ?? ''
}

export const selectBottomRimText = (state: LocgMachineState): string => {
  return state.context.values[CodeConfigurationField.BOTTOM_RIM_TEXT] ?? ''
}

export const selectFrame = (state: LocgMachineState): boolean => {
  return state.context.values[CodeConfigurationField.FRAME]
}

export const selectShape = (state: LocgMachineState): FcShape => {
  return state.context.values[CodeConfigurationField.SHAPE]
}

export const selectLogo = (state: LocgMachineState): string | File | undefined => {
  return state.context.values[CodeConfigurationField.LOGO]
}

export const selectProgress = (state: LocgMachineState): number => {
  return state.context.progress
}

// TODO - consolidate naming?
export const selectSection = (state: LocgMachineState): LoggedOutGeneratorSection | null => {
  if (state.matches('codeDesign.editing.color')) {
    return LoggedOutGeneratorSection.ColorSelector
  }
  if (state.matches('codeDesign.editing.frameAndText')) {
    return LoggedOutGeneratorSection.FrameAndText
  }

  if (state.matches('codeDesign.editing.logo')) {
    return LoggedOutGeneratorSection.Logo
  }

  if (state.matches('codeDesign.editing.shape')) {
    return LoggedOutGeneratorSection.ShapeSelector
  }

  if (state.matches('scanDestination')) {
    return LoggedOutGeneratorSection.ScanDestination
  }

  if (state.matches('auth')) {
    return LoggedOutGeneratorSection.SignUp
  }

  return null
}

export const selectSectionGroup = (
  state: LocgMachineState
):
  | LoggedOutGeneratorSection.ScanDestination
  | LoggedOutGeneratorSection.CodeDesign
  | LoggedOutGeneratorSection.SignUp => {
  if (state.matches('scanDestination')) {
    return LoggedOutGeneratorSection.ScanDestination
  }

  if (state.matches('auth')) {
    return LoggedOutGeneratorSection.SignUp
  }

  return LoggedOutGeneratorSection.CodeDesign
}

export const selectError = (state: LocgMachineState) => (field: keyof LocgValues) => {
  return state.context.errors[field]
}

export const selectDesignFirst = (state: LocgMachineState): boolean => {
  return state.context.designFirst ?? false
}

export const selectFirstSectionIsActive = (state: LocgMachineState): boolean => {
  return state.context.designFirst ? state.matches('codeDesign') : state.matches('scanDestination')
}

export const selectSecondSectionIsActive = (state: LocgMachineState): boolean => {
  return state.context.designFirst ? state.matches('scanDestination') : state.matches('codeDesign')
}
