import { FLOWCODE_ROOT } from '@dtx-company/true-common/src/constants/root'
import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useBillingPlanType } from '@app/common/src/hooks/useBillingPlanType/useBillingPlanType'
import { useEffect, useRef } from 'react'
import { useFlowRouter } from '@dtx-company/inter-app/src/hooks/useFlowRouter'
import { useIntercom } from 'react-use-intercom'
import { useIsFlowpage } from '../../../hooks/useIsFlowpage/useIsFlowpage'
import { usePageCount } from '@dtx-company/inter-app/src/redux/slices/utils/pageCount'
import { useUserInfo } from '../../../shared/common/src/hooks/useUserInfo'
import add from 'date-fns/add'
import compact from 'lodash/compact'

/**
 * Provides a simple "account type" for Intercom to filter against.
 *
 * Allows intercom to do things like prioritize answering Enterprise user
 * questions above all else, or targeting messaging differently for pro flex vs
 * pro plus users.
 */
export function getAccountType(planType: PlanType): string {
  switch (planType) {
    case PlanType.ENTERPRISE:
      return 'ENTERPRISE'
    case PlanType.PILOT:
      return 'PILOT'
    case PlanType.PRO_FLEX:
      return 'PRO_FLEX'
    case PlanType.PRO_PLUS_ORG:
      return 'PRO_PLUS'
    case PlanType.PRO:
      return 'PRO'
    case PlanType.FREE:
      return 'BASIC'
  }
}

/**
 * Generates a link so CS can have something to click to see logs of a user and
 * easily paste into a story for any bug reports.
 */
export function generateDataDogLink(uid: string): string {
  const now = new Date()
  // DataDog will default to the past 15 minutes - I don't expect we'll
  // have CS being that realtime so I'm providing a link that encompasses
  // one month back and forward so that people that sit in the app for weeks
  // can hopefully still have their activity captured.
  // The link will be relative to when they access the app and this hook
  // runs so only doing 1 month back would always miss any errors they
  // encounter as they continue to use the app after this runs.
  const oneMonthForward = add(now, { months: 1 }).valueOf()
  const oneMonthBack = add(now, { months: -1 }).valueOf()
  const url = new URL('https://app.datadoghq.com/logs')
  url.search = new URLSearchParams({
    query: `@uid:${uid}`,
    stream_sort: 'time,desc',
    from_ts: oneMonthBack.toString(),
    to_ts: oneMonthForward.toString()
  }).toString()
  return url.toString()
}

/**
 * Handles syncing Intercom with the application and user state.
 */
export function IntercomSync(): null {
  const authState = useAuthState()
  const router = useFlowRouter()
  const billingPlanType = useBillingPlanType()
  const isFlowpage = useIsFlowpage()
  const userInfo = useUserInfo()
  const flowpageCount = usePageCount()
  const navigation_version = 2
  const analytics_landing_page_version = 2

  // MUST destructure intercom because the intercom reference itself is not stable
  // from useIntercom so effects will keep re-triggering, may load multiple instances
  // of the widget, and will eventually crash the app.
  const { boot, shutdown, update } = useIntercom()

  // May error if we attempt to boot twice
  const isInitialized = useRef(false)

  // Boot and Shutdown, sync user
  useEffect(() => {
    if (isFlowpage) return
    if (!authState.isAuthChecked) return
    if (isInitialized.current) return

    // clear previous state, if any, to handle user logging out
    // only for logging out so that messages sent while logged out can be associated
    // to the user once they log in
    if (!authState.isAuthenticated) {
      shutdown()
      isInitialized.current = false
    }

    // boot a new session
    boot()
    isInitialized.current = true
  }, [
    isFlowpage,
    authState.isAuthChecked,
    authState.isAuthenticated,
    authState.jwt,
    boot,
    shutdown
  ])

  // Sync user
  // Any values that are undefined will be ignored, while empty string or null will be unset
  // https://developers.intercom.com/intercom-api-reference/v1.3/reference/create-or-update-user
  useEffect(() => {
    if (isFlowpage) return
    if (!authState.isAuthChecked) return
    if (userInfo.isLoading) return
    if (!authState.jwt) return
    const jwt = authState.jwt
    update({
      userId: jwt.ithacaId,
      createdAt: jwt.createdAt,
      email: jwt.email,
      name: compact([jwt.firstName, jwt.lastName]).join(' '),
      customAttributes: {
        account_type: billingPlanType ? getAccountType(billingPlanType) : null,
        cohort: authState.cohort?.name.replace(/^cohort:/, '').toUpperCase() ?? null,
        flowcode_limit: authState.codeLimit ?? null,
        flowpage_limit: authState.pageLimit ?? null,
        flowcode_count: userInfo.data?.me.flowcodeCount ?? null,
        flowpage_count: flowpageCount ?? null,
        org_role: authState.org?.userOrgRole?.replace(/^org_role:/, '').toUpperCase() ?? null,
        org_name: authState.org?.name ?? null,
        internal_admin_link: `${FLOWCODE_ROOT}/internal-admin/user/detail?id=${jwt.ithacaId}`,
        datadog_link: generateDataDogLink(jwt.ithacaId),
        navigation_version,
        analytics_landing_page_version
      }
    })
  }, [
    isFlowpage,
    authState.isAuthChecked,
    userInfo.isLoading,
    authState.jwt,
    billingPlanType,
    authState.cohort?.name,
    authState.codeLimit,
    authState.pageLimit,
    authState.org,
    userInfo.data,
    flowpageCount,
    update,
    navigation_version,
    analytics_landing_page_version
  ])

  // Track page changes
  useEffect(() => {
    if (isFlowpage) return
    function handleRouteChange(): void {
      update() // pings intercom to record the page change
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [isFlowpage, router, update])

  return null
}
