// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file common/v1/pagination.proto (package common.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { Message, proto3 } from '@bufbuild/protobuf'

/**
 * @generated from enum common.v1.Order
 */
export enum Order {
  /**
   * @generated from enum value: ORDER_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ORDER_ASC = 1;
   */
  ASC = 1,

  /**
   * @generated from enum value: ORDER_DESC = 2;
   */
  DESC = 2
}
// Retrieve enum metadata with: proto3.getEnumType(Order)
proto3.util.setEnumType(Order, 'common.v1.Order', [
  { no: 0, name: 'ORDER_UNSPECIFIED' },
  { no: 1, name: 'ORDER_ASC' },
  { no: 2, name: 'ORDER_DESC' }
])

/**
 * Cursor pagination arguments to provide both forward and backward pagination
 *
 * Use the first/after arguments to paginate forward
 * Use the last/before arguments to paginate backward
 *
 * @generated from message common.v1.PaginationArguments
 */
export class PaginationArguments extends Message<PaginationArguments> {
  /**
   * Paginate after is an opaque cursor that includes any records immediately after sorted by the order field returning at most "first" records.
   * This cursor generally comes from the "end_cursor" response string in PaginationPageInfo
   *
   * @generated from field: optional string after = 1;
   */
  after?: string

  /**
   * Paginate before is an opaque cursor that includes any records immediately before the cursor sorted by the order field returning at most "last" records.
   * This cursor generally comes from the "start_cursor" response string in PaginationPageInfo
   *
   * @generated from field: optional string before = 2;
   */
  before?: string

  /**
   * Paginate first takes a non-negative integer
   *
   * Use this argument to indicate how many records you would like to request
   *
   * @generated from field: optional int32 first = 3;
   */
  first?: number

  /**
   * Paginate last takes a non-negative integer
   *
   * Use this argument to indicate how many records you would like to request
   *
   * @generated from field: optional int32 last = 4;
   */
  last?: number

  /**
   * Order direction
   *
   * @generated from field: optional common.v1.Order order = 5;
   */
  order?: Order

  /**
   * Which field to order by
   *
   * @generated from field: optional string order_by = 6;
   */
  orderBy?: string

  constructor(data?: PartialMessage<PaginationArguments>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'common.v1.PaginationArguments'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'after', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: 'before', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: 'first', kind: 'scalar', T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: 'last', kind: 'scalar', T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: 'order', kind: 'enum', T: proto3.getEnumType(Order), opt: true },
    { no: 6, name: 'order_by', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaginationArguments {
    return new PaginationArguments().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaginationArguments {
    return new PaginationArguments().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): PaginationArguments {
    return new PaginationArguments().fromJsonString(jsonString, options)
  }

  static equals(
    a: PaginationArguments | PlainMessage<PaginationArguments> | undefined,
    b: PaginationArguments | PlainMessage<PaginationArguments> | undefined
  ): boolean {
    return proto3.util.equals(PaginationArguments, a, b)
  }
}

/**
 * Response object used to inform the next pagination arguments
 *
 * @generated from message common.v1.PaginationPageInfo
 */
export class PaginationPageInfo extends Message<PaginationPageInfo> {
  /**
   * Indicates whether more data exists following the set defined by the clients arguments
   *
   * @generated from field: bool has_next_page = 1;
   */
  hasNextPage = false

  /**
   * Indicates whether more data exists prior to the set defined by the clients argument
   *
   * @generated from field: bool has_previous_page = 2;
   */
  hasPreviousPage = false

  /**
   * Opaque cursor corresponding to the first record in the response array
   *
   * @generated from field: optional string start_cursor = 3;
   */
  startCursor?: string

  /**
   * Opaque cursor corresponding to the last record in the response array
   *
   * @generated from field: optional string end_cursor = 4;
   */
  endCursor?: string

  constructor(data?: PartialMessage<PaginationPageInfo>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'common.v1.PaginationPageInfo'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'has_next_page', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: 'has_previous_page', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: 'start_cursor', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: 'end_cursor', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaginationPageInfo {
    return new PaginationPageInfo().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaginationPageInfo {
    return new PaginationPageInfo().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): PaginationPageInfo {
    return new PaginationPageInfo().fromJsonString(jsonString, options)
  }

  static equals(
    a: PaginationPageInfo | PlainMessage<PaginationPageInfo> | undefined,
    b: PaginationPageInfo | PlainMessage<PaginationPageInfo> | undefined
  ): boolean {
    return proto3.util.equals(PaginationPageInfo, a, b)
  }
}
