import { CashApp, Email, Optional, Phone, SMS, Venmo, WiFi } from '../types'
import {
  cashAppSchema,
  emailSchema,
  phoneSchema,
  smsSchema,
  venmoSchema,
  wifiSchema
} from '../schemas'
import validateIsMobilePhone from 'validator/lib/isMobilePhone'

export const isSMSShortCode = (shortCode: string): boolean => /^\d{5,6}$/.test(shortCode)

export const isEmail = (data: Optional<Email>): boolean => emailSchema.isValidSync(data)

export const isSMS = (data: Optional<SMS>): boolean => {
  const phoneNumber = data?.phoneNumber || ''
  const isPhoneNumberValid = validateIsMobilePhone(phoneNumber) || isSMSShortCode(phoneNumber)
  return isPhoneNumberValid && smsSchema.isValidSync(data)
}

/**
 * Checks if string starts with numbers and ends with an optional # symbol
 * @param extension
 * @returns
 */
const getIsExtensionValid = (extension: string): boolean => {
  return /^,*\d+#{0,1}$/.test(extension)
}

// isMobilePhone is deprecated, used isSMS
export const isMobilePhone = (data: Optional<SMS>): boolean => isSMS(data)

export const isPhone = (data: Optional<Phone>): boolean => {
  const [phoneNumber, extension] = (data?.phoneNumber || '').split(/,(.+)/)
  const isValidNumber = validateIsMobilePhone(phoneNumber)
  const isValidExtension = !extension || getIsExtensionValid(extension)
  return isValidNumber && isValidExtension && phoneSchema.isValidSync(data)
}

// TODO: deconstruct and validate venmo url
export const isVenmo = (data: Optional<Venmo>): boolean => {
  return venmoSchema.isValidSync(data)
}

export const isWiFi = (data: Optional<WiFi>): boolean => {
  return wifiSchema.isValidSync(data)
}
export const isCashApp = (data: Optional<CashApp>): boolean => {
  return cashAppSchema.isValidSync(data)
}
