import { SVGProps, memo } from 'react'

function VenmoSvg(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 19 20" {...props}>
      <g clipPath="url(#prefix__prefix__clip_venmo)">
        <path
          fill={props.fill || '#3D95CE'}
          d="M17.698 0c.727 1.2 1.055 2.437 1.055 4 0 4.982-4.254 11.455-7.706 16H3.162L0 1.09 6.904.436 8.576 13.89c1.562-2.545 3.49-6.544 3.49-9.271 0-1.493-.256-2.51-.655-3.346L17.698 0z"
        />
      </g>
      <defs>
        <clipPath id="prefix__prefix__clip_venmo">
          <path fill="#fff" d="M0 0h18.753v20H0V0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Venmo = memo(VenmoSvg)

function Venmo2Svg({
  stroke = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 21 22" {...rest}>
      <path
        stroke={color || stroke}
        strokeWidth={1.5}
        d="M18.698 1c.727 1.2 1.055 2.437 1.055 4 0 4.982-4.254 11.455-7.706 16H4.162L1 2.09l6.904-.655L9.576 14.89c1.562-2.545 3.49-6.544 3.49-9.271 0-1.493-.256-2.51-.655-3.346L18.698 1z"
      />
    </svg>
  )
}

export const Venmo2 = memo(Venmo2Svg)

function VenmoSolidSvg({ color, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg viewBox="0 0 22 22" {...rest}>
      <path
        d="M0 1.28017L8.09524 0.49459C8.74903 5.87261 9.40039 11.2361 10.0493 16.585L10.095 16.5959C10.2595 16.3105 10.428 16.0286 10.5883 15.7392C11.7514 13.6535 12.7735 11.501 13.4877 9.20754C13.9017 7.88035 14.1955 6.53002 14.1468 5.12253C14.1042 3.90793 13.9337 2.72214 13.4284 1.60793C13.4162 1.57221 13.4061 1.53577 13.3981 1.49882L14.5024 1.26577C16.5115 0.85072 18.5211 0.436545 20.5285 0.0136412C20.7024 -0.0230192 20.7907 0.00927689 20.8819 0.171194C21.7198 1.65507 22.0298 3.26681 21.9978 4.96279C21.9654 6.67405 21.5765 8.31329 21.029 9.91805C20.143 12.5166 18.8886 14.93 17.4901 17.2715C16.1321 19.5493 14.6333 21.7292 13.0557 23.8499C13.0213 23.8911 12.9792 23.9249 12.9318 23.9492C12.8844 23.9734 12.8328 23.9878 12.7799 23.9913C9.82038 23.9987 6.86087 23.9965 3.90137 24C3.75726 24 3.69159 23.9712 3.66516 23.8084C3.25555 21.2579 2.84125 18.7081 2.42226 16.159C1.98622 13.4878 1.55003 10.8165 1.1137 8.14526C0.750428 5.92498 0.386584 3.70456 0.0221717 1.48398C0.0127914 1.42943 0.00980673 1.37182 0 1.28017Z"
        fill={color || '#111111'}
      />
    </svg>
  )
}

export const VenmoSolid = memo(VenmoSolidSvg)
