import { ANALYTICS_COLLECTOR_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import {
  AnalyticsCollectorActionType,
  AnalyticsCollectorDisplayType,
  FlowpageAnalyticsCollectorEventTypes
} from '../types'
import { Cookie, getCookie } from '@dtx-company/true-common/src/utils/cookies'
import { DrawerAction } from '@dtx-company/true-common/src/utils/heap/types'
import { HEAP_COOKIE } from '@dtx-company/true-common/src/utils/cookies/cookies.constants'
import { fireHeapEvent as fhe } from '@dtx-company/true-common/src/utils/heap'
import { logger } from '@dtx-company/logger'
import Cookies from 'universal-cookie'
import format from 'date-fns/format'

/**
 * @deprecated use {@link fireAnalyticsEvent} instead
 */
export const fireHeapEvent = fhe

export const fireGtmEvent = (event: string, args?: Record<string, unknown>): void => {
  if (typeof window !== 'undefined') {
    window.dataLayer?.push({ event, ...args })
  }
}

export const fireBingEvent = (event: string, category: string): void => {
  window.uetq = window.uetq || []
  window.uetq.push('event', event, {
    event_category: category,
    event_label: 'Label',
    event_value: '0'
  })
}

export const fireBingProConversionEvent = (): void => {
  window.uetq = window.uetq || []
  window.uetq.push('event', 'purchase', { revenue_value: 1, currency: 'USD' })
}

export const fireBingContactSalesEvent = (): void => {
  window.uetq = window.uetq || []
  window.uetq.push('event', 'book_appointment', {})
}

export const fireOptimizelyEvent = (...args: OptimizelyTrackArgs): void => {
  window.optimizelyTrack = window.optimizelyTrack || []
  window.optimizelyTrack.push(args)
}

interface FireFlowpageAnalyticsCollectorEventProps {
  page_id?: string | null
  link_id?: string | null
  parent_link_id?: string | null
  actionType?: AnalyticsCollectorActionType
  displayType?: AnalyticsCollectorDisplayType
  headers?: Record<string, string | null | undefined>
  latitude?: number
  longitude?: number
  accuracyMeters?: number
  clientEventTime: number
  eventId?: string
  shouldCollectEventId?: boolean
  isInterstitial?: boolean
}

const cookies = new Cookies()

const formatAnalyticsTimestamp = (ts: number): string =>
  format(new Date(ts), `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`) // outputs format of 2023-03-09T09:26:09:019-05:00

export const fireFlowpageAnalyticsCollectorEvent = async (
  eventType: FlowpageAnalyticsCollectorEventTypes,
  {
    page_id: pageId,
    link_id: linkId,
    parent_link_id: parentLinkId,
    actionType,
    displayType,
    headers = {},
    latitude,
    longitude,
    accuracyMeters,
    clientEventTime,
    eventId,
    shouldCollectEventId,
    isInterstitial
  }: FireFlowpageAnalyticsCollectorEventProps
): Promise<string | undefined | void> => {
  const optOut = getCookie(Cookie.OPT_OUT)
  if (optOut || !pageId) return
  const flowpageAnalyticsCollectorUrl = ANALYTICS_COLLECTOR_ENDPOINT + '/flowpage'
  const heapCookie = JSON.stringify(cookies.get(HEAP_COOKIE))
  const rdserviceCookie = getCookie(Cookie.REDIRECT_SERVICE_COOKIE)

  const currentUrl = window.location?.href
  const referrerUrl = document.referrer

  /*
    Tracks the time just before sending the Analytics call. This is compared with
    the time on the Analytics Collector service to determine if the client's clock
    is out of sync, and any adjustments can be made from there.
  */
  const clientSendTime = Date.now()

  const reqBody = JSON.stringify({
    pageId,
    eventType,
    heapCookie,
    currentUrl,
    referrerUrl,
    rdserviceCookie,
    eventRequestHeaders: headers,
    latitude,
    longitude,
    accuracyMeters,
    isInterstitial,
    clientEventTime: formatAnalyticsTimestamp(clientEventTime),
    clientSendTime: formatAnalyticsTimestamp(clientSendTime),
    ...(eventId && { eventId }),
    eventPayload: {
      ...(linkId && { linkId }),
      ...(parentLinkId && { parentLinkId }),
      ...(actionType && { actionType }),
      ...(displayType && { displayType })
    }
  })
  if (navigator?.sendBeacon && !shouldCollectEventId) {
    const reqBlob = new Blob([reqBody])
    navigator.sendBeacon(flowpageAnalyticsCollectorUrl, reqBlob)
    return
  } else {
    try {
      const resp = await fetch(flowpageAnalyticsCollectorUrl, {
        method: 'POST',
        body: reqBody
      })
      const message = resp ? await resp?.text() : undefined
      return message
    } catch (e) {
      logger.logError(`Error firing flowpage analytics event, e`, { team: 'flowlytics' })
    }
  }
}

/**
 * Identifies a user in hubspot and allows you to update a hubspot user's contact properties.
 * Note: this will not immediately forward data to hubspot. Events are only passed to hubspot via trackpage or custom behavioral events.
 *
 * https://developers.hubspot.com/docs/api/events/tracking-code#identify-a-visitor
 */
export const updateHubspotUser = (params: Record<string, unknown> & { email: string }): void => {
  window._hsq = window._hsq || []
  window._hsq.push(['identify', params])
}

export const fireHubspotEvent = (event: string, properties?: Record<string, unknown>): void => {
  window._hsq = window._hsq || []
  window._hsq.push([
    'trackCustomBehavioralEvent',
    {
      name: event,
      properties
    }
  ])
}

export const trackHubSpotPage = (path?: string): void => {
  window._hsq = window._hsq || []
  if (path) window._hsq.push(['setPath', path])
  window._hsq.push(['trackPageView'])
}

/**
 * Returns action user performed on a dropdown or drawer
 */
export const getDrawerAction = (opened: boolean): DrawerAction => {
  return opened ? DrawerAction.UserOpenedDrawer : DrawerAction.UserClosedDrawer
}

// heap only accepts numbers and strings as values on param object
// we prefer to use 1 for true, 0 for false, since it offers additional query options, for ex. when creating aggregations on a table
export function getHeapBooleanParam(param?: boolean): number {
  return param ? 1 : 0
}
