import { ClientSideErrorType, ServerSideErrorType } from './FlowErrorType'

// name property is name of failed functionality (function, logic)
interface FlowErrorProps extends Error {
  type: ServerSideErrorType | ClientSideErrorType
  data?: any
  status?: number
  statusText?: string
  code?: string
}

export class FlowError extends Error {
  type: ServerSideErrorType | ClientSideErrorType
  data?: any
  status?: number
  statusText?: string
  code?: string

  constructor(props: FlowErrorProps) {
    super(props.message)
    this.message = props.message
    this.name = props.name
    this.type = props.type
    this.data = props.data
    this.status = props.status
    this.statusText = props.statusText
    this.code = props.code

    Object.setPrototypeOf(this, FlowError.prototype)
  }
}

export interface ServerSideErrorProps extends FlowErrorProps {
  type:
    | ServerSideErrorType.ServerSideValidationError
    | ServerSideErrorType.ServerSideInternalError
    | ServerSideErrorType.ServerSideOtherError
  code?: string
  endpoint: string
}

export class ServerSideError extends FlowError {
  type: ServerSideErrorType
  code?: string
  endpoint: string

  constructor(props: ServerSideErrorProps) {
    super(props)
    this.message = props.message
    this.name = props.name
    this.type = props.type
    this.code = props.code
    this.endpoint = props.endpoint

    Object.setPrototypeOf(this, ServerSideError.prototype)
  }
}

interface ClientSideErrorProps extends FlowErrorProps {
  type: ClientSideErrorType
}

export class ClientSideError extends FlowError {
  type: ClientSideErrorType

  constructor(props: ClientSideErrorProps) {
    super(props)
    this.message = props.message
    this.name = props.name
    this.type = props.type

    Object.setPrototypeOf(this, ClientSideError.prototype)
  }
}
