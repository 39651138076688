import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PlanType } from '@dtx-company/true-common/src/types/planType'

export interface LockedFeatureModalState {
  lockedFeatureName?: string
  planType?: PlanType
}

const initialState: LockedFeatureModalState = {
  lockedFeatureName: '',
  planType: undefined
}

const lockedFeatureModalSlice = createSlice({
  name: 'lockedFeatureModal',
  initialState,
  reducers: {
    setLockedFeatureModal: (state, action: PayloadAction<LockedFeatureModalState>) => {
      state.lockedFeatureName = action.payload.lockedFeatureName
      state.planType = action.payload.planType
    },
    clearLockedFeatureModal: state => {
      state.lockedFeatureName = initialState.lockedFeatureName
      state.planType = initialState.planType
    }
  }
})

export const { setLockedFeatureModal, clearLockedFeatureModal } = lockedFeatureModalSlice.actions

export default lockedFeatureModalSlice.reducer
