import { MutableRefObject, useEffect, useState } from 'react'

/* This hook is to keep track of whether an element is in view on the screen. 	
The threshold value, or visibility threshold is the proportion (out of 1) of the element that must be visible for the function to return 'true' */

export const useVisibilityThreshold = ({
  threshold,
  ref
}: {
  threshold: number
  ref: MutableRefObject<HTMLElement | null>
}): { visible: boolean } => {
  const [visible, setVisible] = useState<boolean>(false)

  const observerIsDefined = typeof IntersectionObserver !== 'undefined'

  useEffect(() => {
    let observer: IntersectionObserver | null = null
    let refObject: HTMLElement | null = null
    if (observerIsDefined) {
      refObject = ref.current
      observer = new IntersectionObserver(
        ([entry]) => {
          setVisible(entry.intersectionRatio >= threshold)
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: threshold
        }
      )
    }
    if (ref.current) {
      observer?.observe(ref.current)
    }
    return () => {
      if (refObject && observer) observer.unobserve(refObject)
    }
  }, [observerIsDefined, ref, threshold])

  return { visible }
}
