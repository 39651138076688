import { Cookie } from '@dtx-company/true-common/src/types/cookies'
import { getCookie } from '@dtx-company/true-common/src/utils/cookies/cookies.utils'
import { getHeap } from '@dtx-company/true-common/src/utils/heap'

/**
 * @deprecated use {@link fireAnalyticsEvent} from @dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent.ts instead
 */
export async function trackHeapEvent(
  e: string,
  meta?: Record<string, string | number>
): Promise<void> {
  getHeap()?.track(e, meta)
}

interface SetHeapAttributeProps {
  ithacaId?: string
  email?: string
  plan?: string
}

export function setHeapAttributes({ ithacaId, email, plan }: SetHeapAttributeProps): void {
  const heap = getHeap()
  if (heap) {
    if (ithacaId) {
      heap.identify(ithacaId)
      heap.addUserProperties({
        email,
        plan
      })
    } else {
      const anonymousSessionId = getCookie(Cookie.ANONYMOUS_SESSION_ID)
      heap.addUserProperties({ anonymousSessionId })
    }
  }
}
