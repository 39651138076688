import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface CreatePageState {
  autoSubmitPageCreationForm: boolean
}

const initialState: CreatePageState = {
  autoSubmitPageCreationForm: false
}

const createPage = createSlice({
  name: 'createPage',
  initialState,
  reducers: {
    setAutoSubmitPageCreationForm: (state, action: PayloadAction<boolean>) => {
      state.autoSubmitPageCreationForm = action.payload
    }
  }
})

export const { setAutoSubmitPageCreationForm } = createPage.actions

export default createPage.reducer
