import { ThemeOptions, createTheme } from '@mui/material/styles'
import { alertComponentOverrides } from '../components/Alert/Alert.overrides'
import { autocompleteComponentOverrides } from '../components/Autocomplete/Autocomplete.overrides'
import { backdropComponentOverrides } from '../components/Backdrop/Backdrop.overrides'
import { buttonComponentOverrides } from '../components/Button/Button.overrides'
import { checkboxComponentOverrides } from '../components/Checkbox/Checkbox.overrides'
import { chipComponentOverrides } from '../components/Chip/Chip.overrides'
import { colors } from './colors'
import { cssBaselineOverrides } from '../components/CssBaseline/CssBaseline.overrides'
import { dialogComponentOverrides } from '../components/Dialog/Dialog.overrides'
import { dividerComponentOverrides } from '../components/Divider/Divider.overrides'
import { flow2Palette } from './palettes/flow2Palette'
import { formControlLabelComponentOverrides } from '../internal-components/FormControlLabel/FormControlLabel.overrides'
import { menuComponentOverrides } from '../components/Menu/Menu.overrides'
import { selectComponentOverrides } from '../components/Select/Select.overrides'
import { shadows } from './shadows'
import { snackbarComponentOverrides } from '../components/Snackbar/Snackbar.overrides'
import { spacing } from './spacing'
import { switchComponentOverrides } from '../components/Switch/Switch.overrides'
import { tabComponentOverrides } from '../components/Tab/Tab.overrides'
import { textFieldComponentOverrides } from '../components/TextField/TextField.overrides'
import { typography } from './typography'
import { typographyComponentOverrides } from '../components/Typography/Typography.overrides'
import { typographyFlow2 } from './typographyFlow2'
const { brand, builder, lightPalette, darkPalette } = colors

const shape = {
  borderRadius: 0 // default to sharp corners
}

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 768,
    lg: 1152,
    xl: 1440
  }
}

export const themeOptions: ThemeOptions = {
  brand,
  builder,
  breakpoints,
  palette: lightPalette,
  spacing,
  shape,
  typography,
  shadows,
  components: {
    ...alertComponentOverrides,
    ...autocompleteComponentOverrides,
    ...backdropComponentOverrides,
    ...buttonComponentOverrides,
    ...checkboxComponentOverrides,
    ...chipComponentOverrides,
    ...cssBaselineOverrides,
    ...dialogComponentOverrides,
    ...dividerComponentOverrides,
    ...formControlLabelComponentOverrides,
    ...menuComponentOverrides,
    ...selectComponentOverrides,
    ...snackbarComponentOverrides,
    ...switchComponentOverrides,
    ...tabComponentOverrides,
    ...textFieldComponentOverrides,
    ...typographyComponentOverrides
  }
}

/**
 * The default theme for Flowcode's Design System.
 */
const theme = createTheme(themeOptions)

const darkTheme = createTheme({ ...theme, palette: darkPalette })

const themeFlow2Options: ThemeOptions = {
  ...themeOptions,
  palette: flow2Palette,
  typography: typographyFlow2
}

/**
 * The default theme for Flowcode's Design System.
 */
const themeFlow2 = createTheme(themeFlow2Options)

export { theme, darkTheme, themeFlow2, themeFlow2Options }
