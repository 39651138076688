import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface AutosharingTeam {
  orgId: string
  teamId: string
  teamName: string
}

export type AutoshareAssetsSlice = {
  autosharingTeam: AutosharingTeam | null | undefined
}

const initialState: AutoshareAssetsSlice = { autosharingTeam: undefined }

export const autoshareAssetsSlice = createSlice({
  name: 'autoshareAssets',
  initialState,
  reducers: {
    setAutoshareTeam: (state, action: PayloadAction<AutosharingTeam>) => {
      state.autosharingTeam = action.payload
    },
    clearAutoshareTeam: state => {
      state.autosharingTeam = undefined
    }
  }
})

export const { setAutoshareTeam, clearAutoshareTeam } = autoshareAssetsSlice.actions

export default autoshareAssetsSlice.reducer
