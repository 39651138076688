import { Link } from '@dtx-company/flow-codegen/src/page/generated.types'

export enum SelectedFlowpageView {
  GRID,
  LIST
}

type LeanPage = { id: string; thumbNailImgUrl: string; slugName: string }
export type LeanPagesType = Record<string, LeanPage> | null | undefined

export enum PageTypes {
  CONTACT = 'contact',
  DEFAULT = 'default',
  MIAMI_HEAT = 'miamiHeat'
}

export type PDFActionData = {
  link: string
}

export type PlaceholdersType = {
  socialLink?: Partial<Link>
  destination?: Partial<Link>
  contactInfo?: Partial<Link>
}

export type ErrorStateType = { [key: string]: string | null }
export type SetErrorStateType = (_arg0: { [key: string]: string | null }) => void

export interface FileNameActionType {
  type: 'image' | 'pdf' | 'all'
  fileName: string
}

export interface SocialFilesState {
  imageFileName: string
  pdfFileName: string
}
