import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useMemo } from 'react'

/**
 * Provides the type of plan that the current user is on (free, pro, enterprise, etc)
 * even if they don't have an active subscription, such as people with pro-for-free
 * deals through education referrals.
 *
 * Should only be used for logic that is directly related to a user's subscription status,
 * such as displaying current subscription (such as pro-for-free) and handling upgrades
 * vs downgrades.
 * This should never be used to decide which feature a user has access to.
 */
export function useBillingPlanType(doNotDefault?: boolean): PlanType | undefined {
  const authState = useAuthState()
  return useMemo(() => {
    if (!authState.isAuthenticated) return
    // NOTE: these operations are muturally exclusive
    if (authState.hasOperation('view:billing_plan_type_as_enterprise')) return PlanType.ENTERPRISE
    if (authState.hasOperation('view:billing_plan_type_as_pilot')) return PlanType.PILOT
    if (authState.hasOperation('view:billing_plan_type_as_pro_flex')) return PlanType.PRO_FLEX
    if (authState.hasOperation('view:billing_plan_type_as_pro_plus_org'))
      return PlanType.PRO_PLUS_ORG
    if (authState.hasOperation('view:billing_plan_type_as_pro')) return PlanType.PRO
    if (authState.hasOperation('view:billing_plan_type_as_free')) return PlanType.FREE
    if (doNotDefault) return undefined
    return PlanType.FREE
  }, [authState, doNotDefault])
}
