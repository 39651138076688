import { Cookie } from '@dtx-company/true-common/src/types/cookies'
import {
  deleteAllCookies,
  deleteCookie,
  syncRedirectServiceCookie
} from '@dtx-company/true-common/src/utils/cookies/cookies.utils'
import { useCookies } from '@dtx-company/true-common/src/utils/cookies/cookies.hooks'
import { useEffect } from 'react'
import { useIsEU } from './useCheckEU'

/**
 * Handle syncing RDS cookie if it is already set
 *
 */
export const useRDSCookieSync = (): void => {
  const { optOut, setCookie } = useCookies()
  const { isEU, isEULoaded } = useIsEU()
  useEffect(() => {
    const handleRDSCookieSync = async (): Promise<void> => {
      if (optOut) {
        deleteAllCookies()
        return
      }
      if (!isEULoaded) return

      if (isEU) {
        deleteCookie(Cookie.REDIRECT_SERVICE_COOKIE)
        return
      }
      syncRedirectServiceCookie({ setCookie })
    }
    handleRDSCookieSync()
  }, [optOut, setCookie, isEU, isEULoaded])
}
