import { IthacaJwt, IthacaPermission } from '@dtx-company/ithaca-sdk/src'
import { IthacaOperationName } from '@dtx-company/true-common/src/types/operations'
import get from 'lodash/get'

const jwtHasOperationByType = ({
  jwt,
  operationName,
  operationType
}: {
  jwt: IthacaJwt | null
  operationName: IthacaOperationName
  operationType: 'personalPermissions' | 'org.permissions' | 'userExtras'
}): boolean => {
  if (!jwt) return false

  const permissions: IthacaPermission[] = get(jwt, operationType, [])

  return permissions?.some(perm => {
    return perm?.operations?.some((op: string) => op == operationName)
  })
}

/**
 * Determine if a JWT has a specific operation
 */
export const jwtHasOperation = ({
  jwt,
  operationName
}: {
  jwt: IthacaJwt | null
  operationName: IthacaOperationName
}): boolean => {
  return (
    jwtHasOperationByType({ jwt, operationType: 'personalPermissions', operationName }) ||
    jwtHasOperationByType({ jwt, operationType: 'org.permissions', operationName }) ||
    jwtHasOperationByType({ jwt, operationType: 'userExtras', operationName })
  )
}
