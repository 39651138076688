import { RefObject, useEffect } from 'react'
import { useScreenSizeBreakpoints } from '@app/common/src/hooks/useScreenSizeBreakpoints'

export const useOnErrorScrollInToView = (
  error: boolean,
  ref:
    | RefObject<HTMLInputElement>
    | React.RefObject<HTMLButtonElement>
    | React.RefObject<HTMLDivElement>
): void => {
  const { isMobile } = useScreenSizeBreakpoints()

  useEffect(() => {
    if (error) {
      ref.current?.scrollIntoView({
        block: isMobile ? 'start' : 'center',
        behavior: 'smooth'
      })
    }
  }, [error, isMobile, ref])
}
