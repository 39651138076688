import { Dispatch, SetStateAction } from 'react'
import _useLocalStorageState from 'use-local-storage-state'

export type UseLocalStorageStateOptions<T> = {
  key: string
  defaultValue?: T
  syncAcrossTabs?: boolean
}

export type UseLocalStorageStateReturn<T> = [T, Dispatch<SetStateAction<T>>]

/**
 * Syncs stored values into `localStorage` (if available).
 *
 * If multiple places use this hook with the same key, setting the value in one
 * place will updated it in all places.
 *
 * Additionally, localStorage is shared for a domain across tabs so setting a value
 * in one tab may sync it across other tabs (unless `syncAcrossTabs` is set to `false`).
 *
 * Some browsers may not support syncing. If localStorage is blocked, out of memory,
 * or otherwise fails then this will fallback to local memory.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
 */
export function useLocalStorageState<T>(
  options: UseLocalStorageStateOptions<T>
): UseLocalStorageStateReturn<T>
export function useLocalStorageState<T>(
  key: string,
  defaultValue?: T
): UseLocalStorageStateReturn<T>
export function useLocalStorageState<T>(
  keyOrOptions: string | UseLocalStorageStateOptions<T>,
  defaultValueOrNothing?: T
): UseLocalStorageStateReturn<T> {
  const isOptions = typeof keyOrOptions === 'object'

  const [value, setValue] = _useLocalStorageState<T>(isOptions ? keyOrOptions.key : keyOrOptions, {
    defaultValue: isOptions ? keyOrOptions.defaultValue : defaultValueOrNothing,
    storageSync: isOptions ? keyOrOptions.syncAcrossTabs : true
  })

  return [value, setValue]
}
