import { BUTTON_COLOR_VARIANTS, BUTTON_SIZE_VARIANTS } from '../../../constants'
import { Box } from '../Box'
import { ButtonProps, FABButtonProps, TextVariantType } from '../../../types'
import { ComponentType, FC } from 'react'
import { Icon } from '../Icon/'
import { Text } from '../Text'
import { border, color, flexbox, layout, position, shadow, space, variant } from 'styled-system'
import { transition } from '../../../configs'
import facebook from '../../../static/facebook.svg'
import google from '../../../static/google.svg'
import plusIcon from '../../../static/icons/plus.svg'
import styled from 'styled-components'

const ButtonBase: ComponentType<ButtonProps & { isLoading?: boolean }> = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 4px;
  :focus {
    outline: none;
  }
  ${variant({
    prop: 'colorVariant',
    variants: BUTTON_COLOR_VARIANTS
  })}
  ${variant({
    prop: 'sizeVariant',
    variants: BUTTON_SIZE_VARIANTS
  })}
    ${color}
    ${border}
    ${space}
    ${flexbox}
    ${layout}
    ${transition}
    ${position}
    ${shadow}
    ${({ disabled }) => (disabled ? `` : `cursor: pointer`)}
`

/**
 * @deprecated Use a button wrapper component instead
 */
export const Button: FC<ButtonProps> = (props: ButtonProps): JSX.Element => {
  const {
    onClick,
    label,
    icon,
    iconAlt,
    iconProps,
    colorVariant = 'primary',
    sizeVariant = 'small',
    padding = '3px',
    textVariant,
    disabled,
    children,
    loading,
    ...rest
  } = props

  const getTextVariant = (): TextVariantType => {
    switch (sizeVariant) {
      case 'small':
        return 'body/small'
      case 'large':
        return 'body/medium'
      default:
        return 'body/small'
    }
  }
  const getVariant = (): TextVariantType | TextVariantType[] => {
    if (Array.isArray(sizeVariant)) return sizeVariant.map(getTextVariant)
    else return getTextVariant()
  }

  return (
    <ButtonBase
      onClick={onClick}
      colorVariant={colorVariant}
      sizeVariant={sizeVariant}
      className={`${icon ? 'withImg' : ''} ${disabled ? 'disabled' : ''}`}
      disabled={disabled}
      padding={padding}
      isLoading={loading}
      {...rest}
    >
      {icon && <Icon src={icon} alt={iconAlt} marginRight="10px" {...iconProps} />}
      {label && (
        <Text
          variant={textVariant || getVariant()}
          fontWeight={
            getTextVariant() === 'body/small' || getTextVariant() === 'body/medium'
              ? 600
              : 'inherit'
          }
          color="inherit"
        >
          {label}
        </Text>
      )}
      {children}
    </ButtonBase>
  )
}

export const GoogleButton: FC<ButtonProps> = props => (
  <Button
    data-heap-id="GoogleButton"
    iconAlt="Google"
    {...props}
    icon={google}
    iconProps={{ marginLeft: '7px', top: 'calc(50% - 10px) !important' }}
    sizeVariant="mobileFullWidth"
    colorVariant="secondaryBlack"
  />
)
export const FacebookButton: FC<ButtonProps> = props => (
  <Button
    data-heap-id="FacebookButton"
    {...props}
    icon={facebook}
    iconAlt="Facebook"
    iconProps={{ marginLeft: '7px', top: 'calc(50% - 10px) !important' }}
    sizeVariant="mobileFullWidth"
    colorVariant="secondaryBlack"
  />
)

export const FAB: FC<FABButtonProps> = ({
  width = '60px',
  height = '60px',
  borderRadius = '8px',
  transition,
  transitionOuter,
  displayFAB = ['initial', 'initial', 'none'],
  ...props
}: FABButtonProps) => (
  <Box
    display={displayFAB}
    width={width}
    position="fixed"
    bottom="20px"
    right="20px"
    zIndex={4}
    transition={transitionOuter}
  >
    <Button
      width={width}
      height={height}
      borderRadius={borderRadius}
      transition={transition}
      {...props}
    >
      <Box justifyContent="center">
        <Icon src={plusIcon} alt="FAB Button" />
      </Box>
    </Button>
  </Box>
)

export const PrimaryButton: FC<ButtonProps> = props => (
  /*
    in new design system:
    - width should just be type-width + padding
    - height should just be type-height + padding
    - these values are overridden here for now
  */
  <Button
    textVariant="body/medium"
    colorVariant="primary"
    style={{ fontWeight: 700, margin: 0 }}
    height="auto"
    width="auto"
    py="10px"
    px="24px"
    {...props}
  />
)

export const SecondaryButton: FC<ButtonProps> = props => (
  /*
    in new design system:
    - width should just be type-width + padding
    - height should just be type-height + padding
    - these values are overridden here for now
  */
  <Button
    textVariant="body/medium"
    style={{ fontWeight: 'bold' }}
    colorVariant="secondary"
    height="auto"
    width="auto"
    py="10px"
    px="24px"
    backgroundColor={props.backgroundColor ?? 'transparent'}
    {...props}
  />
)

export const PageNavButton: FC<ButtonProps> = props => (
  <Button
    p="8px"
    colorVariant="pageNav"
    width="auto"
    height="auto"
    textVariant="body/small"
    style={{ fontWeight: 'bold' }}
    {...props}
  />
)

/**
 *
 * Generic button with default styling.
 *
 */
export const GenericButton: FC<ButtonProps> = props => (
  <Button colorVariant="invisible" height="auto" width="auto" p="0" m="0" {...props} />
)
