import { AUTHENTICATION_V2_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { Cookie } from '@dtx-company/true-common/src/utils/cookies'
import { Domains } from '@dtx-company/true-common/src/types/domains'
import {
  FronteggObserver,
  IthacaJwt,
  SignUpLocation,
  buildJwtFromFronteggAccessToken,
  clearCache,
  subscribeFronteggObserver,
  unsubscribeFronteggObserver
} from '@dtx-company/ithaca-sdk/src'
import { clearSSORefreshTokenFromLocalStorage } from '@dtx-company/true-common/src/utils/localStorage/clearSSORefreshTokenFromLocalStorage'
import {
  clearUserData,
  setCurrentUserData,
  setImpersonatedUserData
} from '../../redux/slices/user-slice'
import { flowcodeApi } from '../../redux/slices/flowcodeApiSlice/init'
import { flowpageApi } from '../../redux/slices/flowpageApiSlice/empty'
import { handleDatadogLogger, logger } from '@dtx-company/logger'
import { setLoggedInCookie } from '@dtx-company/true-common/src/utils/cookies/cookies.utils'
import { useCallback, useEffect } from 'react'
import { useCookies } from '@dtx-company/true-common/src/utils/cookies/cookies.hooks'
import { useDispatch } from 'react-redux'
import { useListenForImpersonationChanges } from './useListenForImpersonationChanges'
import { useRefreshTokenBeforeExpiration } from './useRefreshTokenBeforeExpiration'
import { useRestoreSessionFromFrontegg } from './useRestoreSession'
import { useRouter } from 'next/router'
import { useSWRConfig } from 'swr'
import Cookies from 'universal-cookie'
import events from '@dtx-company/inter-app/src/event-tracking/events/account'

const cookies = new Cookies()

function useFronteggHandleImpersonationAccessToken(): (
  impersonationAccessToken: string
) => Promise<void> {
  const dispatch = useDispatch()
  const onSignout = useOnSignout()
  return useCallback(
    async impersonationAccessToken => {
      try {
        const jwt = await buildJwtFromFronteggAccessToken(impersonationAccessToken)
        if (jwt) {
          dispatch(setImpersonatedUserData({ data: jwt }))
          dispatch(flowpageApi.util.resetApiState())
          dispatch(flowcodeApi.util.resetApiState())
        }
      } catch (e) {
        logger.logError(e, { technicalArea: 'authentication' })
        onSignout()
        return
      }
    },
    [dispatch, onSignout]
  )
}

const getTypedDomain = (_domain: string | undefined): Domains => {
  return _domain === 'flowpage' ? Domains.FLOWPAGE : Domains.FLOWCODE
}

async function recordReferral(friendId: string, referrerId: string): Promise<void> {
  try {
    const res = await fetch(`${AUTHENTICATION_V2_ENDPOINT}/referrals`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        referrerId,
        friendId
      })
    })
    if (!res.ok) {
      await logger.logFetchError(res, { team: 'monetization', technicalArea: 'authentication' })
    }
  } catch (e) {
    logger.logError(e, { team: 'monetization', technicalArea: 'authentication' })
  }
}

export function checkIfUserJustSignedUp(jwt: IthacaJwt, durationMinutes = 5): boolean {
  const durationMilliseconds = durationMinutes * 60 * 1000
  const nowInMilliseconds = Date.now()
  const userCreatedAtTimeInMilliseconds = new Date(jwt?.createdAt ?? 0).getTime()
  const timeSinceUserCreated = nowInMilliseconds - userCreatedAtTimeInMilliseconds
  return Boolean(jwt?.createdAt && timeSinceUserCreated < durationMilliseconds)
}

function recordSignup(
  jwt: IthacaJwt,
  domain: string | undefined,
  signUpLocation?: SignUpLocation
): void {
  events.userCreatedAccount(jwt?.email || '', getTypedDomain(domain), signUpLocation)
}

function useRecordReferral(): (newUserId: string) => Promise<void> {
  const router = useRouter()
  const referrerId = router.query.referrerId as string | undefined
  return useCallback(
    async newUserId => {
      if (referrerId) {
        await recordReferral(newUserId, referrerId)
      }
    },
    [referrerId]
  )
}

export function useHandleSignupSideEffects(): (
  newUserId: IthacaJwt,
  domain: string | undefined,
  signUpLocation?: SignUpLocation
) => Promise<void> {
  const recordReferral = useRecordReferral()
  const router = useRouter()
  const referrerId = router.query.referrerId as string | undefined
  return useCallback(
    async (newUserJwt, domain, signUpLocation) => {
      recordSignup(newUserJwt, domain, signUpLocation)
      if (referrerId) {
        await recordReferral(newUserJwt.ithacaId)
      }
    },
    [referrerId, recordReferral]
  )
}

export function useHandleFronteggAccessToken(): (
  accessToken: string,
  domain?: string,
  location?: SignUpLocation
) => Promise<void> {
  const dispatch = useDispatch()
  const onSignout = useOnSignout()
  const handleSignupSideEffects = useHandleSignupSideEffects()
  return useCallback(
    async (accessToken, domain, location) => {
      try {
        const jwt = await buildJwtFromFronteggAccessToken(accessToken)
        let justSignedUp = false

        if (!jwt) {
          throw new Error('handleFronteggAccessToken - JWT failed to build')
        } else {
          justSignedUp = checkIfUserJustSignedUp(jwt)
          if (justSignedUp) {
            await handleSignupSideEffects(jwt, domain, location)
          }
          dispatch(setCurrentUserData({ data: jwt, isNewUser: justSignedUp, isFronteggUser: true }))
          setLoggedInCookie(true)
          handleDatadogLogger(jwt.ithacaId)
        }
      } catch (e) {
        logger.logError(e, { technicalArea: 'authentication' })
        onSignout()
      }
    },
    [dispatch, handleSignupSideEffects, onSignout]
  )
}

export function useClearAuthState(): () => void {
  const onSignout = useOnSignout()
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(clearUserData())
    onSignout()
  }, [onSignout, dispatch])
}

export function useListenForAuthChanges(): void {
  useRestoreSessionFromFrontegg()
  useRefreshTokenBeforeExpiration()
  useListenForFronteggAuthChanges()
  useListenForImpersonationChanges()
}

export function useListenForFronteggAuthChanges(): void {
  const { optOut } = useCookies()

  const clearAuthState = useClearAuthState()
  const handleFronteggAccessToken = useHandleFronteggAccessToken()
  const handleFronteggImpersonationAccessToken = useFronteggHandleImpersonationAccessToken()
  useEffect(() => {
    const observer: FronteggObserver = {
      onLogin: handleFronteggAccessToken,
      onSignup: handleFronteggAccessToken,
      onRefresh: handleFronteggAccessToken,
      onLogout: clearAuthState,
      onImpersonation: handleFronteggImpersonationAccessToken
    }

    subscribeFronteggObserver(observer)

    return () => {
      unsubscribeFronteggObserver(observer)
    }
    //include optOut in dependency array to make sure we refresh the user when their cookies change
  }, [handleFronteggAccessToken, handleFronteggImpersonationAccessToken, optOut, clearAuthState])
}

export function useOnSignout(): () => void {
  const { mutate } = useSWRConfig()
  return useCallback(async () => {
    clearCache()

    mutate(() => true, undefined, { revalidate: false })

    clearSSORefreshTokenFromLocalStorage()

    cookies.remove(Cookie.token, { path: '/' })
    setLoggedInCookie(false)
  }, [mutate])
}
