import {
  DrawerContentMeta,
  LinkImageEditModalStateType,
  initialLinkEditorState
} from './linkEditorSlice.types'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const linkEditorSlice = createSlice({
  name: 'linkEdit',
  initialState: initialLinkEditorState,
  reducers: {
    setPreviewLink: (
      state,
      action: PayloadAction<{ previewLink: LinkType | null; isNew?: boolean }>
    ) => {
      state.previewLink = action.payload.previewLink
    },
    setIsNewPreviewLink: (state, action: PayloadAction<{ isNewPreviewLink: boolean }>) => {
      state.isNewPreviewLink = action.payload.isNewPreviewLink
    },
    setDrawerContent: (
      state,
      action: PayloadAction<{ drawerContentState: string; drawerContentMeta?: DrawerContentMeta }>
    ) => {
      state.drawerContentState = action.payload.drawerContentState
      state.drawerContentMeta = action.payload?.drawerContentMeta
    },
    clearDrawerContent: (state, _action) => {
      state.drawerContentState = null
      state.drawerContentMeta = null
      state.drawerOpen = false
    },
    clearPreviewLink: (state, _action) => {
      state.previewLink = null
      state.isNewPreviewLink = true
    },
    openLinkEditDrawer: (state, _action) => {
      state.drawerOpen = true
    },
    closeLinkEditDrawer: (state, _action) => {
      state.drawerOpen = false
      state.linkImageEditModalState = initialLinkEditorState.linkImageEditModalState
    },
    setImageEditModalState: (
      state,
      action: PayloadAction<Partial<LinkImageEditModalStateType>>
    ) => {
      const modalState = action.payload ?? {}
      state.linkImageEditModalState = {
        ...state.linkImageEditModalState,
        ...modalState
      }
    },
    clearImageEditModalState: state => {
      state.linkImageEditModalState = initialLinkEditorState.linkImageEditModalState
    }
  }
})

export const {
  setPreviewLink,
  openLinkEditDrawer,
  closeLinkEditDrawer,
  setDrawerContent,
  clearDrawerContent,
  setIsNewPreviewLink,
  clearPreviewLink,
  setImageEditModalState,
  clearImageEditModalState
} = linkEditorSlice.actions

export default linkEditorSlice.reducer
