import { MenuTarget } from '@dtx-company/inter-app/src/redux/slices/config-slice'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface SettingsStateType {
  hideFooter: boolean
  hideNavbar: boolean
  hideNavbarSignInOptions: boolean
  isLeftMenuOpen: boolean
  rightMenu: {
    isOpen: boolean
    target?: MenuTarget
  }
}

const initialState: SettingsStateType = {
  hideFooter: false,
  hideNavbar: false,
  hideNavbarSignInOptions: false,
  isLeftMenuOpen: true,
  rightMenu: {
    isOpen: false,
    target: MenuTarget.ACCOUNT
  }
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLeftMenu: (state, action: PayloadAction<{ open: boolean }>) => {
      state.isLeftMenuOpen = action.payload.open
      if (state.rightMenu.isOpen) state.rightMenu.isOpen = false
    },
    setRightMenu: (state, action: PayloadAction<{ open: boolean; target?: MenuTarget }>) => {
      state.rightMenu.isOpen = action.payload.open
      state.rightMenu.target = action.payload.target
    },
    setHideFooter: (state, action: PayloadAction<boolean>) => {
      state.hideFooter = action.payload
    },
    setHideNavbar: (state, action: PayloadAction<boolean>) => {
      state.hideNavbar = action.payload
    },
    setHideNavbarSignInOptions: (state, action: PayloadAction<boolean>) => {
      state.hideNavbarSignInOptions = action.payload
    }
  }
})

export const {
  setLeftMenu,
  setRightMenu,
  setHideFooter,
  setHideNavbar,
  setHideNavbarSignInOptions
} = settingsSlice.actions

export default settingsSlice.reducer
