export const getFlowpageHost = (shouldUseFlowcodeDomain?: boolean): string => {
  if (shouldUseFlowcodeDomain) {
    return `${getFlowcodeHost()}/page`
  }

  switch (process.env.APP_ENV) {
    case 'development':
    case 'staging':
      return `stg.flow.page`
    case 'preprod':
      return `preprod.flow.page`
    case 'production':
    default:
      return `flow.page`
  }
}

export const getFlowcodeHost = (): string => {
  switch (process.env.APP_ENV) {
    case 'development':
    case 'staging':
      return `stg.flowcode.com`
    case 'preprod':
      return `preprod.flowcode.com`
    case 'production':
    default:
      return `www.flowcode.com`
  }
}

export const getFlowcodeHostPrefix = (): string => {
  switch (process.env.APP_ENV) {
    case 'development':
    case 'staging':
      return 'stg.'
    case 'preprod':
      return 'preprod.'
    case 'production':
    default:
      return ''
  }
}
