import { AuthCacheProvider } from '@app/authentication/AuthCache/context'
import { CaptureUserAuthenticated } from './CaptureUserAuthenticated'
import { DialogTransitionProvider } from '@dtx-company/design-system/src/components/Dialog/DialogTransition/DialogTransitionProvider'
import { FC, ReactNode } from 'react'
import { FullPageErrorFallback } from '@dtx-company/inter-app/src/components/PageErrorFallback'
import { GooglePlaceAutocompleteGlobalStyles } from './GooglePlaceAutocompleteGlobalStyles'
import { IntercomProvider } from './IntercomProvider'
import { LazyFeatureBundle, LazyMotion } from 'framer-motion'
import { ThemeProvider as LegacyThemeProvider } from 'styled-components'
import { LockedFeatureModalProvider } from '../../../shared/common/src/components/LockedFeatureModal/context/LockedFeatureModalProvider'
import { LoggerErrorBoundary } from '@dtx-company/logger'
import { ReduxProvider } from './ReduxProvider'
import { ThemeProvider } from '@dtx-company/design-system/src/components/ThemeProvider/ThemeProvider'
import { TrackingLog } from './TrackingLog'
import legacyTheme from '@app/common/src/theme'

/* istanbul ignore next */
const loadFeatures: LazyFeatureBundle = () =>
  import('../../../utils/framer').then(res => res.default)

export const AppProviders: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <LoggerErrorBoundary options={{ team: 'unknown' }} fallback={<FullPageErrorFallback />}>
      <ThemeProvider>
        <LegacyThemeProvider theme={legacyTheme}>
          <IntercomProvider>
            <LazyMotion features={loadFeatures}>
              <DialogTransitionProvider>
                <ReduxProvider>
                  <LockedFeatureModalProvider>
                    <CaptureUserAuthenticated />
                    <AuthCacheProvider>{children}</AuthCacheProvider>
                  </LockedFeatureModalProvider>
                </ReduxProvider>
                <TrackingLog />
                <GooglePlaceAutocompleteGlobalStyles />
              </DialogTransitionProvider>
            </LazyMotion>
          </IntercomProvider>
        </LegacyThemeProvider>
      </ThemeProvider>
    </LoggerErrorBoundary>
  )
}
//
