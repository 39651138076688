import { FlowcodeDerivedValues, FlowcodeType } from '@dtx-company/inter-app/src/types/flowcode'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface CodeDetailsPageState {
  flowcode: FlowcodeState
  currentUser: CurrentUser
}

export interface FlowcodeState {
  data?: FlowcodeType
  isViewOnlyAccess?: boolean
  derivedValues?: FlowcodeDerivedValues
  owner?: CodeOwner
  hasLoaded: boolean
  batchId?: string
}

export interface CodeOwner {
  fullName?: string
  entityId?: string
}

export interface CurrentUser {
  deprecated_isEnterpriseUser?: boolean
  isOwner?: boolean
}

export const initialState: CodeDetailsPageState = {
  flowcode: {
    hasLoaded: false
  },
  currentUser: {}
}

const codeDetailsPageSlice = createSlice({
  name: 'codeDetailsPage',
  initialState,
  reducers: {
    updateCurrentUser: (state, action: PayloadAction<CurrentUser>) => {
      state.currentUser = action.payload
    },
    updateFlowcode: (state, action: PayloadAction<FlowcodeState>) => {
      state.flowcode = action.payload
    }
  }
})

export const { updateCurrentUser, updateFlowcode } = codeDetailsPageSlice.actions

export default codeDetailsPageSlice.reducer
