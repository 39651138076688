import { OptimizelyContext, OptimizelyContextProps } from './context'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { useContext } from 'react'
import { useRouter } from 'next/router'

export function useOptimizelyContext(): OptimizelyContextProps | undefined {
  const context = useContext(OptimizelyContext)
  const router = useRouter()
  if (!context && router.pathname !== Routes.FLOWPAGE) {
    throw new Error('useOptimizelyContext must be used within OptimizelyProvider')
  }
  return context
}
