import isPlainObject from 'lodash/isPlainObject'
import mapValues from 'lodash/mapValues'

export const objectMap = <T>(
  obj: Record<string, T>,
  fn: (v: T, k: string, i: number) => T
): Record<string, T> => Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]))

export function objectMapDeep(
  obj: Record<string, unknown>,
  mapper: (_: Record<string, unknown>) => Record<string, unknown>
): Record<string, unknown> {
  return mapper(
    mapValues(obj, function (v: unknown) {
      return isPlainObject(v) ? objectMapDeep(v as Record<string, unknown>, mapper) : v
    })
  )
}
export function unwrapOrThrow<T>(obj: T | null | undefined, msg: string | null = null): T {
  if (!obj) {
    throw Error(msg ? msg : 'Unwrapped object was null or undefined')
  }
  return obj as T
}

export function removeUndefined<T>(obj: T | null | undefined): T | null {
  if (!obj) {
    return null
  }
  return obj as T
}

export function removeNull<T>(obj: T | null | undefined): T | undefined {
  if (!obj) {
    return undefined
  }
  return obj as T
}

export function switchNull<T>(obj: T | null): T | undefined {
  if (!obj) {
    return undefined
  }
  return obj as T
}

export function switchUndefined<T>(obj: T | undefined): T | null {
  if (!obj) {
    return null
  }
  return obj as T
}

export function removeNullsOnOptional<T>(arr: (T | null)[] | null | undefined): T[] | null {
  return arr ? (arr.filter(element => element !== null) as T[]) : null
}

export function removeNullsUndefinedOnOptional<T>(
  arr: (T | null)[] | null | undefined
): T[] | null {
  return arr ? (arr.filter(element => element !== null && element !== undefined) as T[]) : null
}
export function removeNulls<T>(arr: (T | null)[]): T[] {
  return arr.filter(element => element !== null) as T[]
}

type Key = string | number | symbol

function hasKey<O extends Record<string, unknown>>(obj: O, key: Key): key is keyof O {
  return key in obj
}

export function indexInterfaceWithVar<T>(
  obj: T | null | undefined,
  key: Key
): NonNullable<T>[keyof NonNullable<T>] | undefined {
  if (obj == null) {
    return undefined
  }
  if (hasKey(obj, key)) {
    return obj[key]
  }
  return undefined
}

export function indexInterfaceWithVarOrThrow<T>(
  obj: T,
  key: Key
): NonNullable<T>[keyof NonNullable<T>] {
  const v = indexInterfaceWithVar(obj, key)
  if (v) {
    return v
  }
  throw Error(`Key ${String(key)} not in obj`)
}
