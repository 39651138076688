import { AddressProperties, Form, Page, PageTheme, getFieldKey } from '../../types/form.types'
import {
  Choice,
  ChoiceFieldProperties,
  ChoiceType,
  FieldProperties,
  FieldType,
  ImageBlockProperties,
  LegalFieldProperties,
  Field as ProtosField,
  TextFieldProperties
} from '@dtx-company/connect-codegen/src/gen/forms/v1/field_pb'
import { Component } from '@dtx-company/connect-codegen/src/gen/pages/v2/component_pb'
import { ConnectError, createPromiseClient } from '@bufbuild/connect'
import {
  CreatePageRequest,
  Page as ProtosPage,
  UpdatePageRequest
} from '@dtx-company/connect-codegen/src/gen/pages/v2/page_pb'
import { DateRange } from '@dtx-company/connect-codegen/src/gen/forms/v1/options_pb'
import { FieldMask, JsonObject, PlainMessage, Struct, Timestamp } from '@bufbuild/protobuf'
import { FormsService } from '@dtx-company/connect-codegen/src/gen/forms/v1/forms_connect'
import { PageService } from '@dtx-company/connect-codegen/src/gen/pages/v2/page_connect'
import {
  Answer as ProtoAnswer,
  SubmissionRow
} from '@dtx-company/connect-codegen/src/gen/forms/v1/form_submission_pb'
import { Form as ProtosForm } from '@dtx-company/connect-codegen/src/gen/forms/v1/forms_pb'
import { StaticAssetsService } from '@dtx-company/connect-codegen/src/gen/staticassets/v1/static_assets_connect'
import { SuccessAction } from '@dtx-company/connect-codegen/src/gen/forms/v1/success_screen_pb'
import { createConnectTransport } from '@bufbuild/connect-web'
import {
  getFormsApiRootUrl,
  getPagesV2RootUrl,
  getRegistryRootUrl,
  getStaticAssetsRootUrl
} from '@dtx-company/true-common/src/utils/urls/services'

export const protosToForm = (form: ProtosForm): Form => {
  const fields = form.fields.map(f => {
    const field = {
      id: f.id,
      fieldType: f.fieldType,
      name: f.name,
      title: f.title,
      autoGeneratedKey: getFieldKey(f.fieldType),
      description: f.description,
      active: f.active,
      required: f.required,
      properties: {}
    }
    if (f.fieldType == FieldType.LEGAL) {
      const termProps = f.properties?.properties?.value as LegalFieldProperties
      field.properties = {
        legal: {
          termsLink: termProps?.termsLink ?? '',
          disclaimer: termProps?.disclaimer ?? ''
        }
      }
    }
    if (f.fieldType == FieldType.BLOCK_IMAGE) {
      const imageProps = f.properties?.properties?.value as ImageBlockProperties
      field.properties = {
        image: {
          imageUrl: imageProps?.imageUrl
        }
      }
    }
    if (f.fieldType == FieldType.CHOICE) {
      const choiceProperties = f.properties?.properties?.value as ChoiceFieldProperties
      const choices = choiceProperties?.choices?.map(c => {
        return { value: c.value }
      })
      field.properties = {
        choice: {
          choiceType: choiceProperties?.choiceType ?? ChoiceType.CHECKBOX,
          allowOther: choiceProperties?.allowOther,
          choices: choices ?? []
        }
      }
    }
    if (f.fieldType == FieldType.POLL) {
      const choiceProperties = f.properties?.properties?.value
      let choices: { value: string }[] = []
      let allowOther
      if (choiceProperties !== undefined) {
        if ('choices' in choiceProperties) {
          choices = choiceProperties.choices.map(c => {
            return { value: c.value }
          })
        }
        if ('allowOther' in choiceProperties) {
          allowOther = choiceProperties.allowOther
        }
      }
      field.properties = {
        choice: {
          choiceType: ChoiceType.RADIO,
          allowOther,
          choices
        }
      }
    }
    if (f.fieldType == FieldType.TEXT) {
      const textProperties = f.properties?.properties?.value as TextFieldProperties
      const isLongText = textProperties?.paragraph
      if (isLongText) {
        field.properties = {
          textField: {
            paragraph: true
          }
        }
      }
    }
    return field
  })

  return {
    id: form.id,
    title: form.title,
    description: form.description,
    active: form.active,
    successAction:
      form.successAction && form.successAction.action?.case
        ? (form.successAction?.toJson() as Record<string, any>)
        : defaultSuccessAction,
    fields,
    fieldsOrder: [...fields.keys()],
    createdAt: form.createdAt?.toDate().toISOString(),
    updatedAt: form.updatedAt?.toDate().toISOString()
  }
}

export const formToProtos = (form: Form): PlainMessage<ProtosForm> => {
  return formToProto(form)
}

export const formToProto = (form: Form): PlainMessage<ProtosForm> => {
  const fields: ProtosField[] = form.fieldsOrder.map(index => {
    const field = form.fields[index]
    const protosField = {
      id: field.id,
      fieldType: field.fieldType,
      title: field.title,
      description: field.description,
      active: field.active,
      name: field.name,
      required: field.required
    } as ProtosField
    if (field.fieldType == FieldType.CHOICE) {
      const choices = field.properties.choice?.choices?.map(c => {
        return { ...c } as Choice
      })
      protosField.properties = {
        properties: {
          case: 'choice',
          value: {
            choiceType: field.properties.choice?.choiceType ?? ChoiceType.CHECKBOX,
            choices: choices ?? ([] as Choice[]),
            allowOther: true //TODO: set this from props
          } as ChoiceFieldProperties
        }
      } as FieldProperties
    }
    if (field.fieldType == FieldType.POLL) {
      const choices = field.properties.choice?.choices?.map(c => {
        return { ...c } as Choice
      })
      protosField.properties = new FieldProperties({
        properties: {
          case: 'choice',
          value: {
            choiceType: ChoiceType.RADIO,
            choices: choices ?? ([] as Choice[]),
            allowOther: true //TODO: set this from props
          } as ChoiceFieldProperties
        }
      })
    }
    if (field.fieldType == FieldType.TEXT) {
      protosField.properties = {
        properties: {
          case: 'textField',
          value: {
            paragraph: field.properties?.textField?.paragraph ?? false
          } as TextFieldProperties
        }
      } as FieldProperties
    }
    if (field.fieldType == FieldType.LEGAL) {
      protosField.properties = {
        properties: {
          case: 'legal',
          value: {
            termsLink: normalizeUrl(field.properties?.legal?.termsLink ?? ''),
            disclaimer: field.properties?.legal?.disclaimer ?? ''
          } as LegalFieldProperties
        }
      } as FieldProperties
    }
    if (field.fieldType == FieldType.BLOCK_IMAGE) {
      protosField.properties = {
        properties: {
          case: 'image',
          value: {
            imageUrl: normalizeUrl(field.properties?.image?.imageUrl ?? '')
          } as ImageBlockProperties
        }
      } as FieldProperties
    }

    return protosField
  })
  return {
    id: form.id,
    title: form.title ?? 'Form',
    description: form.description ?? '',
    active: form.active,
    successAction: form.successAction
      ? SuccessAction.fromJson(form.successAction as JsonObject)
      : SuccessAction.fromJson({}),
    fields
  }
}

export type FormConfig = {
  formTitle?: {
    text: string
  }
  formDescription?: {
    text: string
  }
  [key: string]: any
}

export const filterComponents = (page: Page): PlainMessage<Component>[] => {
  const hasBackground = page.pageComponents?.some(c => c.id == PageFieldName.PAGE_BACKGROUND)
  let components = page.pageComponents?.map(c => Component.fromJson(c)) ?? []
  if (!hasBackground) {
    components = [backgroundComponent as Component, ...components]
  }
  return components
}
export type BackgroundConfig = {
  imageSrc?: string
  darken?: boolean
  backgroundColor?: string
  [key: string]: any
}

export const getBackgroundConfFromPage = (page: Page): BackgroundConfig => {
  const pageConfig = { ...page.config }
  const backgroundField = page.pageBackgroundId ?? PageFieldName.PAGE_BACKGROUND
  return {
    imageSrc: pageConfig[backgroundField]?.imageSrc
      ? normalizeUrl(pageConfig[backgroundField]?.imageSrc)
      : '',
    darken: page?.config?.background?.darken ? true : false,
    backgroundColor: page?.config?.background?.backgroundColor ?? defaultBackgroundColor
  }
}

export const pageToProtos = (page: Page): Partial<UpdatePageRequest> => {
  const pageConfig = { ...page.config }
  const backgroundField = page.pageBackgroundId ?? PageFieldName.PAGE_BACKGROUND
  pageConfig[backgroundField] = getBackgroundConfFromPage(page)

  pageConfig[page.formContentId ?? PageFieldName.CONTENT] = {
    sx: {
      backgroundColor: page.theme?.palette?.secondary?.main ?? 'white'
    }
  }

  const components = filterComponents(page)
  return {
    page: {
      id: page.id,
      orgId: '',
      workspaceId: '',
      state: '',
      folderPath: '',
      name: page.name ?? '',
      tags: page.tags ?? [],
      theme: Struct.fromJson(page.theme),
      config: Struct.fromJson(pageConfig ?? {}),
      components: components
    } as ProtosPage,
    mask: { paths: ['name', 'tags', 'theme', 'config', 'components'] } as FieldMask
  }
}

export const getTypeUri = (uri: string): string => `${getRegistryRootUrl()}${uri}`

export const protosToPage = (page: ProtosPage): Page => {
  //Ignore versions
  const pageWrapperUri = getTypeUri(PageContentType.PAGE_WRAPPER.slice(0, -5))
  const backgroundUri = getTypeUri(PageContentType.BACKGROUND.slice(0, -5))
  const contentTypeUri = getTypeUri(PageContentType.CONTENT.slice(0, -5))
  const formTypeUri = getTypeUri(PageContentType.FORM.slice(0, -5))
  const config = (page.config?.toJson() as FormConfig) ?? {}
  config.formTitle = { text: config.formTitle?.text ?? '' }
  config.formDescription = { text: config.formDescription?.text ?? '' }

  const formContent = page.components
    ?.find(c => c.typeUri?.startsWith(pageWrapperUri))
    ?.children?.find(c => c.typeUri?.startsWith(contentTypeUri))

  const background = page.components?.find(c => c.typeUri?.startsWith(backgroundUri))

  const formComponent = formContent?.children?.find(c => c.typeUri?.startsWith(formTypeUri))
  const formComponentId = formComponent ? formComponent.id : PageFieldName.FORM
  const formContentId = formContent ? formContent.id : PageFieldName.CONTENT
  const pageBackgroundId = background ? background.id : PageFieldName.PAGE_BACKGROUND

  const theme = page.theme ? (page.theme?.toJson() as PageTheme) : defaultTheme
  if (!theme.palette) {
    theme.palette = defaultPaletteConfig
  }
  if (!theme.components) {
    theme.components = defaultComponentsConfig
  }
  if (!theme.typography) {
    theme.typography = defaultTextStyleConfig
  }
  if (!theme.palette?.primary) {
    theme.palette.primary = { main: defaultPrimaryColor }
  }
  if (!theme.palette?.secondary) {
    theme.palette.secondary = {
      main: config?.[formContentId]?.sx?.backgroundColor ?? defaultPrimaryColor
    }
  }
  if (!theme?.typography?.fontFamily) {
    theme.typography = { fontFamily: defaultFontFamily }
  }
  if (!theme.components?.MuiTextField?.defaultProps) {
    theme.components.MuiTextField = { defaultProps: { variant: defaultTextVariant } }
  }
  if (!theme?.components?.MuiButton?.defaultProps) {
    theme.components.MuiButton = { defaultProps: { variant: defaultButtonVariant } }
  }
  return {
    id: page.id,
    name: page.name,
    tags: page.tags,
    theme: theme,
    config: config,
    createdAt: page.createdAt?.toDate().toISOString(),
    updatedAt: page.updatedAt?.toDate().toISOString(),
    formBackgroundColor: config?.[pageBackgroundId]?.backgroundColor ?? defaultBackgroundColor,
    pageComponents: page.components.map(c => c.toJson()) as Record<string, any>[],
    formComponentId: formComponentId,
    formContentId: formContentId,
    pageBackgroundId: pageBackgroundId
  } as Page
}

export const protosToSubmission = (row: SubmissionRow): { [key: string]: any } => {
  const results: { [key: string]: any } = {
    createdAt: row.createdAt?.toDate()?.toISOString(),
    id: row.id
  }
  for (const id in row.answers) {
    const ans: ProtoAnswer = row.answers[id]
    switch (ans.answer.case) {
      case 'textAnswer':
        results[id] = ans.answer.value.values.map(v => v.value)
        break
      case 'addressAnswer':
        results[id] = { ...ans.answer.value } as AddressProperties
        break
    }
  }
  return results
}

export const formsClient = createPromiseClient(
  FormsService,
  createConnectTransport({
    baseUrl: getFormsApiRootUrl(),
    jsonOptions: {
      ignoreUnknownFields: true
    }
  })
)

export const pagesClient = createPromiseClient(
  PageService,
  createConnectTransport({
    baseUrl: getPagesV2RootUrl(),
    jsonOptions: {
      ignoreUnknownFields: true
    }
  })
)

export const assetClient = createPromiseClient(
  StaticAssetsService,
  createConnectTransport({
    baseUrl: getStaticAssetsRootUrl(),
    jsonOptions: {
      ignoreUnknownFields: true
    }
  })
)

export type ErrorResponse = {
  status: number
  message: string
}

export const getErrorResponse = (err: Error): ErrorResponse => {
  return {
    status: err instanceof ConnectError ? err.code : 500,
    message: err?.message ?? 'Unknown error'
  }
}

export const toDateRange = (
  startDate: string | undefined,
  endDate: string | undefined
): DateRange => {
  return {
    after: startDate ? Timestamp.fromDate(new Date(startDate)) : undefined,
    before: endDate ? Timestamp.fromDate(new Date(endDate)) : undefined
  } as DateRange
}

export const ERROR_MSG_MISSING_VALUE = 'Missing field value'
export const ERROR_MSG_INVALID_VALUE = 'Invalid field value'

export type Phone = {
  countryCode?: any
  value: string
}

export enum PageContentType {
  BACKGROUND = '/api/v1/components/PageBackground/1.0.0',
  PAGE_WRAPPER = '/api/v1/components/PageWrapper/1.0.2',
  CONTENT = '/api/v1/components/FloatingContent/1.0.1',
  FORM_TITLE = '/api/v1/components/Header/1.0.5',
  FORM_DESCRIPTION = '/api/v1/components/FormSubheader/1.0.4',
  FORM = '/api/v1/components/Form/1.0.2',
  BOX = '/api/v1/components/Box/1.0.0'
}

export enum PageFieldName {
  PAGE_BACKGROUND = 'background',
  FORM_TITLE = 'formTitle',
  FORM_DESCRIPTION = 'formDescription',
  FORM = 'form',
  CONTENT = 'content',
  PAGE_WRAPPER = 'pageWrapper'
}

export const defaultComponents = (formComponentId: string = PageFieldName.FORM): JsonObject => {
  return {
    id: PageFieldName.PAGE_WRAPPER,
    typeUri: getTypeUri(PageContentType.PAGE_WRAPPER),
    children: [
      {
        id: PageFieldName.CONTENT,
        typeUri: getTypeUri(PageContentType.CONTENT),
        children: [
          newComponent(PageFieldName.FORM_TITLE, getTypeUri(PageContentType.FORM_TITLE), []),
          newComponent(
            PageFieldName.FORM_DESCRIPTION,
            getTypeUri(PageContentType.FORM_DESCRIPTION),
            []
          ),
          newComponent('spacer', getTypeUri(PageContentType.BOX), [], {
            mb: '16px'
          }),
          newComponent(formComponentId, getTypeUri(PageContentType.FORM), [])
        ]
      }
    ]
  } as JsonObject
}

const newComponent = (
  id: string,
  typeUri: string,
  children: Component[],
  style: any = {}
): PlainMessage<Component> => {
  return {
    id: id,
    typeUri: typeUri,
    style: Struct.fromJson(style),
    children: children
  } as PlainMessage<Component>
}

export const defaultPrimaryColor = '#000000'
export const defaultSecondaryColor = '#FFFFFF'
export const defaultBackgroundColor = '#FFFFFF'
export const defaultButtonVariant = 'contained'
export const defaultTextVariant = 'outlined'
export const defaultFontFamily = 'Inter'

export const backgroundComponent = newComponent(
  PageFieldName.PAGE_BACKGROUND,
  getTypeUri(PageContentType.BACKGROUND),
  [],
  {
    darken: false
  }
) as Component

export const getDefaultPageRequest = (orgId: string): PlainMessage<CreatePageRequest> => {
  const components = Component.fromJson(defaultComponents(PageFieldName.FORM))
  return {
    name: defaultPageName,
    orgId: orgId,
    workspaceId: '',
    state: '',
    folderPath: '',
    tags: [],
    config: Struct.fromJson({
      form: {}
    }),
    theme: Struct.fromJson(defaultTheme),
    components: [components]
  } as PlainMessage<CreatePageRequest>
}

export const defaultPaletteConfig = {
  primary: { main: defaultPrimaryColor },
  secondary: { main: defaultSecondaryColor }
}
export const defaultComponentsConfig = {
  MuiButton: {
    defaultProps: {
      variant: defaultButtonVariant
    }
  },
  MuiTextField: {
    defaultProps: {
      variant: defaultTextVariant
    }
  }
}

export const defaultTextStyleConfig = {
  fontFamily: defaultFontFamily
}
export const defaultTheme: PageTheme = {
  palette: defaultPaletteConfig,
  typography: defaultTextStyleConfig,
  components: defaultComponentsConfig
}

export const defaultPageName = 'Untitled'
export const pageNamePlaceHolder = 'Name your Form'
export const defaultPageTitle = 'Click to add a title'
export const defaultPageDescription = 'Click to add description'

export const defaultFormConfig = {
  formTitle: undefined,
  formDescription: undefined,
  form: {
    id: ''
  }
}

export const DEFAULT_FORM_ID = 'default'

export const enum ConfirmationAction {
  THANK_YOU = 'successScreen',
  REDIRECT = 'redirect',
  VIEW_LIVE_RESULTS = 'viewLiveResult'
}

export const CONFIRMATION_THANK_YOU_TITLE = 'Thank You!'
export const CONFIRMATION_THANK_YOU_DESCRIPTION = 'Your submission has been received.'

export const defaultSuccessAction = {
  [ConfirmationAction.THANK_YOU]: {
    title: CONFIRMATION_THANK_YOU_TITLE,
    description: CONFIRMATION_THANK_YOU_DESCRIPTION
  }
}

export const normalizeUrl = (url: any): any => {
  if (url) {
    return url.toLowerCase().startsWith('http') ? url : `https://${url}`
  }
  return url
}
