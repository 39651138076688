import { baseRGB, withAlpha } from './palettes/lightPalette'
import { spacing } from './spacing'
import type { Shadows } from '@mui/material/styles'

const baseShadows = {
  none: 'none' as const,
  1: `0 ${spacing(0.5)} ${spacing(2)} ${withAlpha(baseRGB.primary, 0.08)}, 0 ${spacing(
    0.125
  )} ${spacing(0.5)} ${withAlpha(baseRGB.primary, 0.12)}`,
  2: `0 ${spacing(0.25)} ${spacing(0.875)} ${withAlpha(baseRGB.primary, 0.12)}, 0 ${spacing(
    1.5
  )} ${spacing(3.625)} ${withAlpha(baseRGB.primary, 0.16)}`,
  3: `0 ${spacing(1.25)} ${spacing(3.75)} ${withAlpha(baseRGB.primary, 0.18)}, 0 ${spacing(
    6.5
  )} ${spacing(14.5)} ${withAlpha(baseRGB.primary, 0.22)}`
}

export const shadows = [
  baseShadows['none'],
  baseShadows[1],
  baseShadows[2],
  /**
   * MUI expects to have exactly 25 shadows, whereas our design system only specifies 4.
   * We therefore repeat the darkest shadow to fill the remaining shadows.
   */
  ...Array(22).fill(baseShadows[3])
] as Shadows
