import { Cookie } from '@dtx-company/true-common/src/types/cookies'
import {
  getCookie,
  getCookieDomain
} from '@dtx-company/true-common/src/utils/cookies/cookies.utils'
import { setBrowserSessionIdLoaded } from '@dtx-company/inter-app/src/redux/slices/cookie'
import { useCookieState } from '@dtx-company/inter-app/src/redux/selectors/useCookieState'
import { useCookies } from '@dtx-company/true-common/src/utils/cookies/cookies.hooks'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import {
  useIsEU,
  useIsEULoaded
} from '@dtx-company/inter-app/src/redux/selectors/currentSessionSelectors'

/**
 * Functional cookie used to identify an anonymous user. This can be forwarded to third party services after a user has consented to functional cookies.
 */
export const useBrowserSessionCookie = (sessionId: string): void => {
  const isEU = useIsEU()
  const isEULoaded = useIsEULoaded()
  const { cookiePreferences } = useCookieState()
  const { optOut, setCookie } = useCookies()
  const dispatch = useDispatch()
  const disableBrowserCookie = optOut || !isEULoaded || (isEU && !cookiePreferences.C0003)

  useEffect(() => {
    const setBrowserCookie = async (): Promise<void> => {
      try {
        if (disableBrowserCookie) return

        const browserSessionId = getCookie(Cookie.BROWSER_SESSION_ID)
        if (!browserSessionId) {
          const domain = getCookieDomain()
          setCookie(Cookie.BROWSER_SESSION_ID, sessionId, { path: '/', domain })
        }

        dispatch(setBrowserSessionIdLoaded(true))
      } catch (e) {
        console.error('error setting browser session cookie', e)
      }
    }

    setBrowserCookie()
  }, [disableBrowserCookie, sessionId, setCookie, dispatch])
}
