import { HeapTrackerEventPropertyMap } from './types/HeapTrackerEventPropertyMap'
import { getHeapTrackerPlanTypeString } from './utils/getHeapTrackerPlanTypeString'
import { getHeapTrackerUserOrgRoleString } from './utils/getHeapTrackerUserOrgRoleString'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useBillingPlanType } from '@app/common/src/hooks/useBillingPlanType/useBillingPlanType'
import { useEffect } from 'react'
import { useHeap } from '@dtx-company/inter-app/src/event-tracking/hooks/useHeap/useHeap'
import { useInitialWindowSize } from '@app/common/src/hooks/useInitialWindowSize'
import { useIsBillingAdmin } from '@dtx-company/inter-app/src/hooks/useIsBillingAdmin/useIsBillingAdmin'
import { useIsFlowpage } from '../../../../hooks/useIsFlowpage/useIsFlowpage'
import { useOptimizelyClient } from '@dtx-company/inter-app/src/hooks/useOptimizelyClient'

/**
 * Handles updating and clearing event properties.
 *
 * NOTE: **ALL** event properties should be included here. They are stored in a
 * cookie (4kb limit) so they need to be cleared and managed appropriately.
 * If different places are all setting different properties it becomes hard
 * to clear them without losing some.
 *
 * If we need to do event properties from elsewhere in the future, I think
 * if we had something like `heap.clearAllProperties()` on login/logout and
 * wrote our own function to do `removeMatching(/^(feature|experiment)__/)`
 * in the future then we could, but seems simplest to keep everything together here.
 */
export function useHeapTrackerEventProperties(): void {
  const heap = useHeap()
  const isFlowpage = useIsFlowpage()
  const authState = useAuthState()
  const isBillingAdmin = useIsBillingAdmin() // only uses props from useAuthState
  const billingPlanType = useBillingPlanType()
  const optimizelyClient = useOptimizelyClient()
  const windowSize = useInitialWindowSize()

  useEffect(() => {
    if (isFlowpage || !heap || !authState.isAuthChecked) return
    const properties: HeapTrackerEventPropertyMap = {
      is_logged_in: authState.isAuthenticated,
      organization_name: authState.jwt?.org?.name,
      organization_role: getHeapTrackerUserOrgRoleString(authState.org?.userOrgRole),
      organization_status: authState.jwt?.org?.status,
      billing_plan_type: billingPlanType
        ? getHeapTrackerPlanTypeString(billingPlanType)
        : undefined,
      is_billing_admin: isBillingAdmin,
      window_inner_width: windowSize.width,
      window_inner_height: windowSize.height
      /*
      These raise some issues with marking all the features/experiments as having had
      a "visitor" in optimizely so we need to think through this more.
      I don't want to delete them and lose the code in case we bring them back so
      commenting out with a note and will be re-evaluate with another story shortly. 
      ...getHeapTrackerFeatureFlagProperties(optimizelyClient),
      ...getHeapTrackerExperimentProperties(optimizelyClient)
      */
    }

    heap.clearEventProperties()
    heap.addEventProperties(properties)
  }, [
    isFlowpage,
    heap,
    authState.isAuthChecked,
    authState.isAuthenticated,
    authState.org?.userOrgRole,
    authState.org?.userOrgRoleExtras,
    authState.jwt?.org?.name,
    authState.jwt?.org?.status,
    billingPlanType,
    isBillingAdmin,
    windowSize.width,
    windowSize.height,
    optimizelyClient
  ])
}
