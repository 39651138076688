import { GlobalStyles, Theme } from '@mui/material'

const INTERCOM_DESKTOP_MIN_WIDTH = 451 // the size at which intercom swaps to desktop mode (DO NOT CHANGE)

function createStyles(theme: Theme): string {
  const offset = 0 // amount to add to the bottom position for mobile + desktop (intercom setting only works on desktop)

  /*
    BE CAREFUL

    We're overriding styles in an unsupported way in order to get a quick solution
    to enabling intercom without it overlapping our UI elements.

    Long-term, we should build a real intercom component into our app and use their api
    instead of their provided Floating Action Button (FAB) for interacting with their
    messenger client.
  */

  return `
    /*
      The "lightweight" app is a stub that's loaded with minimal code to provide the look and
      feel of the real app.
      Once it's clicked, there's a transition while it loads and then the real app pops into
      place and it's removed from the DOM.
      Any positioning must be applied to both separately (and slightly differently) to
      ensure they occupy the same space.
    */
    .intercom-lightweight-app {
      && {
        /*
          This stubs the Floating Action Button in the bottom-right corner.
        */
        .intercom-lightweight-app-launcher {
          bottom: ${20 + offset}px;
  
          display: none;
          ${theme.breakpoints.up('md')} {
            display: block;
          }
        }
  
        /*
          This stubs the messenger window and it initially loads and animates
          with a background and no content. Once it's full size, the real one pops over it.
  
          NOTE: the real one fills the screen on mobile, but the stub still floats like
          the desktop version does - seems like a bug on intercom's part but keeping
          it consistent here.
  
          NOTE2: this isn't created until the button is clicked, and it's removed moments
          later after the animation finishes. If you need to grab it, I suggest
          setting up a DOM monitor like here:
          https://stackoverflow.com/questions/3219758/detect-changes-in-the-dom
        */
        .intercom-lightweight-app-messenger {
          bottom: ${84 + offset}px;
          height: min(${704 - offset}px, 100% - ${104 + offset}px);
        }
      }
    }
  
    /*
      The namespace applies to the real app.
    */
    .intercom-namespace {
      && {
        /*
          The launcher frame is the iframe, its parent is what currently has positioning
          but its parent has a dynamic css class assigned like .intercom-#{guid} so
          there's not a great way to target it directly since there's nothing distinct about
          it.
        */
        *:has(> .intercom-launcher-frame) {
          bottom: ${20 + offset}px;
  
          display: none;
          ${theme.breakpoints.up('md')} {
            display: block;
          }
        }
  
        /*
          Inconsistently, the messenger frame is not the actual iframe but its parent.
          It's positioned independent of the launcher frame.
        */
        .intercom-messenger-frame {
          @media (min-width: ${INTERCOM_DESKTOP_MIN_WIDTH}px) {
            bottom: ${84 + offset}px;
            height: min(${704 - offset}px, 100% - ${104 + offset}px);
          }
        }
      }
    }
  `
}

export function IntercomGlobalStyles(): JSX.Element {
  return <GlobalStyles styles={createStyles} />
}
