import { FLOWCODE_ROOT, FLOWCODE_SUBDOMAIN } from '@dtx-company/true-common/src/constants/root'
import { IS_REVIEW_APP } from '@dtx-company/true-common/src/constants/env'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { useEffect } from 'react'
import { useRouter } from 'next/router'

/**
 * For www.flowcode.com -> app.flowcode.com migration
 * Redirect to FLOWCODE_ROOT if logged in, not in an excluded route, not localhost, not reviewapp
 */

const excludedRoutes = [Routes.FREE_QR_CODE_GENERATOR, Routes.FLOWPAGE]

export const useSubdomainRedirect = (): void => {
  const router = useRouter()

  const isAnExcludedRoute = excludedRoutes.some(route => router.pathname === route)

  const shouldAttemptRedirect = !isAnExcludedRoute

  useEffect(() => {
    const isLocalHost = window.location.host === 'localhost:3000'
    const isReviewApp = IS_REVIEW_APP || window.location.host.includes('reviewapps')
    if (shouldAttemptRedirect && !isLocalHost && !isReviewApp) {
      const currentURL = `${window.location.href}`
      const correctURL = currentURL.replace(window.location.origin, FLOWCODE_ROOT)
      const subdomain = window.location.host.split('.')[0]
      const correctSubdomain = FLOWCODE_SUBDOMAIN

      const doSubdomainsMatch = subdomain === correctSubdomain

      if (!doSubdomainsMatch) {
        window.location = correctURL as unknown as Location
      }
    }
  })
}
