import {
  GlobalXStateContext,
  GlobalXStateContextType
} from '../../../../components/_app/components/XStateProvider'
import { useContext } from 'react'

export const useGlobalServices = (): GlobalXStateContextType => {
  const globalServices = useContext(GlobalXStateContext)
  if (!globalServices) {
    throw new Error('useGlobalServices must be used within a XStateProvider')
  }

  return globalServices
}
