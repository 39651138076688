import { SWRConfig, SWRConfiguration } from 'swr'
import { gqlFetcher } from '../../services/gqlFetcher'

interface FlowSWRConfigProps {
  children: JSX.Element
  fallback?: SWRConfiguration['fallback']
  fetcher?: SWRConfiguration['fetcher']
}

export function FlowSWRConfig(props: FlowSWRConfigProps): JSX.Element {
  return (
    <>
      <SWRConfig
        value={{
          fetcher: props.fetcher || gqlFetcher,
          fallback: props.fallback || {},
          onError(error, _key, _config) {
            console.error(error)
          }
        }}
      >
        {props.children}
      </SWRConfig>
    </>
  )
}
