import { getIntercom } from './getIntercom'

class IntercomEventQueue {
  private static instance: IntercomEventQueue
  private eventQueue: Array<{ eventName: string; metadata?: Record<string, unknown> }> = []

  private constructor() {
    if (IntercomEventQueue.instance) {
      throw new Error('You can only create one instance!')
    }
    IntercomEventQueue.instance = this
  }

  public static getInstance(): IntercomEventQueue {
    if (!IntercomEventQueue.instance) {
      IntercomEventQueue.instance = new IntercomEventQueue()
    }
    return IntercomEventQueue.instance
  }

  /**
   * Enqueue an event to be fired to Intercom.
   *
   * @param eventName - The name of the event to fire to Intercom.
   * @param metadata - Optional metadata to send with the event.
   */
  public enqueueEvent(eventName: string, metadata?: Record<string, unknown>): void {
    this.eventQueue.push({ eventName, metadata })
    this.flushEventQueue()
  }

  /**
   * Flush the event queue by firing events to Intercom.
   */
  public flushEventQueue(): Promise<void[]> {
    const promises: Promise<void>[] = []
    const intercom = this.intercom
    if (intercom && intercom.booted) {
      while (this.eventQueue.length > 0) {
        const event = this.eventQueue.shift()
        if (event) {
          const promise = new Promise<void>(resolve => {
            intercom('trackEvent', event.eventName, event.metadata)
            resolve()
          })
          promises.push(promise)
        }
      }
    }
    return Promise.all(promises)
  }

  private get intercom(): Intercom_.IntercomStatic | undefined {
    return getIntercom()
  }
}

export default IntercomEventQueue.getInstance()
