import { ElementType, ForwardRefExoticComponent, forwardRef } from 'react'
import MuiStack, { StackProps as MuiStackProps } from '@mui/material/Stack'

export type StackProps = Pick<
  MuiStackProps,
  | 'children'
  | 'direction'
  | 'divider'
  | 'gap'
  | 'id'
  | 'key'
  | 'ref'
  | 'sx'
  | 'spacing'
  | 'onScroll'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'justifyContent'
  | 'alignItems'
  | 'flex'
  | 'useFlexGap'
> & { component?: ElementType }

/**
 * The Stack component manages layout of immediate children along the vertical
 * or horizontal axis with optional spacing and/or dividers between each child.
 *
 * Documentation: https://mui.com/material-ui/react-stack/
 */
export const Stack: ForwardRefExoticComponent<StackProps> = forwardRef(
  (props, ref): JSX.Element => <MuiStack {...props} ref={ref} />
)

Stack.displayName = 'Stack'
