import { Components, Theme } from '@mui/material'

export const menuComponentOverrides: Components<Theme> = {
  MuiMenu: {
    styleOverrides: {
      root: {
        // So that select popup doesn't occupy the full height of the viewport
        // even if the number of items is large enough to fill the viewport.
        // The popup box becomes scrollable and 96px of space is left for the
        // user to exit without choosing an option if they don't want to
        '.MuiPopover-paper.MuiPaper-root': {
          maxHeight: 'calc(100% - 96px)'
        }
      }
    }
  }
}
