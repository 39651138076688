import { Cookie } from '@dtx-company/true-common/src/types/cookies'
import { setIsEU, setIsUS } from '@dtx-company/inter-app/src/redux/slices/current-session-slice'
import { useCookies } from '@dtx-company/true-common/src/utils/cookies/cookies.hooks'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

export const useFetchGeolocation = (continent?: string, country?: string): void => {
  const { setCookie } = useCookies()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!continent || !country) {
      // this is the likely path when running without the cloudflare worker
      // `flowcode-worker`
      const script = document.createElement('script')

      const cleanup = (): void => {
        if (script?.parentNode) script.parentNode.removeChild(script)
        window.geofeed = (): void => undefined
      }
      const oneTrustError = (): void => {
        dispatch(setIsUS({ isUS: false }))
        // If we can't determine their location, default EU to true so that we don't break any privacy laws
        dispatch(setIsEU({ isEU: true }))
      }
      const setCountryCodeCookie = (countryCode: string): void => {
        const date = new Date()
        date.setDate(date.getDate() + 7) // 1 week expiration
        setCookie(Cookie.COUNTRY_CODE, countryCode, { expires: date })
      }

      window.geofeed = function (options) {
        setCountryCodeCookie(options.country)
        const isEU = options.continent === 'EU'
        const isUS = options.country === 'US'
        dispatch(setIsEU({ isEU }))
        dispatch(setIsUS({ isUS }))
        cleanup()
      }

      script.onerror = oneTrustError
      script.setAttribute(
        'src',
        'https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location/geofeed'
      )
      document.head.appendChild(script)

      return () => {
        cleanup()
      }
    } else {
      dispatch(setIsEU({ isEU: continent === 'EU' }))
      dispatch(setIsUS({ isUS: country === 'US' }))
    }
  }, [dispatch, continent, country, setCookie])
}
