import { PayloadAction } from '@reduxjs/toolkit'
import { SelectedFlowpageView } from './flowpage'

export enum PagesFilter {
  NAME = 'title',
  URL = 'slugName',
  CREATED_AT = 'createdAt',
  MODIFED_AT = 'modifiedAt'
}

export type PagesFilterActionType = PayloadAction<PagesFilter>

export type PagesFilterValueActionType = PayloadAction<string>

export type SelectedFlowpageViewType = PayloadAction<SelectedFlowpageView>

export type PagesFilterDirectionActionType = PayloadAction<SortDirections>

export enum SortDirections {
  ASC = 'asc',
  DSC = 'dsc'
}
