import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { UserCookiePreferences } from '@dtx-company/true-common/src/types/cookies'

export interface CookieState {
  cookiePreferences: UserCookiePreferences
  anonIdLoaded: boolean
  browserSessionIdLoaded: boolean
}

export const initialCookieState: CookieState = {
  cookiePreferences: {
    C0001: false,
    C0002: false,
    C0003: false,
    C0004: false,
    C0005: false
  },
  browserSessionIdLoaded: false,
  anonIdLoaded: false
}

const cookieSlice = createSlice({
  name: 'user',
  initialState: initialCookieState,
  reducers: {
    setCookiePreferences: (state, action: PayloadAction<UserCookiePreferences>) => {
      state.cookiePreferences = action.payload
    },
    setAnonIdLoaded: (state, action: PayloadAction<boolean>) => {
      state.anonIdLoaded = action.payload
    },
    setBrowserSessionIdLoaded: (state, action: PayloadAction<boolean>) => {
      state.browserSessionIdLoaded = action.payload
    }
  }
})

export const { setCookiePreferences, setAnonIdLoaded, setBrowserSessionIdLoaded } =
  cookieSlice.actions

export default cookieSlice.reducer
