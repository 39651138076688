import { IntercomGlobalStyles } from './IntercomGlobalStyles'
import { IntercomSync } from './IntercomSync'

/**
 * This is the primary Intercom component that handles loading the intercom lib,
 * syncing it to the user, custom styling, and any other intercom functionality.
 *
 * There should only be a single instance of this at a time.
 */
export function Intercom(): JSX.Element {
  return (
    <>
      <div data-testid="intercom-snippet" />
      <IntercomSync />
      <IntercomGlobalStyles />
    </>
  )
}
//
