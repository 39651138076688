import { AUTH_CACHE_ENDPOINT } from '@dtx-company/true-common/src/constants/endpoints'
import { ParseCachedValue } from './types'
import { logger } from '@dtx-company/logger'

export async function getFromCache<T>(
  key: string,
  parseCachedValue?: ParseCachedValue<T>
): Promise<T | undefined> {
  try {
    const params = new URLSearchParams()
    params.append('key', key)
    const res = await fetch(`${AUTH_CACHE_ENDPOINT}?${params.toString()}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json'
      }
    })
    if (res.ok) {
      const { value } = await res.json()
      if (parseCachedValue) {
        return parseCachedValue(value)
      }
      return value
    }
  } catch (e) {
    logger.logError(e, { technicalArea: 'authentication' })
  }
}

export async function setInCache<T>(key: string, value: T): Promise<boolean> {
  let success = false
  try {
    const params = new URLSearchParams()
    params.append('key', key)
    const res = await fetch(`${AUTH_CACHE_ENDPOINT}?${params.toString()}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({ value })
    })
    if (res.ok) {
      success = true
    } else {
      logger.logFetchError(res, { technicalArea: 'authentication' })
    }
  } catch (e) {
    logger.logError(e, { technicalArea: 'authentication' })
  }
  return success
}

export async function deleteFromCache(key: string): Promise<boolean> {
  let success = false
  try {
    const params = new URLSearchParams()
    params.append('key', key)
    const res = await fetch(`${AUTH_CACHE_ENDPOINT}?${params.toString()}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json'
      }
    })
    if (res.ok) {
      success = true
    } else {
      logger.logFetchError(res, { technicalArea: 'authentication' })
    }
  } catch (e) {
    logger.logError(e, { technicalArea: 'authentication' })
  }
  return success
}

export function castToString<T>(value: T): string {
  if (typeof value === 'string') {
    return value
  } else if (typeof value === 'number' || typeof value === 'boolean') {
    return value.toString()
  } else {
    return JSON.stringify(value)
  }
}
