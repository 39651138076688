import { SVGProps, memo } from 'react'

function YoutubeSVG(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 176 124"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      width="1em"
      height="1em"
      {...props}
    >
      <g fillRule="nonzero">
        <path
          d="M172.322 19.363c-2.024-7.622-7.987-13.624-15.561-15.661C143.037 0 88 0 88 0S32.963 0 19.238 3.702C11.665 5.739 5.701 11.741 3.677 19.363 0 33.177 0 62 0 62s0 28.822 3.677 42.638c2.024 7.621 7.988 13.623 15.561 15.661C32.963 124 88 124 88 124s55.037 0 68.761-3.701c7.574-2.038 13.537-8.04 15.561-15.661C176 90.822 176 62 176 62s0-28.823-3.678-42.637"
          fill={props.fill || 'red'}
        />
        <path d="M70 88.169l46-26.168-46-26.17v52.338z" fill="#fff" />
      </g>
    </svg>
  )
}

export const YouTube = memo(YoutubeSVG)

function Youtube2Svg({
  fill = 'currentcolor',
  stroke = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 25 18" {...rest}>
      <path
        fill={color || fill}
        fillRule="evenodd"
        d="M20 1.5H5A3.5 3.5 0 001.5 5v8A3.5 3.5 0 005 16.5h15a3.5 3.5 0 003.5-3.5V5A3.5 3.5 0 0020 1.5zM5 0a5 5 0 00-5 5v8a5 5 0 005 5h15a5 5 0 005-5V5a5 5 0 00-5-5H5z"
        clipRule="evenodd"
      />
      <path
        stroke={color || stroke}
        d="M15.25 9.433l-4.5 2.598a.5.5 0 01-.75-.433V6.402a.5.5 0 01.75-.433l4.5 2.598a.5.5 0 010 .866z"
      />
    </svg>
  )
}

export const YouTube2 = memo(Youtube2Svg)
