import { IS_INTERNAL_ADMIN } from '@dtx-company/true-common/src/constants/env'
import { IthacaJwt } from '@dtx-company/ithaca-sdk/src'
import { RootState } from '../../redux/types'
import { decodeFronteggAccessToken } from '@dtx-company/ithaca-sdk/src/frontegg/decodeFronteggAccessToken'
import { useSelector } from 'react-redux'

export function useIsFronteggUser(): boolean {
  return useSelector((state: RootState) => state.appReducer.userReducer.isFronteggUser)
}

export function useIsAuthChecked(): boolean {
  return useSelector((state: RootState) => state.appReducer.userReducer.isAuthChecked)
}

export function useIsImpersonating(): boolean {
  return useSelector(
    (state: RootState) => state.appReducer.userReducer.impersonatedUserData !== null
  )
}

export function selectCurrentUserJwt(state: RootState): IthacaJwt | null {
  const userJwt = state.appReducer.userReducer.currentUserData

  const impersonatedUserJwt = state.appReducer.userReducer.impersonatedUserData

  if (IS_INTERNAL_ADMIN) {
    // Always use root user's token in internal admin
    return userJwt
  }

  return impersonatedUserJwt ?? userJwt
}

export function useCurrentUserJwt(): IthacaJwt | null {
  return useSelector((state: RootState) => selectCurrentUserJwt(state))
}

export function selectCurrentUserPlan(state: RootState): string | undefined {
  return selectCurrentUserJwt(state)?.org?.orgPlan
}

export function useCurrentUserPlan(): string | undefined {
  return useSelector((state: RootState) => selectCurrentUserPlan(state))
}

export function selectRootUserJwt(state: RootState): IthacaJwt | null {
  return state.appReducer.userReducer.currentUserData
}

export function useRootUserJwt(): IthacaJwt | null {
  return useSelector((state: RootState) => selectRootUserJwt(state))
}

export function selectRootUserFronteggId(state: RootState): string | undefined {
  const jwt = selectRootUserJwt(state)
  return jwt ? decodeFronteggAccessToken(jwt.token)?.sub : undefined
}

export function useRootUserFronteggId(): string | undefined {
  return useSelector(selectRootUserFronteggId)
}
