import { Components, Theme } from '@mui/material'
import { filledInputClasses } from '@mui/material/FilledInput'
import { formHelperTextClasses } from '@mui/material/FormHelperText'
import { formLabelClasses } from '@mui/material/FormLabel'
import { iconButtonClasses } from '@mui/material/IconButton'
import { inputAdornmentClasses } from '@mui/material/InputAdornment'
import { inputBaseClasses } from '@mui/material/InputBase'
import { inputLabelClasses } from '@mui/material/InputLabel'

/**
 * MUI Theme overrides for Flowcode's [TextField](./TextField.tsx) component.
 */
export const textFieldComponentOverrides: Components<Theme> = {
  MuiTextField: {
    defaultProps: {
      variant: 'filled',
      size: 'medium'
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        //searchBar overrides
        '&&.MuiSearchbar': {
          [theme.breakpoints.up('md')]: {
            'input[type="search"]::-webkit-search-cancel-button': {
              WebkitAppearance: 'none'
            },

            '.MuiInputBase-root': {
              borderRadius: 20,
              height: theme.spacing(10)
            },
            height: theme.spacing(10),
            backgroundColor: theme.palette.secondary.main,
            borderRadius: 20
          },
          [theme.breakpoints.down('md')]: {
            '.MuiInputBase-input': {
              padding: '8px'
            },
            '.MuiInputBase-root': {
              borderColor: 'transparent',
              '&:hover': {
                borderColor: 'transparent',
                outline: 'none'
              }
            },

            border: 'none'
          }
        },

        minWidth: ownerState.fullWidth ? 0 : theme.spacing(55),

        // Set background color to transparent
        [`&& .${filledInputClasses.root}, && .${inputLabelClasses.root}`]: {
          backgroundColor: 'transparent',
          '&:hover, &:focus': {
            backgroundColor: 'transparent'
          }
        },

        // Remove bottom border from ::before
        [`&& .${inputBaseClasses.root}.${filledInputClasses.root}::before`]: {
          borderBottom: 'none'
        },

        // Set outline for focus state
        [`&&:focus-within .${filledInputClasses.root}`]: {
          outlineWidth: 4,
          outlineStyle: 'solid',
          outlineColor: ownerState.error
            ? theme.palette.shades.error.focus
            : theme.palette.shades.primary.active
        },

        // Set base border
        [`&& .${filledInputClasses.root}`]: {
          border: ownerState.error ? 2 : 1,
          borderStyle: 'solid',
          borderColor: ownerState.error ? theme.palette.error.main : theme.palette.border.outline
        },

        // Set border color on hover
        [`&:not(.Mui-disabled):hover .${filledInputClasses.root}`]: {
          borderColor: ownerState.error ? theme.palette.error.main : theme.palette.onSurface.primary
        },

        [`.${filledInputClasses.input}`]: {
          boxSizing: 'border-box',
          height: '100%'
        },

        // Set border color on focus and make it thicker
        [`&:focus-within .${filledInputClasses.root}`]: {
          borderColor: ownerState.error
            ? theme.palette.error.main
            : theme.palette.onSurface.primary,
          borderWidth: '2px',

          // Reduce input padding to make up for the thicker border
          ...(ownerState.InputProps?.startAdornment && {
            paddingLeft: `calc(${theme.spacing(3)} - 1px)`,
            paddingRight: `calc(${theme.spacing(3)} - 1px)`
          }),
          ...(!ownerState.InputProps?.startAdornment && {
            [`.${filledInputClasses.input}`]: {
              paddingLeft: `calc(${theme.spacing(3)} - 1px)`
            }
          }),

          // Adjust input padding for multiline input
          [`.${filledInputClasses.input}.${inputBaseClasses.inputMultiline}`]: {
            padding: 0,
            marginLeft: ownerState.error ? 0 : '-1px',
            marginTop: ownerState.error ? 0 : '-1px'
          }
        },

        // Set border and font color on disabled
        '&& .Mui-disabled, &.Mui-disabled': {
          borderColor: theme.palette.border.disabled,
          color: theme.palette.onSurface.disabled
        },

        // Set label color
        [`&& .${inputLabelClasses.root}`]: {
          color: 'inherit',
          [`&.${inputLabelClasses.shrink}`]: {
            ...(ownerState.error && { color: theme.palette.error.main })
          }
        },

        // Set input adornment color
        [`&& .${inputAdornmentClasses.root}`]: {
          color: 'inherit',

          // Make sure icon button color is inherited
          [`.${iconButtonClasses.root}`]: {
            color: 'inherit'
          },

          // Adjust starting input adornment margin from MUI's default since we're altering the input sizes
          // This is for sizes `medium` and `large`, as for `small` the label is hidden and the margin is correct
          [`&.${inputAdornmentClasses.positionStart}:not(${inputAdornmentClasses.hiddenLabel})`]: {
            marginTop: 0
          },

          ...(ownerState.multiline && {
            [`&.${inputAdornmentClasses.positionEnd}`]: {
              position: 'absolute',
              right: theme.spacing(2),
              top: theme.spacing(4)
            }
          })
        },

        // Set helper text color and margin so it aligns with the input text and label
        [`&& .${formHelperTextClasses.root}`]: {
          color: ownerState.error ? theme.palette.error.main : 'onSurface.secondary',
          marginLeft: theme.spacing(3)
        },

        // Adjust label position when size not specified
        // e.g., when used in Autocomplete component
        [`&& .${formLabelClasses.root}.${inputLabelClasses.root}`]: {
          transform: `translate(${theme.spacing(3)}, ${theme.spacing(3.5)}) scale(1)`
        },

        // Enable resizing for multiline input for browsers that support the custom `::-webkit-resizer` pseudo-element
        // (non-Firefox) -- see https://caniuse.com/mdn-css_selectors_-webkit-resizer
        '@supports (not (-moz-appearance: none))': {
          [`&& .${inputBaseClasses.root}.${filledInputClasses.root}.${inputBaseClasses.formControl}.${inputBaseClasses.multiline}`]:
            {
              position: 'relative',
              // display: 'inline-block',

              // Customize the resizer
              '&:after': {
                content:
                  'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEwLjUgMTAuNVY3LjE2N0w3LjE2NSAxMC41SDEwLjV6bS03LjQgMGw3LjQtNy40Vi43NDJMLjc0IDEwLjVIMy4xeiIgZmlsbD0iIzY5Njc2MyIvPjwvc3ZnPg==")',
                position: 'absolute',
                bottom: theme.spacing(-0.5),
                right: theme.spacing(1),
                pointerEvents: 'none'
              },

              [`.${inputBaseClasses.input}.${inputBaseClasses.inputMultiline}`]: {
                resize: 'vertical',

                '::-webkit-resizer': {
                  display: 'none'
                }
              }
            }
        },

        // Enable resizing for multiline input for Firefox without custom resizer,
        // since Firefox doesn't support the `::-webkit-resizer` pseudo-element
        '@supports (-moz-appearance: none)': {
          [`&& .${inputBaseClasses.input}.${inputBaseClasses.inputMultiline}`]: {
            resize: 'vertical'
          }
        },

        /**
         * Our custom sizes
         */

        // Small
        '&&.MuiTextField-sizeSmall': {
          [`.${filledInputClasses.root}`]: {
            height: theme.spacing(10)
          },
          // Adjust default label position
          [`.${inputLabelClasses.root}`]: {
            transform: `translate(${theme.spacing(3)}, ${theme.spacing(2.25)}) scale(1)`
          },
          // Adjust input position to account for hidden label
          [`.${filledInputClasses.input}`]: {
            paddingTop: theme.spacing(4.25)
          }
        },

        // Medium
        '&&.MuiTextField-sizeMedium': {
          [`.${filledInputClasses.root}:not(.${inputBaseClasses.multiline})`]: {
            height: theme.spacing(12)
          },
          // Adjust default label position
          [`.${inputLabelClasses.root}`]: {
            transform: `translate(${theme.spacing(
              ownerState.InputProps?.startAdornment ? 11.5 : 3.25
            )}, ${theme.spacing(ownerState.multiline ? 2 : 3.2)}) scale(1)`
          },
          // Adjust shrunken label position when input is not empty
          [`.${inputLabelClasses.root}.${inputLabelClasses.shrink}`]: {
            transform: `translate(${theme.spacing(
              ownerState.InputProps?.startAdornment ? 11.5 : 3.25
            )}, ${theme.spacing(ownerState.multiline ? 2 : 0.75)}) scale(0.75)`
          },
          [`.${filledInputClasses.input}:not(.${inputBaseClasses.inputMultiline})`]: {
            paddingTop: theme.spacing(5)
          }
        },

        // Large
        '&&.MuiTextField-sizeLarge': {
          [`.${filledInputClasses.root}:not(.${inputBaseClasses.multiline})`]: {
            height: theme.spacing(14)
          },
          // Adjust default label position
          [`.${inputLabelClasses.root}`]: {
            transform: `translate(${theme.spacing(
              ownerState.InputProps?.startAdornment ? 11.5 : 3.25
            )}, ${theme.spacing(ownerState.multiline ? 2 : 4.25)}) scale(1)`
          },
          // Adjust shrunken label position when input is not empty
          [`.${inputLabelClasses.root}.${inputLabelClasses.shrink}`]: {
            transform: `translate(${theme.spacing(
              ownerState.InputProps?.startAdornment ? 11.5 : 3.2
            )}, ${theme.spacing(1.75)}) scale(0.75)`
          }
        }
      })
    }
  }
}
