import { CookieSetOptions } from 'universal-cookie'

export enum CookieClassification {
  strictlyNecessary = 'C0001',
  performance = 'C0002',
  functional = 'C0003',
  targeting = 'C0004',
  socialMedia = 'C0005'
}

/**
 * @todo: Use UPPER_CASE for all cookies
 */
export enum Cookie {
  ADMIN_TOKEN = 'ADMIN_TOKEN',
  BROWSER_SESSION_ID = 'BROWSER_SESSION_ID',
  // Don't use this cookie in app for logged in state, it's only use is for the marketing site
  LOGGED_IN = 'LOGGED_IN',
  ANONYMOUS_SESSION_ID = 'ANONYMOUS_SESSION_ID',
  FLOWCODE_PRO_OFFER = 'FLOWCODE_PRO_OFFER',
  OPT_OUT = 'OPT_OUT',
  REDIRECT_SERVICE_COOKIE = 'rdservice',
  analytics_waitlist = 'analytics_waitlist',
  pro_waitlist = 'pro_waitlist',
  vote_ = 'vote_',
  isBannerVisibleCookie = 'isBannerVisibleCookie',
  token = 'token',
  TV_TOOL_COOKIE = 'frontegg_tvtool_token',
  FLOW_LEADS_COOKIE = 'frontegg_flowleads_token',
  NBA_ID_SESSION = 'nba_id_session',
  COUNTRY_CODE = 'countryCode',
  DISMISSED_DATA_VIA_EXPLORE_API_SECTION = 'DISMISSED_DATA_VIA_EXPLORE_API_SECTION',
  USER_HAS_GENERATED_ANALYTICS_INSIGHTS = 'USER_HAS_GENERATED_ANALYTICS_INSIGHTS'
}

export type UserCookiePreferences = {
  [key in CookieClassification]: boolean
}

export type CookieType = {
  name: string
  type: CookieClassification
  defaultValue: string
}

export type CookieMap = {
  [key in Cookie]: CookieType
}
export interface UseCookiesType {
  optOut: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCookie: (name: Cookie, value: any, options?: CookieSetOptions) => void
  handleUserOptOut: () => Promise<void>
}
