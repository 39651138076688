import { SignUpLocation } from '../auth'

export interface FronteggObserver {
  onLogin?(accessToken: string): void
  onSignup?(accessToken: string, domain?: string, location?: SignUpLocation): void
  onLogout?(): void
  onRefresh?(accessToken: string): void
  onImpersonation?: (accessToken: string) => void
}

export let fronteggObservers: FronteggObserver[] = []

export function subscribeFronteggObserver(observer: FronteggObserver): void {
  fronteggObservers.push(observer)
}

export function unsubscribeFronteggObserver(observer: FronteggObserver): void {
  fronteggObservers = fronteggObservers.filter(element => element !== observer)
}
