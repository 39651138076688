import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Theme } from '@dtx-company/flow-codegen/src/page/generated.types'
import { defaultUserTheme } from '@dtx-company/true-common/src/constants/page'

export interface OptimizeTestSlice {
  userTheme: PageType['theme']
  requireTwoLinksToPublish: boolean
}

const initialState: OptimizeTestSlice = {
  userTheme: defaultUserTheme,
  requireTwoLinksToPublish: false
}

const optimizeTestSlice = createSlice({
  name: 'optimizeTestSlice',
  initialState,
  reducers: {
    setPageTheme: (state, action: PayloadAction<{ userTheme: Theme }>) => {
      state.userTheme = action.payload.userTheme
    },

    setRequireTwoLinksToPublish: (
      state,
      action: PayloadAction<{ requireTwoLinksToPublish: boolean }>
    ) => {
      state.requireTwoLinksToPublish = action.payload.requireTwoLinksToPublish
    }
  }
})

export const { setPageTheme, setRequireTwoLinksToPublish } = optimizeTestSlice.actions

export default optimizeTestSlice.reducer
