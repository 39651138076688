// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file forms/v1/forms.proto (package forms.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { Message, proto3, Timestamp } from '@bufbuild/protobuf'
import { SuccessAction } from './success_screen_pb'
import { Field } from './field_pb'
import { PaginationOptions } from './options_pb'

/**
 * @generated from message forms.v1.Form
 */
export class Form extends Message<Form> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: bool active = 2;
   */
  active = false

  /**
   * @generated from field: forms.v1.SuccessAction success_action = 3;
   */
  successAction?: SuccessAction

  /**
   * @generated from field: repeated forms.v1.Field fields = 4;
   */
  fields: Field[] = []

  /**
   * @generated from field: string title = 5;
   */
  title = ''

  /**
   * @generated from field: string description = 6;
   */
  description = ''

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 7;
   */
  createdAt?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 8;
   */
  updatedAt?: Timestamp

  /**
   * @generated from field: optional string action_text = 9;
   */
  actionText?: string

  constructor(data?: PartialMessage<Form>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.Form'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: 'success_action', kind: 'message', T: SuccessAction },
    { no: 4, name: 'fields', kind: 'message', T: Field, repeated: true },
    { no: 5, name: 'title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 8, name: 'updated_at', kind: 'message', T: Timestamp },
    { no: 9, name: 'action_text', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Form {
    return new Form().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Form {
    return new Form().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Form {
    return new Form().fromJsonString(jsonString, options)
  }

  static equals(
    a: Form | PlainMessage<Form> | undefined,
    b: Form | PlainMessage<Form> | undefined
  ): boolean {
    return proto3.util.equals(Form, a, b)
  }
}

/**
 * @generated from message forms.v1.CreateFormResponse
 */
export class CreateFormResponse extends Message<CreateFormResponse> {
  /**
   * @generated from field: forms.v1.Form form = 1;
   */
  form?: Form

  constructor(data?: PartialMessage<CreateFormResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.CreateFormResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'form', kind: 'message', T: Form }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFormResponse {
    return new CreateFormResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFormResponse {
    return new CreateFormResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateFormResponse {
    return new CreateFormResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateFormResponse | PlainMessage<CreateFormResponse> | undefined,
    b: CreateFormResponse | PlainMessage<CreateFormResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateFormResponse, a, b)
  }
}

/**
 * @generated from message forms.v1.CreateFormRequest
 */
export class CreateFormRequest extends Message<CreateFormRequest> {
  /**
   * @generated from field: forms.v1.SuccessAction success_action = 1;
   */
  successAction?: SuccessAction

  /**
   * @generated from field: repeated forms.v1.Field fields = 2;
   */
  fields: Field[] = []

  /**
   * @generated from field: string title = 3;
   */
  title = ''

  /**
   * @generated from field: string description = 4;
   */
  description = ''

  /**
   * @generated from field: bool active = 5;
   */
  active = false

  constructor(data?: PartialMessage<CreateFormRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.CreateFormRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'success_action', kind: 'message', T: SuccessAction },
    { no: 2, name: 'fields', kind: 'message', T: Field, repeated: true },
    { no: 3, name: 'title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFormRequest {
    return new CreateFormRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFormRequest {
    return new CreateFormRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFormRequest {
    return new CreateFormRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CreateFormRequest | PlainMessage<CreateFormRequest> | undefined,
    b: CreateFormRequest | PlainMessage<CreateFormRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateFormRequest, a, b)
  }
}

/**
 * @generated from message forms.v1.GetFormRequest
 */
export class GetFormRequest extends Message<GetFormRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<GetFormRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.GetFormRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFormRequest {
    return new GetFormRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFormRequest {
    return new GetFormRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFormRequest {
    return new GetFormRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetFormRequest | PlainMessage<GetFormRequest> | undefined,
    b: GetFormRequest | PlainMessage<GetFormRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetFormRequest, a, b)
  }
}

/**
 * @generated from message forms.v1.GetFormResponse
 */
export class GetFormResponse extends Message<GetFormResponse> {
  /**
   * @generated from field: forms.v1.Form form = 1;
   */
  form?: Form

  constructor(data?: PartialMessage<GetFormResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.GetFormResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'form', kind: 'message', T: Form }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFormResponse {
    return new GetFormResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFormResponse {
    return new GetFormResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFormResponse {
    return new GetFormResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GetFormResponse | PlainMessage<GetFormResponse> | undefined,
    b: GetFormResponse | PlainMessage<GetFormResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetFormResponse, a, b)
  }
}

/**
 * @generated from message forms.v1.RenderFormRequest
 */
export class RenderFormRequest extends Message<RenderFormRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<RenderFormRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.RenderFormRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RenderFormRequest {
    return new RenderFormRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RenderFormRequest {
    return new RenderFormRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RenderFormRequest {
    return new RenderFormRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: RenderFormRequest | PlainMessage<RenderFormRequest> | undefined,
    b: RenderFormRequest | PlainMessage<RenderFormRequest> | undefined
  ): boolean {
    return proto3.util.equals(RenderFormRequest, a, b)
  }
}

/**
 * @generated from message forms.v1.RenderFormResponse
 */
export class RenderFormResponse extends Message<RenderFormResponse> {
  /**
   * @generated from field: forms.v1.Form form = 1;
   */
  form?: Form

  constructor(data?: PartialMessage<RenderFormResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.RenderFormResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'form', kind: 'message', T: Form }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RenderFormResponse {
    return new RenderFormResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RenderFormResponse {
    return new RenderFormResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RenderFormResponse {
    return new RenderFormResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: RenderFormResponse | PlainMessage<RenderFormResponse> | undefined,
    b: RenderFormResponse | PlainMessage<RenderFormResponse> | undefined
  ): boolean {
    return proto3.util.equals(RenderFormResponse, a, b)
  }
}

/**
 * Performs a PUT-style update, syncing all form state
 *
 * @generated from message forms.v1.UpdateFormRequest
 */
export class UpdateFormRequest extends Message<UpdateFormRequest> {
  /**
   * @generated from field: forms.v1.Form form = 1;
   */
  form?: Form

  constructor(data?: PartialMessage<UpdateFormRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.UpdateFormRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'form', kind: 'message', T: Form }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFormRequest {
    return new UpdateFormRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFormRequest {
    return new UpdateFormRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFormRequest {
    return new UpdateFormRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateFormRequest | PlainMessage<UpdateFormRequest> | undefined,
    b: UpdateFormRequest | PlainMessage<UpdateFormRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateFormRequest, a, b)
  }
}

/**
 * @generated from message forms.v1.UpdateFormResponse
 */
export class UpdateFormResponse extends Message<UpdateFormResponse> {
  /**
   * @generated from field: forms.v1.Form form = 1;
   */
  form?: Form

  constructor(data?: PartialMessage<UpdateFormResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.UpdateFormResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'form', kind: 'message', T: Form }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFormResponse {
    return new UpdateFormResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFormResponse {
    return new UpdateFormResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateFormResponse {
    return new UpdateFormResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: UpdateFormResponse | PlainMessage<UpdateFormResponse> | undefined,
    b: UpdateFormResponse | PlainMessage<UpdateFormResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateFormResponse, a, b)
  }
}

/**
 * @generated from message forms.v1.ArchiveFormRequest
 */
export class ArchiveFormRequest extends Message<ArchiveFormRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  constructor(data?: PartialMessage<ArchiveFormRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.ArchiveFormRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ArchiveFormRequest {
    return new ArchiveFormRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArchiveFormRequest {
    return new ArchiveFormRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ArchiveFormRequest {
    return new ArchiveFormRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ArchiveFormRequest | PlainMessage<ArchiveFormRequest> | undefined,
    b: ArchiveFormRequest | PlainMessage<ArchiveFormRequest> | undefined
  ): boolean {
    return proto3.util.equals(ArchiveFormRequest, a, b)
  }
}

/**
 * @generated from message forms.v1.ArchiveFormResponse
 */
export class ArchiveFormResponse extends Message<ArchiveFormResponse> {
  constructor(data?: PartialMessage<ArchiveFormResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.ArchiveFormResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ArchiveFormResponse {
    return new ArchiveFormResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArchiveFormResponse {
    return new ArchiveFormResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ArchiveFormResponse {
    return new ArchiveFormResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ArchiveFormResponse | PlainMessage<ArchiveFormResponse> | undefined,
    b: ArchiveFormResponse | PlainMessage<ArchiveFormResponse> | undefined
  ): boolean {
    return proto3.util.equals(ArchiveFormResponse, a, b)
  }
}

/**
 * @generated from message forms.v1.ListFormsRequest
 */
export class ListFormsRequest extends Message<ListFormsRequest> {
  /**
   * @generated from field: optional forms.v1.PaginationOptions page_options = 3;
   */
  pageOptions?: PaginationOptions

  constructor(data?: PartialMessage<ListFormsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.ListFormsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: 'page_options', kind: 'message', T: PaginationOptions, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFormsRequest {
    return new ListFormsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFormsRequest {
    return new ListFormsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFormsRequest {
    return new ListFormsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListFormsRequest | PlainMessage<ListFormsRequest> | undefined,
    b: ListFormsRequest | PlainMessage<ListFormsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListFormsRequest, a, b)
  }
}

/**
 * @generated from message forms.v1.ListFormsResponse
 */
export class ListFormsResponse extends Message<ListFormsResponse> {
  /**
   * @generated from field: repeated forms.v1.Form forms = 1;
   */
  forms: Form[] = []

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = ''

  constructor(data?: PartialMessage<ListFormsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'forms.v1.ListFormsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'forms', kind: 'message', T: Form, repeated: true },
    { no: 2, name: 'next_page_token', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFormsResponse {
    return new ListFormsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFormsResponse {
    return new ListFormsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFormsResponse {
    return new ListFormsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListFormsResponse | PlainMessage<ListFormsResponse> | undefined,
    b: ListFormsResponse | PlainMessage<ListFormsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListFormsResponse, a, b)
  }
}
