import { FeatureFlags } from '@dtx-company/true-common/src/constants/featureFlags'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { VariableValuesObject } from '@optimizely/react-sdk/dist/client'
import { useEffect } from 'react'
import { useFeature } from '@optimizely/react-sdk'
import { useFlowFeatureContext } from './context/useFlowFeatureContext'
import { useRouter } from 'next/router'

export type UseFlowFeatureCache = { [key: string]: [...ReturnType<typeof useFeature>, boolean] }

/**
 * Wraps useFeature from Optimizely with additional cache layers for
 * use during development.
 *
 * The cache may be populated via the command-line
 *  `window.flow.feature.add(key, value, persist)`
 * or via the query param
 *  `?feature[key]=value`
 *
 * The value can match one of the following use cases:
 * - on | true | 1 = enabled
 * - off | false | 0 = disabled
 * - json | object = enabled with settings
 *
 * Cache priority: query > session > local
 *
 * @see useFeature
 *
 * @example
 * // ?feature[my_flag]=off
 * useFlowFeature('my_flag') // => [false, {}, true, false]
 * // ?feature[my_json_flag]={"num":1}
 * useFlowFeature('my_json_flag') // => [true, { num: 1 }, true, false]
 *
 * @example
 * > flow.feature.add('other_flag', 'on', false)
 * > flow.feature.remove('other_flag')
 * > flow.feature.clear()
 * > flow.feature.list()
 */
export function useFlowFeatureActual(
  ...args: Parameters<typeof useFeature>
): [...ReturnType<typeof useFeature>, boolean] {
  const context = useFlowFeatureContext()
  const cache = { ...context.localCache, ...context.sessionCache, ...context.queryCache }
  const optimizelyData = useFeature(...args)

  const key = args[0]
  useEffect(() => {
    const hasKey = Boolean(key)
    if (hasKey) {
      context.addToLiveCache(key, [...optimizelyData, context.attributesReady])
    }
  }, [key, context, optimizelyData])
  if (key in cache) {
    return cache[key]
  }
  return [...optimizelyData, context.attributesReady]
}

export function useFlowFeatureStub(
  ..._args: Parameters<typeof useFeature>
): [...ReturnType<typeof useFeature>, boolean] {
  return [false, {}, true, true, true]
}

export function useFlowFeatureFn(): (
  ...args: Parameters<typeof useFeature>
) => [...ReturnType<typeof useFeature>, boolean] {
  const router = useRouter()
  const isFlowpage = router?.pathname === Routes.FLOWPAGE
  if (isFlowpage) return useFlowFeatureStub
  return useFlowFeatureActual
}

type P = Parameters<typeof useFeature>
type RT = [...ReturnType<typeof useFeature>, boolean]

/**
 * Provides the state of the requested feature flag.
 *
 * @see useFeature from Optimizely
 * @see FeatureFlags for typing
 *
 * @example
 * interface FeatureFlags {
 *   bike_speeds: { max_speed: number }
 * }
 * const [bikeSpeedsEnabled, { maxSpeed }] = useFlowFeature('bike_speeds')
 */
export function useFlowFeature<K extends keyof FeatureFlags>(
  featureKey: K,
  options?: P[1],
  overrides?: P[2]
): [RT[0], FeatureFlags[K], RT[2], RT[3], RT[4]]
export function useFlowFeature<K extends string>(
  featureKey: K,
  options?: P[1],
  overrides?: P[2]
): [RT[0], VariableValuesObject, RT[2], RT[3], RT[4]]
export function useFlowFeature<K extends keyof FeatureFlags | string>(
  featureKey: K,
  options?: P[1],
  overrides?: P[2]
): [
  RT[0],
  K extends keyof FeatureFlags ? FeatureFlags[K] : VariableValuesObject,
  RT[2],
  RT[3],
  RT[4]
] {
  return useFlowFeatureFn()(featureKey, options, overrides) as unknown as [
    RT[0],
    K extends keyof FeatureFlags ? FeatureFlags[K] : VariableValuesObject,
    RT[2],
    RT[3],
    RT[4]
  ]
}
