import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface AuthFormState {
  email: string
  password: string
  submitting?: boolean
}

export const initialAuthForm: AuthFormState = {
  email: '',
  password: ''
}

const authFormSlice = createSlice({
  name: 'authForm',
  initialState: initialAuthForm,
  reducers: {
    setAuthFormState: (state, action: PayloadAction<AuthFormState>) => {
      state.email = action.payload.email
      state.password = action.payload.password
      state.submitting = false
    },
    clearAuthFormState: state => {
      state.email = ''
      state.password = ''
      state.submitting = false
    }
  }
})

export const { setAuthFormState, clearAuthFormState } = authFormSlice.actions

export default authFormSlice.reducer
