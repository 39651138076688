import { startOfDay, subDays } from 'date-fns'

export enum Intervals {
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly'
}

export enum DateFormat {
  API = 'yyyy-MM-dd',
  CHART = 'MMMM d, yyyy',
  LABEL = 'MMM d',
  HOUR = 'h:mmaaa',
  DATE_AND_HOUR = 'MMM d, yyyy, h:mmaaa'
}

export const Today = startOfDay(new Date())
export const Yesterday = subDays(Today, 1)
export const LastWeek = subDays(Today, 6)
export const LastMonth = subDays(Today, 29)
export const AllTimeStartDate = new Date('06/01/2019')

export const DateFilters = {
  last7Days: LastWeek,
  last30Days: LastMonth,
  last90Days: subDays(Today, 89),
  last365Days: subDays(Today, 364),
  allTime: AllTimeStartDate
}

export enum DateOptions {
  TODAY = 'Today',
  LAST7 = 'Last 7 days',
  LAST30 = 'Last 30 days',
  LAST90 = 'Last 90 days',
  LAST365 = 'Last 365 days',
  ALLTIME = 'All time',
  SELECT = 'Select date range'
}

export const DateDrilldownOptions = Object.values(DateOptions).slice(0, -1)

export const APIDateFilters = {
  [DateOptions.TODAY]: {
    dates: [Today],
    interval: Intervals.HOURLY
  },
  [DateOptions.LAST7]: {
    dates: [DateFilters.last7Days, Today],
    interval: Intervals.DAILY
  },
  [DateOptions.LAST30]: {
    dates: [DateFilters.last30Days, Today],
    interval: Intervals.DAILY
  },
  [DateOptions.LAST90]: {
    dates: [DateFilters.last90Days, Today],
    interval: Intervals.DAILY
  },
  [DateOptions.LAST365]: {
    dates: [DateFilters.last365Days, Today],
    interval: Intervals.WEEKLY
  },
  [DateOptions.ALLTIME]: {
    dates: [DateFilters.allTime, Today],
    interval: Intervals.WEEKLY
  }
}

export enum Weekdays {
  M = 'Monday',
  T = 'Tuesday',
  W = 'Wednesday',
  Th = 'Thursday',
  F = 'Friday',
  S = 'Saturday',
  Su = 'Sunday'
}

export const DaysOfWeek: { [dayKey: number]: string } = {
  [0]: 'Monday',
  [1]: 'Tuesday',
  [2]: 'Wednesday',
  [3]: 'Thursday',
  [4]: 'Friday',
  [5]: 'Saturday',
  [6]: 'Sunday'
}

export const WeekdayKeys = Object.keys(Weekdays)

export const Hours = [
  '12am',
  '1am',
  '2am',
  '3am',
  '4am',
  '5am',
  '6am',
  '7am',
  '8am',
  '9am',
  '10am',
  '11am',
  '12pm',
  '1pm',
  '2pm',
  '3pm',
  '4pm',
  '5pm',
  '6pm',
  '7pm',
  '8pm',
  '9pm',
  '10pm',
  '11pm',
  '12am'
]

export interface DateLabels {
  current: string
  previous: string
}

export enum DateDrilldownSources {
  DropdownMenu,
  QuickFilters
}
