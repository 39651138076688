import { fireHeapEvent } from '@dtx-company/true-common/src/utils/heap'
import { fireHubspotEvent } from '.'
import { fireIntercomEvent as fireIntercomEvent } from './intercom/fireIntercomEvent'

/**
 * Analytics integrations with track event methods that accept a string event name and an optional properties object.
 */
export enum AnalyticsIntegrations {
  HEAP = 'heap',
  INTERCOM = 'intercom',
  HUBSPOT = 'hubspot'
}

export const eventFiringMap = {
  [AnalyticsIntegrations.HEAP]: fireHeapEvent,
  [AnalyticsIntegrations.INTERCOM]: fireIntercomEvent,
  [AnalyticsIntegrations.HUBSPOT]: fireHubspotEvent
}

/**
 * Fire an event to one or more analytics integrations.
 *
 * @param event - The name of the event to fire.
 * @param properties - Optional properties to send with the event.
 * @param integrations - Optional list of integrations to fire to. Defaults to just Heap.
 *
 * @note
 * This will fire to just Heap by default. If you want to fire to other integrations, you must specify them.
 */
export const fireAnalyticsEvent = (
  event: string,
  properties: Record<string, unknown> = {},
  integrations: AnalyticsIntegrations[] = [AnalyticsIntegrations.HEAP]
): Promise<void[]> => {
  const promises = integrations.map(integration => {
    return new Promise<void>(resolve => {
      resolve(eventFiringMap[integration](event, properties))
    })
  })
  return Promise.all(promises)
}
