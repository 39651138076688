import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  TeamMember,
  TeamMemberRole
} from '@dtx-company/inter-app/src/components/FlowTeams/FlowTeams.types'

export interface ModalMembersTableSliceState {
  editableMembers: TeamMember[]
  uneditableMembers: TeamMember[]
  editableMembersHaveChanged: boolean
  idOfMemberFocused: string | null
}

export const initialState: ModalMembersTableSliceState = {
  editableMembers: [],
  uneditableMembers: [],
  editableMembersHaveChanged: false,
  idOfMemberFocused: null
}

export const modalMembersTableSlice = createSlice({
  name: 'modalMembersTable',
  initialState,
  reducers: {
    addUneditableMember: (state, action: PayloadAction<{ newMember: TeamMember }>) => {
      state.uneditableMembers = [...state.uneditableMembers, action.payload.newMember]
    },
    addEditableMember: (state, action: PayloadAction<{ newMember: TeamMember }>) => {
      state.editableMembers = [...state.editableMembers, action.payload.newMember]
      state.editableMembersHaveChanged = true
    },
    updateEditableMemberRole: (
      state,
      action: PayloadAction<{ memberId: string; newRole: TeamMemberRole }>
    ) => {
      state.editableMembers = state.editableMembers.map(m => {
        if (m.id !== action.payload.memberId) return m
        return {
          ...m,
          role: action.payload.newRole
        }
      })
      state.editableMembersHaveChanged = true
      state.idOfMemberFocused = action.payload.memberId
    },
    removeEditableMember: (
      state,
      action: PayloadAction<{ memberToRemove: TeamMember; indexOfMemberToRemove: number }>
    ) => {
      const { indexOfMemberToRemove } = action.payload

      const idOfMemberFocused =
        state.editableMembers[indexOfMemberToRemove - 1]?.id ??
        state.editableMembers[indexOfMemberToRemove + 1]?.id ??
        null

      state.editableMembers = state.editableMembers.filter(
        m => m.id !== action.payload.memberToRemove.id
      )
      state.editableMembersHaveChanged = true
      state.idOfMemberFocused = idOfMemberFocused
    },
    resetTableState: state => {
      state.editableMembers = initialState.editableMembers
      state.editableMembersHaveChanged = initialState.editableMembersHaveChanged
      state.uneditableMembers = initialState.uneditableMembers
      state.idOfMemberFocused = initialState.idOfMemberFocused
    }
  }
})

export const {
  addUneditableMember,
  addEditableMember,
  updateEditableMemberRole,
  removeEditableMember,
  resetTableState
} = modalMembersTableSlice.actions

export default modalMembersTableSlice.reducer
