import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { CONTACT_US_EMAIL } from '@dtx-company/true-common/src/constants/contactUs'
import { FC } from 'react'
import { HELP_URL } from '@dtx-company/true-common/src/constants/help'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'

// TODO - create generic static page to reuse - DF

export const ErrorPage: FC = () => {
  return (
    <Box
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
      px={['16px', null, null]}
    >
      <Image src={'/icons/page-error.svg'} height="64px" width="64px" />
      <Spacer mt="16px" />
      <Image src={'/icons/flowcode-small-black.svg'} height="32px" width="226px" />
      <Spacer mt="16px" />
      <Text variant="display/small" textAlign="center">
        Something went wrong
      </Text>
      <Spacer mt="16px" />
      <Box maxWidth="560px">
        <Text textAlign="center">
          We&apos;re sorry for the inconvenience. Please refresh to try again. If you have any
          urgent questions or needs, you can view our{' '}
          <a href={HELP_URL} rel="noopener noreferrer" target="_blank">
            <Text component="span" variant="body/small" fontWeight="bold">
              Help Center
            </Text>
          </a>{' '}
          or{' '}
          <a href={`mailto:${CONTACT_US_EMAIL}`}>
            <Text component="span" variant="body/small" fontWeight="bold">
              Contact Us
            </Text>
          </a>
          .
        </Text>
      </Box>
    </Box>
  )
}
