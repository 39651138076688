export const IS_JEST = process.env.NODE_ENV === 'test'
export const IS_CI = process.env.CI === 'true'
export const IS_DEBUG = process.env.DEBUG === 'true'
export const IS_PRODUCTION = process.env.APP_ENV === 'production'
export const IS_STAGING = process.env.APP_ENV === 'staging'
export const IS_DEVELOPMENT = process.env.APP_ENV === 'development'
export const IS_REVIEW_APP = Boolean(process.env.PR_NUMBER)
export const IS_INTERNAL_ADMIN = process.env.APP_NAME === 'internal-admin'
export const IS_CYPRESS = IS_CI && !IS_JEST
export const APP_ENV = process.env.APP_ENV
export const USE_NGROK = process.env.USE_NGROK
export const NGROK_DOMAIN = process.env.NGROK_DOMAIN
export const PR_NUMBER = process.env.PR_NUMBER
