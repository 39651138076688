import { useFlowFeature } from '@dtx-company/inter-app/src/hooks/useFlowFeature/useFlowFeature'

export function parseFlowFeatureValue(
  value: string | Record<string, unknown> | number | boolean | undefined
): ReturnType<typeof useFlowFeature> {
  if (typeof value === 'object') {
    return [true, value, true, false, true]
  }

  // A JSON value would always start with { since the feature settings
  // are an object so using a cheap check for it here
  if (value && typeof value === 'string' && value[0] === '{') {
    const settings = JSON.parse(value)
    return [true, settings, true, false, true]
  }

  switch (value?.toString()) {
    case 'on':
    case '1':
    case 'true':
      return [true, {}, true, false, true]
    case 'off':
    case '0':
    case 'false':
      return [false, {}, true, false, true]
    default:
      throw new Error(`Unable to parse value: ${value}`)
  }
}
