import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export enum Product {
  FLOWCODE = 'flowcode',
  FLOWPAGE = 'flowpage',
  ALL = 'all'
}

export interface DashboardState {
  selectedProduct: Product
}

const initialDisplayState: DashboardState = {
  selectedProduct: Product.ALL
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialDisplayState,
  reducers: {
    setSelectedProduct(state, action: PayloadAction<Product>) {
      state.selectedProduct = action.payload
    }
  }
})

export const { setSelectedProduct } = dashboardSlice.actions
export default dashboardSlice.reducer
