import { useEffect } from 'react'
import {
  useIsEULoaded,
  useIsEU as useIsEUSelector
} from '@dtx-company/inter-app/src/redux/selectors/currentSessionSelectors'

export function useCheckEU(callback: () => void): void {
  const { isEU } = useIsEU()
  useEffect(() => {
    if (isEU) callback()
  }, [callback, isEU])
}

export function useIsEU(): {
  isEU?: boolean
  isEULoaded: boolean
} {
  const isEU = useIsEUSelector()
  const isEULoaded = useIsEULoaded()

  return {
    isEU,
    isEULoaded
  }
}
