import { CombinedTextProps } from '../atoms'
import { ModalProps } from './modal.types'

export enum MaskType {
  CIRCLE = 'circle',
  SQUARE = 'square',
  RECTANGLE = 'rectangle',
  IPHONEX = 'iphonex',
  FEATURED = 'featured',
  NO_IMAGE = 'noImage'
}

export interface FileUploadProps {
  handleUpload: (arg0: React.ChangeEvent<HTMLInputElement>) => void | Promise<void>
  id: string
  accept?: string
  multiple?: boolean
  children: React.ReactNode
}

export interface ImageEditProp {
  title: string
  useObjectURL?: boolean
  file?: File | string
  submitting: boolean
  openModal: boolean
  hideMask?: boolean
  onPositionChange?: () => void
  onSlide?: () => void
  onUpload?: () => void
  onSave?: () => void
  //deprecated with 7.0.0
  maskRadius?: number
  maskType: MaskType
  hideDelete?: boolean
  handleUploadImage: (
    imageUrl: string,
    maskType?: MaskType,
    imageSize?: number
  ) => void | Promise<void>
  handleDeleteImage?: () => void
  handleClose: () => void
  spinner?: React.ReactNode
  titleProps?: Omit<CombinedTextProps, 'children'>
  usingPortal?: ModalProps['usingPortal']
}
