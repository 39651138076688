import { SVGProps, memo } from 'react'

function PhotoSvg({ fill = 'currentcolor', color, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 20 16" {...rest}>
      <path
        fill={color || fill}
        fillRule="evenodd"
        d="M18 1.5H2a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h16a.5.5 0 00.5-.5V2a.5.5 0 00-.5-.5zM2 0a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2V2a2 2 0 00-2-2H2zm4 5a1 1 0 11-2 0 1 1 0 012 0zm7.13 1.093a.754.754 0 00-.55-.339.754.754 0 00-.609.214L9.248 8.673l-.675-.8a.75.75 0 00-1.078-.071l-4 3.643a.752.752 0 00-.05 1.06.752.752 0 001.06.05l3.424-3.12.698.827c.135.16.33.256.54.265.21.01.413-.069.562-.217l2.643-2.626 2.998 4.644a.75.75 0 101.26-.814l-3.5-5.42z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export const Photo = memo(PhotoSvg)

function PhotoFilledSvg({ fill = 'currentcolor', ...props }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 15" fill="none" {...props}>
      <path
        d="M14.963 1.579a2.08 2.08 0 00-.689-.554c-.263-.13-.549-.2-.838-.203H2.163a1.96 1.96 0 00-.84.204c-.265.13-.5.318-.694.553A2.89 2.89 0 000 3.414v8.82c-.005.675.22 1.327.63 1.836.193.235.429.425.693.556.264.132.55.202.841.207h11.271c.29-.004.576-.075.84-.206a2.09 2.09 0 00.688-.557 2.86 2.86 0 00.637-1.836v-8.82a2.86 2.86 0 00-.637-1.835zM3.549 3.4c.189 0 .373.06.53.172.157.112.28.273.352.46a1.1 1.1 0 01.055.594c-.036.2-.127.383-.26.526a.933.933 0 01-.489.281.889.889 0 01-.55-.059.972.972 0 01-.428-.38 1.084 1.084 0 01-.159-.57c0-.135.024-.269.071-.393.048-.124.118-.237.206-.332a.945.945 0 01.308-.222.887.887 0 01.364-.077zm9.107 8.407H2.938a.282.282 0 01-.16-.027.308.308 0 01-.124-.113.341.341 0 01-.015-.336l.806-1.345 1.15-1.92a.374.374 0 01.13-.12.344.344 0 01.332 0c.052.029.096.07.13.12l1.144 1.913 2.613-4.365a.374.374 0 01.13-.12.344.344 0 01.332 0c.051.029.096.07.13.12l2.613 4.372.806 1.345a.345.345 0 01-.016.336.308.308 0 01-.123.113.282.282 0 01-.16.027z"
        fill={fill}
      />
    </svg>
  )
}

export const PhotoFilled = memo(PhotoFilledSvg)
