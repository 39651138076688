import { Breakpoint, SxProps, TypographyVariants, useTheme } from '@mui/material/styles'
import { ForwardRefExoticComponent, forwardRef } from 'react'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { variants } from './Typography.overrides'
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/material/Typography'

export type TypographyProps = Pick<
  MuiTypographyProps,
  'align' | 'children' | 'color' | 'id' | 'noWrap' | 'ref' | 'component'
> & {
  variant?: MuiTypographyProps['variant'] | ResponsiveTypographyVariant
}

export type ResponsiveTypographyVariant = { [key in Breakpoint]?: keyof TypographyVariants }

/**
 * Use typography to present your design and content as clearly and efficiently as possible.
 *
 * @see https://mui.com/material-ui/react-typography for more details.
 *
 * @note Component overrides are defined in [./Typography.overrides.ts](./Typography.overrides.ts).
 */
const _Typography: ForwardRefExoticComponent<TypographyProps> = forwardRef(
  ({ variant, ...rest }, ref) => {
    const theme = useTheme()
    let sx: SxProps = {}
    const props: MuiTypographyProps = { ...rest }

    if (variant && typeof variant === 'object') {
      sx = Object.entries(variant).reduce<SxProps>((acc, [breakpoint, variant]) => {
        return {
          ...acc,
          [`${theme.breakpoints.up(breakpoint as Breakpoint)}`]:
            variants[variant as keyof typeof variants]
        }
      }, sx)
    } else {
      props.variant = variant as MuiTypographyProps['variant']
    }

    return <MuiTypography ref={ref} {...props} sx={sx} />
  }
)
_Typography.displayName = 'Typography'

interface TypographyTypeMap {
  props: TypographyProps
  defaultComponent: 'span'
}

export const Typography: OverridableComponent<TypographyTypeMap> = _Typography

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    // disable unused MUI built-in variants
    subtitle1: false
    subtitle2: false
    button: false
    overline: false
    caption: false

    // these variants are "enabled" to let another package have correct types
    // using the Typography component directly from MUI. They aren't available
    // as valid parameters when using the Typography component from the
    // design-system because of the TypographyProps type defined at the top of
    // this file (these variant aren't defined there).
    body1: true
    body2: true
    h1: true
    h2: true
    h3: true
    h4: true
    h5: true
    h6: true

    // enable custom variants
    displayXLarge: true
    displayLarge: true
    displayMedium: true
    displaySmall: true
    headlineXLarge: true
    headlineLarge: true
    headlineMedium: true
    headlineSmall: true
    headlineXSmall: true
    subtitleLarge: true
    subtitleMedium: true
    subtitleSmall: true
    bodyLarge: true
    bodyMedium: true
    bodySmall: true
    labelLarge: true
    labelMedium: true
    labelSmall: true
    captionMedium: true
    overlineLarge: true
    overlineMedium: true
    overlineSmall: true
  }
}
