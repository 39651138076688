import {
  FetchRequest,
  FetchSideError,
  FlowResponse,
  RequestMethod
} from '@app/code/src/types/flowFetch'
import { JSON_HEADERS } from '@dtx-company/ithaca-sdk/src'
import {
  LoggerOptions,
  ServerSideError,
  ServerSideErrorType,
  cloneErrorObject,
  getServerSideErrorType,
  logger
} from '@dtx-company/logger'

// fetch utility function
// additional header data might be used for different endpoints
// make sure that error handler works as expected. Not all our services works consistently
// check errors as 404 or other type of errors inside your function if you need do specify the behavior

export const flowFetch = async <T>(
  method: RequestMethod,
  url: string,
  loggerOptions: LoggerOptions,
  requestInit: FetchRequest,
  onError?: (res: Response) => Promise<void>
): Promise<FlowResponse<T> | undefined> => {
  try {
    const res = await fetch(url, {
      method: method,
      ...requestInit,
      headers: {
        ...JSON_HEADERS,
        ...requestInit.headers
      }
    })
    if (res.ok) {
      const data = await res.json()
      return {
        status: res.status,
        statusText: res.statusText,
        redirected: res.redirected,
        ok: res.ok,
        type: res.type,
        url: res.url,
        data: data
      }
    } else {
      if (onError) await onError(res)
      else await defaultFetchErrorHandler(res)
    }
  } catch (e) {
    logger.logError(
      new ServerSideError({
        ...cloneErrorObject(e),
        type: e.type || ServerSideErrorType.ServerSideOtherError,
        endpoint: e.endpoint
      }),
      loggerOptions
    )
    throw e
  }
}

export const defaultFetchErrorHandler = async (res: Response): Promise<Error> => {
  const body = res.json ? await res.json() : res.statusText
  const message = body && body.message ? body.message : JSON.stringify(body)
  const error = new FetchSideError({
    type: getServerSideErrorType(res.status),
    endpoint: res.url,
    message: message,
    name: 'API request Error',
    status: res.status,
    statusText: res.statusText,
    data: body
  })

  throw error
}
