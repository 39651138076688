import {
  AddLinkType,
  DeleteLinkType,
  EditLinkType,
  EditPageType,
  EditProfileImgType,
  EditThemeType,
  EditUserInfoType,
  InitDemoPageType,
  InitTempPageType,
  LinkModifyType,
  OrderLinksType,
  RenameSlugType,
  ToggleLinkActiveType
} from './pageEditor'
import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { DEFAULT_PAGE_ID, defaultUserTheme } from '@dtx-company/true-common/src/constants/page'
import { Link } from '@dtx-company/flow-codegen/src/page/generated.types'
import { PageEditorState } from './pageEditor.types'
import { PageType as TypedPage } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { generateNKeysBetween } from 'fractional-indexing'
import { getCurrentPageWithState } from './util'
import { initialEditorState } from './pageEditor.constants'
import { removeNullsOnOptional } from '@dtx-company/true-common/src/utils/objects'

type Reducer<T> = CaseReducer<PageEditorState, PayloadAction<T>>

const getLinkIndex = (linkId: string, page: TypedPage): number | null =>
  page?.links?.findIndex(link => link?.id === linkId) ?? null

export const addLink: Reducer<AddLinkType> = (state, action) => {
  const page = getCurrentPageWithState(state)
  if (page) {
    const newLink = action.payload.link
    page.links = [...(page.links || []), newLink]
  }
}

export const editLink: Reducer<EditLinkType> = (state, action) => {
  const page = getCurrentPageWithState(state)
  if (page && page.links) {
    const linkId = action.payload.linkId
    const idx = page.links?.findIndex(link => link?.id === linkId)
    if (idx !== undefined && idx > -1 && page.links[idx]) {
      const updatedFields = action.payload.link
      const newLink = {
        ...(page.links[idx] as Link),
        ...updatedFields
      }
      const newLinks = page.links.map(link => {
        return link?.id === newLink.id ? newLink : link
      })
      page.links = newLinks
    }
  }
}

export const deleteLink: Reducer<DeleteLinkType> = (state, action) => {
  const page = getCurrentPageWithState(state)
  if (page && page.links) {
    const deleteId = action.payload.linkId
    const deleteOrder = action.payload.linkOrder
    const newLinks =
      removeNullsOnOptional(page.links)
        ?.filter(({ id }) => id !== deleteId)
        .map(({ order, ...rest }) => ({
          order: order > deleteOrder ? order - 1 : order,
          ...rest
        })) || null
    page.links = newLinks
  }
}

/**
 * Applies a position and order to links created in the demo flow
 */
export const orderLinks: Reducer<OrderLinksType> = (state, action) => {
  const page = getCurrentPageWithState(state)
  if (page && page.links) {
    const oldLinks = removeNullsOnOptional(page.links)
    if (oldLinks) {
      const keys = generateNKeysBetween(null, null, oldLinks.length)
      const links: LinkModifyType[] = action.payload.links
      page.links = links.map((link, i) => ({ ...link, order: i, position: keys[i] }))
    }
  }
}

export const editTheme: Reducer<EditThemeType> = (state, action) => {
  const page = getCurrentPageWithState(state)
  if (page) {
    if (page.theme) {
      const newTheme = action.payload.theme
      page.theme = {
        ...page.theme,
        ...newTheme
      }
    } else {
      page.theme = {
        ...defaultUserTheme,
        ...action.payload.theme
      }
    }
  }
}

export const editUserInfo: Reducer<EditUserInfoType> = (state, action) => {
  const page = getCurrentPageWithState(state)
  if (page) {
    page.caption = action.payload.description
    page.displayName = action.payload.title
  }
}

export const editPageType: Reducer<EditPageType> = (state, action) => {
  const page = getCurrentPageWithState(state)

  if (page) {
    page.pageType = action.payload.pageType
  }
}

export const toggleLinkActive: Reducer<ToggleLinkActiveType> = (state, action) => {
  const page = getCurrentPageWithState(state)
  if (page && page.links) {
    const linkId = action.payload.linkId
    const idx = getLinkIndex(linkId, page)
    if (idx != undefined && idx > -1 && page.links[idx]) {
      page.links[idx] = {
        ...(page.links[idx] as Link),
        active: !page.links[idx]?.active
      }
    }
  }
}

export const editProfileImage: Reducer<EditProfileImgType> = (state, action) => {
  const page = getCurrentPageWithState(state)
  if (page) {
    page.profileImage = action.payload.profileImage || null
  }
}

export const setPageId: Reducer<string | null> = (state, action) => {
  state.pageId = action.payload
}

export const setTemplateId: Reducer<string | null> = (state, action) => {
  state.templateId = action.payload
}

export const initDemoPage: Reducer<InitDemoPageType> = (state, action) => {
  state.tempPage = action.payload.page
  state.pageId = DEFAULT_PAGE_ID
}

export const toggleShare: Reducer<void> = state => {
  const page = getCurrentPageWithState(state)
  if (page) {
    page.share = !page.share
  }
}

export const toggleIsIndex: Reducer<void> = state => {
  const page = getCurrentPageWithState(state)
  if (page) {
    page.isIndexed = !page.isIndexed
  }
}

export const resetPageEditorSlice: Reducer<void> = state => {
  state.pageId = initialEditorState.pageId
  state.pages = initialEditorState.pages
  state.tempPage = initialEditorState.tempPage
  state.templateId = initialEditorState.templateId
  state.autosaveIndicatorState = initialEditorState.autosaveIndicatorState
}

export const updateRenamePage: Reducer<RenameSlugType> = (state, action) => {
  const page = getCurrentPageWithState(state)

  if (page) {
    page.slugName = action.payload.page.slugName
    page.shortUrl = action.payload.page.shortUrl
  }
}

export const renameSlug: Reducer<{ newSlugName: string }> = (state, action) => {
  const page = getCurrentPageWithState(state)
  if (page) {
    page.slugName = action.payload.newSlugName
  }
}

export const initTempPage: Reducer<InitTempPageType> = (state, action) => {
  state.tempPage = action.payload.page
  state.pageId = DEFAULT_PAGE_ID
}

export const setClientHasMailchimpKey: Reducer<{ apiKey: string }> = (state, action) => {
  state.clientHasMaillchimpKey = action.payload.apiKey
}

export const toggleLoading: Reducer<{ isLoading: boolean }> = (state, action) => {
  state.autosaveIndicatorState.isLoading = action.payload.isLoading
}
